import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Spinner, Box } from '@hover/blueprint';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { debounce, isNil } from 'lodash';
import type { Editor as IEditor } from 'tinymce';

import { RemoteService } from 'src/api/graphql-global-types';
import { generateRemoteJWT as generateRemoteJWTReturnType } from 'src/api/types/generateRemoteJWT';
import appSettings from 'src/appSettings';
import { GENERATE_JWT_TOKEN } from 'src/features/settings/api/queries/jwt';

import { getToolbar, getPlugins } from './utils';

type Props = IAllProps & {
  orgId: string;
  additionalPlugins?: string[]; // eg: ['pluginA', 'pluginB', pluginC']
  additionalToolbarItems?: string[][]; // see `DEFAULT_TOOLBAR` for example
  setup?: (editor: IEditor) => void;
  toolbar?: string[][];
  contentStyle?: string;
};

export const WysiwygEditor: React.FC<Props> = ({
  additionalPlugins = [],
  additionalToolbarItems = [],
  initialValue,
  onDirty,
  onEditorChange,
  orgId,
  plugins,
  setup,
  toolbar,
  value: _formValue,
  contentStyle = '',
  ...props
}) => {
  const [jwt, setJwt] = useState<string | null>(null);

  const handleEditorChange: typeof onEditorChange = (value, editor) => {
    if (!isNil(onEditorChange) && editor.isDirty())
      onEditorChange(value, editor);
  };

  const onCompleted = (data: generateRemoteJWTReturnType) => {
    if (data.generateRemoteJWT?.jwt) setJwt(data.generateRemoteJWT.jwt);
  };

  const [generateJwtToken] = useMutation(GENERATE_JWT_TOKEN, {
    onCompleted,
  });

  useEffect(() => {
    generateJwtToken({
      variables: {
        remoteService: RemoteService.TINY_MCE,
        orgId,
      },
    });
  }, []);

  if (!jwt)
    return (
      <Box justifyContent="center">
        <Spinner />
      </Box>
    );

  return (
    <Editor
      apiKey={appSettings.TINYMCE_API_KEY}
      initialValue={initialValue}
      onEditorChange={debounce(handleEditorChange)}
      onDirty={onDirty}
      plugins={getPlugins({ plugins, additionalPlugins })}
      toolbar={getToolbar({ toolbar, additionalToolbarItems })}
      value={_formValue}
      init={{
        height: 500,
        menubar: false,
        statusbar: false,
        setup,
        content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } ${contentStyle}`,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tinydrive_token_provider: (success: any, failure: any) => {
          success({
            token: jwt,
          });
          failure('Error getting JWT token');
        },
        toolbar_mode: 'wrap',
      }}
      {...props}
    />
  );
};
