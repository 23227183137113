import queryString from 'query-string';

import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';

export const getTemplateIdsFromUrl = () => {
  const parsedHash = queryString.parse(window.location.hash, {
    arrayFormat: 'bracket',
  }).template_ids;

  return parsedHash && parsedHash.length > 0
    ? (parsedHash as string[]).map((id) => Number(id))
    : [];
};

export const organizeTemplatesByTrade = (
  templates: (Template | null | undefined)[] | null,
) => {
  return templates?.reduce((acc: { [key: string]: Template[] }, template) => {
    if (template && template.tradeType) {
      if (Array.isArray(acc[template.tradeType])) {
        acc[template.tradeType].push(template);
      } else {
        acc[template.tradeType] = [template];
      }
    }
    return acc;
  }, {});
};
