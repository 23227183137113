import { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Heading } from '@hover/blueprint';
import { isEqual, xor, isEmpty, last } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  estimationConfigTemplates_estimationConfigTemplates_nodes as Template,
  estimationConfigTemplates as TemplateQuery,
} from 'src/api/types/estimationConfigTemplates';
import { GET_TEMPLATES } from 'src/features/exteriorEstimator/apis/queries/templates';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { TradeSection } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/TradeSection';
import { useMachete } from 'src/features/exteriorEstimator/hooks';
import {
  getTemplatesEnd,
  initializeSelectedTemplates,
  toggleSelectedTemplate,
  deselectAllTemplates,
} from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getJobDetails,
  getTemplates,
  getSelectedTemplateIds,
  organizeTemplatesByTrade,
  getGeometry,
  getMetadata,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { areAnyTemplatesSelected } from 'src/features/exteriorEstimator/utils/templateSelection';
import { getTemplateIdsFromUrl } from 'src/features/exteriorEstimator/utils/templateUtils';
import { SelectSingleTemplate } from 'src/features/settings/takeoffs/components/Templates/SelectSingleTemplate';
import { useEffectOnMount, usePrevious } from 'src/hooks';
import { FeatureFlag, QUANTITY_TAKEOFF } from 'src/lib/FeatureFlag';
import {
  getUserOrgId,
  getTradeTypesSorted,
  getMaterialListFeature,
} from 'src/redux/selectors';
import { tradeTypesSorted } from 'src/utils/TradeTypeSort';

export interface TradeAttributes {
  collapsed: boolean;
  templates: Template[];
}

interface TradeCollection {
  [trade: string]: TradeAttributes;
}

export const SelectTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const { initializeMacheteData } = useMachete();

  const [trades, setTrades] = useState<TradeCollection>({});

  const orgId = useSelector(getUserOrgId);
  const templates = useSelector(organizeTemplatesByTrade);
  const ungroupedTemplates = useSelector(getTemplates);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const geometryUrl = useSelector(getJobDetails)?.threeDFiles?.geometryUrl;
  const metadataUrl = useSelector(getJobDetails)?.threeDFiles?.metadataUrl;

  const geometry = useSelector(getGeometry);
  const metadata = useSelector(getMetadata);

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  let selectedTemplateIds = useSelector(getSelectedTemplateIds);

  if (!selectedTemplateIds) {
    selectedTemplateIds = getTemplateIdsFromUrl();
    dispatch(initializeSelectedTemplates({ templateIds: selectedTemplateIds }));
  }
  const previousSelectedTemplateIds = usePrevious(selectedTemplateIds);

  const { data, loading, fetchMore } = useQuery<TemplateQuery>(GET_TEMPLATES, {
    variables: { orgId },
  });

  const handleTradeSelection = (selectedTrade: TradeTypeEnum) => {
    const newTrades = { ...trades };
    newTrades[selectedTrade] = {
      ...trades[selectedTrade],
      collapsed: !trades[selectedTrade].collapsed,
    };

    setTrades(newTrades);
  };

  const getCollapsedStatus = useCallback(
    (templatesOfTrade: Template[]) => {
      if (materialListFeatureEnabled) {
        return false;
      }

      return !areAnyTemplatesSelected(templatesOfTrade, selectedTemplateIds);
    },
    [materialListFeatureEnabled, selectedTemplateIds],
  );

  useEffectOnMount(() => {
    if (!geometry && !metadata && geometryUrl && metadataUrl) {
      initializeMacheteData(geometryUrl, metadataUrl);
    }
  });

  useEffect(() => {
    if (
      data?.estimationConfigTemplates?.edges &&
      data?.estimationConfigTemplates?.edges?.length > 0 &&
      !data?.estimationConfigTemplates.pageInfo.hasNextPage
    ) {
      const theseTemplates = data?.estimationConfigTemplates?.edges
        ?.map((edge) => edge?.node)
        .filter((t) => !!t);
      dispatch(
        getTemplatesEnd({
          templates: theseTemplates as Template[],
        }),
      );
    }
  }, [data?.estimationConfigTemplates, dispatch]);

  useEffect(() => {
    // got new templates from redux
    if (templates && tradeTypes.length) {
      const theseTrades: TradeCollection = {};
      tradeTypesSorted(tradeTypes).forEach((tradeType) => {
        const templatesOfTrade = templates[tradeType];

        if (!templatesOfTrade) return;

        theseTrades[tradeType] = {
          collapsed: getCollapsedStatus(templatesOfTrade),
          templates: templatesOfTrade,
        };
      });
      setTrades(theseTrades);
    }
  }, [templates, tradeTypes, getCollapsedStatus]);

  useEffect(() => {
    if (
      previousSelectedTemplateIds &&
      !isEmpty(trades) &&
      !isEqual(previousSelectedTemplateIds, selectedTemplateIds) // selected templates are different from before
    ) {
      const selectedIds = xor(previousSelectedTemplateIds, selectedTemplateIds);

      const newTrades = { ...trades };

      Object.keys(newTrades).forEach((tradeType) => {
        const templatesOfTrade = newTrades[tradeType].templates;

        if (areAnyTemplatesSelected(templatesOfTrade, selectedIds)) {
          newTrades[tradeType] = {
            ...trades[tradeType],
            collapsed: getCollapsedStatus(templatesOfTrade),
          };
        }
      });

      setTrades(newTrades);
    }
  }, [
    selectedTemplateIds,
    trades,
    previousSelectedTemplateIds,
    getCollapsedStatus,
  ]);

  useEffect(() => {
    // once templates and trades are set up, and if material list feature is enabled
    // and the selection state hasn't been set up (either by cache or otherwise)
    if (
      materialListFeatureEnabled &&
      !isEmpty(templates) &&
      !isEmpty(tradeTypes) &&
      isEmpty(selectedTemplateIds)
    ) {
      if (templates?.ROOF?.length) {
        // if there is a roofing template, select the last item (top of the list in the UI)
        const roofingTemplateId = last(templates.ROOF)?.id;
        dispatch(toggleSelectedTemplate({ id: roofingTemplateId as number }));
      } else if (Object.keys(templates).length) {
        //  if there isn't a roofing template, but there are templates to select, attempt to pre-select whatever you find
        const targetTrade = last(Object.keys(templates)) || 0;
        const targetTemplateId = last(templates[targetTrade])?.id;
        dispatch(toggleSelectedTemplate({ id: targetTemplateId as number }));
      }
    }
  }, [
    templates,
    tradeTypes,
    materialListFeatureEnabled,
    selectedTemplateIds,
    dispatch,
  ]);

  if (!data || loading) return null;

  const { hasNextPage, endCursor } = data.estimationConfigTemplates.pageInfo;

  if (hasNextPage) {
    fetchMore({
      variables: {
        orgId,
        after: endCursor,
      },
    });
  }

  return (
    <FeatureFlag
      flagName={QUANTITY_TAKEOFF}
      whenOn={
        <SelectSingleTemplate
          templates={ungroupedTemplates || []}
          selectedTemplateIds={selectedTemplateIds}
          handleTemplateSelection={(selectedTemplate: Template) => {
            dispatch(deselectAllTemplates());
            dispatch(toggleSelectedTemplate({ id: selectedTemplate.id }));
          }}
        />
      }
      whenOff={
        <Box flex-direction="column" width="100%" height="100vh">
          <EstimatorResponsiveWrapper
            data-test-id="select-templates-page"
            flex={1}
          >
            <Box flexDirection="column" width="100%">
              <Heading
                size={500}
                marginBottom={400}
                marginLeft={100}
                fontWeight="bold"
              >
                Select project scope
              </Heading>
              {Object.entries(trades).map(([trade, tradeCollection]) => {
                return (
                  <TradeSection
                    key={trade}
                    trade={trade as TradeTypeEnum}
                    tradeAttributes={tradeCollection}
                    handleTradeSelection={handleTradeSelection}
                    selectedTemplateIds={selectedTemplateIds}
                  />
                );
              })}
            </Box>
          </EstimatorResponsiveWrapper>
        </Box>
      }
    />
  );
};
