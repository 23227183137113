import { Icon, Box, Body, Panel } from '@hover/blueprint';
import { iClock } from '@hover/icons';

export const DeliveryOnHold = () => {
  return (
    <Panel
      boxShadow="distance500"
      paddingX={600}
      paddingY={400}
      marginBottom={500}
    >
      <Box justifyContent="flex-start" alignItems="center">
        <Icon
          icon={iClock}
          color="neutral600"
          size="medium"
          marginRight={200}
        />
        <Body size={400} margin={0}>
          Submit list of materials and quantity to distributor and add delivery
          details later
        </Body>
      </Box>
    </Panel>
  );
};
