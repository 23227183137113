import { createAction } from 'typesafe-actions';

import {
  ProjectManagementOrderDataType,
  ProjectManagementOrderBranchAccountType,
} from 'src/features/projectManagement/types';

export const START_ORDER_FLOW = 'ESTIMATOR_PRODUCTION/START_ORDER_FLOW';
export const startOrderFlow = createAction(
  START_ORDER_FLOW,
  (payload: ProjectManagementOrderBranchAccountType) => payload,
)();

export const END_ORDER_FLOW = 'ESTIMATOR_PRODUCTION/END_ORDER_FLOW';
export const endOrderFlow = createAction(END_ORDER_FLOW, () => null)();

export const UPDATE_PROJECT_MANAGEMENT_ORDER_DATA =
  'ESTIMATOR_PRODUCTION/UPDATE_PROJECT_MANAGEMENT_ORDER_DATA';
export const updateProjectManagementOrderData = createAction(
  UPDATE_PROJECT_MANAGEMENT_ORDER_DATA,
  (payload: ProjectManagementOrderDataType) => payload,
)();

export const SUBMIT_AND_POLL_DISTRIBUTION_ORDER =
  'ESTIMATOR_PRODUCTION/SUBMIT_AND_POLL_DISTRIBUTION_ORDER';
export const submitAndPollDistributionOrder = createAction(
  SUBMIT_AND_POLL_DISTRIBUTION_ORDER,
  (payload: ProjectManagementOrderBranchAccountType) => payload,
)();
