import { PureComponent } from 'react';

import { AddListItem } from 'src/features/projectManagement/components/ProductionView/AddListItemFormModal/AddListItemFromModal';

import FilterBar from '../FilterBar';
import { ProductionSummary } from '../ProductionSummary';
import Tables from '../Tables';

export class ProductionContent extends PureComponent {
  public render() {
    return (
      <>
        <ProductionSummary />
        <FilterBar />
        <Tables />
        <AddListItem />
      </>
    );
  }
}
