export const generateSignatureMenuItems = (maxSigneeCount: number) => {
  const menuItems = [];
  for (let i = 1; i <= maxSigneeCount; i += 1) {
    menuItems.push({
      label: `Signee ${i} Initials`,
      value: `<span class="mceNonEditable signature_placeholder initials signee${i}" style="background-color:#DEEEFF;">[Signee ${i} Initials]</span>`,
    });
    menuItems.push({
      label: `Signee ${i} Signature`,
      value: `<span class="mceNonEditable signature_placeholder signature signee${i}" style="background-color:#DEEEFF;">[Signee ${i} Signature]</span>`,
    });
  }
  return menuItems;
};

// creates array of length n with values 1 to n
export const fillArrayRange = (n: number) =>
  Array(n)
    .fill(null)
    .map((value, i) => i + 1);
