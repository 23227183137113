import { createAction } from 'typesafe-actions';

export const track = createAction(
  'EXTERIOR_ESTIMATOR/TRACK',
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: { eventName: string; properties: any }) => payload,
)();

interface NavigateCategories {
  direction?: string;
  forcedTargetCategoryIndex?: number;
}

export const navigateCategories = createAction(
  'EXTERIOR_ESTIMATOR/NAVIGATE_CATEGORIES',
  ({ direction, forcedTargetCategoryIndex }: NavigateCategories) => ({
    direction,
    forcedTargetCategoryIndex,
  }),
)();

export const navigateToNextPage = createAction(
  'EXTERIOR_ESTIMATOR/NAVIGATE_TO_NEXT_PAGE',
  (currentCategory: string) => currentCategory,
)();

export const navigateToPreviousPage = createAction(
  'EXTERIOR_ESTIMATOR/NAVIGATE_TO_PREVIOUS_PAGE',
  (currentCategory: string) => currentCategory,
)();

export const setError = createAction(
  'EXTERIOR_ESTIMATOR/SET_ERROR',
  (error: boolean) => ({ error }),
)();
