import { upperCase } from 'lodash';
import pluralize from 'pluralize';

import { EHI_ESTIMATOR_DEBUG_KEY } from 'src/constants';
import {
  ClassificiationFacade,
  GeometryEdge,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';
import { HDFMeasurements } from 'src/features/exteriorEstimator/types/HdfMeasurements';
import {
  COMMERCE_INCLUDE_OPENINGS_LESS_THAN_33_SQFT,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';

import { FACADE_TYPES } from '../constants/sidingConstants';
import { EdgeTotals, EdgesForFacade } from './PartialMeasurementsMapHdf';
import PartialSidingUtils from './PartialSidingUtils';
import { WindowsForFacade } from './PartialsMeasurementsMap';

export class LineSegmentCalculatorHdf {
  public windowsForFacade: WindowsForFacade = {};

  public edges: GeometryEdge[] = [];

  public facades: ClassificiationFacade[] = [];

  /**
   * @example
   * calculatedEdgeTotals
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   */
  public calculatedEdgeTotals: EdgeTotals = {};

  private edgesForFacade: EdgesForFacade = {};

  private questionResponses: QuestionResponses = {};

  private hdfMeasurements: HDFMeasurements | null = null;

  public selectedSidingFacades: string[] = [];

  private orgSettings: EhiOrgSettings;

  public selectedAreaWithTrim = 0;

  constructor({
    edges,
    facades,
    windowsForFacade,
    questionResponses,
    hdfMeasurements,
    orgSettings,
  }: {
    edges: GeometryEdge[];
    facades: ClassificiationFacade[];
    windowsForFacade: WindowsForFacade;
    questionResponses: QuestionResponses;
    hdfMeasurements: HDFMeasurements | null;
    orgSettings: EhiOrgSettings;
  }) {
    this.edges = edges;
    this.facades = facades;
    this.windowsForFacade = windowsForFacade;
    this.hdfMeasurements = hdfMeasurements;
    this.orgSettings = orgSettings;
    this.questionResponses = questionResponses;
    this.edgesForFacade = this.createEdgesForFacade();
    this.calculatedEdgeTotals = this.calculateEdgeTotals();
    this.selectedSidingFacades = this.getSelectedSidingFacades();
    this.selectedAreaWithTrim = this.getSelectedAreaWithTrim();
  }

  private createEdgesForFacade() {
    const edgesForFacade = this.facades.reduce<EdgesForFacade>(
      (_edgesForFacade, facade) => {
        const eff = { ..._edgesForFacade };
        eff[facade.display_label] = facade.edges;
        return eff;
      },
      {},
    );
    return edgesForFacade;
  }

  private calculateEdgeTotals() {
    // first initialize all the edgeTotals with 0's
    const edgeTotals = this.edges.reduce<EdgeTotals>((_map, { type }) => {
      const map = { ..._map };
      const typeCountKey = `${type}_count`; // ie ridge_count
      const typeTotalKey = `${type}_total`; // ie ridge_total
      if (!map[typeCountKey]) {
        map[typeTotalKey] = 0;
        map[typeCountKey] = 0;
      }
      return map;
    }, {});

    // then sum them up (in feet)
    Object.values(this.getSummableEdges()).forEach(
      ({ type, length = 0, source }) => {
        const typeKey =
          source === 'wall' && (type === 'eave' || type === 'rake') // eaves_total/rakes_total denotes a siding edge, eave_total/rake_total denotes roof edge
            ? pluralize(type)
            : type;
        const typeCountKey = `${typeKey}_count`; // ie ridge_count
        const typeTotalKey = `${typeKey}_total`; // ie ridge_total
        if (!edgeTotals[typeTotalKey]) edgeTotals[typeTotalKey] = 0;
        if (!edgeTotals[typeCountKey]) edgeTotals[typeCountKey] = 0;
        edgeTotals[typeTotalKey] += length / 12;
        edgeTotals[typeCountKey] += 1;
      },
    );

    edgeTotals.window_count_total = this.getWindowCount();

    return edgeTotals;
  }

  /**
   * returns a map of edges which can be summed
   * @example
   * getSummableEdges()
   * {
   *   'E-1': { type: 'rake', length: 10 ... },
   *   'E-2': { type: 'ridge', length: 20 ... },
   * }
   */
  private getSummableEdges() {
    // it is possible that a certain edge is attached to mmultiple facades or is classified as two different types (in here twice)
    return Object.values(this.getSummableEdgesForFacade()).reduce<{
      [facadeDisplayLabel: string]: GeometryEdge;
    }>((_summableEdges, edges) => {
      const summabledEdges = { ..._summableEdges };
      edges.forEach((edgeId) => {
        const edge = this.edges.find(
          (ed) =>
            ed.id === edgeId ||
            ed?.classifiedInContext?.edgeId?.uuid === edgeId,
        );

        if (!edge) return;
        if (!summabledEdges[edge.id]) {
          summabledEdges[edge.id] = edge;
        }
      });
      return summabledEdges;
    }, {});
  }

  /**
   * returns a edgesForFacade map only including facades that are currently selected
   * also removes some edges from non-siding facades
   */
  private getSummableEdgesForFacade() {
    const nonSidingEdgesToRemove = [
      'opening_sides',
      'opening_bottoms',
      'opening_tops',
      'sloped_bases',
      'perimeter',
      'level_bases',
    ];
    return Object.entries(this.edgesForFacade).reduce<EdgesForFacade>(
      (_edgesForFacade, [facadeDisplayLabel, edges]) => {
        const edgesForFacade = { ..._edgesForFacade };
        // if the facade is selected
        if (this.questionResponses[facadeDisplayLabel]) {
          // removes some edges from any non-siding faces (ie, brick, stucco, unknown, etc...)
          const filteredEdges = edges.filter((edgeId) => {
            const edge = this.edges.find(
              (ed) =>
                ed.id === edgeId ||
                ed?.classifiedInContext?.edgeId?.uuid === edgeId,
            );
            if (!edge) return false;
            return !(
              facadeDisplayLabel.split('-').shift() !== 'SI' &&
              nonSidingEdgesToRemove.includes(edge.type)
            );
          });

          edgesForFacade[facadeDisplayLabel] = filteredEdges;
        }
        return edgesForFacade;
      },
      {},
    );
  }

  private getWindowCount() {
    return Object.entries(this.questionResponses).reduce<number>(
      (count, [id, isSelected]) => {
        const windowsForFacade = this.windowsForFacade[id];
        const len = windowsForFacade?.length;

        if (isSelected && len) {
          return count + len;
        }
        return count;
      },
      0,
    );
  }

  private getSelectedSidingFacades() {
    return Object.entries(this.questionResponses).reduce<string[]>(
      (acc, [id, isSelected]) => {
        if (FACADE_TYPES[upperCase(id.split('-').shift())] && isSelected) {
          acc.push(id);
        }
        return acc;
      },
      [],
    );
  }

  private getSelectedAreaWithTrim() {
    const facadesWithOpenings = this.hdfMeasurements?.combinedAreaWithTrim;
    const facadesWithoutOpenings =
      this.hdfMeasurements?.combinedAreaWithTrimNoOpenings;

    const facades = PartialSidingUtils.shouldCalculateWithOpenings(
      this.orgSettings,
    )
      ? facadesWithOpenings
      : facadesWithoutOpenings;

    if (!facades) return 0;

    return Object.entries(facades)
      .filter(([id]) => this.selectedSidingFacades.includes(id))
      .reduce<number>((acc, [, val]) => acc + val.combinedArea, 0);
  }
}
