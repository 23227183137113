import React from 'react';

import { Body } from '@hover/blueprint';
import styled from 'styled-components';

const EmptyCustomTemplates = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin: 10px;
`;

export const EmptySavedTemplatesDisclaimer = () => {
  return (
    <>
      <Body>My Custom Templates</Body>
      <EmptyCustomTemplates>
        Customize by selecting an existing template, editing, <br />
        and saving for quicker future use.
      </EmptyCustomTemplates>
    </>
  );
};
