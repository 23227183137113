import {
  EstimationConfigInputInputTypeEnum,
  TradeTypeCategoryEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  Input,
  Page,
  QuestionId,
  InputOption,
} from 'src/features/exteriorEstimator/types';

import * as measurementsUtils from './measurementsUtils';

export const getQuestionDefaultValue = (question: Input) => {
  if (question.questionDefaultValue == null) return 0;

  if (question.argument && measurementsUtils.isPitch(question.argument))
    return null;
  switch (question.inputType) {
    case EstimationConfigInputInputTypeEnum.NUMBER:
      return Number(question.questionDefaultValue);
    case EstimationConfigInputInputTypeEnum.BOOLEAN:
      /* this will change to return JSON.parse(question.questionDefaultValue)
      once BE starts sending booleans as "true" || "false" */
      return Number(question.questionDefaultValue);
    default:
      // handles string and select
      return question.questionDefaultValue;
  }
};

export const sortQuestionInputOptions = (options: InputOption[]) =>
  options.sort((a, b) =>
    !!a.sortOrder && !!b.sortOrder
      ? a.sortOrder - b.sortOrder
      : Number(new Date(a.updatedAt ?? '')) -
        Number(new Date(b.updatedAt ?? '')),
  );

export const getQuestionById = (pages: Page[], id: QuestionId) => {
  const allQuestions = pages.reduce<Input[]>(
    (questions, page) => [...questions, ...(page.questions || [])],
    [],
  );
  return allQuestions.find((question) => question.id === id);
};

export const getPageForCategory = (pages: Page[], category: string) => {
  return pages.find((pageForCategory) => pageForCategory.category === category);
};

export const getRoofTrades = (tradeTypes: TradeType[]) => {
  return tradeTypes
    ? tradeTypes.filter(
        (tradeType) =>
          tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.ROOF,
      )
    : [];
};

export const getSidingTrades = (tradeTypes: TradeType[]) => {
  return tradeTypes
    ? tradeTypes.filter(
        (tradeType) =>
          tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.SIDING,
      )
    : [];
};

export const getTradeTypeEnumsOfRoofCategory = (tradeTypes: TradeType[]) => {
  return getRoofTrades(tradeTypes).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );
};

export const getTradeTypeEnumsOfSidingCategory = (tradeTypes: TradeType[]) => {
  return getSidingTrades(tradeTypes).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );
};

export const isRoofTradeType = (
  tradeType: TradeTypeEnum,
  tradeTypes: TradeType[],
) => {
  return getTradeTypeEnumsOfRoofCategory(tradeTypes).includes(tradeType);
};

export const isUsingRoofTemplate = (
  templates: Template[],
  tradeTypes: TradeType[],
) => {
  const tradeTypesOfTemplates = templates.map((template) => template.tradeType);
  return getTradeTypeEnumsOfRoofCategory(tradeTypes).some((roofTradeType) =>
    tradeTypesOfTemplates.includes(roofTradeType),
  );
};

export const isUsingSidingTemplate = (
  templates: Template[],
  tradeTypes: TradeType[],
) => {
  const tradeTypesOfTemplates = templates.map((template) => template.tradeType);
  return getTradeTypeEnumsOfSidingCategory(tradeTypes).some((sidingTradeType) =>
    tradeTypesOfTemplates.includes(sidingTradeType),
  );
};
