import { Box } from '@hover/blueprint';
import { useSelector, useDispatch } from 'react-redux';

import { DistributionDeliveryMethodEnum } from 'src/api/graphql-global-types';
import { VendorQuery_vendors_distributor_capabilities_deliveryMethods as DeliveryMethodType } from 'src/api/types/VendorQuery';
import { RadioButton, RadioGroup } from 'src/components/Radio';
import {
  deliveryMethodValueField,
  deliveryMethodErrorField,
} from 'src/features/projectManagement/constants';
import {
  updateOrderDetailsForm,
  deliveryMethodUpdated,
} from 'src/features/projectManagement/redux/actions';
import { getDistributorCapabilities } from 'src/features/projectManagement/redux/selectors';

export const DeliveryMethods: React.FC = () => {
  const dispatch = useDispatch();
  const deliveryMethods: DeliveryMethodType[] | null =
    useSelector(getDistributorCapabilities)?.deliveryMethods ?? null;

  const onChange = (value: DistributionDeliveryMethodEnum) => {
    dispatch(
      updateOrderDetailsForm({
        errors: {
          [deliveryMethodErrorField]: false,
        },
        values: {
          [deliveryMethodValueField]: DistributionDeliveryMethodEnum[value],
        },
      }),
    );
    dispatch(deliveryMethodUpdated());
  };

  const sortedDeliveryMethods = [...(deliveryMethods ?? [])].sort(
    (a, b) => (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0),
  );

  return !deliveryMethods ? null : (
    <Box data-test-id="delivery-method-options-container">
      <RadioGroup marginBottom={600} onChange={onChange}>
        {sortedDeliveryMethods.map(({ id, label, deliveryMethod }) => (
          <RadioButton
            key={id}
            label={label}
            name="deliveryMethod"
            value={deliveryMethod}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
