import { Error } from './Error';

export const EstimatorError = () => {
  return (
    <Error
      header="There was an error generating your estimate. We'll contact your admin about the issue."
      buttonText="Contact Us"
      onClick={() => window.open('/contact', '_blank')}
    />
  );
};
