import appSettings from 'src/appSettings';
import request from 'src/lib/request';
import { GetSubOrgResponse } from 'src/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class HyperionApi {
  public static getUserById(userId: number) {
    return request.get(`/api/v1/users/${userId}`, {
      baseURL: appSettings.HYPERION_API_SERVER,
    });
  }

  public static getSubOrgs = ({
    orgId,
    page = 1,
  }: {
    orgId: number;
    page?: number;
  }) => {
    return request.get<GetSubOrgResponse>(
      `/api/v1/orgs?parent_id=${orgId}&page=${page}`,
      {
        baseURL: appSettings.HYPERION_API_SERVER,
      },
    );
  };
}
