import { useQuery } from '@apollo/client';
import { Box, Button } from '@hover/blueprint';
import styled from 'styled-components';

import { estimationEstimateGroups } from 'src/api/types/estimationEstimateGroups';
import type { jobsWithSalesOpportunity_jobs_results_salesOpportunity_soldEstimateGroup_user as UserType } from 'src/api/types/jobsWithSalesOpportunity';
import { GET_ESTIMATE_GROUPS } from 'src/features/estimatesAccess/api/queries/queries';

import EstimateGroup from './EstimateGroup';

const Wrapper = styled.div`
  margin: 0.25em 0;
`;

type Props = {
  jobId: number;
  selectedOrgId: number;
  excludeSoldEstimateId?: string | number | undefined;
  user: UserType | undefined;
};

export const EstimateGroupList: React.FC<Props> = ({
  jobId,
  selectedOrgId,
  excludeSoldEstimateId,
  user,
}) => {
  const { data, loading, fetchMore } = useQuery<estimationEstimateGroups>(
    GET_ESTIMATE_GROUPS,
    {
      variables: {
        jobId,
        orgId: selectedOrgId,
      },
      notifyOnNetworkStatusChange: true, // for some reason this ensures that `loading` works on fetchMore calls
      context: { allowBatching: true },
    },
  );

  if (!data) return null;

  const loadMore = () => {
    fetchMore({
      variables: {
        jobId,
        orgId: selectedOrgId,
        endCursor: data.estimationEstimateGroups.pageInfo.endCursor,
      },
    });
  };

  return (
    <>
      {data?.estimationEstimateGroups?.edges?.map((edge) => {
        const { node } = edge ?? {};
        if (!node || node.id === excludeSoldEstimateId) return null;
        return (
          <Wrapper key={node.id}>
            <EstimateGroup
              selectedOrgId={selectedOrgId}
              {...node}
              user={user}
            />
          </Wrapper>
        );
      })}
      {(data?.estimationEstimateGroups?.edges?.length ?? 0) > 0 && (
        <Box justifyContent="center" width={1}>
          <Button
            isDisabled={!data.estimationEstimateGroups.pageInfo?.hasNextPage}
            onClick={loadMore}
            isLoading={loading}
            fill="minimal"
          >
            load more
          </Button>
        </Box>
      )}
    </>
  );
};
