import { useEffect } from 'react';

import {
  Box,
  Modal,
  TextInput,
  Textarea,
  Field,
  Button,
} from '@hover/blueprint';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import {
  CreateTemplateGroupType,
  DisplayedTemplateGroup,
} from './TemplateEdit/types';

interface CreateTemplateGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: FieldValues) => void;
  currentGroup: CreateTemplateGroupType | DisplayedTemplateGroup | null;
}

export const CreateTemplateGroupModal: React.FC<
  CreateTemplateGroupModalProps
> = ({ isOpen, onClose, onSave, currentGroup }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const {
    register,
    handleSubmit,
    formState,
    formState: { isValid, errors, isDirty },
    reset,
    ...methods
  } = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      name: '',
      description: '',
    },
  });

  useEffect(() => {
    reset({
      name: currentGroup?.name ?? '',
      description: currentGroup?.description ?? '',
    });
  }, [currentGroup, reset]);

  const closeModal = () => {
    onClose();
    reset();
  };

  const save = (data: FieldValues) => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      button_location: currentGroup ? 'Edit-Group-Modal' : 'Create-Group-Modal',
      button_text: currentGroup ? 'Save' : 'Create',
      backend_id_value: currentGroup?.id ?? '',
      ...commonTrackingProps,
    });

    onSave(data);
  };

  const footer = (
    <Box data-test-id="CreateTemplateGroup-Actions">
      <Box>
        <Button
          data-test-id="CreateTemplateGroup-Actions-Cancel"
          color="primary"
          fill="outline"
          marginRight={200}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          data-test-id="CreateTemplateGroup-Actions-Save"
          color="primary"
          isDisabled={!isDirty || !isValid}
          onClick={handleSubmit(save)}
        >
          {currentGroup ? 'Save' : 'Create'}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      header={currentGroup ? 'Edit Group' : 'Create Group'}
      footer={footer}
      maxContentHeight="calc(100vh - 240px)"
    >
      <FormProvider
        {...{ register, formState, handleSubmit, reset, ...methods }}
      >
        <Field
          data-test-id="templateName"
          error={errors?.name?.message}
          label="Group Name (Required)"
          name="name"
          aria-required
        >
          <TextInput
            data-test-id="groupNameInput"
            label="groupNameInput"
            placeholder=""
            {...register('name', {
              required: 'name is required',
            })}
          />
        </Field>
        <Field
          data-test-id="templateDescription"
          error={errors?.description?.message}
          label="Description"
          name="description"
        >
          <Textarea
            data-test-id="groupDescriptionInput"
            rows={4}
            {...register('description')}
          />
        </Field>
      </FormProvider>
    </Modal>
  );
};
