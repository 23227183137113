import { useMutation } from '@apollo/client';
import {
  Body,
  Box,
  Heading,
  Loader,
  Button,
  LoadingOverlay,
} from '@hover/blueprint';
import { useParams } from 'react-router-dom';

import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate as ChecklistTemplateType } from 'src/api/types/inspectionConfigChecklistTemplate';
import { NavButton } from 'src/components/blueprint';
import { SortDirectionType } from 'src/constants';
import { UPDATE_TEMPLATE_SECTION_ORDER } from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';
import { TemplateNameEdit } from 'src/features/settings/components/ChecklistTemplates/TemplateNameEdit';
import { useEffectOnMount, useQueryEhi, useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

import { TemplateLogo } from './TemplateLogo';
import { TemplateSection } from './TemplateSection';
import { TemplateSectionCreateOrUpdate } from './TemplateSectionCreateOrUpdate';

export const FormBuilder = () => {
  const { templateId } = useParams();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const { data, loading } = useQueryEhi(GET_INSPECTION_CHECKLIST_TEMPLATE, {
    variables: {
      id: templateId,
    },
  });
  const [updateTemplateSectionOrder, { loading: updatingSectionOrder }] =
    useMutation(UPDATE_TEMPLATE_SECTION_ORDER, {
      refetchQueries: [
        {
          query: GET_INSPECTION_CHECKLIST_TEMPLATE,
          variables: { id: templateId },
        },
      ],
    });

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: `${EventNames.settings.inspectionReport.edit}-${templateId}`,
      ...commonTrackingProps,
    });
  });

  const checklistTemplate =
    data?.inspectionConfigChecklistTemplate as ChecklistTemplateType;

  const updateSectionOrder = async (
    sectionId: string,
    direction: SortDirectionType,
  ) => {
    const sections = [...checklistTemplate.sections];
    const fromIndex = sections.findIndex((section) => section.id === sectionId);
    if (
      (fromIndex === 0 && direction === SortDirectionType.asc) ||
      (fromIndex === sections.length - 1 &&
        direction === SortDirectionType.desc)
    )
      return;
    const section = sections.splice(fromIndex, 1);
    const toIndex = direction > 0 ? fromIndex + 1 : fromIndex - 1;
    sections.splice(toIndex, 0, section[0]);
    const checklistTemplateSectionIds = sections.map((s) => s.id);
    await updateTemplateSectionOrder({
      variables: {
        checklistTemplateId: templateId,
        checklistTemplateSectionIds,
      },
    });
  };

  const handleClickDone = () => {
    typewriter.buttonPressed({
      button_text: 'Done',
      page_or_screen_name: `${EventNames.settings.inspectionReport.edit}-${templateId}`,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <Box flexDirection="column" minHeight="100vh">
      <LoadingOverlay isLoading={updatingSectionOrder} />
      <Box flex="1" flexDirection="column" width={1}>
        <TemplateLogo logoUrl={checklistTemplate.logoUrl} />
        <Box flexDirection="row">
          <Heading isLight size={500}>
            {checklistTemplate.name}
          </Heading>
          <TemplateNameEdit
            name={checklistTemplate.name}
            templateId={templateId}
          />
        </Box>

        {checklistTemplate.sections.map((section, index) => (
          <TemplateSection
            key={section.id}
            name={section.name}
            isFirst={index === 0}
            isLast={index === checklistTemplate.sections.length - 1}
            templateId={templateId}
            templateSectionId={section.id}
            questions={section.questions}
            updateSectionOrder={updateSectionOrder}
          />
        ))}

        <TemplateSectionCreateOrUpdate templateId={templateId}>
          <Button fill="outline" size="large" marginBottom={400}>
            Create new Section
          </Button>
        </TemplateSectionCreateOrUpdate>
      </Box>
      <Box
        as="footer"
        marginX="-64px"
        marginBottom="-40px"
        background="neutral.0"
        position="sticky"
        bottom={0}
        padding={400}
        alignItems="center"
        gap={400}
        borderTop="1px solid"
        borderTopColor="neutral.200"
        justifyContent="end"
      >
        <Body as="span" color="neutral600" size={400}>
          {`Last change made on ${new Date(
            checklistTemplate.updatedAt,
          ).toLocaleString()}`}
        </Body>
        <NavButton
          to="/workflows/inspection_templates"
          onClick={() => handleClickDone()}
        >
          Done
        </NavButton>
      </Box>
    </Box>
  );
};
