import { Body, Box, Badge } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { estimationEstimateGroups_estimationEstimateGroups_edges_node_estimates as Estimate } from 'src/api/types/estimationEstimateGroups';
import { FormattedNumber } from 'src/components/FormattedNumber';
import {
  getMaterialListFeature,
  getOrgSettings,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { estimateTotalWithDiscounts } from 'src/utils/estimatesUtils';
import { formatTradesStringForEstimates } from 'src/utils/Formatters';

interface ParentProps {
  isSold?: boolean;
  estimate: Estimate;
}

export const EstimationGroupEstimate: React.FC<ParentProps> = ({
  isSold,
  estimate,
}) => {
  const showPricesInEstimation =
    useSelector(getOrgSettings)?.showPricesInEstimation &&
    !!estimate?.template?.showPricesInEstimation;
  const estimateTotal = estimateTotalWithDiscounts(estimate);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const orderingOnlyFeatureEnabled = useSelector(getOrderingOnlyFeatureAccess);
  const showOrderingVersion = orderingOnlyFeatureEnabled && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderingOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;
  const { name } = estimate;

  const total = () => {
    // should be - discounts
    return showPricesInEstimation ? (
      <Body
        size={300}
        gridColumn={5}
        padding="1.25em 0"
        color="neutral.600"
        display="flex"
        justifyContent="flex-end"
        textOverflow="ellipsis"
        overflow="hidden"
        as="span"
        data-test-id="estimateGroup-Total"
      >
        <FormattedNumber value={estimateTotal} format="$0,0[.]00" />
      </Body>
    ) : null;
  };

  return (
    <>
      <Body
        size={300}
        gridColumn={2}
        paddingY={400}
        color="neutral.600"
        textOverflow="ellipsis"
        overflow="hidden"
        fontWeight={700}
        as="span"
      >
        {name}
      </Body>
      <Body
        size={300}
        gridColumn={3}
        paddingY={400}
        color="neutral.600"
        textOverflow="ellipsis"
        overflow="hidden"
        as="span"
        data-test-id="estimateTrade"
      >
        {formatTradesStringForEstimates([estimate])}
      </Body>
      {total()}

      {isSold && !showOrderingOrMaterialListVersion && (
        <Box data-test-id="is-sold-badge" gridColumn={6}>
          <Badge colorScheme="success">Sold</Badge>
        </Box>
      )}
    </>
  );
};
