import { Heading, Panel, Box } from '@hover/blueprint';

import {
  MatchingGroups,
  VariationMatchingToggle,
} from 'src/components/VariationMatchingToggle';
import { ESTIMATE_GROUP_FOR_VARIATION_SELECTION } from 'src/features/exteriorEstimator/apis/queries';
import { useQueryEhi } from 'src/hooks';

type Props = {
  estimateName: string;
  estimateGroupId: string;
};

export const VariationsHeader: React.FC<Props> = ({
  estimateName,
  estimateGroupId,
}) => {
  const { data } = useQueryEhi(ESTIMATE_GROUP_FOR_VARIATION_SELECTION, {
    variables: { id: estimateGroupId },
  });

  const enableVariationMatching =
    data?.estimationEstimateGroup?.enableVariationMatching;

  return (
    <Panel
      padding={500}
      width={1}
      boxShadow="0px 7px 4px -4px rgba(0, 0, 0, 0.1)"
      flexDirection={{ base: 'column', desktop: 'row' }}
      justifyContent="space-between"
    >
      <Box>
        <Heading
          data-test-id="variationsHeader"
          size={{ base: 200, tablet: 500 }}
          whiteSpace="nowrap"
        >
          Select variations for {estimateName}
        </Heading>
      </Box>
      <Box marginBottom={300}>
        <VariationMatchingToggle
          type={MatchingGroups.SELECT_VARIATIONS}
          id={estimateGroupId as string}
          enableVariationMatching={enableVariationMatching as boolean}
        />
      </Box>
    </Panel>
  );
};
