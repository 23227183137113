/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from '@apollo/client';
import { Box, Icon, Body, Link, Button, Heading } from '@hover/blueprint';
import { iCheckCircle } from '@hover/icons';
import { push } from 'connected-react-router';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { distributionOrderForSummary as DistributionOrderType } from 'src/features/projectManagement/apis/graphql/queries/types/distributionOrderForSummary';
import styled from 'styled-components';

import VerticalStepper from 'src/components/VerticalStepper';
import { ESTIMATOR_PRODUCTION } from 'src/features/exteriorEstimator/constants/urls';
import { DISTRIBUTION_ORDER_FOR_SUMMARY } from 'src/features/projectManagement/apis/graphql/queries/orders';
import { getParams } from 'src/features/projectManagement/redux/selectors';
import { RootState, RootAction } from 'src/types/reduxStore';

const Label = styled(Box)`
  color: ${({ theme }) => theme.colors.neutral600};
`;

const InfoBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral100};
  color: ${({ theme }) => theme.colors.neutral600};
`;

export const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  orgId: getParams(state).orgId,
  orderId: getParams(state).orderId,
  projectManagementOrderData:
    state.estimatorProductionTools.projectManagementOrderData,
});

export const mapDispatchToProps = (dispatch: Redux.Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pushRoute: push,
    },
    dispatch,
  );

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const OrderSummary: React.FC<ComponentProps> = (
  props: ComponentProps,
) => {
  const { orderId, pushRoute, jobId, orgId, projectManagementOrderData } =
    props;
  const { data } = useQuery<DistributionOrderType>(
    DISTRIBUTION_ORDER_FOR_SUMMARY,
    {
      variables: { id: orderId },
      skip: !!projectManagementOrderData.distributionOrder, // if distributionOrder already exists in redux, no need to query
    },
  );

  const { distributionOrder } = data ?? projectManagementOrderData;
  if (!distributionOrder) return <div>Loading…</div>;
  const {
    shippingAddressLine1,
    shippingAddressLine2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressCountry,
    shippingAddressPostalCode,
    purchaseOrderNumber,
    distributor: { displayName },
    branch,
    jobAccount,
  } = distributionOrder;
  const deliveryAddress = [
    shippingAddressLine1,
    shippingAddressLine2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressCountry,
    shippingAddressPostalCode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Box justifyContent="center">
      <Box flexDirection="row" margin={800} maxWidth="1000px">
        <Box flexDirection="column" width="80%" marginRight={600}>
          <Box>
            <Icon
              icon={iCheckCircle}
              color="success500"
              size="large"
              data-test-id="orderSummary-page"
              margin={0}
            />
          </Box>
          <Box data-test-id="displayName-text">
            <Heading size={400} mt={200} mb={600}>
              Order sent to {displayName}
            </Heading>
          </Box>
          <Box flexDirection="column" data-test-id="poNumber-text" mb={400}>
            <Label mb={200}>PO Number</Label>
            <Box>{purchaseOrderNumber}</Box>
          </Box>
          {branch?.name && (
            <Box
              mt={300}
              flexDirection="column"
              data-test-id="branchName-text"
              mb={400}
            >
              <Label mb={200}>Branch</Label>
              <Box>{branch?.name}</Box>
            </Box>
          )}
          {jobAccount?.displayName && (
            <Box
              mt={300}
              mb={400}
              flexDirection="column"
              data-test-id="jobAccountName-text"
            >
              <Label mb={200}>Job Account</Label>
              <Box>{jobAccount?.displayName}</Box>
            </Box>
          )}
          <Box
            mt={300}
            flexDirection="column"
            data-test-id="address-text"
            mb={400}
          >
            <Label mb={200}>Delivery address</Label>
            <Box data-test-id="orderSummaryAddress">{deliveryAddress}</Box>
          </Box>
          {distributionOrder?.notes?.length > 0 ? (
            <Box
              mt={300}
              flexDirection="column"
              data-test-id="notes-container"
              mb={400}
            >
              <Label mb={200}>Order Notes</Label>
              <Box data-test-id="notes-text">{distributionOrder.notes}</Box>
            </Box>
          ) : null}
          <Box mt={300} mb={400}>
            {!isNil(distributionOrder.url) ? (
              <Link href={distributionOrder.url}>Review Order</Link>
            ) : null}
          </Box>
          <InfoBox
            width="75%"
            padding={200}
            mb={600}
            mt={400}
            data-test-id="disclaimer-text"
          >
            <Body size={400} margin={0}>
              To view or make any changes to your order, please review order.
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              For any discrepancy between your HOVER and {displayName}'s
              submitted order please contact your {displayName} Sales Rep.
            </Body>
          </InfoBox>
          <Box>
            <Button
              onClick={() => {
                pushRoute(
                  `${ESTIMATOR_PRODUCTION}?jobId=${jobId}&orgId=${orgId}`,
                );
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
        <Box flexDirection="column">
          <VerticalStepper
            steps={[
              { text: 'Order submitted', completed: true },
              { text: 'Order confirmed', completed: true },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
