/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint no-console: 0 */
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createHashHistory, History } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import appSettings from 'src/appSettings';

import createRootReducer from './combineReducers';

type WindowType = { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose };

const sagaMonitor = {
  rootSagaStarted: (...args: any) => console.log('ROOT SAGA STARTED:', ...args),
  effectTriggered: (...args: any) => console.log('EFFECT TRIGGERED:', ...args),
  effectResolved: (...args: any) => console.log('EFFECT RESOLVED:', ...args),
  effectRejected: (...args: any) => console.warn('EFFECT REJECTED:', ...args),
  effectCancelled: (...args: any) => console.warn('EFFECT CANCELLED:', ...args),
  actionDispatched: (...args: any) =>
    console.log('ACTION DISPATCHED:', ...args),
};

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    Sentry.captureException(error);
    console.warn(sagaStack);
  },
  sagaMonitor: !!appSettings.ENABLE_SAGA_LOGGING ? sagaMonitor : undefined,
});

export const history: History = createHashHistory();

const routerMW = routerMiddleware(history);

const composeEnhancers =
  (window as WindowType).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = () => {
  return {
    ...createStore(
      createRootReducer(history),
      composeEnhancers(applyMiddleware(routerMW, sagaMiddleware)),
    ),
    runSaga: sagaMiddleware.run,
  };
};

export const store = configureStore();
