import { get } from 'lodash';

import { User } from 'src/types/actionTypes';
import { isMobileApp } from 'src/utils/BrowserUtils';

export function getCommonProps(userProfile: User) {
  const pageParams = window.location.hash.substr(
    window.location.hash.indexOf('?') + 1,
  );
  const pagePath = window.location.hash.substring(
    0,
    window.location.hash.indexOf('?') || undefined,
  );
  return {
    user_is_test: userProfile.isTestData,
    org_id: get(userProfile, 'orgs[0].id'),
    user_id: userProfile.id,
    org_name: get(userProfile, 'orgs[0].name'),
    user_email: userProfile.email,
    device_is_mobile_or_web: isMobileApp ? 'mobile' : 'web',
    device_OS: navigator.appVersion,
    partner_id: get(userProfile, 'orgs[0].partner.id'),
    partner_name: get(userProfile, 'orgs[0].partner.name'),
    path: window.location.pathname + pagePath,
    search: pageParams,
    active_commerce_product: get(userProfile, 'activeCommerceProduct'),
  };
}
