import { createReducer } from 'typesafe-actions';

import { productCatalogOrgSettings_productCatalogOrgSettings as ProductCatalogOrgSettings } from 'src/api/types/productCatalogOrgSettings';
import { projectManagementConfigOrgProposalSettingGlobal_projectManagementConfigOrgProposalSetting as ProjectManagementOrgProposalSettings } from 'src/api/types/projectManagementConfigOrgProposalSettingGlobal';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { getOrgSettings, getSubOrgs, setTradeTypes } from 'src/redux/actions';
import { OrgSettings, SubOrg } from 'src/types';

export type EhiOrgSettings =
  | (Omit<OrgSettings, '__typename'> &
      Omit<ProductCatalogOrgSettings, '__typename'> &
      Omit<ProjectManagementOrgProposalSettings, '__typename'>)
  | null;

export interface IInitialState {
  orgSettings: EhiOrgSettings | null;
  subOrgs: SubOrg[];
  tradeTypesSorted: TradeType[];
}

export const initialState: IInitialState = {
  orgSettings: null,
  subOrgs: [],
  tradeTypesSorted: [],
};

export const ehiReducer = createReducer(initialState)
  .handleAction(getOrgSettings.success, (state, action) => {
    return {
      ...state,
      orgSettings: action.payload.orgSettings,
    };
  })
  .handleAction(getSubOrgs.success, (state, action) => ({
    ...state,
    subOrgs: action.payload.orgs,
  }))
  .handleAction(setTradeTypes, (state, action) => ({
    ...state,
    tradeTypesSorted: action.payload,
  }));
