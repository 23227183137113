import { useCallback } from 'react';

import { productCatalogProductsByCategory } from 'src/api/types/productCatalogProductsByCategory';
import { GET_PRODUCTS_BY_CATEGORY } from 'src/features/settings/api/queries/calculationRules';
import { getPaginatedGql } from 'src/utils/PaginatedGqlUtils';

export function useProductsByCategory() {
  const fetchProductsByCategory = useCallback(
    async (orgId: string, productCatalogCategoryId: string) => {
      const res: productCatalogProductsByCategory | null =
        await getPaginatedGql<productCatalogProductsByCategory>({
          query: GET_PRODUCTS_BY_CATEGORY,
          variables: {
            orgId,
            productCatalogCategoryId,
          },
          typeName: 'productCatalogProducts',
        });
      const results = res!.productCatalogProducts.edges!.map((e) => e!.node!);

      return results;
    },
    [],
  );

  return { fetchProductsByCategory };
}
