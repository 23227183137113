import { pullAll, uniq } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { estimationConfigTemplate_estimationConfigTemplate_templateGroups_lineItems as LineItem } from 'src/api/types/estimationConfigTemplate';

export const useTemplateTristateCheckbox = (lineItems: LineItem[] | null) => {
  const { setValue, watch } = useFormContext();

  const templateLineItemIds = watch('templateLineItems');
  const groupLineItemIds = lineItems?.map(({ id }) => id.toString());
  const checkedLineItemIds = templateLineItemIds.filter(
    (lineItemId: string) => {
      return groupLineItemIds?.includes(lineItemId);
    },
  );

  const allChecked = groupLineItemIds?.length === checkedLineItemIds.length;
  const isIndeterminate =
    checkedLineItemIds.length !== 0 &&
    checkedLineItemIds.length !== groupLineItemIds?.length;

  const handleSelectAllNone = () => {
    if (checkedLineItemIds.length === 0 || isIndeterminate) {
      setValue(
        'templateLineItems',
        uniq([...(groupLineItemIds ?? []), ...templateLineItemIds]),
      );
    } else {
      setValue(
        'templateLineItems',
        pullAll(templateLineItemIds, groupLineItemIds),
      );
    }
  };

  return { allChecked, isIndeterminate, handleSelectAllNone };
};
