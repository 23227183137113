import { OrderCheckListItemsType } from 'src/features/projectManagement/types';

import ErrorMessage from './ErrorMessage';
import { ListItemErrorContainer, ListItemName } from './styled';

interface Props {
  listItem: OrderCheckListItemsType;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line camelcase
export const ListItemError_Deprecated: React.FC<Props> = ({
  listItem: { productName, errors },
}) => {
  return !errors?.length ? null : (
    <ListItemErrorContainer data-test-id="listItemError" marginBottom="20px">
      <ListItemName>{productName}</ListItemName>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {errors.map((error: any) => (
        <ErrorMessage key={JSON.stringify(error)} error={error} />
      ))}
    </ListItemErrorContainer>
  );
};
