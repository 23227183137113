import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
  EstimationDiscountCreate,
  EstimationDiscountDiscountTypeEnum,
} from 'src/api/graphql-global-types';
import { createDiscount as EstimationDiscountCreateMutation } from 'src/api/types/createDiscount';
import {
  GET_ESTIMATE_GROUP,
  CREATE_DISCOUNT,
} from 'src/features/exteriorEstimator/apis/queries';
import { PromotionModal } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/PromotionModal';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getEstimateGroupIdFromLocation,
  getActiveEstimates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getVariationsFilter } from 'src/redux/selectors';

type Props = {
  estimateTotal: number;
  closeModal: () => void;
};

export const CreatePromotionModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const id = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const activeEstimates = useSelector(getActiveEstimates);

  const [createPromotion] = useMutation<EstimationDiscountCreateMutation>(
    CREATE_DISCOUNT,
    {
      onCompleted: ({ estimationDiscountCreate }) => {
        if (estimationDiscountCreate?.discount) {
          dispatch(
            estimatorActions.receiveDiscount({
              discount: estimationDiscountCreate.discount,
            }),
          );
        }
        closeModal();
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP,
          variables: {
            id,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    },
  );

  const handleCreate = (discountAttributes: EstimationDiscountCreate) => {
    // applying a discount to a single template
    if (discountAttributes.estimationEstimateId) {
      createPromotion({
        variables: {
          discountAttributes,
        },
      });
    } else {
      // applying discount to the whole project
      activeEstimates.forEach((estimate) => {
        createPromotion({
          variables: {
            discountAttributes: {
              ...discountAttributes,
              estimationEstimateId: estimate.id.toString(),
            },
          },
        });
      });
    }
  };

  return (
    <PromotionModal
      closeModal={closeModal}
      percentValue=""
      discountType={EstimationDiscountDiscountTypeEnum.PERCENTAGE}
      name=""
      dollarValue=""
      createPromotion={handleCreate}
      displayVerb="Add"
    />
  );
};
