import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

import {
  NetworkRequestError,
  RequestError,
  TimeoutRequestError,
  BadRequestError,
  UnauthorizedRequestError,
  NotFoundRequestError,
  ForbiddenRequestError,
  InternalServerRequestError,
} from './errors';

interface Err extends Error {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class Request {
  static async send<T>(url: string, opts: AxiosRequestConfig) {
    const {
      timeout = 60 * 1000, // 60 second default timeout
      method = 'get',
      data,
      headers = {},
      responseType = 'json',
      withCredentials = true,
      ...rest
    } = opts;

    const options = {
      responseType,
      timeout,
      ...rest,
      method,
      withCredentials,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      data,
    };
    if (
      data &&
      (headers as any)['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      options.data = queryString.stringify(data);
    }
    let result;
    let err: Err | undefined;
    axios.defaults.withCredentials = true;

    try {
      switch (method) {
        case 'delete':
          result = await axios.delete<T>(url, options as any);
          break;
        case 'get':
          result = await axios.get<T>(url, options as any);
          break;
        case 'patch':
          result = await axios.patch<T>(url, options);
          break;
        case 'put':
          result = await axios.put<T>(url, options);
          break;
        case 'post':
          result = await axios.post<T>(url, options);
          break;
        default:
          result = await axios.get<T>(url, options as any);
      }
      return result;
    } catch (e: any) {
      if (e.request && e.request.status === 0) {
        err = new NetworkRequestError(e.message);
      } else if (e.code === 'ECONNABORTED') {
        err = new TimeoutRequestError(e.message);
      } else if (e.response && e.response.status >= 400) {
        switch (e.response.status) {
          case 400:
            err = new BadRequestError(e.message);
            break;
          case 401:
            err = new UnauthorizedRequestError(e.message);
            break;
          case 403:
            err = new ForbiddenRequestError(e.message);
            break;
          case 404:
            err = new NotFoundRequestError(e.message);
            break;
          case 500:
            err = new InternalServerRequestError(e.message);
            break;
          default:
            err = new RequestError(e.message);
            break;
        }
      } else {
        err = new RequestError(e.message);
      }
      err.response = e.response;
      err.request = e.request;
    }
    throw err;
  }

  static async delete<T>(url: string, opts?: AxiosRequestConfig) {
    return this.send<T>(url, { ...opts, method: 'delete' });
  }

  static async get<T>(url: string, opts?: AxiosRequestConfig) {
    return this.send<T>(url, { ...opts, method: 'get' });
  }

  static async patch<T>(url: string, opts?: AxiosRequestConfig) {
    return this.send<T>(url, { ...opts, method: 'patch' });
  }

  static async post<T>(url: string, opts?: AxiosRequestConfig) {
    return this.send<T>(url, { ...opts, method: 'post' });
  }

  static async put<T>(url: string, opts?: AxiosRequestConfig) {
    return this.send<T>(url, { ...opts, method: 'put' });
  }
}
