import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { all, call, takeLatest, select, put, delay } from 'redux-saga/effects';

import { getSuborgs } from 'src/features/estimatesAccess/components/utils/getSuborgs';
import {
  EstimatorProductionApi,
  GetSalesOpportunitiesData,
} from 'src/features/projectManagement/apis/estimatorProduction';
import * as EstimatorProductionActions from 'src/features/projectManagement/redux/actions';
import {
  createPdfSaga,
  handlePdfErrorSaga,
  handlePdfSuccessSaga,
} from 'src/features/projectManagement/redux/sagas/orderDocumentSagas';
import { getProductsFor } from 'src/features/projectManagement/redux/sagas/projectManagementListItemSagas';
import {
  getParams,
  getProductsMap,
} from 'src/features/projectManagement/redux/selectors';
import {
  ProductsMap,
  SalesOpportunityType,
  JobDetailsType,
  PollProjectManagementOrderDocumentsAction,
  OrderDocumentType,
} from 'src/features/projectManagement/types';
import {
  mapProductsToListItems,
  insertListItems,
  hydrateListItemsWithVariationPrice,
  buildPdfFileName,
} from 'src/features/projectManagement/utils/ProductionListUtils';
import { downloadPdf } from 'src/lib/download';
import {
  getSubOrgs as getSubOrgsAction,
  mobileDownloadPdf,
} from 'src/redux/actions';
import {
  getUserProfile,
  combineSubOrgsWithUserOrgs,
} from 'src/redux/selectors';
import { SubOrg } from 'src/types/actionTypes';

export * from 'src/features/projectManagement/redux/sagas/projectManagementListItemSagas';
export * from 'src/features/projectManagement/redux/sagas/orderDocumentSagas';

export function* getJobMeasurements(jobId: string) {
  try {
    return yield call(EstimatorProductionApi.getJobMeasurements, jobId);
  } catch (e) {
    return { data: {} };
  }
}

export function* getEstimateUser(userId: number | null) {
  try {
    return yield call(EstimatorProductionApi.getEstimateUser, userId);
  } catch (e) {
    return { data: {} };
  }
}

const getProductionListFromResp = (graphqlData: GetSalesOpportunitiesData) => {
  const opportunity = graphqlData.salesOpportunities[0];
  if (!opportunity) {
    return null;
  }
  return opportunity.productionList;
};

const getEstimate = (graphqlData: GetSalesOpportunitiesData) => {
  const opportunity = graphqlData.salesOpportunities[0];
  if (!opportunity) {
    return null;
  }
  return opportunity.soldEstimateGroup;
};

export function* hydrateListItemsWithProducts(
  salesOpportunities: SalesOpportunityType[],
) {
  const listItems = get(
    salesOpportunities,
    ['0', 'productionList', 'listItems'],
    [],
  );
  // Filters for only org variations if flag is enabled.
  const products = yield call(getProductsFor, listItems);
  if (!products) return [];

  const productsMap: ProductsMap = yield select(getProductsMap(products));
  const listItemsWithProduct = yield call(mapProductsToListItems, {
    listItems,
    productsMap,
  });
  return listItemsWithProduct;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleSalesOpportunityError(message: string, error?: any) {
  yield put(
    EstimatorProductionActions.updateErrorModal({
      isOpen: true,
      message,
    }),
  );
  yield put(EstimatorProductionActions.toggleShouldShowSpinner(false));
  yield put(EstimatorProductionActions.sagaError(message));
  Sentry.captureMessage(message);
  if (error) {
    Sentry.captureException(error);
  }
}

export function* initProductionManagementSaga() {
  yield put(EstimatorProductionActions.toggleShouldShowSpinner(true));

  const { jobId, orgId } = yield select(getParams);
  const {
    orgs: [{ id: currentOrgId }],
  } = yield select(getUserProfile);

  if (currentOrgId) {
    try {
      const orgs = yield call(getSuborgs, currentOrgId);
      yield put(getSubOrgsAction.success({ orgs }));
    } catch (error) {
      yield put(getSubOrgsAction.failure(error as any));
    }
  }

  try {
    const orgIdForQuery = Number(orgId ?? currentOrgId);
    const allOrgsWithAccess = yield select(combineSubOrgsWithUserOrgs);

    if (
      !allOrgsWithAccess
        .map((org: SubOrg) => Number(org.id))
        .includes(orgIdForQuery)
    ) {
      yield call(
        handleSalesOpportunityError,
        `Project for job ${jobId} has not yet been sold.`,
      );
      return;
    }

    const [
      {
        data: {
          jobs: { results: job },
        },
      },
      { data: jobMeasurements },
      { data: graphqlData },
    ]: [
      { data: JobDetailsType },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { data: any }, // JobMeasurementsType?
      { data: GetSalesOpportunitiesData },
    ] = yield all([
      call(EstimatorProductionApi.getJobDetails, jobId),
      call(getJobMeasurements, jobId),
      call(EstimatorProductionApi.getSalesOpportunities, orgIdForQuery, jobId),
    ]);

    if (!graphqlData) {
      yield call(
        handleSalesOpportunityError,
        `Cannot find a sales opportunity for job ${jobId}`,
      );
      return;
    }

    const jobDetails = job ? job[0] : null;
    const productionList = getProductionListFromResp(graphqlData);
    const estimate = getEstimate(graphqlData);

    if (!productionList || !estimate) {
      yield call(
        handleSalesOpportunityError,
        `Missing sold estimate for job ID ${jobId}`,
      );
      return;
    }

    const { salesOpportunities } = graphqlData;
    const listItemsWithProduct = yield call(
      hydrateListItemsWithProducts,
      salesOpportunities,
    );

    const { vendors } = graphqlData;
    const { userId } = estimate.estimates[0];

    const {
      data: { user },
    } = yield call(getEstimateUser, userId);

    const listItems = insertListItems(
      productionList?.listItems ?? [],
      listItemsWithProduct,
    );

    yield put(
      EstimatorProductionActions.getProductionDataEnd({
        jobDetails,
        jobMeasurements,
        estimateDetails: estimate,
        user,
        productionList: {
          ...productionList,
          listItems,
        },
        vendors,
        salesOpportunities,
      }),
    );
    yield put(EstimatorProductionActions.toggleShouldShowSpinner(false));
  } catch (error) {
    yield call(handleSalesOpportunityError, `An error has occured`, error);
  }
}

export function* pollProjectManagementOrderDocumentsSaga({
  payload,
}: PollProjectManagementOrderDocumentsAction) {
  const { jobId } = yield select(getParams);

  try {
    const {
      data: { projectManagementOrderDocuments },
    } = yield call(
      EstimatorProductionApi.projectManagementOrderDocuments,
      payload,
    );
    const orderDocument: OrderDocumentType =
      projectManagementOrderDocuments.find(
        (document: OrderDocumentType) =>
          document.id === payload.orderDocumentIds[0],
      );
    if (orderDocument.stateDeprecated !== 'complete') {
      yield delay(1500);
      yield put(
        EstimatorProductionActions.pollProjectManagementOrderDocuments(payload),
      );
    } else {
      yield put(EstimatorProductionActions.createPdf.success());
      yield put(
        EstimatorProductionActions.webDownloadPdf.request(orderDocument),
      );
      const filename = orderDocument.pdf?.filename;
      const name = buildPdfFileName({
        jobId,
        filename,
      });
      downloadPdf({ url: orderDocument?.pdf?.url ?? '', name });
      yield put(EstimatorProductionActions.webDownloadPdf.success());
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(EstimatorProductionActions.createPdf.failure(error));
  }
}

export function* estimatorProductionSagas() {
  yield all([
    takeLatest(
      EstimatorProductionActions.getProductionData,
      initProductionManagementSaga,
    ),
    takeLatest(
      EstimatorProductionActions.webDownloadPdf.failure,
      handlePdfErrorSaga,
    ),
    takeLatest(mobileDownloadPdf.success, handlePdfSuccessSaga),
    takeLatest(EstimatorProductionActions.createPdf.request, createPdfSaga),
    takeLatest(
      EstimatorProductionActions.pollProjectManagementOrderDocuments,
      pollProjectManagementOrderDocumentsSaga,
    ),
  ]);
}
