import {
  ListItemCreate as ListItemCreateParams,
  LineItemTypeEnum,
  QuantityUnitsEnum,
} from 'src/api/graphql-global-types';
import { Suggestion } from 'src/components/InputWithDropdownTypeahead';
import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';
import { UNITS_MAP } from 'src/utils/unitsMap';

import {
  VendorType,
  ProductSearchResult,
  ProductSearchDistributor,
} from '../../../types';
import { AddListItemFormModalState } from './Modal';

export const convertMeasurementUnitToQuantityUnit = (
  measurementUnit?: string,
) => {
  if (!measurementUnit) return null;
  switch (measurementUnit) {
    case 'ea':
      return QuantityUnitsEnum.EACH;
    case 'hr':
      return QuantityUnitsEnum.HOURS;
    case 'lf':
      return QuantityUnitsEnum.LINEAR_FEET;
    case 'pc':
      return QuantityUnitsEnum.PIECES;
    case 'sqft':
      return QuantityUnitsEnum.SQUARE_FEET;
    case 'cuft':
      return QuantityUnitsEnum.CUBIC_FEET;
    case 'sq':
      return QuantityUnitsEnum.SQUARES;
    case 'bg':
      return QuantityUnitsEnum.BAGS;
    case 'bx':
      return QuantityUnitsEnum.BOXES;
    case 'bdl':
      return QuantityUnitsEnum.BUNDLES;
    case 'rl':
      return QuantityUnitsEnum.ROLLS;
    case 'tb':
      return QuantityUnitsEnum.TUBES;
    default:
      return null;
  }
};

export const calculateTotal = (
  quantity: number | null,
  unitPrice: number | null,
) => {
  if (quantity === null || unitPrice === null) {
    return 0;
  }
  return quantity * unitPrice;
};

export const getUnits = () =>
  Array.from(new Set(Object.values(UNITS_MAP))).map((unit) => ({
    value: unit,
  }));

export const buildSearchResults = (
  results: ProductSearchResult[],
  distributor: ProductSearchDistributor,
  supportsProductOrdering: boolean,
) => {
  const searchSuggestions: Suggestion[] = [];
  results.forEach((result: ProductSearchResult) => {
    const suggestion: Suggestion = {
      text: result.name,
      id: result.id.toString(),
    };
    if (supportsProductOrdering)
      suggestion.icon = distributor.logo?.redirectUrl;
    searchSuggestions.push(suggestion);
  });
  return searchSuggestions;
};

export const getVendors = (vendors: VendorType[] | undefined) => {
  if (!vendors) {
    return [];
  }
  return vendors.map((v) => ({ value: v.vendorName }));
};

export const getVendorId = (
  vendors: VendorType[] | undefined,
  vendor: string | null | undefined,
) => {
  const vendorForList =
    vendor && vendors ? vendors.find((v) => v.vendorName === vendor) : null;
  return vendorForList ? vendorForList.id : undefined;
};

export const getParamsForCreate = (
  createState: AddListItemFormModalState,
  vendors: VendorType[] | undefined,
): ListItemCreateParams | undefined => {
  const {
    type,
    quantity,
    unitPrice,
    quantityUnit,
    itemName,
    vendor,
    color,
    skuId,
    tradeType,
    externalProductId,
    externalVariationId,
    userSetCustomColor,
    userSetCustomSku,
    userSetCustomUnitCost,
  } = createState;

  if (!type || !itemName) {
    return undefined;
  }

  return {
    type: type.toUpperCase() as LineItemTypeEnum,
    name: itemName,
    vendorId: getVendorId(vendors, vendor),
    quantity: quantity || undefined,
    unitCost: unitPrice || undefined,
    measurementUnits: quantityUnit || undefined,
    color: color || undefined,
    sku: skuId || undefined,
    tradeType: tradeType || undefined,
    externalVariationId,
    externalProductId,
    quantityUnits: convertMeasurementUnitToQuantityUnit(quantityUnit),
    userSetCustomColor,
    userSetCustomSku,
    userSetCustomUnitCost,
  };
};

// soon to be deprecated
const fetchPCExternalPricingForVariation = async ({
  externalVariationId,
  distributorId = '',
  accountId = '',
  distributionBranchId,
  orgId = '',
  quantityUnits = '',
}: {
  externalVariationId: string;
  distributorId?: string;
  accountId?: string;
  distributionBranchId?: string;
  orgId?: string;
  quantityUnits?: string;
}) => {
  let defaultSkuId = '';
  let unitPrice = null;

  try {
    const {
      data: {
        productCatalogDistributorVariationAttributes: { sku, unitCost },
      },
    } = await EstimatorProductionApi.productCatalogVariationAttributes({
      accountId,
      distributorId,
      lineItemAttributes: {
        variationId: externalVariationId,
        quantityUnits,
      },
      distributionBranchId,
      orgId,
    });
    defaultSkuId = sku;
    unitPrice = unitCost;
  } catch (error) {
    // NO OP, use default/null values
    console.log(
      `Error fetching List Item Vendor  attributes for distributorId: ${distributorId} and variation ${externalVariationId}`,
    );
  }
  return { defaultSkuId, unitPrice };
};

export const fetchExternalPricingForVariation = async (
  externalVariationId: string,
  vendor?: VendorType | null,
  orgId?: string,
  quantityUnits?: string,
) =>
  fetchPCExternalPricingForVariation({
    externalVariationId,
    distributorId: vendor?.distributorId ?? undefined,
    accountId: vendor?.accountId ?? undefined,
    distributionBranchId: vendor?.branch?.id ?? undefined,
    orgId,
    quantityUnits,
  });
