import { useState } from 'react';

import { Box, Loader, Table, Tbody, Thead, Tr, Th } from '@hover/blueprint';

import {
  estimationConfigLineItemsSettings as Data,
  estimationConfigLineItemsSettings_estimationConfigLineItems_nodes as LineItemType,
} from 'src/api/types/estimationConfigLineItemsSettings';
import { NoResultsFound } from 'src/components/NoResultsFound';
import { LineItem } from 'src/features/settings/components/LineItems/LineItem';
import { VersionHistoryModal } from 'src/features/settings/components/LineItems/VersionHistoryModal';

interface ListItemsContentProps {
  data: Data;
  loading?: boolean;
  search?: string | null;
}

export const LineItemsContent: React.FC<ListItemsContentProps> = ({
  loading,
  data,
  search,
}) => {
  const [currentLineItem, setCurrentLineItem] = useState<LineItemType | null>(
    null,
  );
  const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] =
    useState(false);
  const openVersionHistoryModal = (lineItem: LineItemType) => {
    setCurrentLineItem(lineItem);
    setIsVersionHistoryModalOpen(true);
  };

  const closeVersionHistoryModal = () => setIsVersionHistoryModalOpen(false);

  if (loading) return <Loader data-test-id="LineItemsSpinner" size="large" />;

  const {
    estimationConfigLineItems: { nodes },
  } = data;

  const renderListItems = () => {
    return (
      <Box flexDirection="column" width="100%">
        <Table size="small">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Trade</Th>
              <Th>Template</Th>
              <Th isNumeric>
                <Box display="block" marginX={400}>
                  Cost ($)
                </Box>
              </Th>
              <Th>Unit</Th>
              <Th>Last update</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody data-test-id="ListItemTable">
            {nodes?.map(
              (lineItem) =>
                lineItem && (
                  <LineItem
                    key={lineItem.id}
                    lineItem={lineItem}
                    openVersionHistoryModal={openVersionHistoryModal}
                  />
                ),
            )}
          </Tbody>
        </Table>
        {isVersionHistoryModalOpen && (
          <VersionHistoryModal
            onClose={closeVersionHistoryModal}
            lineItem={currentLineItem}
          />
        )}
      </Box>
    );
  };

  return (
    <Box flexDirection="column" width="100%">
      {nodes && nodes.length > 0 ? (
        renderListItems()
      ) : (
        <NoResultsFound searchTerm={search} />
      )}
    </Box>
  );
};
