import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const esignReducer = createReducer(initialState)
  .handleAction(estimatorActions.embeddedESign.request, (state) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      isLoadingProposals: true,
    },
  }))
  .handleAction(
    estimatorActions.embeddedESign.success,
    (state, { payload }) => {
      return {
        ...state,
        proposalsData: {
          ...state.proposalsData,
          signatureRequest: {
            isRequested: true,
            requestId: payload.id,
          },
        },
      };
    },
  )
  .handleAction(
    estimatorActions.embeddedESign.failure,
    (state, { payload }) => {
      return {
        ...state,
        proposalsData: {
          ...state.proposalsData,
          error: payload,
          isLoadingProposals: false,
          signatureRequest: {},
        },
      };
    },
  )
  .handleAction(estimatorActions.emailESign.request, (state) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      isLoadingProposals: true,
    },
  }))
  .handleAction(estimatorActions.emailESign.success, (state, { payload }) => {
    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        signatureRequest: {
          ...state.proposalsData.signatureRequest,
          ...payload,
        },
        isLoadingProposals: false,
      },
    };
  })
  .handleAction(estimatorActions.emailESign.failure, (state, { payload }) => {
    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        error: payload,
        isLoadingProposals: false,
        signatureRequest: {},
      },
    };
  })
  .handleAction(estimatorActions.embeddedSignUrl.success, (state) => {
    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        signatureRequest: {},
        isLoadingProposals: false,
      },
    };
  })
  .handleAction(
    estimatorActions.embeddedSignUrl.failure,
    (state, { payload }) => {
      return {
        ...state,
        proposalsData: {
          ...state.proposalsData,
          error: payload,
          isLoadingProposals: false,
        },
      };
    },
  );
