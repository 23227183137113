import { gql } from '@apollo/client';

export const GET_SALES_OPPORTUNITIES = gql`
  query getSalesOpportunitiesForAccess(
    $orgId: ID!
    $jobId: ID
    $active: Boolean = true
  ) {
    salesOpportunities(orgId: $orgId, jobId: $jobId) {
      id
      jobId
      orgId
      soldEstimateGroup {
        id
        state
        createdAt
        updatedAt
        orgId
        jobId
        totalCost
        totalPrice
        estimates(active: $active) {
          name
          tradeType
          userId
          active
          basePrice
          lineItems {
            price
          }
          id
          discounts {
            discountType
            value
          }
        }
        salesOpportunity {
          id
          soldEstimateGroupId
        }
      }
    }
  }
`;

export const GET_ESTIMATE_GROUPS = gql`
  query estimationEstimateGroups(
    $jobId: ID
    $orgId: ID
    $endCursor: String
    $salesOpportunityId: ID
    $active: Boolean = true
  ) {
    estimationEstimateGroups(
      jobId: $jobId
      orgId: $orgId
      first: 3
      after: $endCursor
      salesOpportunityId: $salesOpportunityId
    ) {
      nodes {
        id
      }
      edges {
        node {
          id
          state
          createdAt
          updatedAt
          orgId
          jobId
          totalCost
          totalPrice
          estimates(active: $active) {
            name
            tradeType
            userId
            active
            basePrice
            lineItems {
              price
            }
            template {
              id
              showPricesInEstimation
            }
            id
            discounts {
              discountType
              value
            }
          }
          salesOpportunity {
            id
            soldEstimateGroupId
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_PROPOSAL_FOR_ESTIMATE_GROUP = gql`
  query projectManagementProposalDocuments($estimateGroupId: ID!) {
    projectManagementProposalDocuments(
      estimateGroupId: $estimateGroupId
      last: 1
      state: COMPLETE
    ) {
      nodes {
        id
      }
    }
  }
`;
