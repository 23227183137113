import {
  DistributionDeliveryTimeEnum,
  DistributionDeliveryMethodEnum,
  DistributionDeliveryTypeEnum,
} from 'src/api/graphql-global-types';
import {
  customerNameValueField,
  phoneNumberValueField,
  poNumberValueField,
  poNumberErrorField,
  addressLineOneValueField,
  addressLineOneErrorField,
  addressLineTwoValueField,
  cityValueField,
  cityErrorField,
  zipCodeValueField,
  zipCodeErrorField,
  stateProvinceValueField,
  stateProvinceErrorField,
  deliveryMethodValueField,
  deliveryMethodErrorField,
  deliveryDateValueField,
  deliveryDateErrorField,
  deliveryTimeValueField,
  deliveryTimeErrorField,
} from 'src/features/projectManagement/constants';

export type { distributorCapabilities as DistributorCapabilities } from 'src/api/types/distributorCapabilities';
export {
  DistributionDeliveryTimeEnum,
  DistributionDeliveryMethodEnum,
  DistributionDeliveryTypeEnum,
};

export interface BillingFormValues {
  errors?: {
    jobAccountInvalid?: boolean;
    jobNameInvalid?: boolean;
  };
  values?: {
    jobAccount?: string;
    jobName?: string;
  };
}

export interface OrderDetailsFormType {
  errors?: {
    [poNumberErrorField]?: boolean;
    [addressLineOneErrorField]?: boolean;
    [cityErrorField]?: boolean;
    [zipCodeErrorField]?: boolean;
    [stateProvinceErrorField]?: boolean;
    [deliveryMethodErrorField]?: boolean;
    [deliveryDateErrorField]?: boolean;
    [deliveryTimeErrorField]?: boolean;
  };
  values?: {
    [poNumberValueField]?: string | null;
    [customerNameValueField]?: string | null;
    [phoneNumberValueField]?: string | null;
    [addressLineOneValueField]?: string | null;
    [addressLineTwoValueField]?: string | null;
    [cityValueField]?: string | null;
    [zipCodeValueField]?: string | null;
    [stateProvinceValueField]?: string | null;
    [deliveryMethodValueField]?: DistributionDeliveryMethodEnum | null;
    [deliveryDateValueField]?: Date | null;
    [deliveryTimeValueField]?: DistributionDeliveryTimeEnum | null;
    deliveryType?: DistributionDeliveryTypeEnum;
    notes?: string | null;
  };
}
