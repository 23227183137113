import { ApolloProvider } from '@apollo/client';
import { BlueprintProvider } from '@hover/blueprint';
import { BreakerProvider, OverridePanel } from '@hover/breaker-react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';

import appSettings from 'src/appSettings';
import { GraphqlClient } from 'src/lib/GraphqlClient';
import theme from 'src/lib/theme';
import { rootSaga } from 'src/redux/sagas';
import { store, history } from 'src/store/configureStore';

import './index.scss';

import { App } from './App';
import { SupplierStatusProvider } from './components/SupplierStatusProvider';
import { shouldUseBreaker } from './lib/FeatureFlag';

store.runSaga(rootSaga);

export const Providers = () => {
  return (
    <BreakerProvider config={{ service: 'ehi-frontend' }}>
      <IntercomProvider
        autoBoot
        appId={appSettings.INTERCOM_APP_ID}
        autoBootProps={{ hideDefaultLauncher: true }}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={GraphqlClient}>
              <ConnectedRouter history={history}>
                <BlueprintProvider includeFonts>
                  <SupplierStatusProvider>
                    <App />
                    {shouldUseBreaker() && <OverridePanel />}
                  </SupplierStatusProvider>
                </BlueprintProvider>
              </ConnectedRouter>
            </ApolloProvider>
          </ThemeProvider>
        </Provider>
      </IntercomProvider>
    </BreakerProvider>
  );
};
