import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { omit } from 'lodash';

import { InputContainer, StyledTextInput } from './StyledFormattedInput';

interface Props {
  value: string;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  margin?: React.CSSProperties['margin'];
  width?: React.CSSProperties['width'];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  validators?: Function[];
  'data-test-id'?: string;
}

export class DumbInput extends PureComponent<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> {
  @autobind
  isValidInput(value: string) {
    const { validators } = this.props;
    if (!validators || !validators.length) return true;
    return validators
      .map((validator) => validator(value))
      .every((validationResult) => !!validationResult);
  }

  @autobind
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    if (this.isValidInput(event.currentTarget.value)) {
      onChange(event);
    } else {
      // flash input border red for 1 sec and then transition out to default border color
      // also flash banner at bottom with error message
    }
  }

  public render() {
    const { handleBlur, value, width, margin, ...rest } = this.props;
    const inputProps = omit(rest, [
      'value',
      'validators',
      'type',
      'onChange',
      'textAlign',
      'data-test-id',
    ]);

    return (
      <InputContainer width={width}>
        <StyledTextInput
          type="text"
          onChange={this.handleChange}
          onBlur={handleBlur}
          value={value || ''}
          width={width}
          margin={margin}
          data-test-id={rest['data-test-id']}
          {...inputProps}
        />
      </InputContainer>
    );
  }
}
