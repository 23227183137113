import { ApolloClient } from '@apollo/client';

import type { distributionOrderCheck } from 'src/api/types/distributionOrderCheck';
import {
  GET_PRODUCTION_LIST,
  ORDER_CHECK,
} from 'src/features/project/apis/graphql/queries/queries';

export const getProductionList = (
  apolloClient: ApolloClient<object>,
  orgId: string,
  jobId: number,
) => {
  const productionList = apolloClient.readQuery({
    query: GET_PRODUCTION_LIST,
    variables: {
      orgId,
      jobId,
    },
  });
  return productionList;
};

export const getDistributionOrderCheck = (
  apolloClient: ApolloClient<object>,
  orderCheckId: string,
) => {
  return apolloClient.readQuery<distributionOrderCheck>({
    query: ORDER_CHECK,
    variables: {
      id: orderCheckId,
    },
  });
};
