// @tolicodes  - jest doesn't play nice with extended errors
// there is a very strange jest issue, when importing
// an extended class from another file, and also running --coverage (IMPORTANT,
// yeah I know wtf), the class will be an instance of the base class
// (in this case `Error` instead of `NotFoundRequestError`). So we have to
// do this workaround
// https://github.com/babel/babel/issues/4485#issuecomment-315569892
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-classes-per-file
function fixJestTransformIssue(ClassName) {
  this.constructor = ClassName;
  // eslint-disable-next-line no-proto
  this.__proto__ = ClassName.prototype;
}

class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;

    fixJestTransformIssue.call(this, ExtendableError);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class RequestError extends ExtendableError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, RequestError);
  }
}
export class TimeoutRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, TimeoutRequestError);
  }
}
export class BadRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, BadRequestError);
  }
}
export class NotFoundRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, NotFoundRequestError);
  }
}
export class UnauthorizedRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, UnauthorizedRequestError);
  }
}
export class ForbiddenRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, ForbiddenRequestError);
  }
}
export class InternalServerRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, InternalServerRequestError);
  }
}
export class NetworkRequestError extends RequestError {
  constructor(...args) {
    super(...args);
    fixJestTransformIssue.call(this, NetworkRequestError);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default {
  RequestError,
  NetworkRequestError,
  TimeoutRequestError,
  BadRequestError,
  NotFoundRequestError,
  UnauthorizedRequestError,
  ForbiddenRequestError,
  InternalServerRequestError,
};
