import { useMemo } from 'react';

import { Box } from '@hover/blueprint';
import { toNumber } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { FACADE_TYPES } from 'src/features/exteriorEstimator/constants/sidingConstants';
import {
  getRoofFacetInputs,
  getRoofLineSegmentInputs,
  getSidingFacetInputs,
  getSidingLineSegmentInputs,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { QuestionResponses, Input } from 'src/features/exteriorEstimator/types';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';

import { TilesByType } from './TilesByType';

type Props = {
  trade: TradeTypeEnum.SIDING | TradeTypeEnum.ROOF;
  updateAnswers: (responses: QuestionResponses) => void;
};

export const SelectionTiles: React.FC<Props> = ({ trade, updateAnswers }) => {
  const sidingFacetInputs = useSelector(getSidingFacetInputs);
  const roofFacetInputs = useSelector(getRoofFacetInputs);
  const roofLineSegmentInputs = useSelector(getRoofLineSegmentInputs);
  const sidingLineSegmentInputs = useSelector(getSidingLineSegmentInputs);

  const sidingInputsByType = useMemo(
    () =>
      sidingFacetInputs?.reduce<{ [facadeType: string]: Input[] }>(
        (acc, question) => {
          const facadeType =
            FACADE_TYPES[question.name?.split('-').shift() ?? ''];

          if (!acc[facadeType]) {
            acc[facadeType] = [question];
          } else {
            acc[facadeType].push(question);
            acc[facadeType].sort((a, b) => {
              const aSortOrder = toNumber(a.name?.split('-').pop());
              const bSortOrder = toNumber(b.name?.split('-').pop());
              return aSortOrder - bSortOrder;
            });
          }
          return acc;
        },
        {},
      ),
    [sidingFacetInputs],
  );

  const partialRoofQuestionsByPitch =
    PartialRoofUtils.organizeByPitch(roofFacetInputs);

  if (trade === TradeTypeEnum.SIDING && !sidingInputsByType) return null;

  if (trade === TradeTypeEnum.ROOF && !partialRoofQuestionsByPitch) return null;

  return (
    <Box flex={1} overflowY="scroll" flexDirection="column" padding="none">
      {trade === TradeTypeEnum.SIDING &&
        !!sidingInputsByType &&
        Object.entries(sidingInputsByType).map(([type, questions]) => (
          <TilesByType
            grouping={type}
            questions={questions}
            key={type}
            updateAnswers={updateAnswers}
            trade={trade}
            lineSegmentInputs={sidingLineSegmentInputs}
          />
        ))}
      {trade === TradeTypeEnum.ROOF && !!partialRoofQuestionsByPitch && (
        <>
          {Object.entries(partialRoofQuestionsByPitch).map(
            ([pitch, questions]) => (
              <TilesByType
                grouping={pitch}
                questions={questions}
                key={pitch}
                updateAnswers={updateAnswers}
                trade={trade}
                lineSegmentInputs={roofLineSegmentInputs}
              />
            ),
          )}
        </>
      )}
    </Box>
  );
};
