import { Box, Heading, Body } from '@hover/blueprint';

export const TemplatesWelcome = () => (
  <Box
    marginBottom={400}
    flexDirection="column"
    borderColor="neutral300"
    borderBottomStyle="solid"
    borderBottomWidth="1px"
  >
    <Heading fontWeight="bold" size={300}>
      Welcome to HOVER’s Bill of Materials workflow.
    </Heading>
    <Body size={300} fontWeight="bold" color="neutral600" marginTop={0}>
      In this workflow, you can choose from 3 generic pre-built roofing
      templates to create a bill of materials within minutes! Below are a few
      tips to get you started off.
    </Body>

    <Body size={300} color="neutral600" marginTop={0}>
      Step 1: Become familiar with the materials and packaging units in the
      templates.
      <br />
      Step 2: You can edit line items within the template by selecting the ‘edit
      button’ on the right column within the template. Add, remove, or edit line
      items as you see fit.
      <br />
      Step 3: If you’d like to + Add a Line Item to the existing template,
      please reach out directly to direct.ordering@hover.to and one of our
      dedicated teammates will be happy to assist.
      <br />
      Step 4: If you scroll down to the bottom of this template page, you’ll
      have the ability to add another generic template.
      <br />
      Step 5: Once you are ready to roll select the Save and Continue button to
      start building out your bill of materials
    </Body>

    <Body size={300} fontWeight="bold" color="neutral600" marginTop={0}>
      You can edit these at any time in the Edit Templates tab of the toolbar
      above.
    </Body>
  </Box>
);
