import { useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  OrderModalStates,
  OrderModalTitles,
} from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import { areAnyOrderDetailsInputsInvalid } from 'src/features/projectManagement/redux/selectors';
import { OrderModalStateStrings } from 'src/features/projectManagement/types';
import { getUserOrgId } from 'src/redux/selectors';
import { RootAction, RootState } from 'src/types/reduxStore';

import ActionButton from './components/ActionButton';
import CancelButton from './components/CancelButton';
import OrderModalContent from './Content';
import { Modal, contentStyle, footerStyle } from './styled';

export const mapStateToProps = (state: RootState) => ({
  shouldShowOrderModal: state.estimatorProductionTools.shouldShowOrderModal,
  projectManagementOrderData:
    state.estimatorProductionTools.projectManagementOrderData,
  shouldDisableActionButton: areAnyOrderDetailsInputsInvalid(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      submitAndPollDistributionOrder: actions.submitAndPollDistributionOrder,
      toggleOrderModal: actions.toggleOrderModal,
      startOrderFlow: actions.startOrderFlow,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const OrderModalERPComponent: React.FC<Props> = ({
  shouldShowOrderModal,
  projectManagementOrderData: { orderModalState },
  submitAndPollDistributionOrder,
  startOrderFlow,
  toggleOrderModal,
  shouldDisableActionButton,
}) => {
  useEffect(() => {
    return () => toggleOrderModal({ show: false });
  }, [toggleOrderModal]);

  const { getValues } = useFormContext();
  const orgId = useSelector(getUserOrgId);

  const handleActionClick = useCallback(() => {
    const {
      // these values are being retrieved from the form state
      distributionBranchId = '',
      distributionJobAccountId = '',
    } = getValues();

    switch (orderModalState) {
      case OrderModalStates.OrderErrors:
        return (
          toggleOrderModal({ show: false }) &&
          submitAndPollDistributionOrder({
            distributionBranchId,
            distributionJobAccountId,
            orgId,
          })
        );

      default:
        return startOrderFlow({
          distributionBranchId,
          distributionJobAccountId,
          orgId,
        });
    }
  }, [
    orderModalState,
    toggleOrderModal,
    submitAndPollDistributionOrder,
    startOrderFlow,
    getValues,
  ]);

  const handleCancelClick = useCallback(
    () => toggleOrderModal({ show: false }),
    [toggleOrderModal],
  );

  return (
    <Modal
      data-test-id="orderModal"
      isOpen={shouldShowOrderModal}
      title={OrderModalTitles[orderModalState as OrderModalStateStrings]}
      contentStyle={contentStyle}
      textAlign="left"
      lineHeight="23px"
      padding="32px"
      justifyFooter="space-between"
      footerStyle={footerStyle}
      cancel={
        <CancelButton
          orderModalState={orderModalState}
          handleClick={handleCancelClick}
        />
      }
      action={
        <ActionButton
          isDisabled={shouldDisableActionButton}
          orderModalState={orderModalState}
          handleClick={handleActionClick}
        />
      }
    >
      <OrderModalContent orderModalState={orderModalState} />
    </Modal>
  );
};

export const OrderModalERP = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderModalERPComponent);
