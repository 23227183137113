import { useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { useDispatch, useSelector } from 'react-redux';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type { projectManagementListItemCreate_projectManagementListItemCreate as ProjectManagementListItemCreate } from 'src/api/types/projectManagementListItemCreate';
import type { projectManagementListItemUpdate_projectManagementListItemUpdate as ProjectManagementListItemUpdate } from 'src/api/types/projectManagementListItemUpdate';
import type {
  projectManagementProductionList as ProductionList,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
  projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup,
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
} from 'src/api/types/projectManagementProductionList';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import {
  GET_PRODUCTION_LIST,
  UPDATE_LIST_ITEM,
  CREATE_LIST_ITEM,
} from 'src/features/project/apis/graphql/queries/queries';
import type { AddEditFields } from 'src/features/project/components/common/AddEditMaterialModal';
import { AddEditMaterialModal } from 'src/features/project/components/common/AddEditMaterialModal';
import { Attributes } from 'src/features/project/components/OrderDetail/OrderDetailContent';
import { DistributorSelectModal } from 'src/features/project/components/ProjectScope/DistributorSelectModal';
import { DownloadPdfModal } from 'src/features/project/components/ProjectScope/DownloadPdfModal';
import { CUSTOM_VARIANT_COLOR } from 'src/features/project/components/ProjectScope/EditableVariationSelection';
import { ProjectDetails } from 'src/features/project/components/ProjectScope/ProjectDetails/index';
import {
  updateDistributor,
  updateSelectedListItems,
  toggleSelectedItem,
} from 'src/features/project/redux/actions';
import { getSelectedListItemIds } from 'src/features/project/redux/selectors/projectSelectors';
import {
  convertMeasurementUnitToQuantityUnit,
  getListItemsByTypeAndTrade,
  getListItemsByTypeAndTradeIds,
} from 'src/features/project/util/utils';
import {
  useToastEhi,
  ToastStatusEnum,
  useTracking,
  usePrevious,
} from 'src/hooks';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

import { useAddMaterialModal } from './hooks/useAddMaterialModal';
import { ProjectScopeContentTable } from './ProjectScopeContentTable';
import { ProjectScopeHeader } from './ProjectScopeHeader';
import { ProjectScopeLoader } from './ProjectScopeLoader';

type ProjectScopeContentProps = {
  data?: ProductionList;
  estimateGroup: EstimateGroup;
  loading: boolean;
  jobId: number;
  orgId: string;
  distributors?: Distributor[];
  enableInlineEditingV2: boolean;
  showDownloadPdfModal: boolean;
  downloadPdfLineItemType: LineItemTypeEnum | null;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  closeDownloadPdfModal: () => void;
  showDistributorSelectModal: boolean;
  closeDistributorSelectModal: () => void;
  setDistributor: (distributor: Distributor | null) => void;
  selectDistributor: () => void;
};

const enum TOAST_IDS {
  UPDATE_LIST_ITEM_TOAST,
}

export const ProjectScopeContent: React.FC<ProjectScopeContentProps> = ({
  data,
  estimateGroup,
  loading,
  jobId,
  orgId,
  distributors,
  enableInlineEditingV2,
  showDownloadPdfModal,
  downloadPdfLineItemType,
  openDownloadPdfModal,
  closeDownloadPdfModal,
  showDistributorSelectModal,
  closeDistributorSelectModal,
  setDistributor,
  selectDistributor,
}) => {
  const toast = useToastEhi();
  const dispatch = useDispatch();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const job: Job = estimateGroup?.salesOpportunity?.job ?? {};
  const projectManagementProductionList = data?.projectManagementProductionList;
  const listItems = data?.projectManagementProductionList.listItems;
  const productionListId = projectManagementProductionList?.id;
  const selectedListItemIds = useSelector(getSelectedListItemIds);
  const previousProductionListId = usePrevious(productionListId);

  // Group listItems by type (MATERIALS, LABOR, OTHER), and then by trade (ROOF, GUTTER, etc.).
  const listItemsByTypeAndTrade = useMemo(
    () => getListItemsByTypeAndTrade(listItems),
    [listItems],
  );

  const trades = useMemo(
    () => [
      // Extract the trade types from all of the list item groups.
      ...new Set(
        Object.values(listItemsByTypeAndTrade).reduce((acc, obj) => {
          return acc.concat(Object.keys(obj) as TradeTypeEnum[]);
        }, [] as TradeTypeEnum[]),
      ),
    ],
    [listItemsByTypeAndTrade],
  );

  const {
    showAddEditMaterialModal,
    editingListItem,
    setEditingListItem,
    closeAddEditMaterialModal,
    openAddEditMaterialModal,
  } = useAddMaterialModal();

  /* Clear distributor value in redux when loading/returning to this page. */
  useEffect(() => {
    dispatch(updateDistributor(null));
  }, [dispatch]);

  const onError = () => {
    const listItem = editingListItem?.listItem;
    toast({
      id: TOAST_IDS.UPDATE_LIST_ITEM_TOAST,
      description: sentenceCase(
        `Error ${listItem ? 'updating' : 'adding'} ${
          editingListItem?.listItem?.tradeType
        } ${editingListItem?.type} project item`,
      ),
      status: ToastStatusEnum.ERROR,
      // @ts-expect-error - problem with DataAttributeProps compatibility with data- attr for TS <4.4.
      props: { 'data-test-id': 'Project-Scope-Edit-Error-Toast' },
    });
  };

  const onCompleted = (
    itemData: ProjectManagementListItemCreate | ProjectManagementListItemUpdate,
  ) => {
    const listItem = editingListItem?.listItem;
    const createOrUpdateErrors = itemData?.errors;
    const matchCount = itemData?.matchedListItems?.length ?? 0;

    if (matchCount > 0) {
      toast({
        id: 'variations-matched-toast',
        description: `${matchCount} variations matched`,
        position: 'bottom',
      });
    }

    if (createOrUpdateErrors && createOrUpdateErrors.length > 0) {
      onError();
    } else {
      toast({
        id: TOAST_IDS.UPDATE_LIST_ITEM_TOAST,
        description: sentenceCase(
          `${itemData?.listItem?.tradeType} ${
            itemData?.listItem?.type
          } project item successfully ${listItem ? 'updated' : 'added'}`,
        ),
        status: ToastStatusEnum.SUCCESS,
        // @ts-expect-error - problem with DataAttributeProps compatibility with data- attr for TS <4.4.
        props: { 'data-test-id': 'Project-Scope-Edit-Success-Toast' },
      });
      closeAddEditMaterialModal();
    }
  };

  const [updateListItem, { loading: updateListItemLoading }] = useMutation(
    UPDATE_LIST_ITEM,
    {
      onCompleted: (itemData) =>
        onCompleted(itemData.projectManagementListItemUpdate),
      onError,
    },
  );

  const [createListItem, { loading: createListItemLoading }] = useMutation(
    CREATE_LIST_ITEM,
    {
      onCompleted: (itemData) =>
        onCompleted(itemData.projectManagementListItemCreate),
      onError,
    },
  );

  const editListItem = (itemData: AddEditFields) => {
    const listItem = editingListItem?.listItem;
    updateListItem({
      variables: {
        listItemId: listItem?.clientIdentifier,
        listItemAttributes: {
          color:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name
              ? itemData.customVariant
              : itemData.color,
          name: itemData.materialName,
          tradeType: itemData.trade,
          quantity: itemData.quantity ?? 0,
          unitCost: itemData.unitCost ?? 0,
          quantityUnits: convertMeasurementUnitToQuantityUnit(
            itemData.quantityUnits,
          ),
          externalVariationId:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name
              ? null
              : itemData.externalVariationId,
          productCatalogProductId: itemData.productId,
          userSetCustomColor:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name,
        },
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    });
  };

  const addListItem = (itemData: AddEditFields) => {
    createListItem({
      variables: {
        productionListId,
        orgId,
        listItemAttributes: {
          color:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name
              ? itemData.customVariant
              : itemData.color,
          name: itemData.materialName,
          type: editingListItem?.type,
          tradeType: itemData.trade,
          quantity: itemData.quantity ?? 0,
          unitCost: itemData.unitCost ?? 0,
          quantityUnits: convertMeasurementUnitToQuantityUnit(
            itemData.quantityUnits,
          ),
          externalVariationId:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name
              ? null
              : itemData.externalVariationId,
          productCatalogProductId: itemData.productId,
          userSetCustomColor:
            itemData.externalVariationId === CUSTOM_VARIANT_COLOR.name,
        },
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    }).then(({ data: { projectManagementListItemCreate } }) => {
      // Update the selected items in redux with the newly added item.
      const { listItem } = projectManagementListItemCreate;
      dispatch(
        toggleSelectedItem(listItem.type, listItem.tradeType, listItem.id),
      );
    });
  };

  const onSubmit = (itemData: AddEditFields) => {
    const listItem = editingListItem?.listItem;
    if (listItem) {
      editListItem(itemData);
    } else if (editingListItem) {
      setEditingListItem({
        listItem: { tradeType: itemData.trade } as Attributes,
        type: editingListItem.type,
      }); // set editingListItem here for access to props in onError for addListItem()
      addListItem(itemData);
    }
  };

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    // Create a state object that maps <LineItemTypeEnum>: <TradeType>: <listItemIds>
    // If returning to page with existing selections from redux, use those.
    const listItemsByTypeAndTradeIds =
      !!selectedListItemIds && previousProductionListId === productionListId
        ? selectedListItemIds
        : getListItemsByTypeAndTradeIds(listItemsByTypeAndTrade);

    dispatch(updateSelectedListItems(listItemsByTypeAndTradeIds));
  }, [
    data,
    dispatch,
    selectedListItemIds,
    loading,
    listItemsByTypeAndTrade,
    listItems,
  ]);

  if (
    !data?.projectManagementProductionList ||
    loading ||
    !productionListId ||
    !estimateGroup
  ) {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.loaderAnimationShowed,
      ...commonTrackingProps,
    });

    if (materialListFeatureEnabled) {
      return <ProjectScopeLoader />;
    }

    return <LoaderSpinner show data-test-id="ProjectScopeSpinner" />;
  }

  return (
    <>
      {/* Project Scope Modals */}
      <DistributorSelectModal
        isOpen={showDistributorSelectModal}
        onCancel={closeDistributorSelectModal}
        setDistributor={setDistributor}
        distributors={distributors}
      />
      <DownloadPdfModal
        isOpen={showDownloadPdfModal}
        lineItemType={downloadPdfLineItemType}
        onCancel={closeDownloadPdfModal}
        productionList={
          projectManagementProductionList as ProjectManagementProductionList
        }
        job={estimateGroup?.salesOpportunity.job}
      />
      {showAddEditMaterialModal && (
        <AddEditMaterialModal
          isOpen
          onCancel={closeAddEditMaterialModal}
          editFn={onSubmit}
          trades={trades}
          editableItem={editingListItem}
          showSku={false}
          realTimePricing={false}
          distributors={distributors}
          loading={updateListItemLoading || createListItemLoading}
        />
      )}

      {/* Production Console header. */}
      <ProjectScopeHeader
        job={job}
        productionList={
          projectManagementProductionList as ProjectManagementProductionList
        }
      />

      {/* Main Content - project details and project table */}
      <Box
        data-test-id="projectScope-wrapper"
        flexDirection="column"
        flex="auto"
        paddingX={{ base: 200, tablet: 700 }}
        paddingY={{ base: 200, tablet: materialListFeatureEnabled ? 500 : 0 }}
        backgroundColor="neutral.100"
      >
        <Box flexDirection="column" width="100%" flex="1 1 auto">
          <Box flex="0 0 250px">
            <ProjectDetails
              estimateGroup={estimateGroup as EstimateGroup}
              productionList={
                projectManagementProductionList as ProjectManagementProductionList
              }
            />
          </Box>

          <ProjectScopeContentTable
            orgId={orgId}
            jobId={jobId}
            distributors={distributors}
            selectedListItemIds={selectedListItemIds}
            listItemsByTypeAndTrade={listItemsByTypeAndTrade}
            openAddEditMaterialModal={openAddEditMaterialModal}
            openDownloadPdfModal={openDownloadPdfModal}
            selectDistributor={selectDistributor}
            setEditingListItem={setEditingListItem}
            enableInlineEditingV2={enableInlineEditingV2}
            productionListId={productionListId}
          />
        </Box>
      </Box>
    </>
  );
};
