import appSettings from 'src/appSettings';
import request from 'src/lib/request';

export class TokenHandler {
  static pendingTokenFetch = undefined;

  static fetchAccessToken() {
    if (!this.pendingTokenFetch) {
      const tokenHost = appSettings.HYPERION_API_SERVER;
      this.pendingTokenFetch = request
        .post(`${tokenHost}/oauth/token?grant_type=password`, {
          headers: { 'Cache-Control': 'no-cache' },
        })
        .then((res) => {
          this.pendingTokenFetch = undefined;
          const {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line camelcase
            data: { access_token },
          } = res;
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line camelcase
          return access_token;
        })
        .catch((e) => {
          if (
            e.response &&
            (e.response.status === 401 ||
              e.response?.data?.error === 'invalid_grant')
          ) {
            window.location.replace(
              `/login?redirect_to=${encodeURIComponent(window.location.href)}`,
            );
            return null;
          }
          throw e;
        });
    }

    return this.pendingTokenFetch;
  }
}
