// base logic taken from https://usehooks.com/useLocalStorage
import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { get, set, isFunction, unset } from 'lodash';

import { EHI_LOCAL_STORAGE_KEY } from 'src/constants';

export const checkIsPersisted = (path: string) => {
  try {
    const storage = JSON.parse(
      window.localStorage.getItem(EHI_LOCAL_STORAGE_KEY) ?? '{}',
    );

    return !!get(storage, path);
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

export const removeItemFromLocalStorage = (path: string) => {
  try {
    const storage = JSON.parse(
      window.localStorage.getItem(EHI_LOCAL_STORAGE_KEY) ?? '{}',
    );
    unset(storage, path);
    window.localStorage.setItem(EHI_LOCAL_STORAGE_KEY, JSON.stringify(storage));
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 * wraps `useState` hook to use `localStorage`
 * `path` should begin with a `jobId` and
 * follow same format as `path` in `lodash.get` https://lodash.com/docs/4.17.15#get
 *
 * @example
 * // localStorage when component mounts
 * localStorage = {};
 *
 * const [questionAnswer, setQuestionAnswer] = useLocalStorage('12345.questions.123', 'some value');
 *
 * // localStorage after calling hook
 * localStorage = {
 *   'HOVER/Exterior_Estimator': {
 *     12345: {
 *       questions: {
 *         123: 'some value',
 *       },
 *     },
 *   },
 * };
 */
export function useLocalStorage<T>(
  path: string,
  initialValue: T,
  options?: {
    forceReplace: boolean;
  },
) {
  const [isPersisted, setIsPersisted] = useState(checkIsPersisted(path));
  // checks if the value has been previously set to localStorage

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const storage = JSON.parse(
        window.localStorage.getItem(EHI_LOCAL_STORAGE_KEY) ?? '{}',
      );

      if (options?.forceReplace) {
        // remove item from localStorage and return initialValue
        removeItemFromLocalStorage(path);
        return initialValue;
      }
      // Get from local storage by path
      const item = get(storage, path);
      return item ?? initialValue;
    } catch (error) {
      // If error also return initialValue
      Sentry.captureException(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = isFunction(value) ? value(storedValue) : value;
    try {
      // Save to local storage
      const storage = JSON.parse(
        window.localStorage.getItem(EHI_LOCAL_STORAGE_KEY) ?? '{}',
      );
      set(storage, path, valueToStore);
      window.localStorage.setItem(
        EHI_LOCAL_STORAGE_KEY,
        JSON.stringify(storage),
      );
      setIsPersisted(true);
    } catch (error) {
      Sentry.captureException(error);
      setIsPersisted(checkIsPersisted(path));
    }
    // Save state
    setStoredValue(valueToStore);
  };

  const removeItem = () => {
    removeItemFromLocalStorage(path);
    setIsPersisted(false);
  };

  return [storedValue, setValue, isPersisted, removeItem] as const;
}
