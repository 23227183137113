import { Box } from '@hover/blueprint';

import { PreviewMasks } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/PreviewImage';
import { VisibilityControlProps } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/types';
import { DefaultModal } from 'style-guide';

type Props = VisibilityControlProps & {
  showDefaultElementVisibilityPreviewImageModal: boolean;
  setShowDefaultElementVisibilityPreviewImageModal: (show: boolean) => void;
};

export const ElementVisibilityPreviewModal: React.FC<Props> = ({
  includeTotalPriceDefault,
  includeTradePriceDefault,
  includeAllEstimateItemsDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
  showDefaultElementVisibilityPreviewImageModal,
  setShowDefaultElementVisibilityPreviewImageModal,
}) => {
  return (
    <DefaultModal
      isOpen={showDefaultElementVisibilityPreviewImageModal}
      childrenStyle={{ alignItems: 'center', padding: 0 }}
      closeButton={() => {
        setShowDefaultElementVisibilityPreviewImageModal(false);
      }}
      title="Default element visibility"
      headerStyle={{
        paddingTop: '0px',
        paddingbottom: '5px',
      }}
    >
      <Box
        position="relative"
        justifyContent="center"
        width="50vw"
        height="90vh"
      >
        <PreviewMasks
          includeTotalPriceDefault={includeTotalPriceDefault}
          includeTradePriceDefault={includeTradePriceDefault}
          includeItemQuantitiesDefault={includeItemQuantitiesDefault}
          includeItemCostDefault={includeItemCostDefault}
          includeAllEstimateItemsDefault={includeAllEstimateItemsDefault}
        />
      </Box>
    </DefaultModal>
  );
};
