import { Box } from '@hover/blueprint';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  max-width: 500px;
  padding: 0 16px;
  flex: 1;
  margin: auto;
`;

export const WarningContainer = styled(Box)`
  line-height: 1.3;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.neutral100};
  border-radius: 4px;
`;

export const WarningContent = styled.span`
  margin: 0px 10px;
  color: ${({ theme }) => theme.colors.neutral600};
`;

export const OverviewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FlexOverflowContainer = styled(Box)`
  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
`;

export const NavBarContainer = styled.nav`
  height: 75px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.neutral000};
`;

export const NavTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavTitle = styled.h1`
  ${({ theme }) => theme.mixins.font('medium', 16)};
  color: ${({ theme }) => theme.colors.slateGray};
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 0;
`;
