import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const inputCategoriesReducer = createReducer(initialState).handleAction(
  estimatorActions.storeInputCategories,
  (state, { payload: { categories } }) => {
    return {
      ...state,
      config: {
        ...state.config,
        inputCategories: categories,
      },
    };
  },
);
