import { getSalesOpportunities_salesOpportunities_soldEstimateGroup as SoldEstimateGroupType } from 'src/api/types/getSalesOpportunities';
import {
  TradeTypes,
  OrderModalStates,
  poNumberValueField,
  poNumberErrorField,
  customerNameValueField,
  phoneNumberValueField,
  addressLineOneValueField,
  addressLineOneErrorField,
  addressLineTwoValueField,
  cityValueField,
  cityErrorField,
  zipCodeValueField,
  zipCodeErrorField,
  stateProvinceValueField,
  stateProvinceErrorField,
} from 'src/features/projectManagement/constants';
import {
  VendorType,
  AddListItemErrors,
  JobType,
  ProjectManagementOrderDataType,
  OrderDetailsFormType,
  OrderDocumentType,
  SalesOpportunityType,
  UserType,
  ErrorModalType,
  BillingFormValues,
} from 'src/features/projectManagement/types';
import { Summary as JobMeasurements } from 'src/types/EstimationMeasurementTypes';

export const ProjectManagementOrderDataInitialState = {
  vendorForOrder: null,
  orderModalState: OrderModalStates.Pristine,
  isPollingProductionList: false,
  errorMessage: '',
  lostListItems: [],
  projectManagementOrder: null,
  distributionOrder: null,
  selectedDeliveryMethod: null,
};

export const OrderDetailsFormInitialState: OrderDetailsFormType = {
  errors: {
    [poNumberErrorField]: true,
    [addressLineOneErrorField]: true,
    [cityErrorField]: true,
    [zipCodeErrorField]: true,
    [stateProvinceErrorField]: true,
  },
  values: {
    [poNumberValueField]: null,
    [customerNameValueField]: null,
    [phoneNumberValueField]: null,
    [addressLineOneValueField]: null,
    [addressLineTwoValueField]: null,
    [cityValueField]: null,
    [zipCodeValueField]: null,
    [stateProvinceValueField]: null,
    notes: null,
  },
};

export const BillingFormInitialState: BillingFormValues = {
  errors: { jobAccountInvalid: false, jobNameInvalid: false },
};

export type ProjectManagementStatePdf = {
  type: string | null;
  vendor: VendorType | null;
  shouldShowCostDetails: boolean;
  shouldShowItemMeasurements: boolean;
  shouldShowTotalCost: boolean;
  isFetching: boolean;
  error: Error | null;
  notes: string | null;
};

export interface ProjectManagementState {
  isGettingProductionData: boolean;
  jobDetails: JobType | null;
  jobMeasurements: JobMeasurements | null;
  estimateDetails: SoldEstimateGroupType | null;
  estimateUser: UserType | null;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productionList: any;
  salesOpportunities: SalesOpportunityType[];
  vendors: VendorType[] | null;
  error: string | null;
  shouldShowAddListItemModal: boolean;
  shouldShowAddListItemToast: boolean;
  addListItemToastText: string;
  shouldShowSpinner: boolean;
  addListItemErrors: AddListItemErrors;
  shouldShowOrderModal: boolean;
  shouldShowPdfModal: boolean;
  errorModal: ErrorModalType;
  pdf: ProjectManagementStatePdf;
  projectManagementOrderData: ProjectManagementOrderDataType;
  orderDetailsForm: OrderDetailsFormType;
  orderDocuments: OrderDocumentType[];
  tradeFilter: string;
  isSubmitOrderDisabled: boolean;
  billingFormValues: BillingFormValues;
}

const initialState: ProjectManagementState = {
  isGettingProductionData: true,
  jobDetails: null,
  jobMeasurements: null,
  estimateDetails: null,
  estimateUser: null,
  productionList: null,
  salesOpportunities: [],
  vendors: null,
  error: null,
  shouldShowAddListItemModal: false,
  shouldShowAddListItemToast: false,
  addListItemToastText: '',
  shouldShowSpinner: false,
  addListItemErrors: {},
  shouldShowOrderModal: false,
  shouldShowPdfModal: false,
  errorModal: {
    isOpen: false,
    message: null,
  },
  pdf: {
    type: null,
    vendor: null,
    shouldShowCostDetails: true,
    shouldShowItemMeasurements: true,
    shouldShowTotalCost: true,
    isFetching: false,
    error: null,
    notes: null,
  },
  projectManagementOrderData: ProjectManagementOrderDataInitialState,
  orderDetailsForm: OrderDetailsFormInitialState,
  orderDocuments: [],
  billingFormValues: BillingFormInitialState,
  tradeFilter: TradeTypes.ALL_TRADES,
  isSubmitOrderDisabled: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default initialState;
