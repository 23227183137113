import { upperCase } from 'lodash';

import {
  AugmentedPlainMeasurements,
  PlainMeasurements,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';
import { PartialSidingUtils } from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

import { FACADE_TYPES } from '../constants/sidingConstants';
import {
  PartialsMeasurementsMap,
  EdgeTotals,
  EdgesForFacade,
} from './PartialsMeasurementsMap';

export class LineSegmentCalculator extends PartialsMeasurementsMap {
  /**
   * @example
   * edgeTotals
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   */
  public calculatedEdgeTotals: EdgeTotals = {};

  private questionResponses: QuestionResponses = {};

  public selectedSidingFacades: string[] = [];

  public selectedAreaWithTrim = 0;

  public orgSettings: EhiOrgSettings;

  constructor({
    pristinePlainMeasurements,
    estimationJson,
    questionResponses,
    orgSettings,
  }: {
    pristinePlainMeasurements: PlainMeasurements;
    estimationJson: Measurements;
    questionResponses: QuestionResponses;
    orgSettings: EhiOrgSettings;
  }) {
    super(pristinePlainMeasurements, estimationJson);

    this.orgSettings = orgSettings;
    this.questionResponses = questionResponses;
    this.calculatedEdgeTotals = this.calculateEdgeTotals();
    this.selectedSidingFacades = this.getSelectedSidingFacades();
    this.selectedAreaWithTrim = this.getSelectedAreaWithTrim();
  }

  private calculateEdgeTotals() {
    // first initialize all the edgeTotals with 0's
    const edgeTotals = Object.keys(this.edgeTotals).reduce<EdgeTotals>(
      (_blankEdgeTotals, argument) => {
        const blankEdgeTotals = { ..._blankEdgeTotals };
        blankEdgeTotals[argument] = 0;
        return blankEdgeTotals;
      },
      {},
    );

    // then sum them up (in feet)
    Object.values(this.getSummableEdges()).forEach(({ type, length = 0 }) => {
      const typeCountKey = `${type}_count`; // ie ridge_count
      const typeTotalKey = `${type}_total`; // ie ridge_total
      if (!edgeTotals[typeTotalKey]) edgeTotals[typeTotalKey] = 0;
      if (!edgeTotals[typeCountKey]) edgeTotals[typeCountKey] = 0;
      edgeTotals[typeTotalKey] += length / 12;
      edgeTotals[typeCountKey] += 1;
    });

    edgeTotals.window_count_total = this.getWindowCount();

    return edgeTotals;
  }

  /**
   * returns a map of edges which can be summed
   * @example
   * getSummableEdges()
   * {
   *   'E-1': { type: 'rake', length: 10 ... },
   *   'E-2': { type: 'ridge', length: 20 ... },
   * }
   */
  private getSummableEdges() {
    return Object.values(this.getSummableEdgesForFacade()).reduce<
      AugmentedPlainMeasurements['edges']
    >((_summableEdges, edges) => {
      const summabledEdges = { ..._summableEdges };
      edges.forEach((edge) => {
        if (!summabledEdges[edge.display_label]) {
          summabledEdges[edge.display_label] = edge;
        }
      });
      return summabledEdges;
    }, {});
  }

  /**
   * returns a edgesForFacade map only including facades that are currently selected
   * also removes some edges from non-siding facades
   */
  private getSummableEdgesForFacade() {
    const nonSidingEdgesToRemove = [
      'opening_sides',
      'opening_bottoms',
      'opening_tops',
      'sloped_bases',
      'perimeter',
      'level_bases',
      'non_siding_level_bases',
      'non_siding_perimeter',
    ];

    return Object.entries(this.edgesForFacade).reduce<EdgesForFacade>(
      (_edgesForFacade, [facadeDisplayLabel, edges]) => {
        const edgesForFacade = { ..._edgesForFacade };
        // if the facade is selected
        if (this.questionResponses[facadeDisplayLabel]) {
          // removes some edges from any non-siding faces (ie, brick, stucco, unknown, etc...)
          const filteredEdges = edges.filter(
            (edge) =>
              !(
                facadeDisplayLabel.split('-').shift() !== 'SI' &&
                nonSidingEdgesToRemove.includes(edge.type)
              ),
          );
          edgesForFacade[facadeDisplayLabel] = filteredEdges;
        }
        return edgesForFacade;
      },
      {},
    );
  }

  private getWindowCount() {
    return Object.entries(this.questionResponses).reduce<number>(
      (count, [id, isSelected]) => {
        const windowsForFacade = this.windowsForFacade[id];
        const len = windowsForFacade?.length;

        if (isSelected && len) {
          return count + len;
        }
        return count;
      },
      0,
    );
  }

  private getSelectedSidingFacades() {
    return Object.entries(this.questionResponses).reduce<string[]>(
      (acc, [id, isSelected]) => {
        if (FACADE_TYPES[upperCase(id.split('-').shift())] && isSelected) {
          acc.push(id);
        }
        return acc;
      },
      [],
    );
  }

  private getSelectedAreaWithTrim() {
    const facades = Object.values(this?.estimationJson?.facades ?? {}).flat();

    const combinedAreaWithTrimForFacade =
      PartialSidingUtils.getCombinedAreaWithTrimForFacades(
        facades,
        PartialSidingUtils.shouldCalculateWithOpenings(this.orgSettings),
      );

    return Object.entries(combinedAreaWithTrimForFacade)
      .filter(([id]) => this.selectedSidingFacades.includes(id))
      .reduce<number>((acc, [, val]) => acc + val.combinedArea, 0);
  }
}
