import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { projectManagementOrderDocuments_projectManagementOrderDocuments as OrderDocument } from 'src/features/projectManagement/apis/graphql/queries/types';

import { PdfModalStates } from 'src/features/projectManagement/constants';

import DownloadExisting from './DownloadExisting';
import DownloadNew from './DownloadNew';

interface Props {
  orderDocuments: OrderDocument[];
  refetch: () => void;
}

interface IState {
  hasHistoricalExports: boolean;
  didUserNavigateToNewFromExisting: boolean;
  modalState: PdfModalStates;
}

export class PdfModal extends PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    const { orderDocuments } = this.props;
    const hasHistoricalExports = !!orderDocuments.length;
    this.state = {
      hasHistoricalExports,
      didUserNavigateToNewFromExisting: false,
      modalState: hasHistoricalExports
        ? PdfModalStates.DownloadExisting
        : PdfModalStates.DownloadNew,
    };
  }

  @autobind
  switchStateToNew() {
    this.setState({
      modalState: PdfModalStates.DownloadNew,
    });
  }

  @autobind
  toggleDidUserNavigateToNewFromExisting() {
    const { didUserNavigateToNewFromExisting } = this.state;
    this.setState({
      didUserNavigateToNewFromExisting: !didUserNavigateToNewFromExisting,
    });
  }

  @autobind
  switchStateToExisting() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.hasHistoricalExports) return;
    this.toggleDidUserNavigateToNewFromExisting();
    this.setState({
      modalState: PdfModalStates.DownloadExisting,
    });
  }

  render() {
    const { orderDocuments, refetch } = this.props;
    const { modalState, didUserNavigateToNewFromExisting } = this.state;
    return modalState === PdfModalStates.DownloadExisting ? (
      <DownloadExisting
        orderDocuments={orderDocuments}
        switchStateToNew={this.switchStateToNew}
        toggleDidUserNavigateToNewFromExisting={
          this.toggleDidUserNavigateToNewFromExisting
        }
        refetch={refetch}
      />
    ) : (
      <DownloadNew
        didUserNavigateToNewFromExisting={didUserNavigateToNewFromExisting}
        switchStateToExisting={this.switchStateToExisting}
        refetch={refetch}
      />
    );
  }
}
