import { createSelector } from 'reselect';

import { ListItemIdsByTypeAndTrade } from 'src/features/project/types';
import { RootState } from 'src/types/reduxStore';

export const getSelectedListItemIds = createSelector(
  (state: RootState) => {
    return state.project.selectedListItemIds;
  },
  (selectedListItemIds: ListItemIdsByTypeAndTrade | null) => {
    return selectedListItemIds;
  },
);

export const getDistributor = (state: RootState) => state.project.distributor;

export const getBranch = (state: RootState) => state.project.branch;

export const getJobAccount = (state: RootState) => state.project.jobAccount;
