import { Flex } from '@hover/blueprint/chakra';
import ContentLoader from 'react-content-loader';

export const ProjectScopeLoader = () => {
  return (
    <Flex w="100%" alignItems="center" justifyContent="center">
      <ContentLoader
        speed={2}
        width={1328}
        height={805}
        viewBox="0 0 1328 805"
        backgroundColor="#f3f3f3"
        foregroundColor="#ededed"
      >
        <rect width="353" height="19" x="1" fill="#EDEDED" rx="5" />
        <rect width="18" height="18" x="1" y="337.877" fill="#EDEDED" rx="3" />
        <rect
          width="262"
          height="19"
          x="32.925"
          y="337.377"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="154" height="34" x="1174" y="332" fill="#EDEDED" rx="17" />
        <rect
          width="35"
          height="9"
          x="35.671"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="35"
          height="9"
          x="325"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="35"
          height="9"
          x="590"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="35"
          height="9"
          x="753"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="35"
          height="9"
          x="896"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="35"
          height="9"
          x="1077"
          y="382.176"
          fill="#EDEDED"
          rx="4.5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="35.783"
          y="417.022"
          fill="#F0F0F0"
          rx="3.209"
        />
        <rect
          width="58"
          height="16"
          x="64.663"
          y="417.045"
          fill="#F0F0F0"
          rx="5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="463.552"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect width="148" height="16" x="66" y="464" fill="#EDEDED" rx="5" />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="528.532"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect
          width="148"
          height="16"
          x="65.999"
          y="528.98"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="593.513"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect
          width="148"
          height="16"
          x="65.999"
          y="593.961"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="658.493"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect
          width="148"
          height="16"
          x="65.999"
          y="658.941"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="723.474"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect
          width="148"
          height="16"
          x="65.999"
          y="723.922"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="16.045"
          height="16.045"
          x="36.585"
          y="788.454"
          fill="#EDEDED"
          rx="3.209"
        />
        <rect
          width="148"
          height="16"
          x="65.999"
          y="788.902"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="128" height="10" x="913" y="12" fill="#EDEDED" rx="5" />
        <rect width="84" height="34" x="1051" fill="#EDEDED" rx="17" />
        <rect width="183" height="34" x="1145" fill="#EDEDED" rx="17" />
        <rect
          width="353"
          height="19"
          x=".671"
          y="77.254"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="182" height="117" x="1" y="130" fill="#EDEDED" rx="5" />
        <rect
          width="128"
          height="16"
          x="202.343"
          y="130.396"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="91"
          height="16"
          x="202.343"
          y="156.396"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="77"
          height="16"
          x="202.343"
          y="182.396"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="391.657"
          height="19"
          x="459.671"
          y="77.254"
          fill="#EDEDED"
          rx="5"
        />
        <rect
          width="286.436"
          height="11"
          x="459.671"
          y="107.254"
          fill="#EDEDED"
          rx="5"
        />
        <path
          fill="#EDEDED"
          d="m530.171 232.74-26.5-13-23 7-14.5-9-4 1-1.5-1-1-13 11-3-1.5-27.5-3-1v-2l18.5-17h-1.5v-2.5l10.5-12h16.5l58-2.5 24 30 31.5 13.5v2.5l-2.5.5-2 32-51 26-12-5v-3l-13-5.5-13 4.5Z"
        />
        <rect
          width="187.4"
          height="48"
          x="655.515"
          y="135.254"
          fill="#EDEDED"
          rx="4"
        />
        <rect
          width="145"
          height="48"
          x="656"
          y="194.986"
          fill="#EDEDED"
          rx="4"
        />
        <rect
          width="291"
          height="19"
          x="928.671"
          y="77.254"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="182" height="11" x="929" y="105" fill="#EDEDED" rx="5" />
        <rect
          width="152"
          height="11"
          x="928.671"
          y="139"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="61" height="11" x="1267" y="139" fill="#EDEDED" rx="5" />
        <rect
          width="127"
          height="11"
          x="928.671"
          y="158"
          fill="#EDEDED"
          rx="5"
        />
        <rect width="61" height="11" x="1267" y="158" fill="#EDEDED" rx="5" />
      </ContentLoader>
    </Flex>
  );
};
