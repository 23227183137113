import { cloneDeep } from 'lodash';
import queryString from 'query-string';

import {
  EstimationConfigInputInputTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { getRoofTrades } from 'src/features/exteriorEstimator/utils/questionsUtils';

import { MEASUREMENT } from '../constants/questionCategories';
import { ESTIMATOR_QUESTIONS_URL, ESTIMATES_URL } from '../constants/urls';
import { QuestionResponses, Input } from '../types';

export const arrayToQueryParams = (
  key: string | number,
  array: Array<string | number | null>,
) => {
  return queryString.stringify(
    { [`${key}`]: array },
    { arrayFormat: 'bracket' },
  );
};

// returns new array with element at position "from" moved to position "to"
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const moveElementInArray = (array: any[], to: number, from: number) => {
  const clone = cloneDeep(array);
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  return clone;
};

export const getInputs = (questionResponses: QuestionResponses) => {
  const inputs = cloneDeep(questionResponses);
  Object.entries(inputs).forEach(([questionId, response]) => {
    if (response === null || response === undefined) {
      inputs[questionId] = 0;
    }
  });
  return inputs;
};

export const generateEstimatorWithTemplatesUrl = (
  oldUrl: string,
  selectedTemplates: number[],
) => {
  let newUrl = oldUrl.slice(0);
  const templates = selectedTemplates.map((template) => template.toString());
  const templateParams = arrayToQueryParams('template_ids', templates);

  const indexOfTemplateParams = oldUrl.indexOf('template_ids');
  if (indexOfTemplateParams !== -1) {
    // clear the old template params
    newUrl = oldUrl.slice(0, indexOfTemplateParams - 1);
  }
  // remove trailing &
  if (oldUrl[oldUrl.length - 1] === '&') newUrl = oldUrl.slice(0, -1);
  const separator = oldUrl.indexOf('?') === -1 ? '?' : '&';
  return `${newUrl}${separator}${templateParams}`;
};

export const getEstimatorQuestionsUrl = ({
  jobId,
  category,
}: {
  jobId: number;
  category: string;
}) => {
  const parsedHash = queryString.parse(window.location.hash);
  const templateIds = parsedHash['template_ids[]'];
  let categoryName = category;
  if (categoryName === MEASUREMENT) categoryName = 'measurement_values';
  let url = `${ESTIMATOR_QUESTIONS_URL}/${categoryName.toLowerCase()}?jobId=${jobId}`;

  if (templateIds?.length) {
    const templateParams = arrayToQueryParams(
      'template_ids',
      Array.isArray(templateIds) ? templateIds : [templateIds],
    );
    url = url.concat(`&${templateParams}`);
  }
  return url;
};

export const stripTemplateIdsFromParams = (search: string) => {
  const parsed = queryString.parse(search);
  delete parsed['template_ids[]'];
  return queryString.stringify(parsed);
};

export const getEstimateGroupDetailsUrl = ({
  jobId,
  estimateGroupId,
}: {
  jobId: number;
  estimateGroupId: string;
}) => {
  return `${ESTIMATES_URL}/${estimateGroupId}/details?jobId=${jobId}`;
};

// questions here is per category
export const getAreAllSelectsAnswered = ({
  questions = [],
  questionResponses = {},
}: {
  questions: Input[];
  questionResponses: QuestionResponses;
}) => {
  // filter the selects
  const selects = questions.filter(
    (question) =>
      question.inputType === EstimationConfigInputInputTypeEnum.SELECT,
  );
  if (selects.length === 0) return true;

  return selects.every((question) => {
    const { id, inputOptions } = question;
    const answer = questionResponses[id];
    const inputOptionValues = inputOptions?.map((option) => option.value);

    if (typeof answer !== 'string') return false;
    return inputOptionValues?.includes(answer);
  });
};

export const createTradeTypeAttributes = ({
  selectedTrades,
  tradeTypes,
}: {
  selectedTrades: TradeTypeEnum[];
  tradeTypes: TradeType[];
}) => {
  const tradeTypeAttributes = [];
  const roofTrades = getRoofTrades(tradeTypes).map(
    (tradeType: TradeType) => tradeType.tradeTypeEnumValue,
  );

  roofTrades.forEach((roofTrade: TradeTypeEnum) => {
    if (selectedTrades.includes(roofTrade)) {
      tradeTypeAttributes.push({
        tradeType: roofTrade,
        fullMeasurements: true,
      });
    }
  });

  if (selectedTrades.includes(TradeTypeEnum.SIDING)) {
    tradeTypeAttributes.push({
      tradeType: TradeTypeEnum.SIDING,
      fullMeasurements: true,
    });
  }
  return tradeTypeAttributes;
};
