import { RootState } from 'src/types/reduxStore';

export const getSelectedProposal = (state: RootState) => {
  if (!state.exteriorEstimator.proposalsData.selectedProposalId) return null;
  return state.exteriorEstimator.proposalsData.proposals.find(
    (proposal) =>
      proposal.id === state.exteriorEstimator.proposalsData.selectedProposalId,
  );
};

export const getIsLoadingProposals = (state: RootState) =>
  state.exteriorEstimator.proposalsData.isLoadingProposals;
