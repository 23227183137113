import { getProducts_productCatalogProducts_edges_node_variations as Variation } from 'src/api/types/getProducts';

import { ListItemType } from '../types';

export const getSingleVariationNameIfOnlyOneExists = ({
  listItem,
}: {
  listItem: ListItemType;
}): string | null => {
  const { product } = listItem;
  const variations = product?.variations ?? [];
  return variations.length === 1 ? variations[0].name : null;
};

export const getVariationSku = ({
  variation,
  listItem,
}: {
  variation: Variation | null;
  listItem: ListItemType;
}): string | null => {
  const { vendor } = listItem;
  const vendorDistributorId = vendor?.distributorId ?? null;
  if (!variation || !vendorDistributorId) return null;
  const externalVariationIdentifiers =
    variation?.externalVariationIdentifiers ?? [];
  return (
    externalVariationIdentifiers.find(
      ({ distributorId }) => distributorId === vendorDistributorId,
    )?.sku ?? null
  );
};

export const getVariationByName = ({
  name,
  listItem,
}: {
  name: string | null;
  listItem: ListItemType;
}): Variation | null => {
  if (!name) return null;
  const { product } = listItem;
  return (
    product?.variations.find((variation) => variation.name === name) ?? null
  );
};

export const extractListItemVariationName = ({
  listItem,
}: {
  listItem: ListItemType;
}): string | null => {
  const { product, externalVariationId } = listItem;
  const variations: Variation[] = product?.variations ?? [];
  return variations.find(({ id }) => id === externalVariationId)?.name ?? null;
};

export const getListItemDefaultColor = (listItem: ListItemType) => {
  return (
    listItem.color ||
    getSingleVariationNameIfOnlyOneExists({ listItem }) ||
    extractListItemVariationName({ listItem })
  );
};
