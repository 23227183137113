export const ORDERING_AUTO_ATTACH_PDF = 'commerce-ordering-auto-attach-pdf';
export const UNIT_COST_IN_LINE_EDIT_CHANGE_LOG =
  'commerce-unit-cost-in-line-edit-change-log';

export const COMMERCE_PROJECT_SCOPE = 'commerce_project_scope_p1';
export const COMMERCE_PROJECT_SCOPE_INLINE_EDITING =
  'commerce-project-scope-p1-inlineediting';
export const COMMERCE_PROJECT_SCOPE_INLINE_EDITING_V2 =
  'commerce-project-scope-inline-editing-v2';
export const COMMERCE_PRODUCTION_LIST_DELETE_ITEM =
  'commerce-production-list-delete-item';
export const COMMERCE_PRODUCT_SEARCH_V2 = 'product-catalog-search-v2';

export const QUANTITY_TAKEOFF = 'growth-quantity-takeoff';
export const DIRECT_TO_PRODUCTION =
  'growth-quantity-takeoff-direct-to-production';
export const COOKIE_MANAGEMENT = 'web-cookie-management';

export const WORKFLOWS_SALES_PRESENTATION_INTEREST =
  'pa-sales-pres-early-access';

export const COMMERCE_ORDERING_ONLY_FLOW = 'commerce-ordering-only-flow';
export const PA_SALES_PRES_PDF_UPLOAD = 'PA_salesPresPdfUpload';
export const COMMERCE_PROJECT_LIST_REQUEST_REFACTOR =
  'commerce-project-list-request-refactor';
export const WORKFLOWS_RR_NAMED_ESTIMATES = 'workflows-rr-named-estimates';
export const COMMERCE_USE_HDF = 'commerce-use-hdf';
export const COMMERCE_BRICK_AND_STONE_ESTIMATION =
  'commerce-brick-and-stone-estimation';
export const COMMERCE_TEMPLATE_PRODUCT_GROUPED_LINE_ITEMS =
  'commerce-template-product-grouped-line-items';
export const COMMERCE_INLINE_EDITING_REDESIGN =
  'commerce-inline-editing-redesign';
export const COMMERCE_USER_ACTIVE_COMMERCE_PRODUCT =
  'commerce-user-active-commerce-product';
export const COMMERCE_INCLUDE_OPENINGS_LESS_THAN_33_SQFT =
  'commerce-include-openings-less-than-33-sqft';

export const COMMERCE_IMPERSONATION_BANNER = 'commerce-impersonation-banner';

// Hyperion active commerce products
export const ACTIVE_COMMERCE_PRODUCT_ESTIMATION = 'estimation';
export const ACTIVE_COMMERCE_PRODUCT_ORDERING_ONLY = 'ordering_only';
export const ACTIVE_COMMERCE_PRODUCT_OUT_OF_THE_BOX_CONFIG =
  'out_of_the_box_configuration';
