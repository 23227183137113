import { Box, Heading } from '@hover/blueprint';

import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { TemplateSelection } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/TemplateSelection';
import { isTemplateSelected } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/TradeSection';
import { Template } from 'src/features/exteriorEstimator/types';

interface SelectSingleTemplateProps {
  templates: Template[];
  selectedTemplateIds: number[];
  handleTemplateSelection: (selectedTemplate: Template) => void;
}

export const SelectSingleTemplate = ({
  templates,
  selectedTemplateIds,
  handleTemplateSelection,
}: SelectSingleTemplateProps) => {
  return (
    <EstimatorResponsiveWrapper data-test-id="select-templates-page" flex={1}>
      <Box flexDirection="column" width="100%">
        <Heading
          size={500}
          marginBottom={400}
          marginLeft="4px"
          fontWeight="bold"
        >
          Select the template you’d like to use for this project
        </Heading>
        {templates.map((template: Template) => (
          <Box
            margin={100}
            padding={100}
            key={template.id}
            data-test-id="singleSelectTemplateSection"
          >
            <TemplateSelection
              template={template}
              checked={isTemplateSelected(template, selectedTemplateIds)}
              onClickTemplate={() => handleTemplateSelection(template)}
            />
          </Box>
        ))}
      </Box>
    </EstimatorResponsiveWrapper>
  );
};
