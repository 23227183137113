import {
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges as TemplateEdges,
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node as Template,
} from 'src/api/types/estimationConfigTemplatesWithLineItems';

export const getRoofTemplates = (
  templateEdges: (TemplateEdges | null)[],
): Template[] =>
  templateEdges
    .filter((edge) => edge?.node?.tradeType === 'ROOF')
    .map((edge) => edge?.node as Template)
    .filter(Boolean);
