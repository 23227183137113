import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { EHIHoverHeader as HoverHeader } from 'src/components/EHIHoverHeader';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { ToastNotification } from 'src/components/ToastNotification';
import { getParams } from 'src/features/projectManagement/redux/selectors';
import { RootState, RootAction } from 'src/types/reduxStore';

import * as EstimatorProductionActions from '../../redux/actions';
import ErrorModal from './ErrorModal';
import PdfWrapper from './PdfModal/PdfWrapper';
import { ProductionContent } from './ProductionContent/ProductionContent';

export const ReactHeaderContainer = styled.div`
  box-sizing: content-box;
  height: 100vh;
  width: 100%;
`;

export const ProductionViewContentContainer = styled.div`
  padding: 0px;
  width: 100%;
  margin: auto;
`;

export const mapStateToProps = (state: RootState) => ({
  isGettingProductionData:
    state.estimatorProductionTools.isGettingProductionData,
  shouldShowSpinner: state.estimatorProductionTools.shouldShowSpinner,
  shouldShowAddListItemToast:
    state.estimatorProductionTools.shouldShowAddListItemToast,
  addListItemToastText: state.estimatorProductionTools.addListItemToastText,
  jobId: state?.estimatorProductionTools?.jobDetails?.id,
  jobIdParam: getParams(state).jobId,
});

export const mapDispatchToProps = (dispatch: Redux.Dispatch<RootAction>) =>
  bindActionCreators(
    {
      getProductionData: EstimatorProductionActions.getProductionData,
      updateAddListItemToast: EstimatorProductionActions.updateAddListItemToast,
    },
    dispatch,
  );

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class ProductionViewComponent extends PureComponent<ComponentProps> {
  componentDidMount() {
    const { getProductionData, jobId, jobIdParam } = this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line radix
    if (!jobId || !jobIdParam || jobId !== parseInt(jobIdParam)) {
      getProductionData();
    }
  }

  @autobind
  clearToastStatus() {
    const { updateAddListItemToast } = this.props;
    updateAddListItemToast('', false);
  }

  render() {
    const {
      isGettingProductionData,
      shouldShowSpinner,
      shouldShowAddListItemToast,
      addListItemToastText,
    } = this.props;
    return (
      <ReactHeaderContainer data-test-id="production-container">
        <HoverHeader>
          <PdfWrapper />
          <ErrorModal />
          <ProductionViewContentContainer data-test-id="productionViewContentContainer">
            <ToastNotification
              notification={`${addListItemToastText} is added to your production list`}
              severity="Confirmation"
              show={shouldShowAddListItemToast}
              clearError={this.clearToastStatus}
            />
            <LoaderSpinner show={shouldShowSpinner} />
            {!isGettingProductionData && <ProductionContent />}
          </ProductionViewContentContainer>
        </HoverHeader>
      </ReactHeaderContainer>
    );
  }
}

export const ProductionView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductionViewComponent);
