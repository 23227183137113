import { useQuery } from '@apollo/client';

import { QuantityUnitsEnum } from 'src/api/graphql-global-types';
import { estimationConfigLineItemsSettings_estimationConfigLineItems_nodes as LineItem } from 'src/api/types/estimationConfigLineItemsSettings';
import {
  estimationConfigLineItemVersions_estimationConfigLineItemVersions_edges_node_attributeChanges as Attribute,
  estimationConfigLineItemVersions_estimationConfigLineItemVersions_edges as LineItemVersion,
} from 'src/api/types/estimationConfigLineItemVersions';
import { HistoryModal } from 'src/components/HistoryModal';
import { GET_LINE_ITEM_ACTIVITY_HISTORY } from 'src/features/settings/api/queries/lineItems';
import { formattedUnitCost } from 'src/features/settings/utils/unitCost';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

interface VersionHistoryModalProps {
  onClose: () => void;
  lineItem: LineItem | null;
}

export const VersionHistoryModal: React.FC<VersionHistoryModalProps> = ({
  onClose,
  lineItem,
}) => {
  const { data, fetchMore, loading } = useQuery(
    GET_LINE_ITEM_ACTIVITY_HISTORY,
    {
      variables: { lineItemId: lineItem?.id },
      fetchPolicy: 'cache-and-network',
    },
  );

  const lineItemVersions: LineItemVersion[] | [] =
    data?.estimationConfigLineItemVersions?.edges ?? [];

  const hasNextPage =
    data?.estimationConfigLineItemVersions?.pageInfo?.hasNextPage;
  const endCursor = data?.estimationConfigLineItemVersions?.pageInfo?.endCursor;

  if (hasNextPage && endCursor) {
    fetchMore({
      variables: {
        lineItemId: lineItem?.id,
        after: endCursor,
      },
    });
  }

  /*
  In the future we will be displaying things like margin, name, and tax which
  will all require being displayed slightly differently.
 */
  const lineItemAttributeFormatter = (attribute: Attribute) => {
    const attributeName = attribute?.attributeName;
    switch (attributeName) {
      case 'unit_cost': {
        return `$${formattedUnitCost(
          attribute.oldValue,
        )} to $${formattedUnitCost(
          attribute.newValue,
        )} / ${lineItemQuantityUnits(
          lineItem?.quantityUnits as QuantityUnitsEnum,
        )}`;
      }
      default: {
        return `${attribute.oldValue} to ${attribute.newValue}`;
      }
    }
  };

  return (
    <HistoryModal
      name={lineItem?.name}
      versions={lineItemVersions}
      isLoadingPages={loading || hasNextPage}
      isOpen
      onClose={onClose}
      attributeFormatter={lineItemAttributeFormatter}
    />
  );
};
