import { ReactNode, PureComponent } from 'react';

import { Body, Box } from '@hover/blueprint';

import * as FormatUtils from 'src/utils/Formatters';

import { ErrorMessageContainer } from './styled';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export, @typescript-eslint/no-explicit-any
export default class ErrorMessage extends PureComponent<any> {
  render(): ReactNode {
    const { error } = this.props;
    const attribute = FormatUtils.sentenceCase(
      error.attribute.split('_').join(' '),
    );
    return (
      <ErrorMessageContainer data-test-id="order-modal-error-message">
        <Box as="ul" flexDirection="column" marginY={0}>
          {error.errors.map((message: string) => (
            <Body
              size={400}
              marginY={0}
              as="li"
              listStyleType="circle"
              key={message}
            >
              {message}
            </Body>
          ))}
        </Box>
      </ErrorMessageContainer>
    );
  }
}
