/* eslint-disable camelcase */
import { createAsyncAction, createAction } from 'typesafe-actions';

import { EstimationDiscountUpdate } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup } from 'src/api/types/EstimateGroup';
import { estimateUpdate_update_estimate } from 'src/api/types/estimateUpdate';

export const estimateGroupCreate = createAsyncAction(
  'ESTIMATOR/ESTIMATE_GROUP_CREATE_REQUEST',
  'ESTIMATOR/ESTIMATE_GROUP_CREATE_SUCCESS',
  'ESTIMATOR/ESTIMATE_GROUP_CREATE_FAILURE',
)<void, EstimateGroup_estimationEstimateGroup, Error>();

export const updatePromotion = createAction(
  'ESTIMATOR/UPDATE_PROMOTION',
  (params: EstimationDiscountUpdate) => ({ params }),
)();

export const toggleEstimateActivationEnd = createAction(
  'ESTIMATOR/TOGGLE_ESTIMATE_ACTIVATION_END',
  (estimate: estimateUpdate_update_estimate) => ({ estimate }),
)();

export const fetchEstimateGroup = createAsyncAction(
  'ESTIMATOR/FETCH_ESTIMATE_GROUP_REQUEST',
  'ESTIMATOR/FETCH_ESTIMATE_GROUP_SUCCESS',
  'ESTIMATOR/FETCH_ESTIMATE_GROUP_FAILURE',
)<{ estimateGroupId: string | string[] }, void, Error>();

export const RECEIVE_ESTIMATE_GROUP = 'ESTIMATOR/RECEIVE_ESTIMATE_GROUP';
export const receiveEstimateGroup = createAction(
  RECEIVE_ESTIMATE_GROUP,
  (estimateGroup: EstimateGroup_estimationEstimateGroup) => estimateGroup,
)();

export const toggleHasDismissedMissingVariationsWarning = createAction(
  'EXTERIOR_ESTIMATOR/TOGGLE_HAS_DISMISSED_MISSING_VARIATIONS_WARNING',
  (payload: boolean) => payload,
)();

export const fetchEstimateGroupsForJob = createAsyncAction(
  'ESTIMATOR/FETCH_ESTIMATE_GROUPS_FOR_JOB_REQUEST',
  'ESTIMATOR/FETCH_ESTIMATE_GROUPS_FOR_JOB_SUCCESS',
  'ESTIMATOR/FETCH_ESTIMATE_GROUPS_FOR_JOB_FAILURE',
)<
  { jobId: string | string[] },
  EstimateGroup_estimationEstimateGroup[],
  Error
>();
