import { useEffect, useState } from 'react';

import { Box, Body, Image, LoadingOverlay, Heading } from '@hover/blueprint';
import { push } from 'connected-react-router';
import moment from 'moment';
import pluralize from 'pluralize';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import {
  inspectionChecklist as InspectionChecklistQueryType,
  inspectionChecklist_inspectionChecklist_sections_questions as InspectionChecklistQuestion,
  inspectionChecklist_inspectionChecklist_sections_questions_images as InspectionChecklistImage,
} from 'src/api/types/inspectionChecklist';
import {
  ImageLightboxModal,
  LightboxImage,
} from 'src/components/blueprint/Lightbox/ImageLightboxModal';
import { JOB_DETAILS } from 'src/features/exteriorEstimator/apis/queries';
import { GET_INSPECTION_CHECKLIST } from 'src/features/exteriorEstimator/apis/queries/checklist';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { getParams } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  checklistAnswerFormatter,
  checklistSortOrder,
} from 'src/features/exteriorEstimator/utils/ChecklistAnswerUtils';
import { useQueryEhi, useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  orgId: getParams(state).orgId,
  jobDetails: state.exteriorEstimator.job.jobDetails,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      push,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const InspectionChecklistShowComponent: React.FC<Props> = (props) => {
  const { jobId, orgId, jobDetails } = props;
  const dispatch = useDispatch();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const { data: jobDetailsData } = useQueryEhi(JOB_DETAILS, {
    variables: { ids: [Number(jobId)] },
  });

  useEffect(() => {
    const result = jobDetailsData?.jobs?.results?.[0];
    if (result) {
      dispatch(estimatorActions.getJobDetails.success({ jobDetails: result }));
    }
  }, [jobDetailsData, dispatch]);

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.estimator.checklist.page,
      job_id: Number(jobId),
    });
  }, [jobId]);

  const [currentImageSet, setCurrentImageSet] = useState<LightboxImage[]>([]);
  const [isChecklistImageModalOpen, setIsChecklistImageModalOpen] =
    useState(false);

  const closeChecklistImageModal = () => {
    setIsChecklistImageModalOpen(false);
  };

  const [initialModalImageUrl, setInitialModalImageUrl] = useState<string>('');

  const { data, stopPolling, error } =
    useQueryEhi<InspectionChecklistQueryType>(GET_INSPECTION_CHECKLIST, {
      variables: { jobId, orgId },
      fetchPolicy: 'no-cache',
    });
  if (error) {
    stopPolling();
  }
  const checklist = data && data.inspectionChecklist;
  if (!checklist) {
    return <LoadingOverlay isLoading />;
  }

  stopPolling();

  const mapImagesToLightboxImages = (
    images: InspectionChecklistImage[],
    lightboxTitle: string,
  ) => {
    return images?.map((image) => {
      return {
        url: image.redirectUrl,
        title: lightboxTitle,
      };
    });
  };

  const handleImageClick = (
    question: InspectionChecklistQuestion,
    imageUrl: string,
  ) => {
    setInitialModalImageUrl(imageUrl);

    const mappedImages: LightboxImage[] = mapImagesToLightboxImages(
      question.images,
      question.questionText,
    );

    setCurrentImageSet(mappedImages);
    setIsChecklistImageModalOpen(true);
  };

  const imagesAvailable = (imageCount: number) => {
    return `${imageCount} ${pluralize('image', imageCount)} available`;
  };

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      opacity={1}
      flex={1}
      maxHeight={1}
      data-test-id="inspectionChecklistShow"
    >
      <Box display="block" width={1} height={500} zIndex={1}>
        <Box
          height={500}
          borderBottomWidth="1px"
          borderBottomColor="neutral400"
          boxShadow="distance600"
          alignItems="center"
          justifyContent="center"
        >
          <Body>{jobDetails?.locationLine1} Checklist</Body>
        </Box>
      </Box>
      <EstimatorResponsiveWrapper>
        <Body
          size={400}
          color="neutral400"
          data-test-id="inspectionChecklistShow-completedAt"
        >
          Completed {moment(checklist.updatedAt).format('M/D/YYYY, h:mm a')}
        </Body>
        {checklist.sections.sort(checklistSortOrder).map((section) => {
          return (
            <Box flexDirection="column">
              <Heading>{section.name}</Heading>
              {section.questions.sort(checklistSortOrder).map((question) => {
                return (
                  <Box
                    flexDirection="column"
                    borderBottom="1px solid"
                    borderBottomColor="neutral400"
                    key={question?.id}
                  >
                    <Box
                      flexDirection="row"
                      key={question.id}
                      data-test-id="inspectionChecklistShow-question"
                      justifyContent="space-between"
                      pt={500}
                      pb={500}
                    >
                      <Body as="span">{question.questionText}</Body>
                      <Body
                        as="span"
                        data-test-id="inspectionChecklistShow-answer"
                      >
                        {question.answerValue
                          ? checklistAnswerFormatter(
                              question.answerValue,
                              question.questionType as unknown as EstimationConfigInputInputTypeEnum,
                            )
                          : '-'}
                      </Body>
                    </Box>
                    {question?.images?.length > 0 && (
                      <Box flexDirection="column">
                        <Box
                          data-test-id={`inspectionChecklistShow-images-counter-${question.id}`}
                          marginBottom={200}
                          color="neutral500"
                          fontSize={200}
                        >
                          {imagesAvailable(question?.images?.length ?? 0)}
                        </Box>
                        <Box
                          data-test-id="inspectionChecklistShow-images"
                          paddingBottom={500}
                          overflowX="auto"
                        >
                          {question.images?.map((image) => {
                            return (
                              <Box
                                key={image.redirectUrl}
                                paddingLeft={100}
                                height={500}
                              >
                                <Image
                                  src={image.redirectUrl}
                                  alt="Checklist Image"
                                  height={500}
                                  borderRadius={300}
                                  objectFit="contain"
                                  data-test-id={`inspectionChecklistShow-images-${question.id}-${image.redirectUrl}`}
                                  onClick={() => {
                                    handleImageClick(
                                      question,
                                      image.redirectUrl,
                                    );
                                  }}
                                />
                              </Box>
                            );
                          })}
                          <ImageLightboxModal
                            isOpen={isChecklistImageModalOpen}
                            onClose={closeChecklistImageModal}
                            images={currentImageSet}
                            initialImageUrl={initialModalImageUrl}
                            data-test-id="modal"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </EstimatorResponsiveWrapper>
    </Box>
  );
};

export const InspectionChecklistShow = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InspectionChecklistShowComponent);
