import styled, { css } from 'styled-components';

export const Container = styled.nav`
  width: 100%;
  background: ${({ theme }) => theme.colors.extraLightGray};
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const FiltersContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const FilterStyles = css`
  box-sizing: border-box;
  border-radius: 4.27px;
  margin: 5px 10px;
  padding: 7px 10px;
  cursor: pointer;
`;

export const ActiveFilterStyle = css`
  border: 2px solid ${({ theme }) => theme.colors.defaultActionBlue};
  background: ${({ theme }) => theme.colors.lightBlueAlt};
`;

export const FilterWrapper = styled.div<{ isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background: ${({ theme }) => theme.colors.neutral000};
  ${FilterStyles};
  ${({ isActive }) =>
    isActive &&
    css`
      ${ActiveFilterStyle}
    `}
`;

export const Text = styled.p`
  ${({ theme }) => theme.mixins.font('book', 'normal')};
  margin: 0;
  padding: 0;
`;
