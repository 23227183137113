import { useCallback, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Panel } from '@hover/blueprint';
import { push } from 'connected-react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  SOD,
  ESTIMATOR_PRODUCTION,
} from 'src/features/exteriorEstimator/constants/urls';
import { GET_BRANCHES_FOR_DISTRIBUTOR } from 'src/features/projectManagement/apis/graphql/queries';
import { OrderModalERP } from 'src/features/projectManagement/components/ProductionView/OrderModal/OrderModalERP';
import {
  getParams,
  getOrgIdParam,
  getVendorForOrder,
  getVendorForOrderMaterialListItems,
} from 'src/features/projectManagement/redux/selectors';
import { RootState, RootAction } from 'src/types/reduxStore';

import { Billing } from './Billing/Billing';
import { Delivery } from './Delivery/Delivery';
import { Navbar } from './Navbar/Navbar';
import { OrderTotalsPanel } from './OrderTotalsPanel/OrderTotalsPanel';

const FixedWrapper = styled(Box)`
  position: fixed;
  top: 70px;
  margin-left: 50%;
  margin-top: 32px;
  z-index: 11;
`;

const AbsoluteContentWrapper: React.FC = ({ children, ...props }) => (
  <Box
    position="absolute"
    top="70px"
    width={1}
    display="flex"
    flexDirection="column"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
);

const Section: React.FC = ({ children, ...props }) => (
  <Box width={1} justifyContent="center" maxWidth="1000px" {...props}>
    <Box flex={2}>{children}</Box>
    <Box flex={1} />
  </Box>
);

export const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  orgId: getOrgIdParam(state),
  listItems: getVendorForOrderMaterialListItems(state),
  distributor: getVendorForOrder(state)?.distributor, // Distr for this order
});

export const mapDispatchToProps = (dispatch: Redux.Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pushRoute: push,
    },
    dispatch,
  );

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/**
 * The set of fields handled in form state.
 */
export type OrderFormFields = {
  distributionBranchId: string;
  distributionJobAccountId: string;
};

export const Checkout: React.FunctionComponent<ComponentProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: ComponentProps) => {
  const { pushRoute, jobId, orgId, distributor, listItems } = props;

  const returnToPmp = useCallback(() => {
    pushRoute(`${ESTIMATOR_PRODUCTION}?jobId=${jobId}&orgId=${orgId}`);
    return null;
  }, [jobId, orgId, pushRoute]);

  useEffect(() => {
    if (!jobId) {
      pushRoute(SOD);
    } else if (!listItems?.length) {
      returnToPmp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnToPmp]);

  const distributorId = distributor?.id;
  // On first render, query for list of branches for
  // this Org + Distributor combination.
  const {
    loading: loadingBranches,
    data,
    error,
  } = useQuery(GET_BRANCHES_FOR_DISTRIBUTOR, {
    variables: { distributorId, orgId },
  });

  const methods = useForm<OrderFormFields>({
    criteriaMode: 'all',
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <Box flexDirection="column">
        <OrderModalERP />
        <Box position="relative">
          <Box position="fixed" zIndex={12}>
            <Navbar />
          </Box>
          <AbsoluteContentWrapper>
            <Panel
              paddingTop={600}
              width={1}
              flexDirection="column"
              alignItems="center"
              backgroundColor="neutral100"
            >
              <Section>
                <Billing
                  loadingBranches={loadingBranches}
                  branches={data?.distributionApprovedBranches}
                  error={error}
                />
              </Section>
            </Panel>
            <FixedWrapper>
              <OrderTotalsPanel onPressCancel={returnToPmp} orgId={orgId} />
            </FixedWrapper>
            <Box width={1} flexDirection="column" alignItems="center">
              {/* wrap each content block in section to preserve space to right */}
              <Section>
                <Delivery />
              </Section>
            </Box>
          </AbsoluteContentWrapper>
        </Box>
      </Box>
    </FormProvider>
  );
});
