import { createAction } from 'typesafe-actions';

import { Page } from 'src/features/exteriorEstimator/types';

export const storePages = createAction(
  'ESTIMATOR/STORE_PAGES',
  ({
    pages,
    isManualMeasurementsNeeded,
  }: {
    pages: Page[];
    isManualMeasurementsNeeded: boolean;
  }) => ({ pages, isManualMeasurementsNeeded }),
)();
