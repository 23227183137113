type MeasurementUnitProps = {
  unit: string;
};

const MeasurementUnit = ({ unit }: MeasurementUnitProps) => {
  if (unit === 'sqft') {
    return (
      <span>
        {' '}
        ft<sup>2</sup>
      </span>
    );
  }

  if (unit === 'lf') {
    return <span> lf</span>;
  }

  return <span>{unit}</span>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MeasurementUnit;
