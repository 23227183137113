import { createRoot } from 'react-dom/client';

import { Providers } from 'src/providers';

const container = document.getElementById('react-root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<Providers />);
