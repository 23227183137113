// can't use styled-components
export const containerStyle: React.CSSProperties = {
  padding: '0px 50px',
};

export const tableStyle: React.CSSProperties = {
  width: '100%',
  marginTop: '40px',
  textAlign: 'left',
};

export const headerWrapper: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const bold: React.CSSProperties = { fontWeight: 'bold' };
export const textAlignRight: React.CSSProperties = { textAlign: 'right' };

export const headerText: React.CSSProperties = {
  ...bold,
  fontSize: 'larger',
};

export const logoStyle: React.CSSProperties = { width: '200px' };

export const totalsTableStyleContainer: React.CSSProperties = {
  display: 'block',
  pageBreakInside: 'avoid',
};

export const totalsTableTyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '30px',
};

export const shortCol: React.CSSProperties = {
  width: '25%',
};

export const fatCol: React.CSSProperties = {
  width: '50%',
};

export const labelStyle: React.CSSProperties = {
  textTransform: 'uppercase',
};

export const footerStyle: React.CSSProperties = {
  display: 'inline-block',
  marginTop: 30,
};

export const footerWrapperStyle: React.CSSProperties = {
  fontSize: '12px',
  textAlign: 'center',
  fontFamily: 'Times New Roman',
  width: '100%',
};

export const grid: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '25% 20% 25% 15% [last] 15%',
};

export const gridWithUnitCost: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '20% 15% 20% 15% 15% [last] 15%',
};

export const col: React.CSSProperties = {
  padding: '8px',
  overflowWrap: 'break-word',
};

export const col1: React.CSSProperties = {
  ...col,
  gridColumnStart: 1,
};

export const col2: React.CSSProperties = {
  ...col,
  gridColumnStart: 2,
};

export const col3: React.CSSProperties = {
  ...col,
  gridColumnStart: 3,
};

export const col4: React.CSSProperties = {
  ...col,
  gridColumnStart: 4,
};

export const col5: React.CSSProperties = {
  ...col,
  gridColumnStart: 5,
};

export const col6: React.CSSProperties = {
  ...col,
  gridColumnStart: 6,
};

export const lastCol: React.CSSProperties = {
  ...col,
  ...textAlignRight,
  gridColumnStart: 'last',
};

export const tableRow: React.CSSProperties = {
  borderBottom: '1px solid lightgrey',
};

export const tableHeader: React.CSSProperties = {
  ...tableRow,
  ...bold,
  backgroundColor: '#f5f5f5',
};

export const vendorHeader: React.CSSProperties = {
  ...headerText,
  textTransform: 'uppercase',
  padding: '20px 0 5px',
};
