import {
  Input,
  PristineMeasurement,
} from 'src/features/exteriorEstimator/types/Questions';

import { MEASUREMENT_NAME } from '../constants/questionCategories';

export class PristineMeasurementsGenerator {
  static initialize(questions: Input[]) {
    const pristineMeasurements: PristineMeasurement[] = questions
      .filter((question) => question.inputCategory.name === MEASUREMENT_NAME)
      .map((question) => ({
        questionId: question.id,
        answer: question.answer,
      }));
    return pristineMeasurements;
  }
}
