import { PureComponent } from 'react';

import {
  Icon,
  Body,
  Box,
  Button,
  Heading,
  TextInput,
  Panel,
  Select,
} from '@hover/blueprint';
import { iDollarSign, iPlus, iX } from '@hover/icons';
import autobind from 'autobind-decorator';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { withTypewriter } from 'src/components/WithTypewriter';
import { Heading as EstimatorHeading } from 'src/features/exteriorEstimator/components/common/EstimatorHeading';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  organizeTemplatesByTrade,
  getSelectedTrades,
  getParams,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  CustomLineItem,
  CustomLineItemErrorInput,
} from 'src/features/exteriorEstimator/types';
import {
  getCustomLineItemsFromCache,
  saveCustomLineItemsToCache,
} from 'src/features/exteriorEstimator/utils/cacheUtils';
import { validateCustomLineItems } from 'src/features/exteriorEstimator/utils/customItemsUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

interface CustomLineItemArgument extends CustomLineItem {
  index: number;
}

export const mapStateToProps = (state: RootState) => ({
  customLineItems: state.exteriorEstimator.inputs.customLineItems,
  templates: organizeTemplatesByTrade(state),
  selectedTrades: getSelectedTrades(state),
  jobDetails: state.exteriorEstimator.job.jobDetails,
  commonProps: getUserTrackingProps(state),
  jobId: getParams(state).jobId,
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      storeCustomLineItems: estimatorActions.getCustomLineItems.success,
      updateCustomLineItems: estimatorActions.updateCustomLineItems.success,
    },
    dispatch,
  );

type IProps = {
  customLineItems: CustomLineItem[] | null;
  typewriter: any;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

export class CustomLineItemsPageComponent extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { storeCustomLineItems, jobId } = this.props;
    if (!jobId) return;

    const customLineItems = getCustomLineItemsFromCache(Number(jobId));
    if (customLineItems) storeCustomLineItems({ customLineItems });
  }

  componentDidUpdate(prevProps: Props) {
    const { customLineItems } = this.props;
    if (
      !prevProps.customLineItems &&
      customLineItems &&
      !customLineItems.length
    )
      // design calls for the form to be initialized with at least one empty custom item if none already exist
      this.addBlankCustomItem();
  }

  @autobind
  updateCustomLineItems(newCustomLineItems: CustomLineItem[]) {
    const { updateCustomLineItems, jobId, selectedTrades } = this.props;

    if (!newCustomLineItems || !jobId) return;

    const theseCustomLineItems = validateCustomLineItems(
      newCustomLineItems,
      selectedTrades,
    ) as CustomLineItem[];
    saveCustomLineItemsToCache(theseCustomLineItems, Number(jobId));
    updateCustomLineItems({ customLineItems: theseCustomLineItems });
  }

  @autobind
  addBlankCustomItem() {
    const { customLineItems, selectedTrades, jobId } = this.props;

    if (!customLineItems || !jobId) return;

    const newCustomLineItems = customLineItems ? [...customLineItems] : [];
    newCustomLineItems.push({
      key: '',
      value: '',
      tradeType: selectedTrades[0] ?? undefined,
      type: LineItemTypeEnum.MATERIAL,
    });
    this.updateCustomLineItems(newCustomLineItems);
  }

  handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  customItemProps(customItem: CustomLineItem) {
    return {
      line_item_name: customItem?.key,
      line_item_price: customItem?.value,
      line_item_type: customItem?.type,
      trade_type: customItem?.tradeType,
    };
  }

  @autobind
  handleAddItem() {
    const { jobDetails, commonProps, typewriter } = this.props;
    this.addBlankCustomItem();
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      button_text: 'Add Item',
      primary_cta: true,
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  handleRemoveItem(index: number) {
    const { customLineItems, commonProps, jobDetails, typewriter } = this.props;
    if (!customLineItems) return;
    const newCustomLineItems = [...customLineItems];
    const removedItem = newCustomLineItems.splice(index, 1);
    this.updateCustomLineItems(newCustomLineItems);

    typewriter.buttonPressed({
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      button_text: 'Delete Item',
      primary_cta: false,
      ...commonProps,
      ...jobProps(jobDetails),
      ...this.customItemProps(removedItem[0]),
    });
  }

  @autobind
  handleLabelChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name: index, value: newKey } = e.target;

    const { customLineItems } = this.props;
    if (!customLineItems) return;

    const newCustomLineItems = [...customLineItems];
    newCustomLineItems[Number(index)] = {
      ...customLineItems[Number(index)],
      key: newKey,
    };
    this.updateCustomLineItems(newCustomLineItems);
  }

  @autobind
  handleLabelBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const { value: newKey } = e.target;
    const { commonProps, jobDetails, typewriter } = this.props;

    typewriter.textInput({
      input_value: newKey,
      input_label: 'Name',
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  handleTradeChange(value: string, index: number) {
    const { customLineItems, commonProps, jobDetails, typewriter } = this.props;
    if (!customLineItems) return;

    const newCustomLineItems = [...customLineItems];
    newCustomLineItems[index] = {
      ...customLineItems[index],
      tradeType: value,
    };
    this.updateCustomLineItems(newCustomLineItems);

    typewriter.optionSelected({
      option_type: 'dropdown',
      selection: value,
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      primary_cta: false,
      options: 'Trade',
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  handleTypeChange(value: string, index: number) {
    const { customLineItems, jobDetails, commonProps, typewriter } = this.props;

    if (!customLineItems) return;
    const newCustomLineItems = [...customLineItems];

    newCustomLineItems[index] = {
      ...customLineItems[index],
      type: value,
    };
    this.updateCustomLineItems(newCustomLineItems);

    typewriter.optionSelected({
      option_type: 'dropdown',
      selection: value,
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      primary_cta: false,
      options: 'Type',
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name: index, value } = e.currentTarget;
    const { customLineItems } = this.props;
    if (!customLineItems) return;
    const newCustomLineItems = [...customLineItems];

    const isValidUSDRegex = /^-?\d*?\.?\d*?$/;
    if (!isValidUSDRegex.test(value)) return;
    newCustomLineItems[Number(index)] = {
      ...newCustomLineItems[Number(index)],
      value,
    };
    this.updateCustomLineItems(newCustomLineItems);
  }

  @autobind
  handleBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const { name: index, value } = event.currentTarget;
    const { customLineItems, commonProps, jobDetails, typewriter } = this.props;
    if (!customLineItems) return;
    const newCustomLineItems = [...customLineItems];
    const newValue = numeral(value).format('-00.00');

    newCustomLineItems[Number(index)] = {
      ...newCustomLineItems[Number(index)],
      value: newValue,
    };
    this.updateCustomLineItems(newCustomLineItems);

    typewriter.textInput({
      input_value: newValue,
      input_label: 'Value',
      page_or_screen_name: EventNames.estimator.customItemsPage.page,
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  renderInput({
    key,
    value,
    error,
    index,
    tradeType,
    type,
  }: CustomLineItemArgument) {
    const { selectedTrades } = this.props;

    const tradeTypeSelectionOptions =
      selectedTrades &&
      selectedTrades.map((selectedTradeType) => ({
        value: selectedTradeType ?? '',
        index,
      }));

    const typeSelectionOptions = Object.keys(LineItemTypeEnum).map(
      (selectedType) => ({
        value: selectedType,
        index,
      }),
    );

    return (
      <Box key={`${index}_container`} flexDirection="column" width={1}>
        <Box
          backgroundColor="neutral.100"
          padding={100}
          alignItems="center"
          flex={1}
          marginY={100}
        >
          <Box flexWrap="wrap" flex={1} alignItems="center">
            <Box margin={100} flexGrow={1} flexShrink={0} flexBasis={160}>
              <TextInput
                flex={1}
                value={key}
                placeholder="Name"
                onChange={this.handleLabelChange}
                onBlur={this.handleLabelBlur}
                name={index.toString()}
                isInvalid={
                  error && error.input === CustomLineItemErrorInput.KEY
                }
                key={`${index}_label`}
                data-test-id="itemName"
              />
            </Box>
            <Box margin={100} flexGrow={1} flexShrink={0} flexBasis={160}>
              <Select
                defaultValue={tradeType}
                onChange={(e) => this.handleTradeChange(e.target.value, index)}
                isInvalid={
                  error && error.input === CustomLineItemErrorInput.TRADE_TYPE
                }
              >
                {tradeTypeSelectionOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </Select>
            </Box>
            <Box margin={100} flexGrow={1} flexShrink={0} flexBasis={160}>
              <Select
                defaultValue={type}
                onChange={(e) => this.handleTypeChange(e.target.value, index)}
              >
                {typeSelectionOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </Select>
            </Box>
            <Box margin={100} flexGrow={1} flexShrink={0} flexBasis={160}>
              <TextInput
                flex={1}
                iconBefore={iDollarSign}
                value={value}
                placeholder="0.00"
                onChange={this.handleAmountChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                name={index.toString()}
                key={`${index}_value`}
                data-test-id="itemValue"
                type="number"
                inputMode="decimal"
              />
            </Box>
          </Box>
          <Button
            shape="circle"
            fill="minimal"
            color="danger"
            data-test-id="removeItem"
            onClick={() => this.handleRemoveItem(index)}
            label="removeItem"
          >
            <Icon icon={iX} />
          </Button>
        </Box>
        {error && (
          <Body
            marginTop={0}
            mx={100}
            size={300}
            color="dangerBase"
            data-test-id="custom-item-input-error"
          >
            {error.text}
          </Body>
        )}
      </Box>
    );
  }

  renderResponsive({
    customLineItems,
  }: {
    customLineItems: CustomLineItem[] | null;
  }) {
    return (
      <EstimatorResponsiveWrapper>
        <Box justifyContent="center">
          <EstimatorHeading data-test-id="add-custom-item-page-header">
            Add Custom Item
          </EstimatorHeading>
        </Box>
        {customLineItems &&
          customLineItems.map(({ key, value, error, tradeType, type }, index) =>
            this.renderInput({
              key,
              value,
              error,
              index,
              tradeType,
              type,
            }),
          )}
        <Box>
          <Button
            my={400}
            fill="outline"
            size="large"
            width="100%"
            onClick={this.handleAddItem}
            data-test-id="addItem"
            iconBefore={iPlus}
          >
            Add Item
          </Button>
        </Box>
        <Box justifyContent="center">
          <Panel
            marginY="600"
            padding="600"
            alignItems="center"
            width="750"
            backgroundColor="neutral.0"
            boxShadow="distance500"
            data-test-id="tool-tip"
          >
            <Heading color="neutral.600" size={300}>
              PRO TIP
            </Heading>
            <Body color="neutral.600">
              You can add additional items in scope for the job by entering a
              description and specifying a number in the Total $ field.
            </Body>
          </Panel>
        </Box>
      </EstimatorResponsiveWrapper>
    );
  }

  render() {
    const { customLineItems } = this.props;
    return this.renderResponsive({
      customLineItems,
    });
  }
}

export const CustomLineItemsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(CustomLineItemsPageComponent));
