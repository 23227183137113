import { Body } from '@hover/blueprint';
import moment from 'moment';

import { EstimateGroupData } from 'src/features/exteriorEstimator/types';

type Props = {
  createdAt: EstimateGroupData['createdAt'];
  format?: string;
};

export const Timestamp: React.FC<Props> = ({
  createdAt,
  format = 'MM/DD/YYYY, h:mm A',
}) => {
  const formattedDateTime = moment(createdAt).format(format);

  return (
    <Body size={500} color="neutral800" marginY={200} fontFamily="Avenir Next">
      Created {formattedDateTime}
    </Body>
  );
};
