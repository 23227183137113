import { useState } from 'react';

import {
  Body,
  Box,
  Button,
  Heading,
  TileCheckbox,
  Icon,
} from '@hover/blueprint';
import {
  iThumbsUp,
  iThumbsDown,
  iSmartphone,
  iTablet,
  iMonitor,
  iMoreHorizontal,
} from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

const enum DeviceTypesEnum {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Laptop = 'Laptop',
  Other = 'Other',
}

interface DeviceTypesType {
  [k: string]: boolean;
}

const DeviceTypes: DeviceTypesType = {
  [DeviceTypesEnum.Mobile]: false,
  [DeviceTypesEnum.Tablet]: false,
  [DeviceTypesEnum.Laptop]: false,
  [DeviceTypesEnum.Other]: false,
};

interface PostSubmitProps {
  handleClickSubmit: () => void;
}

export const PostSubmit: React.FC<PostSubmitProps> = ({
  handleClickSubmit,
}) => {
  const [needsCustomization, setNeedsCustomization] = useState<boolean | null>(
    null,
  );
  const [deviceTypes, setDeviceTypes] = useState<DeviceTypesType>(DeviceTypes);

  const screenName = 'Sales Presentation Sign Up Info';
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleNeedsCustomizationChange = (value: boolean) => () => {
    setNeedsCustomization(value);
    typewriter.optionSelected({
      option_type: 'radio',
      selection: value ? 'Yes' : 'No',
      page_or_screen_name: screenName,
      primary_cta: false,
      options: 'Yes, I do. / No, thanks',
      ...commonTrackingProps,
    });
  };

  const handleDeviceChange = (value: DeviceTypesEnum) => () => {
    const selectedDeviceType = { [value]: !deviceTypes[value] };
    setDeviceTypes({ ...deviceTypes, ...selectedDeviceType });
    if (value) {
      typewriter.checkboxSelected({
        selection: value,
        page_or_screen_name: screenName,
        primary_cta: false,
        options: 'Mobile, Tablet, Laptop, Other',
        ...commonTrackingProps,
      });
    }
  };

  return (
    <Box flexDirection="column" alignItems="center">
      <Heading size={600} marginY="0" padding="0">
        Thank you for signing up!
      </Heading>
      <Body size={600} marginY="0" padding="0">
        Just a few more questions to help us assist you better. You may skip
        this step.
      </Body>

      <Box flexDirection="column" flex={1}>
        <Box flexDirection="column" flex={1}>
          <Body>
            Do you need assistance customizing the presentation template by
            HOVER?
          </Body>
          <Box>
            <Box flexDirection="row" gap={400}>
              <TileCheckbox
                flex={1}
                onChange={handleNeedsCustomizationChange(true)}
                isChecked={
                  needsCustomization === null ? false : !!needsCustomization
                }
              >
                <Icon icon={iThumbsUp} size="large" />
                <Body m={0}>Yes, I do</Body>
              </TileCheckbox>
              <TileCheckbox
                flex={1}
                isChecked={
                  needsCustomization === null ? false : !needsCustomization
                }
                onChange={handleNeedsCustomizationChange(false)}
              >
                <Icon icon={iThumbsDown} size="large" />
                <Body m={0}>No, I don&#39;t</Body>
              </TileCheckbox>
            </Box>
          </Box>
        </Box>

        <Box flexDirection="column" flex={1}>
          <Body>Device used for presentation (select all that apply)</Body>
          <Box gap={400}>
            <TileCheckbox
              flex={1}
              onChange={handleDeviceChange(DeviceTypesEnum.Mobile)}
              isChecked={deviceTypes[DeviceTypesEnum.Mobile]}
            >
              <Icon icon={iSmartphone} size="large" />
              <Body>Mobile</Body>
            </TileCheckbox>
            <TileCheckbox
              flex={1}
              onChange={handleDeviceChange(DeviceTypesEnum.Tablet)}
              isChecked={deviceTypes[DeviceTypesEnum.Tablet]}
            >
              <Icon icon={iTablet} size="large" />
              <Body>Tablet</Body>
            </TileCheckbox>
            <TileCheckbox
              flex={1}
              onChange={handleDeviceChange(DeviceTypesEnum.Laptop)}
              isChecked={deviceTypes[DeviceTypesEnum.Laptop]}
            >
              <Icon icon={iMonitor} size="large" />
              <Body>Laptop</Body>
            </TileCheckbox>
            <TileCheckbox
              flex={1}
              onChange={handleDeviceChange(DeviceTypesEnum.Other)}
              isChecked={deviceTypes[DeviceTypesEnum.Other]}
            >
              <Icon icon={iMoreHorizontal} size="large" />
              <Body>Other</Body>
            </TileCheckbox>
          </Box>
        </Box>

        <Box>
          <Button
            mt={600}
            size="large"
            isDisabled={!deviceTypes}
            onClick={handleClickSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
