import { Box, IconButton } from '@hover/blueprint';
import { iPlusCircle, iEdit, iChevronUp, iChevronDown } from '@hover/icons';

import { InspectionConfigChecklistQuestionTypeEnum } from 'src/api/graphql-global-types';
import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate_sections_questions_options as QuestionOptionType } from 'src/api/types/inspectionConfigChecklistTemplate';
import { SortDirectionType } from 'src/constants';

import { TemplateQuestionCreateOrUpdate } from './TemplateQuestionCreateOrUpdate';
import { TemplateQuestionDeleteIcon } from './TemplateQuestionDeleteIcon';

interface IconBarProps {
  templateQuestionId: string;
  templateId: string;
  templateSectionId: string;
  questionText: string;
  questionType: InspectionConfigChecklistQuestionTypeEnum | null;
  supportsImageUploads: string;
  sortOrder: number | null;
  options: QuestionOptionType[] | null;
  updateQuestionOrder: (questionId: string, direction: number) => void;
  isFirst: boolean;
  isLast: boolean;
}

export const IconBar: React.FC<IconBarProps> = ({
  templateQuestionId,
  templateId,
  templateSectionId,
  questionText,
  questionType,
  supportsImageUploads,
  sortOrder,
  options,
  updateQuestionOrder,
  isFirst,
  isLast,
}) => {
  const onSortOrderUp = async () => {
    await updateQuestionOrder(templateQuestionId, SortDirectionType.asc);
  };

  const onSortOrderDown = async () => {
    await updateQuestionOrder(templateQuestionId, SortDirectionType.desc);
  };

  return (
    <Box position="absolute" bottom="-25px" right="0px" background="neutral.50">
      <Box width="146px">
        <Box alignItems="center" mb={200} ml={200}>
          <TemplateQuestionCreateOrUpdate
            templateId={templateId}
            templateSectionId={templateSectionId}
            sortOrder={(sortOrder || 0) + 1}
          >
            <IconButton
              label="addTemplateSection"
              color="neutral"
              icon={iPlusCircle}
              fill="minimal"
            />
          </TemplateQuestionCreateOrUpdate>
          <TemplateQuestionCreateOrUpdate
            templateId={templateId}
            templateSectionId={templateSectionId}
            questionId={templateQuestionId}
            text={questionText}
            type={questionType}
            supportsImage={supportsImageUploads}
            sortOrder={sortOrder}
            options={options}
          >
            <IconButton
              label="editTemplateSection"
              color="neutral"
              icon={iEdit}
              fill="minimal"
            />
          </TemplateQuestionCreateOrUpdate>
          <TemplateQuestionDeleteIcon
            templateQuestionId={templateQuestionId}
            templateId={templateId}
          />
        </Box>
        <Box flexDirection="column">
          <IconButton
            isDisabled={isFirst}
            label="SortUpTemplateSection"
            color="neutral"
            icon={iChevronUp}
            fill="minimal"
            onClick={onSortOrderUp}
          />
          <IconButton
            isDisabled={isLast}
            label="SortDownTemplateSection"
            color="neutral"
            icon={iChevronDown}
            fill="minimal"
            onClick={onSortOrderDown}
          />
        </Box>
      </Box>
    </Box>
  );
};
