import { gql } from '@apollo/client';

export const GET_TEMPLATES_WITH_LINE_ITEMS = gql`
  query estimationConfigTemplatesWithLineItems($orgId: ID!, $after: String) {
    estimationConfigTemplates(orgId: $orgId, after: $after, first: 20) {
      nodes {
        id
        name
        orgId
        tradeType
        sortOrder
      }
      edges {
        node {
          id
          name
          orgId
          tradeType
          sortOrder
          lineItems {
            id
            type
            name
            unitCost
            quantityUnits
            tradeTypes
            netCoverageLineItemPreset {
              id
              value
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const UPDATE_CONFIG_LINE_ITEM = gql`
  mutation estimationConfigLineItemUpdate(
    $id: ID!
    $lineItemAttributes: EstimationConfigLineItemUpdateInput!
  ) {
    estimationConfigLineItemUpdate(
      id: $id
      lineItemAttributes: $lineItemAttributes
    ) {
      lineItem {
        id
        name
        netCoverageLineItemPreset {
          id
          value
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_CONFIG_TEMPLATE = gql`
  mutation estimationConfigTakeoffTemplateUpdate(
    $id: ID!
    $templateAttributes: EstimationConfigTemplateUpdateInput!
  ) {
    estimationConfigTemplateUpdate(
      id: $id
      templateAttributes: $templateAttributes
    ) {
      errors {
        attribute
        errors
      }
      template {
        id
        name
      }
    }
  }
`;

export const COPY_ESTIMATION_CONFIG_TEMPLATE = gql`
  mutation estimationConfigTemplateCopy($templateId: ID!) {
    estimationConfigTemplateCopy(templateId: $templateId) {
      errors {
        attribute
        errors
      }
    }
  }
`;
