/* eslint-disable camelcase */
import {
  LineItemTypeEnum,
  DistributionDeliveryMethodEnum,
} from 'src/api/graphql-global-types';
import { distributionOrder_distributionOrder as DistributionOrder } from 'src/api/types/distributionOrder';
import {
  getProducts,
  getProducts_productCatalogProducts_edges_node_variations as Variation,
  getProductsVariables as GetProductsApiParams,
} from 'src/api/types/getProducts';
import {
  getSalesOpportunities_salesOpportunities_productionList_listItems as ListItem,
  getSalesOpportunities_salesOpportunities_productionList_listItems_vendor as ListItemVendorType,
  getSalesOpportunities_salesOpportunities_productionList as ProductionListType,
  getSalesOpportunities_salesOpportunities_soldEstimateGroup as SoldEstimateGroupType,
} from 'src/api/types/getSalesOpportunities';
import {
  jobDetails_jobs_results as JobType,
  jobDetails,
} from 'src/api/types/jobDetails';
import { projectManagementOrderDocumentCreateVariables as orderDocumentCreateVariables } from 'src/api/types/projectManagementOrderDocumentCreate';
import {
  projectManagementOrderDocuments_projectManagementOrderDocuments as OrderDocumentType,
  projectManagementOrderDocuments_projectManagementOrderDocuments_pdf as OrderDocumentPdfType,
} from 'src/api/types/projectManagementOrderDocuments';
import { projectManagementOrderFulfillmentStart_projectManagementOrderFulfillmentStart_order as ProjectManagementOrder } from 'src/api/types/projectManagementOrderFulfillmentStart';
import {
  VendorQuery_vendors_distributor as ProductProvider,
  VendorQuery_vendors as VendorType,
} from 'src/api/types/VendorQuery';
import { RestDiscountType } from 'src/constants'; // deprecated. New GraphQL API is using enum type
import { ORDER_DOCUMENT_CREATE } from 'src/features/projectManagement/apis/graphql/queries/queries';
import {
  SET_VENDOR_FOR_ORDER,
  TOGGLE_PDF_MODAL,
  UPDATE_PDF_MODAL_STATE,
  POLL_PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
  FETCH_PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
  RECEIVE_ORDER_DOCUMENT,
  RECEIVE_ORDER_DOCUMENTS,
} from 'src/features/projectManagement/redux/actions';

export type { ehiOrgSettings_ehiOrgSettings as OrgSettings } from 'src/api/types/ehiOrgSettings';
export type {
  productSearch_productCatalogProductSearch as ProductSearchResult,
  productSearch_productCatalogDistributor as ProductSearchDistributor,
} from 'src/api/types/productSearch';

export type { Variation, VendorType };
export interface createPdfVariables extends orderDocumentCreateVariables {
  refetch: () => void;
  eventName: string;
}

interface ListItemType extends ListItem {
  product?: Product;
}

export type {
  JobType,
  jobDetails as JobDetailsType,
  OrderDocumentPdfType,
  OrderDocumentType,
  ListItemType,
};

export type { ProductionListType, SoldEstimateGroupType };

export interface GetProductionDataPayload {
  isGettingProductionData?: boolean;
  jobDetails: JobType | null;
  jobMeasurements: null;
  estimateDetails: SoldEstimateGroupType;
  user: UserType;
  productionList: ProductionListType;
  vendors: VendorType[];
  salesOpportunities: SalesOpportunityType[];
}

export interface Discount {
  value: number;
  discountType: RestDiscountType['percentage'] | RestDiscountType['dollars']; // deprecated. New GraphQL API is using enum type
  id: number;
  name: string;
}

export interface EstimateType {
  id: number;
  createdAt: Date;
  discount: number;
  discounts: Discount[];
  name: string;
  userId: number;
  lineItems: LineItemType[];
  tradeType: string;
  active?: boolean;
}

export interface LineItemType {
  custom: boolean | null | undefined;
  wasteFactor: number;
  quantity: number;
  results: string;
}

export interface UserType {
  first_name: string;
  last_name: string;
}

export interface SalesOpportunityType {
  id: number;
  productionList?: ProductionListType;
  soldEstimate?: EstimateType;
  soldEstimateGroup?: SoldEstimateGroupType;
}

export interface ListItemTypeWithError extends ListItemType {
  errors?: string[];
}
export interface OrderCheckListItemsErrorsType {
  attribute: string;
  errors: string[];
}
export interface OrderCheckListItemsType {
  id: number;
  productName: string;
  errors: OrderCheckListItemsErrorsType[];
}

export interface CostBreakdownType {
  pretaxCost: number;
  profitMargin: number;
  profitAmount: number;
}

export interface SortedMaterialItemGroup {
  [vendor: string]: TableItemType;
}

export interface TableItemType {
  listItems: ListItemType[];
  subtotal: number;
  salesTaxSubtotal: number;
  vendor?: ListItemVendorType | null;
}

export type LineItemsType = ListItemType[];

export interface CategoryLineItems {
  [vendorName: string]: TableItemType;
}

export interface LineItemCategoriesSortedByVendorsType {
  [categoryName: string]: CategoryLineItems;
}

export interface VendorNamesSortedAlphabeticallyType {
  [categoryName: string]: Array<string>;
}

export interface AddListItemErrors {
  type?: LineItemTypeEnum | null;
  itemName?: string | null;
  name?: string | null;
}

export interface Problem {
  path: string[];
  explanation: string;
}

export interface Extensions {
  problems: Problem[];
}

export interface GraphQLError {
  extensions: Extensions;
}

export interface GraphQLAddListItemErrors {
  graphQLErrors: [];
}

export interface ProductsData {
  products: Product[];
}

export interface Product {
  id: string;
  name: string;
  provider: ProductProvider;
  variations: Variation[];
}

export interface LogoType {
  url: string;
}

export interface ProductColor {
  name: string;
}

export type { GetProductsApiParams };

export interface ProductsMap {
  [productId: string]: Product;
}

export interface MapProductsToListItemsType {
  listItems: ListItemType[];
  productsMap: ProductsMap;
}

export interface GetProductsResponse {
  data: getProducts;
}

export interface ReceiveProducts {
  products: ProductsMap;
}

export interface ReceiveListItemsWithProductsParams {
  listItemsWithProduct: ListItemType[];
  currentListItems: ListItemType[];
}

export interface AlphaNumericConfig {
  allowedChars: string[];
}

export interface OrderErrorType {
  attribute: string;
  errors: string[];
}

export interface OrderCheckType {
  errors: OrderErrorType[];
  listItems?: ListItemTypeWithError[];
  lineItems?: ListItemTypeWithError[];
}

export interface OrderModalStateTypes {
  Pristine?: string;
  Loading: string;
  GenericError: string;
  OrderDetailsForm: string;
  OrderErrors: string;
}

export interface ToggleOrderPayload {
  show: boolean;
}

export interface TogglePdfPayload {
  show: boolean;
  type?: string;
  vendor?: VendorType;
}

export interface VendorForOrderPayload {
  vendor: VendorType | undefined;
}

export interface TogglePdfModalAction {
  type: typeof TOGGLE_PDF_MODAL;
  payload: TogglePdfPayload;
}

export interface SetVendorForOrderAction {
  type: typeof SET_VENDOR_FOR_ORDER;
  payload: VendorForOrderPayload;
}

export interface OrderStateType {
  Queued: string;
  Failed: string;
  Submitted: string;
  Pristine?: string;
  Checking?: string;
  CheckSuccess?: string;
  CheckFailed?: string;
}

export interface GetProductionListParams {
  orgId: number;
  jobId: number;
  orderId?: number;
}

export interface GetProductionListResponseData {
  projectManagementProductionList: ProductionListType;
  salesOpportunities: SalesOpportunityType[];
}

export interface GetProductionListResponse {
  data: GetProductionListResponseData;
}

export interface UpdatePdfModalStatePayload {
  pdfModalState: string;
}

export interface UpdatePdfModalStateAction {
  type: typeof UPDATE_PDF_MODAL_STATE;
  payload: UpdatePdfModalStatePayload;
}

export interface ProjectManagementOrderDataType {
  vendorForOrder?: VendorType | null;
  orderModalState?: string;
  isPollingProductionList?: boolean;
  errorMessage?: string;
  lostListItems?: ListItemType[];
  projectManagementOrder?: ProjectManagementOrder | null;
  distributionOrder?: DistributionOrder | null;
  selectedDeliveryMethod?: DistributionDeliveryMethodEnum | null;
}

export interface ProjectManagementOrderBranchAccountType {
  distributionBranchId: string;
  distributionJobAccountId: string;
  orgId: string;
}

export type Validator = (value: string) => boolean;

export interface InputDataType {
  labelText: string;
  valueField: string;
  errorField: string;
}

export interface InputsType {
  [key: string]: InputDataType;
}

export interface CustomizePdfOutputPayload {
  shouldShowCostDetails?: boolean;
  shouldShowItemMeasurements?: boolean;
  shouldShowTotalCost?: boolean;
}

export interface OrderDocumentCreateAction {
  type: typeof ORDER_DOCUMENT_CREATE;
  payload: createPdfVariables;
}

export interface PollProjectManagementOrderDocumentsPayload {
  productionListId: number;
  orderDocumentIds: string[];
}

export interface PollProjectManagementOrderDocumentsAction {
  type: typeof POLL_PROJECT_MANAGEMENT_ORDER_DOCUMENTS;
  payload: PollProjectManagementOrderDocumentsPayload;
}

export interface FetchProjectManagementOrderDocumentsPayload {
  productionListId: number;
  orderDocumentIds?: string[];
}

export interface FetchProjectManagementOrderDocumentsAction {
  type: typeof FETCH_PROJECT_MANAGEMENT_ORDER_DOCUMENTS;
  payload: FetchProjectManagementOrderDocumentsPayload;
}

export interface ReceiveOrderDocumentAction {
  type: typeof RECEIVE_ORDER_DOCUMENT;
  payload: OrderDocumentType;
}

export interface ReceiveOrderDocumentsAction {
  type: typeof RECEIVE_ORDER_DOCUMENTS;
  payload: OrderDocumentType[];
}

export interface OrderDocumentStateType {
  Complete: string;
  Generating: string;
}

export interface ErrorModalType {
  isOpen: boolean;
  message?: string | null;
}

export interface CategorizedItemsType {
  [category: string]: ListItemType[];
}

export interface ListItemUpdateFromVendorAttributesToUpdate {
  // Attributes with a true value will be updated
  sku: boolean;
  unitCost: boolean;
}

export interface ListItemUpdateFromVendorParams {
  listItemId: number;
  attributesToUpdate: ListItemUpdateFromVendorAttributesToUpdate;
}

export type OrderModalStateStrings =
  | 'ItemError'
  | 'OrderError'
  | 'Loading'
  | 'GenericError';
