import { createAction } from 'typesafe-actions';

import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';

export interface updateTemplateSearchFilter {
  value: string;
}

export interface UpdateSelectedTemplate {
  id: number;
}

export interface UnselectTemplates {
  ids: number[];
}

export interface GetTemplatesEnd {
  templates: Template[];
}

export interface InitializeSelectedTemplatesEnd {
  templateIds: number[];
}

export const toggleSelectedTemplate = createAction(
  'EXTERIOR_ESTIMATOR/TOGGLE_SELECTED_TEMPLATE',
  ({ id }: UpdateSelectedTemplate) => ({ id }),
)();

export const unselectTemplates = createAction(
  'EXTERIOR_ESTIMATOR/UNSELECT_TEMPLATES',
  ({ ids }: UnselectTemplates) => ({ ids }),
)();

export const deselectAllTemplates = createAction(
  'EXTERIOR_ESTIMATOR/DESELECT_ALL_TEMPLATES',
  () => null,
)();

export const initializeSelectedTemplates = createAction(
  'EXTERIOR_ESTIMATOR/INITIALIZE_SELECTED_TEMPLATES',
  ({ templateIds }: InitializeSelectedTemplatesEnd) => ({ templateIds }),
)();

export const initializeTemplates = createAction(
  'EXTERIOR_ESTIMATOR/INITIALIZE_TEMPLATES',
  () => null,
)();

export const getTemplatesEnd = createAction(
  'EXTERIOR_ESTIMATOR/GET_TEMPLATES_END',
  ({ templates }: GetTemplatesEnd) => ({ templates }),
)();

export const updateTemplateSearchFilter = createAction(
  'EXTERIOR_ESTIMATOR/GET_TEMPLATES_SEARCH_FILTER',
  ({ value }: updateTemplateSearchFilter) => ({ value }),
)();
