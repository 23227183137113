import { isNil } from 'lodash';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import {
  inspectionChecklist_inspectionChecklist_sections_questions as InspectionChecklistQuestion,
  inspectionChecklist_inspectionChecklist_sections as InspectionChecklistSection,
} from 'src/api/types/inspectionChecklist';

export const checklistAnswerFormatter = (
  answerText: string,
  questionType: EstimationConfigInputInputTypeEnum | null,
) => {
  if (questionType === EstimationConfigInputInputTypeEnum.BOOLEAN) {
    if (answerText === 'true') return 'Yes';
    return 'No';
  }
  return answerText;
};

// sorts by sortOrder ascending, nulls sorted last
export const checklistSortOrder = (
  a: InspectionChecklistQuestion | InspectionChecklistSection,
  b: InspectionChecklistQuestion | InspectionChecklistSection,
) => {
  if (a.sortOrder === b.sortOrder) {
    return 0;
  }
  if (isNil(a?.sortOrder)) {
    return 1;
  }
  if (isNil(b.sortOrder)) {
    return -1;
  }
  return a.sortOrder < b.sortOrder ? -1 : 1;
};
