import { cloneDeep } from 'lodash';
import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const discountReducer = createReducer(initialState)
  .handleAction(
    estimatorActions.receiveDiscount,
    (state, { payload: { discount } }) => ({
      ...state,
      discounts: {
        ...state.discounts,
        [discount.id]: discount,
      },
    }),
  )
  .handleAction(
    estimatorActions.removeDiscount.success,
    (state, { payload: { discountId } }) => {
      const newState = cloneDeep({ ...state, selectedPromotionId: null });
      delete newState.discounts[discountId];
      return newState;
    },
  )
  .handleAction(
    estimatorActions.setSelectedPromotionId,
    (state, { payload: { promotionId } }) => ({
      ...state,
      inputs: {
        ...state.inputs,
        selectedPromotionId: promotionId,
      },
    }),
  );
