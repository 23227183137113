import { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import { find, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  TradeTypeEnum,
  ProjectManagementConfigProposalTemplatePageTypeEnum,
} from 'src/api/graphql-global-types';
import { projectManagementConfigOrgProposalSettingSelfService } from 'src/api/types/projectManagementConfigOrgProposalSettingSelfService';
import { proposalPagesAndTemplates } from 'src/api/types/proposalPagesAndTemplates';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import {
  GET_PROPOSAL_SETTING,
  GET_TEMPLATES_AND_PROPOSAL_PAGES,
} from 'src/features/settings/api/queries/proposals';
import { ScopeOfWorkDataContainer as ScopeOfWork } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/ScopeOfWorkDataContainer';
import { NotificationHandlers } from 'src/features/settings/components/Settings';
import { getUserOrgId } from 'src/redux/selectors';
import { sentenceCase } from 'src/utils/Formatters';

import { Section } from './Section';

export const SectionDataContainer: React.FC<NotificationHandlers> = ({
  setNotification,
}) => {
  const { sectionId } = useParams();
  const history = useHistory();
  const orgId = useSelector(getUserOrgId);

  const setSuccessNotification = useCallback(
    () =>
      setNotification({
        show: true,
        severity: 'Confirmation',
        notification: 'Proposal section successfully saved',
      }),
    [setNotification],
  );

  const setFailureNotification = useCallback(
    () =>
      setNotification({
        show: true,
        severity: 'Error',
        notification: 'There was an issue while saving, try again',
      }),
    [setNotification],
  );

  const navigateToProposalList = useCallback(
    () => history.push(`/workflows/proposal/`),
    [history],
  );

  const { data, loading, fetchMore } = useQuery<proposalPagesAndTemplates>(
    GET_TEMPLATES_AND_PROPOSAL_PAGES,
    {
      variables: { orgId },
    },
  );

  const {
    data: { projectManagementConfigOrgProposalSetting } = {},
    loading: settingsLoading,
  } = useQuery<projectManagementConfigOrgProposalSettingSelfService>(
    GET_PROPOSAL_SETTING,
    {
      variables: { orgId },
    },
  );

  if (
    !data ||
    !projectManagementConfigOrgProposalSetting ||
    loading ||
    settingsLoading
  )
    return <LoaderSpinner show />;

  const { hasNextPage, endCursor } = data.estimationConfigTemplates.pageInfo;

  if (hasNextPage) {
    fetchMore({
      variables: {
        orgId,
        after: endCursor,
      },
    });
  }

  // de-dupe trades (multiple templates can be of same trade)
  const tradeOptions = uniqBy(
    data.estimationConfigTemplates.edges,
    'node.tradeType',
  ).map((edge) => ({
    value: edge?.node?.tradeType as string,
    displayValue: sentenceCase(edge?.node?.tradeType ?? '') as string,
    name: edge?.node?.tradeType as string,
  }));
  const tradeOptionsForSelect = [
    {
      name: 'all',
      value: 'ALL',
      displayValue: 'All Trades',
    },
    ...tradeOptions,
  ];

  const viewMode = !!sectionId ? 'VIEW_EDIT' : 'CREATE';

  // TODO: just query for one page when BE provides in https://hoverinc.atlassian.net/browse/ES-115
  const { showOrgLogo, showTitle, title, tradeType, content, pageType } =
    find(data.projectManagementConfigProposalTemplate?.pages, {
      id: sectionId,
    }) ?? {};

  return pageType ===
    ProjectManagementConfigProposalTemplatePageTypeEnum.LIST_ITEMS ? (
    <ScopeOfWork
      showOrgLogo={!!showOrgLogo}
      showTitle={!!showTitle}
      title={title as string}
      setSuccessNotification={setSuccessNotification}
      setFailureNotification={setFailureNotification}
      navigateToProposalList={navigateToProposalList}
    />
  ) : (
    <Section
      tradeOptions={tradeOptionsForSelect}
      viewMode={viewMode}
      showOrgLogo={viewMode === 'CREATE' ? true : !!showOrgLogo}
      showTitle={viewMode === 'CREATE' ? true : !!showTitle}
      title={viewMode === 'CREATE' ? 'Add Section' : (title as string)}
      tradeType={
        viewMode === 'CREATE' ? TradeTypeEnum.ALL : (tradeType as TradeTypeEnum)
      }
      orgId={orgId}
      content={content}
      setSuccessNotification={setSuccessNotification}
      setFailureNotification={setFailureNotification}
      navigateToProposalList={navigateToProposalList}
      projectManagementConfigOrgProposalSetting={
        projectManagementConfigOrgProposalSetting
      }
    />
  );
};
