import { Body, Box } from '@hover/blueprint';
import { connect } from 'react-redux';

import {
  getDoesOrderCheckListItemHaveErrors,
  getOrderCheck,
} from 'src/features/projectManagement/redux/selectors';
import { RootState } from 'src/types/reduxStore';

import { ErrorList } from '../../Errors/ErrorList';

const mapStateToProps = (state: RootState) => ({
  orderCheck: getOrderCheck(state),
  hasItemErrors: getDoesOrderCheckListItemHaveErrors(state),
});

interface ComponentProps {
  distributorDisplayName: string;
}

type Props = ReturnType<typeof mapStateToProps> & ComponentProps;

const ItemErrorsComponent: React.FC<Props> = ({
  orderCheck,
  distributorDisplayName,
  hasItemErrors,
}) => {
  if (!hasItemErrors) return null;
  return (
    <Box
      flexDirection="column"
      alignItems="flex-start"
      paddingLeft={600}
      data-test-id="itemErrors-container"
    >
      <Box alignItems="flex-start">
        <Body size={400} margin={0} textAlign="left">
          The products below are missing details. You can still submit the
          order. Your {distributorDisplayName} Sales Rep will substitute any
          unavailable item(s).
        </Body>
      </Box>
      <Box width={1}>
        <ErrorList orderCheck={orderCheck} />
      </Box>
    </Box>
  );
};

export const ItemErrors = connect(mapStateToProps)(ItemErrorsComponent);
