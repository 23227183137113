import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const measurementsReducer = createReducer(initialState)
  .handleAction(
    estimatorActions.storeJobMeasurements,
    (state, { payload: { jobMeasurements } }) => ({
      ...state,
      job: {
        ...state.job,
        jobMeasurements,
      },
    }),
  )
  .handleAction(
    estimatorActions.storePristineMeasurements,
    (state, { payload: { pristineMeasurements } }) => ({
      ...state,
      job: {
        ...state.job,
        pristineMeasurements,
      },
    }),
  )
  .handleAction(estimatorActions.storeFullMeasurements, (state, action) => ({
    ...state,
    job: {
      ...state.job,
      fullMeasurements: action.payload.fullMeasurements,
    },
  }))
  .handleAction(estimatorActions.storeHdfMeasurements, (state, action) => ({
    ...state,
    job: {
      ...state.job,
      hdfMeasurements: action.payload.hdfMeasurements,
    },
  }))
  .handleAction(
    estimatorActions.storePartialsMeasurements,
    (state, action) => ({
      ...state,
      job: {
        ...state.job,
        partialsMeasurements: action.payload.partialsMeasurements,
      },
    }),
  )
  .handleAction(estimatorActions.storeGeometry, (state, action) => ({
    ...state,
    job: {
      ...state.job,
      geometry: action.payload.geometry,
    },
  }))
  .handleAction(estimatorActions.storeMetadata, (state, action) => ({
    ...state,
    job: {
      ...state.job,
      metadata: action.payload.metadata,
    },
  }))
  .handleAction(
    estimatorActions.getMeasurementsPlainJson.success,
    (state, { payload }) => {
      return {
        ...state,
        job: {
          ...state.job,
          plainMeasurements: {
            ...state.job.plainMeasurements,
            ...payload,
          },
        },
      };
    },
  );
