import { Box, Td, Tr, Icon, Button } from '@hover/blueprint';
import { iCheck, iEdit } from '@hover/icons';
import { isNil } from 'lodash';

import { DistributionOrderCheckLineItemCheckResultEnum as OrderCheckResultEnum } from 'src/api/graphql-global-types';
import type { distributionOrderCheck_distributionOrderCheck_lineItems as OrderCheckLineItem } from 'src/api/types/distributionOrderCheck';
import {
  SkuCellRenderer,
  VariationCellRenderer,
} from 'src/features/project/components/common/CellRenderers';
import {
  ItemErrorIcon,
  ItemWarningIcon,
} from 'src/features/project/components/common/CommonIcons';
import {
  formattedCost,
  formattedUnitCost,
  formattedNumber,
} from 'src/features/project/util/utils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

interface LineItemProps {
  lineItem: OrderCheckLineItem;
  // refetchListItems: () => void;
  setSelectedEditListItem: (lineItem: OrderCheckLineItem) => void;
}

export const OrderDetailListItem: React.FC<LineItemProps> = ({
  setSelectedEditListItem,
  lineItem,
}) => {
  // Result of the order check on this lineItem - SUCCESS | ERROR | FAILURE
  const orderCheckResult = lineItem.checkResult ?? OrderCheckResultEnum.SUCCESS;

  const displayUnits = lineItemQuantityUnits(lineItem.quantityUnits);

  const onEdit = () => {
    setSelectedEditListItem(lineItem);
  };

  const borderStyle = {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'neutral.300',
  };

  return (
    <>
      {/* LineItem data */}
      <Tr
        key={lineItem.id}
        data-test-id={`ListItemRow-${lineItem.id}`}
        lineHeight="1rem"
        verticalAlign="baseline"
        backgroundColor={
          // red background for FAILURE
          orderCheckResult === OrderCheckResultEnum.FAILURE
            ? 'danger.100'
            : 'none'
        }
        sx={
          // style for additional error text for non-SUCCESS
          orderCheckResult !== OrderCheckResultEnum.SUCCESS
            ? { '& td': { paddingBottom: '0px' } }
            : borderStyle
        }
      >
        {/* lineItem check status icon */}
        <Td width="48px" textAlign="center">
          {orderCheckResult === OrderCheckResultEnum.SUCCESS && (
            <Icon icon={iCheck} color="neutral.900" top="6px" />
          )}
          {orderCheckResult === OrderCheckResultEnum.FAILURE && (
            <ItemErrorIcon top="6px" />
          )}
          {orderCheckResult === OrderCheckResultEnum.ERROR && (
            <ItemWarningIcon top="6px" />
          )}
        </Td>
        <Td>{lineItem.productName}</Td>
        <Td>
          <VariationCellRenderer item={lineItem} />
        </Td>
        <Td>
          <SkuCellRenderer item={lineItem} />
        </Td>
        <Td>
          {!!lineItem.measurement && lineItem.measurement > 0 && (
            <Box>
              {formattedNumber(
                lineItem.measurement,
                null,
                lineItemQuantityUnits(lineItem.measurementUnits),
              )}
            </Box>
          )}
        </Td>
        <Td>
          <Box>{`${lineItem.quantity} ${displayUnits}`}</Box>
        </Td>
        <Td isNumeric>
          {!isNil(lineItem.unitCost)
            ? formattedUnitCost(lineItem.unitCost, displayUnits)
            : ''}
        </Td>
        <Td isNumeric>
          {!isNil(lineItem.pretaxCost)
            ? formattedCost(lineItem.pretaxCost)
            : ''}
        </Td>
        <Td>
          <Button
            data-test-id={`${lineItem.id}-edit`}
            fill="minimal"
            iconBefore={iEdit}
            onClick={onEdit}
            paddingX={2}
            marginLeft="auto"
            top="3px"
            fontWeight="normal"
            fontSize={14}
            color="neutral"
            shape="box"
          >
            Edit
          </Button>
        </Td>
      </Tr>
      {/* LineItem errors */}
      {orderCheckResult !== OrderCheckResultEnum.SUCCESS
        ? Object.values(lineItem.errors).map((error, attributeIndex) =>
            error.errors.map((errorItem, errorIndex) => (
              <Tr
                key={errorItem}
                backgroundColor={
                  // red background for FAILURE
                  orderCheckResult === OrderCheckResultEnum.FAILURE
                    ? 'danger.100'
                    : 'none'
                }
                className="orderErrorRow-tr"
                fontSize={100}
                sx={
                  attributeIndex === lineItem.errors.length - 1 &&
                  errorIndex === error.errors.length - 1
                    ? { ...borderStyle, '& td': { paddingTop: '0px' } }
                    : { '& td': { paddingTop: '0px', paddingBottom: '0px' } }
                }
              >
                <Td width="48px" />
                <Td
                  colSpan={8}
                  color={
                    // red text for FAILURE
                    orderCheckResult === OrderCheckResultEnum.FAILURE
                      ? 'danger.600'
                      : 'neutral.600'
                  }
                >
                  {errorItem}
                </Td>
              </Tr>
            )),
          )
        : null}
    </>
  );
};
