import { useState, useEffect } from 'react';

import {
  Panel,
  Box,
  IconButton,
  Heading,
  Field,
  Button,
  Select,
  Body,
  TextInput,
  Textarea,
} from '@hover/blueprint';
import { iPlusCircle, iTrash2 } from '@hover/icons';
import {
  useFieldArray,
  useForm,
  FormProvider,
  FieldArrayWithId,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { productCatalogCategories_productCatalogCategories_nodes as Category } from 'src/api/types/productCatalogCategories';
import { MATCH_GROUP } from 'src/features/settings/api/queries/calculationRules';
import { CreateEditFooter } from 'src/features/settings/components/ColorGroupingRules/CreateEditFooter';
import { ProductsMatchingCategoryModal } from 'src/features/settings/components/ColorGroupingRules/ProductsByCategoryModal';
import { CreateFormValues } from 'src/features/settings/components/ColorGroupingRules/types';
import { useProductCatalogCategories } from 'src/features/settings/hooks/useCategories';
import { useMatchGroups } from 'src/features/settings/hooks/useMatchGroups';
import {
  getCategories,
  getAllCategories,
} from 'src/features/settings/redux/selectors';
import {
  useLazyQueryEhi,
  useEffectOnMount,
  usePrevious,
  useTracking,
} from 'src/hooks';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

const Sidebar: React.FC = ({ children }) => {
  return (
    <Box width="30%" flexDirection="column">
      <Box backgroundColor="primary100" flexDirection="column" padding={500}>
        {children}
      </Box>
    </Box>
  );
};
const EditRuleSidebar = () => {
  return (
    <Sidebar>
      <Heading size={200}>Pro Tip</Heading>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        Simplify the color selections your team must make when preparing an
        estimate or material order with HOVER.
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        1. Combine categories to increase matches between products
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        2. Include more categories based on your organization process
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        3. At the moment category can be used only once for matching rules
      </Body>
    </Sidebar>
  );
};

const CreateRuleSidebar = () => {
  return (
    <Sidebar>
      <Heading size={200}>How Does It Work?</Heading>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        Create matching rules to simplify color selection when using HOVER for
        estimation or material ordering.
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        1. Name and add description for matching. (Visible only for Admins)
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        2. Product colors within selected categories will match if applicable
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        3. At the moment category can be used only once for matching rules
      </Body>
      <Body fontWeight="100" fontSize="200" marginBottom="revert">
        4. Create more rules to increase automatization for your team
      </Body>
    </Sidebar>
  );
};

export const ColorGroupingRulesCreateEdit: React.FC = () => {
  const { groupId } = useParams();
  const editMode = !!groupId;
  const allCategories = useSelector(getAllCategories);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [categories, setCategories] = useState<Category[] | null>(null);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const {
    register,
    handleSubmit,
    formState,
    formState: { isValid, errors, isDirty },
    reset,
    control,
    getValues,
    setValue,
    watch,
    ...methods
  } = useForm<CreateFormValues>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      name: '',
      description: '',
      dropdowns: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'dropdowns',
    control,
  });

  const dropdowns = watch('dropdowns');
  const prevDropdowns = usePrevious(dropdowns);

  // this makes textarea update properly
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const description = watch('description');

  const orgId = useSelector(getUserOrgId);
  const { fetchProductCatalogCategories } = useProductCatalogCategories();
  const { fetchMatchGroups } = useMatchGroups();

  const orgCategories = useSelector(getCategories);

  const [getMatchGroup, { data: matchGroupData }] = useLazyQueryEhi(
    MATCH_GROUP,
    {
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'no-cache',
    },
  );

  useEffectOnMount(() => {
    if (groupId) getMatchGroup({ variables: { id: groupId } });

    typewriter.pageViewed({
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      ...commonTrackingProps,
    });
  });

  useEffect(() => {
    if (matchGroupData && matchGroupData.productCatalogMatchGroup) {
      const matchGroup = matchGroupData.productCatalogMatchGroup;

      setValue('name', matchGroup.name);
      setValue('description', matchGroup.description ?? '');
      setValue(
        'dropdowns',
        matchGroup.categories.map((category: Category) => {
          return { value: category.id };
        }),
      );
    }
  }, [matchGroupData, setValue, append]);

  useEffect(() => {
    if (!orgCategories) fetchProductCatalogCategories(orgId);
  }, [orgId, fetchProductCatalogCategories, orgCategories]);

  useEffect(() => {
    const getMatchGroups = async () => {
      let groups = await fetchMatchGroups(orgId);
      if (matchGroupData && matchGroupData.productCatalogMatchGroup)
        groups = groups.filter(
          (g) => g.id !== matchGroupData.productCatalogMatchGroup.id,
        );
      const allUsedCategories = groups
        .map((group) => group.categories.map((category) => category.id))
        .flat();

      if (!orgCategories) return;

      setCategories(
        orgCategories?.filter(
          (category) => !allUsedCategories.includes(category.id),
        ),
      );
    };
    if (orgCategories) {
      getMatchGroups();
    }
  }, [orgId, orgCategories, fetchMatchGroups, matchGroupData]);

  useEffect(() => {
    if (!categories) return;
    if (editMode) return; // don't need to do this if editing
    append({ value: null });
  }, [categories, append, editMode]);

  useEffect(() => {
    if (!prevDropdowns) return;
    const oldValues = prevDropdowns
      ? (prevDropdowns as any).map((p: any) => p.value)
      : [];
    const newValues = dropdowns ? dropdowns.map((p) => p.value) : [];

    const diff = newValues.filter((x) => !oldValues.includes(x));

    if (diff && diff.length > 0 && diff[0]) {
      typewriter.optionSelected({
        option_type: 'dropdown',
        selection: diff[0],
        page_or_screen_name: editMode
          ? EventNames.settings.colorGrouping.edit
          : EventNames.settings.colorGrouping.create,
        primary_cta: false,
        options: 'Categories',
        ...commonTrackingProps,
      });
    }
  }, [dropdowns, prevDropdowns]);

  const addDropdown = () => {
    if (!categories) return;
    append({ value: null });
    typewriter.buttonPressed({
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      button_text: 'New Category Dropdown',
      primary_cta: false,
      backend_id_type: 'Category',
      ...commonTrackingProps,
    });
  };

  const trackRuleNameChanged = () => {
    typewriter.textInput({
      input_value: getValues().name,
      backend_id_type: 'MatchGroup',
      backend_id_value: matchGroupData?.productCatalogMatchGroup?.id,
      input_label: 'Name',
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      ...commonTrackingProps,
    });
  };

  const trackRuleDescChanged = () => {
    typewriter.textInput({
      input_value: getValues().description,
      backend_id_type: 'MatchGroup',
      backend_id_value: matchGroupData?.productCatalogMatchGroup?.id,
      input_label: 'Description',
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      ...commonTrackingProps,
    });
  };

  const addDropdownDisabled =
    categories &&
    dropdowns.filter((d) => d.value !== null).length === categories.length;

  const deleteDropdownDisabled = dropdowns.length <= 1;

  const matchedProductsCount = (matchGroupId: string | null) => {
    if (!matchGroupId || !categories) return null;
    const matchedCategory = categories.find(
      (category) => category.id === matchGroupId,
    );
    if (!matchedCategory) return null;
    const count = matchedCategory?.orgProductsCount;
    return (
      <Box marginLeft={500} alignItems="center">
        <Button
          fill="minimal"
          shape="box"
          padding="4px"
          fontWeight={500}
          data-test-id="orgProducts"
          onClick={() => setSelectedCategory(matchedCategory)}
        >
          <Body marginBottom="revert" textDecoration="underline">
            {count} products
          </Body>
        </Button>
        will match
      </Box>
    );
  };

  const renderUnavailableSelect = (
    field: FieldArrayWithId<CreateFormValues, 'dropdowns', 'id'>,
    category: Category | undefined,
  ) => {
    return (
      <Box
        flexDirection="row"
        marginBottom={400}
        key={field.id}
        justifyContent="space-between"
        alignItems="center"
      >
        <Select
          width="50%"
          placeholder={` ${category?.name} (Unavailable)`}
          data-test-id="unavailableCategory"
          disabled
        />
      </Box>
    );
  };

  const allCategoriesInUse =
    !editMode && categories !== null && categories.length === 0;

  const renderSelect = (
    field: FieldArrayWithId<CreateFormValues, 'dropdowns', 'id'>,
    index: number,
  ) => {
    return (
      <Box
        flexDirection="row"
        marginBottom={400}
        key={field.id}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="row" alignItems="center" width="100%">
          <Select
            width="50%"
            placeholder="Select"
            data-test-id="ruleCategories"
            value={dropdowns[index]?.value ?? ''}
            disabled={allCategoriesInUse}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            {...register(`dropdowns.${index}.value` as const)}
          >
            {categories &&
              categories.map((category) => {
                return (
                  <option
                    key={category.id}
                    value={category.id}
                    disabled={
                      !!dropdowns.find(
                        (dropdown) => dropdown.value === category.id,
                      )
                    }
                  >
                    {category.name}
                  </option>
                );
              })}
          </Select>
          {dropdowns[index]?.value !== null &&
            matchedProductsCount(dropdowns[index].value)}
        </Box>

        <IconButton
          label="removeCategory"
          icon={iTrash2}
          fill="minimal"
          onClick={() => {
            typewriter.buttonPressed({
              page_or_screen_name: editMode
                ? EventNames.settings.colorGrouping.edit
                : EventNames.settings.colorGrouping.create,
              button_text: 'Remove Category',
              primary_cta: false,
              backend_id_value: dropdowns[index].value || '',
              backend_id_type: 'Category',
              ...commonTrackingProps,
            });
            remove(index);
          }}
          color={deleteDropdownDisabled ? 'neutral' : 'primary'}
          isDisabled={deleteDropdownDisabled}
        />
      </Box>
    );
  };

  return (
    <Box flexDirection="column" height="calc(100vh - 146px)">
      <Heading data-test-id="CalculationRulesCreateHeader" size={600}>
        {editMode ? 'Edit' : 'Create'} Rule
      </Heading>
      <ProductsMatchingCategoryModal
        selectedCategory={selectedCategory}
        onClose={() => setSelectedCategory(null)}
      />
      <Box flexDirection="column" height={1} overflow="scroll">
        <Box flexDirection="row" justifyContent="space-between">
          <Box flexDirection="column" width="100%" marginRight={600}>
            <Panel
              width="100%"
              flexDirection="column"
              boxShadow="0px 2px 6px rgba(118, 118, 118, 0.4)"
              marginBottom={400}
              padding={400}
              backgroundColor="neutral50"
            >
              <FormProvider
                {...{
                  register,
                  formState,
                  handleSubmit,
                  watch,
                  getValues,
                  setValue,
                  control,
                  reset,
                  ...methods,
                }}
              >
                <Field
                  data-test-id="calculationRuleName"
                  error={errors?.name?.message}
                  label="Name (Required)"
                  name="name"
                  aria-required
                >
                  <TextInput
                    data-test-id="ruleName"
                    label="ruleName"
                    placeholder="E.g. match metal variations..."
                    {...register('name', {
                      required: 'name is required',
                    })}
                    onBlur={trackRuleNameChanged}
                  />
                </Field>
                <Field
                  data-test-id="calculationRulesDescription"
                  label="Description"
                  name="description"
                >
                  <Textarea
                    data-test-id="ruleDescription"
                    rows={4}
                    placeholder="E.g. what rule does in a few words to help with admin collaboration..."
                    {...register('description')}
                    onBlur={trackRuleDescChanged}
                  />
                </Field>
              </FormProvider>
            </Panel>
            <Body>Details</Body>
            <Panel
              width="100%"
              flexDirection="column"
              boxShadow="0px 2px 6px rgba(118, 118, 118, 0.4)"
              marginBottom={400}
              padding={400}
              backgroundColor="neutral50"
            >
              <Heading size={200} fontWeight="100">
                Grouping Variations
              </Heading>
              <Body size={400} color="neutral500">
                Material variants in category below will match if applicable.
                One or more categories can be included. Override automatched
                variants anytime.
              </Body>
              {allCategoriesInUse && (
                <Body color="danger.500">*All categories in use.</Body>
              )}
              {fields.map((field, index) => {
                const availableCategory = orgCategories?.find(
                  (c) => c.id === field.value,
                );
                const categoryFromConfig = allCategories?.find(
                  (c) => c.id === field.value,
                );

                if (!availableCategory && categoryFromConfig) {
                  // this means the category was added to the rule when the category was available to the org, but now it's not anymore for whatever reason
                  return renderUnavailableSelect(field, categoryFromConfig);
                }
                return renderSelect(field, index);
              })}
              <IconButton
                label="AddDropdown"
                icon={iPlusCircle}
                fill="minimal"
                onClick={addDropdown}
                color={addDropdownDisabled ? 'neutral' : 'primary'}
                isDisabled={!!addDropdownDisabled}
              />
            </Panel>
          </Box>
          {editMode ? <EditRuleSidebar /> : <CreateRuleSidebar />}
        </Box>
      </Box>
      <CreateEditFooter
        editMode={editMode}
        formValues={getValues()}
        saveDisabled={!isDirty || !isValid}
        matchGroup={matchGroupData?.productCatalogMatchGroup}
      />
    </Box>
  );
};
