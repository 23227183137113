import { Box, Select } from '@hover/blueprint';
import type { SelectProps } from '@hover/blueprint';
import type { CSSObject } from '@hover/blueprint/chakra';

export interface Option extends React.InputHTMLAttributes<HTMLOptionElement> {
  displayValue?: string | number | null;
}

export interface DropdownProps extends SelectProps {
  options: Option[];
  error?: boolean;
  placeholder?: string;
  sx?: CSSObject;
}

// temporary until Blueprint provides
export const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  defaultValue,
  size,
  onChange,
  error,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sx = {},
  placeholder = '---',
  ...props
}) => {
  return (
    <Box style={{ flex: 1 }}>
      <Select
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        size={size}
        isInvalid={!!error}
        {...props}
      >
        {!value && !defaultValue && (
          <option hidden value="">
            {placeholder}
          </option>
        )}

        {options?.map?.(
          ({ value: optionValue, displayValue, ...optionAttributes }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={optionValue} {...optionAttributes}>
              {displayValue || optionValue}
            </option>
          ),
        )}
      </Select>
    </Box>
  );
};
