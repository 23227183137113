import { Body, Menu, IconButton, MenuItem } from '@hover/blueprint';
import { iPlusCircle } from '@hover/icons';

import { TemplateQuestionCreateOrUpdate } from './TemplateQuestionCreateOrUpdate';
import { TemplateSectionCreateOrUpdate } from './TemplateSectionCreateOrUpdate';

interface TemplateSectionMenuOptionsProps {
  templateId: string;
  templateSectionId: string;
}

export const TemplateSectionMenuOptions = ({
  templateId,
  templateSectionId,
}: TemplateSectionMenuOptionsProps) => (
  <Menu
    inPortal
    trigger={
      <IconButton
        label="more-section-options"
        color="neutral"
        icon={iPlusCircle}
        fill="minimal"
        ml={100}
      />
    }
  >
    <MenuItem>
      <TemplateSectionCreateOrUpdate templateId={templateId}>
        <Body as="span">Add new section below</Body>
      </TemplateSectionCreateOrUpdate>
    </MenuItem>
    <MenuItem>
      <TemplateQuestionCreateOrUpdate
        templateId={templateId}
        templateSectionId={templateSectionId}
      >
        <Body as="span">Add new question below</Body>
      </TemplateQuestionCreateOrUpdate>
    </MenuItem>
  </Menu>
);
