import { gql } from '@apollo/client';

import { estimateFieldsForEstimateGroups } from 'src/api/queries/queries';

export const ESTIMATION_ESTIMATE_TOGGLE_ACTIVATION = gql`
  mutation update(
    $id: ID!
    $attributes: EstimateUpdate!
    $filterVariationsByOrg: Boolean
    $orgId: ID!
  ) {
    estimationEstimateUpdate(id: $id, estimateAttributes: $attributes) {
      estimate {
        estimateGroup {
          totalPrice
          monthlyPrice
          profitMargin
          profitAmount
          estimates {
            ...estimateFieldsForEstimateGroups
          }
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${estimateFieldsForEstimateGroups}
`;

export const ESTIMATION_ESTIMATE_TOGGLE_UNIQUE_ACTIVATION = gql`
  mutation estimateUpdate(
    $associatedId: ID!
    $associatedAttributes: EstimateUpdate!
    $id: ID!
    $attributes: EstimateUpdate!
    $filterVariationsByOrg: Boolean
    $orgId: ID!
  ) {
    associatedUpdate: estimationEstimateUpdate(
      id: $associatedId
      estimateAttributes: $associatedAttributes
    ) {
      errors {
        attribute
        errors
      }
    }
    update: estimationEstimateUpdate(id: $id, estimateAttributes: $attributes) {
      estimate {
        estimateGroup {
          totalPrice
          monthlyPrice
          profitMargin
          profitAmount
          estimates {
            ...estimateFieldsForEstimateGroups
          }
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${estimateFieldsForEstimateGroups}
`;

export const ESTIMATE_GROUP_FOR_VARIATION_SELECTION = gql`
  query EstimateGroupForVariationSelection($id: ID!) {
    estimationEstimateGroup(id: $id) {
      id
      enableVariationMatching
    }
  }
`;

export const ESTIMATION_ESTIMATE = gql`
  query estimationEstimate(
    $id: ID!
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    estimationEstimate(id: $id) {
      id
      jobId
      name
      estimateGroup {
        id
      }
      lineItems {
        id
        quantity
        price
        product {
          id
          name
          productCatalogCategory {
            id
            name
          }
          variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
            id
            name
            texture {
              diffuseMapThumbnail {
                redirectUrl
              }
            }
          }
        }
        selectedVariation {
          id
          name
        }
        allowVariationSelection
      }
    }
  }
`;
