import { Box, Body, Heading } from '@hover/blueprint';

interface InfoCardProps {
  icon: React.ReactElement;
  heading: string;
  body: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ icon, heading, body }) => (
  <Box
    height="400px"
    flexDirection="column"
    width="40%"
    padding={600}
    justifyContent="center"
    alignItems="center"
  >
    <Box
      borderRadius="50%"
      backgroundColor="#FFEEE6"
      height="70px"
      width="70px"
      alignItems="center"
      justifyContent="center"
    >
      {icon}
    </Box>
    <Heading size={200} mt={500}>
      {heading}
    </Heading>
    <Body textAlign="center">{body}</Body>
  </Box>
);
