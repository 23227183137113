import { useCallback, useEffect, useState } from 'react';

import { Panel, Body, Icon } from '@hover/blueprint';
import { iAlertTriangle } from '@hover/icons';
import { isEmpty, isNil, some, find } from 'lodash';
import { connect } from 'react-redux';

import {
  LineItemTypeEnum,
  ListItemUpdate as ListItemUpdateParams,
} from 'src/api/graphql-global-types';
import { estimateFields as EstimateFields } from 'src/api/types/estimateFields';
import { BottomBar } from 'src/features/projectManagement/components/ProductionView/BottomBar/BottomBar';
import { LineItemFilters } from 'src/features/projectManagement/constants/multiTrades';
import {
  TableItemType,
  VendorType,
  ListItemType,
} from 'src/features/projectManagement/types';
import { massageItemsForMultiTrades } from 'src/features/projectManagement/utils/MultiTradesUtils';
import {
  salesTaxPercent,
  postTaxTotal,
} from 'src/features/projectManagement/utils/ProductionListUtils';
import { getTradeTypesSorted } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';

import HeadRow from './HeadRow';
import {
  TableContainer,
  TableWrapper,
  TableName,
  TableHeadSection,
} from './styled';
import { TradeItem } from './TradeItem';
import { validateQuantity } from './TradeItem/QuantityInput';

export const mapStateToProps = (state: RootState) => ({
  tradeFilter: state.estimatorProductionTools.tradeFilter,
  jobEstimates: state.estimatorProductionTools.estimateDetails?.estimates,
  tradeTypes: getTradeTypesSorted(state),
});

interface TableProps {
  materials: TableItemType;
  updateListItem: (listItemId: number, params: ListItemUpdateParams) => void;
  vendor: VendorType;
  vendors: VendorType[];
  tableType: LineItemTypeEnum;
}

type Props = ReturnType<typeof mapStateToProps> & TableProps;

const TableComponent: React.FC<Props> = (props: Props) => {
  const [isSubmitOrderDisabled, setSubmitOrderDisabled] =
    useState<boolean>(false);
  const [isQuantityValid, setQuantityValid] = useState<boolean>(true);
  const [isColorSelectionValid, setColorSelectionValid] =
    useState<boolean>(true);
  const [isColorAvailableValid, setColorAvailableValid] =
    useState<boolean>(true);
  const [items, setItems] = useState<ListItemType[]>([]);

  // Member functions.
  const tableTitle = () => {
    const { tableType } = props;
    // eslint-disable-next-line react/destructuring-assignment
    const vendorName = props.vendor?.vendorName || 'OTHER';

    switch (tableType) {
      case LineItemTypeEnum.MATERIAL:
        return `MATERIALS LIST - ${vendorName}`;
      case LineItemTypeEnum.LABOR:
        return `LABOR LIST - ${vendorName}`;
      default:
        return `OTHER - ${vendorName}`;
    }
  };

  const renderPartialSidingWarning = () => {
    const { tableType, jobEstimates } = props;

    let isPartialSidingJob = false;
    if (!jobEstimates) return null;

    jobEstimates.forEach((estimate: EstimateFields) => {
      if (
        estimate.tradeType === 'SIDING' &&
        estimate.fullMeasurements === false
      )
        isPartialSidingJob = true;
    });

    // only show this if its a partial siding job
    // and if the table is a material table
    if (isPartialSidingJob && tableType === LineItemTypeEnum.MATERIAL) {
      return (
        <Panel
          data-test-id="pmpPartialSidingWarning"
          backgroundColor="neutral100"
          color="neutral600"
          flexDirection="row"
          justifyContent="left"
          alignItems="center"
          padding={100}
          marginTop={200}
          marginBottom={600}
        >
          <Icon
            icon={iAlertTriangle}
            size="medium"
            color="inherit"
            marginRight={400}
            marginLeft={400}
          />
          <Body size={400} color="inherit" textAlign="left" marginTop={500}>
            Manual adjustment for siding section. Review measurements and
            quantities.
          </Body>
        </Panel>
      );
    }

    return null;
  };

  const {
    materials,
    updateListItem,
    vendor,
    vendors,
    tradeFilter,
    tableType,
    tradeTypes,
  } = props;

  const calculatedPostTaxTotal = postTaxTotal(
    materials.subtotal,
    materials.salesTaxSubtotal,
  );

  useEffect(() => {
    const multiTradeItems = massageItemsForMultiTrades(
      materials.listItems,
      tradeTypes,
      tradeFilter,
      LineItemFilters.CREATED_AT,
    );
    setItems(multiTradeItems);
  }, [materials.listItems, tradeFilter, tradeTypes]);

  const areColorSelectionsValid = useCallback((lineItems: ListItemType[]) => {
    // Validate each item has a selected color/variation.
    const hasInvalidColorItems = some(lineItems, (item) => {
      return isNil(item.color) || isEmpty(item.color);
    });
    return !hasInvalidColorItems;
  }, []);

  const areColorAvailabilitiesValid = useCallback(
    (lineItems: (ListItemType & { isColorUnavailable?: boolean })[]) => {
      // First, mark any invalid/unavailable color in the line items;
      // also un-mark any previously-marked unavailable color after re-selecting an available color.
      lineItems.forEach((item) => {
        const matchingColor = find(item?.product?.variations, (variation) => {
          return variation.id === item.externalVariationId;
        });
        const foundItem = find(
          lineItems,
          (lineItem) => item.id === lineItem.id,
        );
        if (!isNil(foundItem)) {
          if (!isNil(item.externalVariationId) && isNil(matchingColor)) {
            // Flag the listItem as having an unavailable color,
            // in order to show dropdown error state.
            foundItem.isColorUnavailable = true;
          } else if (foundItem.isColorUnavailable) {
            // After selecting a valid color for a listItem that previously had
            // an unavailable color selected, delete the isColorUnavailable flag.
            delete foundItem.isColorUnavailable;
            // Clone the changed item in the lineItems array,to trigger re-render
            // of the table when a color selection is changed.
            lineItems.splice(lineItems.indexOf(item), 1, { ...foundItem });
          }
        }
      });
      // Second, validate each item has a selected color/variation
      // that's in their org-filtered colors/variations; i.e., has no item with an unavailable color.
      const hasInvalidColorAvailability = some(lineItems, (item) => {
        return !isNil(item.externalVariationId) && item.isColorUnavailable;
      });
      return !hasInvalidColorAvailability;
    },
    [],
  );

  const areQuantitySelectionsValid = useCallback(
    (lineItems) => {
      // Validate each item has a selected quantity.
      const hasInvalidQuantityItems = some(lineItems, (item) => {
        return !validateQuantity(
          item.quantity,
          vendor?.distributor?.supportsProductCatalog,
        );
      });
      return !hasInvalidQuantityItems;
    },
    [vendor?.distributor?.supportsProductCatalog],
  );

  useEffect(() => {
    // Do not validate colors for orders that don't have a vendor/distributor;
    // these will be either Labor or vendor-specific custom materials.
    if (!(isNil(vendor) || isNil(vendor.distributorId))) {
      // Validate each materials item has a selected color/variation.
      const colorSelectionsValid = areColorSelectionsValid(items);
      // If there are items without color selections, disable the Order Now button.
      setColorSelectionValid(colorSelectionsValid);
      // Validate each materials item has an available org-filtered selected color/variation.
      const colorAvailableValid = areColorAvailabilitiesValid(items);
      // If there are items with color selections that aren't available, disable the Order Now button.
      setColorAvailableValid(colorAvailableValid);
    }
    // Validate each materials item has a non-zero quantity.
    const quantitySelectionsValid = areQuantitySelectionsValid(items);
    // If there are items without quantity selections, disable the Order Now button.
    setQuantityValid(quantitySelectionsValid);
  }, [
    areColorSelectionsValid,
    areColorAvailabilitiesValid,
    areQuantitySelectionsValid,
    items,
    vendor,
  ]);

  useEffect(() => {
    // Aggregate valid state depends on isQuantityValid and isColorSelectionValid.
    // Set valid state when components change.
    setSubmitOrderDisabled(!isQuantityValid || !isColorSelectionValid);
  }, [isQuantityValid, isColorSelectionValid]);

  return items.length !== 0 ? (
    <TableContainer data-test-id="materials-table-container">
      <TableWrapper>
        <TableHeadSection>
          <tr>
            <TableName data-test-id="materials-table-name">
              {tableTitle()}
            </TableName>
          </tr>
        </TableHeadSection>
        <tbody>
          <tr>
            <td colSpan={2}>{renderPartialSidingWarning()}</td>
          </tr>
          <HeadRow tableType={tableType} />
          {items.map((materialItem) => (
            <TradeItem
              key={materialItem.id}
              tableType={tableType}
              listItem={materialItem}
              updateListItem={updateListItem}
              vendors={vendors}
            />
          ))}
        </tbody>
      </TableWrapper>
      <BottomBar
        salesTaxPercent={salesTaxPercent(
          calculatedPostTaxTotal,
          materials.subtotal,
        )}
        salesTax={materials.salesTaxSubtotal}
        pretaxCost={calculatedPostTaxTotal}
        subTotal={materials.subtotal}
        listItems={materials.listItems}
        vendor={vendor}
        type={tableType}
        isSubmitOrderDisabled={isSubmitOrderDisabled}
        isColorSelectionValid={isColorSelectionValid}
        isColorAvailableValid={isColorAvailableValid}
      />
    </TableContainer>
  ) : null;
};
export const Table = connect(mapStateToProps)(TableComponent);
