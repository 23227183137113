import styled from 'styled-components';

const Checkmark = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 3px;
    height: 20px;
    width: 20px;
    border: 1px solid
      ${({ checked, theme }) =>
        checked ? theme.colors.defaultActionBlue : theme.colors.defaultGray};
    border-radius: 3px;
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.defaultActionBlue : theme.colors.neutral000};
    transition-duration: 250ms;
    transition-timing-function: ease-in;
    transition-property: background-color, border;
  }

  &::after {
    left: 5px;
    top: 8px;
    position: absolute;
    content: ${({ checked }) => (checked ? '""' : 'none')};
    display: inline-block;
    height: 7px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: ${({ theme }) => theme.colors.neutral000};
    transform: rotate(-45deg);
    transition-duration: 250ms;
    transition-timing-function: ease-in;
    transition-property: content;
  }
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0 !important;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  text-align: initial;
  line-height: initial;
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-test-id'?: string;
}

export const Checkbox: React.FC<Props> = (props) => {
  const { checked, style, ...rest } = props;

  return (
    <Container style={style}>
      <Input
        {...rest}
        type="checkbox"
        checked={checked}
        data-test-id={rest['data-test-id'] ?? 'checkbox-input'}
      />
      <Checkmark checked={checked} />
    </Container>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Checkbox;
