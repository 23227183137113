import { ApolloQueryResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { uniqBy, isNil, isEmpty } from 'lodash';

import { estimationConfigLineItems as estimationConfigLineItemsType } from 'src/api/types/estimationConfigLineItems';
import { GET_ESTIMATION_CONFIG_LINE_ITEMS } from 'src/features/settings/api/queries/materials';
import { LineItems } from 'src/features/settings/components/MaterialList';
import { GraphqlClient } from 'src/lib/GraphqlClient';

const getEstimationConfigLineItems = (
  orgId: string,
  after: string | undefined,
): Promise<ApolloQueryResult<estimationConfigLineItemsType>> => {
  const endCursor = after;
  return GraphqlClient.query({
    query: GET_ESTIMATION_CONFIG_LINE_ITEMS,
    variables: {
      orgId,
      withProductsOnly: true,
      ...(endCursor && { endCursor }),
    },
    fetchPolicy: 'no-cache',
  });
};

export const getCombinedEstimationConfigLineItems = async (orgId: string) => {
  const combinedLineItems: LineItems = [];
  let after;
  let makeRequests = true;
  let requestsMade = 0;
  const maxRequests = 1000;

  while (makeRequests) {
    try {
      const {
        data: {
          estimationConfigLineItems: {
            nodes = [],
            pageInfo: { hasNextPage, endCursor } = {},
          } = {},
        } = {},
        errors,
        // eslint-disable-next-line no-await-in-loop
      } = await getEstimationConfigLineItems(orgId, after);
      // If query response contains an errors array, break out of loop,
      // return the errors and any partial data, as standard GQL data/errors response.
      if (!isEmpty(errors)) {
        return {
          data: uniqBy(combinedLineItems, 'product.id'),
          errors,
        };
      }
      after = endCursor as never;
      requestsMade += 1;

      if (!hasNextPage || requestsMade > maxRequests) {
        makeRequests = false;
      }

      nodes?.forEach((item) => {
        if (!isNil(item)) {
          combinedLineItems.push(item);
        }
      });
    } catch (err) {
      return {
        data: uniqBy(combinedLineItems, 'product.id'),
        errors: [err as GraphQLError],
      };
    }
  }

  // Return combined pages of data as standard GQL data/errors response.
  return {
    data: uniqBy(combinedLineItems, 'product.id'),
  };
};
