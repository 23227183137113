import { useState } from 'react';

import type { NumberFormatValues } from 'react-number-format';

import { NumberInput, NumberInputProps } from 'src/components';
import type { QuestionAnswer } from 'src/features/exteriorEstimator/types';

type Props = NumberInputProps & {
  handleInputChange: (answer: QuestionAnswer) => void;
};

export const NumberQuestion: React.FC<Props> = ({
  value: _value,
  handleInputChange,
  ...props
}) => {
  const [value, setValue] = useState(_value);

  const handleBlur = () => {
    setValue(value ?? 0);
    handleInputChange(value ?? 0);
  };

  const handleValueChange = (values: NumberFormatValues) => {
    setValue(values.floatValue);
  };

  return (
    <NumberInput
      {...props}
      value={value}
      onBlur={handleBlur}
      onValueChange={handleValueChange}
      min={0}
      thousandSeparator
      decimalScale={2}
      size="small"
      width="90px"
      data-test-id="estimator-number-input"
    />
  );
};
