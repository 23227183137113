import { useState } from 'react';

import { Button } from '@hover/blueprint';
import { iUpload } from '@hover/icons';
import { useSelector } from 'react-redux';

import typewriter from 'src/lib/telemetry/generated/segment';
import { getUserTrackingProps } from 'src/redux/selectors';

import { InputFileUploader } from './InputFileUploader';

export const SalesPresentationUploadButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const commonProps = useSelector(getUserTrackingProps);

  const track = () => {
    typewriter.buttonPressed({
      page_or_screen_name: 'Sales Presentation List',
      button_text: 'upload presentation',
      feature: 'Sales Presentation',
      primary_cta: false,
      ...commonProps,
    });
  };

  return (
    <InputFileUploader setLoading={setIsLoading} track={track}>
      <Button
        isLoading={isLoading}
        fill="solid"
        iconBefore={iUpload}
        color="primary"
      >
        Upload presentation
      </Button>
    </InputFileUploader>
  );
};
