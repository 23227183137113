import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { VendorType } from 'src/features/projectManagement/types';

import { StyledButton } from './BottomBarStyled';

interface Props {
  type: LineItemTypeEnum;
  vendor?: VendorType;
  isSubmitOrderDisabled: boolean;
  handleClick: () => void;
}

export const OrderButton: React.FC<Props> = ({
  type,
  vendor,
  isSubmitOrderDisabled,
  handleClick,
}) => {
  if (!vendor) return null;

  const identifier = vendor?.distributor?.identifier;
  const supportsProductOrdering = vendor?.distributor?.supportsProductOrdering;

  // this conditional was returing `undefined` sometimes
  // so wrapped in Boolean() so it's always a boolean and not `undefined`
  const shouldRender = Boolean(
    type === LineItemTypeEnum.MATERIAL &&
      supportsProductOrdering &&
      !!identifier,
  );

  return shouldRender ? (
    <StyledButton
      data-test-id="pmpOrderCTA"
      shape="box"
      marginRight={400}
      onClick={handleClick}
      isDisabled={isSubmitOrderDisabled}
    >
      Order Now
    </StyledButton>
  ) : null;
};
