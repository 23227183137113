import { gql } from '@apollo/client';

export const GET_ESTIMATION_CONFIG_LINE_ITEMS = gql`
  query estimationConfigLineItems(
    $orgId: ID!
    $endCursor: String
    $withProductsOnly: Boolean
  ) {
    estimationConfigLineItems(
      orgId: $orgId
      after: $endCursor
      withProductsOnly: $withProductsOnly
      first: 20
    ) {
      nodes {
        product {
          id
          name
          variationsCount
          productCatalogCategory {
            id
            name
          }
          productCatalogCategories {
            id
            name
          }
          orgVariationsCount(orgId: $orgId)
          provider {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
