import { Box } from '@hover/blueprint';

import { Question } from 'src/features/exteriorEstimator/components/EstimationTool/QuestionView/Question/Question';

import { ComponentProps } from './AdjustSidingMeasurement';

export const SidingQuestions: React.FC<ComponentProps> = ({
  questions,
  updateAnswer,
}) => {
  return (
    <Box paddingTop={400} flexDirection="column">
      {questions.map((question) => (
        <Question
          question={question}
          key={question.id}
          updateAnswer={updateAnswer}
        />
      ))}
    </Box>
  );
};
