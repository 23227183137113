import { useEffect, useState } from 'react';

import { Label, Box, Body } from '@hover/blueprint';

import { ErrorType, PristineType, LabelColors } from '../styled';

interface Props {
  isRequired?: boolean;
  isPristine?: boolean;
  isInvalid?: boolean;
  labelText?: string;
  width?: number;
  labelSize?: number;
  errorMessage?: string;
  inputId?: string;
  marginRight?: 0 | 500;
}

export const InputWrapper: React.FC<Props> = ({
  children,
  isRequired,
  isPristine,
  isInvalid,
  inputId,
  labelText,
  width = 200,
  labelSize = 400,
  errorMessage,
  marginRight = 0,
}) => {
  const [labelColor, setLabelColor] = useState<ErrorType | PristineType>(
    LabelColors.pristine,
  );

  useEffect(() => {
    setLabelColor(
      isPristine || !isInvalid ? LabelColors.pristine : LabelColors.error,
    );
  }, [labelColor, isPristine, isInvalid]);

  return (
    <Box
      flexDirection="column"
      marginBottom={600}
      as="section"
      width={width}
      marginRight={marginRight}
    >
      <Box justifyContent="flex-start" alignItems="center" marginBottom={100}>
        <Label htmlFor={inputId}>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Body margin={0} size={labelSize as any} color={labelColor}>
            {labelText}
          </Body>
        </Label>
        <Body
          margin={0}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          size={labelSize as any}
          marginLeft={100}
          color={labelColor}
        >
          {isRequired ? '*' : '(optional)'}
        </Body>
      </Box>
      {children}
      <ErrorMessage color={labelColor} errorMessage={errorMessage} />
    </Box>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default InputWrapper;

interface ErrorMessageProps {
  color: ErrorType | PristineType;
  errorMessage?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ color, errorMessage }) => (
  <Box position="relative" margin={0} data-test-id="orderInputError">
    <Box position="absolute" top={5}>
      <Body size={300} color={color} margin={0}>
        {errorMessage}
      </Body>
    </Box>
  </Box>
);
