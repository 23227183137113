import { PureComponent } from 'react';

import { Box, Button } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface OwnProps {
  show: boolean;
}

type Props = RouteComponentProps & OwnProps;

class HeroActionsBar extends PureComponent<Props> {
  @autobind
  handleEditAnswersClick() {
    const { history } = this.props;

    history.replace({
      pathname: '/estimator/questions/select_templates',
      search: history.location.search,
    });
  }

  render() {
    const { show = true } = this.props;

    return (
      <Box width={1} justifyContent="flex-end">
        {show && (
          <Button onClick={this.handleEditAnswersClick}>Edit Answers</Button>
        )}
      </Box>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withRouter(HeroActionsBar);
