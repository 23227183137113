import { get } from 'lodash';

import { ACLTemplateOptions } from 'src/api/graphql-global-types';
import { RootState } from 'src/types/reduxStore';
import { hasAclAccessesForOrg } from 'src/utils/userUtils';

export const getIsOrgAdmin = (state: RootState): boolean => {
  const acl = get(state, `hover.userProfile.aclAccesses`, []);
  const orgs = get(state, `hover.userProfile.orgs`, [{}]);
  return hasAclAccessesForOrg({
    acl,
    orgId: orgs[0].id,
    desiredAclTemplates: [ACLTemplateOptions.ADMIN],
  });
};

export const getIsJobManager = (state: RootState): boolean => {
  const acl = get(state, `hover.userProfile.aclAccesses`, []);
  const orgs = get(state, `hover.userProfile.orgs`, [{}]);
  return hasAclAccessesForOrg({
    acl,
    orgId: orgs[0].id,
    desiredAclTemplates: [ACLTemplateOptions.JOB_MANAGER],
  });
};

export const getCanViewProductionConsole = (state: RootState): boolean => {
  const acl = get(state, `hover.userProfile.aclAccesses`, []);
  const orgs = get(state, `hover.userProfile.orgs`, [{}]);

  return hasAclAccessesForOrg({
    acl,
    orgId: orgs[0].id,
    desiredAclTemplates: [
      ACLTemplateOptions.ADMIN,
      ACLTemplateOptions.JOB_MANAGER,
    ],
  });
};

export const getLocation = (state: RootState) => state?.router?.location ?? {};
