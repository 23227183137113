import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box } from '@hover/blueprint';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import { ESTIMATION_QUANTITY_UNITS } from 'src/features/project/apis/graphql/queries/queries';
import type { EditableItem } from 'src/features/project/components/common/AddEditMaterialModal';
import type {
  ListItemIdsByTypeAndTrade,
  ListItemsByTypeAndTrade,
} from 'src/features/project/types';
import { getAttributesFromListItem } from 'src/features/project/util/utils';
import { ToastStatusEnum, useQueryEhi, useToastEhi } from 'src/hooks';
import { byLineItemTypeComparator } from 'src/utils/comparators';

import { ProjectScopeTypeListMemoized } from './ContentTable/ProjectScopeTypeList';

type ProjectScopeContentTableProps = {
  orgId: string;
  jobId: number;
  distributors?: Distributor[];
  listItemsByTypeAndTrade: ListItemsByTypeAndTrade;
  selectedListItemIds: ListItemIdsByTypeAndTrade | null;
  enableInlineEditingV2: boolean;
  openAddEditMaterialModal: (lineItemType: LineItemTypeEnum) => void;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  selectDistributor: () => void;
  setEditingListItem: Dispatch<SetStateAction<EditableItem | null>>;
  productionListId: number;
};

const enum TOAST_IDS {
  GET_QUANTITY_UNITS_TOAST,
}

export const ProjectScopeContentTable = ({
  orgId,
  jobId,
  distributors,
  listItemsByTypeAndTrade,
  selectedListItemIds,
  enableInlineEditingV2,
  openAddEditMaterialModal,
  openDownloadPdfModal,
  selectDistributor,
  setEditingListItem,
  productionListId,
}: ProjectScopeContentTableProps) => {
  const toast = useToastEhi();
  const lineItemsTypeSorted = useMemo(() => {
    const keys = Object.keys(listItemsByTypeAndTrade) as LineItemTypeEnum[];
    return keys.sort(byLineItemTypeComparator);
  }, [listItemsByTypeAndTrade]);

  const setEditing = (listItem: ListItem) => {
    if (!isNil(listItem)) {
      const editListItem = getAttributesFromListItem(listItem, null);
      setEditingListItem({ listItem: editListItem, type: listItem.type });
    }
  };

  const { data: quantityUnitsData } = useQueryEhi(ESTIMATION_QUANTITY_UNITS, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_QUANTITY_UNITS_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .estimateQuantityUnits,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  return (
    <Box
      marginTop={600}
      flexDirection="column"
      data-test-id="ProjectScope-ListItemsTable-Container"
    >
      {lineItemsTypeSorted.map((lineItemType) => (
        <ProjectScopeTypeListMemoized
          key={lineItemType}
          orgId={orgId}
          jobId={jobId}
          lineItemType={lineItemType}
          selectedListItemIds={
            selectedListItemIds && selectedListItemIds[lineItemType]
          }
          itemsByTrade={listItemsByTypeAndTrade[lineItemType]}
          openDownloadPdfModal={openDownloadPdfModal}
          selectDistributor={selectDistributor}
          openAddEditMaterialModal={openAddEditMaterialModal}
          setEditingListItem={setEditing}
          distributors={distributors}
          productionListId={productionListId}
          enableInlineEditingV2={enableInlineEditingV2}
          quantityUnits={quantityUnitsData?.estimationQuantityUnits ?? []}
        />
      ))}
    </Box>
  );
};
