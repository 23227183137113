import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import type { distributionOrderCheck_distributionOrderCheck_lineItems as OrderCheckLineItem } from 'src/api/types/distributionOrderCheck';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';

/**
 * Types
 */

export type ListItemIdsByTrade = Record<TradeTypeEnum, Array<string>>;
export type ListItemIdsByTypeAndTrade = Record<
  LineItemTypeEnum,
  ListItemIdsByTrade
>;

export const OTHER_TRADE_TYPE = 'OTHER';
export type OtherTrade = 'OTHER';

export type ListItemsByTrade = Record<
  TradeTypeEnum | OtherTrade,
  Array<ListItem>
>;
export type ListItemsByTypeAndTrade = Record<
  LineItemTypeEnum,
  ListItemsByTrade
>;

// This type is a workaround for the backend type OrderCheckLineItems returning the `tradeType`
// field as `string` instead of `TradeTypeEnum`.
export type OrderCheckLineItemWithTradeType = Omit<
  OrderCheckLineItem,
  'tradeType'
> & { tradeType: TradeTypeEnum };

export type OrderLineItemsByTrade = Record<
  TradeTypeEnum | OtherTrade,
  Array<OrderCheckLineItemWithTradeType>
>;
