// order is: Siding, Unknown, Stucco, Tudor, Stone, Brick, Wrap
export const facadesSortOrderByType = [
  'SI',
  'UN',
  'STC',
  'TD',
  'STO',
  'BR',
  'WR',
];
