import { BoxProps } from '@hover/blueprint';

import { Content } from './styled';

const Wrapper: React.FunctionComponent<BoxProps> = ({ children, ...props }) => {
  return (
    <Content
      width={{
        base: '90vw',
        desktop: '60vw',
      }}
      maxWidth="maxModalWidth"
      {...props}
    >
      {children}
    </Content>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Wrapper;
