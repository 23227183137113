import { useState } from 'react';

import { Modal, Button, Field, TextInput } from '@hover/blueprint';

import { EstimationConfigLineItemUpdateInput } from 'src/api/graphql-global-types';
import { estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node as Template } from 'src/api/types/estimationConfigTemplatesWithLineItems';

interface TemplateEditModalProps {
  template: Template;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: EstimationConfigLineItemUpdateInput) => void;
}

export const TemplateEditModal = ({
  template,
  onSubmit,
  onClose,
  isLoading,
}: TemplateEditModalProps) => {
  const [templateName, setTemplateName] = useState(template.name || '');

  return (
    <Modal
      isOpen
      footer={
        <>
          <Button fill="outline" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            isLoading={isLoading}
            onClick={() =>
              onSubmit(template.id, {
                name: templateName,
              })
            }
          >
            Submit
          </Button>
        </>
      }
      header="Edit Template"
      onClose={onClose}
      size="medium"
    >
      <Field label="Template Name (required)" name="template_name">
        <TextInput
          value={templateName}
          onChange={({ target: { value } }) => setTemplateName(value)}
        />
      </Field>
    </Modal>
  );
};
