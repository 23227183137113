import { useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Field, Heading, Radio, Stack } from '@hover/blueprint';
import { useRadioGroup } from '@hover/blueprint/chakra';
import { iPercent } from '@hover/icons';
import { toNumber, toString } from 'lodash';
import {
  Controller,
  useForm,
  SubmitHandler,
  useController,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';
import {
  EHI_ORG_SETTINGS_UPDATE,
  ESTIMATION_CONFIG_TRADE_TYPE_CATEGORY_WASTE_FACTOR_UPDATE,
  GET_TRADE_TYPE_ENUM_ORGS,
} from 'src/api/queries/queries';
import {
  ehiOrgSettingsUpdate,
  ehiOrgSettingsUpdateVariables,
} from 'src/api/types/ehiOrgSettingsUpdate';
import {
  estimationConfigTradeTypeCategoryWasteFactorUpdate,
  estimationConfigTradeTypeCategoryWasteFactorUpdateVariables,
} from 'src/api/types/estimationConfigTradeTypeCategoryWasteFactorUpdate';
import {
  estimationConfigTradeTypeEnumOrgs,
  estimationConfigTradeTypeEnumOrgsVariables,
} from 'src/api/types/estimationConfigTradeTypeEnumOrgs';
import { NumberInput } from 'src/components';
import { getOrgSettings, getUserOrgId } from 'src/redux/selectors';

type FormValues = {
  sidingWaste: string;
  includeOpeningsLessThan33Sqft: string;
};

export const CalculationPreferences: React.FC = () => {
  const orgId = useSelector(getUserOrgId);
  const includeOpeningsLessThan33Sqft = toString(
    useSelector(getOrgSettings)?.includeOpeningsLessThan33Sqft,
  );

  const { data } = useQuery<
    estimationConfigTradeTypeEnumOrgs,
    estimationConfigTradeTypeEnumOrgsVariables
  >(GET_TRADE_TYPE_ENUM_ORGS, {
    variables: {
      orgId,
    },
  });

  const sidingWaste = (
    data?.estimationConfigTradeTypeEnumOrgs?.nodes?.find(
      (n) => n?.tradeType === 'SIDING',
    )?.wasteFactor ?? 0
  ).toString();

  const defaultValues = {
    sidingWaste,
    includeOpeningsLessThan33Sqft,
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset,
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    // this necessary because on initial load the redux values aren't available
    reset({
      includeOpeningsLessThan33Sqft,
      sidingWaste,
    });
  }, [includeOpeningsLessThan33Sqft, reset, sidingWaste]);

  const { field: radioField } = useController({
    name: 'includeOpeningsLessThan33Sqft',
    control,
    defaultValue: defaultValues.includeOpeningsLessThan33Sqft,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...radioField,
  });

  const [updateOrgSettings, { loading: loadingOrgSettings }] = useMutation<
    ehiOrgSettingsUpdate,
    ehiOrgSettingsUpdateVariables
  >(EHI_ORG_SETTINGS_UPDATE, {
    onCompleted: () => {
      reset(getValues());
    },
  });

  const [updateSidingWaste, { loading: loadingSidingWaste }] = useMutation<
    estimationConfigTradeTypeCategoryWasteFactorUpdate,
    estimationConfigTradeTypeCategoryWasteFactorUpdateVariables
  >(ESTIMATION_CONFIG_TRADE_TYPE_CATEGORY_WASTE_FACTOR_UPDATE, {
    onCompleted: () => {
      reset(getValues());
    },
  });

  const loading = loadingOrgSettings || loadingSidingWaste;

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    updateOrgSettings({
      variables: {
        orgId: toString(orgId),
        orgSettingsAttributes: {
          includeOpeningsLessThan33Sqft:
            formData.includeOpeningsLessThan33Sqft === 'true',
        },
      },
    });

    updateSidingWaste({
      variables: {
        orgId: toString(orgId),
        tradeTypeCategory: TradeTypeCategoryEnum.SIDING,
        wasteFactor: toNumber(formData.sidingWaste),
      },
    });
  };

  return (
    <Stack
      as="form"
      direction="column"
      height="100%"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction="column" flex={1}>
        <Box
          justifyContent="space-between"
          alignItems="center"
          marginBottom={500}
        >
          <Box alignItems="center">
            <Heading size={600} marginBottom={0}>
              Calculation Preferences
            </Heading>
          </Box>
        </Box>
        <Heading size={200}>SIDING</Heading>
        <Box
          borderColor="neutral200"
          borderWidth="1px"
          borderRadius="lg"
          borderStyle="solid"
          padding={500}
        >
          <Stack direction="column">
            <Field
              label="Default siding waste"
              name="sidingWaste"
              error={errors.sidingWaste && 'Siding waste cannot be empty'}
            >
              <Controller
                name="sidingWaste"
                control={control}
                rules={{ min: 0, max: 99, required: true }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    iconAfter={iPercent}
                    width="150px"
                    decimalScale={0}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;

                      if (!floatValue) return true;

                      return (
                        toNumber(floatValue) < 100 && toNumber(floatValue) >= 0
                      );
                    }}
                  />
                )}
              />
            </Field>
            <Field
              label="Siding walls and trim calculation"
              name="includeOpeningsLessThan33Sqft"
            >
              <Stack direction="column" {...getRootProps()}>
                <Radio {...getRadioProps({ value: 'true' })}>
                  Include openings less than 33 SQFT
                </Radio>

                <Radio {...getRadioProps({ value: 'false' })}>
                  Exclude openings less than 33 SQFT
                </Radio>
              </Stack>
            </Field>
          </Stack>
        </Box>
      </Stack>

      <Box as="footer" gap={400}>
        <Button
          type="submit"
          isDisabled={!isValid || !isDirty || loading}
          isLoading={loading}
        >
          Save
        </Button>
        <Button
          fill="outline"
          isDisabled={!isDirty || loading}
          onClick={() => reset()}
        >
          Cancel
        </Button>
      </Box>
    </Stack>
  );
};
