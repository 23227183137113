import { Body, Checkbox } from '@hover/blueprint';
import { Collapse } from '@hover/blueprint/chakra';
import { Controller, useFormContext } from 'react-hook-form';

import { PreviewImage } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/PreviewImage';

import { CheckboxFieldUIWrapper, SectionPanel } from './components';

type Props = {
  handleCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: boolean) => void,
    block: string,
    label: string,
  ) => void;
  handleShowDefaultElementVisibilityPreviewImageModal: () => void;
  includeTradePriceDefaultInitialValue: boolean;
  includeItemCostDefaultInitialValue: boolean;
  includeItemQuantitiesDefaultInitialValue: boolean;
};

export const DefaultElementVisibilityPanel: React.FC<Props> = ({
  handleCheckboxChange,
  handleShowDefaultElementVisibilityPreviewImageModal,
  includeTradePriceDefaultInitialValue,
  includeItemCostDefaultInitialValue,
  includeItemQuantitiesDefaultInitialValue,
}) => {
  const { control, setValue, watch } = useFormContext();

  const {
    includeTotalPriceDefault,
    includeTradePriceDefault,
    includeAllEstimateItemsDefault,
    includeItemCostDefault,
    includeItemQuantitiesDefault,
  } = watch();

  const toggleRelatedPriceValues = (value: boolean) => {
    if (!value) {
      setValue('includeTradePriceDefault', false);
    } else {
      setValue(
        'includeTradePriceDefault',
        includeTradePriceDefaultInitialValue,
      );
    }
  };

  const toggleRelatedTeamMemberValues = (value: boolean) => {
    if (!value) {
      setValue('includeItemCostDefault', false);
      setValue('includeItemQuantitiesDefault', false);
    } else {
      setValue('includeItemCostDefault', includeItemCostDefaultInitialValue);
      setValue(
        'includeItemQuantitiesDefault',
        includeItemQuantitiesDefaultInitialValue,
      );
    }
  };

  return (
    <SectionPanel
      headingText="Default element visibility"
      subHeadingText="Manage which elements to show on each proposal PDF"
      data-test-id="DefaultElementVisibilityPanel"
      sidePanelTestId="imagePreviewPanel"
      sidePanel={
        <PreviewImage
          includeTotalPriceDefault={includeTotalPriceDefault}
          includeTradePriceDefault={includeTradePriceDefault}
          includeAllEstimateItemsDefault={includeAllEstimateItemsDefault}
          includeItemCostDefault={includeItemCostDefault}
          includeItemQuantitiesDefault={includeItemQuantitiesDefault}
          onClick={handleShowDefaultElementVisibilityPreviewImageModal}
        />
      }
    >
      <CheckboxFieldUIWrapper letterIndicator="P">
        <Controller
          control={control}
          name="includeTotalPriceDefault"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(
                  e,
                  onChange,
                  'Default element visibility',
                  'includeTotalPriceDefault',
                );
                toggleRelatedPriceValues(!value);
              }}
              isChecked={value}
            >
              <Body size={500} margin={0} color="neutral700">
                Total price
              </Body>
            </Checkbox>
          )}
        />
      </CheckboxFieldUIWrapper>
      <Collapse in={includeTotalPriceDefault}>
        <CheckboxFieldUIWrapper letterIndicator="A" indent>
          <Controller
            control={control}
            name="includeTradePriceDefault"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Default element visibility',
                    'includeTradePriceDefault',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Trade price (sub-total)
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
      <CheckboxFieldUIWrapper letterIndicator="B">
        <Controller
          control={control}
          name="includeAllEstimateItemsDefault"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(
                  e,
                  onChange,
                  'Default element visibility',
                  'includeAllEstimateItemsDefault',
                );
                toggleRelatedTeamMemberValues(!value);
              }}
              isChecked={value}
            >
              <Body size={500} margin={0} color="neutral700">
                Line items
              </Body>
            </Checkbox>
          )}
        />
      </CheckboxFieldUIWrapper>
      <Collapse in={includeAllEstimateItemsDefault}>
        <CheckboxFieldUIWrapper letterIndicator="C" indent>
          <Controller
            control={control}
            name="includeItemCostDefault"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Default element visibility',
                    'includeItemCostDefault',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Item price
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
      <Collapse in={includeAllEstimateItemsDefault}>
        <CheckboxFieldUIWrapper letterIndicator="D" indent>
          <Controller
            control={control}
            name="includeItemQuantitiesDefault"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Default element visibility',
                    'includeItemQuantitiesDefault',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Item quantities
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
    </SectionPanel>
  );
};
