const handler = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line object-shorthand, func-names
  get: function (obj, prop) {
    return obj[prop] || '';
  },
};

// __global_app_settings come from frontend-server image (or src/server.js for local dev env) and are injected into the image at build time
// need process.env for jest tests
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-underscore-dangle
const settings = window.__global_app_settings || process.env;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default new Proxy(settings, handler);
