import { EstimationDiscountDiscountTypeEnum } from 'src/api/graphql-global-types';

interface CalculateDiscountParams {
  value: number;
  discountType: EstimationDiscountDiscountTypeEnum;
  total: number;
}

export const calculatePercentage = ({
  value,
  discountType,
  total,
}: CalculateDiscountParams) => {
  if (
    discountType &&
    discountType.toUpperCase() === EstimationDiscountDiscountTypeEnum.PERCENTAGE
  ) {
    return value;
  }
  return (value / total) * 100;
};

export const calculateDollars = ({
  value,
  discountType,
  total,
}: CalculateDiscountParams) => {
  if (
    discountType &&
    discountType.toUpperCase() === EstimationDiscountDiscountTypeEnum.DOLLARS
  ) {
    return value;
  }
  return (value / 100) * total;
};
