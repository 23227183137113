import { useState } from 'react';

import { Button } from '@hover/blueprint';
import { AspectRatio } from '@hover/blueprint/chakra'; // TODO: update import when blueprint adds to main api
import { iPlayCircle } from '@hover/icons';
import { useSelector } from 'react-redux';

import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { DefaultModal } from 'style-guide';

type Props = {
  viewMode: ViewMode;
};

export const HowItWorksSection: React.FC<Props> = ({ viewMode }) => {
  const [showHowItWorks, setShowHowItWorks] = useState<boolean>(false);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const handleShowHowItWorksPress = () => {
    setShowHowItWorks(true);
    typewriter.buttonPressed({
      button_text: 'Watch how it works',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  return (
    <>
      <Button
        iconAfter={iPlayCircle}
        fill="minimal"
        onClick={handleShowHowItWorksPress}
        isDisabled={viewMode === 'EDIT'}
      >
        Watch how it works
      </Button>
      <DefaultModal
        isOpen={showHowItWorks}
        data-test-id="HowItWorksModal"
        textAlign="left"
        title="How it works"
        justifyTitle="center"
        headerStyle={{
          paddingTop: '0px',
        }}
        childrenStyle={{ alignItems: 'center' }}
        contentStyle={{ width: '85vw' }}
        closeButton={() => {
          setShowHowItWorks(false);
        }}
      >
        <AspectRatio ratio={16 / 9} width="100%" height="100%" maxWidth="60vw">
          <iframe
            title="Wistia video player"
            allowFullScreen
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            src="https://fast.wistia.net/embed/iframe/gyo207ss3f?wmode=transparent"
          />
        </AspectRatio>
      </DefaultModal>
    </>
  );
};
