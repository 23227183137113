import { Badge, BadgeProps } from '@hover/blueprint';

import {
  projectManagementSignatureRequestStates,
  ProjectManagementSignatureRequestState,
} from 'src/features/exteriorEstimator/constants/ProjectManagementSignatureRequestStates';

type StatusBadgeProps = BadgeProps & {
  signatureRequestState: ProjectManagementSignatureRequestState | undefined;
};

export const StatusBadge = ({
  signatureRequestState,
  ...props
}: StatusBadgeProps) => {
  if (!signatureRequestState) return null;

  const { color, text } =
    projectManagementSignatureRequestStates[signatureRequestState];

  return (
    <Badge data-test-id="statusBadge" colorScheme={color} {...props}>
      {text}
    </Badge>
  );
};
