import { useBreakpointValue } from '@hover/blueprint';

export const useIsMobileBreakpoint = (breakpointOptions = {}): boolean => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      tablet: false,
    },
    { fallback: 'tablet', ...breakpointOptions },
  );

  return isMobile;
};
