import { createAction, createAsyncAction } from 'typesafe-actions';

import { estimateFields_discounts as Discount } from 'src/api/types/estimateFields';

const SET_SELECTED_PROMOTION_ID =
  'EXTERIOR_ESTIMATOR/SET_SELECTED_PROMOTION_ID';
const RECEIVE_DISCOUNT = 'EXTERIOR_ESTIMATOR/RECEIVE_DISCOUNT';

export const setSelectedPromotionId = createAction(
  SET_SELECTED_PROMOTION_ID,
  ({ promotionId }: { promotionId: number }) => ({ promotionId }),
)();

export const receiveDiscount = createAction(
  RECEIVE_DISCOUNT,
  ({ discount }: { discount: Discount }) => ({ discount }),
)();

export const removeDiscount = createAsyncAction(
  'ESTIMATOR/REMOVE_DISCOUNT_REQUEST',
  'ESTIMATOR/REMOVE_DISCOUNT_SUCCESS',
  'ESTIMATOR/REMOVE_DISCOUNT_FAILURE',
)<{ discountId: number }, { discountId: number }, Error>();
