import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ProjectManagementSignatureRequestStateEnum } from 'src/api/graphql-global-types';
import {
  FETCH_EMBEDDED_SIGNATURE_URL,
  FETCH_SIGNATURE_REQUEST,
} from 'src/features/exteriorEstimator/apis/queries/proposal';
import { useProposals } from 'src/features/exteriorEstimator/hooks/useProposals';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { getEstimateGroupIdFromLocation } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useLazyQueryEhi } from 'src/hooks/useQueryEhi';
import { RootAction } from 'src/types/reduxStore';
import Hellosign from 'src/utils/Hellosign';

interface IProps {
  id: string;
  setProposalModalView: (view: null) => void;
}

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setProposalsError: estimatorActions.setProposalsError,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapDispatchToProps> & IProps;

export const EmbeddedSignatureFlowLoaderComponent: React.FC<Props> = ({
  id,
  setProposalsError,
  setProposalModalView,
}) => {
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const [fetchProposals] = useProposals();

  const dispatch = useDispatch();
  const { loading, data, stopPolling, error } = useQuery(
    FETCH_SIGNATURE_REQUEST,
    {
      variables: { id },
      pollInterval: 1500,
    },
  );

  const [fetchEmbeddedSignatureUrl, { data: embeddedSignatureUrlData }] =
    useLazyQueryEhi(FETCH_EMBEDDED_SIGNATURE_URL, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!embeddedSignatureUrlData) return;

    const signUrl =
      embeddedSignatureUrlData?.projectManagementSignatureRequestEmbeddedUrl
        ?.signUrl;
    if (signUrl) {
      setProposalModalView(null);
      Hellosign.openEmbeddedSign(signUrl);
      dispatch(estimatorActions.embeddedSignUrl.success(signUrl));
      fetchProposals(estimateGroupId);
    } else {
      dispatch(
        estimatorActions.embeddedSignUrl.failure(
          new Error('failed to get signature url'),
        ),
      );
    }
  }, [
    embeddedSignatureUrlData,
    dispatch,
    estimateGroupId,
    fetchProposals,
    setProposalModalView,
  ]);

  useEffect(() => {
    const signatureRequest = data?.projectManagementSignatureRequest;
    if (!signatureRequest) return;
    if (
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.REQUESTED ||
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.PARTIALLY_SIGNED
    ) {
      stopPolling();
      fetchEmbeddedSignatureUrl({ variables: { signatureRequestId: id } });
    } else if (
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.FAILED ||
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.CANCELLED ||
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.COMPLETE ||
      signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.DECLINED
    ) {
      // end state was reached, no need to poll more
      stopPolling();
      if (
        signatureRequest.state ===
        ProjectManagementSignatureRequestStateEnum.FAILED
      ) {
        const errorMessage =
          error ||
          data.projectManagementSignatureRequest?.failureReason ||
          new Error('Error in EmbeddedSignatureFlowLoader');
        Sentry.captureMessage(errorMessage);
        setProposalsError(errorMessage);
      }
    }
  }, [data]);

  if (!data || loading || error) {
    if (error) {
      stopPolling();
      setProposalsError(error);
      Sentry.captureException(error);
    }
    return null;
  }

  const { projectManagementSignatureRequest: signatureRequest } = data;

  if (!signatureRequest) return null;

  return null;
};

export const EmbeddedSignatureFlowLoader = connect(
  undefined,
  mapDispatchToProps,
)(EmbeddedSignatureFlowLoaderComponent);
