import { filter, findIndex, groupBy } from 'lodash';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { productCatalogDistributorVariationsAttributes_productCatalogDistributorVariationsAttributes as VariationAttributes } from 'src/api/types/productCatalogDistributorVariationsAttributes';
import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';

import {
  ListItemType,
  ProductionListType,
  TableItemType,
  MapProductsToListItemsType,
  Product,
  SortedMaterialItemGroup,
} from '../types';

interface CategoriesType {
  [categoryType: string]: ListItemType[];
}

export const categorizeListItems = (
  productionList: ProductionListType,
): CategoriesType => {
  if (!productionList) {
    return { materialItems: [], laborItems: [], otherItems: [] };
  }
  const materialItems = filter(
    productionList.listItems,
    (item) => item.type === LineItemTypeEnum.MATERIAL,
  );
  const laborItems = filter(
    productionList.listItems,
    (item) => item.type === LineItemTypeEnum.LABOR,
  );
  const otherItems = filter(
    productionList.listItems,
    (item) => item.type === LineItemTypeEnum.OTHER,
  );

  return { materialItems, laborItems, otherItems };
};

export const salesTaxPercent = (postTaxTotal: number, subtotal: number) =>
  postTaxTotal / subtotal - 1;

export const postTaxTotal = (subtotal: number, salesTaxSubtotal: number) =>
  subtotal + salesTaxSubtotal;

export const sortByVendorWithTax = (listItems: ListItemType[]) =>
  listItems.reduce(
    (acc: { [vendorName: string]: TableItemType }, item: ListItemType) => {
      const { vendor } = item;
      const vendorName = vendor ? vendor.vendorName : 'OTHER';
      if (acc[vendorName]) {
        acc[vendorName].listItems.push(item);
        acc[vendorName].subtotal += item?.pretaxCost || 0;
        acc[vendorName].salesTaxSubtotal +=
          (item?.taxPercent || 0) * (item?.pretaxCost || 0);
      } else {
        acc[vendorName] = {
          listItems: [item],
          subtotal: item?.pretaxCost || 0,
          salesTaxSubtotal: (item?.taxPercent || 0) * (item?.pretaxCost || 0),
          vendor,
        };
      }
      return acc;
    },
    {},
  );

export const getListItemsForSingleSetMaterialItems = (
  materials: SortedMaterialItemGroup | null,
) => {
  if (!materials || Object.keys(materials).length !== 1) {
    return null;
  }
  return Object.values(materials)[0];
};

export const insertItemInListById = (
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _list: any[],
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemToInsert: any, // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
any[] => {
  const list = [..._list];
  const index = findIndex(list, (item) => itemToInsert.id === item.id);
  if (index >= 0) {
    list[index] = { ...list[index], ...itemToInsert };
  } else {
    list.push(itemToInsert);
  }
  return list;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const insertListItem = (_listItems: any[], listItem: any) => {
  const listItems = [..._listItems];
  const index = findIndex(listItems, (item) => item.id === listItem.id);

  if (index >= 0) {
    listItems[index] = { ...listItems[index], ...listItem };
  } else {
    listItems.push(listItem);
  }

  return listItems;
};

export const insertListItems = (
  _currentListItems: ListItemType[],
  listItemsToInsert: ListItemType[],
): ListItemType[] => {
  let currentListItems = [..._currentListItems];
  if (!listItemsToInsert.length) return currentListItems;
  const listItemToInsert: ListItemType = listItemsToInsert[0];
  listItemsToInsert.shift();
  currentListItems = insertListItem(currentListItems, listItemToInsert);
  return insertListItems(currentListItems, listItemsToInsert);
};

export const mapProductsToListItems = ({
  listItems,
  productsMap,
}: MapProductsToListItemsType) => {
  const listItemsWithProduct: ListItemType[] = [];
  listItems.forEach((listItem: ListItemType) => {
    if (!listItem.externalProductId) return;
    const product: Product = productsMap[listItem.externalProductId];
    if (!!product) listItemsWithProduct.push({ ...listItem, product });
  });
  return listItemsWithProduct;
};

export const getLostListItems = ({
  sentItems = [],
  receivedItems = [],
}: {
  sentItems: ListItemType[];
  receivedItems: ListItemType[];
}) => {
  const lostItems: ListItemType[] = [];
  const idsOfReceivedItems = new Set(receivedItems.map(({ id }) => id));
  sentItems.forEach((sentItem) => {
    if (!idsOfReceivedItems.has(sentItem.id)) lostItems.push(sentItem);
  });
  return lostItems;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObjectListByCreatedAt = (list: any[]): any[] => {
  const newList = [...list];
  return newList.sort(
    (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)),
  );
};

export const formatCreatedAtToHumanFriendly = (createdAt: string): string => {
  const digitValue = '2-digit';
  const mmddyy = new Date(createdAt).toLocaleDateString(undefined, {
    year: digitValue,
    month: digitValue,
    day: digitValue,
  });
  let hhmmss = createdAt.split('T')[1];
  hhmmss = hhmmss.substring(0, hhmmss.length - 1);
  return `${mmddyy} - ${hhmmss}`;
};

export const buildPdfFileName = ({
  jobId,
  filename,
}: {
  jobId: string;
  filename?: string;
}) => {
  let name = `ID${jobId}`;
  if (filename) name = name.concat(`-${filename}`);
  return name;
};

export const formatUnitCost = (value: string | number | null) => {
  return Number(value).toFixed(2);
};

export const fetchPCExternalPricingForAllVariationsPerDistributor = async ({
  listItems,
  distributorId = '',
  accountId = '',
  distributionBranchId = '',
  orgId = '',
  quantityUnits = '',
}: {
  listItems: ListItemType[];
  distributorId?: string;
  accountId?: string;
  distributionBranchId?: string;
  orgId?: string;
  quantityUnits: string | null;
}) => {
  let result;

  try {
    const {
      data: { productCatalogDistributorVariationsAttributes },
    } = await EstimatorProductionApi.productCatalogVariationsAttributes({
      accountId,
      distributorId,
      lineItemsAttributes: listItems.map((listItem) => {
        return {
          variationId: listItem.externalVariationId,
          clientIdentifier: listItem.id.toString(),
          quantityUnits,
        };
      }),
      distributionBranchId,
      orgId,
    });
    result = productCatalogDistributorVariationsAttributes;
  } catch (error) {
    console.log(
      `Error fetching List Item Vendor  attributes for distributorId: ${distributorId} and listItems ${listItems}`,
    );
  }
  return result;
};

// fetches current PC pricing for line items with variations, and optionally updates the Line Items with up to date price information
export const hydrateListItemsWithVariationPrice = async (
  listItems: ListItemType[],
  orgId: string,
) => {
  const listItemsByVendor = groupBy(listItems, (listItem) => {
    return listItem?.vendor?.distributor?.identifier;
  });
  delete listItemsByVendor.undefined;

  await Promise.all(
    Object.values(listItemsByVendor).map(async (listItemsOfVendor) => {
      const { vendor, quantityUnits } = listItemsOfVendor[0];
      const prices = await fetchPCExternalPricingForAllVariationsPerDistributor(
        {
          orgId,
          distributorId: vendor?.distributorId || undefined,
          distributionBranchId: vendor?.branch?.id,
          accountId: vendor?.accountId || undefined,
          listItems: listItemsOfVendor.filter(
            (item) => item.externalVariationId,
          ),
          quantityUnits,
        },
      );
      prices.forEach((price: VariationAttributes) => {
        const listItemIndex = listItems.findIndex(
          (item) => item.id.toString() === price.clientIdentifier,
        );

        if (listItemIndex < 0) return;
        const existingListItem = listItems[listItemIndex];
        if (
          (existingListItem.unitCost !== price.unitCost && price.unitCost) ||
          (existingListItem.sku !== price.sku && price.sku)
        ) {
          let params = {};
          if (price.unitCost) params = { ...params, unitCost: price.unitCost };
          if (price.sku) params = { ...params, sku: price.sku };

          EstimatorProductionApi.updateListItem(existingListItem.id, params);
        }
        const newItem = {
          ...listItems[listItemIndex],
          unitCost: price.unitCost ?? 0,
          sku: price.sku,
        };
        listItems.splice(listItemIndex, 1, newItem);
      });
    }),
  );

  return listItems;
};
