import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const wasteFactorReducer = createReducer(initialState).handleAction(
  estimatorActions.getPredictedWasteFactorRoof.success,
  (state, action) => ({
    ...state,
    wasteFactor: {
      ...state.wasteFactor,
      predictedWasteFactorRoof: action.payload,
    },
  }),
);
