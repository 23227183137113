import { isEmpty, isNil, flatten } from 'lodash';
import moment from 'moment';

import {
  TradeTypeEnum,
  EstimationEstimateGroupFacetNestedAttributesInput,
} from 'src/api/graphql-global-types';
import {
  estimateFields as EstimateFields,
  estimateFields_discounts as Discount,
} from 'src/api/types/estimateFields';
import { estimateFieldsForEstimateGroups as EstimateWithProductFields } from 'src/api/types/estimateFieldsForEstimateGroups';
import {
  EstimateGroup_estimationEstimateGroup_estimates as EstimateGroupEstimate,
  EstimateGroup_estimationEstimateGroup as EstimationEstimateGroup,
  EstimateGroup_estimationEstimateGroup_estimates_templateGroups_lineItems as TemplateGroupLineItem,
  EstimateGroup_estimationEstimateGroup as EstimateGroupData,
  EstimateGroup_estimationEstimateGroup_userAnswers_inputCategory as InputCategory,
  EstimateGroup_estimationEstimateGroup_userAnswers as Answer,
} from 'src/api/types/EstimateGroup';
import { EstimateGroupForInputSummary_estimationEstimateGroup as EstimateGroupForInputSummary } from 'src/api/types/EstimateGroupForInputSummary';
import {
  EstimateGroupForSummary_estimationEstimateGroup_estimates as EstimationGroupEstimateWithProduct,
  EstimateGroupForSummary_estimationEstimateGroup as EstimateGroup,
  EstimateGroupForSummary_estimationEstimateGroup_estimates as EstimateSummaryEstimate,
} from 'src/api/types/EstimateGroupForSummary';
import { estimationEstimate_estimationEstimate as Estimate } from 'src/api/types/estimationEstimate';
import { estimationEstimateGroupsForJob_estimationEstimateGroups_nodes as EstimateGroupForJob } from 'src/api/types/estimationEstimateGroupsForJob';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  QuestionResponses,
  CustomLineItem,
} from 'src/features/exteriorEstimator/types';
import * as customItemsUtils from 'src/features/exteriorEstimator/utils/customItemsUtils';
import {
  getInputs,
  createTradeTypeAttributes,
} from 'src/features/exteriorEstimator/utils/miscUtils';

type Hash = {
  [trade: string]: EstimateSummaryEstimate[];
};

// line items with variations & a quantity over zero
export const getLineItemsWithVariations = (estimate: EstimateSummaryEstimate) =>
  estimate?.lineItems &&
  estimate.lineItems?.filter(
    (lineItem) =>
      (lineItem?.product?.variations?.length ?? 0) > 0 &&
      lineItem.allowVariationSelection &&
      (lineItem?.quantity ?? 0) > 0,
  );

export const getLineItemsWithVariationsFromTemplateGroups = (
  estimate: EstimateGroupEstimate,
) => {
  return (
    estimate?.templateGroups &&
    flatten(
      estimate.templateGroups.map((templateGroup) => templateGroup.lineItems),
    ).filter(
      (lineItem) =>
        (lineItem?.product?.variations?.length ?? 0) > 0 &&
        lineItem?.allowVariationSelection &&
        (lineItem?.quantity ?? 0) > 0,
    )
  );
};

export const getLineItemsWithVariationsForVariations = (estimate: Estimate) =>
  estimate?.lineItems &&
  estimate.lineItems?.filter(
    (lineItem) =>
      (lineItem?.product?.variations?.length ?? 0) > 0 &&
      lineItem.allowVariationSelection &&
      (lineItem?.quantity ?? 0) > 0,
  );

export const getUnselectedVariationsCount = (
  estimate: EstimationGroupEstimateWithProduct | undefined,
) => {
  if (estimate?.lineItems) {
    const lineItemsWithVariations = getLineItemsWithVariations(estimate) || [];
    const selectedVariationsCount = estimate.lineItems.reduce(
      (count, lineItem) =>
        isNil(lineItem.selectedVariation) ? count : count + 1,
      0,
    );

    return lineItemsWithVariations.length - selectedVariationsCount;
  }

  return 0;
};

export const getAreSelectedVariationsMissing = (
  estimates: EstimateSummaryEstimate[],
) => {
  const lineItemsWithVariations = flatten(
    estimates
      .filter((estimate) => estimate.active)
      .map((estimate) => getLineItemsWithVariations(estimate)),
  );

  return lineItemsWithVariations.some(
    (lineItem) => !lineItem?.selectedVariation,
  );
};

export const canAddVariations = (
  estimate: EstimationGroupEstimateWithProduct | undefined,
) => {
  if (!estimate) return false;
  return !isEmpty(getLineItemsWithVariations(estimate));
};

export const hasSelectedVariation = (
  estimate: EstimateWithProductFields | undefined,
) => {
  if (!estimate) return false;
  return estimate.lineItems?.some(
    (lineItem) =>
      lineItem.allowVariationSelection &&
      lineItem.selectedVariation &&
      lineItem.product?.variations &&
      lineItem.product.variations.length > 0,
  );
};

export const getSelectedVariationNamesByEstimate = (
  estimate: EstimationGroupEstimateWithProduct | undefined,
) => {
  if (!estimate?.lineItems?.length) return [];

  const selectedVariationNames: string[] = [];
  estimate.lineItems.forEach((lineItem) => {
    const lineItemName = lineItem?.name;
    const variationName = lineItem?.selectedVariation?.name;
    if (variationName)
      selectedVariationNames.push(`${variationName} (${lineItemName})`);
  });

  return selectedVariationNames;
};

export const createTradeTypeEstimatesHash = (
  estimates: EstimateSummaryEstimate[],
) => {
  const initializer: Hash = {};
  return estimates.reduce((hash, estimate) => {
    if (!estimate.tradeType) return hash;
    if (hash[estimate.tradeType]) {
      hash[estimate.tradeType as string].push(estimate);
    } else {
      hash[estimate.tradeType as string] = [estimate]; // eslint-disable-line no-param-reassign
    }
    return hash;
  }, initializer);
};

export const estimatesComparator = (a: EstimateFields, b: EstimateFields) =>
  a?.template?.sortOrder !== b?.template?.sortOrder
    ? Number(a?.template?.sortOrder) - Number(b?.template?.sortOrder)
    : a.id - b.id;

export const lineItemComparator = (
  a: { price: number | null; id: number },
  b: { price: number | null; id: number },
) => (a.price !== b.price ? Number(b.price) - Number(a.price) : b.id - a.id);

// callback to pass to Array.filter()
export const lineItemHasQuantity = (lineItem: TemplateGroupLineItem): boolean =>
  !!(lineItem.quantity && lineItem.quantity > 0);

// callback to pass to Array.filter()
export const lineItemHasQuantityAndUnitCost = (
  lineItem: TemplateGroupLineItem,
): boolean =>
  !!(lineItem.price && lineItem.price > 0 && lineItemHasQuantity(lineItem));

export const filterLineItemsForDoTransform = (isDirectOrdering: boolean) => {
  return isDirectOrdering
    ? lineItemHasQuantity
    : lineItemHasQuantityAndUnitCost;
};

// turns the discounts on Estimate into a map of discount ids to the discount itself
export const getDiscounts = (estimates: EstimateGroupEstimate[]) => {
  if (!estimates) return {};

  const discounts: {
    [key: number]: Discount;
  } = {};

  estimates.forEach((estimate) => {
    estimate?.discounts?.forEach((discount) => {
      discounts[discount.id] = {
        ...discount,
        estimationEstimateId: estimate.id,
      };
    });
  });

  return discounts;
};

export const isEstimateGroupSold = (
  estimateGroup: EstimateGroupData | EstimateGroupForJob | null | undefined,
) =>
  estimateGroup &&
  estimateGroup.salesOpportunity.soldEstimateGroupId ===
    estimateGroup.id.toString();

export const estimateGroupsHistoryComparator = (
  a: EstimateGroupData | EstimateGroupForJob | null | undefined,
  b: EstimateGroupData | EstimateGroupForJob | null | undefined,
) => {
  if (isEstimateGroupSold(a) && !isEstimateGroupSold(b)) return -1;
  if (isEstimateGroupSold(b) && !isEstimateGroupSold(a)) return 1;
  return moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf();
};

interface inputCategory {
  input: InputCategory;
  answers: Answer[];
}

export const inputCategoryComparator = (a: inputCategory, b: inputCategory) =>
  a.input.sortOrder - b.input.sortOrder;

export const answerComparator = (a: Answer, b: Answer) => {
  return a.inputCategory.sortOrder - b.inputCategory.sortOrder;
};

export const hasAnySoldEstimateGroup = (
  estimateGroup: EstimateGroup | EstimationEstimateGroup | undefined | null,
) => !!estimateGroup?.salesOpportunity?.soldEstimateGroupId;

export const getSelectedRoofFacetTotal = (
  estimateGroup: EstimateGroupForInputSummary,
) =>
  estimateGroup?.facets
    ? estimateGroup.facets
        .filter((f) => f.identifier.includes('RF'))
        .reduce((_acc, facet) => {
          let acc = _acc;
          acc += facet.area ?? 0;
          return acc;
        }, 0)
    : 0;

export const getSelectedSidingFacetTotal = (
  estimateGroup: EstimateGroupForInputSummary,
) =>
  estimateGroup?.facets
    ? estimateGroup.facets
        .filter((f) => !f.identifier.includes('RF'))
        .reduce((_acc, facet) => {
          let acc = _acc;
          acc += facet.area ?? 0;
          return acc;
        }, 0)
    : 0;

export const estimateGroupCreateParams = ({
  customLineItems,
  jobId,
  orgId,
  questionResponses,
  selectedTemplates,
  selectedTrades,
  tradeTypes,
  facetsAttributes,
  showOrderingVersion,
  customizedProductionList = false,
}: {
  customLineItems: CustomLineItem[];
  jobId: number | string;
  orgId: number | string;
  questionResponses: QuestionResponses;
  selectedTemplates: number[];
  tradeTypes: TradeType[];
  selectedTrades: TradeTypeEnum[];
  facetsAttributes?: EstimationEstimateGroupFacetNestedAttributesInput[];
  showOrderingVersion: boolean;
  customizedProductionList?: boolean;
}) => {
  const inputs = getInputs(questionResponses);
  const estimationEstimateGroupCreateInputs = Object.entries(inputs).map(
    ([key, val]) => ({
      id: key,
      value: val?.toString(),
    }),
  );

  const tradeTypeAttributes = createTradeTypeAttributes({
    selectedTrades,
    tradeTypes,
  });

  const convertedCustomLineItems =
    customItemsUtils.convertCustomLineItemsToApiResponse(customLineItems);

  return {
    templateIds: selectedTemplates,
    tradeTypeAttributes,
    inputs: estimationEstimateGroupCreateInputs,
    customLineItems: convertedCustomLineItems,
    markAsSoldAndActivate: showOrderingVersion,
    estimateGroupAttributes: { orgId, jobId, facetsAttributes },
    customizedProductionList,
  };
};
