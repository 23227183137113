import { gql } from '@apollo/client';

export const SALES_OPPORTUNITY_UPDATE = gql`
  mutation salesOpportunityUpdate(
    $salesOpportunityAttributes: SalesOpportunityUpdate!
    $id: ID!
  ) {
    salesOpportunityUpdate(
      salesOpportunityAttributes: $salesOpportunityAttributes
      id: $id
    ) {
      salesOpportunity {
        id
        soldEstimateGroupId
      }
      errors {
        attribute
        errors
      }
    }
  }
`;
