import { Box } from '@hover/blueprint';
import styled from 'styled-components';

import StyleGuide from 'style-guide';

export const Button = styled(StyleGuide.Button)``;

export const Content = styled(Box)`
  padding: 24px;
  font-style: italic;
`;

export const Contact = styled.a`
  text-decoration: underline;
`;
