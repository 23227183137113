import { Button, ButtonProps } from '@hover/blueprint';
import { Link, LinkProps } from 'react-router-dom';

/**
 * A chakra-ui `<Button>` that navigates to a react-router-dom route.
 * This component essentially composes a react-router-dom `<Link>` and
 * chakra-ui `<Button>`, while maintaining expected behavior for
 * disabling the button and its click event.
 * @param to The route string to navigate to
 * @returns The `<Button>` JSX.Element
 */
export const NavButton: React.FC<ButtonProps & LinkProps> = ({
  to,
  target,
  isDisabled = false,
  children,
  ...buttonProps
}) => {
  return isDisabled ? (
    <Button {...buttonProps} isDisabled={isDisabled}>
      {children}
    </Button>
  ) : (
    <Button as={Link} to={to} target={target} {...buttonProps}>
      {children}
    </Button>
  );
};
