import numeral from 'numeral';

export const squaredFeetToRoofSquares = (value: number) => value / 100;

export const squaredInchesToSquaredFeet = (value: number) => value * 0.00694444;

export const squaredFeetToSquaredInches = (value: number) => value / 0.00694444;

export const squareMetersToSquareFeet = (value: number) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  value * 10.763910416709723;

export const squareMetersToSquareInches = (value: number) => value * 1550;

export const squareInchesToSquareMeters = (value: number) => value / 1550.0;

export const metersToFeet = (value: number) => (value * 100) / 30.48;

export const metersToInches = (value: number) => (value * 100) / 2.54;

export const inchesToFeet = (value: number) => value / 12;

export const feetToInches = (value: number) => value * 12;

export const inchesToMeters = (value: number) => value * 0.0254;

// 23" returns 1'11"
export const inchesToFeetInches = (value: number, format = '0,0') => {
  const feetinches = [];
  let feet;
  let inches;

  feet = Math.floor(value / 12.0);
  inches = Math.round(value - feet * 12.0);

  if (inches === 12) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-plusplus
    ++feet;
    inches = 0;
  }

  if (feet > 0) {
    feetinches.push(format ? numeral(feet).format(format) : feet);
    feetinches.push("'");
  }

  if (feet > 0) {
    if (inches > 0) {
      feetinches.push(' ');
      feetinches.push(inches);
      feetinches.push('"');
    }
  } else {
    feetinches.push(inches);
    feetinches.push('"');
  }

  return {
    feet,
    inches,
    feetinches: feetinches.join(''),
  };
};

export const metersToFeetInches = (value: number) => {
  const inchesValue = metersToInches(value);
  const result = inchesToFeetInches(inchesValue);

  return result;
};
