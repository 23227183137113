import { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Body,
  Box,
  IconButton,
  Tr,
  Td,
  Toggle,
  Input,
  LoadingOverlay,
  Image,
  Link,
} from '@hover/blueprint';
import { iTrash, iEdit2, iSave, iX } from '@hover/icons';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { SalesPresentationPresentationStateEnum } from 'src/api/graphql-global-types';
import type { getSalesPresentations_salesPresentationPresentations as SalesPresentation } from 'src/api/types/getSalesPresentations';
import { salesPresentationPresentationStateToggle } from 'src/api/types/salesPresentationPresentationStateToggle';
import { salesPresentationPresentationUpdate } from 'src/api/types/salesPresentationPresentationUpdate';
import {
  UPDATE_SALES_PRESENTATION,
  UPDATE_SALES_PRESENTATION_STATE,
} from 'src/features/settings/api/queries/salesPresentation';
import type { CurrentSelectedPresentation } from 'src/features/settings/components/SalesPresentation/SalesPresentationTable';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';
import typewriter from 'src/lib/telemetry/generated/segment';
import { getUserTrackingProps } from 'src/redux/selectors';

interface SalesPresentationTableProps {
  presentation: SalesPresentation;
  showConfirmDeleteModal: (
    showConfirmationDeleteModalProps: CurrentSelectedPresentation,
  ) => void;
}

export const SalesPresentationRow: React.FC<SalesPresentationTableProps> = ({
  presentation,
  showConfirmDeleteModal,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const commonProps = useSelector(getUserTrackingProps);

  const toast = useToastEhi();

  const [updateSalesPresentation, { loading: updatingSalesPresentation }] =
    useMutation<salesPresentationPresentationUpdate>(
      UPDATE_SALES_PRESENTATION,
      {
        variables: {
          presentationAttributes: {
            id: presentation.id,
            name,
          },
        },
        refetchQueries: ['getSalesPresentations'],
        onCompleted: () => {
          toast({
            id: 'sales-presentation-renaming-success-toast',
            description: `Your sales presentation was successfully renamed`,
            status: ToastStatusEnum.SUCCESS,
          });
        },
        onError: (error) => {
          toast({
            id: 'sales-presentation-renaming-failure-toast',
            description: `Your sales presentation was not successfully renamed`,
            status: ToastStatusEnum.ERROR,
          });
          Sentry.captureException(error);
        },
      },
    );

  const [toggleStatus, { loading: updatingState }] =
    useMutation<salesPresentationPresentationStateToggle>(
      UPDATE_SALES_PRESENTATION_STATE,
      {
        refetchQueries: ['getSalesPresentations'],
      },
    );

  const onToggleChange = () => {
    if (updatingState) return;
    const newState =
      presentation.state === SalesPresentationPresentationStateEnum.ACTIVE
        ? SalesPresentationPresentationStateEnum.INACTIVE
        : SalesPresentationPresentationStateEnum.ACTIVE;

    toggleStatus({
      variables: {
        id: presentation.id,
      },
      optimisticResponse: {
        salesPresentationPresentationStateToggle: {
          salesPresentation: {
            id: presentation.id,
            name: presentation.name,
            state: newState,
            __typename: 'SalesPresentationPresentation',
          },
          errors: [],
          __typename: 'PresentationStateTogglePayload',
        },
      },
    });

    typewriter.checkboxSelected({
      selection: newState,
      page_or_screen_name: 'Sales Presentation List',
      primary_cta: false,
      options: 'Active/Inactive',
      ...commonProps,
    });
  };

  const removeTemplate = () => {
    typewriter.buttonPressed({
      button_text: 'delete presentation',
      page_or_screen_name: 'Sales Presentation List',
      primary_cta: false,
      ...commonProps,
    });
    showConfirmDeleteModal({ id: presentation.id, name: presentation.name });
  };

  const editName = () => {
    setIsEditing(true);
    setName(presentation.name);
  };

  const handleClickCancel = () => {
    setIsEditing(false);
    setName('');
  };

  const handleClickSave = () => {
    updateSalesPresentation();
    handleClickCancel();
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setName(value);
  };

  const handleChangeKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleClickSave();
    } else if (event.key === 'Escape') {
      handleClickCancel();
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const isNameInvalid = name.length === 0;

  const isActive =
    presentation.state === SalesPresentationPresentationStateEnum.ACTIVE;

  return (
    <Tr key={presentation.id} _hover={{ backgroundColor: 'primary100' }}>
      <LoadingOverlay isLoading={updatingSalesPresentation} />
      <Td width="35%">
        <Box flexDirection="row" alignItems="center">
          <Box height="100%" pr={200}>
            <Link
              href={presentation.pdf?.redirectUrl}
              download={`${presentation.name}.pdf`}
              type="application/pdf"
              target="_blank"
            >
              <Image
                src="https://assets.hover.to/icons/SVG/pdf.svg"
                alt="Pdf Icon"
                width={100}
                height={100}
              />
            </Link>
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            flex="1"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            _hover={{
              cursor: 'pointer',
            }}
          >
            {isEditing ? (
              <>
                <Input
                  autoFocus
                  value={name}
                  size="tiny"
                  onChange={handleChange}
                  onKeyDown={handleChangeKeyDown}
                  isInvalid={isNameInvalid}
                />
                <Box ml={200}>
                  <IconButton
                    mr={100}
                    label="save"
                    icon={iSave}
                    fill="minimal"
                    shape="square"
                    size="medium"
                    color="neutral"
                    onClick={handleClickSave}
                    isDisabled={isNameInvalid}
                    data-test-id="saveIcon"
                  />
                  <IconButton
                    label="cancel"
                    icon={iX}
                    fill="minimal"
                    shape="square"
                    size="medium"
                    color="neutral"
                    onClick={handleClickCancel}
                    data-test-id="cancelIcon"
                  />
                </Box>
              </>
            ) : (
              <>
                {presentation.name}
                <IconButton
                  ml={200}
                  display={isHovering ? 'block' : 'none'}
                  label="edit"
                  icon={iEdit2}
                  fill="minimal"
                  shape="square"
                  size="medium"
                  color="neutral"
                  onClick={editName}
                  data-test-id="editIcon"
                />
              </>
            )}
          </Box>
        </Box>
      </Td>
      <Td width="35%">
        <Box alignItems="center">
          <Box alignItems="center">
            <Toggle
              value={isActive}
              onChange={onToggleChange}
              marginRight={200}
            >
              <Body color="neutral.600" as="span">
                {isActive ? 'Active' : 'Inactive'}
              </Body>
            </Toggle>
          </Box>
        </Box>
      </Td>
      <Td>
        <Box display="inline-flex" data-test-id="template-updatedAt">
          {new Date(presentation.updatedAt).toLocaleString()}
        </Box>
      </Td>
      <Td>
        <Box>
          <IconButton
            label="remove"
            icon={iTrash}
            fill="minimal"
            shape="square"
            size="medium"
            color="neutral"
            onClick={() => {
              removeTemplate();
            }}
            data-test-id="removeIcon"
          />
        </Box>
      </Td>
    </Tr>
  );
};
