import { Body, Box, Icon } from '@hover/blueprint';
import { iSearch } from '@hover/icons';

interface NoResultsProps {
  searchTerm?: string | null;
}

export const NoResultsFound: React.FC<NoResultsProps> = ({ searchTerm }) => {
  const message = 'No results found';

  return (
    <Box
      backgroundColor="neutral.0"
      height="700"
      justifyContent="center"
      alignItems="center"
      data-test-id="noResultsView"
    >
      <Box flexDirection="column" justifyContent="center" alignItems="center">
        <Icon size="huge" icon={iSearch} color="neutral.600" />
        <Box flexDirection="row">
          <Body as="span" color="neutral.600">
            {message}
          </Body>
          {searchTerm && (
            <Box>
              <Body as="span" color="neutral600">
                &nbsp;for&nbsp;&nbsp;
              </Body>
              <Body as="span" color="neutral600" fontWeight={700}>
                &lsquo;{searchTerm}&rsquo;
              </Body>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
