import { Body, Box, Button, Heading } from '@hover/blueprint';

type Props = {
  setShowModal: (show: boolean) => void;
};

export const UserCannotChangeStatus: React.FC<Props> = ({ setShowModal }) => {
  const handleClick = () => setShowModal(false);
  return (
    <>
      <Heading size={400} color="neutral.700" data-test-id="status-title">
        Need to change the status?
      </Heading>
      <Body size={400} color="neutral.600" data-test-id="status-text">
        Please contact your manager to change this estimate from sold to unsold.
      </Body>
      <Box flexDirection="column">
        <Button
          marginTop={300}
          onClick={handleClick}
          data-test-id="okay-button"
        >
          Okay
        </Button>
      </Box>
    </>
  );
};
