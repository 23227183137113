import { BadgeProps } from '@hover/blueprint';

import { ProjectManagementSignatureRequestStateEnum } from 'src/api/graphql-global-types';

export type ProjectManagementSignatureRequestState = Extract<
  ProjectManagementSignatureRequestStateEnum,
  | ProjectManagementSignatureRequestStateEnum.DECLINED
  | ProjectManagementSignatureRequestStateEnum.FAILED
  | ProjectManagementSignatureRequestStateEnum.QUEUED
  | ProjectManagementSignatureRequestStateEnum.REQUESTED
  | ProjectManagementSignatureRequestStateEnum.SIGNED
  | ProjectManagementSignatureRequestStateEnum.PARTIALLY_SIGNED
  | ProjectManagementSignatureRequestStateEnum.COMPLETE
>;

export const projectManagementSignatureRequestStates: Record<
  ProjectManagementSignatureRequestState,
  { color: BadgeProps['colorScheme']; text: string }
> = {
  [ProjectManagementSignatureRequestStateEnum.DECLINED]: {
    color: 'danger',
    text: 'Signature declined',
  },
  [ProjectManagementSignatureRequestStateEnum.FAILED]: {
    color: 'danger',
    text: 'Failed',
  },
  [ProjectManagementSignatureRequestStateEnum.PARTIALLY_SIGNED]: {
    color: 'neutral',
    text: 'Signing',
  },
  [ProjectManagementSignatureRequestStateEnum.QUEUED]: {
    color: 'neutral',
    text: 'Signature requested',
  },
  [ProjectManagementSignatureRequestStateEnum.REQUESTED]: {
    color: 'neutral',
    text: 'Signature requested',
  },
  [ProjectManagementSignatureRequestStateEnum.SIGNED]: {
    color: 'success',
    text: 'Signed',
  },
  [ProjectManagementSignatureRequestStateEnum.COMPLETE]: {
    color: 'success',
    text: 'Signed',
  },
};

export const isValidProjectManagementSignatureRequestState = (
  signatureRequestState:
    | ProjectManagementSignatureRequestStateEnum
    | null
    | undefined,
): signatureRequestState is ProjectManagementSignatureRequestState => {
  if (!signatureRequestState) return false;

  return Object.keys(projectManagementSignatureRequestStates).includes(
    signatureRequestState,
  );
};
