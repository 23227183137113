import { PureComponent } from 'react';

import ReactDOM from 'react-dom';
import styled, { CSSObject } from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.46);
  z-index: 1001;
  padding: 0;
  margin: 0;
`;

const Content = styled.div<{ contentLabel: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 43px 30px 30px;
  transform: translate(-50%, -50%);
  height: auto;
  display: flex;
  font-family: 'Gotham Book';
  max-width: 100vw;
  max-height: 90vh;
  text-align: center;
  flex-direction: column;
`;

interface Props {
  isOpen: boolean;
  contentStyle?: CSSObject;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class HvModal extends PureComponent<Props> {
  modalPortal: HTMLElement | null;

  constructor(props: Props) {
    super(props);

    this.modalPortal = document.getElementById('react-modal-portal');

    /**
     * Needed to `mount` this component in tests. Otherwise, `mount` complains
     * that the target div doesn't exist.
     */
    if (!this.modalPortal) {
      const el = document.createElement('div');
      el.id = 'react-modal-portal';
      document.body.appendChild(el);
      this.modalPortal = el;
    }
  }

  render() {
    const { children, isOpen, contentStyle = {}, ...otherProps } = this.props;

    if (!isOpen || !this.modalPortal) return null;

    return ReactDOM.createPortal(
      <Overlay>
        <Content contentLabel="Modal" style={contentStyle} {...otherProps}>
          {children}
        </Content>
      </Overlay>,
      this.modalPortal,
    );
  }
}
