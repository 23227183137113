import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const proposalReducer = createReducer(initialState)
  .handleAction(estimatorActions.createProposal.request, (state) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      isLoadingProposals: true,
    },
  }))
  .handleAction(
    estimatorActions.createProposal.success,
    (state, { payload: proposal }) => {
      return {
        ...state,
        proposalsData: {
          ...state.proposalsData,
          proposals: [...state.proposalsData.proposals, proposal],
          selectedProposalId: proposal.id,
          isLoadingProposals: false,
        },
      };
    },
  )
  .handleAction(estimatorActions.updateProposal, (state, action) => {
    const proposalIndex = state.proposalsData.proposals.findIndex(
      (proposal) => proposal.id === action.payload.proposal.id,
    );

    const newProposals = [...state.proposalsData.proposals];
    newProposals.splice(proposalIndex, 1, action.payload.proposal);

    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        proposals: newProposals,
        selectedProposalId: action.payload.proposal.id,
      },
    };
  })
  .handleAction(estimatorActions.getProposals.request, (state) => {
    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        isLoadingProposals: true,
      },
    };
  })
  .handleAction(estimatorActions.getProposals.success, (state, action) => {
    return {
      ...state,
      proposalsData: {
        ...state.proposalsData,
        proposals: action.payload,
        isLoadingProposals: false,
      },
    };
  })
  .handleAction(estimatorActions.setSelectedProposal, (state, action) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      selectedProposalId: action.payload.id,
    },
  }))
  .handleAction(estimatorActions.setProposalsError, (state, action) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      error: action.payload,
      isLoadingProposals: false,
      signatureRequest: {},
    },
  }))
  .handleAction(estimatorActions.clearProposalsError, (state) => ({
    ...state,
    proposalsData: {
      ...state.proposalsData,
      error: undefined,
    },
  }));
