import { get } from 'lodash';

import { CategoryLineItems, ListItemType } from '../types';

export interface PdfOutputDataType {
  listItems: ListItemType[];
  subtotal: number;
  salesTaxSubtotal: number;
}

export class PdfOutputData {
  listItemsSummary: CategoryLineItems;

  constructor(listItemsSummary: CategoryLineItems) {
    this.listItemsSummary = listItemsSummary;
  }

  public build() {
    const result: PdfOutputDataType = {
      listItems: this.getAllListItems(),
      subtotal: this.getSubtotal(),
      salesTaxSubtotal: this.getSalesTaxSubtotal(),
    };
    return result;
  }

  private getAllListItems() {
    const emptyListItems: ListItemType[] = [];
    return this.getListItems().reduce(
      (acc, categoryListItems) => acc.concat(categoryListItems.listItems),
      emptyListItems,
    );
  }

  private getSubtotal() {
    return this.getListItems().reduce(
      (acc, categoryListItems) => acc + categoryListItems.subtotal,
      0,
    );
  }

  private getSalesTaxSubtotal() {
    return this.getListItems().length === 1
      ? get(this.getListItems()[0], 'salesTaxSubtotal')
      : 0; // labor and others don't have this
  }

  private getListItems() {
    return Object.values(this.listItemsSummary);
  }
}
