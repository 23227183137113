import { gql } from '@apollo/client';

import { lineItems, errorFields } from 'src/api/queries/queries';

export const ESTIMATION_LINE_ITEM_UPDATE = gql`
  mutation estimationLineItemUpdate(
    $id: ID!
    $lineItemAttributes: EstimationLineItemUpdateInput!
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    estimationLineItemUpdate(id: $id, lineItemAttributes: $lineItemAttributes) {
      lineItem {
        ...lineItems
      }
      matchedLineItems {
        id
        name
      }
      errors {
        ...errorFields
      }
    }
  }
  ${lineItems}
  ${errorFields}
`;
