import { useCallback, useState } from 'react';

export function useDirectOrderCtaModal() {
  const [showDirectOrderCtaModal, setShowDirectOrderCtaModal] =
    useState<boolean>(false);

  const openDirectOrderCtaModal = useCallback(() => {
    setShowDirectOrderCtaModal(true);
  }, [setShowDirectOrderCtaModal]);

  const closeDirectOrderCtaModal = useCallback(() => {
    setShowDirectOrderCtaModal(false);
  }, [setShowDirectOrderCtaModal]);

  return {
    showDirectOrderCtaModal,
    openDirectOrderCtaModal,
    closeDirectOrderCtaModal,
  };
}
