import React, { useState, useCallback, ReactElement } from 'react';

import { TextInput, Box } from '@hover/blueprint';
import type { TextInputProps } from '@hover/blueprint';
import Downshift, { GetRootPropsOptions } from 'downshift';
import { debounce, omit } from 'lodash';

export interface Suggestion {
  text: string;
  icon?: string;
  id: string;
}

export type InputWithDropdownTypeaheadProps = {
  suggestions: Suggestion[];
  onChange?: (
    text: string,
    selected: boolean,
    selectedItem?: Suggestion,
  ) => void;
  dataTestId?: string;
  placeholder?: string;
  label: string;
  initialInputValue?: string;
  inputProps?: TextInputProps;
  elementBefore?: ReactElement;
  elementAfter?: ReactElement;
};

export const InputWithDropdownTypeahead: React.FC<
  InputWithDropdownTypeaheadProps & React.RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, InputWithDropdownTypeaheadProps>(
  (
    {
      onChange,
      suggestions,
      placeholder,
      label,
      initialInputValue,
      inputProps,
      elementBefore,
      elementAfter,
    },
    ref,
  ) => {
    const [text, setText] = useState<string>('');

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStateChange = (changes: any) => {
      let inputText;
      let selected = false;
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty('selectedItem')) {
        inputText = changes.selectedItem.text;
        selected = true;
        // eslint-disable-next-line no-prototype-builtins
      } else if (changes.hasOwnProperty('inputValue')) {
        inputText = changes.inputValue;
      }

      if (!onChange) return;
      // Broadcast a change event on inputTextChange or selectionChange event.
      if (
        // eslint-disable-next-line no-prototype-builtins
        changes.hasOwnProperty('selectedItem') ||
        // eslint-disable-next-line no-prototype-builtins
        changes.hasOwnProperty('inputValue')
      ) {
        setText(inputText);
        onChange(inputText, selected, changes.selectedItem);
      }
    };

    const handleChangeDebounced = useCallback(
      debounce(handleStateChange, 350),
      [],
    );

    const stateReducer = (state: any, changes: any) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.blurInput:
          return {
            ...changes,
            inputValue:
              state.selectedItem !== '' ? state.selectedItem : state.inputValue,
          };
        default:
          return changes;
      }
    };

    return (
      <Downshift
        selectedItem={text}
        onStateChange={handleChangeDebounced}
        initialInputValue={initialInputValue || ''}
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        itemToString={(item: any) => item?.text ?? item ?? ''}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          getRootProps,
        }) => {
          // Get the Downshift inputProps, in order to access the onChange for Downshift.
          const dsInputProps = getInputProps();
          return (
            <Box display="block" width="100%">
              <Box
                {...getRootProps({} as GetRootPropsOptions | undefined, {
                  suppressRefError: true,
                })}
              >
                <TextInput
                  ref={ref}
                  width="100%"
                  // @ts-expect-error - when expilictly returning render component, TS isn't properly reading the type for TextInputProps['type']
                  type="text"
                  display="block"
                  label={label}
                  placeholder={placeholder}
                  elementBefore={elementBefore}
                  elementAfter={elementAfter}
                  {...{
                    ...dsInputProps,
                    onChange: (e) => {
                      // The onChange of the textInput needs to both:
                      // a. Call the Downshift onChange from getInputProps().
                      // b. Optionally call a passed-in onChange if present, to support a controlled component.
                      if (!!dsInputProps?.onChange) {
                        dsInputProps?.onChange(e);
                      }
                      if (!!inputProps?.onChange) {
                        inputProps?.onChange(e);
                      }
                    },
                  }}
                  {...omit(inputProps, 'onChange')}
                />
              </Box>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: 'white',
                  zIndex: 1000,
                  position: 'absolute',
                }}
                {...getMenuProps()}
              >
                {isOpen ? (
                  <Box
                    boxShadow="0px 2px 6px rgba(118, 118, 118, 0.4)"
                    flexDirection="column"
                    borderRadius="4px"
                    width="100%"
                    overflow="auto"
                    maxHeight="250px"
                  >
                    {suggestions.map((item, index) => (
                      <Box
                        key={item.text}
                        px={400}
                        paddingTop={200}
                        data-test-id="suggestions"
                        paddingBottom={100}
                        justifyContent="space-between"
                        backgroundColor={
                          index === highlightedIndex ? 'neutral.400' : 'none '
                        }
                        {...getItemProps({
                          key: item.text,
                          index,
                          // TODO: Fix this the next time the file is edited.
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          item: item as any,
                        })}
                      >
                        {item.text}
                        {item.icon && (
                          <span data-test-id={`${item.text}-icon`}>
                            <img
                              width="16px"
                              height="16px"
                              src={item.icon}
                              alt="icon"
                            />
                          </span>
                        )}
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </div>
            </Box>
          );
        }}
      </Downshift>
    );
  },
);
