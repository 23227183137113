import { legacyColors as hoverColors } from '@hover/blueprint/foundation';
import { css } from 'styled-components';

const baseFontSize = 16;
const NAVBAR_HEIGHT = '66px';
// don't delete any of these until you've confirmed they arne't being used in @hover/header anymore
const headerColors = {
  WHITE: hoverColors.neutral0, // https://github.com/hoverinc/header/blob/8f11dd9a487e8576418f5bd4afcf652f9f3a7a2b/packages/header/src/components/HamburgerMenu.tsx#L48
  GRAY500: hoverColors.neutral600, // https://github.com/hoverinc/header/blob/8f11dd9a487e8576418f5bd4afcf652f9f3a7a2b/packages/header/src/components/HamburgerMenu.tsx#L32
  GRAY600: hoverColors.neutral700, // https://github.com/hoverinc/header/blob/8f11dd9a487e8576418f5bd4afcf652f9f3a7a2b/packages/header/src/components/HamburgerMenu.tsx#L51
  BLACK: hoverColors.neutral700, // https://github.com/hoverinc/header/blob/8f11dd9a487e8576418f5bd4afcf652f9f3a7a2b/packages/header/src/components/HamburgerMenu.tsx#L36
};

const pxToRem = (px) => px / baseFontSize;

const breakpoints = ['320px', '375px', '768px', '1024px', '1200px', '1366px'];
/* eslint-disable prefer-destructuring */
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];
breakpoints.mobileSmall = breakpoints[0];
breakpoints.mobileMed = breakpoints[1];
breakpoints.tabletPortrait = breakpoints[2];
breakpoints.desktop = breakpoints[3];
breakpoints.xlDesktop = breakpoints[4];
breakpoints.xxlDesktop = breakpoints[5];
breakpoints.largerThanMobile = '376px';
breakpoints.largerThanIPadPortrait = '769px';
breakpoints.largerThanIPadLandscape = '1025px';
/* eslint-enable prefer-destructuring */

const sizes = [];
sizes.maxModalWidth = '768px';

const theme = {
  space: [0, 4, 8, 16, 24, 32, 40, 56],
  breakpoints,
  sizes,
  constants: {
    navbarHeight: NAVBAR_HEIGHT,
  },
  mediaQueries: {
    largerThanMobile: `@media screen and (min-width: ${breakpoints.largerThanMobile})`,
    largerThanIPadPortrait: `@media screen and (min-width: ${breakpoints.largerThanIPadPortrait})`,
    largerThanIPadLandscape: `@media screen and (min-width: ${breakpoints.largerThanIPadLandscape})`,
  },
  colors: {
    WHITE: '#FFFFFF', // for Header
    transparent: 'transparent',
    black: '#171f2e',
    silver: '#f6f6f7',
    // green
    actionGreen: '#30ad7d',
    green: '#008000',
    // yellow
    processingYellow: '#ecb252',
    // blue
    defaultActionBlue: '#23a2e0',
    defaultActionDarkBlue: '#1D8ABF',
    activeBlue: '#145f85',
    lightActionBlue: '#a6daf3;',
    lightBlue: '#4ba1db',
    lightBlueAlt: '#def1fb',
    // navy
    defaultNavy: '#161e2f',
    // red
    alizarinCrimson: '#E43239',
    borderError: '#d1495a',
    errorRed: '#e23956',
    // gray
    border: '#999999',
    licorice: '#333947',
    defaultGray: '#838b9b',
    darkGray: '#9ba2ae',
    darkerGray2: '#555555',
    mediumGray: '#b4b8c3',
    slateGray: '#788493',
    mediumGrayFour: '#a9adb5',
    mediumGrayFive: '#8a8e97',
    lightGray: '#ccd0d6',
    lightGrayTwo: '#f3f4f4',
    lightGrayThree: '#D3D3D3',
    lightGrayFour: '#D0D2D5',
    extraLightGray: '#e5e7ea',
    disabledGray: '#d6d7d9',
    lightGrayText: '#a2a5ab',
    mediumGrayText: '#737882',

    // hoverColors eg primary500, neutral200, etc...
    neutral000: hoverColors.neutral0,
    ...hoverColors,
    ...headerColors, // colors that @hover/header needs to work (do not delete until confirmed @hover/header is no longer using)

    // features specific
    materialOrder: {
      qa: {
        headerBorder: '#dfdfdf',
        headerShadow: '0 10px 16px 0 rgba(0, 0, 0, 0.12)',
        footerShadow: '0 2px 4px 0 rgba(172, 172, 172, 0.59)',
      },
      searchBar: {
        border: '#e2e2e2',
        bg: 'rgba(246, 245, 245, 0.5)',
        activeBorder: '#1472a5',
      },
    },
  },
  transitions: {
    default: '100ms ease-in-out',
  },
  mixins: {
    font(weight, size, important) {
      return css`
        ${this.fontWeights[weight + (important ? 'Important' : '')]};
        font-size: ${pxToRem(size)}rem ${important ? ' !important' : ''};
      `;
    },
    textEllipsis() {
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `;
    },
    fontWeights: {
      light: css`
        font-family: 'Gotham Light', 'Helvetica Neue', 'Arial', sans-serif;
      `,
      book: css`
        font-family: 'Gotham Book', 'Helvetica Neue', 'Arial', sans-serif;
      `,
      medium: css`
        font-family: 'Gotham Medium', 'Helvetica Neue', 'Arial', sans-serif;
      `,
      bold: css`
        font-family: 'Gotham Bold', 'Helvetica Neue', 'Arial', sans-serif;
      `,
      boldImportant: css`
        font-family: 'Gotham Bold', 'Helvetica Neue', 'Arial', sans-serif !important;
      `,
      mediumImportant: css`
        font-family: 'Gotham Medium', 'Helvetica Neue', 'Arial', sans-serif !important;
      `,
      bookImportant: css`
        font-family: 'Gotham Book', 'Helvetica Neue', 'Arial', sans-serif !important;
      `,
    },
    focusBorder: css`
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    `,
  },
  utils: {
    pxToRem,
  },

  // Header themes:
  fonts: {
    CLEAR_SANS: 'Clear Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    PROXIMA_NOVA:
      'ProximaNova Regular, Helvetica Neue, Helvetica, Arial, sans-serif',
    AVENIR_NEXT: 'Avenir Next, Helvetica Neue, Helvetica, Arial, sans-serif',
    GOTHAM_BOOK: 'Gotham Book, Helvetica Neue, Arial, sans-serif;',
    GOTHAM_LIGHT: 'Gotham Light, Helvetica Neue, Arial, sans-serif;',
  },
  fontSizes: {
    LARGE: 18,
    XLARGE: 22,
  },
  // used by HOVER Header
  transitionSpeeds: {
    MEDIUM: 300,
  },
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default theme;
