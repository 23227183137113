import * as answerActions from './answerActions';
import * as checklistActions from './checklistActions';
import * as discountActions from './discountActions';
import * as esignActions from './esignActions';
import * as estimateActions from './estimateActions';
import * as estimateGroupActions from './estimateGroupActions';
import * as inputCategoriesActions from './inputCategoriesActions';
import * as lineItemActions from './lineItemActions';
import * as measurementsActions from './measurementsActions';
import * as miscActions from './miscActions';
import * as pageActions from './pageActions';
import * as proposalActions from './proposalActions';
import * as questionActions from './questionActions';
import * as requiredInputsActions from './requiredInputsActions';
import * as templatesActions from './templatesActions';
import * as wasteFactorActions from './wasteFactorActions';

export const estimatorActions = {
  ...answerActions,
  ...discountActions,
  ...estimateActions,
  ...estimateGroupActions,
  ...inputCategoriesActions,
  ...lineItemActions,
  ...measurementsActions,
  ...miscActions,
  ...pageActions,
  ...questionActions,
  ...requiredInputsActions,
  ...templatesActions,
  ...proposalActions,
  ...esignActions,
  ...wasteFactorActions,
  ...checklistActions,
};
