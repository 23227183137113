import { Box } from '@hover/blueprint';

import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';

export const EstimateGroupBottomBar: React.FC = ({ children }) => (
  <Box
    position="sticky"
    width="100vw"
    maxHeight="fit-content"
    bottom={0}
    justifyContent="center"
    backgroundColor="neutral.0"
    borderTopWidth="1.5px"
    borderTopStyle="solid"
    borderColor="neutral.200"
    data-test-id="estimateGroupOverviewFooter"
  >
    <EstimatorResponsiveWrapper
      flexDirection="row"
      justifyContent="space-between"
    >
      {children}
    </EstimatorResponsiveWrapper>
  </Box>
);
