import { createAction } from 'typesafe-actions';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import type {
  distributionApprovedBranchesQuery_distributionApprovedBranches as Branch,
  distributionApprovedBranchesQuery_distributionApprovedBranches_jobAccounts as JobAccount,
} from 'src/api/types/distributionApprovedBranchesQuery';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors_distributor as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import type {
  ListItemIdsByTypeAndTrade,
  ListItemsByTrade,
} from 'src/features/project/types';

export const updateSelectedListItems = createAction(
  'PROJECT/UPDATE_SELECTED_LIST_ITEM_IDS',
  (selectedListItemIds: ListItemIdsByTypeAndTrade | null) => ({
    selectedListItemIds,
  }),
)();

export const toggleSelectedItem = createAction(
  'PROJECT/TOGGLE_SELECTED_ITEM',
  (type: LineItemTypeEnum, trade: TradeTypeEnum, itemId: string | number) => ({
    type,
    trade,
    itemId,
  }),
)();

export const toggleSelectAllTypeItems = createAction(
  'PROJECT/TOGGLE_SELECT_ALL_TYPE_ITEMS',
  (type: LineItemTypeEnum, typeItemsByTrade: ListItemsByTrade) => ({
    type,
    typeItemsByTrade,
  }),
)();

export const toggleSelectAllTradeItems = createAction(
  'PROJECT/TOGGLE_SELECT_ALL_TRADE_ITEMS',
  (type: LineItemTypeEnum, trade: TradeTypeEnum, items: ListItem[]) => ({
    type,
    trade,
    items,
  }),
)();

export const removeLineItem = createAction(
  'PROJECT/REMOVE_LINE_ITEM',
  (type: LineItemTypeEnum, trade: TradeTypeEnum, itemId: string | number) => ({
    type,
    trade,
    itemId,
  }),
)();

export const updateDistributor = createAction(
  'PROJECT/UPDATE_DISTRIBUTOR',
  (distributor: Distributor | null) => ({ distributor }),
)();

export const updateBranch = createAction(
  'PROJECT/BRANCH',
  (branch: Branch | null) => ({ branch }),
)();

export const updateJobAccount = createAction(
  'PROJECT/UPDATE_JOB_ACCOUNT',
  (jobAccount: JobAccount | null) => ({ jobAccount }),
)();
