/* eslint-disable max-classes-per-file */
import { get as lodashGet, cloneDeep, uniqBy } from 'lodash';

import { InspectionConfigChecklistQuestionTypeEnum } from 'src/api/graphql-global-types';
import { estimationConfigInputCategories_estimationConfigInputCategories_edges_node as Category } from 'src/api/types/estimationConfigInputCategories';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { inspectionChecklist_inspectionChecklist_sections_questions as ChecklistQuestion } from 'src/api/types/inspectionChecklist';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  PARTIAL_ROOF_LINE_SEGMENT_FEATURES_QUESTIONS,
  PARTIAL_SIDING_LINE_SEGMENT_FEATURES_QUESTIONS,
  SIDING_ARGUMENT_MAP,
} from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import {
  SELECT_TEMPLATES,
  MEASUREMENT,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
  OTHER,
  CUSTOM_LINE_ITEMS,
  ADJUST_SIDING_MEASUREMENT,
  MEASUREMENT_NAME,
  ROOF_FACET_SELECTION_3D,
  SIDING_FACET_SELECTION_3D,
  ROOF_FACET_SELECTION,
  PARTIAL_SIDING_SELECTION,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import {
  PARTIAL_SIDING_SELECTION_QUESTION,
  SIDING_MEASUREMENTS,
} from 'src/features/exteriorEstimator/constants/sidingConstants';
import {
  Input,
  Page,
  CustomInputTypes,
  Checklist,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';
import * as measurementsUtils from 'src/features/exteriorEstimator/utils/measurementsUtils';
import { moveElementInArray } from 'src/features/exteriorEstimator/utils/miscUtils';
import {
  isUsingRoofTemplate,
  isUsingSidingTemplate,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import {
  COMMERCE_BRICK_AND_STONE_ESTIMATION,
  COMMERCE_USE_HDF,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

import PartialSidingUtils from './PartialSidingUtils';
import PartialsMeasurementsMap from './PartialsMeasurementsMap';

interface QuestionCategories {
  [key: string]: Input[];
}

interface AreaPerLabelEntity {
  [key: string]: number;
}

interface QuestionGeneratorParams {
  inputs: Input[];
  categories: Category[];
  templates: Template[] | null;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobMeasurements: any;
  selectedTemplates: Template[] | null;
  tradeTypes: TradeType[];
  checklist: Checklist | null;
  showOrderingVersion: boolean;
  partialMeasurements: PlainMeasurements;
  estimationJson: Measurements;
}

export class QuestionsGenerator {
  inputs: Input[];

  questions: Input[];

  categories: Category[];

  templates: Template[] | null;

  tradeTypes: TradeType[];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobMeasurements: any;

  selectedTemplates: Template[] | null;

  checklist: Checklist | null;

  flowRelatedPages: string[];

  showOrderingVersion: boolean;

  partialMeasurements: PlainMeasurements;

  estimationJson: Measurements;

  constructor(params: QuestionGeneratorParams) {
    this.inputs = params.inputs;
    this.categories = params.categories;
    this.templates = params.templates;
    this.jobMeasurements = params.jobMeasurements;
    this.selectedTemplates = params.selectedTemplates;
    this.checklist = params.checklist;
    this.tradeTypes = params.tradeTypes;
    this.showOrderingVersion = params.showOrderingVersion;
    this.partialMeasurements = params.partialMeasurements;
    this.estimationJson = params.estimationJson;

    // this is sorted
    this.flowRelatedPages = [
      SELECT_TEMPLATES,
      ROOF_FACET_SELECTION_3D,
      SIDING_FACET_SELECTION_3D,
    ];

    this.questions = this.generateQuestions();
  }

  public isManualMeasurementsNeeded() {
    return this.questions.some(
      (question) =>
        question.inputCategory.name === MEASUREMENT_NAME && question.isEditable,
    );
  }

  public hasRoofMeasurements() {
    const roof = this.jobMeasurements?.roof || null;
    return !!roof;
  }

  public hasSidingMeasurements() {
    const sides = this.jobMeasurements?.sides || null;
    if (!sides) return false;
    const { front, right, back, left } = sides;
    if (
      front?.total === 0 &&
      front?.area_per_label?.length === 0 &&
      right?.total === 0 &&
      right?.area_per_label?.length === 0 &&
      back?.total === 0 &&
      back?.area_per_label?.length === 0 &&
      left?.total === 0 &&
      left?.area_per_label?.length === 0
    )
      return false; // HDF specific
    if (!!front || !!right || !!back || !!left) return true;
    return false;
  }

  public getSortedPagesWithQuestions() {
    const questionCategories: QuestionCategories = {};
    // assign category to each question
    this.questions.forEach((question) => {
      let category;

      if (this.includedInCategories(question)) {
        category = this.standardizeCategoryName(question.inputCategory.name);
      } else {
        category = OTHER;
      }
      question = this.overrideQuestionDefaultValueFromChecklist(question); // eslint-disable-line no-param-reassign
      questionCategories[category] = questionCategories[category] || [];
      questionCategories[category].push(question);
    });

    // organize categories into pages
    let pagesWithQuestions: Page[] = [];
    Object.entries(questionCategories).forEach(([key, value]) => {
      // remove blanks potentially created by upgrades
      if (value.length > 0) {
        pagesWithQuestions.push({
          category: key,
          questions: value,
          shouldRender: true,
        });
      }
    });

    if (this.isUsingRoofTemplate() && this.hasRoofMeasurements()) {
      // make roof v2 page
      pagesWithQuestions.push({
        category: ROOF_FACET_SELECTION_3D,
        tabs: [
          {
            category: ROOF_FACET_SELECTION,
            questions: questionCategories[ROOF_FACET_SELECTION],
          },
          {
            category: ROOF_LINE_SEGMENT_ADJUSTMENT,
            questions: questionCategories[ROOF_LINE_SEGMENT_ADJUSTMENT],
          },
        ],
        shouldRender: true,
        questions: [
          ...(questionCategories[ROOF_FACET_SELECTION] || []),
          ...(questionCategories[ROOF_LINE_SEGMENT_ADJUSTMENT] || []),
        ],
      });
    }

    if (this.usingSidingTemplate() && this.hasSidingMeasurements()) {
      // make siding v2 page
      pagesWithQuestions.push({
        category: SIDING_FACET_SELECTION_3D,
        tabs: [
          {
            category: PARTIAL_SIDING_SELECTION,
            questions: questionCategories[PARTIAL_SIDING_SELECTION],
          },
          {
            category: SIDING_LINE_SEGMENT_ADJUSTMENT,
            questions: questionCategories[SIDING_LINE_SEGMENT_ADJUSTMENT],
          },
        ],
        questions: [
          ...(questionCategories[PARTIAL_SIDING_SELECTION] || []),
          ...(questionCategories[SIDING_LINE_SEGMENT_ADJUSTMENT] || []),
        ],
        shouldRender: true,
      });
    }

    // sort the pages
    const sortOrder = this.flowRelatedPages.concat(this.sortedCategories());

    pagesWithQuestions.sort(
      (a, b) => sortOrder.indexOf(a.category) - sortOrder.indexOf(b.category),
    );

    this.orderQuestions(pagesWithQuestions);

    pagesWithQuestions = this.removeMeasurementQuestions(pagesWithQuestions);
    pagesWithQuestions = this.reorderMeasurementQuestions(pagesWithQuestions);

    pagesWithQuestions =
      this.removeAdjustSidingMeasurementsQuestions(pagesWithQuestions);

    if (!this.showOrderingVersion) {
      pagesWithQuestions.push({
        category: CUSTOM_LINE_ITEMS,
        questions: [],
        shouldRender: true,
      });
    }

    // These categories no longer get their own page in the estimator so they are filtered out
    // ROOF_FACET_SELECTION & ROOF_LINE_SEGMENT_ADJUSTMENT are tabs in the ROOF_FACET_SELECTION_3D page
    // PARTIAL_SIDING_SELECTION & SIDING_LINE_SEGMENT_ADJUSTMENT are tabs in the SIDING_FACET_SELECTION_3D page
    pagesWithQuestions = pagesWithQuestions.filter(
      (page) =>
        ![
          ROOF_FACET_SELECTION,
          ROOF_LINE_SEGMENT_ADJUSTMENT,
          PARTIAL_SIDING_SELECTION,
          SIDING_LINE_SEGMENT_ADJUSTMENT,
        ].includes(page.category),
    );

    return pagesWithQuestions;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line class-methods-use-this
  private orderQuestions(pagesWithQuestions: Page[]) {
    pagesWithQuestions.forEach((page) => {
      page.questions?.sort((q1, q2) => {
        const order1 = q1.sortOrder;
        const order2 = q2.sortOrder;
        const isNum = (n: number | null | undefined) => n || n === 0;

        if (isNum(order1) && isNum(order2)) {
          return (order1 || 0) - (order2 || 0);
        }
        return isNum(order1) ? -1 : 1; // 'Null' goes to the back
      });
    });
  }

  private overrideQuestionDefaultValueFromChecklist(question: Input) {
    const newQuestion = { ...question };
    const checklistQuestions =
      this?.checklist?.sections.flatMap((section) => section.questions) || [];

    const checklistQuestion = checklistQuestions.find(
      (chQuestion: ChecklistQuestion) =>
        chQuestion.estimationConfigInputId === question.id,
    );
    if (!checklistQuestion) return newQuestion;

    let answer: string | number | null = checklistQuestion.answerValue;
    if (
      checklistQuestion.questionType ===
      InspectionConfigChecklistQuestionTypeEnum.BOOLEAN
    ) {
      // cast the string 'true'/'false' to boolean
      answer = checklistQuestion.answerValue === 'true' ? 1 : 0;
    } else if (
      checklistQuestion.questionType ===
      InspectionConfigChecklistQuestionTypeEnum.SELECT
    ) {
      // find the answer value by the option id
      const answerValue = question.inputOptions?.find(
        (inputOption) =>
          inputOption.id === checklistQuestion.estimationConfigInputOptionId,
      )?.value;
      if (answerValue) answer = answerValue;
    }

    newQuestion.questionDefaultValue = answer;
    return newQuestion;
  }

  private removeAdjustSidingMeasurementsQuestions(pagesWithQuestions: Page[]) {
    const clone = cloneDeep(pagesWithQuestions);
    let shouldShow = false;

    Object.values(SIDING_MEASUREMENTS).forEach((field) => {
      const otherSidingQuestion = this.questions.find(
        (question) => question.argument === field,
      );
      if (!!this.jobMeasurements[field] || !!otherSidingQuestion)
        shouldShow = true;
    });
    const adjustSidingMeasurementsPage = clone.find(
      (page) => page.category === ADJUST_SIDING_MEASUREMENT,
    );
    if (adjustSidingMeasurementsPage)
      adjustSidingMeasurementsPage.shouldRender = shouldShow;

    return clone;
  }

  private removeMeasurementQuestions(pagesWithQuestions: Page[]) {
    const clone = cloneDeep(pagesWithQuestions);
    if (this.isManualMeasurementsNeeded()) {
      return clone;
    }

    const measurementPage = clone.find((page) => page.category === MEASUREMENT);
    if (measurementPage) measurementPage.shouldRender = false;
    return clone;
  }

  /* eslint-disable class-methods-use-this */
  private reorderMeasurementQuestions(pagesWithQuestions: Page[]) {
    const measurementPageIndex = pagesWithQuestions.findIndex(
      (page) => page.category === MEASUREMENT,
    );
    if (measurementPageIndex === -1) return pagesWithQuestions;
    const selectTemplatesIndex = pagesWithQuestions.findIndex(
      (page) => page.category === SELECT_TEMPLATES,
    );

    const clone = moveElementInArray(
      pagesWithQuestions,
      selectTemplatesIndex + 1,
      measurementPageIndex,
    );

    return clone;
  }

  private generateQuestions() {
    const inputs = this.updateMeasurementQuestions(this.inputs);

    const otherQuestions = this.generateFlowSpecificQuestions();
    return inputs.concat(otherQuestions);
  }

  private includedInCategories(question: Input) {
    if (!this.categories) return false;
    if (!question.inputCategory) return false;

    const categoryIds = this.categories.map((category) => category.id);
    const customCategoryNames = this.flowRelatedPages.concat([
      ROOF_FACET_SELECTION,
      ROOF_LINE_SEGMENT_ADJUSTMENT,
      PARTIAL_SIDING_SELECTION,
      SIDING_LINE_SEGMENT_ADJUSTMENT,
    ]);

    const isIncludedInCategories =
      customCategoryNames.includes(question.inputCategory.name) ||
      categoryIds.includes(question.inputCategory.id);

    return isIncludedInCategories;
  }

  private sortedCategories() {
    return this.categories
      .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
      .map((category) => this.standardizeCategoryName(category.name));
  }

  private generateFlowSpecificQuestions() {
    let flowSpecificQuestions: Input[] = [];
    if (this.hasRoofMeasurements()) {
      flowSpecificQuestions = flowSpecificQuestions.concat(
        this.generatePartialRoofQuestions(),
      );
    }
    flowSpecificQuestions = flowSpecificQuestions.concat(
      this.generateTemplateQuestions(),
    );
    if (this.hasSidingMeasurements()) {
      flowSpecificQuestions = flowSpecificQuestions.concat(
        this.generatePartialSidingQuestions(),
      );
    }
    return flowSpecificQuestions;
  }

  private isUsingRoofTemplate() {
    if (!this.selectedTemplates) return false;
    return isUsingRoofTemplate(this.selectedTemplates, this.tradeTypes);
  }

  private usingSidingTemplate() {
    if (!this.selectedTemplates) return false;
    return isUsingSidingTemplate(this.selectedTemplates, this.tradeTypes);
  }

  private generatePartialSidingQuestions() {
    const partialSidingQuestions: Input[] = [];
    if (!this.usingSidingTemplate()) return partialSidingQuestions;
    if (!this.jobMeasurements) return partialSidingQuestions;
    const sidingQuestionData = this.getPartialSidingQuestionData();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    Object.entries(sidingQuestionData).forEach(([face, data]: [string, any]) =>
      data.area_per_label.forEach((side: AreaPerLabelEntity) =>
        Object.entries(side).forEach(([id, total]) =>
          partialSidingQuestions.push({
            ...PARTIAL_SIDING_SELECTION_QUESTION,
            id,
            total,
            ...this.jobMeasurements.combinedAreaWithTrim[id],
            face,
            questionDefaultValue:
              PartialSidingUtils.setDefaultPartialSidingSelection(
                id,
                this.jobMeasurements.unknownsConnectedToSidingMap,
              ),
            name: id,
            question: id,
            inputType: CustomInputTypes.TILE_CHECKBOX,
          }),
        ),
      ),
    );

    // generate the line segments questions
    const sidingLineSegmentQuestions = this.getMeasurementInputs()
      .filter((question) => {
        return (
          question.argument &&
          PARTIAL_SIDING_LINE_SEGMENT_FEATURES_QUESTIONS.includes(
            question.argument,
          )
        );
      })
      .map((question) => ({
        ...question,
        inputCategory: {
          name: SIDING_LINE_SEGMENT_ADJUSTMENT,
          id: SIDING_LINE_SEGMENT_ADJUSTMENT,
        },
      }));
    partialSidingQuestions.push(...sidingLineSegmentQuestions);

    return uniqBy(partialSidingQuestions, 'id');
  }

  private generatePartialRoofQuestions() {
    let partialRoofQuestions: Input[] = [];
    // only if Roof template selected
    if (!this.isUsingRoofTemplate()) return partialRoofQuestions;
    if (this.jobMeasurements) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const roofFacetQuestions = this.getRoofFacets().map((face: any) => ({
        id: face.label,
        name: face.label,
        pitch: face.pitch,
        question: face.label,
        inputType: CustomInputTypes.TILE_CHECKBOX,
        area: face.area,
        inputCategory: {
          name: ROOF_FACET_SELECTION,
          id: ROOF_FACET_SELECTION,
        },
      }));
      partialRoofQuestions = partialRoofQuestions.concat(roofFacetQuestions);

      this.getMeasurementInputs().forEach((question) => {
        if (
          question.argument &&
          PARTIAL_ROOF_LINE_SEGMENT_FEATURES_QUESTIONS.includes(
            question.argument,
          )
        ) {
          partialRoofQuestions.push({
            ...question,
            inputCategory: {
              name: ROOF_LINE_SEGMENT_ADJUSTMENT,
              id: ROOF_LINE_SEGMENT_ADJUSTMENT,
            },
          });
        }
      });
    }

    return partialRoofQuestions;
  }

  private getMeasurementInputs() {
    return this.inputs.filter(
      (input) => input.inputCategory.name === MEASUREMENT_NAME,
    );
  }

  private updateMeasurementQuestions(inputs: Input[]) {
    const updateQuestionData = inputs.map((question) => {
      if (question.inputCategory.name !== MEASUREMENT_NAME) return question;
      let measurementValue;
      if (measurementsUtils.isPitch(question?.argument ?? '')) {
        measurementValue = measurementsUtils.getPitchTotal(
          question?.argument ?? '',
          this.jobMeasurements?.roof,
        );
      } else if (question.argument) {
        const { edgeTotals } = new PartialsMeasurementsMap(
          this.partialMeasurements,
          this.estimationJson,
        );

        if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION)) {
          measurementValue =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            edgeTotals[SIDING_ARGUMENT_MAP[question.argument]] ??
            lodashGet(this.jobMeasurements, question.argument) ??
            lodashGet(this.jobMeasurements.roof, question.argument);
        } else {
          measurementValue =
            lodashGet(this.jobMeasurements, question.argument) ??
            lodashGet(this.jobMeasurements.roof, question.argument);
        }
      }

      const isEditable =
        measurementValue == null ||
        (isEnabled(COMMERCE_USE_HDF) &&
          this.usingSidingTemplate() &&
          question.argument?.startsWith('siding') &&
          measurementValue === 0);

      return {
        ...question,
        answer: measurementValue,
        isEditable,
      };
    });
    return updateQuestionData;
  }

  private getPartialSidingQuestionData() {
    return this.jobMeasurements?.sides ?? {};
  }

  private getRoofFacets() {
    return this.jobMeasurements?.roof?.pitch ?? [];
  }

  // "test category" => TEST_CATEGORY
  private standardizeCategoryName(name: string) {
    return name.toUpperCase().replace(/ /g, '_');
  }

  private generateTemplateQuestions() {
    if (!this.templates) return [];
    return this.convertTemplatesToInputs();
  }

  private convertTemplatesToInputs() {
    const templateInputs: Input[] = [];
    if (!this.templates) return templateInputs;

    this.templates.forEach((template) => {
      templateInputs.push({
        name: template.name,
        id: `template${template.id}`,
        inputType: CustomInputTypes.TILE_CHECKBOX,
        sortOrder: template.sortOrder,
        trade_type: template.tradeType,
        question: template.name,
        inputCategory: {
          name: SELECT_TEMPLATES,
          id: SELECT_TEMPLATES,
        },
      });
    });
    return templateInputs;
  }
}

export class EstimatorFlowGenerator {
  static generatePages(params: QuestionGeneratorParams) {
    return new QuestionsGenerator(params);
  }
}
