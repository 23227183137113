import { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Heading, Button, Body, Panel } from '@hover/blueprint';
import { useDispatch, connect } from 'react-redux';

import {
  ProjectManagementSignatureRequestRequestTypeEnum,
  ProjectManagementSignatureRequestStateEnum,
} from 'src/api/graphql-global-types';
import { projectManagementProposalDocument_projectManagementProposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocument';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { CREATE_SIGNATURE_REQUEST } from 'src/features/exteriorEstimator/apis/queries/proposal';
import { EmbeddedSignatureFlowLoader } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/EmbeddedSignatureFlowLoader';
import { StatusBadge } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/StatusBadge';
import { isValidProjectManagementSignatureRequestState } from 'src/features/exteriorEstimator/constants/ProjectManagementSignatureRequestStates';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { downloadPdf } from 'src/lib/download';
import { RootState } from 'src/types/reduxStore';
import { isReactNativeWebview } from 'src/utils/EnvUtils';

interface IProps {
  proposal: Proposal;
  jobName: string;
  setProposalModalView: (view: ProposalViews | null) => void;
  hideTotalPrice: boolean;
}

export const mapStateToProps = (state: RootState) => ({
  signatureRequest: state.exteriorEstimator.proposalsData?.signatureRequest,
});

type Props = IProps & ReturnType<typeof mapStateToProps>;

export const ProposalShowBodyComponent: React.FC<Props> = ({
  proposal,
  jobName,
  signatureRequest,
  setProposalModalView,
  hideTotalPrice,
}) => {
  const dispatch = useDispatch();
  const [disableEsign, setDisableEsign] = useState(false);

  const [createEmailSignatureRequest] = useMutation(CREATE_SIGNATURE_REQUEST, {
    onCompleted: ({ projectManagementSignatureRequestCreate }) => {
      const { signatureRequest: signatureRequestResponse } =
        projectManagementSignatureRequestCreate;
      dispatch(estimatorActions.emailESign.success(signatureRequestResponse));
    },
    onError: (error) => {
      dispatch(estimatorActions.emailESign.failure(error));
    },
  });

  const [createEmbedSignatureRequest] = useMutation(CREATE_SIGNATURE_REQUEST, {
    onCompleted: ({ projectManagementSignatureRequestCreate }) => {
      const { signatureRequest: signatureRequestResponse } =
        projectManagementSignatureRequestCreate;
      dispatch(
        estimatorActions.embeddedESign.success(signatureRequestResponse),
      );
    },
    onError: (error) => {
      dispatch(estimatorActions.embeddedESign.failure(error));
    },
  });

  const handleShare = () => {
    navigator
      .share({
        title: proposal?.pdf?.filename ?? 'Proposal',
        url: proposal?.pdf?.redirectUrl ?? 'https://hover.to/404',
      })
      .then(() => {
        // handle success
      })
      .catch(console.error);
  };

  const handleShow = useCallback(() => {
    if (isReactNativeWebview()) {
      const payload = { action: 'openPDF', url: proposal?.pdf?.url };
      window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    } else {
      if (!proposal?.pdf?.url) return;
      const fileName = `${jobName}-proposal-${proposal.totalPrice}`;

      downloadPdf({ url: proposal?.pdf?.url, name: fileName });
    }
  }, [proposal, jobName]);

  const handleEmailSign = useCallback(() => {
    setDisableEsign(true);

    setProposalModalView(ProposalViews.SENDING_EMAIL_ESIGN);
    createEmailSignatureRequest({
      variables: {
        signatureRequestAttributes: {
          proposalDocumentId: proposal.id,
          requestType: ProjectManagementSignatureRequestRequestTypeEnum.EMAIL,
        },
      },
    });
  }, [proposal, createEmailSignatureRequest, setProposalModalView]);

  const handleEmbeddedSign = useCallback(() => {
    setDisableEsign(true);
    if (!proposal) return;

    createEmbedSignatureRequest({
      variables: {
        signatureRequestAttributes: {
          proposalDocumentId: proposal.id,
          requestType: ProjectManagementSignatureRequestRequestTypeEnum.EMBED,
        },
      },
    });
  }, [proposal, createEmbedSignatureRequest]);

  const eSignButtons = () => {
    if (!proposal) return null;
    if (
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.SIGNED ||
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.COMPLETE
    )
      return null;
    const isEmailButtonDisabled =
      !proposal.customerEmail ||
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.REQUESTED ||
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.QUEUED ||
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.CREATED ||
      proposal.signatureRequest?.state ===
        ProjectManagementSignatureRequestStateEnum.FAILED;

    return (
      <div>
        <Box marginBottom={400}>
          <Button
            flex={1}
            size="large"
            type="submit"
            onClick={handleEmbeddedSign}
            data-test-id="proposalShow-onscreen"
            isDisabled={disableEsign || !proposal.customerEmail}
          >
            Onscreen eSignature
          </Button>
        </Box>
        <Box marginBottom={400}>
          <Button
            flex={1}
            size="large"
            type="submit"
            onClick={handleEmailSign}
            isDisabled={disableEsign || isEmailButtonDisabled}
            data-test-id="proposalShow-email"
          >
            Request email eSignature
          </Button>
        </Box>
      </div>
    );
  };

  if (!proposal) return null;

  const signatureRequestState = proposal?.signatureRequest?.state;
  const failureReason = proposal?.signatureRequest?.failureReason;

  const createdAt = new Date(proposal.createdAt);

  return (
    <Box flexShrink={0} flex={1} data-test-id="proposalShowBody" minWidth={0}>
      {signatureRequest?.isRequested && signatureRequest?.requestId && (
        <EmbeddedSignatureFlowLoader
          id={signatureRequest.requestId}
          key={signatureRequest.id}
          setProposalModalView={setProposalModalView}
        />
      )}
      <Box flexDirection="column" margin={400} flex={1} minWidth={0}>
        <Box flex={1} flexDirection="column" as="section">
          <Heading size={300} marginBottom={300}>
            {!hideTotalPrice && (
              <FormattedNumber
                value={proposal.totalPrice ?? 0}
                format="$0,0.00"
                data-test-id="proposalTotal"
              />
            )}
          </Heading>
          <Box
            data-test-id="customerNameList"
            flexDirection="column"
            marginBottom={400}
          >
            {proposal?.customers &&
              proposal.customers.map((customer) => (
                <Box key={customer?.id} flexDirection="column">
                  {customer.fullName && (
                    <Heading size={200} marginBottom={0}>
                      {customer.fullName}
                    </Heading>
                  )}
                  {customer.emailAddress && (
                    <Body
                      marginTop={0}
                      marginBottom={customer.phoneNumber ? 0 : 500}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {customer.emailAddress}
                    </Body>
                  )}
                  {customer.phoneNumber && (
                    <Body marginTop={0}>{customer.phoneNumber}</Body>
                  )}
                </Box>
              ))}
            <Body marginTop={0} color="neutral600" size={500}>
              {createdAt && `Created: ${createdAt.toLocaleString()}`}
            </Body>
          </Box>
          <>
            {isValidProjectManagementSignatureRequestState(
              signatureRequestState,
            ) && (
              <Box
                marginBottom={600}
                flexDirection="column"
                alignItems="flex-start"
              >
                <StatusBadge
                  signatureRequestState={signatureRequestState}
                  marginBottom={500}
                />
                {failureReason && (
                  <Panel
                    backgroundColor="danger100"
                    color="danger800"
                    data-test-id="proposalShow-failureReason"
                    width={1}
                  >
                    <Body color="inherit" marginTop={0} marginBottom={0}>
                      {failureReason}
                    </Body>
                  </Panel>
                )}
              </Box>
            )}
          </>

          {!proposal.customerEmail && (
            <Body size={400}>
              This proposal does not have an email. Please create a new proposal
              with a valid email address to request signatures.
            </Body>
          )}
        </Box>
        {eSignButtons()}
        {!!navigator && !!navigator.share && (
          <Box marginBottom={400}>
            <Button
              fill="outline"
              flex={1}
              size="large"
              type="submit"
              onClick={handleShare}
              data-test-id="proposalShare"
            >
              Share
            </Button>
          </Box>
        )}
        <Box>
          <Button
            flex={1}
            fill="outline"
            size="large"
            type="submit"
            onClick={handleShow}
            data-test-id="proposalShow"
          >
            View PDF
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const ProposalShowBody = connect(mapStateToProps)(
  ProposalShowBodyComponent,
);
