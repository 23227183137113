import { flatten, some } from 'lodash';
import { createSelector } from 'reselect';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  getLineItemsWithVariationsFromTemplateGroups,
  isEstimateGroupSold,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { RootState } from 'src/types/reduxStore';

export const getEstimateGroupId = (state: RootState) =>
  state.exteriorEstimator.estimateGroup?.data?.id;

export const getEstimateGroupEstimates = (state: RootState) =>
  state.exteriorEstimator.estimateGroup?.data?.estimates;

export const getEstimatefromEstimateGroupById = (
  state: RootState,
  estimateId: number,
) =>
  createSelector(getEstimateGroupEstimates, (estimates) =>
    estimates?.find((e) => e.id === Number(estimateId)),
  )(state);

export const getHasDismissedMissingVariationsWarning = (state: RootState) =>
  state.exteriorEstimator.estimateGroup.hasDismissedMissingVariationsWarning;

export const getEstimationGroupEstimate = (state: RootState, id: number) =>
  state.exteriorEstimator.estimateGroup?.data?.estimates.find((estimate) => {
    return estimate.id === id;
  });

export const getActiveEstimates = createSelector(
  getEstimateGroupEstimates,
  (estimates = []) => estimates.filter((est) => est.active),
);

export const getActiveEstimateOfSameTrade = createSelector(
  getActiveEstimates,
  (_: RootState, tradeType: TradeTypeEnum) => tradeType,
  (estimates, tradeType) =>
    estimates?.find((estimate) => {
      return estimate.tradeType === tradeType && estimate.active;
    }),
);

export const getAllActiveEstimates = (state: RootState) => {
  if (!state.exteriorEstimator.estimateGroup?.data?.estimates) return [];
  return state.exteriorEstimator.estimateGroup?.data?.estimates.filter(
    (estimate) => {
      return estimate.active;
    },
  );
};

export const getEstimationGroupDiscounts = (state: RootState) =>
  state.exteriorEstimator.discounts;

export const getActiveEstimateDiscounts = createSelector(
  getActiveEstimates,
  getEstimationGroupDiscounts,
  (activeEstimates, discounts = {}) => {
    if (!activeEstimates || Object.keys(discounts).length === 0) return [];
    const activeEstimateIds = activeEstimates.map((estimate) => estimate.id);
    return Object.values(discounts).filter((discount) =>
      activeEstimateIds.includes(discount.estimationEstimateId ?? -1),
    );
  },
);

export const getEstimateGroupTotalPrice = (state: RootState) =>
  state.exteriorEstimator.estimateGroup?.data?.totalPrice;

export const getAreSelectedVariationsMissing = createSelector(
  getActiveEstimates,
  (estimates = []) => {
    const lineItemsWithVariations = flatten(
      estimates.map((estimate) =>
        getLineItemsWithVariationsFromTemplateGroups(estimate),
      ),
    );

    return lineItemsWithVariations.some(
      (lineItem) => !lineItem?.selectedVariation,
    );
  },
);

export const getTotalActiveEstimatesMonthlyCost = (state: RootState) =>
  state.exteriorEstimator.estimateGroup?.data?.monthlyPrice;

export const getEstimateGroupError = (state: RootState) =>
  state.exteriorEstimator.estimateGroup.error;

export const getEstimateGroup = (state: RootState) =>
  state.exteriorEstimator.estimateGroup?.data;

export const getIsEstimateGroupSold = createSelector(
  getEstimateGroup,
  (estimateGroup) => isEstimateGroupSold(estimateGroup),
);

export const getSalesOpportunity = createSelector(
  getEstimateGroup,
  (estimateGroup) => estimateGroup?.salesOpportunity,
);

export const getHasNoActiveEstimate = createSelector(
  getEstimateGroup,
  (estimateGroup) => !some(estimateGroup?.estimates, { active: true }),
);

export const getEstimateGroupOrgId = (state: RootState) =>
  state.exteriorEstimator.estimateGroup.data?.orgId;
