const eventNames = {
  estimator: {
    checklist: {
      page: 'property-inspection-checklist',
    },
    customItemsPage: {
      page: 'custom-items-page',
    },
    selectRoofType: { page: 'Select-roof-type' },
    selectSidingType: { page: 'Select-siding-type' },
    sidingPartialSelection: { page: 'Siding-partial-selection' },
    selectTemplates: { page: 'Select-templates' },
    wasteFactor: { page: 'Waste-factor' },
    estimateGroupsScreen: {
      page: 'Estimate-groups',
    },
    estimateDetailsScreen: {
      page: 'Estimate-details',
      changeSoldStatusModal: {
        page: 'Estimate-details-change-sold-status-modal',
      },
      markAsSold: {
        markAsSoldConfirmPressed: `Confirm as Sold`,
        markAsSoldNotYetPressed: 'Not Yet Sold',
        markedAsSoldCancelPressed: 'Cancel',
        markedAsSoldRemovePressed: 'Remove',
      },
      promotion: {
        page: 'Estimate-details-add-promotion-modal',
      },
      proposal: {
        removeCustomerModal: 'remove-customer-modal',
        page: 'get-proposal-modal',
      },
      estimates: {
        colorModal: 'Color-modal',
        selectEstimates: 'Select-estimates',
      },
      inputSummary: {
        page: 'estimate-input-summary',
      },
    },
    colorSelection: 'select-variations',
  },
  pmp: {
    page: 'production-page',
    addListItemModal: {
      page: 'production-page-add-list-item-modal',
    },
    pdf: {
      page: 'production-page-pdf-modal',
      downloadNew: 'production-page-pdf-download-new',
      downloadExisting: 'production-page-pdf-download-existing',
    },
  },
  saveAndAccess: {
    page: 'save-and-access',
    selectEstimatesPressed: 'Select Estimates',
    opportunityPressed: 'Project',
    proposalPressed: 'Proposal',
    productionPressed: 'Production',
  },
  settings: {
    templates: {
      page: 'Template-management',
    },
    template: {
      page: 'Template-edit',
    },
    lineItems: {
      page: 'Manage-line-items',
    },
    proposal: {
      page: 'Manage-proposal',
      deleteSectionModal: 'Delete-section-modal',
      editSectionInput: 'Edit-section_input',
    },
    section: {
      page: 'Edit-section_input',
      scopeOfWorkPage: 'Scope of Work',
    },
    colorGrouping: {
      page: 'Color_Grouping_Rules',
      create: 'Color_Grouping_Rules_Create',
      edit: 'Color_Grouping_Rules_Edit',
    },
    materialsList: {
      page: 'Materials-list',
      error: {
        page: 'Materials-list_error',
      },
      enableFilteringModal: {
        page: 'Materials-list-modal',
        confirmation: {
          page: 'Materials-list-modal',
        },
      },
    },
    productVariantShow: {
      page: 'Product-show-detail',
    },
    productVariantEdit: {
      page: 'Product_edit',
    },
    inspectionReport: {
      page: 'Inspection-Report-Templates-Index',
      edit: 'Edit-inspection-Report-Template',
    },
  },
  projectList: {
    page: 'Projects-list',
  },
  project: {
    scope: {
      page: 'project-scope',
      loaderAnimationShowed: 'project-scope-loader-animation-showed',
      templateSelectModal: {
        page: 'project-scope-template-select-modal-list',
        selection: 'project-scope-template-select-modal-list-selection',
        close: 'project-scope-template-select-modal-list-close',
        cancel: 'project-scope-template-select-modal-list-cancel',
        confirm: 'project-scope-template-select-modal-list-confirm',
      },
      tipsButtonPressed: `project-scope-tips-button-pressed`,
      tipsModal: {
        page: 'project-scope-tips-modal',
        downloadPdfPressed: 'project-scope-tips-modal-download-pdf-pressed',
        downloadPdfSuccess: 'project-scope-tips-modal-download-pdf-success', // TODO when feature is in place
        downloadPdfFailure: 'project-scope-tips-modal-download-pdf-failure', // TODO when feature is in place
        learnMorePressed: 'project-scope-tips-modal-learn-more-pressed',
        getStartedPressed: 'project-scope-tips-modal-get-started-pressed',
        close: 'project-scope-tips-modal-close',
      },
      customizeMaterialListPressed: `project-scope-customize-material-list-pressed`,
      customizePopoverPressed: `project-scope-customize-popover-pressed`,
      helpButtonPressed: `project-scope-help-button-pressed`,
      helpModal: {
        page: 'project-scope-help-modal',
        helpCenterPressed: 'project-scope-help-modal-help-center-pressed',
        close: 'project-scope-help-modal-close',
      },
      financialsPanel: {
        learnMorePressed: 'project-scope-financials-panel-learn-more-pressed',
      },
      saveAsTemplatePressed: 'project-scope-save-as-template-pressed',
      orderMaterialsButtonPressed:
        'project-scope-order-materials-button-pressed',
      orderMaterialsModel: {
        page: 'project-scope-order-materials-modal',
        getStarted: 'project-scope-order-materials-modal-get-started-pressed',
        learnMore: 'project-scope-order-materials-modal-learn-more-pressed',
      },
      calculationDetailsHovered: 'project-scope-calculation-details-hovered',
    },
    orderDetails: {
      page: 'Order-details',
    },
    checkout: {
      page: 'Checkout',
    },
    orderConfirmation: {
      page: 'Order-confirmation',
    },
  },
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default eventNames;
export const { estimator, pmp, saveAndAccess } = eventNames;
