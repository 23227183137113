import { isNil } from 'lodash';

import { PageDirection } from 'src/components/blueprint/Pagination';
import { ParamsHash } from 'src/hooks/useSearchParams';

const PAGE_SIZE = 20;

interface FetchMoreParams {
  variables: {
    before?: string;
    after?: string;
    first?: number;
    last?: number;
  };
}

interface OnPaginateParams {
  direction: PageDirection;
  updateParams: (params: ParamsHash) => void;
  fetchMore: (params: FetchMoreParams) => void;
  startCursor?: string;
  endCursor?: string;
}

export const usePagination = () => {
  const onPaginate = ({
    direction,
    updateParams,
    fetchMore,
    startCursor,
    endCursor,
  }: OnPaginateParams) => {
    if (direction === PageDirection.Prev) {
      updateParams({
        before: startCursor,
        after: undefined,
      });
      fetchMore({
        variables: {
          before: startCursor,
          after: undefined,
          last: PAGE_SIZE,
          first: undefined,
        },
      });
    } else {
      updateParams({
        after: endCursor,
        before: undefined,
      });
      fetchMore({
        variables: {
          after: endCursor,
          before: undefined,
          first: PAGE_SIZE,
          last: undefined,
        },
      });
    }
  };

  const paginationQueryParams = ({
    after,
    before,
  }: {
    after: string | null;
    before: string | null;
  }) => {
    return {
      after: after || undefined,
      before: before || undefined,
      first:
        !isNil(after) || (isNil(before) && isNil(after))
          ? PAGE_SIZE
          : undefined,
      last: !isNil(before) ? PAGE_SIZE : undefined,
    };
  };
  return { onPaginate, paginationQueryParams };
};
