import { Box, Heading } from '@hover/blueprint';
import { useDispatch, useSelector } from 'react-redux';

import { updateOrderDetailsForm } from 'src/features/projectManagement/redux/actions';
import {
  getOrderDetailsForm,
  getDistributorCapabilities,
} from 'src/features/projectManagement/redux/selectors';
import { DistributionDeliveryMethodEnum } from 'src/features/projectManagement/types';
import { RootState } from 'src/types/reduxStore';

import { DeliveryDate } from './DeliveryDate';
import { DeliveryOnHold } from './DeliveryOnHold';
import { DeliveryTime } from './DeliveryTime';
import { DeliveryType } from './DeliveryType';

export interface UpdateOrderDetailsParam {
  valueAttribute?: string;
  value?: Date | string;
  errorAttribute?: string;
  isInvalid?: boolean;
}

export const DeliveryDetails = () => {
  const dispatch = useDispatch();

  const distributorCapabilities = useSelector((state: RootState) =>
    getDistributorCapabilities(state),
  );

  const {
    deliveryMethod: selectedDeliveryMethod,
    deliveryTime: selectedDeliveryTime,
    deliveryType: selectedDeliveryType,
  } = useSelector(getOrderDetailsForm)?.values ?? {};

  const updateOrderDetails = ({
    valueAttribute,
    value,
    errorAttribute,
    isInvalid,
  }: UpdateOrderDetailsParam) => {
    dispatch(
      updateOrderDetailsForm({
        errors: {
          [errorAttribute]: isInvalid,
        },
        values: {
          [valueAttribute]: value,
        },
      }),
    );
  };

  return (
    <Box flexDirection="column" data-test-id="delivery-details">
      <Heading color="primary900" size={500} marginBottom={300}>
        Delivery details
      </Heading>
      {selectedDeliveryMethod === DistributionDeliveryMethodEnum.ON_HOLD ? (
        <DeliveryOnHold />
      ) : (
        <>
          <DeliveryDate onChange={updateOrderDetails} />
          {!!distributorCapabilities &&
          distributorCapabilities.supportsDeliveryTime ? (
            <DeliveryTime
              selectedDeliveryTime={selectedDeliveryTime}
              deliveryTimes={distributorCapabilities.deliveryTimes}
              onChange={updateOrderDetails}
            />
          ) : null}
        </>
      )}
      {distributorCapabilities?.supportsDeliveryType ? (
        <DeliveryType
          value={selectedDeliveryType}
          deliveryTypes={distributorCapabilities.deliveryTypes}
          onChange={updateOrderDetails}
        />
      ) : null}
    </Box>
  );
};
