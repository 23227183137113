import { useState } from 'react';

import {
  Body,
  Box,
  Checkbox,
  CheckboxGroup,
  Field,
  Heading,
  Panel,
  TextInput,
} from '@hover/blueprint';
import { cloneDeep, remove, sortBy } from 'lodash';
import { FieldValues, Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { DropResult } from 'src/components';
import { CreateTemplateGroupModal } from 'src/features/settings/components/Templates/CreateTemplateGroupModal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { bySortOrderAndCreatedAtComparator } from 'src/utils/comparators';
import { sentenceCase } from 'src/utils/Formatters';
import { reorderList } from 'src/utils/reorderList';

import { TemplateGroups } from './TemplateGroups';
import type { TemplateType, DisplayedTemplateGroup } from './types';
import { UnassignedLineItems } from './UnassignedLineItems';

type TemplateEditFormProps = {
  template: TemplateType;
  setTemplate: React.Dispatch<React.SetStateAction<TemplateType>>;
};

export const TemplateEditForm: React.FC<TemplateEditFormProps> = ({
  template,
  setTemplate,
}) => {
  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const lineItemsCount = template.templateGroups
    .map((group) => group.lineItems)
    .flat().length;

  const namedTemplateGroups = template.templateGroups
    .filter((group) => !group.defaultGroup)
    .sort(bySortOrderAndCreatedAtComparator);

  const maxSortOrder = Math.max(
    ...namedTemplateGroups.map((group) => group.sortOrder),
    0,
  );

  const unassignedTemplateGroup = template.templateGroups.filter(
    (group) => group.defaultGroup,
  )[0];

  const [isTemplateGroupModalOpen, setIsTemplateGroupModalOpen] =
    useState<boolean>(false);

  const [currentTemplateGroup, setCurrentTemplateGroup] =
    useState<DisplayedTemplateGroup | null>(null);

  const openCreateTemplateGroupModal = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      item_type: 'Template group',
      button_text: 'Create template group',
      ...commonTrackingProps,
    });
    setIsTemplateGroupModalOpen(true);
  };

  const closeTemplateGroupModal = () => {
    setCurrentTemplateGroup(null);
    setIsTemplateGroupModalOpen(false);
  };

  const openEditTemplateGroupModal = (group: DisplayedTemplateGroup) => {
    setCurrentTemplateGroup(group);
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      backend_id_value: group.id ?? '',
      item_type: 'Template group',
      button_text: 'Edit group',
      ...commonTrackingProps,
    });

    setIsTemplateGroupModalOpen(true);
  };

  const onDragEnd = (dropResult: DropResult) => {
    const { destination, source } = dropResult;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newNamedGroups = sortBy(cloneDeep(namedTemplateGroups), 'sortOrder');
    const sourceGroup = newNamedGroups[source.index];

    const reorderedGroups = reorderList(
      newNamedGroups,
      source.index,
      destination.index,
    );

    // reshuffle sort orders after insertion
    for (let i = 0; i < reorderedGroups.length; i += 1) {
      reorderedGroups[i].sortOrder = i + 1;
    }

    setTemplate({
      ...template,
      templateGroups: [...reorderedGroups, unassignedTemplateGroup],
    });

    typewriter.elementDragged({
      name: `Template Group ${sourceGroup.id}`,
      old_position: source.index + 1,
      new_position: destination.index + 1,
      ...commonTrackingProps,
    });
  };

  const handleNameChange = () => {
    typewriter.textInput({
      input_value: getValues('name'),
      input_label: 'Template Name',
      backend_id_type: 'Template',
      backend_id_value: template.id.toString(),
      page_or_screen_name: EventNames.settings.template.page,
      ...commonTrackingProps,
    });

    setTemplate({
      ...template,
      name: getValues('name'),
    });
  };

  const handleActiveChange = (selected: boolean) => {
    typewriter.checkboxSelected({
      selection: selected.toString(),
      options: 'Active for organization',
      backend_id_type: 'Template',
      backend_id_value: template.id.toString(),
      page_or_screen_name: EventNames.settings.template.page,
      ...commonTrackingProps,
    });
    setTemplate({
      ...template,
      active: selected,
    });
  };

  const createTemplateGroup = (data: FieldValues) => {
    setTemplate({
      ...template,
      templateGroups: [
        ...template.templateGroups,
        {
          id: '',
          createdAt: null,
          defaultGroup: false,
          description: data.description,
          lineItems: [],
          name: data.name,
          sortOrder: maxSortOrder + 1,
        },
      ],
    });
    closeTemplateGroupModal();
  };

  const editTemplateGroup = (data: FieldValues) => {
    if (!currentTemplateGroup) return;

    const newCurrentTemplateGroup = {
      ...currentTemplateGroup,
      name: data.name,
      description: data.description,
    };
    const index = namedTemplateGroups.indexOf(currentTemplateGroup);
    if (index === -1) return;

    const newTemplateGroups = [...namedTemplateGroups];
    newTemplateGroups.splice(index, 1, newCurrentTemplateGroup);

    setTemplate({
      ...template,
      templateGroups: [...newTemplateGroups, unassignedTemplateGroup],
    });
    closeTemplateGroupModal();
  };

  const deleteTemplateGroup = (group: DisplayedTemplateGroup) => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      button_location: 'Delete-Group-Modal',
      button_text: 'Delete',
      backend_id_value: group.id ?? '',
      ...commonTrackingProps,
    });

    // when we delete a group, we need to reassign its lineItems
    // to the UnassignedTemplateGroup
    const clonedTemplate = cloneDeep(template);

    // grab the lineItemsToMove to the unassigned group
    const lineItemsToMove = group.lineItems;

    // remove the group
    remove(clonedTemplate.templateGroups, group);

    const newUnassignedTemplateGroup = cloneDeep(unassignedTemplateGroup);

    // reassign the deleted groups lineItems to the unassigned group
    newUnassignedTemplateGroup.lineItems = [
      ...(unassignedTemplateGroup.lineItems ?? []),
      ...(lineItemsToMove ?? []),
    ];

    remove(clonedTemplate.templateGroups, unassignedTemplateGroup);

    // update state with new template
    setTemplate({
      ...clonedTemplate,
      templateGroups: [
        ...clonedTemplate.templateGroups,
        newUnassignedTemplateGroup,
      ],
    });
  };

  return (
    <Box data-test-id="TemplateEditContent" flexDirection="column">
      <CreateTemplateGroupModal
        isOpen={isTemplateGroupModalOpen}
        onClose={closeTemplateGroupModal}
        onSave={currentTemplateGroup ? editTemplateGroup : createTemplateGroup}
        currentGroup={currentTemplateGroup}
      />
      <Panel backgroundColor="neutral.0" padding={500} boxShadow="distance500">
        <Field
          data-test-id="templateName"
          error={errors?.name?.message}
          label="Template Name"
          name="name"
        >
          <TextInput
            data-test-id="templateNameInput"
            {...register('name', {
              required: 'name is required',
            })}
            onBlur={handleNameChange}
          />
        </Field>
        <Field
          data-test-id="templateTrade"
          error={errors?.name?.message}
          label="Trade"
          name="trade"
        >
          <Box>{sentenceCase(template.tradeType)}</Box>
        </Field>
        <Controller
          name="active"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                id="active"
                data-test-id="templateActive"
                isChecked={value}
                onChange={(e) => {
                  onChange(e);
                  handleActiveChange(e.target.checked);
                }}
              >
                <Box flexDirection="row" alignItems="center">
                  <Body
                    size={500}
                    margin={0}
                    marginRight={200}
                    color="neutral700"
                  >
                    Active for organization
                  </Body>
                </Box>
              </Checkbox>
            );
          }}
        />
      </Panel>
      <Heading size={400} marginTop={400}>
        {`Line items (${lineItemsCount})`}
      </Heading>
      <Controller
        name="templateLineItems"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <CheckboxGroup
              onChange={(values) => {
                onChange(values.sort());
              }}
              value={value}
              defaultValue={[]}
            >
              <TemplateGroups
                groups={namedTemplateGroups}
                onCreate={openCreateTemplateGroupModal}
                onDelete={deleteTemplateGroup}
                onEdit={openEditTemplateGroupModal}
                onDragEnd={onDragEnd}
              />
              <UnassignedLineItems
                lineItems={unassignedTemplateGroup?.lineItems}
                group={unassignedTemplateGroup}
              />
            </CheckboxGroup>
          );
        }}
      />
    </Box>
  );
};
