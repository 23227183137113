import { useState } from 'react';

import { useEffectOnMount } from './useEffectOnMount';

export const useGetScreenSize = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const setWindowSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffectOnMount(() => {
    window.addEventListener('resize', setWindowSize);

    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
  });

  return { screenWidth, screenHeight };
};

export const useScreenUtils = () => {
  const { screenWidth, screenHeight } = useGetScreenSize();

  const isLandscape = screenWidth > screenHeight;
  const flexDirection: 'row' | 'column' = isLandscape ? 'row' : 'column';

  return {
    flexDirection,
    screenWidth,
    screenHeight,
  };
};
