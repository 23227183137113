import React, { useState } from 'react';

import {
  Tooltip,
  Input,
  Td,
  Menu,
  Link,
  Box,
  Icon,
  MenuGroup,
  MenuItem,
} from '@hover/blueprint';
import {
  Editable,
  EditablePreview,
  EditableInput,
} from '@hover/blueprint/chakra';
import { iChevronDown } from '@hover/icons';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { EditedStatusEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { useProjectScopeTracker } from 'src/features/project/components/ProjectScope/hooks/useProjectScopeTracker';
import { usePrevious } from 'src/hooks/usePrevious';
import {
  isEnabled,
  COMMERCE_TEMPLATE_PRODUCT_GROUPED_LINE_ITEMS,
  COMMERCE_INLINE_EDITING_REDESIGN,
} from 'src/lib/FeatureFlag';
import { getMaterialListFeature } from 'src/redux/selectors';

import { EditableProductName } from './Inputs/EditableProductName';
import { ProductNameInput } from './Inputs/ProductNameInput';

type Props = {
  isMaterial: boolean;
  isDisabled?: boolean;
  enableInlineEditingV2: boolean;
  listItem: ListItem;
  distributors?: Distributor[];
  orgId: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
  onSuggestedItemsUpdate: (listItemId: number) => void;
};

export const ListItemNameColumn = ({
  enableInlineEditingV2,
  isMaterial,
  isDisabled = false,
  listItem,
  distributors,
  orgId,
  jobId,
  onUpdate,
  onSuggestedItemsUpdate,
}: Props) => {
  /* Component state */

  const [name, setName] = useState(listItem.name);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const prevName = usePrevious(listItem.name);

  /* React hook form */

  const {
    setValue,
    register,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();

  const handleInputUpdate = () => {
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    onUpdate('Product Name');
  };

  const handleSuggestedItemsClick = (listItemId: number) => {
    onSuggestedItemsUpdate(listItemId);
  };

  /* Feature flags and access */

  const inlineEditRedesignEnabled = isEnabled(COMMERCE_INLINE_EDITING_REDESIGN);
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  /* Render */

  if (!enableInlineEditingV2) {
    return <Td>{listItem.name}</Td>;
  }

  if (isMaterial) {
    return (
      <Td
        paddingRight={{ base: 0, tablet: inlineEditRedesignEnabled ? 800 : 0 }}
        paddingY={{ tablet: inlineEditRedesignEnabled ? 500 : 0 }}
        paddingLeft="0 !important"
        verticalAlign={{
          base: '',
          tablet: inlineEditRedesignEnabled ? 'top' : '',
        }}
        display={{ base: 'flex', tablet: 'table-cell' }}
        flexDirection="column"
        flexBasis="100%"
      >
        {inlineEditRedesignEnabled ? (
          <ProductNameInput
            label={name}
            name={name}
            orgId={orgId}
            jobId={jobId}
            listItem={listItem}
            distributors={distributors}
            setName={setName}
            onUpdate={handleInputUpdate}
            setIsProductSelected={setIsProductSelected}
          />
        ) : (
          <Editable
            size="tiny"
            value={name}
            isDisabled={isDisabled}
            selectAllOnFocus
            onCancel={(prevValue) => {
              if (!isProductSelected) {
                setName(prevValue);
              }

              setIsProductSelected(false);
            }}
          >
            <Tooltip label="Click to edit" placement="top">
              <EditablePreview display="flex" height="auto" />
            </Tooltip>
            <EditableInput
              as={EditableProductName}
              label={name}
              name={name}
              orgId={orgId}
              jobId={jobId}
              listItem={listItem}
              distributors={distributors}
              setName={setName}
              onUpdate={handleInputUpdate}
              setIsProductSelected={setIsProductSelected}
            />
          </Editable>
        )}

        {isEnabled(COMMERCE_TEMPLATE_PRODUCT_GROUPED_LINE_ITEMS) &&
          listItem.templateListItemGroupListItems.length > 0 && (
            <Menu
              trigger={
                <Link
                  as="button"
                  data-test-id="grouped-list-items-dropdown-button"
                >
                  <Box display="flex" flexDir="row" alignItems="center">
                    Suggested items <Icon icon={iChevronDown} />
                  </Box>
                </Link>
              }
            >
              <MenuGroup title="Suggestions">
                {listItem.templateListItemGroupListItems.map(
                  (groupListItem) => (
                    <MenuItem
                      onClick={() =>
                        handleSuggestedItemsClick(groupListItem.id)
                      }
                    >
                      {groupListItem.name}
                    </MenuItem>
                  ),
                )}
              </MenuGroup>
            </Menu>
          )}

        {materialListFeatureEnabled &&
          listItem.editedStatus === EditedStatusEnum.EDITED && (
            <Box color="neutral.500" fontSize={100} marginTop={100}>
              Item manually edited
            </Box>
          )}
      </Td>
    );
  }

  return (
    <Td>
      {inlineEditRedesignEnabled ? (
        <Input
          size="tiny"
          {...register('name', {
            required: 'Item name is required',
          })}
          disabled={isDisabled}
          defaultValue={listItem.name}
          isInvalid={!!get(formErrors, 'name')}
          onChange={() => {
            trackInlineEditingInputPressed('Name');
          }}
          onBlur={async (event) => {
            // Set the new value based on the blur event, then trigger validation and wait for completion.
            setValue('name', event.target.value);
            await trigger('name');

            // If new value has no errors, update the value. Else, revert it.
            if (!get(formErrors, 'name')) {
              onUpdate('Name');
            } else {
              setValue('name', prevName);
            }
          }}
        />
      ) : (
        <Editable
          defaultValue={listItem.name}
          selectAllOnFocus
          isDisabled={isDisabled}
          submitOnBlur={!get(formErrors, 'name.message')} // Prevents submission of invalid value, when validation error.
          onSubmit={() => {
            onUpdate('Name');
          }}
          onCancel={(prev) => {
            // If input is canceled on blur due to validation error, then reset the previous value.
            setValue('name', prev, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          size="tiny"
        >
          <Tooltip label="Click to edit" placement="top">
            <EditablePreview display="flex" height="auto" />
          </Tooltip>
          <Tooltip
            label={get(formErrors, 'name.message')}
            placement="top"
            background="danger300"
          >
            <Input
              as={EditableInput}
              size="tiny"
              {...register('name', {
                required: 'Item name is required',
              })}
              isInvalid={!!get(formErrors, 'name')}
              onChange={() => {
                trackInlineEditingInputPressed('Name');
              }}
            />
          </Tooltip>
        </Editable>
      )}
    </Td>
  );
};
