export const ESTIMATOR_QUESTIONS_URL = '/estimator/questions';
export const ESTIMATES_URL = '/estimator/estimates';
export const ESTIMATES_FAILURE = '/estimator/estimate_load_failure';
export const ESTIMATOR = '/estimator';
export const PROPOSAL_TOOL = '/estimate_proposal';
export const ESTIMATOR_PRODUCTION = '/estimator_production';
export const SOD = '/estimates';
export const CHECKOUT_PATH = '/estimator_production/order/checkout';
export const SUMMARY_PATH = '/estimator_production/order/summary';

// artemis endpoints
export const ARTEMIS_PROD = 'https://ehi.hover.to';
export const ARTEMIS_STAGING = 'https://staging-ehi.hover.to';
