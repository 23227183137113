import { useDispatch } from 'react-redux';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { getProposals } from 'src/features/exteriorEstimator/utils/gqlUtils';

export function useProposals() {
  const dispatch = useDispatch();

  const fetchProposals = async (estimateGroupId: string) => {
    try {
      dispatch(estimatorActions.getProposals.request({ estimateGroupId }));
      const theseProposals = await getProposals(estimateGroupId);
      dispatch(estimatorActions.getProposals.success(theseProposals));
    } catch (proposalsError) {
      dispatch(estimatorActions.setError(true));
      dispatch(estimatorActions.getProposals.failure(proposalsError as Error));
    }
  };

  return [fetchProposals] as const;
}
