import { ACLTemplateOptions } from 'src/api/graphql-global-types';
import { profile_profile_user_aclAccesses as AclAccesses } from 'src/api/types/profile';

interface IsAclAccessForOrgArguments {
  acl: AclAccesses[];
  orgId: number;
  desiredAclTemplates: ACLTemplateOptions[];
}

export const hasAclAccessesForOrg = ({
  acl,
  orgId,
  desiredAclTemplates,
}: IsAclAccessForOrgArguments) => {
  const aclForOrg = acl.find((access) => access.orgId === orgId);
  return aclForOrg && aclForOrg.aclTemplate
    ? desiredAclTemplates.includes(aclForOrg.aclTemplate)
    : false;
};
