import { useEffect, useState } from 'react';

import { breakerClient } from '@hover/breaker-react';
import * as Sentry from '@sentry/react';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import {
  ORG_SETTINGS_QUERY,
  GET_TRADES,
  GET_PRODUCT_CATALOG_ORG_SETTINGS,
  GET_PROJECT_MANAGEMENT_CONFIG_ORG_PROPOSAL_SETTINGS,
  PROFILE,
} from 'src/api/queries/queries';
import appSettings from 'src/appSettings';
import { ImpersonatorBanner } from 'src/components/ImpersonatorBanner';
import { IntegrationConnectionErrorModal } from 'src/components/IntegrationConnectionErrorModal';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { useEffectOnMount, useQueryEhi, useLazyQueryEhi } from 'src/hooks';
import {
  GQLError,
  subscribeToGQLIntegrationConnectionErrors,
  IntegrationConnectionError,
} from 'src/lib/GraphqlClient';
import { ROUTES, matchRoute } from 'src/lib/routeConfig';
import { initializeSentry } from 'src/lib/sentry';
import * as hoverActions from 'src/redux/actions';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import {
  getUserOrgId,
  getAuthStatus,
  getSplitInitStatus,
  getUserProfile,
} from 'src/redux/selectors';
import { AUTHENTICATION_STATES } from 'src/types';
import { isTest } from 'src/utils/EnvUtils';
import SplitConfig from 'src/utils/SplitConfig';

export const App: React.FC = () => {
  const authStatus = useSelector(getAuthStatus);
  const currentUser = useSelector(getUserProfile);
  const isSplitInitialized = useSelector(getSplitInitStatus);
  const orgId = useSelector(getUserOrgId);
  const dispatch = useDispatch();

  // Provide the errors state object to the OAuthErrors dialog.
  const [integrationConnectionErrors, setIntegrationConnectionErrors] =
    useState<ReadonlyArray<GQLError<IntegrationConnectionError>> | undefined>();

  // When OAuthErrors dialog is closed/canceled, clear the state.
  const clearIntegrationConnectionErrors = () =>
    setIntegrationConnectionErrors(undefined);

  // Listen for route changes, to evaluate whether to show Intercom messenger at the current route.
  const location = useLocation();
  const { update } = useIntercom();

  const { data: profileData, error: profileError } = useQueryEhi(PROFILE, {
    fetchPolicy: 'no-cache',
  });

  const [getOrgSettings, { data: orgSettingsData }] = useLazyQueryEhi(
    ORG_SETTINGS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  );

  const [
    getProductCatalogOrgSettings,
    { data: productCatalogOrgSettingsData },
  ] = useLazyQueryEhi(GET_PRODUCT_CATALOG_ORG_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [getOrgProposalSettings, { data: orgProposalSettingsData }] =
    useLazyQueryEhi(GET_PROJECT_MANAGEMENT_CONFIG_ORG_PROPOSAL_SETTINGS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  const [getTrades, { data: tradesData }] = useLazyQueryEhi(GET_TRADES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const initUserServices = async () => {
    if (!currentUser) return;
    // init Sentry
    initializeSentry({
      user: {
        id: String(currentUser.id),
        email: currentUser.email,
        orgId: currentUser?.orgs[0]?.id,
        ...(currentUser.name && { username: currentUser.name }),
      },
    });

    try {
      // init Split with org, partner and email
      const split = await SplitConfig.init({
        orgId: currentUser?.orgs[0]?.id,
        defaultAttributes: {
          partnerId: currentUser?.orgs[0]?.partner?.id?.toString(),
        },
        userId: String(currentUser.id),
      });
      dispatch(hoverActions.setSplitStatus(true));

      const splitsAsBreakerProject = split.convertSplitsToBreakerProject();
      breakerClient.setProject(splitsAsBreakerProject);
    } catch (e) {
      Sentry.captureException(e);
    }

    // init Segment using user's id
    if (!isTest()) {
      window.analytics?.identify(currentUser.id, {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
      });
    }
  };

  useEffect(() => {
    if (!!window.Intercom.booted && !!currentUser) {
      const path = location.pathname;
      // Find the matching routeConfig for the current location path.
      const routeConfigMatch = matchRoute(path);
      // Check if the Intercom launcher is enabled or disabled on this route.
      const showMessenger =
        routeConfigMatch?.state?.showIntercomMessenger || false;
      // Update the Intercom SDK to show/hide the launcher based on the routeConfig state value.
      update({
        hideDefaultLauncher: !showMessenger,
        userHash: currentUser.intercomSha,
        lastRequestAt: new Date().getTime().toString(),
      });
    }
  }, [location, currentUser, update]);

  useEffectOnMount(() => {
    // eslint-disable-next-line no-console
    console.log('VERSION: ', appSettings.GIT_SHA);
    dispatch(hoverActions.setAuthStatus(AUTHENTICATION_STATES.AUTHENTICATING));
  });

  useEffect(() => {
    if (!profileData) {
      return;
    }
    // if (profileData.profile?.user?.id) {
    //   (window as any).braze.changeUser(profileData.profile.user.id);
    // }
    dispatch(hoverActions.setAuthStatus(AUTHENTICATION_STATES.AUTHENTICATED));
    dispatch(hoverActions.getUserProfile.success(profileData));
  }, [profileData, dispatch]);

  useEffect(() => {
    if (currentUser) {
      initUserServices();
      getOrgSettings();
      getProductCatalogOrgSettings({ variables: { orgId } });
      getOrgProposalSettings({ variables: { orgId } });
      getTrades();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (
      orgSettingsData &&
      productCatalogOrgSettingsData &&
      orgProposalSettingsData
    ) {
      const orgSettings = {
        ...orgSettingsData.ehiOrgSettings,
        ...productCatalogOrgSettingsData.productCatalogOrgSettings,
        ...orgProposalSettingsData.projectManagementConfigOrgProposalSetting,
      };
      dispatch(
        hoverActions.getOrgSettings.success({
          orgSettings: omit(orgSettings, ['__typename']) as EhiOrgSettings,
        }),
      );
    }
  }, [
    orgSettingsData,
    productCatalogOrgSettingsData,
    orgProposalSettingsData,
    dispatch,
  ]);

  useEffect(() => {
    if (tradesData) {
      dispatch(hoverActions.setTradeTypes(tradesData.tradeTypes));
    }
  }, [tradesData, dispatch]);

  useEffect(() => {
    if (profileError) {
      dispatch(hoverActions.setAuthStatus(AUTHENTICATION_STATES.FAILED));
    }
  }, [profileError, dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // On application launch, subscribe to integration connection errors
    // in order to display reconnection modal.
    const integrationConnectionErrorSubscriber =
      subscribeToGQLIntegrationConnectionErrors(
        (gqlIntegrationConnectionErrors) => {
          setIntegrationConnectionErrors(gqlIntegrationConnectionErrors);
        },
      );

    // Remove global loader fixed at index.html
    document.querySelector('.global-loader-wrapper')?.remove();

    // Unsubscribe when leaving application.
    return () => {
      integrationConnectionErrorSubscriber.unsubscribe();
    };
  }, []);

  return authStatus === AUTHENTICATION_STATES.AUTHENTICATED &&
    isSplitInitialized ? (
    <div>
      <ImpersonatorBanner />
      <Switch>
        {ROUTES.map((route) => (
          <Route
            path={route.path}
            render={(props) => (
              // pass the sub-routes down to keep nesting
              <route.component {...props} routes={route.routes} />
            )}
            key={route.path}
          />
        ))}
      </Switch>
      <IntegrationConnectionErrorModal
        errors={integrationConnectionErrors}
        onCancel={clearIntegrationConnectionErrors}
      />
    </div>
  ) : (
    <LoaderSpinner show />
  );
};
