import { Box, Icon, Body, Heading, Link, Container } from '@hover/blueprint';
import { iChevronRight, iPlus } from '@hover/icons';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  estimationEstimateGroupsForJob,
  estimationEstimateGroupsForJob_estimationEstimateGroups_nodes as EstimateGroup,
} from 'src/api/types/estimationEstimateGroupsForJob';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { ESTIMATOR } from 'src/constants/urls';
import { GET_ESTIMATE_GROUPS_FOR_JOB } from 'src/features/exteriorEstimator/apis/queries';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { getParams } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  estimateGroupsHistoryComparator,
  isEstimateGroupSold,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { getEstimateGroupDetailsUrl } from 'src/features/exteriorEstimator/utils/miscUtils';
import { useQueryEhi } from 'src/hooks/useQueryEhi';
import { isUserLightWeightFlow } from 'src/redux/selectors';
import { formatTradesStringForEstimates } from 'src/utils/Formatters';

export const History: React.FC = () => {
  const { jobId } = useSelector(getParams);
  const isLightWeightFlow = useSelector(isUserLightWeightFlow);

  const { data, loading, fetchMore } =
    useQueryEhi<estimationEstimateGroupsForJob>(GET_ESTIMATE_GROUPS_FOR_JOB, {
      variables: {
        jobId,
        active: true,
      },
    });

  if (loading || !data) return <LoaderSpinner show />;

  const { hasNextPage, endCursor } = data.estimationEstimateGroups.pageInfo;

  const estimateGroups = data.estimationEstimateGroups.edges?.map(
    (e) => e?.node,
  );

  if (hasNextPage) {
    fetchMore({
      variables: {
        jobId,
        active: true,
        endCursor,
      },
    });
  }

  const renderNav = () => {
    return (
      <Container
        w={1}
        maxW="auto"
        padding="0"
        shadow="distance300"
        border="disabled"
      >
        <EstimatorResponsiveWrapper
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Container w={1} maxW="auto" padding="0">
            <Heading size={200} color="neutral.600">
              {`Generated estimates (${estimateGroups?.length ?? 0})`}
            </Heading>
          </Container>
          <Link
            as={RouterLink}
            to={`${ESTIMATOR}/questions/select_templates?jobId=${jobId}`}
          >
            <Icon icon={iPlus} size="medium" color="primary500" />
          </Link>
        </EstimatorResponsiveWrapper>
      </Container>
    );
  };

  const renderEstimateGroup = (estimateGroup: EstimateGroup) => {
    const { estimates } = estimateGroup;
    const estimateGroupTotal = estimateGroup.totalPrice;

    return (
      <Container
        width={1}
        maxW="auto"
        padding="0"
        borderBottom="disabled"
        borderColor="neutral.200"
        m={2}
        key={estimateGroup.id}
        data-test-id={`estimateGroup-${estimateGroup.id}`}
      >
        <Link
          as={RouterLink}
          data-test-id={`estimateGroup-${estimateGroup.id}-details`}
          to={`${getEstimateGroupDetailsUrl({
            jobId: Number(jobId),
            estimateGroupId: estimateGroup.id,
          })}`}
          _hover={{ textDecoration: 'none' }}
          width={1}
        >
          <Box flexDirection="row" width={1}>
            <Box width={1} flexDirection="column" paddingBottom={400}>
              {!isLightWeightFlow && (
                <Box
                  data-test-id={`estimateGroup-${estimateGroup.id}-total`}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Heading size={300} marginY={0}>
                    {estimateGroup.name ?? 'Estimate'}
                  </Heading>
                  <Heading size={300} marginY={0} isDisplay>
                    <FormattedNumber
                      value={estimateGroupTotal ?? 0}
                      format="$0,0[.]00"
                    />
                  </Heading>
                </Box>
              )}
              <Box data-test-id={`estimateGroup-${estimateGroup.id}-trades`}>
                <Body size={400} mt={50} mb={50}>
                  {formatTradesStringForEstimates(estimates)}
                </Body>
              </Box>
              <Box>
                <Body size={400} color="neutral500" mt={50} mb={50}>
                  {moment(estimateGroup.createdAt).format('MM/DD/YYYY')}
                </Body>
              </Box>
            </Box>
            {isEstimateGroupSold(estimateGroup) && (
              <Box
                alignItems="center"
                data-test-id={`estimateGroup-${estimateGroup.id}-sold`}
              >
                <Body size={400} color="success500" mt={0} mb={0}>
                  Sold
                </Body>
              </Box>
            )}
            <Box alignItems="center">
              <Icon icon={iChevronRight} size="medium" color="neutral300" />
            </Box>
          </Box>
        </Link>
      </Container>
    );
  };

  const renderEstimateGroups = (
    estGroups: (EstimateGroup | null | undefined)[],
  ) => {
    // remove undefined or null values
    const filteredEstimateGroups = estGroups.filter((e) => !isNil(e));

    const sortedEstimateGroups = filteredEstimateGroups.sort(
      estimateGroupsHistoryComparator,
    );

    return sortedEstimateGroups.map((estimateGroup) =>
      renderEstimateGroup(estimateGroup as EstimateGroup),
    );
  };

  return (
    <Container
      data-test-id="estimatesHistory"
      width={1}
      maxW="auto"
      padding="0"
    >
      {renderNav()}
      <Container width={1} maxW="auto" padding="0">
        <EstimatorResponsiveWrapper alignItems="center">
          {!isEmpty(estimateGroups) &&
            renderEstimateGroups(estimateGroups as EstimateGroup[])}
        </EstimatorResponsiveWrapper>
      </Container>
    </Container>
  );
};
