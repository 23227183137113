import styled from 'styled-components';

import { Roof } from 'src/components/RoofOutline';

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto 40px auto;
  padding-top: 20px;
`;

export const JobDetailsContainer = styled.div`
  padding: 0 15px;
  ${Roof} {
    height: 300px;
    width: 300px;
    margin-left: 0;
  }
`;

export const Text = styled.div`
  ${({ theme }) => theme.mixins.font('light', 13)}
`;

export const PropertyName = styled.h2`
  margin: 0 0 20px 0;
  ${({ theme }) => theme.mixins.font('medium', 20)}
`;

export const RoofOutlineContainer = styled.div`
  margin: 15px 0px;
`;

export const CreatedLine = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${({ theme }) => theme.mixins.font('light', 13)}
`;

export const CreatedRight = styled.div`
  margin-left: auto;
`;

export const MeasurementDetailsContainer = styled.div`
  padding: 0 15px;
`;

export const MeasurementDetailsHeader = styled.h2`
  padding: 0 0 10px 16px;
  ${({ theme }) => theme.mixins.font('medium', 20)}
`;

export const Table = styled.table`
  background-color: ${({ theme }) => theme.colors.neutral000};
  & th,
  & td {
    background-position: right top;
    background-repeat: repeat-y;
    background-size: 1px 18px;
    padding: 0.5rem 1rem;
  }
  & sup {
    /* https://css-tricks.com/snippets/css/prevent-superscripts-and-subscripts-from-affecting-line-height/ */
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
`;

export const TableHeader = styled.th`
  text-align: center;
  background-image: none !important;
  ${({ theme }) => theme.mixins.font('bold', 13)}

  &:first-child {
    text-align: left;
  }
`;

export const Row = styled.tr`
  background-color: transparent !important; /* foundation */

  &:last-child {
    td {
      background-image: none !important;
      border-bottom: none;
    }
  }
  & td {
    height: 42px;
  }
`;

export const TableData = styled.td`
  padding: 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrayFour};
  text-align: center;
  font-size: 13px;

  &:first-child {
    text-align: left;
  }
`;
