import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  EstimationEstimateGroupStateEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { projectEstimationEstimateGroupCreate_estimationEstimateGroupCreate as EstimateGroupCreate } from 'src/api/types/projectEstimationEstimateGroupCreate';
import { messages } from 'src/constants/messages';
import { useEstimate } from 'src/features/exteriorEstimator/hooks';
import {
  getQuestionResponses,
  getCleanCustomLineItems,
  getSelectedTemplateIds,
  getSelectedTrades,
  getTemplates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getAllSelectedFacetParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/roofQuestionsSelectors';
import { estimateGroupCreateParams } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import {
  CREATE_ESTIMATE_GROUP,
  GET_ESTIMATE_GROUP,
} from 'src/features/project/apis/graphql/queries/queries';
import {
  useLazyQueryEhi,
  useToastEhi,
  ToastStatusEnum,
  usePrevious,
} from 'src/hooks';
import {
  getOrgIdParam,
  getOrgSettings,
  getTradeTypesSorted,
} from 'src/redux/selectors';

const enum TOAST_IDS {
  GET_PRODUCTION_LIST_ERROR_TOAST,
  GET_TEMPLATES_ERROR_TOAST,
  CREATE_ESTIMATE_GROUP_ERROR_TOAST,
  CREATE_ORG_SETUP_TOAST,
  GET_AND_POLL_ORG_SETUP_ERROR_TOAST,
  GET_AND_POLL_ESTIMATE_GROUP_ERROR_TOAST,
}

export function useMaterialListFeature() {
  const jobId = Number(get(useParams(), 'jobId'));
  const orgId = useSelector(getOrgIdParam);
  const toast = useToastEhi();
  const { setupEstimator, isFetchingQuestions } = useEstimate();
  const orgSettings = useSelector(getOrgSettings);
  const questionResponses = useSelector(getQuestionResponses);
  const customLineItems = useSelector(getCleanCustomLineItems);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const selectedTrades = useSelector(getSelectedTrades) as TradeTypeEnum[];
  const facetsAttributes = useSelector(getAllSelectedFacetParams);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const allTemplates = useSelector(getTemplates);
  const prevIsFetchingQuestions: boolean | undefined =
    usePrevious(isFetchingQuestions);

  const [isRecreatingMaterialList, setIsRecreatingMaterialList] = useState<
    boolean | undefined
  >(undefined);

  const [createEstimateGroup] = useMutation(CREATE_ESTIMATE_GROUP, {
    onCompleted: ({
      estimationEstimateGroupCreate,
    }: {
      estimationEstimateGroupCreate: EstimateGroupCreate;
    }) => {
      const id = estimationEstimateGroupCreate?.estimateGroup?.id;
      fetchAndPollEstimateGroup({
        variables: {
          id,
          orgId,
        },
        pollInterval: 1500,
      });
    },
    onError: () => {
      toast({
        id: TOAST_IDS.CREATE_ESTIMATE_GROUP_ERROR_TOAST,
        description:
          messages.projectScope.errors.mutation.productionList.estimateGroup,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const [
    fetchAndPollEstimateGroup,
    {
      data: getEstimateGroupData,
      error: getEstimateGroupError,
      stopPolling: stopPollingEstimateGroup,
    },
  ] = useLazyQueryEhi(GET_ESTIMATE_GROUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: () => {
      toast({
        id: TOAST_IDS.GET_AND_POLL_ESTIMATE_GROUP_ERROR_TOAST,
        description:
          messages.projectScope.errors.query.productionList.estimateGroup,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  /*
  When setupEstimator gets invoked the isFetchingQuestions state updates to true
  and when it finalizes all the templates inputs + measurements in the redux store 
  the isFetchingQuestions states updates to false. So we listen to that change, and when 
  it is finished updating we send a request to create a estimate group
  */
  useEffect(() => {
    const isDoneFetching =
      prevIsFetchingQuestions === true && isFetchingQuestions === false;

    if (isDoneFetching) {
      const vars = estimateGroupCreateParams({
        jobId,
        orgId,
        selectedTemplates: selectedTemplateIds || [],
        customLineItems,
        questionResponses,
        tradeTypes,
        selectedTrades,
        facetsAttributes,
        showOrderingVersion: true, // mark as sold and activate
      });
      createEstimateGroup({ variables: vars });
    }
  }, [isFetchingQuestions, prevIsFetchingQuestions]);

  /*
  When an estimate is created from the setup Estimators isFetchingQuestions-useEffect,  
  wait till the estimate state is complete, to stop polling and fetch production list
  */
  useEffect(() => {
    const { state } = getEstimateGroupData?.estimationEstimateGroup ?? {};
    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      stopPollingEstimateGroup();
      if (isRecreatingMaterialList) {
        setIsRecreatingMaterialList(false); // this is the end of the happy path recreating ml
      }
    }
    if (
      getEstimateGroupError ||
      state === EstimationEstimateGroupStateEnum.FAILED
    ) {
      materialListErrored(getEstimateGroupError);
      stopPollingEstimateGroup();
    }
  }, [getEstimateGroupData, getEstimateGroupError, stopPollingEstimateGroup]);

  /*
  if an error occurs, stop the process of setting up material list, log the error and notify of error state
  */
  const materialListErrored = (err: any) => {
    console.error(err);
    Sentry.captureException(err);
  };

  const recreateEstimateGroupWithTemplates = () => {
    const templates = allTemplates || [];
    const selected = selectedTemplateIds || [];
    setIsRecreatingMaterialList(true);
    setupEstimator({
      currentlySelectedTemplates: selected,
      templates,
      setIsInitializing: () => true,
      showOrderingVersion: false,
      orgSettings,
    });
  };

  return {
    recreateEstimateGroupWithTemplates,
    isRecreatingMaterialList,
  };
}
