import { Box, Heading } from '@hover/blueprint';

interface Props {
  name: string;
}

export const ProductHeader: React.FC<Props> = ({ name }) => {
  return (
    <Box my={400}>
      <Heading size={650}>{name}</Heading>
    </Box>
  );
};
