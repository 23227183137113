import { useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { productCatalogDistributor_productCatalogDistributor_capabilities_deliveryTypes as DeliveryTypes } from 'src/features/projectManagement/apis/graphql/queries/types/productCatalogDistributor';

import { RadioGroup, RadioButton } from 'src/components/Radio';
import {
  deliveryTypeValueField,
  deliveryTypeErrorField,
} from 'src/features/projectManagement/constants';
import { DistributionDeliveryTypeEnum } from 'src/features/projectManagement/types';

import { UpdateOrderDetailsParam } from './DeliveryDetails';

export interface Props {
  value?: DistributionDeliveryTypeEnum | null;
  deliveryTypes: DeliveryTypes[];
  onChange: (params: UpdateOrderDetailsParam) => void;
}

export const DeliveryType: React.FC<Props> = ({
  value,
  deliveryTypes,
  onChange,
}) => {
  useEffect(() => {
    onChange({
      errorAttribute: deliveryTypeErrorField,
      isInvalid: !value,
    });
    // we want to only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (value: DistributionDeliveryTypeEnum) => {
    onChange({
      valueAttribute: deliveryTypeValueField,
      value,
      errorAttribute: deliveryTypeErrorField,
      isInvalid: !value,
    });
  };

  const sortedDeliveryTypes = [...deliveryTypes].sort(
    (a, b) => a.sortOrder - b.sortOrder,
  );

  return (
    <RadioGroup
      heading="Delivery type"
      marginBottom={600}
      onChange={handleChange}
      selectedValue={value as string} // RadioGroup's selectedValue does not expect an enum type
    >
      {sortedDeliveryTypes.map(({ id, label, deliveryType }) => (
        <RadioButton
          label={label}
          name="deliveryType"
          value={deliveryType}
          key={id}
        />
      ))}
    </RadioGroup>
  );
};
