import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Heading, Button, Body, Link, Tag } from '@hover/blueprint';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  productCatalogProduct as ProductCatalogProductQueryType,
  productCatalogProduct_productCatalogProduct as Product,
} from 'src/api/types/productCatalogProduct';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from 'src/components/blueprint/Breadcrumb';
import { GET_PRODUCT_CATALOG_PRODUCT } from 'src/features/settings/api/queries/products';
import { ProductHeader } from 'src/features/settings/components/common/ProductHeader';
import { useTracking } from 'src/hooks';
import {
  getUserTrackingProps,
  getOrgSettings,
  getUserOrgVariationsFilter,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

const Variations = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral000};
`;

const DisabledCount = styled(Box)`
  color: ${({ theme }) => theme.colors.neutral400};
`;

const EditButtons = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
`;

interface Props {
  product: Product;
  productFilteringEnabled: boolean;
}
export const ProductShowContent: React.FC<Props> = ({
  product,
  productFilteringEnabled,
}) => {
  const history = useHistory();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const editVariantText = 'Edit Variants';

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.productVariantShow.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  const navigateToEdit = () => {
    history.push(`/workflows/materials/products/${product.id}/edit`);

    typewriter.buttonPressed({
      button_text: editVariantText,
      page_or_screen_name: EventNames.settings.productVariantShow.page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  const formattedVariationsLength = numeral(product.variations.length).format(
    '0,0',
  );

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            trackEventName={EventNames.settings.materialsList.page}
            label="Materials"
            to="/workflows/materials"
          />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink isCurrentPage label={product.name} />
        </BreadcrumbItem>
      </Breadcrumb>
      <Box flexDirection="column" width={1}>
        <ProductHeader name={product.name} />

        <Box flexDirection="column" data-test-id="ProductShow">
          <Box flexDirection="row" alignItems="center" marginBottom={200}>
            <Heading marginBottom={0} size={400}>
              Enabled Variants:
            </Heading>
            <Tag
              data-test-id="backgroundCircle"
              marginLeft="200"
              borderRadius="full"
              backgroundColor="neutral.200"
            >
              {formattedVariationsLength}
            </Tag>
          </Box>
          <Variations flexDirection="column" width="75%">
            <Box
              paddingY={200}
              maxHeight={
                productFilteringEnabled // Additional offset required when "Enable filtering" notification is visible.
                  ? 'calc(100vh - 354px)'
                  : 'calc((100vh - 354px) - 78px)'
              }
              overflowY="auto"
              flexDirection="column"
            >
              {product.variations.map((variation) => {
                return (
                  <Box
                    key={variation.id}
                    data-test-id={`Variant-${variation.id}`}
                    marginX={500}
                  >
                    <Body size={400} mt={200} mb={200}>
                      {variation.name}
                    </Body>
                  </Box>
                );
              })}
            </Box>
            {!productFilteringEnabled && (
              <Box flexDirection="column" marginX={500} marginBottom={400}>
                <Body
                  size={400}
                  mb={0}
                  data-test-id="turnOnFiltering"
                  color="neutral600"
                >
                  <strong>
                    Do you want to limit variants your team can select and sell
                    for this material?
                  </strong>
                </Body>
                <Body size={400} mt={0} mb={100}>
                  <Link as={RouterLink} to="/workflows/materials">
                    Turn on variants filtering
                  </Link>
                </Body>
              </Box>
            )}
            <EditButtons
              flexDirection="row"
              justifyContent="space-between"
              padding={300}
              alignItems="center"
            >
              <Button
                data-test-id="navigateToEdit"
                fill="outline"
                onClick={navigateToEdit}
              >
                {editVariantText}
              </Button>
              <DisabledCount data-test-id="disabledVariantsCount">
                {product.variationsCount - (product?.orgVariationsCount ?? 0)}{' '}
                variant(s) disabled for your organization
              </DisabledCount>
            </EditButtons>
          </Variations>
        </Box>
      </Box>
    </>
  );
};

export const ProductShow: React.FC = () => {
  const { productId } = useParams();
  const orgSettings = useSelector(getOrgSettings);

  let variationsFilter = useSelector(getUserOrgVariationsFilter);
  variationsFilter = { ...variationsFilter, filterVariationsByOrg: true };

  const { data: productData } = useQuery<ProductCatalogProductQueryType>(
    GET_PRODUCT_CATALOG_PRODUCT,
    {
      variables: { id: productId, ...variationsFilter },
      fetchPolicy: 'no-cache',
    },
  );

  if (!productData) return null;

  const { productCatalogProduct: product } = productData;
  if (!product) return null;
  const productFilteringEnabled = orgSettings?.productFilteringEnabled;

  return (
    <ProductShowContent
      product={product}
      productFilteringEnabled={!!productFilteringEnabled} // Coercing from type productFilteringEnabled: boolean | undefined
    />
  );
};
