import { createAsyncAction } from 'typesafe-actions';

import { projectManagementProposalDocument_projectManagementProposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocument';
import { projectManagementSignatureRequestCreate_projectManagementSignatureRequestCreate_signatureRequest as SignatureRequest } from 'src/api/types/projectManagementSignatureRequestCreate';
import {
  projectManagementSignatureRequestEmbeddedUrl_projectManagementSignatureRequestEmbeddedUrl as EmbeddedUrl,
  projectManagementSignatureRequestEmbeddedUrlVariables,
} from 'src/api/types/projectManagementSignatureRequestEmbeddedUrl';

export const emailESign = createAsyncAction(
  'EXTERIOR_ESTIMATOR/EMAIL_E_SIGN_REQUEST',
  'EXTERIOR_ESTIMATOR/EMAIL_E_SIGN_SUCCESS',
  'EXTERIOR_ESTIMATOR/EMAIL_E_SIGN_FAILURE',
)<Proposal, SignatureRequest, Error>();

export const embeddedESign = createAsyncAction(
  'EXTERIOR_ESTIMATOR/EMBEDDED_E_SIGN_REQUEST',
  'EXTERIOR_ESTIMATOR/EMBEDDED_E_SIGN_SUCCESS',
  'EXTERIOR_ESTIMATOR/EMBEDDED_E_SIGN_FAILURE',
)<Proposal, SignatureRequest, Error>();

export const embeddedSignUrl = createAsyncAction(
  'EXTERIOR_ESTIMATOR/EMBEDDED_SIGN_URL_REQUEST',
  'EXTERIOR_ESTIMATOR/EMBEDDED_SIGN_URL_SUCCESS',
  'EXTERIOR_ESTIMATOR/EMBEDDED_SIGN_URL_FAILURE',
)<projectManagementSignatureRequestEmbeddedUrlVariables, EmbeddedUrl, Error>();
