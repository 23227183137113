import numeral from 'numeral';

import { lineItemQuantityUnits } from 'src/utils/unitsMap';

export const lineItemUnits = (units: string | null) => {
  return lineItemQuantityUnits(units);
};

export const lineItemMeasurementNumber = (
  manualMeasurement: number,
  automaticMeasurement: number | string,
) => {
  const manualMeasurementNumber = numeral(manualMeasurement);
  const automaticMeasurementNumber = numeral(automaticMeasurement);
  let measurement;

  if (manualMeasurement > 0) {
    measurement = manualMeasurementNumber;
  } else if (automaticMeasurement > 0) {
    measurement = automaticMeasurementNumber;
  }

  return measurement || numeral(0);
};

export const lineItemMeasurementDisplay = (
  manualMeasurement: number,
  automaticMeasurement: number,
) => {
  const measurement = lineItemMeasurementNumber(
    manualMeasurement,
    automaticMeasurement,
  );

  return numeral(measurement).format('0.00');
};

export const lineItemMaterialQuantity = (
  quantityString: string,
  unit: string,
) => {
  if (!unit || !quantityString) {
    return null;
  }
  const quantity = numeral(quantityString);
  const roundedQuantity = Math.ceil(Number(quantity.value()));
  const units = lineItemQuantityUnits(unit);
  return `${roundedQuantity} ${units}`;
};
