import { InputsType } from 'src/features/projectManagement/types';

// optional fields
export const customerNameValueField = 'customerName';
export const phoneNumberValueField = 'phoneNumber';
export const addressLineTwoValueField = 'addressLineTwo';

// required fields
export const poNumberValueField = 'poNumber';
export const poNumberErrorField = 'isPoNumberInvalid';

export const addressLineOneValueField = 'addressLineOne';
export const addressLineOneErrorField = 'isAddressLineOneInvalid';

export const cityValueField = 'city';
export const cityErrorField = 'isCityInvalid';

export const zipCodeValueField = 'zipCode';
export const zipCodeErrorField = 'isZipCodeInvalid';

export const stateProvinceValueField = 'state';
export const stateProvinceErrorField = 'isStateInvalid';

export const deliveryMethodValueField = 'deliveryMethod';
export const deliveryMethodErrorField = 'isDeliveryMethodInvalid';

export const deliveryDateValueField = 'deliveryDate';
export const deliveryDateErrorField = 'isDeliveryDateInvalid';

export const deliveryTimeValueField = 'deliveryTime';
export const deliveryTimeErrorField = 'isDeliveryTimeInvalid';

export const deliveryTypeValueField = 'deliveryType';
export const deliveryTypeErrorField = 'isDeliveryTypeInvalid';

export const inputs: InputsType = {
  [poNumberValueField]: {
    labelText: 'Purchase order number',
    valueField: poNumberValueField,
    errorField: poNumberErrorField,
  },
  [customerNameValueField]: {
    labelText: 'Customer name',
    valueField: customerNameValueField,
    errorField: '', // optional fields so no errors
  },
  [phoneNumberValueField]: {
    labelText: 'Phone number',
    valueField: phoneNumberValueField,
    errorField: '', // optional fields so no errors
  },
  [addressLineOneValueField]: {
    labelText: 'Address line 1',
    valueField: addressLineOneValueField,
    errorField: addressLineOneErrorField,
  },
  [addressLineTwoValueField]: {
    labelText: 'Address line 2',
    valueField: addressLineTwoValueField,
    errorField: '', // optional fields so no errors
  },
  [cityValueField]: {
    labelText: 'City',
    valueField: cityValueField,
    errorField: cityErrorField,
  },
  [zipCodeValueField]: {
    labelText: 'Zip code',
    valueField: zipCodeValueField,
    errorField: zipCodeErrorField,
  },
  [stateProvinceValueField]: {
    labelText: 'State',
    valueField: stateProvinceValueField,
    errorField: stateProvinceErrorField,
  },
};
