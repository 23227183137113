import appSettings from 'src/appSettings';

export const isReactNativeWebview = () => {
  return window?.ReactNativeWebView?.postMessage ?? false;
};

export const isStaging = () => {
  return window.location.host.includes('staging');
};

export const isSandbox = () => {
  return window.location.host.includes('sandbox');
};

export const isMaster = () => {
  return window.location.host.includes('master');
};

export const isDevelopment = () => {
  return (
    appSettings.NODE_ENV === 'development' ||
    window.location.host.includes('localhost') ||
    window.location.host.includes('0.0.0.0') ||
    window.location.host.includes('.local:') ||
    window.location.host.includes('local')
  );
};

export const isTest = () => {
  return appSettings.NODE_ENV?.toLowerCase() === 'test' || !!window.Cypress;
};

export const isQA = () => {
  return window.location.host.includes('qa');
};

export const isProduction = () => {
  return (
    !isMaster() &&
    !isStaging() &&
    !isDevelopment() &&
    !isQA() &&
    !isSandbox() &&
    !isTest()
  );
};

export const getSplitApiKey = () => {
  /* TODO: This works around a change in behavior introduced by
    the change to use the Split.io service in local and tet envs,
    rather than using the service in 'localhost' mode.  That change
    causes the injected overrides to be overwritten by the treatments
    response from the service.
    THIS SHOULD BE CONSIDERED TEMPORARY, AND REPLACED WITH A MORE 
    ROBUST AND GENERALIZED SOLUTION THAT CAN WORK WITHOUT CUSTOM CODE.
  */
  // Use localhost mode overrides for test scenario.
  if (!!window.Cypress && !window.HOVER.isProduction) {
    // If we're in Cypress, use localhost mode
    // in order to return the test overrides, unless
    // the Cypress test is a smoke test in Production.
    return 'localhost';
  }

  return appSettings.SPLIT_API_KEY;
};
