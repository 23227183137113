import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15px;
  flex-grow: 1;
  max-width: 300px;
`;

export const Group = styled.div`
  margin-bottom: 20px;
`;

export const Header = styled.h2`
  padding: 0 0 10px 0;
  ${({ theme }) => theme.mixins.font('medium', 20)}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 175px;
`;

export const Cell = styled.span<{ bold?: boolean; light?: boolean }>`
  height: 35px;
  text-align: right;
  ${({ theme }) => theme.mixins.font('light', 13)}

  ${({ bold, theme }) => bold && theme.mixins.font('bold', 13)}
  color: ${({ light, theme }) =>
    light ? theme.colors.lightGrayText : theme.colors.defaultNavy}
`;
