import { useMutation } from '@apollo/client';
import { Box, Heading, RadioGroup } from '@hover/blueprint';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimateFields as EstimateFields } from 'src/api/types/estimateFields';
import { EstimateGroupForSummary_estimationEstimateGroup_estimates as EstimationGroupEstimateWithProduct } from 'src/api/types/EstimateGroupForSummary';
import {
  ESTIMATION_ESTIMATE_TOGGLE_UNIQUE_ACTIVATION,
  ESTIMATION_ESTIMATE_TOGGLE_ACTIVATION,
  GET_ESTIMATE_GROUP_FOR_SUMMARY,
} from 'src/features/exteriorEstimator/apis/queries';
import { Estimate } from 'src/features/exteriorEstimator/components/EstimationTool/Estimates/Estimate';
import * as actions from 'src/features/exteriorEstimator/redux/actions/estimateGroupActions';
import {
  getParams,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { estimatesComparator } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { useTracking } from 'src/hooks';
import {
  getTradeTypesSorted,
  getVariationsFilter,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  estimates: EstimateFields[];
  trade: TradeTypeEnum;
  setIsUpdatingActivating: (value: boolean) => void;
  hasSoldEstimateGroup: boolean;
};

export const EstimateGroup: React.FC<Props> = ({
  trade,
  estimates,
  setIsUpdatingActivating,
  hasSoldEstimateGroup,
}) => {
  const dispatch = useDispatch();

  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);

  const { jobId } = useSelector(getParams);
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const isDisabled = hasSoldEstimateGroup;
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [estimateToggleActivation] = useMutation(
    ESTIMATION_ESTIMATE_TOGGLE_ACTIVATION,
    {
      onCompleted: ({ estimationEstimateUpdate }) => {
        dispatch(
          actions.toggleEstimateActivationEnd(
            estimationEstimateUpdate.estimate,
          ),
        );
        setIsUpdatingActivating(false);
      },
      onError: () => {
        setIsUpdatingActivating(false);
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP_FOR_SUMMARY,
          variables: {
            id: estimateGroupId,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    },
  );

  const [estimateToggleUniqueActivation, ,] = useMutation(
    ESTIMATION_ESTIMATE_TOGGLE_UNIQUE_ACTIVATION,
    {
      onCompleted: ({ update }) => {
        dispatch(actions.toggleEstimateActivationEnd(update.estimate));
        setIsUpdatingActivating(false);
      },
      onError: () => {
        setIsUpdatingActivating(false);
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP_FOR_SUMMARY,
          variables: {
            id: estimateGroupId,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    },
  );

  const getActiveEstimateOfSameTrade = (tradeType: TradeTypeEnum) => {
    return estimates?.find((estimate) => {
      return estimate.tradeType === tradeType && estimate.active;
    });
  };

  const handleClickAndChange = (value: string) => {
    const estimate = find(estimates, { id: Number(value) });

    const activeEstimateOfSameTrade = getActiveEstimateOfSameTrade(trade);
    if (isDisabled) return;
    setIsUpdatingActivating(true);

    if (value === 'None') {
      const variables = {
        id: Number(activeEstimateOfSameTrade?.id),
        attributes: { active: false },
        ...variationsFilter,
      };
      estimateToggleActivation({ variables });
      typewriter.optionSelected({
        option_type: 'radio',
        options: trade,
        selection: 'None',
        page_or_screen_name: EventNames.estimator.estimateGroupsScreen.page,
        job_id: Number(jobId),
        ...commonTrackingProps,
      });
    } else if (estimate) {
      const activatingOtherTemplateOfSameTrade =
        activeEstimateOfSameTrade &&
        estimate.id !== activeEstimateOfSameTrade.id;

      const variables = {
        id: estimate.id,
        attributes: { active: true },
        ...variationsFilter,
      };

      if (activatingOtherTemplateOfSameTrade) {
        const theseVariables = {
          ...variables,
          associatedId: activeEstimateOfSameTrade?.id,
          associatedAttributes: { active: false },
        };
        estimateToggleUniqueActivation({ variables: theseVariables });
      } else {
        estimateToggleActivation({ variables });
      }

      typewriter.optionSelected({
        option_type: 'radio',
        options: trade,
        selection: estimate?.name ?? '',
        page_or_screen_name: EventNames.estimator.estimateGroupsScreen.page,
        job_id: Number(jobId),
        ...commonTrackingProps,
      });
    }
  };

  if (!estimates || tradeTypesSorted.length === 0) return null;
  const tradeType = tradeTypesSorted.find(
    (_t) => _t.tradeTypeEnumValue === trade,
  );

  return (
    <Box flexDirection="column" key={trade}>
      <Heading
        margin="1em 0 0.5em 0"
        size={200}
        data-test-id="estimateGroupsTrade"
      >
        {tradeType?.tradeTypeName}
      </Heading>
      <RadioGroup
        onChange={handleClickAndChange}
        value={getActiveEstimateOfSameTrade(trade)?.id.toString() ?? 'None'}
      >
        {estimates.sort(estimatesComparator).map((estimate) => (
          <Estimate
            key={estimate.id}
            estimate={estimate as EstimationGroupEstimateWithProduct}
            trade={trade as TradeTypeEnum}
            name={estimate.name ?? ''}
            id={estimate.id}
            isDisabled={isDisabled}
          />
        ))}
        <Estimate
          key={`${trade}-none`}
          trade={trade as TradeTypeEnum}
          isNone
          name="None"
          id="None"
          isDisabled={isDisabled}
        />
      </RadioGroup>
    </Box>
  );
};
