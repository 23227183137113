// this wraps our request lib but fetches the jwt-token from localStorage, and uses the artemis base url
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import request from 'src/lib/request';
import { TokenHandler } from 'src/lib/tokenHandler';

interface RequestParams {
  path: string;
  baseUrl: string;
  options?: AxiosRequestConfig;
}

export class HoverRequest {
  static hoverAccessToken: string;

  static apiVersion = 1;

  static set token(token: string) {
    this.hoverAccessToken = token;
  }

  static get token() {
    return this.hoverAccessToken;
  }

  static async retrieveToken({ force }: { force?: boolean } = {}) {
    if (!this.token || force) {
      this.token = (await TokenHandler.fetchAccessToken()) as any;
    }
    return this.token;
  }

  static removeToken() {
    this.token = '';
  }

  static async send<T>(path: string, baseUrl: string, options = {}) {
    this.retrieveToken();

    axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig<any>) => {
        /* eslint-disable no-param-reassign */
        config.headers = config.headers || {};
        (config.headers as any)['X-JWT-AUTH'] = `Bearer ${this.token}`;
        return config;
        /* eslint-enable no-param-reassign */
      },
    );
    const requestOptions = {
      withCredentials: false,
      ...options,
      baseURL: baseUrl,
    };

    try {
      const success = await request.send<T>(path, requestOptions);
      return success;
    } catch (e: any) {
      if (e.response && e.response.status === 401) {
        const accessToken =
          (await TokenHandler.fetchAccessToken()) as unknown as string;

        this.token = accessToken;
        return request.send<T>(path, requestOptions);
      }
      throw e;
    }
  }

  static async get<T>(params: RequestParams) {
    return this.send<T>(params.path, params.baseUrl, {
      ...params.options,
      method: 'get',
    });
  }
}
