import { Box } from '@hover/blueprint';
import numeral from 'numeral';

import { SUPERSCRIPT_TWO } from 'src/features/exteriorEstimator/constants/sidingConstants';
import { QuestionAnswer } from 'src/features/exteriorEstimator/types';

export const AreaCounter = ({ area }: { area: QuestionAnswer }) => {
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      data-test-id="selectedPartialSidingAreaCounter"
    >{`${numeral(area).format('0,0')} ft${SUPERSCRIPT_TWO} area selected`}</Box>
  );
};
