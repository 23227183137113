import { useQuery } from '@apollo/client';

import { ORG_PROPOSAL_SETTINGS } from 'src/features/exteriorEstimator/apis/queries';
import { ProposalOrgSettings } from 'src/features/exteriorEstimator/types';

import { ProposalCreateBody } from './ProposalCreateBody';

interface Props {
  orgId: string;
  estimateGroupId: string;
  isLoading: boolean;
  hidePriceToggles: boolean;
}

export const ProposalCreateLoader: React.FC<Props> = ({
  orgId,
  estimateGroupId,
  isLoading,
  hidePriceToggles,
}) => {
  const { loading, data, error } = useQuery(ORG_PROPOSAL_SETTINGS, {
    variables: { orgId },
  });

  // default settings if org hasn't set up proposal settings
  const defaultSettings: Omit<ProposalOrgSettings, '__typename' | 'id'> = {
    allowDefaultOverrideIncludeAllEstimateItems: true,
    allowDefaultOverrideIncludeItemCost: true,
    allowDefaultOverrideIncludeItemQuantities: true,
    allowDefaultOverrideIncludeTotalPrice: true,
    allowDefaultOverrideIncludeTradePrice: true,
    includeAllEstimateItemsDefault: false,
    includeItemCostDefault: false,
    includeItemQuantitiesDefault: false,
    includeTotalPriceDefault: false,
    includeTradePriceDefault: false,
    maxSigneeCount: 1,
    defaultNote: '',
  };

  if (data && !loading && !error) {
    const orgSettings = data?.projectManagementConfigOrgProposalSetting
      ? data.projectManagementConfigOrgProposalSetting
      : defaultSettings;

    return (
      <ProposalCreateBody
        {...orgSettings}
        estimateGroupId={estimateGroupId}
        isLoading={isLoading}
        hidePriceToggles={hidePriceToggles}
      />
    );
  }
  return null;
};
