import { Box, Button, Modal, Body } from '@hover/blueprint';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  isLoading: boolean;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  header?: string;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  isOpen,
  isLoading,
  message,
  onCancel,
  onConfirm,
  header = 'Confirmation',
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    size="medium"
    header={header}
    isClosable
    footer={
      <Box>
        <Button
          color="primary"
          fill="outline"
          marginRight={200}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} isLoading={isLoading} color="danger">
          Delete
        </Button>
      </Box>
    }
  >
    <Body>{message}</Body>
  </Modal>
);
