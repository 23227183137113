import { all, fork } from 'redux-saga/effects';

import { estimatorProductionSagas } from './estimatorProductionSagas';
import projectManagementListItemSagas from './projectManagementListItemSagas';
import projectManagementOrderSagas from './projectManagementOrderSagas';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function* sagas() {
  yield all([
    fork(estimatorProductionSagas),
    fork(projectManagementOrderSagas),
    fork(projectManagementListItemSagas),
  ]);
}
