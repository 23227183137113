import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Field,
  Heading,
  Icon,
  Modal,
  TextInput,
} from '@hover/blueprint';
import { iTrash } from '@hover/icons';
import * as EmailValidator from 'email-validator';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getJobDetails } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useLocalStorage, useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

const RemoveCustomerModal = ({
  removeCustomer,
  closeModal,
  isOpen,
}: {
  removeCustomer: () => void;
  closeModal: () => void;
  isOpen: boolean;
}) => (
  <>
    <Modal
      isOpen={isOpen}
      footer={
        <>
          <Button
            fill="outline"
            size="large"
            onClick={closeModal}
            color="danger"
          >
            Cancel
          </Button>
          <Button size="large" onClick={removeCustomer} color="danger">
            Remove
          </Button>
        </>
      }
      header="Remove Customer"
      onClose={closeModal}
    >
      This action will remove customer from proposal and you will lose details
    </Modal>
  </>
);

const CustomerFields: React.FC<{
  index: number;
  areMultipleCustomers: boolean;
  removeCustomer: () => void;
  estimateGroupId: string;
}> = ({ index, areMultipleCustomers, removeCustomer, estimateGroupId }) => {
  const jobDetails = useSelector(getJobDetails);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [isRemoveCustomerModalOpen, setIsRemoveCustomerModalOpen] =
    useState(false);

  const closeRemoveCustomerModal = () => {
    setIsRemoveCustomerModalOpen(false);
    typewriter.buttonPressed({
      backend_id_value: estimateGroupId,
      backend_id_type: 'estimate_group_id',
      job_id: jobDetails?.id,
      button_text: 'Cancel',
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.removeCustomerModal,
      ...commonTrackingProps,
    });
  };
  const openRemoveCustomerModal = () => {
    setIsRemoveCustomerModalOpen(true);
    typewriter.buttonPressed({
      backend_id_value: estimateGroupId,
      backend_id_type: 'estimate_group_id',
      job_id: jobDetails?.id,
      button_text: 'Delete',
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.page,
      ...commonTrackingProps,
    });
  };

  const removeCustomerWithTracking = () => {
    removeCustomer();
    typewriter.buttonPressed({
      backend_id_value: estimateGroupId,
      backend_id_type: 'estimate_group_id',
      job_id: jobDetails?.id,
      button_text: 'Remove',
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.removeCustomerModal,
      ...commonTrackingProps,
    });
  };

  return (
    <Box flexDirection="column" as="section" flexShrink={0}>
      <RemoveCustomerModal
        isOpen={isRemoveCustomerModalOpen}
        closeModal={closeRemoveCustomerModal}
        removeCustomer={removeCustomerWithTracking}
      />
      <Box justifyContent="space-between">
        <Heading size={200}>
          Customer {areMultipleCustomers ? index + 1 : null}
        </Heading>
        {areMultipleCustomers && (
          <Icon
            _hover={{ cursor: 'pointer' }}
            icon={iTrash}
            onClick={openRemoveCustomerModal}
            aria-label="remove customer"
          />
        )}
      </Box>

      <Field
        label="Full name"
        name={`customers.${index}.name`}
        error={errors?.customers?.[index]?.name?.message}
      >
        <TextInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {...register(`customers.${index}.name` as const, {
            required: 'Name is required',
          })}
          data-test-id="nameInput"
        />
      </Field>

      <Field
        label="Email"
        name={`customers.${index}.email`}
        error={errors?.customers?.[index]?.email?.message}
      >
        <TextInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {...register(`customers.${index}.email` as const, {
            validate: {
              emailValidator: (value) => {
                if (!value) {
                  return 'Email is required';
                }
                return EmailValidator.validate(value) || 'Must be valid email';
              },
            },
          })}
          data-test-id={
            errors?.email?.message ? 'emailInputInvalid' : 'emailInput'
          }
          type="email"
        />
      </Field>
      <Field
        label="Phone (optional)"
        name={`customers.${index}.phoneNumber`}
        error={errors?.customers?.[index]?.phoneNumber?.message}
      >
        <TextInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {...register(`customers.${index}.phoneNumber` as const)}
          data-test-id="phoneInput"
          type="tel"
          inputMode="tel"
        />
      </Field>
    </Box>
  );
};

export const CustomerDetails: React.FC<{
  maxSigneeCount: number;
  estimateGroupId: string;
}> = ({ maxSigneeCount, estimateGroupId }) => {
  const jobDetails = useSelector(getJobDetails);
  const { getValues, control } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { fields, append, remove } = useFieldArray({
    name: 'customers',
    control,
  });

  const buttonText = 'Add another customer';

  const [, setCustomers] = useLocalStorage(
    `${jobDetails?.id}.proposalData.customers`,
    [],
  );

  useEffect(() => {
    return () => {
      setCustomers(getValues('customers'));
    };
    // persist the form values on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appendCustomer = () => {
    append({ name: '', email: '', phoneNumber: '' });
    typewriter.buttonPressed({
      backend_id_value: estimateGroupId,
      backend_id_type: 'estimate_group_id',
      job_id: jobDetails?.id,
      button_text: buttonText,
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.page,
      ...commonTrackingProps,
    });
  };

  return (
    <Box flexDirection="column" marginBottom={400} as="section" flexShrink={0}>
      <>
        {fields.map((field, index, array) => {
          return (
            <CustomerFields
              key={field.id}
              index={index}
              areMultipleCustomers={array.length > 1}
              removeCustomer={() => remove(index)}
              estimateGroupId={estimateGroupId}
            />
          );
        })}
        {fields.length < maxSigneeCount && (
          <Box>
            <Button fill="outline" onClick={appendCustomer}>
              {buttonText}
            </Button>
          </Box>
        )}
      </>
    </Box>
  );
};
