import { TileCheckbox, TileCheckboxProps } from '@hover/blueprint';

import type { QuestionAnswer } from 'src/features/exteriorEstimator/types';

type Props = TileCheckboxProps & {
  handleInputChange: (answer: QuestionAnswer) => void;
};

export const TileCheckboxQuestion: React.FC<Props> = ({
  children,
  handleInputChange,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e.target.checked);
  };
  return (
    <TileCheckbox onChange={handleChange} {...props}>
      {children}
    </TileCheckbox>
  );
};
