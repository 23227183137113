import { PureComponent, ReactNode } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { projectManagementOrderDocuments_projectManagementOrderDocuments as OrderDocument } from 'src/features/projectManagement/apis/graphql/queries/types';

import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { withTypewriter } from 'src/components/WithTypewriter';
import { OrderDocumentState } from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import {
  getParams,
  getJobDetails,
} from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import { getOrderDocumentsByState } from 'src/features/projectManagement/utils/orderUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootAction, RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import OrderDocumentDownloadLink from './OrderDocumentDownloadLink';
import {
  Modal,
  Button,
  contentStyle,
  footerStyle,
  Content,
  FlexColumn,
  ErrorMessage,
} from './styled';

export const mapStateToProps = (state: RootState) => ({
  shouldShowPdfModal: state.estimatorProductionTools.shouldShowPdfModal,
  pdf: state.estimatorProductionTools.pdf,
  jobId: getParams(state).jobId,
  jobDetails: getJobDetails(state),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      toggle: actions.togglePdfModal,
      updatePdfModalState: actions.updatePdfModalState,
    },
    dispatch,
  );

interface OwnProps {
  orderDocuments?: OrderDocument[];
  switchStateToNew: () => void;
  refetch: () => void;
  toggleDidUserNavigateToNewFromExisting: () => void;
  typewriter: any;
}
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export class DownloadExisting extends PureComponent<Props> {
  static ERROR_MESSAGE =
    "We're unable to generate your Order Summary at the moment. Please try again.";

  componentDidUpdate(prevProps: Props) {
    const { shouldShowPdfModal, jobDetails, refetch, typewriter } = this.props;
    if (!prevProps.shouldShowPdfModal && shouldShowPdfModal) {
      // if true, modal appeared
      refetch();

      typewriter.pageViewed({
        page_or_screen_name: EventNames.pmp.pdf.downloadExisting,
        ...jobProps(jobDetails),
      });
    }
  }

  @autobind
  handleCancelClick() {
    const { toggle } = this.props;
    toggle({ show: false });
  }

  @autobind
  handleActionClick() {
    const {
      jobDetails,
      switchStateToNew,
      toggleDidUserNavigateToNewFromExisting,
      typewriter,
    } = this.props;

    typewriter.buttonPressed({
      button_text: 'PDF Historical Exports Download',
      page_or_screen_name: EventNames.pmp.page,
      primary_cta: false,
      ...jobProps(jobDetails),
    });
    toggleDidUserNavigateToNewFromExisting();
    switchStateToNew();
  }

  @autobind
  getTitle() {
    const { jobId } = this.props;
    return `Order history exports for Property Id ${jobId}`;
  }

  @autobind
  renderActionButton() {
    return (
      <Button onClick={this.handleActionClick} data-test-id="downloadNewPdf">
        Download New PDF
      </Button>
    );
  }

  @autobind
  renderFooterContent(error) {
    return error ? (
      <ErrorMessage data-test-id="downloadPdfErrorMessage">
        {DownloadExisting.ERROR_MESSAGE}
      </ErrorMessage>
    ) : null;
  }

  public render(): ReactNode {
    const {
      shouldShowPdfModal,
      orderDocuments,
      pdf: { isFetching, error },
    } = this.props;
    if (!orderDocuments) return null;
    const shouldShowBoxShadow = orderDocuments.length > 3;
    const completedOrderDocuments = getOrderDocumentsByState(
      orderDocuments,
      OrderDocumentState.Complete,
    );

    return (
      <>
        {isFetching && <LoaderSpinner show />}
        <Modal
          data-test-id="generatePdfModal-downloadExisting"
          isOpen={shouldShowPdfModal}
          contentStyle={contentStyle}
          textAlign="left"
          title={this.getTitle()}
          lineHeight="23px"
          justifyFooter="flex-end"
          padding="0"
          footerStyle={footerStyle}
          closeButton={this.handleCancelClick}
          action={this.renderActionButton()}
          footerContent={this.renderFooterContent(error)}
        >
          <Content shouldShowBoxShadow={shouldShowBoxShadow}>
            <FlexColumn>
              {completedOrderDocuments.map((orderDocument) => (
                <OrderDocumentDownloadLink
                  key={orderDocument.id}
                  orderDocument={orderDocument}
                />
              ))}
            </FlexColumn>
          </Content>
        </Modal>
      </>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(DownloadExisting));
