import React, { useCallback, useEffect, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import { Box, Hide } from '@hover/blueprint';
import { ProgressiveLoader } from '@hover/blueprint/extra/components';
import { Location } from 'history';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import type {
  productCatalogConfigOrgDistributors as Distributors,
  productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor,
} from 'src/api/types/productCatalogConfigOrgDistributors';
import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup,
  projectManagementProductionList_projectManagementProductionList_listItems_product as Product,
} from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  GET_PRODUCTION_LIST,
  GET_DISTRIBUTORS,
} from 'src/features/project/apis/graphql/queries/queries';
import { ProjectScopeContent } from 'src/features/project/components/ProjectScope/ProjectScopeContent';
import { useMaterialListFeature } from 'src/features/project/hooks/useMaterialListFeature';
import {
  useQueryEhi,
  useLazyQueryEhi,
  useToastEhi,
  ToastStatusEnum,
  useTracking,
  usePrevious,
} from 'src/hooks';
import {
  isEnabled,
  COMMERCE_PROJECT_SCOPE_INLINE_EDITING_V2,
} from 'src/lib/FeatureFlag';
import {
  getOrgIdParam,
  getMaterialListCreateParam,
  getMaterialListFeature,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import {
  updateBranch,
  updateDistributor,
  updateJobAccount,
} from '../../redux/actions';
import { useDistributorSelectModal } from './hooks/useDistributorSelectModal';
import { useDownloadPdfModal } from './hooks/useDownloadPdfModal';
import { TemplateSelectionModal } from './MaterialList/TemplateSelectionModal';
import { ProjectScopeFooter } from './ProjectScopeFooter';

const enum TOAST_IDS {
  GET_PRODUCTION_LIST_ERROR_TOAST,
}

export type AddEditFields = {
  id: string;
  trade: TradeTypeEnum;
  name: string;
  quantityUnits: string;
  quantity: number;
  calculatedQuantity: number;
  color: string | null;
  externalVariationId?: string | null;
  unitCost: number | null;
  totalCost: number;
  customVariant: string;
  sku: string | null;
  productCatalogProductId: string;
  product: Product;
  measurement: number;
  wasteFactor: number;
  type: string;
  userSetCustomColor: boolean;
};
export type AddEditFormData = Record<`${string}`, AddEditFields>;

export const ProjectScope: React.FC = () => {
  const orgId = useSelector(getOrgIdParam);
  const jobId = Number(get(useParams(), 'jobId'));
  const location: Location<{ refetch: boolean }> = useLocation();
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const materialListCreate = useSelector(getMaterialListCreateParam);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaterialListEnabled = useSelector(getMaterialListFeature);

  const [showTemplateSelectionModal, setShowTemplateSelectionModal] =
    useState<boolean>(false);
  const { recreateEstimateGroupWithTemplates, isRecreatingMaterialList } =
    useMaterialListFeature();
  const prevIsRecreatingMaterialList = usePrevious(isRecreatingMaterialList);
  const isDoneRecreatingMaterialList =
    isRecreatingMaterialList === false && prevIsRecreatingMaterialList === true;

  // Segment tracking.
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      ...commonTrackingProps,
    });
    // Track only on page load/view.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchProductionList, { data, loading, refetch, networkStatus }] =
    useLazyQueryEhi(GET_PRODUCTION_LIST, {
      variables: {
        orgId,
        jobId,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        toast({
          id: TOAST_IDS.GET_PRODUCTION_LIST_ERROR_TOAST,
          description:
            messages.projectScope.errors.query.productionList.getProductionList,
          status: ToastStatusEnum.ERROR,
        });
      },
    });

  const {
    showDownloadPdfModal,
    downloadPdfLineItemType,
    openDownloadPdfModal,
    closeDownloadPdfModal,
  } = useDownloadPdfModal({ jobId });

  useEffect(() => {
    if (materialListCreate && isMaterialListEnabled) {
      setShowTemplateSelectionModal(true);
    } else {
      // fetch the prod list normally
      fetchProductionList();
    }
  }, [materialListCreate]);

  useEffect(() => {
    // On revisiting the page, refetch the production list after an order;
    // can't use `refetchQueries` in mutation because polling is required.
    const shouldRefetch = location.state?.refetch ?? false;

    if (shouldRefetch) {
      refetch();
    }
  }, [location, refetch]);

  const estimateGroup: EstimateGroup =
    data?.projectManagementProductionList?.estimateGroup ??
    ({} as EstimateGroup);

  // Get available distributors for org, for distributor selection menu.
  const {
    data: distributorsData,
    loading: loadingDistributors,
  }: { data?: Distributors; loading: boolean } = useQueryEhi(GET_DISTRIBUTORS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'cache-first', // default
  });

  const distributors = distributorsData?.productCatalogConfigOrgDistributors;

  const {
    showDistributorSelectModal,
    openDistributorSelectModal,
    closeDistributorSelectModal,
  } = useDistributorSelectModal();

  const setDistributor = useCallback(
    (distributor: Distributor | null) => {
      const selectedDistributor = distributor?.distributor || null;
      // Store distributor object in redux.
      dispatch(updateDistributor(selectedDistributor));
      // Clear branch/jobAccount when distributor is changed.
      dispatch(updateBranch(null));
      dispatch(updateJobAccount(null));
      // Navigate to OrderDetail screen for next step.
      history.push(`/project/${jobId}/detail?orgId=${orgId}`);
    },
    [dispatch, history, jobId, orgId],
  );

  // If only one distributor exists, bypass distributor selection.
  const selectDistributor = useCallback(() => {
    if (distributors?.length === 1) {
      setDistributor(distributors[0]);
    } else {
      openDistributorSelectModal();
    }
    // Segment tracking.
    typewriter.buttonPressed({
      button_text: 'Order',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [
    distributors,
    jobId,
    openDistributorSelectModal,
    setDistributor,
    commonTrackingProps,
  ]);

  useEffect(() => {
    if (!isDoneRecreatingMaterialList) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('materialListCreate');

    history.replace({
      search: queryParams.toString(),
    });
  }, [isDoneRecreatingMaterialList]);

  if (isRecreatingMaterialList) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ProgressiveLoader
          marginTop="20vh"
          label="Loading Material List"
          captions={[
            'Hang tight as we build your material list!',
            'Say goodbye to number crunching...',
            'Just a few more seconds',
            'Calculating materials',
            'Calculating quantities!',
          ]}
          width="100%"
          data-test-id="TemplateSelectionModal-Loader"
        />
      </Box>
    );
  }

  return (
    <>
      {isMaterialListEnabled && (
        <TemplateSelectionModal
          isOpen={showTemplateSelectionModal}
          onClose={() => {
            setShowTemplateSelectionModal(false);
            recreateEstimateGroupWithTemplates();
          }}
        />
      )}

      <ProjectScopeContent
        loading={
          loading ||
          loadingDistributors ||
          isRecreatingMaterialList ||
          networkStatus === NetworkStatus.refetch
        }
        data={data}
        estimateGroup={estimateGroup}
        jobId={jobId}
        orgId={orgId}
        distributors={distributors}
        enableInlineEditingV2={isEnabled(
          COMMERCE_PROJECT_SCOPE_INLINE_EDITING_V2,
        )}
        showDownloadPdfModal={showDownloadPdfModal}
        downloadPdfLineItemType={downloadPdfLineItemType}
        openDownloadPdfModal={openDownloadPdfModal}
        closeDownloadPdfModal={closeDownloadPdfModal}
        showDistributorSelectModal={showDistributorSelectModal}
        closeDistributorSelectModal={closeDistributorSelectModal}
        setDistributor={setDistributor}
        selectDistributor={selectDistributor}
      />

      <Hide below="tablet">
        {isMaterialListEnabled && data && (
          <ProjectScopeFooter
            openDownloadPdfModal={openDownloadPdfModal}
            selectDistributor={selectDistributor}
            distributors={distributors}
            listItems={data.projectManagementProductionList.listItems}
          />
        )}
      </Hide>
    </>
  );
};
