import { useMutation } from '@apollo/client';
import { Button, Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import appSettings from 'src/appSettings';
import { useToastEhi, ToastStatusEnum } from 'src/hooks';
import { COMMERCE_IMPERSONATION_BANNER, isEnabled } from 'src/lib/FeatureFlag';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';
import request from 'src/lib/request';
import { getImpersonator, getUserProfile } from 'src/redux/selectors';

export const ImpersonatorBannerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary900};
  color: ${({ theme }) => theme.colors.neutral50};
  padding: 10px;
`;

export const ImpersonatorBanner: React.FC = () => {
  const currentImpersonator = useSelector(getImpersonator);
  const currentUser = useSelector(getUserProfile);
  const toast = useToastEhi();

  const endImpersonationSession = () => {
    const hyperionUrl = new URL(appSettings.HYPERION_API_SERVER);
    const endImpersonationUrl = `${hyperionUrl.toString()}api/v1/users/stop_impersonating`;

    request
      .delete(endImpersonationUrl)
      .then((res) => {
        HoverRequest.removeToken();
        window.location.reload();
      })
      .catch(() => {
        toast({
          id: 'impersonator-banner-error',
          description: 'Could not end impersonation session.',
          status: ToastStatusEnum.ERROR,
        });
      });
  };

  if (
    !currentImpersonator ||
    !currentUser ||
    !isEnabled(COMMERCE_IMPERSONATION_BANNER)
  ) {
    return null;
  }

  return (
    <ImpersonatorBannerContainer>
      <Box flexDirection="row" justifyContent="center" alignItems="center">
        <span data-test-id="impersonationInfo">
          You, <strong>{currentImpersonator.userEmail}</strong>, are currently
          impersonating the user <strong>{currentUser.email}</strong>.
        </span>
        <Button
          data-test-id="endImpersonationAccessButton"
          color="primary"
          fill="outline"
          size="small"
          onClick={() => {
            endImpersonationSession();
          }}
          marginLeft={200}
        >
          End Access
        </Button>
      </Box>
    </ImpersonatorBannerContainer>
  );
};
