import { isEmpty } from 'lodash';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';

import { CustomLineItem, CustomLineItemErrorInput } from '../types';

export const DUPLICATE_KEY_ERROR = "You've already entered this item.";
export const REQUIRES_KEY_ERROR = 'Item cannot be left blank.';
export const MISSING_TRADE_ERROR = 'Item requires a trade type';

const isMissingTradeType = (
  customLineItem: CustomLineItem,
  selectedTrades: (string | null)[],
) => {
  if (selectedTrades.length === 1) return false;
  if (customLineItem.key === '') return false;
  const tradeType = customLineItem.tradeType || '';
  return !selectedTrades.includes(tradeType);
};

const isDuplicateKey = (key: string, index: number, keys: string[]) =>
  key && keys.indexOf(key) !== index;

const isMissingKey = (key: string, value: string) =>
  isEmpty(key.trim()) && value;

const customLineItemError = (
  customLineItem: CustomLineItem,
  index: number,
  selectedTrades: (string | null)[],
  keys: string[],
) => {
  if (isDuplicateKey(customLineItem.key, index, keys))
    return {
      text: DUPLICATE_KEY_ERROR,
      input: CustomLineItemErrorInput.KEY,
    };
  if (isMissingKey(customLineItem.key, customLineItem.value))
    return {
      text: REQUIRES_KEY_ERROR,
      input: CustomLineItemErrorInput.KEY,
    };
  if (isMissingTradeType(customLineItem, selectedTrades))
    return {
      text: MISSING_TRADE_ERROR,
      input: CustomLineItemErrorInput.TRADE_TYPE,
    };
  return null;
};

export const validateCustomLineItems = (
  customLineItems: CustomLineItem[],
  selectedTrades: (string | null)[],
) => {
  const keys = customLineItems.map((item) => item.key);

  return customLineItems.map((item, index) => ({
    ...item,
    error: customLineItemError(item, index, selectedTrades, keys),
  }));
};

export const convertCustomLineItemsToApiResponse = (
  customLineItems: CustomLineItem[] = [],
) => {
  return customLineItems
    .filter((customLineItem) => customLineItem.key !== '')
    .map((filteredLineItem) => ({
      name: filteredLineItem.key,
      price: Number(filteredLineItem.value.replace(/[$,]/g, '')),
      tradeType: filteredLineItem.tradeType
        ? filteredLineItem.tradeType.toUpperCase()
        : undefined,
      type: filteredLineItem.type || LineItemTypeEnum.OTHER,
    }));
};
