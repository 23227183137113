import { gql } from '@apollo/client';

export const GET_INSPECTION_CHECKLIST = gql`
  query inspectionChecklist($jobId: ID!, $orgId: ID!) {
    inspectionChecklist(jobId: $jobId, orgId: $orgId) {
      createdAt
      updatedAt
      lastSavedByUserId
      sections {
        id
        name
        sortOrder
        questions {
          answerValue
          questionText
          questionType
          sortOrder
          estimationConfigInputId
          estimationConfigInputOptionId
          id
          images {
            redirectUrl
          }
        }
      }
    }
  }
`;

export const GET_INSPECTION_CHECKLISTS = gql`
  query inspectionChecklists($jobId: ID!, $orgId: ID!) {
    inspectionChecklists(jobId: $jobId, orgId: $orgId) {
      nodes {
        id
        name
        createdAt
        updatedAt
        sections {
          id
          name
          sortOrder
          questions {
            answerValue
            questionText
            questionType
            sortOrder
            estimationConfigInputId
            estimationConfigInputOptionId
            id
            images {
              redirectUrl
            }
          }
        }
        reportPdf {
          redirectUrl
          filename
        }
      }
    }
  }
`;
