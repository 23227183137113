import { useState, useEffect } from 'react';

import { Box, Body, Button, Image, LoadingOverlay } from '@hover/blueprint';
import { iUpload } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

import { InputFileUploader } from './InputFileUploader';

export const SalesPresentationFileUpload: React.FC = () => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [fileName, setFileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: 'Sales Presentation Upload',
      ...commonTrackingProps,
    });
  }, []);

  const trackFileUpload = () => {
    typewriter.buttonPressed({
      page_or_screen_name: 'Sales Presentation List Empty',
      button_text: 'Choose a file',
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  return (
    <Box flexDirection="column" width={900}>
      <Body color="neutral.600">
        Pick a presentation you’d like to share with the team, PDF format. This
        presentation file will be available on HOVER mobile app for your team to
        use whenever they need it.{' '}
      </Body>
      <LoadingOverlay isLoading={isLoading} />
      <Box
        border="2px dashed #D5D5D5"
        background="neutral.100"
        height="304px"
        justifyContent="center"
        alignItems="center"
      >
        <Box flexDirection="column" justifyContent="center" alignItems="center">
          <Image
            src="https://assets.hover.to/icons/SVG/pdf.svg"
            alt="Pdf Icon"
            width={500}
            height={500}
          />
          <Body>Upload a sales presentation file</Body>
          {fileName !== '' && (
            <Box paddingBottom={400} alignItems="center">
              <Body as="span">{fileName}</Body>
            </Box>
          )}
          <InputFileUploader
            setFileName={setFileName}
            setLoading={setIsLoading}
          >
            <Button
              fill="solid"
              iconBefore={iUpload}
              color="primary"
              padding={500}
              onClick={trackFileUpload}
            >
              Choose a file
            </Button>
          </InputFileUploader>
        </Box>
      </Box>
    </Box>
  );
};
