import { Button as BlueprintButton } from '@hover/blueprint';
import styled from 'styled-components';

export const BottomBarWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  min-height: 75px;
  justify-content: flex-end;
  margin: 0;
  width: 100%;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

export const PricingDetailsContent = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 176px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 16px;
`;

export const BottomBarText = styled.h4`
  text-align: right;
  line-height: 14px;
  margin: 0.5rem 0 0;
`;

export const TaxText = styled(BottomBarText)`
  ${({ theme }) => theme.mixins.font('normal', 12)};
`;

export const SubTotalText = styled(TaxText)``;

export const TotalText = styled(BottomBarText)`
  ${({ theme }) => theme.mixins.font('medium', 12)};
`;

export const StyledButton = styled(BlueprintButton)`
  max-height: 32px;
`;
