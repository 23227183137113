import { useEffect } from 'react';

import { Icon, Heading, Box, Body, Button, Loader } from '@hover/blueprint';
import { iAlertTriangle, iCheckCircle, iXCircle } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export enum ProductFilteringState {
  Error,
  Enabled,
  Disabled,
  Processing,
}

interface Props {
  handlePress: () => void;
}

const Error: React.FC<Props> = ({ handlePress }) => {
  return (
    <Box
      flexDirection="column"
      alignItems="center"
      data-test-id="ModalContentError"
    >
      <Box margin={400}>
        <Icon icon={iAlertTriangle} color="danger500" size="huge" />
      </Box>
      <Body size={400} margin={0} color="neutral600">
        There was a problem while enabling filtering to materials.
      </Body>
      <Body size={400} margin={0} color="neutral600" marginBottom={500}>
        Try enable filtering again or contact HOVER support
      </Body>
      <Button onClick={handlePress}>Try again </Button>
    </Box>
  );
};

const Enabled: React.FC<Props> = ({ handlePress }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.settings.materialsList.enableFilteringModal.confirmation
          .page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      data-test-id="ModalContentEnabled"
    >
      <Box margin={400}>
        <Icon
          icon={iCheckCircle}
          color="success500"
          data-test-id="ModalContentEnabled-icon"
        />
        <Heading size={200}>Enabled</Heading>
      </Box>
      <Body size={400} margin={0} color="neutral600" marginBottom={500}>
        You can manage what your team can select and sell.
      </Body>
      <Button onClick={handlePress}>Got it</Button>
    </Box>
  );
};

const Disabled: React.FC<Props> = ({ handlePress }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.settings.materialsList.enableFilteringModal.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      data-test-id="ModalContentDisabled"
    >
      <Box margin={400}>
        <Icon
          data-test-id="ModalContentDisabled-icon"
          icon={iXCircle}
          color="danger500"
        />
        <Heading size={200}>Disabled</Heading>
      </Box>
      <Body size={400} margin={0} color="neutral600">
        Your team can access all available variants for material.
      </Body>
      <Body size={400} margin={0} color="neutral600" marginBottom={500}>
        This change will affect what Sales rep can select during estimation.
      </Body>
      <Button onClick={handlePress}>Enable filtering</Button>
    </Box>
  );
};

const Processing: React.FC = () => {
  return (
    <Box
      flexDirection="column"
      alignItems="center"
      data-test-id="ModalContentProcessing"
    >
      <Box margin={400}>
        <Heading size={200}>Processing</Heading>
      </Box>
      <Loader size="large" />
    </Box>
  );
};

interface SwitchContentProps {
  closeModal: () => void;
  productFilteringState: ProductFilteringState;
  enableVariantsFiltering: () => void;
}

export const SwitchContent: React.FC<SwitchContentProps> = ({
  closeModal,
  productFilteringState,
  enableVariantsFiltering,
}) => {
  switch (productFilteringState) {
    case ProductFilteringState.Error:
      return <Error handlePress={enableVariantsFiltering} />;
    case ProductFilteringState.Enabled:
      return <Enabled handlePress={closeModal} />;
    case ProductFilteringState.Processing:
      return <Processing />;
    default:
      return <Disabled handlePress={enableVariantsFiltering} />;
  }
};
