import { useQuery } from '@apollo/client';
import { Box, Heading, Body, Spinner } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ProjectManagementSignatureRequestStateEnum } from 'src/api/graphql-global-types';
import { projectManagementProposalDocumentCreate_projectManagementProposalDocumentCreate_proposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocumentCreate';
import { Container } from 'src/components/Misc';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { RootState, RootAction } from 'src/types/reduxStore';

import { FETCH_SIGNATURE_REQUEST } from '../../../apis/queries/proposal';
import { ProposalViews } from '../../../types';

export const mapStateToProps = (state: RootState) => ({
  signatureRequest: state.exteriorEstimator.proposalsData.signatureRequest,
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setProposalsError: estimatorActions.setProposalsError,
    },
    dispatch,
  );

interface OwnProps {
  proposal: Proposal;
  setProposalModalView: (view: ProposalViews | null) => void;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const SendingEmailESignComponent: React.FC<Props> = ({
  proposal,
  signatureRequest,
  setProposalsError,
  setProposalModalView,
}) => {
  const { data, stopPolling, error } = useQuery(FETCH_SIGNATURE_REQUEST, {
    variables: { id: signatureRequest.id },
    pollInterval: 1500,
    skip: !signatureRequest.id,
  });

  if (!!data && data.projectManagementSignatureRequest.state === 'REQUESTED') {
    stopPolling();
    setProposalModalView(ProposalViews.SENT_EMAIL_ESIGN);
  } else if (
    data?.projectManagementSignatureRequest?.state ===
    ProjectManagementSignatureRequestStateEnum.FAILED
  ) {
    stopPolling();
    Sentry.captureMessage(
      data.projectManagementSignatureRequest?.failureReason,
    );
    setProposalsError(data.projectManagementSignatureRequest?.failureReason);
  }

  if (error) {
    stopPolling();
    Sentry.captureException(error);
    setProposalsError(error);
    setProposalModalView(ProposalViews.SHOW);
  }

  return (
    <Box flexShrink={0} flex={1} data-test-id="sendingEmailESignBody">
      <Container>
        <Box justifyContent="center" margin={600}>
          <Spinner size="huge" />
        </Box>
        <Heading size={300}>Sending…</Heading>
        <Body size={200}>
          <Box
            padding={400}
            width={1}
            flexDirection="column"
            alignItems="center"
          >
            <Body as="span" size={200}>
              Email is being sent to
            </Body>
            {proposal.customers.map(({ emailAddress }) => (
              <Body as="span" size={200}>
                <strong>{emailAddress}</strong>
              </Body>
            ))}
            <Body as="span" size={200}>
              to review and sign proposal
            </Body>
          </Box>
        </Body>
      </Container>
    </Box>
  );
};

export const SendingEmailESign = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendingEmailESignComponent);
