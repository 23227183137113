import { useMemo } from 'react';

import {
  Body,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Heading,
  Icon,
  Link,
} from '@hover/blueprint';
import { iDownload, iPlus } from '@hover/icons';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type {
  ListItemIdsByTrade,
  ListItemsByTrade,
} from 'src/features/project/types';
import { useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { toggleSelectAllTypeItems } from '../../redux/actions';
import { useProjectScopeTracker } from './hooks/useProjectScopeTracker';
import { ListControlTotals } from './ListControl/ListControlTotals';

type ProjectScopeTypeListControlsProps = {
  jobId: number;
  lineItemType: LineItemTypeEnum;
  selectedListItemIds: ListItemIdsByTrade | null;
  itemsByTrade: ListItemsByTrade;
  selectDistributor: () => void;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  openAddEditMaterialModal: (type: LineItemTypeEnum) => void;
};

const LineItemTypeDisplayMap = {
  [LineItemTypeEnum.MATERIAL]: 'Materials',
  [LineItemTypeEnum.LABOR]: 'Labor',
  [LineItemTypeEnum.OTHER]: 'Other',
} as const;

export const ProjectScopeTypeListControls: React.FC<
  ProjectScopeTypeListControlsProps & BoxProps
> = ({
  jobId,
  lineItemType,
  selectDistributor,
  selectedListItemIds,
  itemsByTrade,
  openDownloadPdfModal,
  openAddEditMaterialModal,
  ...props
}) => {
  const dispatch = useDispatch();
  const { trackAddItemButton } = useProjectScopeTracker({ jobId });
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isMobile = useIsMobileBreakpoint();

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  const trades = useMemo(() => Object.keys(itemsByTrade), [itemsByTrade]);
  const selectedItemsTotal = useMemo(() => {
    return !selectedListItemIds
      ? 0
      : Object.values(selectedListItemIds).flat().length;
  }, [selectedListItemIds]);

  const itemsByTradeTotal = useMemo(
    () => Object.values(itemsByTrade).flat().length,
    [itemsByTrade],
  );

  const handleToggleSelection = () => {
    dispatch(toggleSelectAllTypeItems(lineItemType, itemsByTrade));
  };

  const handleSaveAsTemplateClick = () => {
    typewriter.linkPressed({
      link_text: 'Save as template',
      page_or_screen_name: EventNames.project.scope.saveAsTemplatePressed,
      primary_cta: true,
      feature: 'material-list',
      link_location: 'project scope',
      ...commonTrackingProps,
    });
  };

  return (
    <Box
      id="ProjectScope-controls"
      alignItems="baseline"
      padding={{ base: 400, tablet: 500 }}
      paddingX={500}
      flexDirection="row"
      justifyContent="space-between"
      backgroundColor="neutral0"
      {...props}
    >
      <Box alignItems="center">
        {!isMobile && (
          <Box width="48px" paddingLeft={3} paddingRight={1}>
            <Checkbox
              value={`${lineItemType}`}
              aria-label={lineItemType}
              id={lineItemType}
              key={lineItemType}
              data-test-id="checkboxLineItemTypeGroup"
              flex="0"
              isChecked={selectedItemsTotal === itemsByTradeTotal}
              isIndeterminate={
                selectedItemsTotal > 0 && selectedItemsTotal < itemsByTradeTotal
              }
              onChange={handleToggleSelection}
            />
          </Box>
        )}

        <Heading
          marginBottom={0}
          paddingLeft={{ base: 0, tablet: 1 }}
          size={400}
        >
          {LineItemTypeDisplayMap[lineItemType]}
        </Heading>

        <Box color="neutral.500" lineHeight={400} marginLeft={400}>
          {selectedItemsTotal === 0 && (
            <Body as="span" color="neutral.500">
              Select materials to order or download as PDF
            </Body>
          )}

          {!isMobile && selectedItemsTotal > 0 && (
            <ListControlTotals
              lineItemType={lineItemType}
              trades={trades}
              selectedListItemIds={selectedListItemIds}
            />
          )}
        </Box>
      </Box>

      <Box>
        {!isMobile && !materialListFeatureEnabled && (
          <>
            {lineItemType === LineItemTypeEnum.MATERIAL && (
              <Button
                data-test-id="ProjectScope-Actions-Order"
                label="Order materials"
                onClick={() => {
                  typewriter.buttonPressed({
                    page_or_screen_name:
                      EventNames.project.scope.orderMaterialsButtonPressed,
                    button_text: 'Order materials',
                    primary_cta: false,
                    ...commonTrackingProps,
                  });
                  selectDistributor();
                }}
                isDisabled={
                  isNil(selectedListItemIds) || selectedItemsTotal === 0
                }
              >
                Order materials
              </Button>
            )}
            <Button
              data-test-id="ProjectScope-Actions-PDF"
              label="Download PDF"
              fill="outline"
              marginLeft={400}
              onClick={() => openDownloadPdfModal(lineItemType)}
              isDisabled={
                isNil(selectedListItemIds) || selectedItemsTotal === 0
              }
            >
              <Icon icon={iDownload} />
              Download PDF
            </Button>
          </>
        )}

        {!isMobile && materialListFeatureEnabled && (
          <Link
            data-test-id="ProjectScope-Actions-SaveAsTemplate"
            colorScheme="primary"
            href="https://hover.qualtrics.com/jfe/form/SV_6mQ0ziNKq6ORDi6?Q_CHL"
            target="_blank"
            onClick={handleSaveAsTemplateClick}
          >
            Save as template
          </Link>
        )}

        {!isMobile && (
          <Button
            data-test-id="ProjectScope-Actions-AddItem"
            label={`Add ${lineItemType.toLowerCase()}`}
            aria-label={`add-${lineItemType}-button`}
            fill="outline"
            marginLeft={400}
            onClick={() => {
              trackAddItemButton(lineItemType);
              openAddEditMaterialModal(lineItemType);
            }}
          >
            <Icon icon={iPlus} />
            {`Add ${lineItemType.toLowerCase()}`}
          </Button>
        )}
      </Box>
    </Box>
  );
};
