import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const pageReducer = createReducer(initialState)
  .handleAction(
    estimatorActions.storePages,
    (state, { payload: { pages, isManualMeasurementsNeeded } }) => ({
      ...state,
      pages: {
        ...state.pages,
        pages,
        isManualMeasurementsNeeded,
      },
    }),
  )
  .handleAction(estimatorActions.shouldRenderPage, (state, action) => {
    const {
      pages: { pages },
    } = state;
    const newPages = [...pages];
    const { pageIndex, shouldRender } = action.payload;
    const page = newPages[pageIndex];
    if (!page) {
      return {
        ...state,
      };
    }
    page.shouldRender = shouldRender;

    return {
      ...state,
      pages: {
        ...state.pages,
        pages: newPages,
      },
    };
  });
