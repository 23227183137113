import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { RootState, RootAction } from 'src/types/reduxStore';

import * as EstimatorProductionActions from '../../../redux/actions';
import {
  getItemsSortedByCategoriesAndVendors,
  getVendorsPerCategoryAlphabetized,
} from '../../../redux/selectors/estimatorProductionSelectors';
import { TableItemType, VendorType } from '../../../types';
import { Container } from './styled';
import { Table } from './Table';

export const mapStateToProps = (state: RootState) => ({
  vendors: state.estimatorProductionTools.vendors,
  itemsByCategoryAndVendor: getItemsSortedByCategoriesAndVendors(state),
  vendorsPerCategoryAlphabetized: getVendorsPerCategoryAlphabetized(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateListItem: EstimatorProductionActions.updateListItem,
    },
    dispatch,
  );

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class Tables extends PureComponent<ComponentProps> {
  findVendorWithDistributor(items, vendorsWithDistributor): VendorType[] {
    const { vendor } = items;
    if (!vendor?.id) return;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    return vendorsWithDistributor.find((v) => v.id === vendor.id);
  }

  public render() {
    const {
      updateListItem,
      vendors,
      itemsByCategoryAndVendor: { materialItems, laborItems, otherItems },
      vendorsPerCategoryAlphabetized: {
        materialItems: sortedVendorsMaterial,
        laborItems: sortedVendorsLabor,
        otherItems: sortedVendorsOther,
      },
    } = this.props;

    return (
      <Container>
        {sortedVendorsMaterial.map(
          (vendorName: string): React.ReactNode => (
            <Table
              data-test-id="trade-table"
              key={`${LineItemTypeEnum.MATERIAL}-${vendorName}`}
              materials={materialItems[vendorName] as TableItemType}
              vendor={this.findVendorWithDistributor(
                materialItems[vendorName],
                vendors,
              )}
              updateListItem={updateListItem}
              vendors={vendors || []}
              tableType={LineItemTypeEnum.MATERIAL}
            />
          ),
        )}
        {sortedVendorsLabor.map(
          (vendorName: string): React.ReactNode => (
            <Table
              data-test-id="trade-table"
              key={`${LineItemTypeEnum.LABOR}-${vendorName}`}
              materials={laborItems[vendorName] as TableItemType}
              updateListItem={updateListItem}
              vendors={vendors || []}
              vendor={this.findVendorWithDistributor(
                laborItems[vendorName],
                vendors,
              )}
              tableType={LineItemTypeEnum.LABOR}
            />
          ),
        )}
        {sortedVendorsOther.map(
          (vendorName: string): React.ReactNode => (
            <Table
              data-test-id="trade-table"
              key={`${LineItemTypeEnum.OTHER}-${vendorName}`}
              materials={otherItems[vendorName] as TableItemType}
              updateListItem={updateListItem}
              vendors={vendors || []}
              vendor={this.findVendorWithDistributor(
                otherItems[vendorName],
                vendors,
              )}
              tableType={LineItemTypeEnum.OTHER}
            />
          ),
        )}
      </Container>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(Tables);
