/**
 * A pretty complex function with casts due to typescript. Original JS implementaion here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals.
 * @param strings The template that constructs the string that will be returned from the function
 * @param keys a list of strings created by the template which are contained in ${}
 * @returns A function that constructs a string, taking in a hash of key:value pairs to replace keys that are placed in the template string.
 */
function template(strings: TemplateStringsArray, ...keys: Array<string>) {
  return (values: Record<string, string | number>) => {
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = values[key];
      result.push(value.toString(), strings[i + 1]);
    });
    return result.join('');
  };
}

export const messages = {
  projectScope: {
    errors: {
      query: {
        // GQL query errors.
        productionList: {
          // GQL query errors for productionList.
          getProductionList:
            'Sorry, there was a problem fetching your production list.',
          getInspectionChecklist:
            'Sorry, there was a problem fetching your checklist.',
          orderCheck: 'Sorry, there was a problem with your order.',
          orderCheckCreate: 'Sorry, there was a problem creating your order',
          distributorCapabilities:
            'Sorry, there was a problem fetching your distributor data.',
          getDistributionOrder:
            'Sorry, there was a problem fetching your distribution order.',
          getProduct: 'Sorry, there was a problem fetching your product.',
          getDistributorVariationsAttributes:
            "Sorry, there was a problem fetching the distributor's variations.",
          getDistributionApprovedBranches:
            "Sorry, there was a problem fetching the distributor's approved branches.",
          projectManagementOrderDocumentCreate:
            'Sorry, there was a problem creating the order document.',
          projectOrderDocuments:
            'Sorry, there was a problem downloading order PDF document',
          estimatesCannotBeChangedforSold:
            'Estimates selection cannot be changed for sold opportunity',
          update: 'Sorry, there was a problem updating your production list.',
          estimateQuantityUnits:
            'Sorry, there was a problem fetching units of measure',
          estimateTemplates: 'Sorry, there was a problem fetching templates.',
          orgConfig:
            'Sorry, there was a problem while getting the organizations config.',
          estimateGroup:
            'Sorry, there was a problem getting estimate group for this project.',
        },
      },
      mutation: {
        productionList: {
          estimateGroup:
            'Sorry, there was a problem creating an estimate group.',
          orgConfigSetup:
            'Sorry, there was a problem creating the organizations config.',
        },
      },
    },
    orderConfirmation: {
      messages: {
        heading: template`Order sent to ${'displayName'}`,
        disclaimerText: {
          orderConfirmationSubmitted: template`If there are any discrepancies between your HOVER order and the order confirmation you receive from ${'displayName'}, please contact your ${'displayName'} Sales Rep.`,
          orderConfirmationPendingSubmitted: template`We sent your order to ${'displayName'}. Their branch needs to review and confirm the order manually. We will send you confirmation when ${'displayName'} approves and starts processing the order. If you have any questions, contact your ${'displayName'} Sales Rep and use the submitted order ID as reference.`,
          orderConfirmationCanceled: template`A ${'displayName'} Sales Rep has canceled this order. Contact your ${'displayName'} Sales Rep for more details.`,
        },
      },
    },
  },
  variationMatching: {
    errors: {
      query: {
        settingUpdateFailed:
          'Updating variation matching setting failed. Please try again.',
      },
    },
  },
};
