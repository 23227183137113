import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const checklistReducer = createReducer(initialState).handleAction(
  estimatorActions.storeChecklist,
  (state, action) => {
    return {
      ...state,
      config: {
        ...state.config,
        checklist: action.payload.checklist,
      },
    };
  },
);
