import React from 'react';

import { useMutation } from '@apollo/client';
import { isNil } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { projectManagementOrderDocumentCreate } from 'src/api/types/projectManagementOrderDocumentCreate';
import { projectManagementProductionList_projectManagementProductionList as ProductionList } from 'src/api/types/projectManagementProductionList';
import {
  PdfOutput,
  PdfFooter,
} from 'src/features/project/components/PdfGeneration/PdfOutput';
import type { ListItemIdsByTypeAndTrade } from 'src/features/project/types';
import { ORDER_DOCUMENT_CREATE } from 'src/features/projectManagement/apis/graphql/queries';
import { getUserProfile, getTradeTypesSorted } from 'src/redux/selectors';
import { byLineItemTypeTradeTypeSortOrderUnitCostAndCreatedAtComparator } from 'src/utils/comparators';
import { sentenceCase } from 'src/utils/Formatters';

type createOrderDocumentInput = {
  purchaseOrderNumber: string;
  notes: string;
  productionList: ProductionList;
  listItemIds: ListItemIdsByTypeAndTrade;
  projectManagementOrderId?: string;
  onCompleted?: (data: projectManagementOrderDocumentCreate) => void;
  onError?: () => void;
};

export function useCreateOrderDocument() {
  const userProfile = useSelector(getUserProfile);

  const [orderDocumentCreate, { loading: orderDocumentCreating }] = useMutation(
    ORDER_DOCUMENT_CREATE,
  );

  const tradeTypeEnumsSorted = useSelector(getTradeTypesSorted).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );

  const createOrderDocument = ({
    purchaseOrderNumber,
    notes,
    productionList,
    listItemIds,
    projectManagementOrderId,
    onCompleted,
    onError,
  }: createOrderDocumentInput) => {
    const job = productionList?.estimateGroup?.salesOpportunity?.job;
    const lineItemType = LineItemTypeEnum.MATERIAL;

    const sortedListItems = [...(productionList?.listItems ?? [])].sort(
      byLineItemTypeTradeTypeSortOrderUnitCostAndCreatedAtComparator(
        tradeTypeEnumsSorted,
      ),
    );

    const listItems = sortedListItems?.filter(
      (listItem) =>
        !isNil(listItemIds) &&
        !isNil(lineItemType) &&
        !isNil(listItemIds[lineItemType]) &&
        Object.values(listItemIds[lineItemType])
          .flat()
          .includes(listItem.id.toString()),
    );

    const inputHtml = renderToStaticMarkup(
      React.createElement(PdfOutput, {
        title: !isNil(lineItemType)
          ? `${sentenceCase(lineItemType)} order`
          : 'Order',
        purchaseOrderNumber,
        job,
        lineItemType,
        userProfile,
        listItems,
        shouldShowTotalCost: true,
        shouldShowItemMeasurements: true,
        shouldShowCostDetails: true,
        notes,
      }),
    );

    const footerHtml = renderToStaticMarkup(
      React.createElement(PdfFooter, {
        jobId: job?.id,
      }),
    );

    orderDocumentCreate({
      onCompleted,
      onError,
      variables: {
        listItemIds: listItems?.map((listItem) => listItem.id.toString()),
        purchaseOrderNumber,
        orderDocumentAttributes: {
          footerHtml,
          inputHtml,
          notes,
          productionListId: productionList?.id,
          orderId: projectManagementOrderId,
        },
      },
    });
  };

  return { createOrderDocument, orderDocumentCreating };
}
