import { Box, Body, Loader } from '@hover/blueprint';
import styled from 'styled-components';

const LoadingContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral000};
`;

export const Loading = () => (
  <LoadingContainer
    data-test-id="MaterialListLoading"
    padding={700}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Loader size="large" />
    <Body size={400} margin={0} marginTop={400} color="neutral600">
      Loading materials list
    </Body>
  </LoadingContainer>
);
