import { gql } from '@apollo/client';

// used by TinyMCE's Drive image uploader to sign the image uploads for a particular user/org
export const GENERATE_JWT_TOKEN = gql`
  mutation generateRemoteJWT($orgId: ID!, $remoteService: RemoteService!) {
    generateRemoteJWT(orgId: $orgId, remoteService: $remoteService) {
      jwt
    }
  }
`;
