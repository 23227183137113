import { gql } from '@apollo/client';

export const errorFields = gql`
  fragment errorFields on Error {
    attribute
    errors
  }
`;

export const PROJECT_MANAGEMENT_ORDER_CREATE = gql`
  mutation projectManagementOrderCreate(
    $orderAttributes: ProjectManagementOrderCreateInput!
    $orgId: ID!
  ) {
    projectManagementOrderCreate(
      orderAttributes: $orderAttributes
      orgId: $orgId
    ) {
      errors {
        ...errorFields
      }
      order {
        id
      }
    }
  }
  ${errorFields}
`;

export const PROJECT_MANAGEMENT_ORDER_FULFILLMENT_START = gql`
  mutation projectManagementOrderFulfillmentStart($orderId: ID!) {
    projectManagementOrderFulfillmentStart(orderId: $orderId) {
      errors {
        ...errorFields
      }
      order {
        id
        distributionOrderId
      }
    }
  }
  ${errorFields}
`;

export const DISTRIBUTION_ORDER = gql`
  query distributionOrder($id: ID!) {
    distributionOrder(id: $id) {
      failureReason
      id
      state
      url
      shippingAddressCity
      shippingAddressCountry
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressPostalCode
      shippingAddressState
      purchaseOrderNumber
      notes
      distributor {
        id
        identifier
        displayName
        name
        supportsProductCatalog
        supportsProductOrdering
      }
      orderCheck {
        errors {
          ...errorFields
        }
        lineItems {
          id
          productName
          errors {
            ...errorFields
          }
        }
      }
    }
  }
  ${errorFields}
`;

export const DISTRIBUTION_ORDER_FOR_SUMMARY = gql`
  query distributionOrderForSummary($id: ID!) {
    distributionOrder(id: $id) {
      failureReason
      id
      state
      url
      notes
      shippingAddressCity
      shippingAddressCountry
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressPostalCode
      shippingAddressState
      purchaseOrderNumber
      distributor {
        identifier
        displayName
      }
      branch {
        name
      }
      jobAccount {
        displayName
      }
    }
  }
`;

export const DISTRIBUTION_ORDER_SUBMIT = gql`
  mutation distributionOrderSubmit($distributionOrderId: ID!) {
    distributionOrderSubmit(distributionOrderId: $distributionOrderId) {
      errors {
        ...errorFields
      }
      order {
        id
        distributor {
          id
          identifier
          name
          supportsProductCatalog
          supportsProductOrdering
        }
        failureReason
        state
        url
        notes
        shippingAddressCity
        shippingAddressCountry
        shippingAddressLine1
        shippingAddressLine2
        shippingAddressPostalCode
        shippingAddressState
        purchaseOrderNumber
      }
    }
  }
  ${errorFields}
`;
