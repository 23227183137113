import { Body, Box } from '@hover/blueprint';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import { RootState } from 'src/types';

const mapStateToProps = (state: RootState) => ({
  selectedTemplates: state.exteriorEstimator.inputs.selectedTemplates,
});

type Props = ReturnType<typeof mapStateToProps>;

const TemplateCounterComponent: React.FC<Props> = ({ selectedTemplates }) => {
  if (!selectedTemplates) return null;
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      data-test-id="templateCounter"
    >
      <Body color="neutral.600" margin={0}>{`${
        selectedTemplates.length
      } ${pluralize('template', selectedTemplates.length)}`}</Body>
    </Box>
  );
};

export const TemplateCounter = connect(mapStateToProps)(
  TemplateCounterComponent,
);
