import { createReducer } from 'typesafe-actions';

import { initialState } from 'src/features/exteriorEstimator/redux/initialState';
import { checklistReducer } from 'src/features/exteriorEstimator/redux/reducers/checklistReducer';
import { discountReducer } from 'src/features/exteriorEstimator/redux/reducers/discountReducer';
import { esignReducer } from 'src/features/exteriorEstimator/redux/reducers/esignReducer';
import { estimateGroupReducer } from 'src/features/exteriorEstimator/redux/reducers/estimateGroupReducer';
import { inputCategoriesReducer } from 'src/features/exteriorEstimator/redux/reducers/inputCategoriesReducer';
import { jobReducer } from 'src/features/exteriorEstimator/redux/reducers/jobReducer';
import { measurementsReducer } from 'src/features/exteriorEstimator/redux/reducers/measurementReducer';
import { miscReducer } from 'src/features/exteriorEstimator/redux/reducers/miscReducer';
import { pageReducer } from 'src/features/exteriorEstimator/redux/reducers/pageReducer';
import { proposalReducer } from 'src/features/exteriorEstimator/redux/reducers/proposalReducer';
import { questionsReducer } from 'src/features/exteriorEstimator/redux/reducers/questionsReducer';
import { requiredInputsReducer } from 'src/features/exteriorEstimator/redux/reducers/requiredInputsReducer';
import { templatesReducer } from 'src/features/exteriorEstimator/redux/reducers/templatesReducer';
import { wasteFactorReducer } from 'src/features/exteriorEstimator/redux/reducers/wasteFactorReducer';

export const exteriorEstimatorReducer = createReducer(initialState, {
  ...discountReducer.handlers,
  ...estimateGroupReducer.handlers,
  ...inputCategoriesReducer.handlers,
  ...jobReducer.handlers,
  ...measurementsReducer.handlers,
  ...miscReducer.handlers,
  ...pageReducer.handlers,
  ...questionsReducer.handlers,
  ...requiredInputsReducer.handlers,
  ...templatesReducer.handlers,
  ...proposalReducer.handlers,
  ...esignReducer.handlers,
  ...wasteFactorReducer.handlers,
  ...checklistReducer.handlers,
});
