import { gql } from '@apollo/client';

export const GET_PRODUCT_CATALOG_PRODUCT = gql`
  query productCatalogProduct(
    $id: ID!
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    productCatalogProduct(id: $id) {
      active
      id
      name
      orgVariationsCount(orgId: $orgId)
      variationsCount
      variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
        id
        name
        orgVariation(orgId: $orgId) {
          id
        }
      }
      provider {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCT_CATALOG_PRODUCTS = gql`
  query productCatalogProducts(
    $orgId: ID!
    $after: String
    $filterByOrg: Boolean
  ) {
    productCatalogProducts(
      orgId: $orgId
      after: $after
      filterByOrg: $filterByOrg
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        name
        variationsCount
        productCatalogCategory {
          id
          name
        }
        productCatalogCategories {
          id
          name
        }
        orgVariationsCount(orgId: $orgId)
        provider {
          id
          name
        }
      }
    }
  }
`;

export const PRODUCT_CATALOG_PRODUCT_SEARCH = gql`
  query productCatalogProductSearchMaterialsPage(
    $orgId: ID!
    $searchTerm: String!
    $filterByOrg: Boolean
  ) {
    productCatalogProductSearch(
      orgId: $orgId
      searchTerm: $searchTerm
      filterByOrg: $filterByOrg
    ) {
      id
      name
      variationsCount
      productCatalogCategory {
        name
      }
      productCatalogCategories {
        id
        name
      }
      orgVariationsCount(orgId: $orgId)
      provider {
        id
        name
      }
    }
  }
`;
