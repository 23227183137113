import { createAction, createAsyncAction } from 'typesafe-actions';

import { ProductCatalogDistributorIdentifierEnum } from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { SubOrg } from 'src/types/actionTypes';

export const redirectOauth = createAsyncAction(
  'EHI/REDIRECT_OAUTH_REQUEST',
  'EHI/REDIRECT_OAUTH_SUCCESS',
  'EHI/REDIRECT_OAUTH_FAILURE',
)<ProductCatalogDistributorIdentifierEnum, void, Error>();

export const mobileDownloadPdf = createAsyncAction(
  'EHI/MOBILE_DOWNLOAD_PDF_REQUEST',
  'EHI/MOBILE_DOWNLOAD_PDF_SUCCESS',
  'EHI/MOBILE_DOWNLOAD_PDF_FAILURE',
)<{ name: string; url: string }, void, Error>();

export const getOrgSettings = createAsyncAction(
  'EHI/GET_ORG_SETTINGS_REQUEST',
  'EHI/GET_ORG_SETTINGS_SUCCESS',
  'EHI/GET_ORG_SETTINGS_FAILURE',
)<void, { orgSettings: EhiOrgSettings }, Error>();

export const setTradeTypes = createAction(
  'EHI/SET_TRADE_TYPES',
  (payload: TradeType[]) => payload,
)();

export const getSubOrgs = createAsyncAction(
  'EHI/GET_SUB_ORGS_REQUEST',
  'EHI/GET_SUB_ORGS_SUCCESS',
  'EHI/GET_SUB_ORGS_FAILURE',
)<{ orgId: number }, { orgs: SubOrg[] }, Error>();
