import { Box, Button, Heading, Body } from '@hover/blueprint';

import { DefaultModal } from 'style-guide';

const headerStyle = {
  paddingTop: '0px',
  borderBottom: '0',
};

const closeButtonStyle = {
  fontSize: '1.5rem',
};

type Props = {
  closeCancelModal: () => void;
  navigateToProposalList: () => void;
  shouldShowCancelModal: boolean;
};

export const CancelModal: React.FC<Props> = ({
  shouldShowCancelModal,
  closeCancelModal,
  navigateToProposalList,
}) => {
  return (
    <>
      <DefaultModal
        isOpen={shouldShowCancelModal}
        data-test-id="cancelProposalModal"
        textAlign="left"
        title={<Heading size={500}>Leave section?</Heading>}
        justifyTitle="center"
        headerStyle={headerStyle}
        closeButtonStyle={closeButtonStyle}
        closeButton={closeCancelModal}
      >
        <Body size={400} data-test-id="cancelProposalModalBody">
          Please confirm that you want to leave this page without saving
          changes. Note that this action cannot be undone.
        </Body>
        <Box flexDirection="row" justifyContent="center" height={375}>
          <Button
            onClick={navigateToProposalList}
            marginRight={400}
            color="danger"
            data-test-id="cancelProposalModalConfirm"
          >
            Leave section
          </Button>
          <Button
            onClick={closeCancelModal}
            color="primary"
            fill="outline"
            data-test-id="deleteProposalModalCancel"
          >
            Keep editing section
          </Button>
        </Box>
      </DefaultModal>
    </>
  );
};
