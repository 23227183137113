import { useState } from 'react';

import { Box, Field as _Field, TextInput } from '@hover/blueprint';
import styled from 'styled-components';

import { Dropdown, DropdownProps } from 'style-guide';

// blueprint bug - the Label gets covered when setting backgroundColor on parent
const Field = styled(_Field)`
  z-index: 10;
`;

export interface Props {
  label: string;
  name: 'jobAccount' | 'jobName' | 'PONumber';
  maxLength?: number;
  width?: number;
  initialValue?: string | null;
  type?: 'text' | 'select';
  value?: string | null;
  required?: boolean;
  disabled?: boolean;
  options?: DropdownProps['options'];
  onBlur: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

export const BillingField: React.FC<Props> = ({
  value: _value,
  label,
  name,
  maxLength,
  width = 0.5,
  initialValue,
  type = 'text',
  onBlur,
  required,
  disabled,
  options,
}) => {
  const [value, setValue] = useState(_value ?? initialValue ?? '');
  const [isError, setError] = useState(false);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setError(false);
    setValue(e.target.value);
  };

  const handleBlur = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setError(!!required && !e.target.value);
    onBlur(e);
  };

  const inputProps = {
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    maxLength,
  };

  return (
    <Box width={width}>
      <Field
        flex={1}
        marginRight={500}
        label={label}
        name={name}
        error={isError ? `${label} is required` : undefined}
        disabled={disabled}
      >
        {
          {
            text: <TextInput {...inputProps} />,
            select: (
              <Dropdown
                {...inputProps}
                options={options ?? []}
                sx={{ height: '48px' }} // to match blueprint TextInput
              />
            ),
          }[type]
        }
      </Field>
    </Box>
  );
};
