import { LineItemTypeEnum } from 'src/api/graphql-global-types';

import {
  OrderModalStateTypes,
  OrderStateType,
  OrderDocumentStateType,
} from '../types';

export * from './multiTrades';
export * from './orderDeliveryFields';

export enum PdfModalStates {
  DownloadNew = 'download_new',
  DownloadExisting = 'download_existing',
}

export const OrderModalStates: OrderModalStateTypes = {
  Pristine: 'Pristine',
  Loading: 'Loading',
  GenericError: 'GenericError',
  OrderDetailsForm: 'OrderDetailsForm',
  OrderErrors: 'OrderErrors',
};

export const OrderModalTitles: OrderModalStateTypes = {
  Loading: '',
  GenericError: 'An error has occurred',
  OrderDetailsForm: 'Add order details',
  OrderErrors: 'We found some issues with your order',
};

export const CancelButtonTexts: OrderModalStateTypes = {
  Loading: 'Cancel',
  GenericError: 'Cancel',
  OrderDetailsForm: 'Cancel',
  OrderErrors: 'Cancel',
};

export const ActionButtonTexts: OrderModalStateTypes = {
  Loading: 'Processing…',
  GenericError: 'Try Again',
  OrderDetailsForm: 'Submit order to Beacon Pro+',
  OrderErrors: 'Submit order',
};

export const OrderState: OrderStateType = {
  Queued: 'queued',
  Failed: 'failed',
  Submitted: 'submitted',
  Checking: 'checking',
  CheckSuccess: 'check_success',
  CheckFailed: 'check_failed',
};

export const OrderDocumentState: OrderDocumentStateType = {
  Complete: 'complete',
  Generating: 'generating',
};

export const LineItemTypeToLowercase: { [k in LineItemTypeEnum]: string } = {
  [LineItemTypeEnum.MATERIAL]: `${LineItemTypeEnum.MATERIAL.toLowerCase()}s`,
  [LineItemTypeEnum.LABOR]: LineItemTypeEnum.LABOR.toLowerCase(),
  [LineItemTypeEnum.OTHER]: LineItemTypeEnum.OTHER.toLowerCase(),
};
