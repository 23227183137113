import { useState, SyntheticEvent } from 'react';

import { useMutation } from '@apollo/client';
import {
  Icon,
  Box,
  Button,
  Heading,
  Body,
  LoadingOverlay,
} from '@hover/blueprint';
import { iTrash } from '@hover/icons';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { ToastNotification } from 'src/components/ToastNotification';
import {
  DELETE_PROPOSAL_PAGE,
  GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
} from 'src/features/settings/api/queries/proposals';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { DefaultModal } from 'style-guide';

const headerStyle = {
  paddingTop: '0px',
  borderBottom: '0',
};

const closeButtonStyle = {
  fontSize: '1.5rem',
};

type Props = {
  id: string;
  title: string | null;
};

export const DeleteProposal: React.FC<Props> = ({ id, title = '' }) => {
  const orgId = useSelector(getUserOrgId);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const closeModal = () => {
    setShowError(false);
    setIsModalOpen(false);
  };

  const onError = () => {
    setShowError(true);
  };

  const onCompleted = () => {
    closeModal();
  };

  const [deleteProposal, { loading }] = useMutation(DELETE_PROPOSAL_PAGE, {
    variables: { id },
    onCompleted: ({ projectManagementConfigProposalTemplatePageDelete }) => {
      if (
        projectManagementConfigProposalTemplatePageDelete?.errors &&
        projectManagementConfigProposalTemplatePageDelete.errors?.length > 0
      ) {
        onError();
      } else {
        onCompleted();
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      onError();
    },
    refetchQueries: [
      {
        query: GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
        variables: { orgId },
      },
    ],
  });

  const openModal = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsModalOpen(true);
    typewriter.buttonPressed({
      button_text: 'Open',
      page_or_screen_name: EventNames.settings.proposal.page,
      backend_id_type: title ?? '',
      primary_cta: false,
      ...commonTrackingProps,
    });
    return false;
  };

  const handleDelete = (e: SyntheticEvent) => {
    deleteProposal();

    typewriter.buttonPressed({
      button_text: 'Delete',
      page_or_screen_name: EventNames.settings.proposal.deleteSectionModal,
      backend_id_type: title ?? '',
      primary_cta: true,
      ...commonTrackingProps,
    });
    e.preventDefault();
  };

  const renderDefaultModal = () =>
    isModalOpen ? (
      <DefaultModal
        isOpen={isModalOpen}
        data-test-id="deleteProposalModal"
        textAlign="left"
        title={
          <>
            <ToastNotification
              notification="Something went wrong. Please try again."
              severity="Error"
              show={showError}
            />
            <Heading size={500}>{`Delete ${title} section`}</Heading>
          </>
        }
        justifyTitle="center"
        headerStyle={headerStyle}
        closeButtonStyle={closeButtonStyle}
        closeButton={closeModal}
      >
        <Body size={400} data-test-id="deleteProposalModalBody">
          Please confirm that you want to delete this section. Note that this
          action cannot be reversed.
        </Body>
        <Box flexDirection="row" justifyContent="center" height={375}>
          <Button
            onClick={handleDelete}
            marginRight={400}
            color="danger"
            data-test-id="deleteProposalModalConfirm"
          >
            Delete
          </Button>
          <Button
            onClick={(e: SyntheticEvent) => {
              typewriter.buttonPressed({
                button_text: 'Cancel',
                page_or_screen_name:
                  EventNames.settings.proposal.deleteSectionModal,
                backend_id_type: title ?? '',
                primary_cta: false,
                ...commonTrackingProps,
              });
              closeModal();
              e.preventDefault();
            }}
            color="primary"
            fill="outline"
            data-test-id="deleteProposalModalCancel"
          >
            Cancel
          </Button>
        </Box>
      </DefaultModal>
    ) : null;

  return (
    <>
      <LoadingOverlay isLoading={loading} />
      <Box>
        <Button
          onClick={openModal}
          fill="minimal"
          data-test-id="deleteProposalIcon"
          label="delete-proposal"
        >
          <Icon
            color="neutral300"
            icon={iTrash}
            size="medium"
            style={{ alignSelf: 'center', justifySelf: 'end' }}
            data-test-id="trash-icon"
          />
        </Button>
        {renderDefaultModal()}
      </Box>
    </>
  );
};
