/* eslint-disable camelcase */

import numeral from 'numeral';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  ListItemUpdate as ListItemUpdateParams,
  ListItemCreate as ListItemCreateParams,
} from 'src/api/graphql-global-types';
import {
  projectManagementListItemCreate_projectManagementListItemCreate_listItem as listItemCreate_listItemCreate_listItem,
  projectManagementListItemCreate_projectManagementListItemCreate_listItem_productionList as listItemCreate_listItemCreate_listItem_productionList,
} from 'src/api/types/projectManagementListItemCreate';
import {
  projectManagementListItemUpdate_projectManagementListItemUpdate_listItem,
  projectManagementListItemUpdate_projectManagementListItemUpdate_listItem_productionList as listItemUpdate_listItemUpdate_listItem_productionList,
} from 'src/api/types/projectManagementListItemUpdate';

import {
  GetProductionDataPayload,
  GraphQLAddListItemErrors,
  ReceiveListItemsWithProductsParams,
  ErrorModalType,
} from '../../types';

export const GET_PRODUCTION_DATA = 'ESTIMATOR_PRODUCTION/GET_PRODUCTION_DATA';
export const GET_PRODUCTION_DATA_END =
  'ESTIMATOR_PRODUCTION/GET_PRODUCTION_DATA_END';
export const UPDATE_LIST_ITEM = 'ESTIMATOR_PRODUCTION/UPDATE_LIST_ITEM';
export const UPDATE_LIST_ITEM_END = 'ESTIMATOR_PRODUCTION/UPDATE_LIST_ITEM_END';
export const CREATE_LIST_ITEM = 'ESTIMATOR_PRODUCTION/CREATE_LIST_ITEM';
export const CREATE_LIST_ITEM_END = 'ESTIMATOR_PRODUCTION/CREATE_LIST_ITEM_END';
export const API_ERROR = 'ESTIMATOR_PRODUCTION/API_ERROR';
export const TOGGLE_SHOW_ADD_LIST_ITEM_MODAL =
  'ESTIMATOR_PRODUCTION/TOGGLE_SHOW_ADD_LIST_ITEM_MODAL';
export const UPDATE_ADD_LIST_ITEM_TOAST =
  'ESTIMATOR_PRODUCTION/UPDATE_ADD_LIST_ITEM_TOAST';
export const TOGGLE_SHOW_SPINNER = 'ESTIMATOR_PRODUCTION/TOGGLE_SHOW_SPINNER';
export const RECEIVE_ADD_LIST_ITEM_ERRORS =
  'ESTIMATOR_PRODUCTION/RECEIVE_ADD_LIST_ITEM_ERRORS';
export const CLEAR_ADD_LIST_ITEM_ERRORS =
  'ESTIMATOR_PRODUCTION/CLEAR_ADD_LIST_ITEM_ERRORS';
export const HYDRATE_LIST_ITEMS_WITH_PRODUCTS =
  'ESTIMATOR_PRODUCTION/HYDRATE_LIST_ITEMS_WITH_PRODUCTS';
export const MAP_PRODUCTS_TO_LIST_ITEMS =
  'ESTIMATOR_PRODUCTION/MAP_PRODUCTS_TO_LIST_ITEMS';
export const RECEIVE_LIST_ITEMS_WITH_PRODUCTS =
  'ESTIMATOR_PRODUCTION/RECEIVE_LIST_ITEMS_WITH_PRODUCTS';

const numberizeAmount = (amount: string | number) => numeral(amount).value();

export interface GetProductionDataEndAction {
  payload: GetProductionDataPayload;
  type: typeof GET_PRODUCTION_DATA_END;
}

export const getProductionData = createAction(GET_PRODUCTION_DATA)();

export const initProduction = createAsyncAction(
  'EHI/INIT_PRODUCTION_START',
  'EHI/INIT_PRODUCTION_SUCCESS',
  'EHI/INIT_PRODUCTION_FAILURE',
)<void, void, Error>();

export const getProductionDataEnd = createAction(
  GET_PRODUCTION_DATA_END,
  (payload: GetProductionDataPayload) => payload,
)();

export const updateListItem = createAction(
  UPDATE_LIST_ITEM,
  (listItemId: number, params: ListItemUpdateParams) => {
    const newParams: ListItemUpdateParams = params;
    if (params.unitCost) {
      newParams.unitCost = numberizeAmount(params.unitCost);
    }
    if (params.quantity) {
      newParams.quantity = Number(params.quantity);
    }

    return {
      listItemId,
      params: newParams,
    };
  },
)();

export const updateListItemEnd = createAction(
  UPDATE_LIST_ITEM_END,
  ({
    listItem,
    productionList,
  }: {
    listItem: projectManagementListItemUpdate_projectManagementListItemUpdate_listItem;
    productionList?: listItemUpdate_listItemUpdate_listItem_productionList;
  }) => ({ listItem, productionList }),
)();

export const createListItem = createAction(
  CREATE_LIST_ITEM,
  (productionListId: number, params: ListItemCreateParams) => {
    const newParams: ListItemCreateParams = params;
    if (params.unitCost) {
      newParams.unitCost = Number(params.unitCost);
    }
    if (params.quantity) {
      newParams.quantity = Number(params.quantity);
    }
    return {
      productionListId,
      params: newParams,
    };
  },
)();

export const createListItemEnd = createAction(
  CREATE_LIST_ITEM_END,
  ({
    listItem,
    productionList,
  }: {
    listItem: listItemCreate_listItemCreate_listItem;
    productionList: listItemCreate_listItemCreate_listItem_productionList;
  }) => ({ listItem, productionList }),
)();

export const sagaError = createAction(API_ERROR, (error: string) => error)();

export const toggleShouldShowAddListItemModal = createAction(
  TOGGLE_SHOW_ADD_LIST_ITEM_MODAL,
  (shouldShowAddListItemModal: boolean) => ({ shouldShowAddListItemModal }),
)();

export const updateAddListItemToast = createAction(
  UPDATE_ADD_LIST_ITEM_TOAST,
  (addListItemToastText: string, shouldShowAddListItemToast: boolean) => ({
    addListItemToastText,
    shouldShowAddListItemToast,
  }),
)();

export const toggleShouldShowSpinner = createAction(
  TOGGLE_SHOW_SPINNER,
  (shouldShowSpinner: boolean) => ({ shouldShowSpinner }),
)();

export const receiveAddListItemErrors = createAction(
  RECEIVE_ADD_LIST_ITEM_ERRORS,
  (addListItemErrors: GraphQLAddListItemErrors) => ({ addListItemErrors }),
)();

export const clearAddListItemErrors = createAction(
  CLEAR_ADD_LIST_ITEM_ERRORS,
  () => ({}),
)();

export const receiveListItemsWithProducts = createAction(
  RECEIVE_LIST_ITEMS_WITH_PRODUCTS,
  (payload: ReceiveListItemsWithProductsParams) => payload,
)();

export const UPDATE_TRADE_FILTER = 'ESTIMATOR_PRODUCTION/UPDATE_TRADE_FILTER';
export const updateTradeFilter = createAction(
  UPDATE_TRADE_FILTER,
  (payload: string) => payload,
)();

export const UPDATE_ERROR_MODAL = `ESTIMATOR_PRODUCTION/UPDATE_ERROR_MODAL`;
export const updateErrorModal = createAction(
  UPDATE_ERROR_MODAL,
  (payload: ErrorModalType) => payload,
)();
