import { PureComponent } from 'react';

import { Body, Box } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  ProductCatalogDistributorIdentifierEnum,
  LineItemTypeEnum,
} from 'src/api/graphql-global-types';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { withTypewriter } from 'src/components/WithTypewriter';
import { ESTIMATOR_PRODUCTION } from 'src/features/exteriorEstimator/constants/urls';
import { OrderButton } from 'src/features/projectManagement/components/ProductionView/BottomBar/OrderButton';
import { OrderLink } from 'src/features/projectManagement/components/ProductionView/BottomBar/OrderLink';
import { LineItemTypeToLowercase } from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import * as orderActions from 'src/features/projectManagement/redux/actions/orderActions';
import {
  getParams,
  getJobDetails,
  getSubmittedOrders,
} from 'src/features/projectManagement/redux/selectors';
import { VendorType } from 'src/features/projectManagement/types';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

import {
  StyledButton,
  BottomBarWrapper,
  Section,
  PricingDetailsContent,
  TaxText,
  SubTotalText,
  TotalText,
} from './BottomBarStyled';

interface BottomBarProps {
  type: LineItemTypeEnum;
  salesTax?: number;
  subTotal?: number;
  pretaxCost: number;
  salesTaxPercent?: number;
  vendor?: VendorType;
  isSubmitOrderDisabled: boolean;
  isColorSelectionValid: boolean;
  isColorAvailableValid: boolean;
  typewriter: any;
}

export const mapStateToProps = (state: RootState) => ({
  estimateGroupId: getParams(state).estimateGroupId,
  orgId: getParams(state).orgId,
  jobId: getParams(state).jobId,
  jobDetails: getJobDetails(state),
  getOrders: (vendorName: string) => getSubmittedOrders(state, vendorName),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setVendorForOrder: actions.setVendorForOrder,
      toggleOrderModal: actions.toggleOrderModal,
      togglePdf: actions.togglePdfModal,
      pushRoute: push,
      clearOrderDetailsForm: orderActions.clearOrderDetailsForm,
    },
    dispatch,
  );

// ColorError and ColorUnavailable are components for displaying "aggregate" validation
// errors; when an error occurs in any item of the corresponding collection.
const ColorError: React.FC<{ isColorSelectionValid: boolean }> = ({
  isColorSelectionValid,
}) => {
  return isColorSelectionValid ? null : (
    <Body size={300} margin={0} marginRight={500} color="danger500">
      Select a color or variation for each order item
    </Body>
  );
};

const ColorUnavailable: React.FC<{ isColorAvailableValid: boolean }> = ({
  isColorAvailableValid,
}) => {
  return isColorAvailableValid ? null : (
    <Body size={300} margin={0} marginRight={500} color="danger500">
      Some colors are unavailable; select from available colors
    </Body>
  );
};

type Props = BottomBarProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export class BottomBarComponent extends PureComponent<Props> {
  @autobind
  renderSubTotal() {
    const { subTotal, salesTax } = this.props;

    if (!salesTax) return null;
    return (
      <PricingDetailsContent>
        <SubTotalText>Subtotal</SubTotalText>
        <SubTotalText data-test-id="bottomBarSubtotal">
          {subTotal && <FormattedNumber value={subTotal} format="$0,0.00" />}
        </SubTotalText>
      </PricingDetailsContent>
    );
  }

  @autobind
  renderTax() {
    const { salesTax, salesTaxPercent: _salesTaxPercent } = this.props;

    if (!salesTax) return null;
    const salesTaxPercent = Number(_salesTaxPercent).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2,
    });
    return (
      <PricingDetailsContent>
        <TaxText>Sales Tax ({salesTaxPercent})</TaxText>
        <TaxText data-test-id="bottomBarTax">
          <FormattedNumber value={salesTax} format="$0,0.00" />
        </TaxText>
      </PricingDetailsContent>
    );
  }

  renderTotal() {
    const { type, pretaxCost } = this.props;

    return (
      <PricingDetailsContent>
        <TotalText>Total Cost</TotalText>
        <TotalText
          data-test-id={`bottomBarTotal${LineItemTypeToLowercase[type]}`}
        >
          <FormattedNumber value={pretaxCost} format="$0,0.00" />
        </TotalText>
      </PricingDetailsContent>
    );
  }

  @autobind
  startAgnosticOrderFlow() {
    const {
      orgId,
      jobId,
      pushRoute,
      setVendorForOrder,
      vendor,
      clearOrderDetailsForm,
    } = this.props;
    clearOrderDetailsForm();
    setVendorForOrder({ vendor });
    pushRoute(
      `${ESTIMATOR_PRODUCTION}/order/checkout?jobId=${jobId}&orgId=${orgId}`,
    );
  }

  @autobind
  handleClickPdfButton() {
    const { togglePdf, type, vendor, jobId, commonProps, typewriter } =
      this.props;
    togglePdf({ show: true, type, vendor });
    typewriter.buttonPressed({
      button_text: 'Download Pdf',
      job_id: Number(jobId),
      page_or_screen_name: EventNames.pmp.page,
      primary_cta: false,
      ...commonProps,
    });
  }

  getVendorIdentifier() {
    const { vendor } = this.props;
    if (!vendor) return null;
    return vendor.distributor?.identifier;
  }

  pdfButton() {
    const { vendor } = this.props;

    return vendor ? (
      <StyledButton
        shape="box"
        data-test-id="pmpGeneratePDF"
        onClick={this.handleClickPdfButton}
      >
        Download
      </StyledButton>
    ) : (
      <div />
    );
  }

  conditionallyRenderTotalDisclaimer() {
    const identifier = this.getVendorIdentifier();
    if (identifier !== ProductCatalogDistributorIdentifierEnum.SRS) return null;
    return (
      <Body size={300} color="neutral500">
        Pricing subject to change before invoicing
      </Body>
    );
  }

  render() {
    const {
      vendor,
      type,
      isSubmitOrderDisabled,
      isColorSelectionValid,
      isColorAvailableValid,
    } = this.props;

    return (
      <BottomBarWrapper data-test-id="pmp-table-bottomBar">
        <Box flexDirection="column" alignItems="flex-end">
          <Section>
            {this.renderSubTotal()}
            {this.renderTax()}
            {this.renderTotal()}
            {this.conditionallyRenderTotalDisclaimer()}
          </Section>
          <Box alignItems="center" minHeight="40px">
            <Box flexDirection="column" alignItems="flex-end">
              <ColorError isColorSelectionValid={isColorSelectionValid} />
              <ColorUnavailable isColorAvailableValid={isColorAvailableValid} />
              <OrderLink vendor={vendor} type={type} />
            </Box>
            <OrderButton
              handleClick={this.startAgnosticOrderFlow}
              type={type}
              vendor={vendor}
              isSubmitOrderDisabled={isSubmitOrderDisabled}
            />
            {this.pdfButton()}
          </Box>
        </Box>
      </BottomBarWrapper>
    );
  }
}

export const BottomBar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(BottomBarComponent));
