import { useState } from 'react';

import { Body, Box, Button, Panel } from '@hover/blueprint';

import {
  DragDropContext,
  DroppableBox,
  DraggableBox,
  DropResult,
} from 'src/components';

import { TemplateGroupTable } from './TemplateGroupTable';
import type { DisplayedTemplateGroup } from './types';

type TemplateGroupsProps = {
  groups: DisplayedTemplateGroup[];
  onCreate: () => void;
  onDelete: (group: DisplayedTemplateGroup) => void;
  onEdit: (group: DisplayedTemplateGroup) => void;
  onDragEnd: (dropResult: DropResult) => void;
};

export const TemplateGroups: React.FC<TemplateGroupsProps> = ({
  groups,
  onCreate,
  onDelete,
  onEdit,
  onDragEnd,
}) => {
  const [isReorderingGroups, setIsReorderingGroups] = useState<boolean>(false);

  const onReorderingEnd = (dropResult: DropResult) => {
    onDragEnd(dropResult);
    setIsReorderingGroups(false);
  };

  const onReorderingStart = () => {
    setIsReorderingGroups(true);
  };

  return (
    <Panel
      backgroundColor="neutral.0"
      padding={500}
      boxShadow="distance500"
      marginBottom={200}
      data-test-id="namedTemplateGroups"
    >
      <Box flexDirection="column">
        {groups.length === 0 ? (
          <Box>
            <Body size={500} color="neutral400">
              &ldquo;Create template group&rdquo; allows you to set up a custom
              arrangement for customer-facing documents. This arrangement has no
              impact on accuracy or calculation.
            </Body>
          </Box>
        ) : (
          <DragDropContext
            onDragEnd={onReorderingEnd}
            onBeforeCapture={onReorderingStart}
          >
            <DroppableBox droppableId="templateGroups" flexDirection="column">
              {groups.map((group: DisplayedTemplateGroup, index) => (
                <DraggableBox
                  key={group.sortOrder}
                  draggableId={group.sortOrder.toString()}
                  index={index}
                >
                  <TemplateGroupTable
                    group={group}
                    collapsed={isReorderingGroups}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </DraggableBox>
              ))}
            </DroppableBox>
          </DragDropContext>
        )}
        <Box>
          <Button
            data-test-id="createTemplateGroupButton"
            fill="outline"
            onClick={onCreate}
          >
            Create template group
          </Button>
        </Box>
      </Box>
    </Panel>
  );
};
