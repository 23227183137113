import { Body, Box, Button, Heading, Icon } from '@hover/blueprint';
import { hClipboardCheck, iBarChart } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { FormattedNumber } from 'src/components/FormattedNumber';
import { EstimateProposalsButton } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateProposalsButton';
import {
  getTotalActiveEstimatesMonthlyCost,
  getJobDetails,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { useTracking } from 'src/hooks';
import {
  getUserTrackingProps,
  isUserLightWeightFlow,
  shouldShowEstimateLineItemDetailsToggle,
} from 'src/redux/selectors';
import { EventNames } from 'src/types';

type Props = {
  total: number;
  shouldShowEstimatePrices: boolean;
  onClickEstimatePriceToggle: () => void;
  toggleProposalModalView: (view: ProposalViews) => void;
  hideTotalPrice: boolean;
};

export const Overview: React.FC<Props> = ({
  total,
  onClickEstimatePriceToggle,
  shouldShowEstimatePrices,
  toggleProposalModalView,
  hideTotalPrice,
}) => {
  const monthlySum = useSelector(getTotalActiveEstimatesMonthlyCost);

  const isLightWeightFlow = useSelector(isUserLightWeightFlow);
  const job = useSelector(getJobDetails);
  const isShowDetailsToggleEnabled = useSelector(
    shouldShowEstimateLineItemDetailsToggle,
  );
  const history = useHistory();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const { estimateGroupId } = useParams();

  const shouldShowMonthlyCost = monthlySum && monthlySum !== 0;

  const goToSummaryPage = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: job?.id,
      button_text: 'Summary Icon',
      primary_cta: true,
      backend_id_value: estimateGroupId,
      backend_id_type: 'estimate_group_id',
      button_location: 'Top-right',
      ...commonTrackingProps,
    });

    history.push({
      pathname: `/estimator/estimates/${estimateGroupId}/summary`,
      search: history.location.search,
      state: {
        prevPath: `${history.location.pathname}${history.location.search}`,
      },
    });
  };

  return !isLightWeightFlow ? (
    <Box
      flexDirection="column"
      data-test-id="estimate-group-overview-container"
    >
      <Box
        width={1}
        alignItems="flex-start"
        justifyContent="space-between"
        padding={400}
      >
        <Box flexDirection="column" height={1}>
          <Heading size={500} color="neutral.700" marginBottom={0}>
            {!hideTotalPrice && (
              <FormattedNumber
                data-test-id="totalPrice"
                value={total}
                format="$0,0.00"
              />
            )}
          </Heading>
          {shouldShowMonthlyCost && monthlySum && !hideTotalPrice && (
            <Body
              color="neutral.600"
              size={300}
              margin="0"
              data-test-id="monthlyPrice"
            >
              as low as <FormattedNumber value={monthlySum} format="$0,0.00" />
              /mo
            </Body>
          )}
        </Box>
        <Box justifyContent="space-between" alignItems="center">
          <Button
            shape="square"
            fill="minimal"
            onClick={goToSummaryPage}
            data-test-id="SummaryNavIcon"
            marginRight={500}
            label="SummaryNavIcon"
          >
            <Icon icon={hClipboardCheck} color="primary600" size="medium" />
          </Button>
          {isShowDetailsToggleEnabled && (
            <Button
              onClick={onClickEstimatePriceToggle}
              data-test-id="sales-insights-icon"
              fill={shouldShowEstimatePrices ? 'solid' : 'minimal'}
              shape="square"
              label="sales-insights-icon"
            >
              <Icon icon={iBarChart} size="medium" />
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <EstimateProposalsButton
          toggleProposalModalView={toggleProposalModalView}
        />
      </Box>
    </Box>
  ) : null;
};
