import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Body, Box, Button, Heading, Field, Textarea } from '@hover/blueprint';
import { isEmpty } from 'lodash';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { projectManagementConfigScopeOfWorkSettingsUpdate as ScopeOfWorkResp } from 'src/api/types/projectManagementConfigScopeOfWorkSettingsUpdate';
import { UPDATE_PROPOSAL_SCOPE_OF_WORK } from 'src/features/settings/api/queries/proposals';
import { CancelModal } from 'src/features/settings/components/Proposal/Section/CancelModal';
import { ElementVisibilityPreviewModal } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/ElementVisibilityPreviewModal';
import { SectionHeaderPanel } from 'src/features/settings/components/Proposal/Section/SectionHeaderPanel';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { SectionPanel } from './components';
import { DefaultElementVisibilityPanel } from './DefaultElementVisibilityPanel';
import { TeamMemberControls } from './TeamMemberControls';

type Props = {
  showOrgLogo: boolean;
  showTitle: boolean;
  title: string;
  includeAllEstimateItemsDefault: boolean;
  includeItemCostDefault: boolean;
  includeItemQuantitiesDefault: boolean;
  includeTotalPriceDefault: boolean;
  includeTradePriceDefault: boolean;
  allowDefaultOverrideIncludeAllEstimateItems: boolean;
  allowDefaultOverrideIncludeItemCost: boolean;
  allowDefaultOverrideIncludeItemQuantities: boolean;
  allowDefaultOverrideIncludeTotalPrice: boolean;
  allowDefaultOverrideIncludeTradePrice: boolean;
  scopeOfWorkDisclaimer: string | null;
  setSuccessNotification: () => void;
  setFailureNotification: () => void;
  navigateToProposalList: () => void;
};

export const ScopeOfWork: React.FC<Props> = ({
  title,
  showTitle,
  showOrgLogo,
  includeAllEstimateItemsDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
  includeTotalPriceDefault,
  includeTradePriceDefault,
  allowDefaultOverrideIncludeAllEstimateItems,
  allowDefaultOverrideIncludeItemCost,
  allowDefaultOverrideIncludeItemQuantities,
  allowDefaultOverrideIncludeTotalPrice,
  allowDefaultOverrideIncludeTradePrice,
  scopeOfWorkDisclaimer,
  setSuccessNotification,
  setFailureNotification,
  navigateToProposalList,
}) => {
  const { sectionId } = useParams();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const {
    formState,
    formState: { errors, isDirty },
    getValues,
    setValue,
    control,
    register,
    handleSubmit,
    ...methods
  } = useForm({
    defaultValues: {
      sectionTitle: title,
      appliesTo: 'ALL',
      showBrandingOnHeader: showOrgLogo,
      showSectionTitle: showTitle,
      includeAllEstimateItemsDefault,
      includeItemCostDefault,
      includeItemQuantitiesDefault,
      includeTotalPriceDefault,
      includeTradePriceDefault,
      allowDefaultOverrideIncludeAllEstimateItems,
      allowDefaultOverrideIncludeItemCost,
      allowDefaultOverrideIncludeItemQuantities,
      allowDefaultOverrideIncludeTotalPrice,
      allowDefaultOverrideIncludeTradePrice,
      scopeOfWorkDisclaimer,
    },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const formIncludeTotalPriceDefault = getValues('includeTotalPriceDefault');
  const formIncludeTradePriceDefault = getValues('includeTradePriceDefault');
  const formIncludeAllEstimateItemsDefault = getValues(
    'includeAllEstimateItemsDefault',
  );
  const formIncludeItemCostDefault = getValues('includeItemCostDefault');
  const formIncludeItemQuantitiesDefault = getValues(
    'includeItemQuantitiesDefault',
  );

  const onError = () => {
    setFailureNotification();
  };

  const onCompletedScopeOfWork = ({
    projectManagementConfigScopeOfWorkSettingsUpdate,
  }: ScopeOfWorkResp) => {
    if (
      projectManagementConfigScopeOfWorkSettingsUpdate?.errors?.length &&
      projectManagementConfigScopeOfWorkSettingsUpdate.errors.length > 0
    ) {
      setFailureNotification();
    } else {
      setSuccessNotification();
      navigateToProposalList();
    }
  };

  const [updateScopeOfWork] = useMutation(UPDATE_PROPOSAL_SCOPE_OF_WORK, {
    onCompleted: onCompletedScopeOfWork,
    onError,
  });

  const [
    showDefaultElementVisibilityPreviewImageModal,
    setShowDefaultElementVisibilityPreviewImageModal,
  ] = useState(false);

  const handleShowDefaultElementVisibilityPreviewImageModal = () => {
    setShowDefaultElementVisibilityPreviewImageModal(true);
  };

  const onSubmit = (data: FieldValues) => {
    const orgProposalSettingAttributes = {
      allowDefaultOverrideIncludeAllEstimateItems:
        data.allowDefaultOverrideIncludeAllEstimateItems,
      allowDefaultOverrideIncludeItemCost:
        data.allowDefaultOverrideIncludeItemCost,
      allowDefaultOverrideIncludeItemQuantities:
        data.allowDefaultOverrideIncludeItemQuantities,
      allowDefaultOverrideIncludeTotalPrice:
        data.allowDefaultOverrideIncludeTotalPrice,
      allowDefaultOverrideIncludeTradePrice:
        data.allowDefaultOverrideIncludeTradePrice,
      includeAllEstimateItemsDefault: data.includeAllEstimateItemsDefault,
      includeItemCostDefault: data.includeItemCostDefault,
      includeItemQuantitiesDefault: data.includeItemQuantitiesDefault,
      includeTotalPriceDefault: data.includeTotalPriceDefault,
      includeTradePriceDefault: data.includeTradePriceDefault,
      scopeOfWorkDisclaimer: data.scopeOfWorkDisclaimer,
    };

    typewriter.buttonPressed({
      button_text: 'Save',
      page_or_screen_name: EventNames.settings.section.scopeOfWorkPage,
      primary_cta: true,
      ...commonTrackingProps,
    });

    const options = {
      variables: {
        proposalTemplatePageId: sectionId,
        proposalTemplatePageAttributes: {
          showOrgLogo: data.showBrandingOnHeader,
          showTitle: data.showSectionTitle,
          title: data.sectionTitle,
          tradeType: 'ALL',
        },
        orgProposalSettingAttributes,
      },
    };

    updateScopeOfWork(options);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: boolean) => void,
    block: string,
    label: string,
  ) => {
    onChange(e.target.checked);
    typewriter.checkboxSelected({
      selection: e.target.checked.toString(),
      options: label,
      page_or_screen_name: EventNames.settings.section.scopeOfWorkPage,
      ...commonTrackingProps,
    });
  };

  const handleCancelPressed = () => {
    typewriter.buttonPressed({
      button_text: 'Cancel',
      page_or_screen_name: EventNames.settings.section.scopeOfWorkPage,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.section.scopeOfWorkPage,
      ...commonTrackingProps,
    });
  });

  const [shouldShowCancelModal, setShouldShowCancelModal] = useState(false);
  const openCancelModal = () => {
    setShouldShowCancelModal(true);
    handleCancelPressed();
  };
  const closeCancelModal = () => setShouldShowCancelModal(false);
  const cancel = () => {
    return isDirty ? openCancelModal() : navigateToProposalList();
  };
  return (
    <>
      <CancelModal
        navigateToProposalList={navigateToProposalList}
        shouldShowCancelModal={shouldShowCancelModal}
        closeCancelModal={closeCancelModal}
      />
      <FormProvider
        {...{
          formState,
          getValues,
          setValue,
          control,
          register,
          handleSubmit,
          ...methods,
        }}
      >
        <Box
          as="form"
          flexDirection="column"
          maxWidth={1020}
          onSubmit={handleSubmit(onSubmit, (er) => console.log(er))}
        >
          <Heading
            data-test-id="proposalScopeOfWorkSectionHeading"
            my={500}
            size={650}
          >
            {title}
          </Heading>
          <Box flexDirection="column" overflowY="auto" maxHeight="70vh">
            <SectionPanel>
              <SectionHeaderPanel
                tradeOptions={null}
                page={EventNames.settings.section.scopeOfWorkPage}
              />
            </SectionPanel>

            <DefaultElementVisibilityPanel
              handleCheckboxChange={handleCheckboxChange}
              handleShowDefaultElementVisibilityPreviewImageModal={
                handleShowDefaultElementVisibilityPreviewImageModal
              }
              includeTradePriceDefaultInitialValue={includeTradePriceDefault}
              includeItemCostDefaultInitialValue={includeItemCostDefault}
              includeItemQuantitiesDefaultInitialValue={
                includeItemQuantitiesDefault
              }
            />
            <TeamMemberControls
              handleCheckboxChange={handleCheckboxChange}
              allowDefaultOverrideIncludeTradePriceInitialValue={
                allowDefaultOverrideIncludeTradePrice
              }
              allowDefaultOverrideIncludeItemCostInitialValue={
                allowDefaultOverrideIncludeItemCost
              }
              allowDefaultOverrideIncludeItemQuantitiesInitialValue={
                allowDefaultOverrideIncludeItemQuantities
              }
            />
            <SectionPanel
              headingText="Disclaimer note"
              subHeadingText="Apply a note to all generated proposals, such as coverage of
unexpected costs."
            >
              <Field
                data-test-id="scopeOfWorkDisclaimer"
                label="Note text"
                name="scopeOfWorkDisclaimer"
              >
                <Textarea
                  data-test-id="scopeOfWorkDisclaimer"
                  resize="vertical"
                  {...register('scopeOfWorkDisclaimer')}
                  placeholder="E.g. additional charges for hidden damages..."
                />
              </Field>
            </SectionPanel>
            <SectionPanel
              headingText="Customer Signature"
              data-test-id="signatureNotification"
            >
              <Body color="neutral600" size={300}>
                This section contains an eSignature spot for the Scope of Work
                and eInitial spot for the selected project colors, which the
                homeowner will digitally sign if someone in your team requests a
                signature via email or performs an onscreen signature.
              </Body>
            </SectionPanel>
          </Box>
          <Box justifyContent="space-between" marginTop={300}>
            <Box>
              <Button
                isDisabled={!isDirty || !isEmpty(errors)}
                marginRight={400}
                data-test-id="saveButton"
                type="submit"
              >
                Save
              </Button>
              <Button
                onClick={cancel}
                type="button"
                fill="outline"
                data-test-id="scopeOfWorkCancelButton"
              >
                Cancel
              </Button>
            </Box>
          </Box>
          <ElementVisibilityPreviewModal
            showDefaultElementVisibilityPreviewImageModal={
              showDefaultElementVisibilityPreviewImageModal
            }
            setShowDefaultElementVisibilityPreviewImageModal={
              setShowDefaultElementVisibilityPreviewImageModal
            }
            includeTotalPriceDefault={formIncludeTotalPriceDefault}
            includeTradePriceDefault={formIncludeTradePriceDefault}
            includeItemQuantitiesDefault={formIncludeItemQuantitiesDefault}
            includeItemCostDefault={formIncludeItemCostDefault}
            includeAllEstimateItemsDefault={formIncludeAllEstimateItemsDefault}
          />
        </Box>
      </FormProvider>
    </>
  );
};
