import { createSelector } from 'reselect';

import { RootState } from 'src/types/reduxStore';

import {
  getQuestionResponses,
  getMeasurementQuestions,
} from './estimatorSelectors';

/* 
  TODO: clean up the estimatorSelectors file and move
  all the other measurements selectors in here
*/

export const getJobMeasurements = (state: RootState) =>
  state.exteriorEstimator.job.jobMeasurements;

export const getGeometry = (state: RootState): object | null =>
  state.exteriorEstimator.job.geometry;

export const getMetadata = (state: RootState): object | null =>
  state.exteriorEstimator.job.metadata;

export const getMeasurementQuestionsAndResponses = createSelector(
  getMeasurementQuestions,
  getQuestionResponses,
  (questions, responses) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return questions.map((question: any) => ({
      ...question,
      value: responses[question.id],
    }));
  },
);

export const getPredictedWasteFactorRoof = (state: RootState) =>
  state.exteriorEstimator.wasteFactor.predictedWasteFactorRoof;

export const getCappedPredictedWasteFactorRoof = createSelector(
  getPredictedWasteFactorRoof,
  (predictedWasteFactorRoof) => {
    if (predictedWasteFactorRoof === undefined) return undefined;
    // set max recommendation to 50 - https://hoverinc.atlassian.net/browse/EHI-2948
    return predictedWasteFactorRoof > 0.5 ? 0.5 : predictedWasteFactorRoof;
  },
);

export const predictedWasteFactorRoofPercent = createSelector(
  getCappedPredictedWasteFactorRoof,
  (cappedPredictedWasteFactorRoof) =>
    cappedPredictedWasteFactorRoof === undefined
      ? undefined
      : parseInt((cappedPredictedWasteFactorRoof * 100).toFixed(0), 10),
);

export const getIsPredictedWasteFactorRoofValid = createSelector(
  getPredictedWasteFactorRoof,
  (predictedWasteFactorRoof) =>
    predictedWasteFactorRoof === 0 || !!predictedWasteFactorRoof,
);
