import { LoaderSpinner } from 'src/components/LoaderSpinner';

import Wrapper from '../Wrapper';
import type { WrapperProps } from '../Wrapper';

const cssProps = {
  width: '100%',
  height: '100%',
};

export const Loading: React.FunctionComponent<WrapperProps> = (props) => {
  return (
    <Wrapper {...props}>
      <LoaderSpinner data-test-id="orderCheckLoader" show {...cssProps} />
    </Wrapper>
  );
};
