import { EstimationDiscountDiscountTypeEnum } from 'src/api/graphql-global-types';

interface EstimateFields {
  basePrice: number | null;
  discounts:
    | { discountType: EstimationDiscountDiscountTypeEnum; value: number }[]
    | null;
}

export const estimateTotalWithDiscounts = (estimate: EstimateFields) => {
  const originalTotal = estimate?.basePrice ?? 0;
  let estimateTotal = originalTotal;

  if ((estimate?.discounts?.length ?? 0) > 0) {
    estimate?.discounts?.forEach((discount) => {
      let discountTotal;
      if (discount.discountType === 'DOLLARS') {
        discountTotal = Number(discount.value);
      } else {
        discountTotal = (discount.value / 100.0) * originalTotal;
      }
      estimateTotal -= discountTotal;
    });
  }
  return Math.max(estimateTotal, 0);
};

export const adjustedMeasurementWithWasteFactor = (
  measurement: number,
  wasteFactor: number,
) => {
  return measurement * (1 + wasteFactor);
};

export const normalizedWasteFactor = (wasteFactor: number | null) => {
  let wf = Number(wasteFactor);
  // LineItems store wasteFactor as an integer, ListItems stores it as a decimal. Always use the decimal representation.
  if (wf % 1 === 0) wf /= 100.0;
  return wf;
};

export const wasteFactorDisplay = (wasteFactor: number | null) => {
  const wasteFactorNumber = normalizedWasteFactor(wasteFactor);
  return Number(wasteFactorNumber).toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
  });
};
