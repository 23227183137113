/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { EHIHoverHeader as HoverHeader } from 'src/components/EHIHoverHeader';
import { getSuborgs } from 'src/features/estimatesAccess/components/utils/getSuborgs';
import { useTracking } from 'src/hooks';
import { getSubOrgs as getSubOrgsAction } from 'src/redux/actions';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { Grid } from './GridStyled';
import { EstimatesHeader as Header } from './Header';
import { JobsList } from './JobsList';

const ReactHeaderContainer = styled.div`
  box-sizing: content-box;
`;

const Container = styled.div`
  margin: 66px auto 0 auto;
  ${Grid}
`;

const EstimatesAccess: React.FC = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(getUserOrgId);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(Number(orgId));

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.saveAndAccess.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  useEffect(() => {
    async function fetchSuborgs() {
      const orgs = await getSuborgs(Number(orgId));
      if (orgs) dispatch(getSubOrgsAction.success({ orgs }));
    }
    if (orgId) {
      try {
        fetchSuborgs();
      } catch (error) {
        dispatch(getSubOrgsAction.failure(error as any));
      }
    }
  }, [dispatch, orgId]);

  return (
    <ReactHeaderContainer>
      {/* @ts-ignore */}
      <HoverHeader fixed>
        <Container>
          <Header
            setSearchQuery={setSearchQuery}
            setSelectedOrgId={setSelectedOrgId}
          />
          <JobsList selectedOrgId={selectedOrgId} searchQuery={searchQuery} />
        </Container>
      </HoverHeader>
    </ReactHeaderContainer>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EstimatesAccess;
