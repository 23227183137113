import { Icon, Panel, Box, Heading } from '@hover/blueprint';
import { iChevronRight } from '@hover/icons';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  CHECKOUT_PATH,
  SUMMARY_PATH,
} from 'src/features/exteriorEstimator/constants/urls';

import { HeadingStateColorType } from './types';

const HeadingStateColor: HeadingStateColorType = {
  past: 'primary500',
  current: 'primary900',
  upcoming: 'secondary300',
};

const HeadingBorder = styled(Heading)`
  padding: 4px 0px 20px 0px;
  border-bottom: 3px solid black;
`;

const getBillingDeliveryColor = (pathname: string) =>
  pathname === CHECKOUT_PATH
    ? HeadingStateColor.current
    : HeadingStateColor.past;

const getOrderConfirmationColor = (pathname: string) =>
  pathname === SUMMARY_PATH
    ? HeadingStateColor.current
    : HeadingStateColor.upcoming;

export const Navbar = () => {
  const location = useLocation();

  return (
    <Panel
      data-test-id="navbar-panel"
      height={70}
      width="100vw"
      boxShadow="distance500"
      justifyContent="center"
    >
      <Box justifyContent="center" alignItems="center">
        <Heading
          data-test-id="order-heading"
          marginX={500}
          marginTop={100}
          size={200}
          color={getBillingDeliveryColor(location.pathname)}
        >
          Order
        </Heading>
        <Icon icon={iChevronRight} color="secondary300" />
        <HeadingBorder
          data-test-id="billingDelivery-heading"
          margin={500}
          size={200}
          color={getBillingDeliveryColor(location.pathname)}
        >
          Billing & delivery
        </HeadingBorder>
        <Icon icon={iChevronRight} color="secondary300" />
        <Heading
          data-test-id="orderConfirmation-heading"
          marginX={500}
          marginTop={100}
          size={200}
          color={getOrderConfirmationColor(location.pathname)}
        >
          Order confirmation
        </Heading>
      </Box>
    </Panel>
  );
};
