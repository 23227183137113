import React, { useEffect, useCallback, useState } from 'react';

import {
  Button,
  Modal,
  Heading,
  Body,
  Spinner,
  Box,
  Hide,
  Flex,
  Link,
} from '@hover/blueprint';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  EstimationConfigTemplateCollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { GET_TEMPLATE_COLLECTIONS } from 'src/api/queries/queries';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { messages } from 'src/constants/messages';
import {
  getSelectedTemplateIds,
  getTemplateSearchFilter,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  useLazyQueryEhi,
  useToastEhi,
  ToastStatusEnum,
  useMaterialListTemplates,
  useTracking,
} from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getOrgIdParam } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { EmptySavedTemplatesDisclaimer } from '../../../../../components/MaterialList/EmptySavedTemplatesDisclaimer';
import { SearchFilterInput } from '../../../../../components/MaterialList/SearchFilterInput';
import { TemplateCardsSection } from '../../../../../components/MaterialList/TemplateCardsSection';

/**
 * API Toast error messaging enum IDs
 */
const enum TOAST_IDS {
  GET_TEMPLATE_COLLECTIONS_ERROR_TOAST,
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TemplateSelectionModalContent = styled.div`
  max-width: 720px;
  height: 95vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const TemplateSelectionModalHeader = styled.div`
  opacity: 0.9;
  flex: 0 0 auto;
`;

const TemplateSelectionModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 auto;
  padding-top: 8px;
`;

export const TemplateSelectionModal = ({ isOpen, onClose }: Props) => {
  const orgId = useSelector(getOrgIdParam);
  const selectedTemplatesIds = useSelector(getSelectedTemplateIds) || [];
  const templateSearchFilter = useSelector(getTemplateSearchFilter);
  const {
    dispatchTemplatesFromSections,
    toggleTemplateAndUnselectOthersInSameTrade,
  } = useMaterialListTemplates();
  const toast = useToastEhi();

  const isMobile = useIsMobileBreakpoint();

  const [templateCollections, setTemplateCollections] = useState<
    TemplateSection[]
  >([]);
  const [isClosingModal, setIsClosingModal] = useState<boolean>(false);

  /* Tracking events */
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  /* Template data fetching */
  const [fetchTemplateCollections, { data, loading }] = useLazyQueryEhi(
    GET_TEMPLATE_COLLECTIONS,
    {
      variables: {
        orgId,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        toast({
          id: TOAST_IDS.GET_TEMPLATE_COLLECTIONS_ERROR_TOAST,
          description:
            messages.projectScope.errors.query.productionList.estimateTemplates,
          status: ToastStatusEnum.ERROR,
        });
      },
      onCompleted: (completedData) => {
        const {
          estimationConfigTemplateCollectionsForOrg: { templateSections },
        } = completedData;
        dispatchTemplatesFromSections(templateSections);
        setTemplateCollections(templateSections);
      },
    },
  );

  const handleCardClick = useCallback(
    (template: Template, templateTradeType: TradeTypeEnum) => {
      toggleTemplateAndUnselectOthersInSameTrade(
        template.id,
        templateTradeType,
      );

      typewriter.buttonPressed({
        page_or_screen_name:
          EventNames.project.scope.templateSelectModal.selection,
        button_text: template.name || '',
        primary_cta: true,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    },
    [templateCollections, selectedTemplatesIds],
  );

  const handleCalculateMaterialListClick = () => {
    setIsClosingModal(true);
    onClose();

    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.confirm,
      button_text: 'Calculate material list',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.page,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    setIsClosingModal(false);
    fetchTemplateCollections();
  }, [isOpen]);

  const showLoadingSpinner = loading || isClosingModal;

  /* Rendering */
  return (
    <Modal
      isOpen={isOpen}
      isCentered
      isClosable={false}
      onClose={() => false}
      size={isMobile ? 'full' : 'large'}
    >
      {showLoadingSpinner ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="huge" data-test-id="TemplateSelectionModal-Loader" />
        </Box>
      ) : (
        <TemplateSelectionModalContent data-test-id="TemplateSelectionModal-Content">
          <TemplateSelectionModalHeader>
            <Hide below="tablet">
              <Body fontWeight={700} size={200}>
                MATERIAL LIST
              </Body>
            </Hide>

            <Box display="flex">
              <Heading marginBottom={0} marginRight={800} whiteSpace="nowrap">
                Select Template
              </Heading>
              <Hide below="tablet">
                <SearchFilterInput />
              </Hide>
            </Box>
          </TemplateSelectionModalHeader>

          <Flex
            flexDirection="column"
            flex="1 1 auto"
            position="relative"
            overflowY="scroll"
          >
            {templateCollections.map((section: TemplateSection) => {
              return section.collectionType ===
                EstimationConfigTemplateCollectionTypeEnum.CUSTOM &&
                section.templatesByTradeType.length <= 0 ? (
                <EmptySavedTemplatesDisclaimer key="empty-saved-template-disclaimer" />
              ) : (
                <TemplateCardsSection
                  templateCollection={section}
                  key={`${section.name}-template-cards-section`}
                  handleCardClick={handleCardClick}
                  searchFilterValue={templateSearchFilter}
                />
              );
            })}
          </Flex>

          <TemplateSelectionModalFooter>
            <Link
              href="https://hover.qualtrics.com/jfe/form/SV_e9GaWLzCJ7lrP6K"
              target="_blank"
            >
              Need another template?
            </Link>
            <Button
              isDisabled={!selectedTemplatesIds.length}
              height={{ base: '44px', tablet: '48px' }}
              onClick={handleCalculateMaterialListClick}
            >
              Calculate <Hide below="tablet">material list</Hide>
            </Button>
          </TemplateSelectionModalFooter>
        </TemplateSelectionModalContent>
      )}
    </Modal>
  );
};
