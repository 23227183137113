import { gql } from '@apollo/client';
/*
  Same endpoint is being hit in exterior estimator but each have different
  requirements so rather than extending the estimator call labeling this
  for self service and being explicit to this flow.
 */
export const pagesFields = gql`
  fragment pagesFields on ProjectManagementConfigProposalTemplatePage {
    content
    id
    pageType
    showOrgLogo
    showTitle
    sortOrder
    title
    tradeType
  }
`;

export const proposalSettingsFields = gql`
  fragment proposalSettingsFields on ProjectManagementConfigOrgProposalSetting {
    allowDefaultOverrideIncludeAllEstimateItems
    allowDefaultOverrideIncludeItemCost
    allowDefaultOverrideIncludeItemQuantities
    allowDefaultOverrideIncludeTotalPrice
    allowDefaultOverrideIncludeTradePrice
    id
    includeAllEstimateItemsDefault
    includeItemCostDefault
    includeItemQuantitiesDefault
    includeTotalPriceDefault
    includeTradePriceDefault
    includeTotalPriceDefault
    maxSigneeCount
    orgId
    proposalsEnabled
    scopeOfWorkDisclaimer
    defaultNote
  }
`;

export const GET_PROPOSAL_SETTING = gql`
  query projectManagementConfigOrgProposalSettingSelfService($orgId: ID!) {
    projectManagementConfigOrgProposalSetting(orgId: $orgId) {
      ...proposalSettingsFields
    }
  }
  ${proposalSettingsFields}
`;

export const GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE = gql`
  query projectManagementConfigProposalTemplate($orgId: ID!) {
    projectManagementConfigProposalTemplate(orgId: $orgId) {
      pages {
        ...pagesFields
      }
    }
  }
  ${pagesFields}
`;

export const UPDATE_PROPOSAL_SETTING = gql`
  mutation projectManagementConfigOrgProposalSettingUpdate(
    $orgId: ID!
    $attributes: ProjectManagementConfigOrgProposalSettingUpdate!
  ) {
    projectManagementConfigOrgProposalSettingUpdate(
      orgId: $orgId
      attributes: $attributes
    ) {
      errors {
        attribute
        errors
      }
      orgProposalSetting {
        ...proposalSettingsFields
      }
    }
  }
  ${proposalSettingsFields}
`;

export const GET_TEMPLATES_AND_PROPOSAL_PAGES = gql`
  query proposalPagesAndTemplates($orgId: ID!, $after: String) {
    estimationConfigTemplates(orgId: $orgId, after: $after, first: 20) {
      nodes {
        id
        name
        orgId
        tradeType
        sortOrder
      }
      edges {
        node {
          id
          name
          orgId
          tradeType
          sortOrder
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    projectManagementConfigProposalTemplate(orgId: $orgId) {
      pages {
        ...pagesFields
      }
    }
  }
  ${pagesFields}
`;

export const UPDATE_PROPOSAL_PAGES_SORT_ORDER = gql`
  mutation projectManagementConfigProposalTemplatesPagesSortOrderUpdate(
    $orgId: ID!
    $proposalTemplatePageIds: [ID!]!
  ) {
    projectManagementConfigProposalTemplatesPagesSortOrderUpdate(
      orgId: $orgId
      proposalTemplatePageIds: $proposalTemplatePageIds
    ) {
      proposalTemplate {
        pages {
          ...pagesFields
        }
      }
    }
  }
  ${pagesFields}
`;

export const UPDATE_PROPOSAL_SCOPE_OF_WORK = gql`
  mutation projectManagementConfigScopeOfWorkSettingsUpdate(
    $proposalTemplatePageId: ID!
    $orgProposalSettingAttributes: ProjectManagementConfigOrgProposalSettingUpdate!
    $proposalTemplatePageAttributes: ProjectManagementConfigProposalTemplatePageCreateInput!
  ) {
    projectManagementConfigScopeOfWorkSettingsUpdate(
      proposalTemplatePageId: $proposalTemplatePageId
      orgProposalSettingAttributes: $orgProposalSettingAttributes
      proposalTemplatePageAttributes: $proposalTemplatePageAttributes
    ) {
      errors {
        attribute
        errors
      }
      orgProposalSetting {
        ...proposalSettingsFields
      }
      proposalTemplatePage {
        ...pagesFields
      }
    }
  }
  ${pagesFields}
  ${proposalSettingsFields}
`;

export const CREATE_PROPOSAL_PAGE = gql`
  mutation projectManagementConfigProposalTemplatePageCreate(
    $orgId: ID!
    $proposalTemplatePageAttributes: ProjectManagementConfigProposalTemplatePageCreateInput!
  ) {
    projectManagementConfigProposalTemplatePageCreate(
      orgId: $orgId
      proposalTemplatePageAttributes: $proposalTemplatePageAttributes
    ) {
      errors {
        attribute
        errors
      }
      proposalTemplatePage {
        ...pagesFields
      }
    }
  }
  ${pagesFields}
`;

export const UPDATE_PROPOSAL_PAGE = gql`
  mutation projectManagementConfigProposalTemplatePageUpdate(
    $id: ID!
    $proposalTemplatePageAttributes: ProjectManagementConfigProposalTemplatePageCreateInput!
  ) {
    projectManagementConfigProposalTemplatePageUpdate(
      id: $id
      attributes: $proposalTemplatePageAttributes
    ) {
      errors {
        attribute
        errors
      }
      proposalTemplatePage {
        ...pagesFields
      }
    }
  }
  ${pagesFields}
`;

export const DELETE_PROPOSAL_PAGE = gql`
  mutation projectManagementConfigProposalTemplatePageDelete($id: ID!) {
    projectManagementConfigProposalTemplatePageDelete(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;

// Proposal preview
export const CREATE_PROPOSAL_PREVIEW = gql`
  mutation projectManagementProposalDocumentPreviewCreate($orgId: ID!) {
    projectManagementProposalDocumentPreviewCreate(orgId: $orgId) {
      errors {
        attribute
        errors
      }
      proposalDocumentPreview {
        failureReason
        id
        state
      }
    }
  }
`;

export const GET_PROPOSAL_PREVIEW = gql`
  query projectManagementProposalDocumentPreview($id: ID!) {
    projectManagementProposalDocumentPreview(id: $id) {
      failureReason
      id
      pdf {
        redirectUrl
        filename
      }
      state
    }
  }
`;
