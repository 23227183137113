import { Link, Tab, Box, Body } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import { eventNameCurrentPage } from 'src/features/settings/utils/generic';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

interface Props {
  url: string;
  label: string | React.ReactNode;
  index: number;
  setIndex: (index: number) => void;
  isNew?: boolean;
}
export const SidebarItem: React.FC<Props> = ({
  url,
  label,
  index,
  setIndex,
  isNew = false,
}) => {
  const location = useLocation();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isActive = () => {
    return window.location.href.includes(url);
  };

  if (isActive()) {
    setIndex(index);
  }

  const handleClick = () => {
    typewriter.linkPressed({
      link_text: label?.toString() ?? '',
      page_or_screen_name: eventNameCurrentPage(location),
      primary_cta: false,
      link_location: 'Sidebar',
      ...commonTrackingProps,
    });
  };

  return (
    <Tab>
      <Box flexDirection="row" alignItems="center" textAlign="left">
        <Link
          as={RouterLink}
          marginY="2px"
          textDecoration="none"
          fontWeight="body"
          color={isActive() ? 'primary600' : 'neutral600'}
          _hover={{ textDecoration: 'none', color: 'primary600' }}
          flex="0 0 auto"
          to={url}
          onClick={handleClick}
        >
          {label}
        </Link>
        {isNew && (
          <Box
            backgroundColor="warning.500"
            paddingX={200}
            marginLeft={200}
            color="primary.900"
            borderRadius="3px"
          >
            <Body lineHeight="0" marginBottom="1rem" fontSize="15px">
              NEW
            </Body>
          </Box>
        )}
      </Box>
    </Tab>
  );
};
