import { Body, Box, Icon, Tooltip } from '@hover/blueprint';
import { iHelpCircle } from '@hover/icons';

import theme from 'src/lib/theme';

interface Props {
  isProductFilteringEnabled: boolean;
}

export const VariantsFiltering: React.FC<Props> = ({
  isProductFilteringEnabled,
}) => {
  return (
    <Box alignItems="center" data-test-id="VariantsFilteringIndicator">
      <Body size={400} color="neutral600" margin={0} marginRight={100}>
        <strong data-test-id="variantsFilteringStatus">
          Variants filtering{' '}
          {isProductFilteringEnabled ? 'enabled' : 'disabled'}
        </strong>
      </Body>
      <Tooltip
        color={theme.colors.neutral000}
        hasArrow
        placement="top-end"
        mb={1}
        label={
          <Box
            data-test-id="questionMarkIconTooltip"
            style={{ fontSize: '0.875rem', padding: '16px 8px' }}
          >
            Your team can access enabled products while using estimation and
            ordering
          </Box>
        }
      >
        <Icon
          data-test-id="questionMarkIcon"
          icon={iHelpCircle}
          color="neutral600"
        />
      </Tooltip>
    </Box>
  );
};
