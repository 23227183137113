export const DEFAULT_TOOLBAR = [
  ['formatselect'],
  ['bold', 'italic'],
  ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
  ['image'],
  ['bullist', 'numlist', 'outdent', 'indent'],
  ['removeformat'],
];

// if explicitly passed `toolbar` arg use, otherwise merge `DEFAULT_TOOLBAR` with `additionalToolbarItems`
export const getToolbar = ({
  toolbar,
  additionalToolbarItems = [],
}: {
  toolbar?: string[][] | undefined;
  additionalToolbarItems?: string[][] | undefined;
}) => {
  const reducer = (acc = '', currentValue: string[]) => {
    if (acc) {
      return `${acc} | ${currentValue.join(' ')}`;
    }
    return currentValue.join(' ');
  };

  if (toolbar) {
    return toolbar.reduce(reducer, '');
  }

  return [...DEFAULT_TOOLBAR, ...additionalToolbarItems].reduce(reducer, '');
};

export const DEFAULT_PLUGINS = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'tinydrive',
  'searchreplace',
  'visualblocks',
];

// if explicitly passed `plugins` use, otherwise merge `addtionalPlugins` with `DEFAULT_PLUGINS`
export const getPlugins = ({
  plugins,
  additionalPlugins = [],
}: {
  plugins?: string | string[] | undefined;
  additionalPlugins?: string[];
}) => {
  if (plugins) {
    return plugins;
  }
  return [...DEFAULT_PLUGINS, ...additionalPlugins];
};
