import { Box } from '@hover/blueprint';
import styled from 'styled-components';

export interface StepperStep {
  text: string;
  completed: boolean;
}

interface Props {
  steps: StepperStep[];
}
const Circle = styled.div<{ completed: boolean }>`
  background-color: ${({ theme }) => theme.colors.neutral000};
  border: 6px solid
    ${({ theme, completed }) =>
      completed ? theme.colors.primary600 : theme.colors.neutral500};
  border-radius: 100%;
  width: 20px; /* +6 for border */
  height: 20px;
  display: inline-block;
`;

const Line = styled.div<{ completed: boolean }>`
  border-left: 3px solid
    ${({ theme, completed }) =>
      completed ? theme.colors.primary600 : theme.colors.neutral500};
  width: 5px;
  height: 40px;
  display: flex;
  align-self: center;
`;

const Label = styled.div<{ completed: boolean }>`
  color: ${({ theme, completed }) =>
    completed ? theme.colors.secondary900 : theme.colors.neutral500};
  font-weight: ${({ completed }) => (completed ? 'bold' : 'normal')};
`;

/* Renders a vertical step progress indicator. Will render the steps in props, in order,
   and visually indicate if a step is completed or not.
   
   IMPORTANT: This component does not attempt to reconcile any non-sensical step ordering.
   If you pass in a series of steps like: [
     {
       text: "step 1",
       completed: true,
     },
     {
       text: "step 2",
       completed: false,
     },
     {
       text: "step 3",
       completed: true,
     }
   ]

   ...then that is that it will render. 
*/
const VerticalStepper: React.FC<Props> = (props: Props) => {
  const { steps } = props;
  const first = steps[0];
  return (
    <div>
      {steps.map((step: StepperStep) => (
        <Box flexDirection="row" key={step.text}>
          <Box flexDirection="column">
            {step !== first && (
              <Line completed={step.completed} data-test-id="stepper-line" />
            )}
            <Circle
              completed={step.completed}
              data-test-id={
                step.completed ? 'stepper-circle-completed' : 'stepper-circle'
              }
            />
          </Box>
          <Box ml={200} flexDirection="column-reverse">
            <Label completed={step.completed}>{step.text}</Label>
          </Box>
        </Box>
      ))}
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default VerticalStepper;
