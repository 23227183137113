import { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Field,
  TextInput,
} from '@hover/blueprint';
import { iEdit2 } from '@hover/icons';

import { UPDATE_TEMPLATE } from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';

interface TemplateNameEditProps {
  name: string;
  templateId: string;
}

export const TemplateNameEdit = ({
  name,
  templateId,
}: TemplateNameEditProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState(name);

  const [updateTemplate, { loading }] = useMutation(UPDATE_TEMPLATE, {
    variables: {
      id: templateId,
      checklistTemplateAttributes: { name: templateName },
    },
    refetchQueries: [
      {
        query: GET_INSPECTION_CHECKLIST_TEMPLATE,
        variables: { id: templateId },
      },
    ],
  });

  const hasError = templateName === '';

  return (
    <Box>
      <IconButton
        label="EditName"
        color="neutral"
        icon={iEdit2}
        fill="minimal"
        ml={200}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header="Edit template name"
        footer={
          <>
            <Button
              fill="outline"
              size="large"
              onClick={() => {
                setTemplateName(name);
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              isLoading={loading}
              isDisabled={hasError}
              onClick={async () => {
                await updateTemplate();
                setIsModalOpen(false);
              }}
            >
              Edit
            </Button>
          </>
        }
      >
        <Field label="Template Name (required)" name="template_name">
          <TextInput
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
          />
        </Field>
      </Modal>
    </Box>
  );
};
