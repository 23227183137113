import { createAction, createAsyncAction } from 'typesafe-actions';

import { estimateFields as EstimateFields } from 'src/api/types/estimateFields';
import { JobDetails } from 'src/features/exteriorEstimator/types';

export const getJobDetails = createAsyncAction(
  'EXTERIOR_ESTIMATOR/GET_JOB_DETAILS_REQUEST',
  'EXTERIOR_ESTIMATOR/GET_JOB_DETAILS_SUCCESS',
  'EXTERIOR_ESTIMATOR/GET_JOB_DETAILS_FAILURE',
)<void, { jobDetails: JobDetails }, Error>();

export const fetchEstimate = createAsyncAction(
  'EXTERIOR_ESTIMATOR/FETCH_ESTIMATE_REQUEST',
  'EXTERIOR_ESTIMATOR/FETCH_ESTIMATE_SUCCESS',
  'EXTERIOR_ESTIMATOR/FETCH_ESTIMATE_FAILURE',
)<
  { estimateId?: number },
  { estimates: EstimateFields[]; selectedEstimateId: number },
  { error: Error }
>();

export const receiveEstimate = createAction(
  'EXTERIOR_ESTIMATOR/RECEIVE_ESTIMATE',
  ({ estimate }: { estimate: EstimateFields }) => ({ estimate }),
)();
