import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';

export const areAnyTemplatesSelected = (
  theseTemplates: Template[],
  selectedTemplateIds: number[] | null,
) => {
  if (!selectedTemplateIds) return false;
  return theseTemplates.some(
    (template) =>
      selectedTemplateIds && selectedTemplateIds.includes(template.id),
  );
};
