import { maxBy } from 'lodash';

import { EstimationConfigTemplateUpdateInput } from 'src/api/graphql-global-types';
import type { TemplateType } from 'src/features/settings/components/Templates/TemplateEdit/types';

export const buildEstimationConfigTemplateUpdateInput = (
  template: TemplateType,
) => {
  const maxSortOrder =
    maxBy(
      template.templateGroups.filter((g) => !g.defaultGroup),
      (group) => group.sortOrder,
    )?.sortOrder ?? -1;

  const estimationConfigTemplateUpdateInput: EstimationConfigTemplateUpdateInput =
    {
      name: template.name,
      active: template.active,
      templateGroupsAttributes: template.templateGroups.map((group) => {
        return {
          ...(group.defaultGroup && { id: group.id }),
          description: group.description,
          name: group.name,
          sortOrder:
            group.defaultGroup && maxSortOrder
              ? maxSortOrder + 1
              : group.sortOrder,
          lineItemIds: group.lineItems
            ? group.lineItems?.map((lineItem) => lineItem?.id?.toString())
            : [],
        };
      }),
    };
  return estimationConfigTemplateUpdateInput;
};
