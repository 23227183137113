import { Body, Flex } from '@hover/blueprint';
import moment from 'moment';
import { useSelector } from 'react-redux';

import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import { SectionHeading } from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { getTradeTypesSorted } from 'src/redux/selectors';
import { sentenceCase } from 'src/utils/Formatters';

export interface MobileProjectBoxProps {
  estimateGroup: EstimateGroup;
}

export function MobileProjectBox({ estimateGroup }: MobileProjectBoxProps) {
  const tradeTypesSorted = useSelector(getTradeTypesSorted);

  const templates = estimateGroup?.estimates
    .filter((estimate) => estimate.active)
    .map((estimate) => estimate.template);

  const date = moment(estimateGroup?.createdAt).format('M/D/YYYY');

  return (
    <Flex
      flexDirection="column"
      borderRadius={12}
      backgroundColor="white"
      padding="19px 16px 16px"
      width="175px"
      flex={1}
    >
      <SectionHeading
        color="neutral.600"
        marginBottom={300}
        textTransform="uppercase"
        fontSize="200"
      >
        Scope
      </SectionHeading>
      <Flex flexDirection="column" data-test-id="templates">
        {tradeTypesSorted?.map((tradeType) => {
          const templateOfTradeType = templates?.find(
            (template) => template?.tradeType === tradeType.tradeTypeEnumValue,
          );
          return templateOfTradeType ? (
            <Flex
              flexDirection="column"
              marginBottom={200}
              key={tradeType.tradeTypeEnumValue}
            >
              <Body
                key={templateOfTradeType?.tradeType}
                size={500}
                margin={0}
                fontSize="200"
              >
                {sentenceCase(templateOfTradeType?.tradeType ?? '')}:
              </Body>
              <Body
                key={templateOfTradeType?.name}
                size={500}
                margin={0}
                fontSize="200"
              >
                {sentenceCase(templateOfTradeType?.name ?? '')}
              </Body>
            </Flex>
          ) : null;
        })}
      </Flex>

      <Body
        data-test-id="projectCreateInfo"
        fontSize="100"
        mt={0}
        mb={400}
        color="neutral.600"
      >
        {date}
        <br />
        {estimateGroup?.user?.name}
      </Body>
    </Flex>
  );
}
