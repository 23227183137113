import styled, { css } from 'styled-components';

interface InfoTextProps {
  margin?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
}

export const InfoText = styled.div`
  ${({ theme, margin = '0' }: InfoTextProps) => css`
    ${theme.mixins.font('book', 13)};
    margin: ${margin};
  `};
  font-style: italic;
`;

export const Container = styled.div``;

export const Contact = styled.a`
  text-decoration: underline;
`;
