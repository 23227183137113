import { PureComponent } from 'react';

import { CancelButtonTexts } from '../../../../constants';
import { Button } from './styled';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export, @typescript-eslint/no-explicit-any
export default class CancelButton extends PureComponent<any> {
  static defaultProps = {
    inverted: true,
    color: 'defaultGray',
  };

  render() {
    const { orderModalState, inverted, color, handleClick } = this.props;
    return (
      <Button
        inverted={inverted}
        color={color}
        onClick={handleClick}
        data-test-id="orderCheckCancel"
      >
        {CancelButtonTexts[orderModalState]}
      </Button>
    );
  }
}
