import { Body, Box, Checkbox } from '@hover/blueprint';
import { Collapse } from '@hover/blueprint/chakra';
import { Controller, useFormContext } from 'react-hook-form';

import { ToggleField } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/ProposalCreateBody/components';

import { CheckboxFieldUIWrapper, SectionPanel } from './components';

type Props = {
  handleCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: boolean) => void,
    block: string,
    label: string,
  ) => void;
  allowDefaultOverrideIncludeTradePriceInitialValue: boolean;
  allowDefaultOverrideIncludeItemCostInitialValue: boolean;
  allowDefaultOverrideIncludeItemQuantitiesInitialValue: boolean;
};

export const TeamMemberControls: React.FC<Props> = ({
  handleCheckboxChange,
  allowDefaultOverrideIncludeTradePriceInitialValue,
  allowDefaultOverrideIncludeItemCostInitialValue,
  allowDefaultOverrideIncludeItemQuantitiesInitialValue,
}) => {
  const { control, setValue, watch } = useFormContext();

  const {
    allowDefaultOverrideIncludeAllEstimateItems,
    allowDefaultOverrideIncludeItemCost,
    allowDefaultOverrideIncludeItemQuantities,
    allowDefaultOverrideIncludeTotalPrice,
    allowDefaultOverrideIncludeTradePrice,

    includeTotalPriceDefault,
    includeTradePriceDefault,
    includeAllEstimateItemsDefault,
    includeItemCostDefault,
    includeItemQuantitiesDefault,
  } = watch();

  const toggleRelatedPriceVisibilityValues = (value: boolean) => {
    if (!value) {
      setValue('allowDefaultOverrideIncludeTradePrice', false);
    } else {
      setValue(
        'allowDefaultOverrideIncludeTradePrice',
        allowDefaultOverrideIncludeTradePriceInitialValue,
      );
    }
  };

  const toggleRelatedDefaultVisibilityValues = (value: boolean) => {
    if (!value) {
      setValue('allowDefaultOverrideIncludeItemCost', false);
      setValue('allowDefaultOverrideIncludeItemQuantities', false);
    } else {
      setValue(
        'allowDefaultOverrideIncludeItemCost',
        allowDefaultOverrideIncludeItemCostInitialValue,
      );
      setValue(
        'allowDefaultOverrideIncludeItemQuantities',
        allowDefaultOverrideIncludeItemQuantitiesInitialValue,
      );
    }
  };

  return (
    <SectionPanel
      headingText="Team member controls"
      subHeadingText="Select which options your team has access to when generating a
proposal PDF for a lead. If you de-select an option, the option will
be hidden from your team."
      data-test-id="DefaultElementVisibilityOverridePanel"
      sidePanel={
        <Box
          marginTop={400}
          data-test-id="DefaultElementVisibilityOverrideToggles"
          flexDirection="column"
        >
          <Collapse in={allowDefaultOverrideIncludeTotalPrice}>
            <ToggleField
              labelText="Include total price"
              name="shouldIncludeTotalPrice"
              size={300}
              value={includeTotalPriceDefault}
              small
              readOnly
            />
          </Collapse>
          <Collapse in={allowDefaultOverrideIncludeTradePrice}>
            <ToggleField
              labelText="Include trade section price"
              name="shouldIncludeTradePrice"
              size={300}
              value={includeTradePriceDefault}
              small
              readOnly
            />
          </Collapse>
          <Collapse in={allowDefaultOverrideIncludeAllEstimateItems}>
            <ToggleField
              labelText="Include all line items"
              name="shouldIncludeAllLineItems"
              size={300}
              value={includeAllEstimateItemsDefault}
              small
              readOnly
            />
          </Collapse>
          <Collapse in={allowDefaultOverrideIncludeItemCost}>
            <ToggleField
              labelText="Include item cost"
              name="shouldIncludeItemCost"
              size={300}
              value={includeItemCostDefault}
              small
              readOnly
            />
          </Collapse>
          <Collapse in={allowDefaultOverrideIncludeItemQuantities}>
            <ToggleField
              labelText="Include quantities"
              name="shouldIncludeItemQuantity"
              size={300}
              value={includeItemQuantitiesDefault}
              small
              readOnly
            />
          </Collapse>
        </Box>
      }
    >
      <CheckboxFieldUIWrapper>
        <Controller
          control={control}
          name="allowDefaultOverrideIncludeTotalPrice"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(
                  e,
                  onChange,
                  'Team member controls',
                  'allowDefaultOverrideIncludeTotalPrice',
                );
                toggleRelatedPriceVisibilityValues(!value);
              }}
              isChecked={value}
            >
              <Body size={500} margin={0} color="neutral700">
                Total price
              </Body>
            </Checkbox>
          )}
        />
      </CheckboxFieldUIWrapper>
      <Collapse in={allowDefaultOverrideIncludeTotalPrice}>
        <CheckboxFieldUIWrapper indent>
          <Controller
            control={control}
            name="allowDefaultOverrideIncludeTradePrice"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Team member controls',
                    'allowDefaultOverrideIncludeTradePrice',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Trade price (sub-total)
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
      <CheckboxFieldUIWrapper>
        <Controller
          control={control}
          name="allowDefaultOverrideIncludeAllEstimateItems"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(
                  e,
                  onChange,
                  'Team member controls',
                  'allowDefaultOverrideIncludeAllEstimateItems',
                );

                toggleRelatedDefaultVisibilityValues(!value);
              }}
              isChecked={value}
            >
              <Body size={500} margin={0} color="neutral700">
                Line items
              </Body>
            </Checkbox>
          )}
        />
      </CheckboxFieldUIWrapper>
      <Collapse in={allowDefaultOverrideIncludeAllEstimateItems}>
        <CheckboxFieldUIWrapper indent>
          <Controller
            control={control}
            name="allowDefaultOverrideIncludeItemCost"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Team member controls',
                    'allowDefaultOverrideIncludeItemCost',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Item price
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
      <Collapse in={allowDefaultOverrideIncludeAllEstimateItems}>
        <CheckboxFieldUIWrapper indent>
          <Controller
            control={control}
            name="allowDefaultOverrideIncludeItemQuantities"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    onChange,
                    'Team member controls',
                    'allowDefaultOverrideIncludeItemQuantities',
                  )
                }
                isChecked={value}
              >
                <Body size={500} margin={0} color="neutral700">
                  Item quantities
                </Body>
              </Checkbox>
            )}
          />
        </CheckboxFieldUIWrapper>
      </Collapse>
    </SectionPanel>
  );
};
