import { Box, Body, Label, Toggle } from '@hover/blueprint';
import { Collapse } from '@hover/blueprint/chakra';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getJobDetails } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { jobProps } from 'src/utils/trackingUtils';

type Props = {
  allowDefaultOverrideIncludeTotalPrice: boolean;
  allowDefaultOverrideIncludeTradePrice: boolean;
  allowDefaultOverrideIncludeAllEstimateItems: boolean;
  allowDefaultOverrideIncludeItemCost: boolean;
  allowDefaultOverrideIncludeItemQuantities: boolean;
  estimateGroupId: string;
  includeTradePriceDefault: boolean;
  includeItemCostDefault: boolean;
  includeItemQuantitiesDefault: boolean;
  hidePriceToggles: boolean;
};

export const ProposalOptions: React.FC<Props> = ({
  allowDefaultOverrideIncludeTotalPrice,
  allowDefaultOverrideIncludeTradePrice,
  allowDefaultOverrideIncludeAllEstimateItems,
  allowDefaultOverrideIncludeItemCost,
  allowDefaultOverrideIncludeItemQuantities,
  includeTradePriceDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
  hidePriceToggles,
}) => {
  const jobDetails = useSelector(getJobDetails);
  const { register, watch, setValue, control } = useFormContext();
  const includeTotalPrice = watch('includeTotalPrice');
  const includeAllEstimateItems = watch('includeAllEstimateItems');

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleClickAllItemsToggle = (value: boolean) => {
    if (!value) {
      setValue('includeItemQuantities', false);
      setValue('includeItemCost', false);
    } else {
      setValue('includeItemQuantities', includeItemQuantitiesDefault);
      setValue('includeItemCost', includeItemCostDefault);
    }
    typewriter.optionSelected({
      selection: 'All',
      option_type: 'radio',
      options: 'proposal modal: include all line items',
      ...commonTrackingProps,
      ...jobProps(jobDetails),
    });
  };

  const handleClickTotalPrice = (value: boolean) => {
    if (!value) {
      setValue('includeTradePrice', false);
    } else {
      setValue('includeTradePrice', includeTradePriceDefault);
    }
  };

  return (
    <Box flexDirection="column" flexShrink={0} as="section">
      {!hidePriceToggles && (
        <Collapse in={allowDefaultOverrideIncludeTotalPrice}>
          <Label justifyContent="space-between" alignItems="center" box>
            <Body size={400} marginRight={50} marginBottom={300}>
              Include total price
            </Body>
            <Controller
              control={control}
              name="includeTotalPrice"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Toggle
                  onChange={(e) => {
                    // value reflects the value before the onChange event.
                    // flipping it so this reads more like a conventional ChangeHandler
                    handleClickTotalPrice(!value);
                    onChange(e);
                  }}
                  value={value}
                  onBlur={onBlur}
                  ref={ref}
                  data-test-id="totalPriceToggle"
                />
              )}
            />
          </Label>
        </Collapse>
      )}

      {!hidePriceToggles && (
        <Collapse
          in={allowDefaultOverrideIncludeTradePrice && includeTotalPrice}
        >
          <Label justifyContent="space-between" alignItems="center" box>
            <Body size={400} marginRight={50} marginBottom={300}>
              Include trade section price
            </Body>
            <Toggle
              {...register('includeTradePrice')}
              data-test-id="tradeSectionPriceToggle"
            />
          </Label>
        </Collapse>
      )}

      <Collapse in={allowDefaultOverrideIncludeAllEstimateItems}>
        <Label justifyContent="space-between" alignItems="center" box>
          <Body size={400} marginRight={50} marginBottom={300}>
            Include all line items
          </Body>
          <Controller
            control={control}
            name="includeAllEstimateItems"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Toggle
                onChange={(e) => {
                  // value reflects the value before the onChange event.
                  // flipping it so this reads more like a conventional ChangeHandler
                  handleClickAllItemsToggle(!value);
                  onChange(e);
                }}
                value={value}
                onBlur={onBlur}
                ref={ref}
                data-test-id="allItemsToggleButton"
              />
            )}
          />
        </Label>
      </Collapse>

      {!hidePriceToggles && (
        <Collapse
          in={allowDefaultOverrideIncludeItemCost && includeAllEstimateItems}
        >
          <Label justifyContent="space-between" alignItems="center" box>
            <Body
              size={400}
              marginRight={50}
              marginBottom={300}
              color={!includeAllEstimateItems ? 'neutral300' : 'inherit'}
            >
              Include item cost
            </Body>
            <Toggle
              {...register('includeItemCost')}
              disabled={!includeAllEstimateItems}
              data-test-id="itemCostToggleButton"
            />
          </Label>
        </Collapse>
      )}

      <Collapse
        in={
          allowDefaultOverrideIncludeItemQuantities && includeAllEstimateItems
        }
      >
        <Label justifyContent="space-between" alignItems="center" box>
          <Body
            size={400}
            marginRight={50}
            marginBottom={300}
            color={!includeAllEstimateItems ? 'neutral300' : 'inherit'}
          >
            Include quantities
          </Body>
          <Controller
            control={control}
            name="includeItemQuantities"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Toggle
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                disabled={!includeAllEstimateItems}
                ref={ref}
                data-test-id="itemQuantitiesToggle"
              />
            )}
          />
        </Label>
      </Collapse>
    </Box>
  );
};
