import { Box, Heading, Body, Button, Loader } from '@hover/blueprint';
import { iArrowRight } from '@hover/icons';

import {
  estimationConfigTemplatesWithLineItems as Data,
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node as Template,
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node_lineItems as LineItemType,
} from 'src/api/types/estimationConfigTemplatesWithLineItems';
import { NavButton } from 'src/components/blueprint';
import { ESTIMATOR } from 'src/features/exteriorEstimator/constants/urls';
import { TemplateTable } from 'src/features/settings/takeoffs/components/Templates/TemplateTable';

import { getRoofTemplates } from '../../utils/templatesFormatter';

interface TemplatesContentProps {
  loading: boolean;
  data: Data;
  jobId: string | null;
  onUpdateLineItem: (lineItem: LineItemType) => void;
  onUpdateTemplate: (template: Template) => void;
  onClickDuplicateTemplate: () => void;
}

export const TemplatesContent = ({
  loading,
  data,
  jobId,
  onUpdateLineItem,
  onUpdateTemplate,
  onClickDuplicateTemplate,
}: TemplatesContentProps) => {
  if (loading || !data?.estimationConfigTemplates?.edges)
    return <Loader data-test-id="TemplatesSpinner" size="large" />;

  const roofTemplates = getRoofTemplates(data.estimationConfigTemplates.edges);

  return (
    <Box flexDirection="column">
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="column">
          <Heading fontWeight="bold" size={300}>
            Takeoff Templates
          </Heading>
          <Body size={300} fontWeight="bold" color="neutral600" marginTop={0}>
            These templates are available in all projects.
          </Body>
        </Box>
        {jobId && (
          <NavButton to={`${ESTIMATOR}?jobId=${jobId}`} iconAfter={iArrowRight}>
            Save and Continue
          </NavButton>
        )}
      </Box>
      {(roofTemplates as Template[]).map((template, index) => (
        <TemplateTable
          key={template.id}
          id={template.id}
          name={template.name}
          lineItems={template.lineItems}
          onUpdateLineItem={onUpdateLineItem}
          onUpdateTemplate={() => onUpdateTemplate(template)}
          isInitiallyCollapsed={index !== 0}
        />
      ))}
      <Button fill="outline" marginY={500} onClick={onClickDuplicateTemplate}>
        + Add Template
      </Button>
    </Box>
  );
};
