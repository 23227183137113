import * as Sentry from '@sentry/react';
import { call, put } from 'redux-saga/effects';

import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';

import * as EstimatorProductionActions from '../actions';

export function* createPdfSaga(
  action: ReturnType<typeof EstimatorProductionActions.createPdf.request>,
) {
  const { payload } = action;
  try {
    const {
      data: {
        projectManagementOrderDocumentCreate: { orderDocument },
      },
    } = yield call(EstimatorProductionApi.orderDocumentCreate, {
      orderDocumentAttributes: payload.orderDocumentAttributes,
      externalIdentifier: payload.externalIdentifier,
      listItemIds: payload.listItemIds,
    });

    yield put(
      EstimatorProductionActions.pollProjectManagementOrderDocuments({
        productionListId: Number(
          payload.orderDocumentAttributes.productionListId,
        ),
        orderDocumentIds: [orderDocument.id],
      }),
    );
    payload.refetch();
  } catch (error) {
    yield put(EstimatorProductionActions.createPdf.failure(error));
    Sentry.captureException(error);
  }
}

export function* handlePdfErrorSaga(
  action: ReturnType<typeof EstimatorProductionActions.webDownloadPdf.failure>,
) {
  const { payload: error } = action;
  yield put(EstimatorProductionActions.webDownloadPdf.failure(error));
}

export function* handlePdfSuccessSaga() {
  yield put(EstimatorProductionActions.webDownloadPdf.success());
}
