import MeasurementUnit from './MeasurementUnit';
import { Row, TableData } from './ProductionSummaryStyled';

type pitch = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line camelcase
  roof_pitch: string;
  area: number;
};

type MeasurementPitchesProps = {
  pitches?: pitch[];
};

const MeasurementPitches: React.FC<MeasurementPitchesProps> = ({
  pitches = [],
}) => {
  return (
    <>
      {pitches.map((pitch, index) => (
        <Row key={pitch.roof_pitch}>
          <TableData>
            {pitch.roof_pitch} {index === 0 && '(predominant)'}
          </TableData>
          <TableData />
          <TableData>
            {pitch.area}
            <MeasurementUnit unit="sqft" />
          </TableData>
        </Row>
      ))}
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MeasurementPitches;
