import {
  Box,
  Heading,
  Body,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Collapse,
} from '@hover/blueprint';
import { iEdit } from '@hover/icons';

import { estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node_lineItems as LineItemType } from 'src/api/types/estimationConfigTemplatesWithLineItems';
import { formatTrades } from 'src/utils/Formatters';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

interface TemplateTableProps {
  id: number;
  name: string | null;
  lineItems: LineItemType[] | null;
  onUpdateLineItem: (lineItem: LineItemType) => void;
  onUpdateTemplate: () => void;
  isInitiallyCollapsed: boolean;
}

export const TemplateTable = ({
  id,
  name,
  lineItems,
  onUpdateLineItem,
  onUpdateTemplate,
  isInitiallyCollapsed,
}: TemplateTableProps) => (
  <Box
    backgroundColor="white"
    flexDirection="column"
    borderRadius={7}
    paddingX={400}
    paddingY={500}
    marginBottom={500}
    boxShadow="0px 4px 14px rgba(0, 0, 0, 0.15)"
  >
    <Collapse
      title={
        <Box color="neutral.800">
          <Heading size={200}>{name}</Heading>
          <Icon
            icon={iEdit}
            onClick={(e) => {
              onUpdateTemplate();
              e.stopPropagation();
            }}
            color="neutral600"
            marginLeft={2}
          />
        </Box>
      }
      showIndentation={false}
      defaultIndex={isInitiallyCollapsed ? undefined : [0]}
    >
      <Box flexDirection="column" width="100%">
        <Body size={300} color="neutral600" marginTop={0}>
          Do not worry about colors here. You’ll have the chance to edit those
          on a per job basis.
        </Body>
        <Table
          mb={500}
          size="small"
          paddingY={500}
          color="neutral600"
          borderColor="neutral300"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius={7}
          fontSize={300}
        >
          <Thead>
            <Tr data-test-id={`TemplateTable-${id}`}>
              <Th>Name</Th>
              <Th>Trade</Th>
              <Th>Packaging Unit</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {lineItems &&
              lineItems.map((lineItem) => (
                <Tr key={lineItem.id}>
                  <Td>{lineItem.name}</Td>
                  <Td>
                    {lineItem.tradeTypes
                      ? formatTrades(lineItem.tradeTypes)
                      : '--'}
                  </Td>
                  <Td>{`${
                    lineItem.netCoverageLineItemPreset
                      ? `${lineItem.netCoverageLineItemPreset.value} `
                      : ''
                  }${lineItemQuantityUnits(lineItem.quantityUnits)}`}</Td>
                  <Td>
                    <Box paddingLeft={10}>
                      <Button
                        shape="circle"
                        fill="minimal"
                        onClick={() => onUpdateLineItem(lineItem)}
                        marginRight={3}
                      >
                        <Icon icon={iEdit} color="neutral600" />
                      </Button>
                    </Box>
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Td colSpan={4}>
                <Box width="100%">
                  <Button fill="outline" marginBottom={400} flex={1}>
                    + Add Line Item
                  </Button>
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Collapse>
  </Box>
);
