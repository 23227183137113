import {
  useEffect,
  forwardRef,
  useRef,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { Spinner } from '@hover/blueprint';
import { useFormContext } from 'react-hook-form';

import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { productCatalogProductSearch_productCatalogProductSearch as ProductSearchResult } from 'src/api/types/productCatalogProductSearch';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { InputWithDropdownTypeahead } from 'src/components/InputWithDropdownTypeahead';
import type { InputWithDropdownTypeaheadProps } from 'src/components/InputWithDropdownTypeahead';
import { useProjectScopeTracker } from 'src/features/project/components/ProjectScope/hooks/useProjectScopeTracker';
import {
  productSearch,
  getTypeAheadSuggestionsIcon,
} from 'src/features/project/util/utils';

type ProductNameInputProps = {
  orgId: string;
  jobId: number;
  name: string;
  distributors?: Distributor[];
  listItem: ListItem;
  setName: (value: string) => void;
  setIsProductSelected: Dispatch<SetStateAction<boolean>>;
  onUpdate: () => void;
} & Omit<InputWithDropdownTypeaheadProps, 'suggestions'>;

/**
 * Custom component used inside a Chakra `Editable` component.
 * Knows when to hide/show itself based on the EditableState.
 */
export const ProductNameInput: React.FC<ProductNameInputProps> = forwardRef<
  HTMLInputElement,
  ProductNameInputProps
>(
  (
    {
      name,
      setName,
      distributors,
      orgId,
      jobId,
      listItem,
      setIsProductSelected,
      onUpdate,
    },
    ref,
  ) => {
    const { trackInlineEditingInputPressed } = useProjectScopeTracker({
      jobId,
    });
    const lastFoundSuggestions = useRef<ProductSearchResult[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [searchSuggestions, setSearchSuggestions] = useState<
      ProductSearchResult[]
    >([]);

    const { setValue } = useFormContext();

    const typeAheadSuggestions = useMemo(() => {
      return searchSuggestions.map((result) => {
        const icon = getTypeAheadSuggestionsIcon(result, distributors);
        return {
          text: result.name,
          id: result.id,
          icon,
        };
      });
    }, [distributors, searchSuggestions]);

    const handleSelectedSearchSuggestion = (text: string) => {
      const selectedProduct = lastFoundSuggestions.current.find(
        (product: ProductSearchResult) =>
          product.name.toLowerCase() === text.toLowerCase(),
      ) as ProductSearchResult;

      if (!selectedProduct) return;

      // If adding/editing a listItem, and the newly-selected
      // product has only one available variation, then
      // pre-select the one variation in the add/edit item form
      if (selectedProduct.variations.length === 1) {
        const variation = selectedProduct.variations[0];
        setValue('color', variation.name, {
          shouldValidate: true,
          shouldDirty: true,
        });

        setValue('externalVariationId', variation.id, {
          shouldValidate: true,
          shouldDirty: true,
        });

        setValue('requiresProductVariationSelection', false, {
          shouldValidate: true,
          shouldDirty: true,
        });

        return;
      }

      setValue('color', null, {
        shouldDirty: true,
        shouldValidate: true,
      });

      setValue('externalVariationId', null, {
        shouldDirty: true,
      });
    };

    const handleSearch = async (
      text: string,
      selected: boolean,
      selectedItem: { text: string; id: string },
    ) => {
      setName(text);

      // Item was selected from suggestion list:
      if (selected) {
        // Set productId
        setValue('productCatalogProductId', selectedItem?.id);
        // Set lineItem name.
        setValue('name', selectedItem?.text);
        // Set that one item was selected list
        setIsProductSelected(true);

        // Set variation related data
        handleSelectedSearchSuggestion(text);

        // Call save function
        onUpdate();
        return;
      }

      setIsLoading(true);

      const productSearchVariables = {
        searchTerm: text,
        orgId,
        distributorId: null,
      };

      const results = await productSearch(productSearchVariables);

      if (!results || results.length === 0) {
        setSearchSuggestions([]);
        setIsLoading(false);
        return;
      }

      setSearchSuggestions(results);
      lastFoundSuggestions.current = results;

      setIsLoading(false);
    };

    useEffect(() => {
      if (!listItem.product || !listItem?.productCatalogProductId) {
        return;
      }

      setSearchSuggestions([listItem.product as ProductSearchResult]);
    }, [listItem, setSearchSuggestions]);

    return (
      <InputWithDropdownTypeahead
        ref={ref}
        elementAfter={isLoading ? <Spinner /> : undefined}
        inputProps={{
          size: 'small',
          fontSize: 'inherit',
          paddingY: '0px',
          value: name,
          borderColor: 'neutral.500',
          borderRadius: '6px',
          onChange: (ev) => {
            setName(ev.target.value); // Set the value for the controlled component, on input change events.
          },
          onKeyDown: (event) => {
            const { value } = event.target as HTMLInputElement;

            // Set custom name
            if (event.key === 'Enter' && value.length > 0) {
              setValue('name', value);
              setValue('externalVariationId', '', {
                shouldDirty: true,
              });
              setValue('color', '', {
                shouldValidate: true,
                shouldDirty: true,
              });

              onUpdate();
            }
          },
        }}
        suggestions={typeAheadSuggestions}
        onChange={(text, selected, selectedItem) => {
          trackInlineEditingInputPressed('Product Name');
          handleSearch(
            text,
            selected,
            selectedItem as { text: string; id: string },
          );
        }}
        initialInputValue={name || ''}
        label="materialName"
      />
    );
  },
);
