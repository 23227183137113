import { Box, Button, Field, TextInput, IconButton } from '@hover/blueprint';
import { iTrash, iPlusCircle } from '@hover/icons';

import { SelectQuestionInputType } from './TemplateQuestionCreateOrUpdate';

interface TemplateQuestionFormSelectOptionsProps {
  options?: SelectQuestionInputType[];
  onAdd: () => void;
  onDelete: (id: string) => void;
  onChange: (id: string, value: string) => void;
}

export const TemplateQuestionFormSelectOptions = ({
  options = [],
  onAdd,
  onDelete,
  onChange,
}: TemplateQuestionFormSelectOptionsProps) => (
  <Box flexDirection="column" paddingX={400}>
    {options.map((option, index) => (
      <Box alignItems="center" key={option.id}>
        <Field
          label={`Option ${index + 1}`}
          name={`option_text_${index}`}
          width="90%"
        >
          <TextInput
            value={option.label}
            onChange={(event) => onChange(option.id, event.target.value)}
            placeholder="Type here"
            isRequired
          />
        </Field>
        {index > 0 && (
          <IconButton
            label="DeleteSelectOption"
            color="neutral"
            icon={iTrash}
            fill="minimal"
            size="large"
            ml={200}
            onClick={() => onDelete(option.id)}
          />
        )}
      </Box>
    ))}
    <Box>
      <Button
        my={400}
        fill="minimal"
        paddingX={2}
        marginTop={0}
        onClick={onAdd}
        iconBefore={iPlusCircle}
      >
        Add another option
      </Button>
    </Box>
  </Box>
);
