import { PureComponent } from 'react';

import { IconButton, Box, Body, Badge } from '@hover/blueprint';
import { iChevronDown, iChevronLeft } from '@hover/icons';
import * as Sentry from '@sentry/react';
import autobind from 'autobind-decorator';
import { some } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import HyperionApi from 'src/api/hyperion';
import {
  estimationEstimateGroups_estimationEstimateGroups_edges_node as EdgeEstimateGroupType,
  estimationEstimateGroups_estimationEstimateGroups_edges_node_estimates as Estimate,
} from 'src/api/types/estimationEstimateGroups';
import type {
  jobsWithSalesOpportunity_jobs_results_salesOpportunity_soldEstimateGroup as JobsWithSalesOpportunitiesSoldEstimateGroupType,
  jobsWithSalesOpportunity_jobs_results_salesOpportunity_soldEstimateGroup_user as UserType,
} from 'src/api/types/jobsWithSalesOpportunity';
import { FormattedNumber } from 'src/components/FormattedNumber';
import {
  getOrgSettings,
  getCanViewProductionConsole,
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';
import { formatTradesStringForEstimates } from 'src/utils/Formatters';

import { EstimateGroupButtons } from './EstimateGroupButtons';
import { EstimationGroupEstimate } from './EstimationGroupEstimate';

interface State {
  expanded: boolean;
  salesRep: {
    name: string;
    email: string;
  };
}

const mapStateToProps = (state: RootState) => ({
  showPricesInEstimation: getOrgSettings(state)?.showPricesInEstimation,
  showOrderingFlow: getOrgSettings(state)?.showOrderingFlow,
  canViewProductionConsole: getCanViewProductionConsole(state), // need this for Buttons
  materialListFeatureEnabled: getMaterialListFeature(state),
  orderingOnlyFeatureAccess: getOrderingOnlyFeatureAccess(state),
});

type EstimateGroupType =
  | EdgeEstimateGroupType
  | JobsWithSalesOpportunitiesSoldEstimateGroupType;

type Props = ReturnType<typeof mapStateToProps> &
  EstimateGroupType & {
    selectedOrgId: number;
    isSold?: boolean;
  } & {
    user: UserType | undefined;
  };

export class EstimateGroup extends PureComponent<Props, State> {
  state = {
    expanded: false,
    salesRep: {
      name: '',
      email: '',
    },
  };

  async componentDidMount() {
    const { estimates, user } = this.props;
    if (user?.name && user?.email) {
      this.setState({
        salesRep: {
          name: user.name,
          email: user.email,
        },
      });
    } else {
      const userId = estimates?.[0]?.userId;
      if (!userId) return;

      try {
        if (userId) {
          const {
            data: {
              user: { first_name: firstName, last_name: lastName, email },
            },
          } = (await HyperionApi.getUserById(userId)) as any;

          this.setState({
            salesRep: {
              name: `${firstName ?? ''} ${lastName ?? ''}`,
              email,
            },
          });
        }
      } catch (e) {
        // noop
        Sentry.captureException(e);
      }
    }
  }

  public formatDate(createdAt: string) {
    return moment(createdAt).format('M/DD LT');
  }

  public noEstimatesSelected() {
    const { estimates } = this.props;
    return !some(estimates, { active: true });
  }

  @autobind
  public toggleExpanded() {
    if (this.noEstimatesSelected()) return;
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  @autobind
  public renderExpander() {
    const { expanded } = this.state;
    const { estimates = [] } = this.props;
    if (estimates.length === 0) return null;

    return expanded ? (
      <IconButton
        fill="minimal"
        color="neutral"
        label="closeEstimates"
        icon={iChevronDown}
        size="large"
        data-test-id="angleDownIcon"
        onClick={this.toggleExpanded}
        isDisabled={this.noEstimatesSelected()}
      />
    ) : (
      <IconButton
        fill="minimal"
        color="neutral"
        label="expandEstimates"
        icon={iChevronLeft}
        size="large"
        data-test-id="angleRightIcon"
        onClick={this.toggleExpanded}
        isDisabled={this.noEstimatesSelected()}
      />
    );
  }

  @autobind
  public renderEstimates() {
    const { estimates, isSold } = this.props;
    const { expanded } = this.state;

    if (!expanded) return null;
    return (
      <>
        <Box
          borderTop="1px solid"
          borderColor="neutral.300"
          gridColumnStart={2}
          gridColumnEnd={8}
        />
        {(estimates as any)?.map((estimate: Estimate) => (
          <EstimationGroupEstimate
            estimate={estimate}
            key={estimate.id}
            isSold={isSold}
          />
        ))}
      </>
    );
  }

  public renderStatusBadge() {
    const {
      isSold,
      showOrderingFlow,
      materialListFeatureEnabled,
      orderingOnlyFeatureAccess,
    } = this.props;
    const showOrderingVersion = orderingOnlyFeatureAccess && showOrderingFlow;
    const showOrderingOrMaterialListVersion =
      showOrderingVersion || materialListFeatureEnabled;

    return (
      <Box gridColumn={6}>
        {isSold && !showOrderingOrMaterialListVersion && (
          <Badge colorScheme="success" data-test-id="statusBadge">
            Sold
          </Badge>
        )}
      </Box>
    );
  }

  public render() {
    const {
      createdAt,
      estimates,
      showPricesInEstimation,
      totalPrice,
      id,
      isSold,
    } = this.props;
    const { salesRep } = this.state;

    const estimateTotal = isSold ? totalPrice : 0;

    return (
      <div>
        <Box
          maxWidth="1280px"
          display="grid"
          alignItems="center"
          gridTemplateColumns="[col1] 8% [col2] 15% [col3] 15% [col4] 10% [col5] 7% [col6] 7% [col7] 1fr [col8] 3%"
          gridColumnGap={300}
          gridRowGap={300}
          background="neutral.100"
          paddingY={300}
          data-test-id={`estimateGroup-${id}`}
        >
          <Body
            size={300}
            gridColumn={1}
            paddingLeft={400}
            color="neutral.600"
            textOverflow="ellipsis"
            overflow="hidden"
            as="span"
          >
            {this.formatDate(createdAt)}
          </Body>
          <Body
            size={300}
            gridColumn={3}
            paddingY={400}
            color="neutral.600"
            textOverflow="ellipsis"
            overflow="hidden"
            as="span"
            data-test-id="estimateTrade"
          >
            {formatTradesStringForEstimates(estimates)}
          </Body>
          <Body
            size={300}
            gridColumn={4}
            paddingY={400}
            color="neutral.600"
            textOverflow="ellipsis"
            overflow="hidden"
            as="span"
          >
            {salesRep.name}
          </Body>
          {showPricesInEstimation && (
            <Body
              size={300}
              gridColumn={5}
              paddingY={400}
              color="neutral.600"
              display="flex"
              justifyContent="flex-end"
              textOverflow="ellipsis"
              overflow="hidden"
              as="span"
            >
              {!!estimateTotal && (
                <FormattedNumber value={estimateTotal} format="$0,0[.]00" />
              )}
            </Body>
          )}
          {this.renderStatusBadge()}
          <EstimateGroupButtons {...(this.props as any)} />
          <Box width={400}>
            <Box
              flex="1"
              justifyContent="flex-end"
              paddingRight={500}
              height="46px"
              width="46px"
            >
              {this.renderExpander()}
            </Box>
          </Box>
          {this.renderEstimates()}
        </Box>
      </div>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(EstimateGroup);
