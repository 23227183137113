import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  ALL_TRADES,
  TradeTypes,
  LineItemFilters,
} from 'src/features/projectManagement/constants/multiTrades';
import { sentenceCase } from 'src/utils/Formatters';

import { ListItemType, TableItemType, CategorizedItemsType } from '../types';

interface ItemsByTradeType {
  [key: string]: ListItemType[];
}

interface VendorListItems {
  [vendorName: string]: TableItemType;
}

export const mapItemsByTradeType = (
  items: ListItemType[],
): ItemsByTradeType => {
  const itemsByTrade: ItemsByTradeType = {};
  items.forEach((item) => {
    const { tradeType } = item;
    if (itemsByTrade[tradeType]) {
      itemsByTrade[tradeType].push(item);
    } else {
      itemsByTrade[tradeType] = [item];
    }
  });
  return itemsByTrade;
};

export const sortItemsByCreatedAt = (items: ListItemType[]): ListItemType[] =>
  items.sort(
    (a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)),
  );

export const sortItemsByPrice = (items: ListItemType[]): ListItemType[] =>
  items.sort((a, b) => {
    return (b?.pretaxCost ?? 0) - (a?.pretaxCost ?? 0);
  });

export const sortTradeItemsBySortOrderAndPriority = (
  itemsByTrade: ItemsByTradeType,
  priority: string,
): ItemsByTradeType => {
  const sortedItemsByTrade: ItemsByTradeType = {};
  Object.keys(itemsByTrade).forEach((tradeType: string): void => {
    const itemsByTradeWithSortOrder = itemsByTrade[tradeType].filter(
      (item) => item.sortOrder,
    );
    const itemsByTradeWithoutSortOrder = itemsByTrade[tradeType].filter(
      (item) => !item.sortOrder,
    );

    // items with a sortOrder field should be sorted by their sortOrder
    const sortedBySortOrder = itemsByTradeWithSortOrder.sort((a, b) =>
      (a?.sortOrder ?? 0) < (b?.sortOrder ?? 0) ? -1 : 1,
    );

    // items without a sortOrder field should be sorted by priority, either PRICE or CREATED_AT
    const sortedByPriority =
      priority === LineItemFilters.CREATED_AT
        ? sortItemsByCreatedAt(itemsByTradeWithoutSortOrder)
        : sortItemsByPrice(itemsByTradeWithoutSortOrder);

    // items sorted by SortOrder should always be first
    sortedItemsByTrade[tradeType] = [...sortedBySortOrder, ...sortedByPriority];
  });
  return sortedItemsByTrade;
};

export const sortItemsByTradeTypeAndPriority = (
  items: ListItemType[],
  priority: string,
  tradeTypes: TradeType[],
): ListItemType[] => {
  const sorted: ListItemType[] = [];
  const itemsByTrade = sortTradeItemsBySortOrderAndPriority(
    mapItemsByTradeType(items),
    priority,
  );
  const sortOrder = [
    ALL_TRADES,
    ...tradeTypes.map((tradeType) => tradeType.tradeTypeEnumValue),
  ];

  sortOrder.forEach((tradeType) => {
    const tradeTypeItems = itemsByTrade[tradeType] || [];
    sorted.push(...tradeTypeItems);
  });

  return sorted;
};

export const filterItemsByTrade = (
  items: ListItemType[],
  tradeFilter: string,
): ListItemType[] => {
  if (tradeFilter === TradeTypes.ALL_TRADES) return items;
  return items.filter(
    ({ tradeType }: ListItemType) => tradeType === tradeFilter,
  );
};

export const massageItemsForMultiTrades = (
  items: ListItemType[],
  tradeTypes: TradeType[],
  tradeFilter: string = TradeTypes.ALL_TRADES,
  lineItemFilter: string = LineItemFilters.PRICE,
) => {
  return filterItemsByTrade(
    sortItemsByTradeTypeAndPriority(items, lineItemFilter, tradeTypes),
    tradeFilter,
  );
};

export const stringifyProductionListTradeTypesForPDF = (
  productionListTradeTypes: string[],
  tradeFilter: string = TradeTypes.ALL_TRADES,
) => {
  if (tradeFilter === TradeTypes.ALL_TRADES) {
    const productionListString: string[] = [];
    productionListTradeTypes.forEach((tradeType: string) => {
      productionListString.push(tradeType);
    });
    return productionListString.join(', ');
  }
  return sentenceCase(tradeFilter);
};

export const extractListItemsArray = (
  vendorListItems: VendorListItems,
): ListItemType[] => Object.values(vendorListItems)[0].listItems;

export const filterCategorizedListItemsByTrade = (
  categorizedItems: CategorizedItemsType,
  tradeType: string,
) => {
  const filteredCategorizedItems: CategorizedItemsType = {};
  Object.entries(categorizedItems).forEach(([category, items]) => {
    filteredCategorizedItems[category] = filterItemsByTrade(items, tradeType);
  });
  return filteredCategorizedItems;
};
