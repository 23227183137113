import { useCallback, useEffect, useState } from 'react';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { EditableItem } from 'src/features/project/components/common/AddEditMaterialModal';

export function useAddMaterialModal() {
  const [showAddEditMaterialModal, setShowAddEditMaterialModal] =
    useState<boolean>(false);

  const [editingListItem, setEditingListItem] = useState<EditableItem | null>(
    null,
  );

  const closeAddEditMaterialModal = useCallback(() => {
    setEditingListItem(null);
    setShowAddEditMaterialModal(false);
  }, [setShowAddEditMaterialModal]);

  const openAddEditMaterialModal = useCallback(
    (type: LineItemTypeEnum) => {
      setShowAddEditMaterialModal(true);
      setEditingListItem({ type });
    },
    [setShowAddEditMaterialModal],
  );

  useEffect(() => {
    if (editingListItem) setShowAddEditMaterialModal(true);
  }, [editingListItem]);

  return {
    showAddEditMaterialModal,
    editingListItem,
    setEditingListItem,
    closeAddEditMaterialModal,
    openAddEditMaterialModal,
  };
}
