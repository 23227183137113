import { useState, useEffect } from 'react';

import { Box, Heading, LoadingOverlay } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  EstimationEstimateGroupStateEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import {
  EstimateGroupForSummary as EstimateGroupForSummaryType,
  EstimateGroupForSummary_estimationEstimateGroup_estimates as Estimate,
} from 'src/api/types/EstimateGroupForSummary';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { GET_ESTIMATE_GROUP_FOR_SUMMARY } from 'src/features/exteriorEstimator/apis/queries';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { PropertyHeroImage } from 'src/features/exteriorEstimator/components/common/PropertyHeroImage/PropertyHeroImage';
import { EstimateGroupFooter } from 'src/features/exteriorEstimator/components/EstimationTool/Estimates/EstimateGroupFooter';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getJobDetailSummary,
  getParams,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  createTradeTypeEstimatesHash,
  hasAnySoldEstimateGroup,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { useQueryEhi, useEffectOnMount, useTracking } from 'src/hooks';
import {
  getTradeTypesSorted,
  getVariationsFilter,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { EstimateGroup } from './EstimateGroup';

export const EstimateGroups: React.FC = () => {
  const jobDetailSummary = useSelector(getJobDetailSummary);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);

  const [isUpdatingActivating, setIsUpdatingActivating] =
    useState<boolean>(false);
  const { jobId } = useSelector(getParams);
  const tradeTypesSorted = useSelector(getTradeTypesSorted);

  const dispatch = useDispatch();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { data, loading, error, stopPolling } =
    useQueryEhi<EstimateGroupForSummaryType>(GET_ESTIMATE_GROUP_FOR_SUMMARY, {
      variables: {
        id: estimateGroupId,
        ...variationsFilter,
      },
      pollInterval: 1500,
    });

  useEffect(() => {
    const { state, failureReason } = data?.estimationEstimateGroup ?? {};
    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      stopPolling();
    }
    if (error || state === EstimationEstimateGroupStateEnum.FAILED) {
      stopPolling();
      dispatch(estimatorActions.setError(true));

      if (failureReason && failureReason !== 'None') {
        Sentry.captureException(error);
      }
    }
  }, [data, error, stopPolling, dispatch]);

  const estimateGroupEstimates = data?.estimationEstimateGroup?.estimates;
  const estimateGroup = data?.estimationEstimateGroup;
  const hasSoldEstimateGroup = hasAnySoldEstimateGroup(estimateGroup);

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.estimator.estimateGroupsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  });

  const renderEstimateGroups = () => {
    const estimatesHash = estimateGroupEstimates
      ? createTradeTypeEstimatesHash(estimateGroupEstimates)
      : {};
    const headerText = hasSoldEstimateGroup
      ? 'Selection cannot be changed for sold project'
      : 'Select project estimates';
    const tradeTypeSortOrder = tradeTypesSorted.map(
      (tradeType) => tradeType.tradeTypeEnumValue,
    );

    const tradeHashKeyComparator = (
      a: [TradeTypeEnum, Estimate[]],
      b: [TradeTypeEnum, Estimate[]],
    ) => tradeTypeSortOrder.indexOf(a[0]) - tradeTypeSortOrder.indexOf(b[0]);

    return (
      <>
        <Box
          flexDirection={{
            base: 'column',
            tablet: 'row',
          }}
          justifyContent="space-between"
        >
          <Box>
            <Heading
              size={200}
              color="neutral.400"
              marginBottom="1em"
              data-test-id="estimateGroupsHeaderText"
            >
              {headerText}
            </Heading>
          </Box>
        </Box>

        {Object.entries(estimatesHash)
          .sort(
            tradeHashKeyComparator as (
              a: [string, Estimate[]],
              b: [string, Estimate[]],
            ) => number,
          )
          .map(([trade, estimates]) => {
            return (
              <EstimateGroup
                trade={trade as TradeTypeEnum}
                estimates={estimates}
                key={trade}
                setIsUpdatingActivating={setIsUpdatingActivating}
                hasSoldEstimateGroup={hasSoldEstimateGroup}
              />
            );
          })}
      </>
    );
  };

  if (
    loading ||
    !estimateGroup ||
    isEmpty(estimateGroupEstimates) ||
    tradeTypesSorted.length === 0
  )
    return <LoaderSpinner show />;

  const hideTotalPrice = estimateGroup?.estimates
    .map((e) => e.template)
    .some((template) => !template?.showPricesInEstimation); // do any templates in this group have showPricesByTemplate, if not, hide the total price

  return (
    <Box flexDirection="column" height="100vh">
      <LoadingOverlay isLoading={isUpdatingActivating} />
      <Box width={1}>
        <PropertyHeroImage
          hideEdit={false}
          jobDetailSummary={jobDetailSummary}
        />
      </Box>
      <EstimatorResponsiveWrapper>
        {estimateGroupEstimates &&
          estimateGroupEstimates.length > 0 &&
          renderEstimateGroups()}
      </EstimatorResponsiveWrapper>
      <EstimateGroupFooter
        estimateGroup={estimateGroup}
        hideTotalPrice={hideTotalPrice}
      />
    </Box>
  );
};
