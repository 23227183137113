import { map } from 'lodash';

import { QuantityUnitsEnum } from 'src/api/graphql-global-types';

export const UNITS_MAP = {
  // labor
  EACH: 'ea',
  HOURS: 'hr',
  LINEAR_FEET: 'lf',
  PIECES: 'pc',
  SQUARE_FEET: 'sqft',
  CUBIC_FEET: 'cuft',
  square: 'sq',
  SQUARES: 'sq',
  pounds: 'lb',
  // materials
  bag: 'bg',
  bags: 'bg',
  box: 'bx',
  boxes: 'bx',
  bundle: 'bdl',
  bundles: 'bdl',
  cartons: 'ctn',
  pairs: 'pr',
  piece: 'pc',
  pieces: 'pc',
  roll: 'rl',
  rolls: 'rl',
  tube: 'tb',
  tubes: 'tb',
} as { [s: string]: string };

export const lineItemQuantityUnits = (unit: string | null) => {
  if (!unit) {
    return '';
  }

  const unitMap =
    UNITS_MAP[unit.toUpperCase() as keyof typeof UNITS_MAP] ||
    UNITS_MAP[unit.toLowerCase() as keyof typeof UNITS_MAP];

  return unitMap ? unitMap.toUpperCase() : unit;
};

export const convertMeasurementUnitToQuantityUnit = (unit: string) => {
  switch (unit.toLowerCase()) {
    case 'ea':
      return QuantityUnitsEnum.EACH;
    case 'hr':
      return QuantityUnitsEnum.HOURS;
    case 'lf':
      return QuantityUnitsEnum.LINEAR_FEET;
    case 'pc':
      return QuantityUnitsEnum.PIECES;
    case 'sqft':
      return QuantityUnitsEnum.SQUARE_FEET;
    case 'cuft':
      return QuantityUnitsEnum.CUBIC_FEET;
    case 'sq':
      return QuantityUnitsEnum.SQUARES;
    case 'bg':
      return QuantityUnitsEnum.BAGS;
    case 'bx':
      return QuantityUnitsEnum.BOXES;
    case 'bdl':
      return QuantityUnitsEnum.BUNDLES;
    case 'rl':
      return QuantityUnitsEnum.ROLLS;
    case 'tb':
      return QuantityUnitsEnum.TUBES;
    case 'ctn':
      return QuantityUnitsEnum.CARTONS;
    case 'pr':
      return QuantityUnitsEnum.PAIRS;
    case 'lb':
      return QuantityUnitsEnum.POUNDS;
    default:
      return null;
  }
};

export const getUnitsMap = (): { [s: string]: string }[] => {
  const units = Array.from(new Set(Object.values(UNITS_MAP)));

  const unitsMap = map(units, (unit: string) => {
    return {
      id: convertMeasurementUnitToQuantityUnit(unit),
      name: unit.toUpperCase(),
    };
  });

  return unitsMap as { [s: string]: string }[];
};
