export interface RestDiscountType {
  percentage: 'percentage';
  dollars: 'dollars';
}

export const RestDiscountTypes: RestDiscountType = {
  percentage: 'percentage',
  dollars: 'dollars',
};

export enum GraphQLDiscountTypes {
  percentage = 'PERCENTAGE',
  dollars = 'DOLLARS',
}

export enum SortDirectionType {
  asc = -1,
  desc = 1,
}
// key for questions cached in localStorage
export const EHI_LOCAL_STORAGE_KEY = 'HOVER/Exterior_Estimator';
// key for "return url" within app, after re-launch from OAuth re-authorization.
export const EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY = 'HOVER/OAuth_Redirect';
// key for 3rd-party OAuth re-authorization URL.
export const EHI_LOCAL_STORAGE_OAUTHURL_KEY = 'HOVER/OAuth_Url';
// key for estimator debugging
export const EHI_ESTIMATOR_DEBUG_KEY = 'hoverEstimatorDebug';
