/* eslint-disable camelcase */

export interface Model {
  id: number;
  job_id: number;
  address?: string | null;
  created_at: string;
  updated_at: string;
  machete_model_measurements?: MacheteModelMeasurements;
  pro_measurements?: ProMeasurementsOrRoofMeasurements;
  pro_premium_measurements?: ProPremiumMeasurements;
  roof_measurements?: ProMeasurementsOrRoofMeasurements;
}
export interface MeasurementUrl {
  url: string;
}

export interface MacheteModelMeasurements {
  url: string;
  metadata: {
    version: number;
  };
  key: string;
}

export interface ProMeasurementsOrRoofMeasurements {
  url: string;
  spreadsheet: MeasurementUrl;
  json: MeasurementUrl;
  summarized_json: MeasurementUrl;
  full_json: MeasurementUrl;
  xml: MeasurementUrl;
}
export interface ProPremiumMeasurements {
  url: string;
  spreadsheet: MeasurementUrl;
  json: MeasurementUrl;
  summarized_json: MeasurementUrl;
  full_json: MeasurementUrl;
  estimation_json: MeasurementUrl;
  xml: MeasurementUrl;
}
