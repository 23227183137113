import styled from 'styled-components';

const CircularButton = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #23a2e0;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CircularButton;
