import { Box } from '@hover/blueprint';
import styled, { css } from 'styled-components';

import StyleGuide from 'style-guide';

export const Modal = styled(StyleGuide.DefaultModal)`
  min-width: 790px;
  max-height: 440px;
`;

export const Button = styled(StyleGuide.Button)`
  width: 220px;
  height: 35px;
`;

export const contentStyle = {
  padding: 0,
  width: '50%',
};

export const footerStyle = {
  padding: '24px',
};

interface ContentProps {
  shouldShowBoxShadow?: boolean;
}

export const Content = styled(Box)`
  ${({ shouldShowBoxShadow }: ContentProps) =>
    shouldShowBoxShadow &&
    css`
      box-shadow: inset 0 -10px 10px -10px
        ${({ theme }) => theme.colors.lightGrayFour};
    `}
  padding: 0 32px;
`;

export const ContentBox = styled(Box)``;

export const ContentLeft = styled(Box)`
  width: 40%;
  font-size: 13px;
`;

export const LabelWrapper = styled.div`
  line-height: 48px;
  height: 24px;
  font-size: 13px;
`;

export const OptionalWrapper = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.lightGrayFour};
`;

export const FlexColumn = styled(Box)`
  flex-direction: column;
  width: 100%;
  padding: 0;
  height: 160px;
  overflow: scroll;
  position: relative;
`;

export const PdfDownload = styled.a`
  width: 200px;
  text-align: left;
  color: ${({ theme }) => theme.colors.defaultActionBlue};
  &:hover {
    color: ${({ theme }) => theme.colors.defaultActionBlue};
  }
`;

export const Checkbox = styled(StyleGuide.Checkbox)``;

export const CheckboxLabelText = styled.span`
  margin-left: 12px;
  font-size: 13px;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.borderError};
  font-size: 0.75rem;
`;
