import { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Icon,
  LoadingOverlay,
  Table,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@hover/blueprint';
import { iInfo } from '@hover/icons';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import type { getSalesPresentations_salesPresentationPresentations as SalesPresentation } from 'src/api/types/getSalesPresentations';
import type { salesPresentationPresentationArchive } from 'src/api/types/salesPresentationPresentationArchive';
import {
  ARCHIVE_SALES_PRESENTATION,
  GET_SALES_PRESENTATIONS,
} from 'src/features/settings/api/queries/salesPresentation';
import { ConfirmDeleteModal } from 'src/features/settings/components/common/ConfirmDeleteModal';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';

import { SalesPresentationRow } from './SalesPresentationRow';

type Props = {
  salesPresentations: SalesPresentation[];
};

export interface CurrentSelectedPresentation {
  id: SalesPresentation['id'];
  name: SalesPresentation['name'];
}

export const SalesPresentationTable: React.FC<Props> = ({
  salesPresentations,
}) => {
  const initialState: CurrentSelectedPresentation = {
    id: '',
    name: '',
  };
  const orgId = useSelector(getUserOrgId);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [currentSalesPresentation, setCurrentSalesPresentation] =
    useState<CurrentSelectedPresentation>(initialState);

  const toast = useToastEhi();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: 'Sales Presentation List',
      ...commonTrackingProps,
    });
  }, []);

  const [salesPresentationArchive, { loading, data }] =
    useMutation<salesPresentationPresentationArchive>(
      ARCHIVE_SALES_PRESENTATION,
      {
        variables: {
          id: currentSalesPresentation.id,
        },
        refetchQueries: [
          { query: GET_SALES_PRESENTATIONS, variables: { orgId } },
        ],
        onCompleted: (onCompletedData) => {
          const name =
            onCompletedData?.salesPresentationPresentationArchive
              ?.salesPresentation?.name;

          toast({
            id: 'sales-presentation-archive-success-toast',
            description: `Your sales presentation "${name}" was successfully deleted`,
            status: ToastStatusEnum.SUCCESS,
          });
        },
        onError: (error) => {
          const name =
            data?.salesPresentationPresentationArchive?.salesPresentation?.name;
          toast({
            id: 'sales-presntation-archive-failure-toast',
            description: `Your sales presentation "${name}" was not successfully deleted`,
            status: ToastStatusEnum.ERROR,
          });
          Sentry.captureException(error);
        },
      },
    );

  const handleClickCancelDelete = () => {
    setCurrentSalesPresentation(initialState);
  };

  const handleClickConfirmDelete = async () => {
    salesPresentationArchive();
    handleClickCancelDelete();
  };

  const showConfirmDeleteModal = (
    presentation: CurrentSelectedPresentation,
  ) => {
    setCurrentSalesPresentation(presentation);
  };

  return (
    <Box flexDirection="column" width="100%">
      <Table size="small">
        <Thead>
          <Tr>
            <Th>Presentation name</Th>
            <Th>
              <Box alignItems="center">
                Status
                <Tooltip
                  hasArrow
                  placement="top-start"
                  label="Active presentation will be visible and accessible to all users. Inactive presentation will not be visible to any user."
                >
                  <Icon icon={iInfo} color="neutral.600" marginLeft={200} />
                </Tooltip>
              </Box>
            </Th>
            <Th>Last update</Th>
            <Th />
          </Tr>
        </Thead>
        <LoadingOverlay isLoading={loading} />
        <Tbody data-test-id="salesPresentationTable">
          {salesPresentations?.map((presentation: SalesPresentation) => (
            <SalesPresentationRow
              key={presentation.id}
              presentation={presentation}
              showConfirmDeleteModal={showConfirmDeleteModal}
            />
          ))}
        </Tbody>
      </Table>
      <ConfirmDeleteModal
        isOpen={currentSalesPresentation.id !== ''}
        isLoading={false}
        message={`Are you sure you want to delete the "${currentSalesPresentation.name}" sales presentation?`}
        onCancel={handleClickCancelDelete}
        onConfirm={handleClickConfirmDelete}
      />
    </Box>
  );
};
