import { useCallback, ReactNode, useEffect } from 'react';

import { Box, Button, Icon } from '@hover/blueprint';
import { iArrowLeft, iX } from '@hover/icons';

import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { Divider } from 'src/components/Misc';
import { Modal } from 'style-guide';

interface IProps {
  header: ReactNode;
  body: ReactNode;
  handleClose: () => void;
  handleBack?: () => void;
  isLoading?: boolean;
}

export const ProposalModal: React.FC<IProps> = ({
  header,
  body,
  handleClose,
  handleBack,
  isLoading,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  const handleClickClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const backButton = handleBack ? (
    <Button
      fill="minimal"
      shape="circle"
      onClick={handleBack}
      data-test-id="proposalModal-Back"
      label="proposalModal-Back"
    >
      <Icon icon={iArrowLeft} color="neutral700" />
    </Button>
  ) : (
    <div style={{ marginRight: '32px' }} />
  );

  return (
    <>
      {isLoading && <LoaderSpinner show />}
      <Modal
        isOpen
        contentStyle={{
          padding: 0,
          width: '90%',
          minWidth: '320px',
          maxWidth: '570px',
          minHeight: '600px',
          textAlign: 'initial',
        }}
        data-test-id="proposalModal"
      >
        <Box
          justifyContent="space-between"
          alignItems="center"
          padding={400}
          flexShrink={0}
        >
          {backButton}
          <Box data-test-id="proposalModal-header">{header}</Box>
          <Button
            fill="minimal"
            shape="circle"
            onClick={handleClickClose}
            data-test-id="proposalModal-Close"
            label="proposalModal-Close"
          >
            <Icon icon={iX} color="neutral700" />
          </Button>
        </Box>
        <Divider />
        <Box flex={1} justifyContent="flex-start" flexDirection="column">
          <Box flex={1} data-test-id="proposalModal-body">
            {body}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
