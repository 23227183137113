import { PureComponent } from 'react';

import { Button } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { withTypewriter } from 'src/components/WithTypewriter';
import { BackButton } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BackButton';
import { BottomBarLayout } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarLayout';
import {
  SIDING_FACET_SELECTION_3D,
  ROOF_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import {
  getQuestionsForCategory,
  getPages,
  getAreAnyCheckboxesAnswered,
  getJobDetails,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { NAVIGATION_DIRECTION } from 'src/features/exteriorEstimator/types/Navigation';
import * as miscUtils from 'src/features/exteriorEstimator/utils/miscUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

export const mapStateToProps = (state: RootState) => ({
  category: state.exteriorEstimator.inputs.currentQuestionCategory,
  questions: getQuestionsForCategory(state),
  allPages: getPages(state),
  questionResponses: state.exteriorEstimator.inputs.questionResponses,
  areAnyCheckboxesAnswered: getAreAnyCheckboxesAnswered(state),
  selectedTemplates: state.exteriorEstimator.inputs.selectedTemplates,
  jobDetails: getJobDetails(state),
  userTrackingProps: getUserTrackingProps(state),
});

interface OwnProps {
  children?: React.ReactNode;
  navigateCategories: (
    direction: NAVIGATION_DIRECTION,
    forcedTargetCategoryIndex: number | null,
  ) => void;
  typewriter: any;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export class BottomBarComponent extends PureComponent<Props> {
  @autobind
  handleNextClick() {
    const {
      navigateCategories,
      jobDetails,
      userTrackingProps,
      category,
      typewriter,
    } = this.props;

    typewriter?.buttonPressed({
      button_text: 'Next',
      page_or_screen_name: category,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...userTrackingProps,
    });

    navigateCategories(NAVIGATION_DIRECTION.FORWARD, null);
  }

  @autobind
  renderNextButton({
    clickHandler,
    shouldDisableAction,
  }: {
    clickHandler: () => void;
    shouldDisableAction: boolean;
  }) {
    const { category, areAnyCheckboxesAnswered } = this.props;
    const isPartialFacetSelectionPage =
      category === ROOF_FACET_SELECTION_3D ||
      category === SIDING_FACET_SELECTION_3D;

    const shouldDisablePartialSelectionPage = isPartialFacetSelectionPage
      ? !areAnyCheckboxesAnswered
      : false;

    const isDisabled = shouldDisableAction || shouldDisablePartialSelectionPage;

    return (
      <Button
        shape="pill"
        size="large"
        onClick={clickHandler}
        isDisabled={isDisabled}
        data-test-id="estimatorNextButton"
      >
        Next
      </Button>
    );
  }

  @autobind
  backButtonClick() {
    const { jobDetails, userTrackingProps, category, typewriter } = this.props;

    typewriter.buttonPressed({
      button_text: 'Back',
      page_or_screen_name: category,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...userTrackingProps,
    });
  }

  render() {
    const { questions, questionResponses, children, navigateCategories } =
      this.props;

    const clickHandler = this.handleNextClick;

    // make sure no selects are de-selected for current category
    const areAllSelectsAnswered = miscUtils.getAreAllSelectsAnswered({
      questions,
      questionResponses,
    });

    const shouldDisableAction =
      questions.length === 0 || !areAllSelectsAnswered;

    return (
      <BottomBarLayout>
        <BackButton
          onClickBack={this.backButtonClick}
          navigateCategories={navigateCategories}
        />
        {children}
        {this.renderNextButton({
          clickHandler,
          shouldDisableAction,
        })}
      </BottomBarLayout>
    );
  }
}

export const BottomBar = connect(mapStateToProps)(
  withTypewriter(BottomBarComponent),
);
