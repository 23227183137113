const download = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);

  a.setAttribute('download', name);

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadPdf = async ({
  url,
  name,
}: {
  url: string;
  name: string;
}) => {
  try {
    const res = await fetch(url);
    if (res.status >= 400 && res.status < 600) {
      // Fetch doesn't throw errors
      throw new Error('Bad response from server');
    }
    const blob = await res.blob();

    // TODO: Remove ternary and replace with `name` when old production console is deprecated.
    const filename = name.endsWith('pdf') ? name : `${name}.pdf`;

    download(blob, filename);
    return true;
  } catch (error) {
    return false;
  }
};

export const downloadCsv = (data: string, name: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  download(blob, name);
};
