import {
  Body,
  Box,
  Checkbox,
  Field,
  TextInput,
  Select,
} from '@hover/blueprint';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

type Props = {
  tradeOptions: ReadonlyArray<{ value: string; displayValue: string }> | null;
  page: string;
};

export const SectionHeaderPanel: React.FC<Props> = ({ tradeOptions, page }) => {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: boolean) => void,
    block: string,
    label: string,
  ) => {
    onChange(e.target.checked);
    typewriter.checkboxSelected({
      selection: e.target.checked.toString(),
      options: label,
      page_or_screen_name: page,
      ...commonTrackingProps,
    });
  };

  return (
    <Box flexDirection="column" alignItems="stretch">
      <Field
        data-test-id="sectionTitleField"
        error={errors?.sectionTitle?.message}
        label="Section Title"
        name="sectionTitle"
      >
        <TextInput
          data-test-id="sectionTitle"
          {...register('sectionTitle', {
            required: 'Title is required',
          })}
          placeholder="Add Section"
        />
      </Field>
      {tradeOptions ? (
        <Field label="Applies to" name="appliesTo">
          <Select {...register('appliesTo')} data-test-id="appliesToSelect">
            {tradeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.displayValue}
              </option>
            ))}
          </Select>
        </Field>
      ) : (
        <Field label="Applies to" name="appliesTo">
          <Select disabled style={{ cursor: 'auto' }}>
            <option key="ALL" value="ALL">
              All Trades
            </option>
          </Select>
        </Field>
      )}
      <Box marginBottom={400}>
        <Controller
          control={control}
          name="showBrandingOnHeader"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) =>
                handleCheckboxChange(e, onChange, '', 'showBrandingOnHeader')
              }
              isChecked={value}
              data-test-id="showBrandingOnHeader"
            >
              <Body size={500} margin={0} color="neutral700">
                Show Branding on Header
              </Body>
            </Checkbox>
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="showSectionTitle"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={(e) =>
                handleCheckboxChange(e, onChange, '', 'showSectionTitle')
              }
              isChecked={value}
              data-test-id="showSectionTitle"
            >
              <Body size={500} margin={0} color="neutral700">
                Show Section Title
              </Body>
            </Checkbox>
          )}
        />
      </Box>
    </Box>
  );
};
