import { useDispatch } from 'react-redux';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { Page } from 'src/features/exteriorEstimator/types/Questions';

export function usePageUtils() {
  const dispatch = useDispatch();

  const togglePageVisibility = (
    pages: Page[],
    category: string,
    shouldRender: boolean,
  ) => {
    const pageIndex = pages.findIndex((page) => page.category === category);
    dispatch(estimatorActions.shouldRenderPage({ pageIndex, shouldRender }));
  };

  return [togglePageVisibility] as const;
}
