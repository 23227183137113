import { get } from 'lodash';
import { createReducer } from 'typesafe-actions';

import { TradeTypes } from '../../constants/multiTrades';
import { Problem } from '../../types';
import {
  insertListItem,
  insertListItems,
} from '../../utils/ProductionListUtils';
import {
  getProductionData,
  getProductionDataEnd,
  updateListItemEnd,
  createListItemEnd,
  sagaError,
  toggleShouldShowAddListItemModal,
  updateAddListItemToast,
  toggleShouldShowSpinner,
  receiveAddListItemErrors,
  clearAddListItemErrors,
  receiveListItemsWithProducts,
  toggleOrderModal,
  setVendorForOrder,
  togglePdfModal,
  updatePdfModalState,
  customizePdfOutput,
  updateOrderDetailsForm,
  createPdf,
  webDownloadPdf,
  updateTradeFilter,
  updateErrorModal,
} from '../actions';
import initialState from '../initialState';

const projectManagementReducer = createReducer(initialState)
  .handleAction(getProductionData, (state) => ({
    ...state,
    errorModal: {
      isOpen: false,
      message: null,
    },
    isGettingProductionData: true,
    tradeFilter: TradeTypes.ALL_TRADES,
  }))
  .handleAction(
    toggleShouldShowAddListItemModal,
    (state, { payload: { shouldShowAddListItemModal } }) => ({
      ...state,
      shouldShowAddListItemModal,
    }),
  )
  .handleAction(
    updateAddListItemToast,
    (
      state,
      { payload: { addListItemToastText, shouldShowAddListItemToast } },
    ) => ({
      ...state,
      addListItemToastText,
      shouldShowAddListItemToast,
    }),
  )
  .handleAction(
    toggleShouldShowSpinner,
    (state, { payload: { shouldShowSpinner } }) => ({
      ...state,
      shouldShowSpinner,
    }),
  )
  .handleAction(getProductionDataEnd, (state, action) => ({
    ...state,
    isGettingProductionData: false,
    jobDetails: action.payload.jobDetails,
    jobMeasurements: action.payload.jobMeasurements,
    estimateDetails: action.payload.estimateDetails,
    estimateUser: action.payload.user,
    productionList: action.payload.productionList,
    vendors: action.payload.vendors,
    salesOpportunities: action.payload.salesOpportunities,
  }))
  .handleAction(
    updateListItemEnd,
    (state, { payload: { listItem, productionList } }) => {
      const list = state.productionList;
      return {
        ...state,
        productionList: {
          ...list,
          ...productionList,
          listItems: insertListItem(list.listItems, listItem),
        },
      };
    },
  )
  .handleAction(
    createListItemEnd,
    (state, { payload: { productionList, listItem } }) => {
      const list = state.productionList;

      return {
        ...state,
        productionList: {
          ...list,
          ...productionList,
          listItems: insertListItem(state.productionList.listItems, listItem),
        },
      };
    },
  )
  .handleAction(sagaError, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(
    receiveAddListItemErrors,
    (state, { payload: { addListItemErrors } }) => {
      const problems = get(
        addListItemErrors,
        'graphQLErrors[0].extensions.problems',
      );
      const errorMap: { [key: string]: string } = {};
      problems.forEach((problem: Problem) => {
        const { path, explanation } = problem;
        const field = path[0];
        errorMap[field] = explanation;
      });
      return {
        ...state,
        addListItemErrors: errorMap,
      };
    },
  )
  .handleAction(clearAddListItemErrors, (state) => ({
    ...state,
    addListItemErrors: {},
  }))
  .handleAction(
    receiveListItemsWithProducts,
    (state, { payload: { currentListItems, listItemsWithProduct } }) => ({
      ...state,
      productionList: {
        ...state.productionList,
        listItems: insertListItems(currentListItems, listItemsWithProduct),
      },
    }),
  )
  .handleAction(toggleOrderModal, (state, { payload: { show } }) => ({
    ...state,
    shouldShowOrderModal: show,
  }))
  .handleAction(
    togglePdfModal,
    (state, { payload: { show, type, vendor } }) => ({
      ...state,
      shouldShowPdfModal: show,
      pdf: {
        ...state.pdf,
        type: show && type ? type : null,
        vendor: vendor || null,
        error: null,
      },
    }),
  )
  .handleAction(
    updatePdfModalState,
    (state, { payload: { pdfModalState } }) => ({
      ...state,
      pdf: {
        ...state.pdf,
        pdfModalState,
      },
    }),
  )
  .handleAction(setVendorForOrder, (state, { payload: { vendor } }) => ({
    ...state,
    projectManagementOrderData: {
      ...state.projectManagementOrderData,
      vendorForOrder: vendor,
    },
  }))
  .handleAction(updateOrderDetailsForm, (state, { payload }) => ({
    ...state,
    orderDetailsForm: {
      ...state.orderDetailsForm,
      errors: {
        ...state?.orderDetailsForm?.errors,
        ...payload?.errors,
      },
      values: {
        ...state?.orderDetailsForm?.values,
        ...payload?.values,
      },
    },
  }))
  .handleAction(customizePdfOutput, (state, action) => ({
    ...state,
    pdf: {
      ...state.pdf,
      shouldShowCostDetails:
        action.payload.shouldShowCostDetails !== undefined
          ? action.payload.shouldShowCostDetails
          : state.pdf.shouldShowCostDetails,
      shouldShowTotalCost:
        action.payload.shouldShowTotalCost !== undefined
          ? action.payload.shouldShowTotalCost
          : state.pdf.shouldShowTotalCost,
      shouldShowItemMeasurements:
        action.payload.shouldShowItemMeasurements !== undefined
          ? action.payload.shouldShowItemMeasurements
          : state.pdf.shouldShowItemMeasurements,
      notes:
        action.payload.notes !== undefined
          ? action.payload.notes
          : state.pdf.notes,
    },
  }))

  .handleAction(createPdf.request, (state) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: true,
      error: null,
    },
  }))
  .handleAction(createPdf.success, (state) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: false,
    },
  }))
  .handleAction(createPdf.failure, (state, action) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: false,
      error: action.payload,
    },
  }))
  .handleAction(webDownloadPdf.request, (state) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: true,
    },
  }))
  .handleAction(webDownloadPdf.success, (state) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: false,
    },
  }))
  .handleAction(webDownloadPdf.failure, (state, action) => ({
    ...state,
    pdf: {
      ...state.pdf,
      isFetching: false,
      error: action.payload,
    },
  }))
  .handleAction(updateTradeFilter, (state, { payload: tradeFilter }) => ({
    ...state,
    tradeFilter,
  }))
  .handleAction(
    updateErrorModal,
    (state, { payload: { isOpen, message = null } }) => ({
      ...state,
      errorModal: {
        ...state.errorModal,
        isOpen,
        message,
      },
    }),
  );

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default projectManagementReducer;
