import { createAsyncAction, createAction } from 'typesafe-actions';

import { profile as Profile } from 'src/api/types/profile';
import { AUTHENTICATION_STATES } from 'src/types';

export const getUserProfile = createAsyncAction(
  'HOVER/GET_USER_PROFILE_REQUEST',
  'HOVER/GET_USER_PROFILE_END', // needs to be called _END for  waitFor() to work
  'HOVER/GET_USER_PROFILE_FAILURE',
)<void, Profile, Error>();

export const setAuthStatus = createAction(
  'EHI/INIT/SET_AUTH_STATUS',
  (status: AUTHENTICATION_STATES) => status,
)();

export const setSplitStatus = createAction(
  'EHI/INIT/SET_SPLIT_STATUS',
  (isSplitInitialized: boolean) => isSplitInitialized,
)();

export const getEhiOrgSettings = createAction('EHI/GET_EHI_ORG_SETTINGS')();
