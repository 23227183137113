import { useEffect, useState } from 'react';

import { Box } from '@hover/blueprint';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';

import { JOB_DETAILS } from 'src/features/exteriorEstimator/apis/queries';
import { ESTIMATOR } from 'src/features/exteriorEstimator/constants/urls';
import { useEstimate } from 'src/features/exteriorEstimator/hooks';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getModelId,
  getPath,
  getSelectedTemplateIds,
  getJobDetails,
  getError,
  getParams,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useQueryEhi, useEffectOnMount } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import {
  getTradeTypesSorted,
  getLocation,
  getOrgSettings,
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { emitPageAction, NewRelicEventType } from 'src/utils/newrelic/page';

import { EstimatorError } from './Error/EstimatorError';

export interface RouteConfigRoute {
  path: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any; // TODO @nickcobbett
  routes?: RouteConfigRoute[];
}

interface OwnProps {
  routes: RouteConfigRoute[];
}

export const EstimationTool: React.FC<OwnProps & RouteComponentProps> = (
  theseProps: OwnProps & RouteComponentProps,
) => {
  const { jobId } = useSelector(getParams);
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const error = useSelector(getError);
  const jobDetails = useSelector(getJobDetails);
  const { setupEstimatorForRefresh, isFetchingQuestions } = useEstimate();
  const { routes, match } = theseProps;
  const tradeTypes = useSelector(getTradeTypesSorted);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const currentPath = useSelector(getPath);
  const history = useHistory();
  const { searchParams } = useSearchParams();
  const modelId = useSelector(getModelId);

  const orgSettings = useSelector(getOrgSettings);
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && orgSettings?.showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffectOnMount(() => {
    emitPageAction(NewRelicEventType.COMPONENT_MOUNTED);
    if (currentPath === ESTIMATOR)
      history.push({
        pathname: '/estimator/questions/select_templates',
        search: searchParams.toString(),
      });
  });

  useEffect(() => {
    async function loadMacheteLib() {
      if (window.machete) return;
      const machete = (
        await import(
          /* webpackChunkName: "machete" */
          '@hover/machete'
        )
      ).default;
      window.machete = machete;
    }
    loadMacheteLib();
  });

  const { data: jobDetailsData, error: jobDetailsError } = useQueryEhi(
    JOB_DETAILS,
    {
      variables: { ids: [Number(jobId)] },
    },
  );

  useEffect(() => {
    if (!jobDetailsData) return;
    const result = jobDetailsData?.jobs?.results?.[0];
    if (result) {
      dispatch(estimatorActions.getJobDetails.success({ jobDetails: result }));
    } else {
      dispatch(estimatorActions.setError(true));
    }
  }, [jobDetailsData, dispatch]);

  useEffect(() => {
    if (
      location.pathname.endsWith('history') ||
      location.pathname.endsWith('select_templates')
    )
      return;
    /* If you navigate directly to an estimator page or refresh while in the middle,
       We need to initialize all the pages/questions/templates so you can still navigate around 
    */

    if (!isEmpty(tradeTypes) && orgSettings && modelId && !loaded) {
      setLoaded(true);

      setupEstimatorForRefresh(
        selectedTemplateIds,
        !!showOrderOrMaterialListVersion,
        orgSettings,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tradeTypes,
    selectedTemplateIds,
    showOrderOrMaterialListVersion,
    orgSettings,
    modelId,
  ]);

  if (error || jobDetailsError || jobDetails === undefined)
    return <EstimatorError />;

  return (
    <Box
      height="100vh"
      position="relative"
      flexDirection="column"
      overflowY="auto"
      overflowX="hidden"
    >
      <Switch location={location}>
        {routes.map((route) => (
          <Route
            path={`${match.path}${route.path}`}
            key={route.path}
            render={(props) => (
              <route.component
                {...props}
                isFetchingQuestions={isFetchingQuestions}
                routes={route.routes}
              />
            )}
          />
        ))}
      </Switch>
    </Box>
  );
};
