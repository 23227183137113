import { captureException } from '@sentry/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const DEFAULT_DOWNLOAD_NAME = 'images';

const downloadImages = (
  imageUrls: string[],
  fileName = DEFAULT_DOWNLOAD_NAME,
) => {
  const zip = new JSZip();
  const folder = zip.folder(fileName);
  imageUrls.forEach((imageUrl: string) => {
    const imagePromise = fetch(imageUrl).then((r) => r.blob());
    const name = imageUrl.split('/').pop() || '';
    folder?.file(name, imagePromise);
  });

  zip
    .generateAsync({ type: 'blob' })
    .then((blob) => saveAs(blob, fileName))
    .catch((e) => captureException(e));
};
export { downloadImages };
