import { PureComponent } from 'react';

import * as Sentry from '@sentry/react';
import { RouterLocation } from 'connected-react-router';
import { isNil } from 'lodash';
import { parse, stringify, stringifyUrl } from 'query-string';
import { connect } from 'react-redux';

import { ProductCatalogDistributorIdentifierEnum } from 'src/api/graphql-global-types';
import appSettings from 'src/appSettings';
import {
  getRedirectUrlFromCache,
  clearRedirectUrlFromCache,
  clearOauthUrlFromCache,
} from 'src/features/exteriorEstimator/utils/cacheUtils';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';
import { getLocation, getUserProfile } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';

export const mapStateToProps = (state: RootState) => ({
  location: getLocation(state),
  userProfile: getUserProfile(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const getDistributorFromLocation = (location: RouterLocation<unknown>) => {
  if (location.pathname.includes('beacon')) {
    // Beacon does not support the state parameter, but has its own route
    return ProductCatalogDistributorIdentifierEnum.BEACON;
  }

  const distributorName: string = location.query.state?.toUpperCase();
  return ProductCatalogDistributorIdentifierEnum[
    distributorName as ProductCatalogDistributorIdentifierEnum
  ];
};

export const ProductCatalogRoutes = {
  [ProductCatalogDistributorIdentifierEnum.ABC]:
    '/api/distribution/oauth/callbacks/abc',
  [ProductCatalogDistributorIdentifierEnum.BEACON]:
    '/api/distribution/oauth/callbacks/beacon',
  [ProductCatalogDistributorIdentifierEnum.SRS]:
    '/api/distribution/oauth/callbacks/srs',
  [ProductCatalogDistributorIdentifierEnum.WIMSATT]: '', // not officially supported yet
};

export const redirectOauth = async ({
  distributor,
  orgId,
  search,
}: {
  distributor: ProductCatalogDistributorIdentifierEnum;
  orgId: number;
  search: string;
}) => {
  let queryParams = search.substring(1);
  queryParams = queryParams.concat(`&org_id=${orgId}`);

  try {
    // Check for OAuth authorization error.
    const { error } = parse(window.location.search);

    const hyperionBaseUrl = appSettings.HYPERION_API_SERVER;
    if (isNil(error)) {
      const queryObject = parse(queryParams);
      const productCatalogUrl = stringifyUrl({
        url: ProductCatalogRoutes[distributor],
        query: queryObject,
      });
      // Save the new valid OAuth token in ProductCatalog.
      await HoverRequest.get({
        path: productCatalogUrl,
        baseUrl: appSettings.PLANET_TERROR_SERVER,
      });
      // Check for a specific redirect page location stored in localStorage.
      const redirectPage = getRedirectUrlFromCache();

      if (!isNil(redirectPage)) {
        // If there's a stored source page, redirect to that, after clearing the saved page.
        clearRedirectUrlFromCache();
        clearOauthUrlFromCache();

        window.location.replace(`${redirectPage}`);
      } else {
        // Otherwise redirect to the Settings|Integrations page, with possible error as query param.
        window.location.replace(
          `${hyperionBaseUrl}/ui/${!isNil(error) ? '?' : ''}${stringify({
            error,
          })}#/hvr/settings/integrations`,
        );
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

class OauthCallback extends PureComponent<Props> {
  componentDidMount() {
    const { location, userProfile } = this.props;
    const distributor = getDistributorFromLocation(location);
    if (!userProfile) return;
    const {
      orgs: [{ id: orgId }],
    } = userProfile;
    redirectOauth({ distributor, orgId, search: location.search });
  }

  public render() {
    return null;
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, null)(OauthCallback);
