import { useMutation } from '@apollo/client';
import { connect, useSelector } from 'react-redux';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { projectManagementOrderCreate_projectManagementOrderCreate as OrderCreatePayload } from 'src/features/projectManagement/apis/graphql/queries/types';

import { PROJECT_MANAGEMENT_ORDER_CREATE } from 'src/features/projectManagement/apis/graphql/queries/orders';
import {
  getProviderOrderAttributes,
  getOrgIdParam,
} from 'src/features/projectManagement/redux/selectors';
import { VendorType } from 'src/features/projectManagement/types';
import { RootState } from 'src/types/reduxStore';

import { Button } from './styled';

interface ProjectManagementOrderCreateResp {
  projectManagementOrderCreate: OrderCreatePayload;
}

interface Props {
  vendor: VendorType;
  handleCreatePdf: (orderId?: string) => void;
  orgId: string;
}
const mapStateToProps = (state: RootState) => ({
  orgId: getOrgIdParam(state),
});

const DownloadPdfButtonComp: React.FC<Props> = ({
  vendor,
  handleCreatePdf,
  orgId,
}) => {
  const orderAttributes = useSelector(getProviderOrderAttributes(vendor));

  const onCompleted = ({
    projectManagementOrderCreate: { order },
  }: ProjectManagementOrderCreateResp) => {
    handleCreatePdf(order?.id);
  };

  const [projectManagementOrderCreate, { loading }] = useMutation(
    PROJECT_MANAGEMENT_ORDER_CREATE,
    {
      onCompleted,
    },
  );

  const handleClick = () => {
    projectManagementOrderCreate({
      variables: {
        orderAttributes,
        orgId,
      },
    });
  };

  return (
    <Button
      onClick={handleClick}
      data-test-id="downloadPdfButton"
      disabled={loading}
    >
      Download PDF
    </Button>
  );
};

export const DownloadPdfButton = connect(
  mapStateToProps,
  null,
)(DownloadPdfButtonComp);
