import { SIDING_ARGUMENT_MAP } from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import {
  Input,
  Page,
  QuestionId,
  QuestionResponses,
  PristineMeasurement,
  QuestionAnswer,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';
import {
  HDFMeasurements,
  PartialsMeasurements,
} from 'src/features/exteriorEstimator/types/HdfMeasurements';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import {
  getPageForCategory,
  getQuestionById,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import { COMMERCE_USE_HDF, isEnabled } from 'src/lib/FeatureFlag';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { Measurements } from 'src/types/EstimationMeasurementTypes';
// returns ROOF/SIDING partial selection QuestionResponses, reset back to their defaultValue or true
// occurs when toggling back to FULL roof after making partial selections
export const resetPartialQuestions = (category: string, pages: Page[]) => {
  const partialQuestionPage = getPageForCategory(pages, category);
  if (!partialQuestionPage) return {};
  const { questions: partialFacetQuestions } = partialQuestionPage;
  const newAnswers = partialFacetQuestions?.reduce(
    (answers: QuestionResponses, question) => {
      // eslint-disable-next-line no-param-reassign
      answers[question.id] = question.questionDefaultValue ?? true;
      return answers;
    },
    {},
  );
  return newAnswers;
};

// returns only the measurement QuestionResponses reset back to their initial values
// occurs if partial selection has changed a measurement value but now we want to revert the changes
export const resetToPristineMeasurements = (
  pristineMeasurements: PristineMeasurement[],
) => {
  const newAnswers = pristineMeasurements.reduce(
    (answers: QuestionResponses, item) => {
      // eslint-disable-next-line no-param-reassign
      answers[item.questionId] = item.answer;
      return answers;
    },
    {},
  );
  return newAnswers;
};

// returns updated roofTotal and pitch QuestionResponses based on a roof facet selection
export const updatePartialRoofCalculations = (
  questionId: QuestionId,
  answer: QuestionAnswer,
  pages: Page[],
  questionResponses: QuestionResponses,
  measurementQuestions: Input[],
) => {
  const question = getQuestionById(pages, questionId);
  if (!question) return {}; // TS
  const { pitchQuestion, roofTotalQuestion } =
    PartialRoofUtils.getPitchQuestionsForPitch(pages, question.pitch ?? '');

  const currentPitchValue = Number(questionResponses[pitchQuestion?.id ?? -1]);
  const pitchChange = answer ? question.area : Number(question.area) * -1;
  const newPitchValue = Math.max(
    Number(currentPitchValue) + Number(pitchChange),
    0,
  );
  if (!pitchQuestion) {
    // no measurement question found for this particular pitch
    return {};
  }
  const newAnswers = {
    ...questionResponses,
    [pitchQuestion.id]: newPitchValue,
  };
  const roofTotalFromPitches = measurementQuestions.reduce((sum, q) => {
    if (PartialRoofUtils.isPitch(q)) {
      const response = newAnswers[q.id];
      return sum + (Number(response) || 0);
    }
    return sum;
  }, 0);

  return {
    [pitchQuestion.id]: newPitchValue,
    ...(!!roofTotalQuestion && {
      [roofTotalQuestion.id]: roofTotalFromPitches,
    }),
  };
};

// returns updated totals and/or edge QuestionResponses based on a partial selection
export const getUpdatedLineSegmentValues = ({
  facetLabel,
  answer,
  answers,
  plainMeasurements,
  fullMeasurements,
  lineSegmentInputs,
  partialsMeasurements,
  hdfMeasurements,
  type,
  questionResponses,
  sidingTotalQuestion,
  sidingWithOpeningsAreaQuestion,
  orgSettings,
}: {
  facetLabel?: string | null;
  answer?: boolean | null;
  answers?: QuestionResponses | null;
  lineSegmentInputs: Input[];
  type: 'SIDING' | 'ROOF';
  partialsMeasurements: PartialsMeasurements | null;
  hdfMeasurements: HDFMeasurements | null;
  plainMeasurements: PlainMeasurements | null;
  fullMeasurements: Measurements | null;
  questionResponses: QuestionResponses;
  sidingTotalQuestion?: Input | null | undefined;
  sidingWithOpeningsAreaQuestion?: Input | null | undefined;
  orgSettings: EhiOrgSettings;
}) => {
  let calculator;
  const newAnswers: QuestionResponses = {};

  if (isEnabled(COMMERCE_USE_HDF) && partialsMeasurements && hdfMeasurements) {
    const { edges, facades, windowsForFacade } = partialsMeasurements;
    if (facetLabel) {
      calculator = new LineSegmentCalculatorHdf({
        edges,
        facades,
        windowsForFacade,
        hdfMeasurements,
        questionResponses: {
          ...questionResponses,
          [facetLabel]: answer,
        },
        orgSettings,
      });
    } else if (answers) {
      calculator = new LineSegmentCalculatorHdf({
        edges,
        facades,
        windowsForFacade,
        hdfMeasurements,
        questionResponses: {
          ...questionResponses,
          ...answers,
        },
        orgSettings,
      });
    }
  } else if (plainMeasurements && fullMeasurements) {
    if (facetLabel) {
      calculator = new LineSegmentCalculator({
        pristinePlainMeasurements: plainMeasurements,
        estimationJson: fullMeasurements,
        questionResponses: {
          ...questionResponses,
          [facetLabel]: answer,
        },
        orgSettings,
      });
    } else if (answers) {
      calculator = new LineSegmentCalculator({
        pristinePlainMeasurements: plainMeasurements,
        estimationJson: fullMeasurements,
        questionResponses: {
          ...questionResponses,
          ...answers,
        },
        orgSettings,
      });
    }
  }

  if (!calculator) return {};

  const { calculatedEdgeTotals, selectedAreaWithTrim } = calculator;

  if (type === 'SIDING' && sidingTotalQuestion) {
    newAnswers[sidingTotalQuestion.id] = selectedAreaWithTrim;
  }

  if (type === 'SIDING' && sidingWithOpeningsAreaQuestion) {
    newAnswers[sidingWithOpeningsAreaQuestion.id] = selectedAreaWithTrim;
  }

  const edgeAnswers: QuestionResponses = {};
  Object.entries(calculatedEdgeTotals).forEach(
    ([lineSegmentArgument, value]) => {
      // lineSegmentArgument = ridge_total | ridge_count | etc...

      // see if line segment maps to a specfic input / question
      let lineSegmentInput;
      if (type === 'SIDING') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            SIDING_ARGUMENT_MAP[question.argument ?? ''] ===
            lineSegmentArgument,
        );
      } else if (type === 'ROOF') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) => question.argument === lineSegmentArgument,
        );
      }

      if (lineSegmentInput) {
        edgeAnswers[lineSegmentInput.id] = parseFloat(value.toFixed(2));
      }
    },
  );
  return { ...newAnswers, ...edgeAnswers };
};
