import { createReducer, StateType } from 'typesafe-actions';

import {
  profile_profile_user as User,
  profile_profile_impersonator as Impersonator,
} from 'src/api/types/profile';
import { AUTHENTICATION_STATES } from 'src/types';

import { getUserProfile, setAuthStatus, setSplitStatus } from '../actions';

interface InitialState {
  impersonator: Impersonator | null;
  userProfile: User | undefined;
  authenticationStatus?: AUTHENTICATION_STATES;
  isSplitInitialized: boolean;
}
const initialState: InitialState = {
  impersonator: null,
  userProfile: undefined,
  authenticationStatus: undefined,
  isSplitInitialized: false,
};

const hoverReducer = createReducer(initialState)
  .handleAction(getUserProfile.success, (state, { payload }) => ({
    ...state,
    userProfile: {
      ...payload.profile.user,
    },
    impersonator: payload.profile.impersonator,
  }))
  .handleAction(setAuthStatus, (state, action) => ({
    ...state,
    authenticationStatus: action.payload,
  }))
  .handleAction(setSplitStatus, (state, action) => ({
    ...state,
    isSplitInitialized: action.payload,
  }));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default hoverReducer;

export type HoverReducerState = StateType<typeof hoverReducer>;
