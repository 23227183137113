import { useCallback, useEffect, useState } from 'react';

import { Box, Body, Icon } from '@hover/blueprint';
import { iX } from '@hover/icons';
import styled, { css } from 'styled-components';

const severityColors = {
  Info: 'secondaryBase',
  Error: 'dangerBase',
  Confirmation: 'successBase',
  Warning: 'warning400',
};

export type SeverityKeys = 'Info' | 'Error' | 'Confirmation' | 'Warning';

export const ToastContainer = styled.div<{
  show?: boolean;
  severity: string;
}>`
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: ${({ theme, severity }) =>
    severity
      ? theme.colors[severityColors[severity as SeverityKeys]]
      : theme.colors.defaultNavy};
  z-index: 1;
  ${({ show }) =>
    css`
      opacity: ${show ? '1' : '0'};
      transform: translateY(${show ? '0px' : '-36px'});
      transition: all 0.3s ease-in-out;
    `}
`;

export const ToastCloseButton = styled.button`
  background: none;
  border: none;
  padding: 0px 2px;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const InfoText = styled(Body)`
  color: ${({ theme }) => theme.colors.neutral000};
  padding: 0;
  margin: 0;
`;

const CloseIcon = styled(Box)`
  color: ${({ theme }) => theme.colors.neutral000};
  margin: 0px;
`;

interface IProps {
  severity: SeverityKeys;
  notification?: string | JSX.Element;
  showClose?: boolean;
  show?: boolean;
  autohide?: boolean;
  timeout?: number;
  clearError?: () => void;
}

export const ToastNotification: React.FC<IProps> = ({
  severity,
  notification,
  showClose = false,
  show = false,
  autohide = true,
  timeout = 3000,
  clearError,
}) => {
  const [shouldShow, setShouldShow] = useState(false);

  const close = useCallback(() => {
    setShouldShow(false);
    if (clearError) clearError();
  }, [clearError]);

  useEffect(() => {
    if (!show)
      return () => {
        // No Op
      };

    // Update visibility state after render, to enable initial transition.
    setTimeout(() => setShouldShow(show), 0);

    // When autohide is enabled, create a timer to hide the component.
    const timer = autohide
      ? setTimeout(() => {
          setShouldShow(false);
          if (clearError) clearError();
        }, timeout)
      : undefined;

    return () => {
      if (clearError) clearError();
      // In cleanup, clear the timeout if one was being used for autohide.
      if (autohide && timer) clearTimeout(timer);
    };
  }, [show, clearError, autohide, showClose, timeout]);

  return (
    <ToastContainer
      show={shouldShow}
      severity={severity}
      data-test-id="ToastContainer"
    >
      <Box flex="1" justifyContent="center" alignItems="center" padding={200}>
        <Body marginTop={0} marginBottom={0} size={300} color="neutral000">
          {notification}
        </Body>
      </Box>
      <CloseIcon
        style={
          showClose || !autohide ? { display: 'flex' } : { display: 'none' }
        }
        alignItems="center"
      >
        <ToastCloseButton onClick={close} data-test-id="CloseButton">
          <Icon icon={iX} color="neutral000" />
        </ToastCloseButton>
      </CloseIcon>
    </ToastContainer>
  );
};
