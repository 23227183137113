import { useCallback } from 'react';

import { Body, Box, Button, Heading, Panel } from '@hover/blueprint';
import numeral from 'numeral';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { ProductCatalogDistributorIdentifierEnum } from 'src/api/graphql-global-types';
import { OrderModalStates } from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import {
  areAnyOrderDetailsInputsInvalid as _areAnyOrderDetailsInputsInvalid,
  getVendorForOrderMaterialListItems,
  getOAuthErrorMessage,
  getErrorMessage,
} from 'src/features/projectManagement/redux/selectors';
import {
  sortByVendorWithTax,
  postTaxTotal,
} from 'src/features/projectManagement/utils/ProductionListUtils';
import { RootAction, RootState } from 'src/types/reduxStore';

import type { OrderFormFields } from '../Checkout';

const BoxWithDividerTop = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral300};
`;

export const mapStateToProps = (state: RootState) => ({
  orderModalState:
    state.estimatorProductionTools.projectManagementOrderData.orderModalState,
  areAnyOrderDetailsInputsInvalid: _areAnyOrderDetailsInputsInvalid(state),
  vendorForOrder:
    state.estimatorProductionTools.projectManagementOrderData.vendorForOrder,
  listItems: getVendorForOrderMaterialListItems(state),
  oauthErrorMessage: getOAuthErrorMessage(state),
  orderErrorMessage: getErrorMessage(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      submitAndPollDistributionOrder: actions.submitAndPollDistributionOrder,
      startOrderFlow: actions.startOrderFlow,
    },
    dispatch,
  );

export interface ParentProps {
  onPressCancel: () => void;
}

type Props = ParentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    orgId: string;
  };

export const OrderTotalsPanelComponent: React.FC<Props> = ({
  startOrderFlow,
  submitAndPollDistributionOrder,
  onPressCancel,
  orderModalState,
  vendorForOrder,
  areAnyOrderDetailsInputsInvalid,
  listItems,
  oauthErrorMessage,
  orderErrorMessage,
  orgId,
}) => {
  const {
    formState: { errors },
    handleSubmit,
  } = useFormContext<OrderFormFields>();

  const isErrorState = () =>
    orderModalState === OrderModalStates.OrderErrors ||
    orderModalState === OrderModalStates.GenericError;
  const distributor = vendorForOrder?.distributor;
  const shouldShowError =
    isErrorState() && !distributor?.supportsShowingOrderCheckResults;
  const genericErrorMessage =
    'Something went wrong during submitting. Try again or contact support';
  const errorMessage =
    oauthErrorMessage ?? orderErrorMessage ?? genericErrorMessage;

  /**
   * Validate the form-based fields, based on presence/absence
   * of form errors.
   */
  const isOrderFormValid = useCallback(() => {
    return !(Object.keys(errors).length > 0);
  }, [errors]);

  const onSubmitPress = (formValues: OrderFormFields) => {
    // On form submit, form-based values are retrieved from the form state
    const { distributionBranchId, distributionJobAccountId } = formValues;
    // If  SRS, always startOrderFlow from beginning each time the Submit button is pressed
    // should use same logic as in https://hoverinc.atlassian.net/browse/EHI-3531 instead of SRS check, probably
    if (
      distributor?.identifier === ProductCatalogDistributorIdentifierEnum.SRS
    ) {
      startOrderFlow({ distributionBranchId, distributionJobAccountId, orgId });
      // If not SRS, submit or start depending on whether there are errors (with order Check )
    } else if (isErrorState()) {
      submitAndPollDistributionOrder({
        distributionBranchId,
        distributionJobAccountId,
        orgId,
      });
    } else {
      startOrderFlow({ distributionBranchId, distributionJobAccountId, orgId });
    }
  };

  /**
   * Disable the submit button based on combination of
   * form-based validation rules and redux store validation.
   */
  const shouldDisableSubmit = () => {
    return !isOrderFormValid() || areAnyOrderDetailsInputsInvalid;
  };

  const orderItemsWithTotals = vendorForOrder
    ? sortByVendorWithTax(listItems)[vendorForOrder.vendorName]
    : null;
  const total = orderItemsWithTotals
    ? postTaxTotal(
        orderItemsWithTotals.subtotal,
        orderItemsWithTotals.salesTaxSubtotal,
      )
    : 0;

  return (
    <Panel
      boxShadow="distance600"
      alignItems="stretch"
      padding={600}
      data-test-id="orderTotalsPanel"
    >
      <Box justifyContent="space-between">
        <Body size={300} margin={0} color="neutral600">
          Subtotal
        </Body>
        <Body
          size={300}
          margin={0}
          color="neutral600"
          data-test-id="orderSubTotal"
        >
          {numeral(orderItemsWithTotals?.subtotal).format('$0,0.00')}
        </Body>
      </Box>
      <Box justifyContent="space-between">
        <Body size={300} margin={0} color="neutral600">
          Tax
        </Body>
        <Body size={300} margin={0} color="neutral600" data-test-id="orderTax">
          {numeral(orderItemsWithTotals?.salesTaxSubtotal).format('$0,0.00')}
        </Body>
      </Box>
      <BoxWithDividerTop
        justifyContent="space-between"
        marginY={400}
        paddingTop={400}
      >
        <Heading size={500} marginRight={400}>
          Total:
        </Heading>
        <Heading size={500} data-test-id="orderTotal">
          {numeral(total).format('$0,0.00')}
        </Heading>
      </BoxWithDividerTop>
      <Body size={300} color="neutral600" margin={0}>
        Pricing subject to change before invoicing
      </Body>
      {shouldShowError && (
        <Box
          width={1}
          maxWidth="208px"
          marginY={400}
          marginX={0}
          data-test-id="orderSubmit-errorMessage"
        >
          <Body size={300} margin={0} color="danger500">
            {errorMessage}
          </Body>
        </Box>
      )}
      <Box width={1} marginY={400}>
        <Button
          data-test-id="submitOrder-button"
          flex={1}
          onClick={handleSubmit(onSubmitPress)}
          isDisabled={
            orderModalState === OrderModalStates.Loading ||
            shouldDisableSubmit()
          }
          size="large"
          isLoading={orderModalState === OrderModalStates.Loading}
        >
          Submit order
        </Button>
      </Box>
      <Box width={1} margin={0}>
        <Button
          data-test-id="cancelOrder-button"
          flex={1}
          fill="minimal"
          onClick={onPressCancel}
          isDisabled={orderModalState === OrderModalStates.Loading}
          size="large"
        >
          Cancel order
        </Button>
      </Box>
    </Panel>
  );
};

export const OrderTotalsPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderTotalsPanelComponent);
