import { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Box,
  Heading,
  Loader,
  Table,
  Tbody,
  Thead,
  Link,
  Tr,
  Th,
} from '@hover/blueprint';
import { useSelector } from 'react-redux';

import {
  getOrgTemplates as GetTemplatesQuery,
  getOrgTemplates as Data,
  getOrgTemplates_estimationConfigTemplates_edges_node as TemplateType,
} from 'src/api/types/getOrgTemplates';
import { PageDirection, Pagination } from 'src/components/blueprint/Pagination';
import { GET_TEMPLATES } from 'src/features/settings/api/queries/templates';
import { Template } from 'src/features/settings/components/Templates/Template';
import { TemplateHistoryModal } from 'src/features/settings/components/Templates/TemplateHistoryModal';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { usePagination } from 'src/hooks/usePagination';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface TemplatesComponentProps {
  data?: Data;
  loading: boolean;
}

const TemplatesTable: React.FC<TemplatesComponentProps> = ({
  loading,
  data,
}) => {
  const [currentTemplate, setCurrentTemplate] = useState<TemplateType | null>(
    null,
  );
  const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] =
    useState(false);

  const openVersionHistoryModal = (template: TemplateType) => {
    setCurrentTemplate(template);
    setIsVersionHistoryModalOpen(true);
  };
  const closeVersionHistoryModal = () => {
    setCurrentTemplate(null);
    setIsVersionHistoryModalOpen(false);
  };

  if (loading || !data?.estimationConfigTemplates)
    return <Loader data-test-id="LineItemsSpinner" size="large" />;

  const {
    estimationConfigTemplates: { nodes },
  } = data;

  return (
    <Box flexDirection="column" width="100%">
      <Table size="small">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Trade</Th>
            <Th>Status</Th>
            <Th>Last update</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody data-test-id="ListItemTable">
          {nodes?.map(
            (template: TemplateType | null) =>
              template && (
                <Template
                  key={template.id}
                  template={template}
                  openVersionHistoryModal={openVersionHistoryModal}
                />
              ),
          )}
        </Tbody>
      </Table>
      {currentTemplate && (
        <TemplateHistoryModal
          isOpen={isVersionHistoryModalOpen}
          onClose={closeVersionHistoryModal}
          template={currentTemplate}
        />
      )}
    </Box>
  );
};

export const TemplatesList: React.FC = () => {
  const orgId = useSelector(getUserOrgId);
  const { updateParams, searchParams } = useSearchParams();
  const { onPaginate, paginationQueryParams } = usePagination();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const after = searchParams.get('after');
  const before = searchParams.get('before');

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.templates.page,
      ...commonTrackingProps,
    });
  });

  const { data, loading, fetchMore } = useQuery<GetTemplatesQuery>(
    GET_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orgId,
        ...paginationQueryParams({ after, before }),
      },
    },
  );

  const endCursor =
    data?.estimationConfigTemplates?.pageInfo?.endCursor ?? undefined;
  const startCursor =
    data?.estimationConfigTemplates?.pageInfo?.startCursor ?? undefined;

  const handlePageClick = (direction: PageDirection) => {
    onPaginate({ direction, updateParams, fetchMore, startCursor, endCursor });
  };

  const trackLearnMore = () => {
    typewriter.linkPressed({
      link_text: 'Learn More',
      primary_cta: false,
      page_or_screen_name: EventNames.settings.templates.page,
      ...commonTrackingProps,
    });
  };

  return (
    <>
      <Box
        justifyContent="space-between"
        alignItems="center"
        marginBottom={400}
      >
        <Heading data-test-id="TemplatesHeader" size={650} marginBottom="0px">
          Templates
        </Heading>
        <Link
          fontWeight="bold"
          href="https://help.hover.to/en/articles/6004506-template-management-customization"
          onClick={trackLearnMore}
        >
          Learn More
        </Link>
      </Box>
      <TemplatesTable loading={loading} data={data} />
      <Pagination
        hasPrevious={
          !!data?.estimationConfigTemplates?.pageInfo?.hasPreviousPage
        }
        hasNext={!!data?.estimationConfigTemplates?.pageInfo?.hasNextPage}
        handlePageClick={handlePageClick}
        resultsCount={data?.estimationConfigTemplates?.nodes?.length ?? 0}
      />
    </>
  );
};
