import { startsWith } from 'lodash';
import { createSelector } from 'reselect';

import {
  PARTIAL_SIDING_SELECTION,
  ROOF_FACET_SELECTION,
  ROOF_FACET_SELECTION_3D,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  SIDING_FACET_SELECTION_3D,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
} from 'src/features/exteriorEstimator/constants/questionCategories';

import { getPages } from './estimatorSelectors';

export const getSidingFacetInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === SIDING_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === PARTIAL_SIDING_SELECTION)
      ?.questions ?? []
  );
});

export const getBrickFacetInputs = createSelector(
  getSidingFacetInputs,
  (inputs) => {
    return inputs.filter((input) => startsWith(input?.name ?? '', 'BR-'));
  },
);

export const getStoneFacetInputs = createSelector(
  getSidingFacetInputs,
  (inputs) => {
    return inputs.filter((input) => startsWith(input?.name ?? '', 'STO-'));
  },
);

export const getSidingLineSegmentInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === SIDING_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === SIDING_LINE_SEGMENT_ADJUSTMENT)
      ?.questions ?? []
  );
});

export const getRoofLineSegmentInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === ROOF_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === ROOF_LINE_SEGMENT_ADJUSTMENT)
      ?.questions ?? []
  );
});

export const getRoofFacetInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === ROOF_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === ROOF_FACET_SELECTION)
      ?.questions ?? []
  );
});
