import { createReducer } from 'typesafe-actions';

import initialState from '../initialState';
import mainReducer from './mainReducer';
import projectManagementOrderReducer from './projectManagementOrderReducer';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default createReducer(initialState, {
  ...mainReducer.handlers,
  ...projectManagementOrderReducer.handlers,
});
