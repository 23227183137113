import { useState } from 'react';

import {
  Icon,
  Body,
  Box,
  Button,
  Checkbox,
  Heading,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Modal,
} from '@hover/blueprint';
import { hGripVertical, iEdit, iTrash } from '@hover/icons';
import { toUpper } from 'lodash';
import { useSelector } from 'react-redux';

import { estimationConfigTemplate_estimationConfigTemplate_templateGroups_lineItems as LineItem } from 'src/api/types/estimationConfigTemplate';
import { useTemplateTristateCheckbox } from 'src/features/settings/components/Templates/TemplateEdit/hooks';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

import type { DisplayedTemplateGroup } from './types';

export const TemplateGroupThead: React.FC<{
  onChange: () => void;
  allChecked: boolean;
  isIndeterminate: boolean;
  group: DisplayedTemplateGroup;
}> = ({ onChange, allChecked, isIndeterminate, group }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const selectAllClicked = () => {
    typewriter.checkboxSelected({
      selection: allChecked ? 'None' : 'All',
      options: 'Select All Line Items In Group',
      page_or_screen_name: EventNames.settings.template.page,
      backend_id_type: 'Template group',
      backend_id_value: group?.id?.toString(),
      ...commonTrackingProps,
    });

    onChange();
  };

  return (
    <Thead>
      <Tr data-test-id="templateGroupLineItemRow">
        <Th width="5%">
          <Checkbox
            isChecked={allChecked}
            onChange={selectAllClicked}
            isIndeterminate={isIndeterminate}
          />
        </Th>
        <Th>
          <Body
            color="neutral.600"
            size={400}
            fontWeight={500}
            marginBottom="0px"
            marginTop="0px"
          >
            {toUpper('Name')}
          </Body>
        </Th>
        <Th>
          <Body
            color="neutral.600"
            size={400}
            fontWeight={500}
            marginBottom="0px"
            marginTop="0px"
          >
            {toUpper('Type')}
          </Body>
        </Th>
      </Tr>
    </Thead>
  );
};

export const LineItemTr: React.FC<{
  lineItem: LineItem;
}> = ({ lineItem }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const selectLineItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    typewriter.checkboxSelected({
      selection: e.currentTarget.checked ? 'No' : 'Yes',
      options: 'Single Line Item',
      page_or_screen_name: EventNames.settings.template.page,
      backend_id_type: 'Line item',
      backend_id_value: lineItem.id.toString(),
      ...commonTrackingProps,
    });
  };

  return (
    <Tr>
      <Td width="5%">
        <Checkbox
          key={lineItem.id}
          id={lineItem.id.toString()}
          value={lineItem.id.toString()}
          onChange={selectLineItem}
        />
      </Td>
      <Td>{lineItem.name}</Td>
      <Td width="25%">{sentenceCase(lineItem.type ?? '')}</Td>
    </Tr>
  );
};

export const TemplateGroupTable: React.FC<{
  group: DisplayedTemplateGroup;
  isDragging?: boolean;
  collapsed: boolean;
  onEdit: (group: DisplayedTemplateGroup) => void;
  onDelete: (group: DisplayedTemplateGroup) => void;
}> = ({ group, collapsed, onEdit, onDelete, ...props }) => {
  const [shouldShowDeleteModal, setShouldShowDeleteModal] =
    useState<boolean>(false);
  const { allChecked, isIndeterminate, handleSelectAllNone } =
    useTemplateTristateCheckbox(group.lineItems);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const edit = () => {
    onEdit(group);
  };

  const handleClickRemoveGroup = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      backend_id_value: group.id ?? '',
      item_type: 'Template group',
      button_text: 'Delete group',
      ...commonTrackingProps,
    });

    setShouldShowDeleteModal(true);
  };

  return (
    <>
      <Modal
        isOpen={shouldShowDeleteModal}
        onClose={() => setShouldShowDeleteModal(false)}
        isClosable
        size="small"
        header="Delete Group"
        footer={
          <Box gap={400}>
            <Button
              color="danger"
              fill="outline"
              onClick={() => setShouldShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onDelete(group);
                setShouldShowDeleteModal(false);
              }}
              color="danger"
            >
              Delete
            </Button>
          </Box>
        }
      >
        Group has assigned line items. If you delete group, all line items will
        be moved into default unassigned group. Do you want to continue?
      </Modal>
      <Box
        flexDirection="column"
        border="1px solid"
        borderColor="neutral.300"
        marginBottom={300}
        width="100%"
      >
        <Box
          flexDirection="column"
          data-test-id="GroupHeader"
          paddingY={200}
          backgroundColor={props.isDragging ? 'primary.700' : 'neutral.200'}
        >
          <Heading size={400} marginBottom="0px" marginTop={100}>
            <Box justifyContent="space-between">
              <Box alignItems="baseline">
                <Icon
                  icon={hGripVertical}
                  marginX={300}
                  color={props.isDragging ? 'neutral.0' : 'neutral.600'}
                />
                <Box
                  data-test-id="GroupName"
                  color={props.isDragging ? 'neutral.0' : ''}
                >
                  {group.name}
                </Box>
              </Box>
              {!props.isDragging && (
                <Box>
                  <Icon
                    data-test-id={`editTemplateGroupButton-${group.id}`}
                    icon={iEdit}
                    onClick={edit}
                    marginRight={400}
                    color="neutral.600"
                    cursor="pointer"
                    aria-label="templateGroupEdit"
                  />
                  <Icon
                    onClick={handleClickRemoveGroup}
                    icon={iTrash}
                    marginRight={400}
                    color="neutral.600"
                    cursor="pointer"
                    aria-label="templateGroupDelete"
                  />
                </Box>
              )}
            </Box>
          </Heading>
          {group.description && (
            <Body
              m={0}
              paddingX={300}
              size={500}
              color={props.isDragging ? 'neutral.0' : ''}
              whiteSpace="pre-line"
            >
              {group.description}
            </Body>
          )}
        </Box>
        {!collapsed && !!group?.lineItems?.length && (
          <Table size="small" borderBottom="0px">
            <TemplateGroupThead
              allChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={handleSelectAllNone}
              group={group}
            />
            <Tbody data-test-id="LineItemsTable" borderBottom="0px">
              {group.lineItems.map((lineItem: LineItem) => (
                <LineItemTr key={lineItem.id} lineItem={lineItem} />
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </>
  );
};
