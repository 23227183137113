import { gql } from '@apollo/client';

export const GET_PRODUCT_CATALOG_ORG_VARIANTS = gql`
  query productCatalogOrgVariants($orgId: ID!, $productId: ID!) {
    productCatalogOrgSettings(orgId: $orgId) {
      orgVariations(productId: $productId) {
        id
        variation {
          id
          name
        }
      }
    }
  }
`;

export const PRODUCT_CATALOG_ORG_SETTINGS_FILTER_ENABLED_UPDATE = gql`
  mutation productCatalogOrgSettingsFilterEnabledUpdate(
    $enableAllVariationsByProductIds: [ID!]
    $orgId: ID!
    $orgVariationsAttributes: [ProductCatalogOrgVariationNestedInput!]
    $productFilteringEnabled: Boolean
  ) {
    productCatalogOrgSettingsUpdate(
      enableAllVariationsByProductIds: $enableAllVariationsByProductIds
      orgId: $orgId
      orgVariationsAttributes: $orgVariationsAttributes
      productFilteringEnabled: $productFilteringEnabled
    ) {
      errors {
        attribute
        errors
      }
      orgSettings {
        productFilteringEnabled
      }
    }
  }
`;

export const PRODUCT_CATALOG_ENABLED_ORG_VARIANTS_UPDATE = gql`
  mutation productCatalogEnabledOrgVariantsUpdate(
    $orgId: ID!
    $orgVariationsAttributes: [ProductCatalogOrgVariationNestedInput!]
    $productId: ID!
  ) {
    productCatalogOrgSettingsUpdate(
      orgId: $orgId
      orgVariationsAttributes: $orgVariationsAttributes
    ) {
      errors {
        attribute
        errors
      }
      orgSettings {
        orgVariations(productId: $productId) {
          variation {
            id
            name
          }
        }
      }
    }
  }
`;

export const PRODUCT_CATALOG_ORG_SETTINGS_FILTER_TOGGLE = gql`
  mutation productCatalogConfigOrgVariationsToggle(
    $orgId: ID!
    $productIds: [ID!]!
    $enable: Boolean!
  ) {
    productCatalogConfigOrgVariationsToggle(
      orgId: $orgId
      productIds: $productIds
      enable: $enable
    ) {
      errors {
        attribute
        errors
      }
    }
  }
`;
