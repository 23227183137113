import React, { useEffect } from 'react';

import {
  Body,
  Box,
  Button,
  Drawer,
  useDisclosure,
  Link,
  Divider,
} from '@hover/blueprint';
import { iExternalLink, iMessageSquare, iHelpCircle } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const HelpDrawerButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  const handleHelpOpenClick = () => {
    onOpen();
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.helpButtonPressed,
      button_text: 'Help',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleClose = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.helpModal.close,
      button_text: 'x',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      typewriter.pageViewed({
        page_or_screen_name: EventNames.project.scope.helpModal.page,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    }
  }, [isOpen]);

  return (
    <Box marginLeft={400}>
      <Drawer isOpen={isOpen} onClose={handleClose} isClosable>
        <Box flexDir="column" paddingX={600} paddingY={400}>
          <Body fontWeight={700} fontSize={200} marginBottom={400}>
            HELP AND RESOURCES
          </Body>
          <Divider />
          <Link
            fontSize={200}
            fontWeight={700}
            marginBottom={500}
            iconBefore={iExternalLink}
            target="_blank"
            href="https://help.hover.to/en/collections/3835185-material-list"
          >
            Help Center
          </Link>
          <Link
            fontSize={200}
            fontWeight={700}
            marginBottom={500}
            iconBefore={iExternalLink}
            target="_blank"
            href="https://hover.to/contact/"
          >
            Contact support
          </Link>
          <Link
            fontSize={200}
            fontWeight={700}
            marginBottom={500}
            iconBefore={iExternalLink}
            target="_blank"
            href="https://help.hover.to/en/articles/7030219-material-lists"
          >
            Learn more about Material List
          </Link>
          <Link
            fontSize={200}
            fontWeight={700}
            iconBefore={iMessageSquare}
            target="_blank"
            href="https://hover.qualtrics.com/jfe/form/SV_71Ao18X88xB1GTk"
          >
            Send feedback
          </Link>
          <Divider />
          <Link
            fontSize={200}
            fontWeight={700}
            marginBottom={500}
            iconBefore={iExternalLink}
            target="_blank"
            href="https://hover.qualtrics.com/jfe/form/SV_er4XF9JCt0ZbQDY?jfefe=new"
          >
            How to connect with a supplier?
          </Link>
          <Link
            fontSize={200}
            fontWeight={700}
            iconBefore={iHelpCircle}
            target="_blank"
            href="https://help.hover.to/en/articles/7031091-how-to-customize-a-material-list"
          >
            Need customization?
          </Link>
        </Box>
      </Drawer>
      <Button
        color="primary"
        textColor={materialListFeatureEnabled ? 'neutral.700' : undefined}
        fontWeight={materialListFeatureEnabled ? 600 : undefined}
        fill="minimal"
        iconBefore={iHelpCircle}
        onClick={handleHelpOpenClick}
        boxShadow={materialListFeatureEnabled ? undefined : 'distance100'}
      >
        Help
      </Button>
    </Box>
  );
};
