import { useQuery } from '@apollo/client';
import { LoadingOverlay } from '@hover/blueprint';
import { useParams } from 'react-router-dom';

import { estimationConfigTemplate as EstimationConfigTemplateQueryType } from 'src/api/types/estimationConfigTemplate';
import { GET_ESTIMATION_TEMPLATE } from 'src/features/settings/api/queries/templates';

import { TemplateEdit } from './TemplateEdit';

export const TemplateEditDataWrapper: React.FC = () => {
  const { templateId } = useParams();

  const { data: templateData, loading } =
    useQuery<EstimationConfigTemplateQueryType>(GET_ESTIMATION_TEMPLATE, {
      variables: { id: templateId },
      fetchPolicy: 'cache-and-network',
    });

  if (loading) {
    return <LoadingOverlay data-test-id="templateEditLoading" />;
  }

  return templateData ? (
    <TemplateEdit template={templateData?.estimationConfigTemplate} />
  ) : null;
};
