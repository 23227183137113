import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AreaCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/AreaCounter';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getJob,
  getQuestionResponses,
  getSidingTotalArea,
  getSidingTotalQuestion,
  getPartialsMeasurements,
  getHdfMeasurements,
  getSidingWithOpeningsAreaQuestion,
  getSidingWithOpeningsTotalArea,
} from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { LineSegmentCalculatorComparator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorComparator';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';
import { COMMERCE_USE_HDF, isEnabled } from 'src/lib/FeatureFlag';
import { getOrgSettings } from 'src/redux/selectors';

interface OwnProps {
  updateAnswer: (updateProps: UpdateAnswer) => void;
}

export const SelectedPartialSidingAreaCounter = (props: OwnProps) => {
  const sidingTotalQuestion = useSelector(getSidingTotalQuestion);
  const sidingTotalArea = useSelector(getSidingTotalArea);
  const sidingWithOpeningsAreaQuestion = useSelector(
    getSidingWithOpeningsAreaQuestion,
  );
  const sidingWithOpeningsTotalArea = useSelector(
    getSidingWithOpeningsTotalArea,
  );
  const { plainMeasurements, fullMeasurements } = useSelector(getJob);
  const partialsMeasurements = useSelector(getPartialsMeasurements);
  const questionResponses = useSelector(getQuestionResponses);
  const hdfMeasurements = useSelector(getHdfMeasurements);
  const orgSettings = useSelector(getOrgSettings);

  const { updateAnswer } = props;

  useEffect(() => {
    if (
      !!plainMeasurements &&
      !!fullMeasurements &&
      !!sidingTotalQuestion &&
      !!orgSettings
    ) {
      const lineSegmentCalculator = new LineSegmentCalculator({
        pristinePlainMeasurements: plainMeasurements,
        estimationJson: fullMeasurements,
        questionResponses,
        orgSettings,
      });
      const { selectedAreaWithTrim, calculatedEdgeTotals } =
        lineSegmentCalculator;

      if (!!partialsMeasurements && isEnabled(COMMERCE_USE_HDF)) {
        const { edges, facades, windowsForFacade } = partialsMeasurements;
        const hdfLineSegmentCalculator = new LineSegmentCalculatorHdf({
          edges,
          facades,
          windowsForFacade,
          questionResponses,
          hdfMeasurements,
          orgSettings,
        });
        // only update the store if the calculated value
        // does not match what's already in redux, using hdf calculator
        if (hdfLineSegmentCalculator.selectedAreaWithTrim !== sidingTotalArea) {
          updateAnswer({
            questionId: sidingTotalQuestion?.id,
            answer: hdfLineSegmentCalculator.selectedAreaWithTrim,
          });
        }

        // In the case where and org is using the old formulas we
        // need to continue to update the siding_with_openings_area_total in
        // using the HDF calculator
        if (
          !!sidingWithOpeningsAreaQuestion &&
          hdfLineSegmentCalculator.selectedAreaWithTrim !==
            sidingWithOpeningsTotalArea
        ) {
          updateAnswer({
            questionId: sidingWithOpeningsAreaQuestion?.id,
            answer: hdfLineSegmentCalculator.selectedAreaWithTrim,
          });
        }
        new LineSegmentCalculatorComparator(
          hdfLineSegmentCalculator.calculatedEdgeTotals,
          calculatedEdgeTotals,
        ).compare();
      } else {
        // only update the store if the calculated value
        // does not match what's already in redux, using non-hdf calculator
        if (selectedAreaWithTrim !== sidingTotalArea) {
          updateAnswer({
            questionId: sidingTotalQuestion?.id,
            answer: selectedAreaWithTrim,
          });
        }

        // In the case where and org is using the old formulas we
        // need to continue to update the siding_with_openings_area_total in
        if (
          !!sidingWithOpeningsAreaQuestion &&
          selectedAreaWithTrim !== sidingWithOpeningsTotalArea
        ) {
          updateAnswer({
            questionId: sidingWithOpeningsAreaQuestion?.id,
            answer: selectedAreaWithTrim,
          });
        }
      }
    }
  }, [
    fullMeasurements,
    plainMeasurements,
    questionResponses,
    sidingTotalQuestion,
    sidingTotalArea,
    updateAnswer,
    partialsMeasurements,
    hdfMeasurements,
    orgSettings,
    sidingWithOpeningsAreaQuestion,
    sidingWithOpeningsTotalArea,
  ]);

  return <AreaCounter area={sidingTotalArea} />;
};
