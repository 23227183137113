import { useTracking } from 'src/hooks';

/**
 * a Higher Order Component that allows class components to use the hook useTypewriter
 * as a prop passed to the target child component
 */
export const withTypewriter = (Component: any) => {
  return (props: any) => {
    const { useTypewriter } = useTracking();
    const typewriter = useTypewriter();
    return <Component typewriter={typewriter} {...props} />;
  };
};
