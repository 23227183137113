import { ReactNode, PureComponent } from 'react';

import { OrderModalStates } from '../../../../constants';
import { GenericError } from './GenericError';
import { Loading } from './Loading';
import { OrderErrors } from './OrderErrors';

interface Props {
  orderModalState: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class OrderModalContent extends PureComponent<Props> {
  render(): ReactNode {
    const { orderModalState } = this.props;
    switch (orderModalState) {
      case OrderModalStates.Loading:
        return <Loading />;
      case OrderModalStates.OrderErrors:
        return <OrderErrors />;
      default:
        return <GenericError />;
    }
  }
}
