import moment from 'moment-timezone';

import { profile_profile_user as UserProfile } from 'src/api/types/profile';
import { JobDetails } from 'src/features/exteriorEstimator/types';

export const getJob = (jobDetails: JobDetails | null) => {
  return {
    homeowner_name: jobDetails?.createdBy?.name,
    address_line_1: jobDetails?.locationLine1,
    address_line_2: jobDetails?.locationLine2,
    city: jobDetails?.locationCity,
    region: jobDetails?.locationRegion,
    country: jobDetails?.locationCountry,
    zip: jobDetails?.locationPostalCode,
    name: jobDetails?.name,
    email: jobDetails?.createdBy?.name,
    id: jobDetails?.id,
  };
};

export const getProductionContact = (userProfile?: UserProfile) => {
  const timeZone = moment.tz.guess(true); // get the users timezone from browsers
  return {
    first_name: userProfile?.firstName,
    last_name: userProfile?.lastName,
    email: userProfile?.email,
    phone: userProfile?.officePhone || userProfile?.mobilePhone,
    org_name: userProfile?.orgs[0]?.name,
    time: moment().tz(timeZone),
    logo: userProfile?.orgs[0]?.brand?.logoUrl,
  };
};

export const getBilling = (userProfile?: UserProfile) => {
  return {
    address_line_1: userProfile?.orgs[0]?.wallet?.billingAddressLine1,
    address_line_2: userProfile?.orgs[0]?.wallet?.billingAddressLine2,
    city: userProfile?.orgs[0]?.wallet?.billingAddressCity,
    region: userProfile?.orgs[0]?.wallet?.billingAddressRegion,
    zip: userProfile?.orgs[0]?.wallet?.billingAddressPostalCode,
    email: userProfile?.orgs[0]?.wallet?.billingEmail,
  };
};
