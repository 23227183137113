import { Box, Button, Modal } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface CancelEditModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onLeave: () => void;
  itemId: string;
  itemType: string;
}

export const CancelEditModal: React.FC<CancelEditModalProps> = ({
  isOpen,
  onCancel,
  onLeave,
  itemId,
  itemType,
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const leave = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.template.page,
      primary_cta: false,
      button_text: 'Leave',
      button_location: 'Unsaved changes modal',
      backend_id_type: itemType,
      backend_id_value: itemId,
      ...commonTrackingProps,
    });
    onLeave();
  };

  const footer = (
    <Box data-test-id="cancelEditModalActions">
      <Button
        data-test-id="keepEditing"
        color="danger"
        fill="outline"
        onClick={onCancel}
        marginRight={200}
      >
        Keep Editing
      </Button>
      <Button data-test-id="leaveEdit" color="danger" onClick={leave}>
        Leave
      </Button>
    </Box>
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      size="small"
      header="Unsaved Changes"
      isClosable
      data-test-id="CancelEditModal"
      footer={footer}
    >
      Do you want to leave without saving changes?
    </Modal>
  );
};
