import { toNumber } from 'lodash';
import numeral from 'numeral';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import {
  EstimateGroup_estimationEstimateGroup_userAnswers as Answer,
  EstimateGroup_estimationEstimateGroup_userAnswers_inputCategory as InputCategoryType,
} from 'src/api/types/EstimateGroup';
import { Answer as AnswerComponent } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/Answer';
import { AnswerSection } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/AnswerSection';
import { checklistAnswerFormatter } from 'src/features/exteriorEstimator/utils/ChecklistAnswerUtils';

interface Props {
  answers: Answer[];
  input: InputCategoryType;
  predictedWasteFactor?: number;
}

export const InputCategory: React.FC<Props> = ({
  answers,
  input,
  predictedWasteFactor,
}) => {
  const userValueFormatter = (answer: Answer) => {
    if (!answer.userValue || !answer.inputType) return '---';
    if (answer.inputCategory.name.toLowerCase() === 'waste_factor') {
      return numeral(toNumber(answer.userValue) / 100).format('0%');
    }
    if (answer.inputType === EstimationConfigInputInputTypeEnum.NUMBER)
      return numeral(answer.userValue).format('0,0[.]00');
    return checklistAnswerFormatter(answer.userValue, answer.inputType);
  };

  const unitsFormatter = (answer: Answer) => {
    if (
      answer.inputCategory.name.toLowerCase() === 'waste_factor' ||
      answer.userValue?.toLowerCase() === 'none'
    )
      return null;
    return answer.measurementUnits;
  };

  return (
    <AnswerSection name={input.name}>
      {answers.map((answer) => (
        <AnswerComponent
          {...answer}
          key={answer.id}
          formattedUserValue={userValueFormatter(answer)}
          units={unitsFormatter(answer)}
          predictedWasteFactor={predictedWasteFactor}
        />
      ))}
    </AnswerSection>
  );
};
