/* eslint-disable camelcase */

import { OrgType } from './OrgTypes';

export interface UserType {
  first_name: string;
  last_name: string;
  email: string;
  orgs: OrgType[];
}
