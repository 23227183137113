import { Icon, Box, Body, Heading, Link } from '@hover/blueprint';
import {
  hCalculator,
  hClipboardCheck,
  hClipboardList,
  iFileText,
} from '@hover/icons';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { inspectionChecklist as InspectionChecklistQueryType } from 'src/api/types/inspectionChecklist';
import type { projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList } from 'src/api/types/projectManagementProductionList';
import { LinkOrComponent } from 'src/components';
import { messages } from 'src/constants/messages';
import { GET_INSPECTION_CHECKLIST } from 'src/features/exteriorEstimator/apis/queries/checklist';
import {
  ProjectDetailsPanel,
  SectionHeading,
  LinkBody,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { RecalculateMaterialListButton } from 'src/features/project/components/ProjectScope/ProjectDetails/RecalculateMaterialListButton';
import { useQueryEhi, useToastEhi, ToastStatusEnum } from 'src/hooks';
import {
  getOrgSettings,
  getUserOrgId,
  getTradeTypesSorted,
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { sentenceCase } from 'src/utils/Formatters';

const enum TOAST_IDS {
  GET_INSPECTION_CHECKLIST_TOAST,
}

interface Props {
  productionList: ProjectManagementProductionList;
}

export const Project: React.FC<Props> = ({ productionList }) => {
  const orgId = useSelector(getUserOrgId);
  const { jobId } = useParams();
  const showInspectionChecklist =
    useSelector(getOrgSettings)?.showInspectionChecklist;
  const toast = useToastEhi();
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const { estimateGroup } = productionList;
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const orderingOnlyFeatureAccess = useSelector(getOrderingOnlyFeatureAccess);

  const templates = estimateGroup?.estimates
    .filter((estimate) => estimate.active)
    .map((estimate) => estimate.template);

  const { data } = useQueryEhi<InspectionChecklistQueryType>(
    GET_INSPECTION_CHECKLIST,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.GET_INSPECTION_CHECKLIST_TOAST,
          description:
            messages.projectScope.errors.query.productionList
              .getInspectionChecklist,
          status: ToastStatusEnum.ERROR,
        });
      },
      variables: { jobId, orgId },
    },
  );

  const checklist = data?.inspectionChecklist;
  const date = new Date(estimateGroup?.createdAt)
    .toString()
    .split(' ')
    .slice(1, 4)
    .join(' ');

  const showProposalButton = !(showOrderingFlow && orderingOnlyFeatureAccess);

  return (
    <ProjectDetailsPanel data-test-id="projectSection">
      <SectionHeading marginBottom={300}>Project</SectionHeading>
      <Body data-test-id="projectCreateInfo" size={500} mt={0} mb={400}>
        {estimateGroup?.user?.name} created project {date}
      </Body>
      <Box flexDirection="row" justifyContent="space-between" flex="0 0 140px">
        <Box
          data-test-id="projectTrades"
          flexDirection="column"
          marginBottom={400}
        >
          <Heading size={300} margin={0}>
            Trades
          </Heading>
          <Box flexDirection="column">
            {tradeTypesSorted?.map((tradeType) => {
              const templateOfTradeType = templates?.find(
                (template) =>
                  template?.tradeType === tradeType.tradeTypeEnumValue,
              );
              return templateOfTradeType ? (
                <Box
                  marginBottom={200}
                  flexDirection="column"
                  key={tradeType.tradeTypeEnumValue}
                >
                  <Body
                    key={templateOfTradeType?.tradeType}
                    size={500}
                    margin={0}
                  >
                    {sentenceCase(templateOfTradeType?.tradeType ?? '')}
                  </Body>
                  <Body key={templateOfTradeType?.name} size={500} margin={0}>
                    [{sentenceCase(templateOfTradeType?.name ?? '')}]
                  </Body>
                </Box>
              ) : null;
            })}
          </Box>
        </Box>
        {!materialListFeatureEnabled && (
          <Box flexDirection="column" paddingLeft={300}>
            {showInspectionChecklist && !showOrderingFlow && (
              <LinkOrComponent
                disabled={!checklist}
                to={`/estimator/inspection_checklists?jobId=${jobId}&orgId=${orgId}`}
                target="_blank"
                sx={!checklist ? { color: 'neutral500' } : {}}
                marginTop={0}
                marginBottom={0}
                data-test-id={`inspectionLink${!!checklist ? '-enabled' : ''}`}
              >
                <Box paddingY={100}>
                  <Icon icon={hClipboardCheck} />
                  <LinkBody size={200} isDisabled={!checklist}>
                    Checklists
                  </LinkBody>
                </Box>
              </LinkOrComponent>
            )}
            <Link
              data-test-id="estimateSummary-link"
              as={RouterLink}
              to={{
                pathname: `/estimator/estimates/${estimateGroup?.id}/summary`,
                search: stringify({ jobId, orgId }),
              }}
              target="_blank"
            >
              <Box paddingY={100}>
                <Icon icon={hClipboardList} />
                <LinkBody size={200}>
                  {showOrderingFlow
                    ? 'Calculated materials summary'
                    : 'Estimate inputs summary'}
                </LinkBody>
              </Box>
            </Link>
            {!showOrderingFlow && (
              <Link
                data-test-id="estimateDetails-link"
                as={RouterLink}
                to={`/estimator/estimates/${estimateGroup?.id}/details?jobId=${estimateGroup?.salesOpportunity?.job?.id}`}
                target="_blank"
              >
                <Box paddingY={100}>
                  <Icon icon={hCalculator} />
                  <LinkBody size={200}>Estimate details</LinkBody>
                </Box>
              </Link>
            )}
            {showProposalButton && (
              <LinkOrComponent
                data-test-id="estimateProposal-link"
                disabled={!estimateGroup?.proposalDocumentsCount}
                as={RouterLink}
                to={`/estimator/estimates/${estimateGroup?.id}/details?jobId=${jobId}&orgId=${orgId}&showProposalModal=true`}
                target="_blank"
                sx={
                  !estimateGroup?.proposalDocumentsCount
                    ? { color: 'neutral500' }
                    : {}
                }
              >
                <Box paddingY={100}>
                  <Icon icon={iFileText} />
                  <LinkBody
                    size={200}
                    isDisabled={!estimateGroup?.proposalDocumentsCount}
                  >
                    Proposal
                  </LinkBody>
                </Box>
              </LinkOrComponent>
            )}
            {showOrderingFlow && orderingOnlyFeatureAccess && (
              <RecalculateMaterialListButton
                salesOpportunityId={
                  productionList.estimateGroup?.salesOpportunity.id
                }
              />
            )}
          </Box>
        )}
      </Box>
    </ProjectDetailsPanel>
  );
};
