import { useQuery } from '@apollo/client';
import { Body, Box, Divider, Heading, Loader, Panel } from '@hover/blueprint';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { EstimateGroup } from 'src/api/types/EstimateGroup';
import { GET_ESTIMATE_GROUP } from 'src/features/exteriorEstimator/apis/queries';
import { EstimateSection } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateSections/EstimateSection';
import { LineItems } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateSections/LineItems';
import { getEstimateGroupIdFromLocation } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { lineItemHasQuantity } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { getVariationsFilter } from 'src/redux/selectors';

export const EstimateSectionsByTemplateGroup: React.FC<{
  shouldShowEstimatePrices: boolean;
}> = ({ shouldShowEstimatePrices }) => {
  const id = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const { data } = useQuery<EstimateGroup>(GET_ESTIMATE_GROUP, {
    variables: {
      id,
      ...variationsFilter,
      active: true, // only display active estimates
    },
  });

  if (!data?.estimationEstimateGroup) return <Loader size="large" />;

  return (
    <Box as="section" width={1} flexDirection="column">
      {data.estimationEstimateGroup.estimates.map((estimate) => (
        <Panel
          key={estimate.id}
          boxShadow="distance500"
          marginBottom={400}
          flexDirection="column"
          width={1}
        >
          <EstimateSection estimate={estimate}>
            {sortBy(estimate?.templateGroups, 'sortOrder').map(
              (templateGroup) => {
                const filteredLineItems = (
                  templateGroup?.lineItems ?? []
                ).filter(lineItemHasQuantity);

                return (
                  filteredLineItems.length > 0 && (
                    <Box
                      data-test-id="templateGroupName"
                      key={templateGroup.id}
                      flexDirection="column"
                    >
                      <Heading color="neutral.700" size={200}>
                        {templateGroup?.name === 'Miscellaneous' ? (
                          <Divider
                            color="neutral400"
                            orientation="horizontal"
                          />
                        ) : (
                          templateGroup.name
                        )}
                      </Heading>
                      {templateGroup?.description && (
                        <Body
                          data-test-id="templateGroupDescription"
                          color="neutral.600"
                          size={400}
                          m={0}
                          whiteSpace="pre-line"
                        >
                          {templateGroup.description}
                        </Body>
                      )}
                      <LineItems
                        lineItems={filteredLineItems}
                        shouldShowEstimatePrices={shouldShowEstimatePrices}
                        showPricesByTemplate={
                          !!estimate?.template?.showPricesInEstimation
                        }
                      />
                    </Box>
                  )
                );
              },
            )}
          </EstimateSection>
        </Panel>
      ))}
    </Box>
  );
};
