import { Heading as BlueprintHeading, Box } from '@hover/blueprint';

interface Props {
  'data-test-id'?: string;
}

export const Heading: React.FC<Props> = (props) => {
  const { children, 'data-test-id': dataTestId } = props;
  return (
    <Box data-test-id={dataTestId} width={1} justifyContent="left">
      <BlueprintHeading
        textTransform="capitalize"
        color="primary900"
        size={500}
        marginBottom={300}
      >
        {children}
      </BlueprintHeading>
    </Box>
  );
};
