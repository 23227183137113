import { Body, useTheme, Link, Checkbox, Grid } from '@hover/blueprint';
import { GridItem } from '@hover/blueprint/chakra';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { estimationConfigLineItems_estimationConfigLineItems_nodes_product_productCatalogCategories as ProductCatalogCategory } from 'src/api/types/estimationConfigLineItems';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  name?: string;
  variationsCount?: number;
  orgVariationsCount?: number | null;
  id?: string;
  categories?: ProductCatalogCategory[];
};

export const ListBody: React.FC<Props> = ({
  name,
  variationsCount,
  orgVariationsCount,
  id,
  categories,
}) => {
  const theme = useTheme();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const handleProductClick = () => {
    typewriter.linkPressed({
      link_text: name ?? '',
      page_or_screen_name: EventNames.settings.productVariantShow.page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  return (
    <Grid
      templateColumns="3fr 1fr 1fr"
      borderWidth="1px 1px 0 1px"
      borderColor={theme.colors.neutral200}
      borderStyle="solid"
      backgroundColor={theme.colors.neutral000}
      paddingX={500}
      width="100%"
      _hover={{
        backgroundColor: theme.colors.neutral200,
      }}
      _last={{
        borderBottom: `1px solid ${theme.colors.neutral200}`,
      }}
      data-test-id="materialListItem"
    >
      <GridItem display="flex">
        <Checkbox
          value={id}
          aria-label={name}
          id={id}
          key={id}
          data-test-id="checkboxMaterialItem"
          flex="0"
        />
        <Link
          data-test-id="materialListBodyContainer"
          as={RouterLink}
          to={`/workflows/materials/products/${id}`}
          onClick={handleProductClick}
          alignItems="center"
          flex="1"
          display="flex"
          flexDirection="row"
          textDecoration="none"
          _focus={{ boxShadow: 'none' }}
        >
          <Body
            color="primary900"
            data-test-id="materialListItemProductName"
            flex={3}
            m={300}
            size={400}
          >
            {name}
          </Body>
        </Link>
      </GridItem>
      <GridItem>
        <Body
          data-test-id="materialListItemCategory"
          size={400}
          m={300}
          color="neutral700"
        >
          {categories ? categories.map((c) => c.name).join(', ') : ''}
        </Body>
      </GridItem>
      <GridItem display="flex" justifyContent="flex-end">
        <Body
          data-test-id="materialListItemVariationCount"
          size={400}
          m={300}
          color="neutral700"
        >
          {`${orgVariationsCount} of ${variationsCount}`}
        </Body>
      </GridItem>
    </Grid>
  );
};
