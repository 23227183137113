import { projectManagementProposalDocumentCreate_projectManagementProposalDocumentCreate_proposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocumentCreate';

// redux store type
export type ProposalsType = {
  proposals: Proposal[];
  isLoadingProposals: boolean;
  selectedProposalId: string | null;
  signatureRequest: {
    isRequested?: boolean;
    requestId?: string;
    id?: string;
    state?: string;
  };
  error?: Error;
};

export enum ProposalViews {
  CREATE,
  SHOW,
  LIST,
  SENDING_EMAIL_ESIGN,
  SENT_EMAIL_ESIGN,
}

export type { Proposal };
