import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { profile_profile_user as UserProfile } from 'src/api/types/profile';
import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as JobData,
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
} from 'src/api/types/projectManagementProductionList';
import { FormattedNumber } from 'src/components/FormattedNumber';
import {
  containerStyle,
  tableStyle,
  headerWrapper,
  headerText,
  logoStyle,
  totalsTableStyleContainer,
  totalsTableTyle,
  shortCol,
  fatCol,
  labelStyle,
  grid,
  gridWithUnitCost,
  tableHeader,
  tableRow,
  col1,
  col2,
  col3,
  col4,
  col5,
  lastCol,
  bold,
  textAlignRight,
  footerStyle,
  footerWrapperStyle,
} from 'src/features/project/styles/OrderPdfStyles';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

export interface PdfOutputParams {
  title: string;
  purchaseOrderNumber: string | null;
  listItems?: ListItem[];
  shouldShowTotalCost: boolean;
  shouldShowItemMeasurements: boolean;
  shouldShowCostDetails: boolean;
  notes: string | null;
  job?: JobData;
  lineItemType: LineItemTypeEnum | null;
  userProfile?: UserProfile;
}

const getProductionContact = (userProfile?: UserProfile) => {
  return {
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    email: userProfile?.email,
    phone: userProfile?.officePhone || userProfile?.mobilePhone,
    orgName: userProfile?.orgs[0]?.name,
    logo: userProfile?.orgs[0]?.brand?.logoUrl,
  };
};

const getBilling = (userProfile?: UserProfile) => {
  return {
    addressLine1: userProfile?.orgs[0]?.wallet?.billingAddressLine1,
    city: userProfile?.orgs[0]?.wallet?.billingAddressCity,
    region: userProfile?.orgs[0]?.wallet?.billingAddressRegion,
    zip: userProfile?.orgs[0]?.wallet?.billingAddressPostalCode,
    email: userProfile?.orgs[0]?.wallet?.billingEmail,
  };
};

const getTradeTypes = (listItems: ListItem[]) => {
  const tradeTypes = new Set();
  listItems.forEach((listItem) => tradeTypes.add(listItem.tradeType));
  return [...tradeTypes].join(', ');
};

export interface PdfFooterParams {
  jobId?: number;
}

export const PdfFooter: React.FC<PdfFooterParams> = ({ jobId }) => {
  const timeZone = moment.tz.guess(true); // get the users timezone from browsers
  return (
    <div data-test-id="footer" style={footerWrapperStyle}>
      <div style={footerStyle}>
        <span>
          Powered by HOVER | Property ID: {jobId} | Generated{' '}
          {moment().tz(timeZone)?.format('MM/DD/YYYY-hh:mm A')}
        </span>
      </div>
    </div>
  );
};

export const PdfOutput: React.FC<PdfOutputParams> = ({
  title,
  purchaseOrderNumber,
  listItems,
  notes,
  job,
  shouldShowTotalCost,
  shouldShowItemMeasurements,
  shouldShowCostDetails,
  userProfile,
  lineItemType,
}) => {
  const productionContact = getProductionContact(userProfile);
  const billing = getBilling(userProfile);

  const renderHeader = () => {
    const productionContactTyped = productionContact as Omit<
      typeof productionContact,
      'logo'
    > & { logo: string | undefined };

    return (
      <div style={headerWrapper} data-test-id="PdfOutputHeader">
        <div>
          <div style={headerText} data-test-id="PdfOutputTitle">
            {title}
          </div>
          {purchaseOrderNumber !== '' && (
            <div style={headerText} data-test-id="PdfOutputPurchaseNum">
              PO: {purchaseOrderNumber}
            </div>
          )}
        </div>
        <div>
          <img
            alt="logo"
            src={productionContactTyped.logo}
            style={logoStyle}
            data-test-id="PdfOutputContactLogo"
          />
        </div>
      </div>
    );
  };

  const renderJobDetailsTable = () => {
    return (
      <>
        <table style={{ ...tableStyle, paddingTop: '10px' }}>
          <thead>
            <tr>
              <th style={{ ...shortCol, ...labelStyle }}>Delivery Address</th>
              <th style={{ ...fatCol, ...labelStyle }}>Production Contact</th>
              <th style={{ ...shortCol, ...labelStyle }}>Billing Address</th>
            </tr>
          </thead>
        </table>
        <table
          style={{
            width: '100%',
            border: '2px solid black',
            textAlign: 'left',
            padding: '8px',
          }}
        >
          <tbody>
            <tr>
              <th style={{ width: '25%', ...bold }}>{job?.name || 'Job'}</th>
              <th style={{ width: '50%', ...bold }}>
                {productionContact.firstName} {productionContact.lastName}
              </th>
              <th style={{ width: '25%', ...bold }}>
                {productionContact.orgName || 'Org Name'}
              </th>
            </tr>
            <tr>
              <td style={shortCol}>{job?.locationLine1}</td>
              <td style={fatCol}>{productionContact.orgName || 'Org Name'}</td>
              <td style={shortCol}>
                {billing.addressLine1}, {billing.city}, {billing.region}{' '}
                {billing.zip}
              </td>
            </tr>
            <tr>
              <td style={shortCol}>
                {job?.locationCity}, {job?.locationRegion}{' '}
                {job?.locationPostalCode}
              </td>
              <td style={fatCol}>{productionContact.email || ''}</td>
              <td style={shortCol}>{billing.email || ''}</td>
            </tr>
            <tr>
              <td style={shortCol} />
              <td style={fatCol}>
                {!isNil(productionContact.phone)
                  ? `T: ${productionContact.phone}`
                  : ''}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderWasteFactor = (listItem: ListItem) => {
    const wasteFactor = Number(listItem.wasteFactor).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    });
    return (
      <div>
        {`+${wasteFactor} = `}
        <FormattedNumber
          value={
            Number(listItem.measurement) * (1 + Number(listItem.wasteFactor))
          }
        />
      </div>
    );
  };

  const renderListItemsTable = () => {
    if (!listItems) return null;
    return (
      <>
        <div data-test-id="pdfOutputTradeType">{getTradeTypes(listItems)}</div>
        <div
          style={{
            ...(shouldShowCostDetails ? gridWithUnitCost : grid),
            ...tableHeader,
          }}
        >
          <div style={col1} data-test-id="itemCol">
            Item
          </div>
          {lineItemType === LineItemTypeEnum.MATERIAL && (
            <div style={col2} data-test-id="variantCol">
              Variant/Sku
            </div>
          )}
          {shouldShowItemMeasurements && (
            <div style={col3} data-test-id="measurementsCol">
              Measurements
            </div>
          )}
          <div
            style={shouldShowCostDetails ? col4 : lastCol}
            data-test-id="quantityCol"
          >
            Quantity
          </div>
          {shouldShowCostDetails && (
            <>
              <div style={col5} data-test-id="unitCostCol">
                Unit Cost
              </div>
              <div style={lastCol} data-test-id="amountCostCol">
                Amount
              </div>
            </>
          )}
        </div>
        {listItems &&
          listItems.map((listItem) => (
            <div
              style={{
                ...(shouldShowCostDetails ? gridWithUnitCost : grid),
                ...tableRow,
              }}
              key={listItem.id}
            >
              <div style={col1} data-test-id={`itemCol${listItem.id}`}>
                {listItem.name}
              </div>
              {lineItemType === LineItemTypeEnum.MATERIAL && (
                <div style={col2} data-test-id={`variantCol${listItem.id}`}>
                  <div>{listItem.color} </div>
                  <div>{listItem.sku}</div>
                </div>
              )}
              {shouldShowItemMeasurements && (
                <div
                  style={col3}
                  data-test-id={`measurementsCol${listItem.id}`}
                >
                  <div>
                    {listItem.measurement ? (
                      <FormattedNumber value={listItem.measurement} />
                    ) : (
                      '--'
                    )}
                    {listItem.measurementUnits && (
                      <span style={{ marginLeft: '5px' }}>
                        {lineItemQuantityUnits(listItem.measurementUnits)}
                      </span>
                    )}
                  </div>
                  {!!listItem.wasteFactor && renderWasteFactor(listItem)}
                </div>
              )}

              <div
                style={shouldShowCostDetails ? col4 : lastCol}
                data-test-id={`quantity${listItem.id}`}
              >
                {listItem.quantity}{' '}
                {lineItemQuantityUnits(listItem.quantityUnits)}
              </div>
              {shouldShowCostDetails && (
                <>
                  <div style={col5} data-test-id={`unitCostCol${listItem.id}`}>
                    <FormattedNumber
                      format="$0,0.00"
                      value={listItem.unitCost}
                    />
                  </div>
                  <div
                    style={lastCol}
                    data-test="amountCostColValue"
                    data-test-id={`amountCostCol${listItem.id}`}
                  >
                    <FormattedNumber
                      format="$0,0.00"
                      value={listItem?.pretaxCost}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
      </>
    );
  };

  const renderNotes = () => {
    return isNil(notes) || !notes.length ? null : (
      <div>
        <div style={headerText}>ORDER NOTES</div>
        <p>{notes}</p>
      </div>
    );
  };

  const renderCostTotals = () => {
    if (!listItems) return null;
    const subtotal = listItems.reduce(
      (acc, listItem) => acc + listItem.pretaxCost,
      0,
    );
    const postTaxTotal = listItems.reduce(
      (acc, listItem) => acc + listItem.totalCost,
      0,
    );
    return (
      shouldShowTotalCost && (
        <div style={totalsTableStyleContainer}>
          <table style={totalsTableTyle} data-test-id="totalCostTable">
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td style={textAlignRight}>
                  {listItems && (
                    <FormattedNumber value={subtotal} format="$0,0.00" />
                  )}
                </td>
              </tr>
              <tr>
                <td>Sales tax</td>
                <td style={textAlignRight}>
                  {listItems && (
                    <FormattedNumber
                      value={postTaxTotal - subtotal}
                      format="$0,0.00"
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td style={headerText}>Total Cost:</td>
                <td style={{ ...headerText, ...textAlignRight }}>
                  {listItems && (
                    <FormattedNumber value={postTaxTotal} format="$0,0.00" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  };

  if (!listItems) {
    return null;
  }

  return (
    <div style={containerStyle} id="container">
      {renderHeader()}
      {renderJobDetailsTable()}
      {renderListItemsTable()}
      {renderCostTotals()}
      {renderNotes()}
    </div>
  );
};
