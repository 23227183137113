import { Box, Button, Icon } from '@hover/blueprint';
import { iArrowLeft, iArrowRight } from '@hover/icons';

export type CursorPaginationProps = {
  hasPrevious: boolean;
  hasNext: boolean;
  resultsCount: number;
  handlePageClick: (direction: PageDirection) => void;
  hideButtons?: boolean;
};

export enum PageDirection {
  Prev,
  Next,
}

export const Pagination: React.FC<CursorPaginationProps> = ({
  hasPrevious,
  hasNext,
  resultsCount = 0,
  handlePageClick,
  hideButtons = false,
}) => {
  return (
    <Box data-test-id="paginationControls" flexDirection="row" marginTop={400}>
      {resultsCount > 0 && (
        <>
          <Button
            shape="box"
            fill="outline"
            data-test-id="PaginationPrevious"
            onClick={() => handlePageClick(PageDirection.Prev)}
            marginRight={400}
            isDisabled={!hasPrevious}
            style={{
              display: hideButtons && !hasPrevious ? 'none' : 'initial',
            }}
          >
            <Icon icon={iArrowLeft} />
            Prev
          </Button>

          <Button
            shape="box"
            fill="outline"
            data-test-id="PaginationNext"
            onClick={() => handlePageClick(PageDirection.Next)}
            isDisabled={!hasNext}
            style={{
              display: hideButtons && !hasNext ? 'none' : 'initial',
            }}
          >
            Next
            <Icon icon={iArrowRight} />
          </Button>
        </>
      )}
    </Box>
  );
};
