import { useEffect } from 'react';

import ActionCable, { Channel } from 'actioncable';

import appSettings from 'src/appSettings';
import { useToastEhi, ToastStatusEnum } from 'src/hooks';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';

const DISTRIBUTOR_STATUS_SUBSCRIPTION =
  'Distribution::DistributorStatusChangesChannel';

type DistributorStatus = {
  distributor_display_name: string;
  distributor_id: string;
  distributor_identifier: string;
  online: boolean;
};

type DistributorsStatusMessage = {
  type: 'connect' | 'update';
  distributors: Array<DistributorStatus>;
};

/**
 * a getter for the correct url to retrieve API responses from:
 * - checks to see if enviroment is production
 *
 *  @return {string} a string that represents the URL to attempt to retreive distributors statuses from
 */
const getCableUrl = async (): Promise<string> => {
  const token = await HoverRequest.retrieveToken();
  const productCatalogUrl = new URL(appSettings.PLANET_TERROR_SERVER);
  productCatalogUrl.protocol = 'wss';
  const baseWebsocketUrl = productCatalogUrl.toString();

  return `${baseWebsocketUrl}cable?jwt=${token}`;
};

/**
 * The main component container which initiates API requests,
 * checks to see if data from that API should show a toast
 * and determines what kind of toast to show - error, success - and its contents
 */
export const SupplierStatusProvider: React.FC = ({ children, ...data }) => {
  const toast = useToastEhi();

  /**
   * opens a success toast notifying that atleast one distributor is now online
   *
   * @param onlineDistributors - an array of distributors that are now online
   */
  const distributorsOnlineToast = (
    onlineDistributors: Array<DistributorStatus>,
  ) => {
    const onlineMsg = (dist: DistributorStatus) =>
      `${dist.distributor_display_name} connection is online; ordering is available.`;
    const toastDesc = onlineDistributors.map(onlineMsg).join('\n');

    toast({
      id: 'distributors-online-toast',
      description: toastDesc,
      status: ToastStatusEnum.SUCCESS,
      preformatted: true,
      wrap: true,
    });
  };

  /**
   * opens an error toast that atleast one distributors is offline/down
   *
   * @param offlineDistributors - an array of distributors that are down
   */
  const distributorsOfflineToast = (
    offlineDistributors: Array<DistributorStatus>,
  ) => {
    const offlineMsg = (dist: DistributorStatus) =>
      `${dist.distributor_display_name} connection is offline; ordering is not available.`;
    const toastDesc = offlineDistributors.map(offlineMsg).join('\n');

    toast({
      id: 'distributors-offline-toast',
      description: toastDesc,
      status: ToastStatusEnum.ERROR,
      preformatted: true,
      wrap: true,
    });
  };

  /**
   * filters for OFFline distributors, and invokes a toast if any are found
   *
   *  @param distributors - an array of distributors
   */
  const toastAllOffline = (distributors: Array<DistributorStatus>) => {
    const offline = distributors.filter((dist) => !dist.online);

    if (offline.length > 0) {
      distributorsOfflineToast(offline);
    }
  };

  /**
   * filters for ONline distributors, and invokes a toast if any are found
   *
   *  @param distributors - an array of distributors
   */
  const toastAllOnline = (distributors: Array<DistributorStatus>) => {
    const online = distributors.filter((dist) => !!dist.online);

    if (online.length > 0) {
      distributorsOnlineToast(online);
    }
  };

  /**
   * checks the api response, either inital connection or subscriptions update
   * and for inital connection starts process of notifying users of offline distributors
   * else begins process of notifying users for any new updates (online or offline) to distributors status
   *
   * @param channelData - the raw json response from the backends action cable channel
   */
  const checkTypeAndToast = (channelData: DistributorsStatusMessage) => {
    const { distributors } = channelData;

    if (channelData.type === 'connect') {
      toastAllOffline(distributors);
    } else {
      toastAllOffline(distributors);
      toastAllOnline(distributors);
    }
  };

  /**
   * creates channel websocket connection to action Cable
   * and then listens for when data is received
   * addtionally, it returns the channel that was created and being listened to
   *
   *  @return {Channel}
   */
  const createChannelConnection = async (
    channelName: string,
    subscriptionCallBack: (channelData: DistributorsStatusMessage) => void,
  ) => {
    const url = await getCableUrl();
    const cable = ActionCable.createConsumer(url);

    const channel: Channel = cable.subscriptions.create(channelName, {
      received(channelData: DistributorsStatusMessage) {
        subscriptionCallBack(channelData);
      },
    });

    return channel;
  };

  /**
   * on mount, set up a channel connection subscription
   * on unmount, unsubscribe from that channel
   */
  useEffect(() => {
    let channel: undefined | ActionCable.Channel;
    createChannelConnection(
      DISTRIBUTOR_STATUS_SUBSCRIPTION,
      checkTypeAndToast,
    ).then((channelConnection) => {
      channel = channelConnection;
    });

    return () => {
      return channel && channel.unsubscribe();
    };
  }, []);

  return <>{children}</>;
};
