import { Body, Checkbox } from '@hover/blueprint';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type ProposalActiveStatusProps = {
  viewMode: ViewMode;
};

export const ProposalActiveStatus: React.FC<ProposalActiveStatusProps> = ({
  viewMode,
}) => {
  const { control } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const toggleActivateProposals = (selected: boolean) => {
    typewriter.checkboxSelected({
      selection: selected.toString(),
      options: 'Active for organization',
      page_or_screen_name: EventNames.settings.proposal.page,
      ...commonTrackingProps,
    });
  };

  return (
    <Controller
      name="proposalsEnabled"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Checkbox
            id="proposalsEnabled"
            data-test-id="ToggleProposal"
            isDisabled={viewMode === 'EDIT'}
            isChecked={value}
            onChange={(e) => {
              onChange(e.target.checked);
              toggleActivateProposals(e.target.checked);
            }}
          >
            <Body
              id="proposalActiveOrgCheckbox"
              size={500}
              margin={0}
              color="neutral700"
            >
              Active for organization
            </Body>
          </Checkbox>
        );
      }}
    />
  );
};
