import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
  query estimationConfigTemplates($orgId: ID!, $after: String) {
    estimationConfigTemplates(orgId: $orgId, after: $after, first: 20) {
      nodes {
        id
        name
        orgId
        tradeType
        sortOrder
        showPricesInEstimation
        description
      }
      edges {
        node {
          id
          name
          orgId
          tradeType
          sortOrder
          showPricesInEstimation
          description
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
