import styled from 'styled-components';

import StyleGuide from 'style-guide';

export const OrderModalWrapper = styled.div``;

export const Modal = styled(StyleGuide.DefaultModal)``;

export const Button = styled(StyleGuide.Button)``;

export const contentStyle = {
  padding: 0,
};

export const footerStyle = {
  padding: '24px',
  zIndex: 2000,
};
