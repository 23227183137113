import {
  EHI_LOCAL_STORAGE_KEY,
  EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY,
  EHI_LOCAL_STORAGE_OAUTHURL_KEY,
} from 'src/constants';

import { CustomLineItem, QuestionAnswer, QuestionResponses } from '../types';

type Key = string | number;

type EstimatorCache = {
  [jobId: number]: CacheDataForJob;
};

type CacheDataForJob = {
  questions: QuestionResponses;
  customLineItems: CustomLineItem[];
  didSetCacheWithManualMeasurements: boolean | null | undefined;
};

export const overrideAnswersWithCachedVals = (
  emptyQuestionResponses: QuestionResponses,
  cachedAnswers: QuestionResponses,
) => {
  return Object.entries(emptyQuestionResponses).reduce(
    (acc: { [id in Key]: QuestionAnswer }, [key, val]) => {
      if (cachedAnswers[key] !== undefined) {
        acc[key] = cachedAnswers[key];
      } else {
        acc[key] = val;
      }
      return acc;
    },
    {},
  );
};

const getEstimatorCache = (): EstimatorCache =>
  JSON.parse(localStorage.getItem(EHI_LOCAL_STORAGE_KEY) || '{}') || {};

const setEstimatorCache = (cache: EstimatorCache) => {
  return localStorage.setItem(EHI_LOCAL_STORAGE_KEY, JSON.stringify(cache));
};

export const getJobDataFromCache = (
  jobId: number,
): CacheDataForJob | undefined => {
  const cache = getEstimatorCache();
  return cache?.[jobId];
};

export const getCustomLineItemsFromCache = (jobId: number) => {
  const jobData = getJobDataFromCache(jobId);
  return jobData?.customLineItems;
};

export const saveCustomLineItemsToCache = (
  customLineItems: CustomLineItem[],
  jobId: number,
) => {
  const cache = getEstimatorCache();
  cache[jobId] = cache[jobId] || { questions: {}, customLineItems: [] };
  cache[jobId].customLineItems = customLineItems;
  setEstimatorCache(cache);
};

export const getQuestionsFromCache = (jobId: number) => {
  const jobData = getJobDataFromCache(jobId);
  return jobData?.questions;
};

export const saveQuestionsToCache = ({
  answers,
  jobId,
  didSetCacheWithManualMeasurements,
}: {
  answers: QuestionResponses;
  jobId: number;
  didSetCacheWithManualMeasurements: boolean;
}) => {
  const cache = getEstimatorCache();
  cache[jobId] = cache[jobId] || {
    questions: {},
    customLineItems: [],
    didSetCacheWithManualMeasurements,
  };
  cache[jobId].questions = answers;
  return setEstimatorCache(cache);
};

export const overrideResponsesFromCache = ({
  jobId,
  questionResponses,
  didSetCacheWithManualMeasurements,
}: {
  jobId: string | number;
  questionResponses: QuestionResponses;
  didSetCacheWithManualMeasurements: boolean;
}) => {
  let updatedAnswers: QuestionResponses = questionResponses;

  const cachedAnswers = getQuestionsFromCache(Number(jobId));

  if (cachedAnswers) {
    // override answers with saved values in localStorage
    updatedAnswers = overrideAnswersWithCachedVals(
      questionResponses,
      cachedAnswers,
    );
  }

  // save to localStorage
  saveQuestionsToCache({
    answers: updatedAnswers,
    jobId: Number(jobId),
    didSetCacheWithManualMeasurements,
  });

  return updatedAnswers;
};

export const getRedirectUrlFromCache = (): string | null => {
  const url = localStorage.getItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY);
  return url;
};

export const saveRedirectUrlToCache = (url: string): void => {
  localStorage.setItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY, url);
};

export const clearRedirectUrlFromCache = (): void => {
  localStorage.removeItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY);
};

export const clearOauthUrlFromCache = (): void => {
  localStorage.removeItem(EHI_LOCAL_STORAGE_OAUTHURL_KEY);
};

export const clearJobDataFromCache = (jobId: number) => {
  const cache = getEstimatorCache();
  delete cache[jobId];
  setEstimatorCache(cache);
};
