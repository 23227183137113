import { useMemo, useCallback } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

export interface ParamsHash {
  [name: string]: string | undefined | null;
}

interface UseSearchParamsReturn {
  updateParam: (name: string, value: string) => void;
  removeParam: (name: string) => void;
  updateParams: (params: ParamsHash) => void;
  searchParams: URLSearchParams;
}

export const useSearchParams = (): UseSearchParamsReturn => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  const updateURL = useCallback(() => {
    searchParams.sort();
    history.replace({
      ...location,
      search: searchParams.toString(),
    });
  }, [history, location, searchParams]);

  const removeParam = (name: string) => {
    /*
      As it currently stands this will delete all params with this name key. If
      in the future we want to only delete ones with specific values we will
      have to provide this function a second value param and a helper which uses
      https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/getAll to
      parse it out. It sounds like this functionality will not be native anytime
      soon: https://github.com/whatwg/url/issues/335
     */
    if (searchParams.has(name)) {
      searchParams.delete(name);
      updateURL();
    }
  };

  const updateParams = (params: ParamsHash) => {
    Object.entries(params).forEach(([name, value]) => {
      if (searchParams.has(name)) {
        removeParam(name);
      }
      if (value) searchParams.append(name, value);
    });
    updateURL();
  };

  const updateParam = (name: string, value: string) => {
    /*
      If in the future we intend on using this in a way which allows multiple
      values on the same name key we should make a separate function 'addParam'
     */
    updateParams({ [name]: value });
  };

  return { searchParams, updateParam, updateParams, removeParam };
};
