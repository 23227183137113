import { useState, useEffect } from 'react';

import { Heading } from '@hover/blueprint';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ProjectManagementProposalDocumentStateEnum } from 'src/api/graphql-global-types';
import { ToastNotification } from 'src/components/ToastNotification';
import { ProposalModal } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/Modal';
import { ProposalCreateLoader } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/ProposalCreateLoader';
import { ProposalShowBody } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/ProposalShowBody';
import { useProposals } from 'src/features/exteriorEstimator/hooks/useProposals';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getSelectedProposal,
  getIsLoadingProposals,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { useTracking } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootAction, RootState } from 'src/types/reduxStore';

import { ProposalListBody } from './ProposalListBody';
import { ProposalLoader } from './ProposalLoader';
import { SendingEmailESign } from './SendingEmailESign';
import { SentEmailESign } from './SentEmailESign';

export const mapStateToProps = (state: RootState) => ({
  jobName: state?.exteriorEstimator?.job?.jobDetails?.name ?? '',
  selectedProposal: getSelectedProposal(state),
  proposals: state.exteriorEstimator.proposalsData.proposals,
  error: state.exteriorEstimator.proposalsData.error,
  isLoadingProposals: getIsLoadingProposals(state),
  jobDetails: state.exteriorEstimator.job.jobDetails,
  orgId: getUserOrgId(state),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setSelectedProposal: estimatorActions.setSelectedProposal,
      clearProposalsError: estimatorActions.clearProposalsError,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type OwnProps = {
  setProposalModalView: (view: ProposalViews | null) => void;
  modalView: ProposalViews;
  hideTotalPrice: boolean;
};

export type Props = ConnectedProps<typeof connector> & OwnProps;

export const ProposalsModalComponent: React.FC<Props> = ({
  selectedProposal,
  jobName,
  setSelectedProposal,
  setProposalModalView,
  proposals,
  modalView = ProposalViews.CREATE,
  isLoadingProposals,
  error,
  clearProposalsError,
  commonProps,
  jobDetails,
  orgId,
  hideTotalPrice,
}) => {
  const [showError, setShowError] = useState(false);
  const [isProposalGenerating, setIsProposalGenerating] = useState(false);
  const { estimateGroupId } = useParams();
  const { removeParam } = useSearchParams();
  const [fetchProposals] = useProposals();
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.page,
      job_id: Number(jobDetails?.id),
      ...commonProps,
    });
  }, [commonProps, jobDetails]);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  useEffect(() => {
    setIsProposalGenerating(
      !!selectedProposal?.id &&
        selectedProposal?.state !==
          ProjectManagementProposalDocumentStateEnum.COMPLETE &&
        selectedProposal?.state !==
          ProjectManagementProposalDocumentStateEnum.FAILED,
    );
  }, [selectedProposal]);

  useEffect(() => {
    if (modalView === ProposalViews.LIST) {
      fetchProposals(estimateGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateGroupId, modalView]);

  const handleClickBack = () => {
    setSelectedProposal({ id: null });
    setProposalModalView(ProposalViews.LIST);
  };

  const handleClickClose = () => {
    removeParam('showProposalModal');
    setProposalModalView(null);
    setSelectedProposal({ id: null });
  };

  const renderHeader = (header: string) => (
    <>
      <Heading size={300}>{header}</Heading>
      <ToastNotification
        notification="Something went wrong. Please try again."
        severity="Error"
        show={showError}
        clearError={clearProposalsError}
      />
    </>
  );

  const renderCreate = () =>
    orgId && (
      <ProposalModal
        header={renderHeader('Get Proposal')}
        body={
          <>
            <ProposalCreateLoader
              estimateGroupId={estimateGroupId}
              orgId={orgId}
              isLoading={isLoadingProposals || isProposalGenerating}
              hidePriceToggles={hideTotalPrice}
            />
            {isProposalGenerating && (
              <ProposalLoader
                id={selectedProposal?.id}
                setProposalModalView={setProposalModalView}
              />
            )}
          </>
        }
        handleClose={handleClickClose}
        isLoading={isLoadingProposals || isProposalGenerating}
      />
    );

  const renderShow = () => {
    if (!selectedProposal) return null;
    return (
      <ProposalModal
        header={renderHeader('Proposal')}
        body={
          <ProposalShowBody
            proposal={selectedProposal}
            jobName={jobName}
            setProposalModalView={setProposalModalView}
            hideTotalPrice={hideTotalPrice}
          />
        }
        handleClose={handleClickClose}
        handleBack={handleClickBack}
        isLoading={isLoadingProposals}
      />
    );
  };

  const renderList = () => {
    return (
      <ProposalModal
        header={renderHeader(`Proposal ${proposals.length}`)}
        body={
          <ProposalListBody
            proposals={proposals}
            setSelectedProposal={setSelectedProposal}
            setProposalModalView={setProposalModalView}
            hideTotalPrice={hideTotalPrice}
          />
        }
        handleClose={handleClickClose}
        isLoading={isLoadingProposals}
      />
    );
  };

  const renderSendingEmailESign = () => {
    if (!selectedProposal) return null;
    return (
      <ProposalModal
        header={renderHeader('Requesting email eSignature')}
        body={
          <SendingEmailESign
            proposal={selectedProposal}
            setProposalModalView={setProposalModalView}
          />
        }
        handleClose={handleClickClose}
      />
    );
  };

  const renderSentEmailESign = () => {
    if (!selectedProposal) return null;
    return (
      <ProposalModal
        header={renderHeader('Requested email eSignature')}
        body={<SentEmailESign proposal={selectedProposal} />}
        handleClose={handleClickClose}
      />
    );
  };

  return (
    <>
      {
        {
          [ProposalViews.CREATE]: renderCreate(),
          [ProposalViews.SHOW]: renderShow(),
          [ProposalViews.LIST]: renderList(),
          [ProposalViews.SENDING_EMAIL_ESIGN]: renderSendingEmailESign(),
          [ProposalViews.SENT_EMAIL_ESIGN]: renderSentEmailESign(),
        }[modalView]
      }
    </>
  );
};

export const ProposalModals = connector(ProposalsModalComponent);
