import { StyleProps, useToast } from '@hover/blueprint';
import type { UseToastOptions, ToastFunction } from '@hover/blueprint';

// notification types
export enum ToastStatusEnum {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

const DEFAULT_TOAST_ID = 'APP_NOTIFICATION_TOAST';
const DEAFULT_TOAST_DURATION = 3000;

type ToastNotificationOptions = Omit<UseToastOptions, 'status'> & {
  status?: ToastStatusEnum;
  preformatted?: boolean;
  wrap?: boolean;
};

/**
 * Function responsible for either opening a new toast or updating an active one, depending on ID:
 *
 * @param blueprintToast ToastFunction
 * @param options UseToastOptions
 *
 */
const openOrUpdate = (
  blueprintToast: ToastFunction,
  options: UseToastOptions,
) => {
  const id = options.id || DEFAULT_TOAST_ID;
  // If there's currently an active/open toast, update it to prevent multiple toasts from opening
  if (blueprintToast.isActive(id)) {
    // we update it, by setting a onCloseComplete callback and closing it
    blueprintToast.update(id, {
      onCloseComplete: () => {
        blueprintToast(options);
      },
    });
    blueprintToast.close(id);
  } else {
    // else, if the toast is not yet active, open it normally
    blueprintToast(options);
  }
};

const getContainerStyle = (preformatted = false, wrap = true): StyleProps => {
  let whiteSpace: React.CSSProperties['whiteSpace'];
  if (preformatted && wrap) {
    whiteSpace = 'pre-wrap';
  } else if (preformatted) {
    whiteSpace = 'pre';
  } else if (!wrap) {
    whiteSpace = 'nowrap';
  }
  return {
    whiteSpace: whiteSpace || 'normal',
  };
};

/**
 * Custom `toast` implementation that does the following:
 * - Sets the `isClosable` or auto-close option based on the toast status of `error` or other.
 * - Sets the auto-close duration to a standard of 3 seconds.
 * - Enforces a "singleton" toast by preventing multiple toasts through the use of a single toast `id`.
 *
 * Options for `closable` and `duration` are pre-configured; all other options
 * to be provided when calling returned toast() function.
 */
export const useToastEhi = () => {
  const blueprintToast: ToastFunction = useToast();
  const toast = (options: ToastNotificationOptions | undefined) => {
    const containerStyle = getContainerStyle(
      options?.preformatted,
      options?.wrap,
    );

    const optionsConfig: UseToastOptions = {
      ...options,
      duration: options?.status === 'error' ? null : DEAFULT_TOAST_DURATION,
      isClosable: options?.status === 'error',
      id: options?.id?.toString(),
      position: 'bottom',
      containerStyle,
    };

    openOrUpdate(blueprintToast, optionsConfig);
  };

  return toast;
};
