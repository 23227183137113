import React from 'react';

import { Body, Box, Link } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import {
  IntegrationConnectionError,
  GQLError,
  hasJobAccountError,
} from 'src/lib/GraphqlClient';
import { getUserTrackingProps } from 'src/redux/selectors';

const HELP_EMAIL = 'direct.ordering@hover.to';

/**
 * Private child components
 */
const OAuthHelpLink: React.FC<{
  trackingPageName: string;
}> = ({ trackingPageName }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const moreInfoLinkClicked = () => {
    // Track Help link of OAuth connection error modal.
    typewriter.linkPressed({
      page_or_screen_name: trackingPageName,
      link_text: 'More info',
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  return (
    <Link
      onClick={moreInfoLinkClicked}
      href={`mailto:${HELP_EMAIL}`}
      textAlign="left"
      target="_new"
    >
      {HELP_EMAIL}
    </Link>
  );
};

const JobAccountMissingContent: React.FC<{
  distributorDisplayName: string;
}> = ({ distributorDisplayName }) => (
  <Box
    as="ol"
    listStylePosition="inside"
    flexDirection="column"
    textAlign="left"
    marginY={0}
    padding={0}
    marginLeft={2}
    lineHeight="24px"
    data-test-id="JobAccountMissingContent"
  >
    <Body as="li">Click “Connect Integration” below.</Body>
    <Body as="li">
      Click “Connect” next to the {distributorDisplayName} distributor section.
    </Body>
    <Body as="li">
      In the “Connect Job Account” form, enter the job account number and
      authorization code for the new job account that you’d like to connect.
    </Body>
    <Body as="li">
      Verify that the new job account appears at the bottom of the{' '}
      {distributorDisplayName} distributor section on the Setting|Integrations
      page.
    </Body>
  </Box>
);

const OAuthErrorsContent: React.FC<{
  distributorDisplayName: string;
}> = ({ distributorDisplayName }) => (
  <Box
    as="ol"
    listStylePosition="inside"
    flexDirection="column"
    textAlign="left"
    marginY={0}
    padding={0}
    marginLeft={2}
    lineHeight="24px"
    data-test-id="OauthErrorsContent"
  >
    <Body as="li">Click “Connect Integration” below.</Body>
    <Body as="li">
      Click “Connect” next to the {distributorDisplayName} distributor section.
    </Body>
    <Body as="li">
      Sign in with your {distributorDisplayName} username and password.
    </Body>
  </Box>
);

type Props = {
  errors: ReadonlyArray<GQLError<IntegrationConnectionError>>;
  trackingPageName: string;
};

export const IntegrationConnectionErrorModalContent = ({
  errors,
  trackingPageName,
}: Props) => {
  const { distributorDisplayName } = errors[0].extensions;

  return (
    <Box flexDirection="column" textAlign="left">
      <Body margin={0}>
        Your {distributorDisplayName} and HOVER accounts need to be connected
        for direct orders.
      </Body>

      <Body isBold>To enable:</Body>

      {hasJobAccountError(errors) ? (
        <JobAccountMissingContent
          distributorDisplayName={distributorDisplayName}
        />
      ) : (
        <OAuthErrorsContent distributorDisplayName={distributorDisplayName} />
      )}

      <Body>
        For more help, send a message to{' '}
        <OAuthHelpLink trackingPageName={trackingPageName} />
      </Body>
    </Box>
  );
};
