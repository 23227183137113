import { useEffect } from 'react';

import { Body, Box, Heading } from '@hover/blueprint';
import { capitalize, isNil, lowerCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  ROOF_FACET_SELECTION_3D,
  SIDING_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getFullMeasurements,
  getJobDetails,
  getQuestionResponses,
  getRoofFacetInputs,
  getSidingFacetInputs,
  getRoofLineSegmentInputs,
  getSidingLineSegmentInputs,
  getSidingTotalQuestion,
  getBrickTotalQuestion,
  getStoneTotalQuestion,
  getBrickFacetInputs,
  getStoneFacetInputs,
  getSidingWithOpeningsAreaQuestion,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { QuestionResponses } from 'src/features/exteriorEstimator/types';
import {
  useScreenUtils,
  useToastEhi,
  ToastStatusEnum,
  useTracking,
} from 'src/hooks';
import { getOrgSettings } from 'src/redux/selectors';
import { jobProps } from 'src/utils/trackingUtils';

import { ModelSection } from '../../Machete/ModelSection';
import { TabsSections } from './TabsSection';

type Props = {
  trade: TradeTypeEnum.ROOF | TradeTypeEnum.SIDING;
  updateAnswer: (updateProps: UpdateAnswer) => void;
  updateAnswers: (responses: QuestionResponses) => void;
};

export const FacetSelection: React.FC<Props> = ({
  trade,
  updateAnswer,
  updateAnswers,
}) => {
  const dispatch = useDispatch();

  const orgSettings = useSelector(getOrgSettings);
  const jobDetails = useSelector(getJobDetails);
  const sidingLineSegmentInputs = useSelector(getSidingLineSegmentInputs);
  const roofFacetInputs = useSelector(getRoofFacetInputs);
  const sidingFacetInputs = useSelector(getSidingFacetInputs);
  const roofLineSegmentInputs = useSelector(getRoofLineSegmentInputs);
  const sidingTotalQuestion = useSelector(getSidingTotalQuestion);
  const sidingWithOpeningsAreaQuestion = useSelector(
    getSidingWithOpeningsAreaQuestion,
  );
  const brickTotalQuestion = useSelector(getBrickTotalQuestion);
  const stoneTotalQuestion = useSelector(getStoneTotalQuestion);
  const brickFacetInputs = useSelector(getBrickFacetInputs);
  const stoneFacetInputs = useSelector(getStoneFacetInputs);
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const estimationJson = useSelector(getFullMeasurements);
  const questionResponses = useSelector(getQuestionResponses);

  useEffect(() => {
    const shouldShowMeasurementsWarning = () => {
      if (!estimationJson || trade === 'ROOF') return false;
      const facades = Object.values(estimationJson?.facades ?? {}).flat();
      // if facade has trim.connected_areas and openings_trim.connected_areas then the measuremments are up to date
      return !facades.every(
        (facade) =>
          !isNil(facade?.trim?.connected_areas) &&
          !isNil(facade?.openings_trim?.connected_areas),
      );
    };

    if (shouldShowMeasurementsWarning()) {
      toast({
        id: 'measurement-warning-toast',
        status: ToastStatusEnum.WARNING,
        description:
          'The siding measurements on this job are outdated. Please ask HOVER Support to reprocess this job for the most accurate siding estimate',
      });
    }
  }, [estimationJson, toast, trade]);

  const { flexDirection, screenHeight } = useScreenUtils();
  const shouldRenderHeading = screenHeight > 500;

  const onModelClick = ({
    featureName: facetLabel,
    trade: _trade,
  }: {
    featureName: string;
    trade: TradeTypeEnum.SIDING | TradeTypeEnum.ROOF;
  }) => {
    typewriter.modelComponentSelected({
      backend_id_type: 'Facade',
      backend_id_value: facetLabel,
      page_or_screen_name:
        _trade === TradeTypeEnum.ROOF
          ? ROOF_FACET_SELECTION_3D
          : SIDING_FACET_SELECTION_3D,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
    });

    if (!facetLabel) return;

    // eslint-disable-next-line no-underscore-dangle
    const lineSegmentInputs = {
      [TradeTypeEnum.ROOF]: roofLineSegmentInputs,
      [TradeTypeEnum.SIDING]: sidingLineSegmentInputs,
    }[_trade];

    dispatch(
      estimatorActions.toggleSelectionAnswer({
        questionId: facetLabel,
        type: _trade,
        lineSegmentInputs,
        sidingTotalQuestion,
        sidingWithOpeningsAreaQuestion,
        brickTotalQuestion,
        stoneTotalQuestion,
        brickFacetInputs,
        stoneFacetInputs,
        orgSettings,
      }),
    );
  };

  const numRoofFacetsSelected = roofFacetInputs.reduce((_acc, question) => {
    let acc = _acc;
    if (questionResponses[question.id]) acc += 1;
    return acc;
  }, 0);

  const numSidingFacetsSelected = sidingFacetInputs.reduce((_acc, question) => {
    let acc = _acc;
    if (questionResponses[question.id]) acc += 1;
    return acc;
  }, 0);

  const areAllRoofFacetsSelected =
    numRoofFacetsSelected === roofFacetInputs.length;

  const areAllSidingFacetsSelected =
    numSidingFacetsSelected === sidingFacetInputs.length;

  const measurementsWarning =
    (trade === TradeTypeEnum.ROOF && !areAllRoofFacetsSelected) ||
    (trade === TradeTypeEnum.SIDING && !areAllSidingFacetsSelected)
      ? `Line segments updated based on partial ${trade.toLowerCase()} selection`
      : undefined;

  return (
    <Box width={1} flexDirection="column">
      {shouldRenderHeading && (
        <Box>
          <Box
            justifyContent="flex-start"
            flexDirection="column"
            padding={400}
            paddingLeft={600}
          >
            <Body margin={0} color="neutralTextLight">
              {capitalize(trade)}
            </Body>
            <Heading size={500}>
              Select {lowerCase(trade)} facets for project
            </Heading>
          </Box>
        </Box>
      )}

      <Box
        width={1}
        flexDirection={flexDirection}
        flex={1}
        minHeight={0}
        overflowX="hidden"
      >
        <Box flexDirection={flexDirection} width="100%">
          <ModelSection onFeatureClick={onModelClick} trade={trade} />
          <TabsSections
            trade={trade}
            updateAnswer={updateAnswer}
            updateAnswers={updateAnswers}
            measurementsWarning={measurementsWarning}
          />
        </Box>
      </Box>
    </Box>
  );
};
