import numeral from 'numeral';

interface Props {
  className?: string;
  value: number | number;
  format?: string;
  suffix?: string;
  'data-test-id'?: string;
}

// TODO: ternary operator in numeral() because of numeral library bug
// remove when fixed
export const FormattedNumber: React.FC<Props> = ({
  className,
  value,
  format,
  suffix,
  ...rest
}) => (
  <span className={className} data-test-id={rest['data-test-id']}>
    {numeral(value >= 0.000001 ? value : 0).format(format || '0,0[.]00')}
    {suffix}
  </span>
);
