import { iDollarSign } from '@hover/icons';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import { AddListItemTextInputWithTypeahead } from 'src/features/projectManagement/components/ProductionView/AddListItemFormModal/AddListItemTextInputWithTypeahead';
import {
  AddListItemErrors,
  VendorType,
} from 'src/features/projectManagement/types';

import { AddListItemDropDown } from './AddListItemDropDown';
import { AddListItemInput } from './AddListItemTextInput';
import { AddListItemFormModalState, ColorSelection } from './Modal';
import { getUnits, getVendors } from './utils';

export const CUSTOM_COLOR = 'Custom color';
export const INPUT_NAMES = {
  sku: 'skuId',
  color: 'color',
  unitPrice: 'unitPrice',
};

interface Props {
  vendors: VendorType[] | null;
  productionListTradeTypes: TradeTypeEnum[];
  addListItemErrors: AddListItemErrors;
  componentState: AddListItemFormModalState;
  nameInvalid: () => boolean;
  skuInvalid: () => boolean;
  colorInvalid: () => boolean;
  quantityInvalid: () => boolean;
  getSelectedVendor: () => VendorType | null | undefined;
  selectDropdownInput: (selection: string, name: string) => void;
  handleSearch: (input: string, selected: boolean) => void;
  handleChangeTextInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectColorDropdownInput: (
    selection: string,
    name: string,
    colors: ColorSelection[],
  ) => void;
}

export const SearchForm: React.FC<Props> = ({
  // redux state
  vendors,
  productionListTradeTypes,
  addListItemErrors,

  // parent component state
  componentState: {
    type,
    quantity,
    unitPrice,
    quantityUnit,
    itemName,
    tradeType,
    skuId,
    color,
    vendor,
    searchSuggestions,
    selectedProduct,
    showColorDropdown,
  },

  // change handlers
  selectColorDropdownInput,
  selectDropdownInput,
  getSelectedVendor,
  handleSearch,
  handleChangeTextInput,

  // validators
  nameInvalid,
  skuInvalid,
  colorInvalid,
  quantityInvalid,
}) => {
  const tradeTypes = productionListTradeTypes.map((trade: string) => ({
    value: trade as TradeTypeEnum,
  }));

  const selectedVendor = getSelectedVendor();
  const showProductSearch = selectedVendor
    ? selectedVendor?.distributor?.supportsProductCatalog
    : false;

  const colors: ColorSelection[] = [{ value: CUSTOM_COLOR, id: CUSTOM_COLOR }];

  if (selectedProduct)
    selectedProduct.variations.forEach((variation) => {
      colors.unshift({
        value: variation.name || '',
        id: variation.id,
      });
    });

  return (
    <>
      <AddListItemDropDown
        label="Category"
        onChangeFunction={(e) => selectDropdownInput(e.target.value, 'type')}
        value={type || ''}
        error={addListItemErrors && !!addListItemErrors.type}
        options={[
          { value: LineItemTypeEnum.MATERIAL },
          { value: LineItemTypeEnum.LABOR },
          { value: LineItemTypeEnum.OTHER },
        ]}
      />
      <AddListItemDropDown
        label="Trade"
        onChangeFunction={(e) =>
          selectDropdownInput(e.target.value, 'tradeType')
        }
        value={tradeType as string}
        dataIdLabel="tradeType-dropdown"
        options={tradeTypes}
      />
      <AddListItemDropDown
        label="Vendor"
        dataIdLabel="vendor-dropdown"
        onChangeFunction={(e) => selectDropdownInput(e.target.value, 'vendor')}
        value={vendor}
        options={getVendors(vendors ?? undefined)}
      />
      {showProductSearch && (
        <AddListItemTextInputWithTypeahead
          suggestions={searchSuggestions}
          label="Product search"
          secondaryLabel="Optional"
          onChangeFunction={handleSearch}
          placeholder="Search by product name"
        />
      )}
      <AddListItemInput
        label="Custom display name"
        dataIdLabel="addListItemModal-itemNameInput"
        onChangeFunction={handleChangeTextInput}
        name="itemName"
        placeholder="Enter product display name here"
        value={itemName || ''}
        error={(addListItemErrors && !!addListItemErrors.name) || nameInvalid()}
        errorMessage="Name must be less than 255 characters"
      />

      {showColorDropdown ? (
        <AddListItemDropDown
          label="Color"
          dataIdLabel="color-dropdown"
          onChangeFunction={(e) =>
            selectColorDropdownInput(e.target.value, 'color', colors)
          }
          value={color}
          error={colorInvalid()}
          options={colors}
        />
      ) : (
        <AddListItemInput
          label="Color"
          secondaryLabel="Optional"
          dataIdLabel="addItemModal-color"
          onChangeFunction={handleChangeTextInput}
          name={INPUT_NAMES.color}
          error={colorInvalid()}
          errorMessage="Color must be less than 255 characters"
          value={color || ''}
        />
      )}

      <AddListItemInput
        label="SKU ID"
        secondaryLabel="Optional"
        dataIdLabel="addItemModal-sku"
        onChangeFunction={handleChangeTextInput}
        name={INPUT_NAMES.sku}
        error={skuInvalid()}
        errorMessage="SKU must be less than 255 characters"
        value={skuId || ''}
      />
      <AddListItemDropDown
        label="UoM"
        secondaryLabel="Optional"
        onChangeFunction={(e) =>
          selectDropdownInput(e.target.value, 'quantityUnit')
        }
        value={quantityUnit}
        dataIdLabel="addListItemModal-quantityUnits"
        options={getUnits()}
      />
      <AddListItemInput
        label="Quantity"
        dataIdLabel="addListItemModal-quantity"
        onChangeFunction={handleChangeTextInput}
        name="quantity"
        type="number"
        error={quantityInvalid()}
        errorMessage="Quantity must be greater than 0"
        placeholder="Quantity"
        value={quantity?.toString() || ''}
      />
      <AddListItemInput
        label="Unit cost"
        iconBefore={iDollarSign}
        dataIdLabel="addListItemModal-unitPrice"
        onChangeFunction={handleChangeTextInput}
        name={INPUT_NAMES.unitPrice}
        type="number"
        placeholder="0.00"
        value={unitPrice?.toString() || ''}
      />
    </>
  );
};
