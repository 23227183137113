import { PureComponent } from 'react';

import { Box, TextInput } from '@hover/blueprint';
import styled from 'styled-components';

import { Label } from 'src/components/Misc';

export interface ComponentProps {
  label: string;
  secondaryLabel?: string;
  dataIdLabel?: string;
  onChangeFunction?: (props: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMessage?: string;
  name?: string;
  value: string;
  placeholder?: string;
  type?: 'number' | 'text';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iconBefore?: any; // IconDefinition from Blueprint
}

export type Props = ComponentProps;

export const ErrorMessage = styled(Box)`
  width: 100%;
  color: ${({ theme }) => theme.colors.danger500};
  font-size: 12px;
  margin-top: 4px;
`;

export class AddListItemInput extends PureComponent<Props> {
  render() {
    const {
      label,
      secondaryLabel,
      dataIdLabel,
      onChangeFunction,
      error,
      name,
      value,
      placeholder,
      iconBefore,
      errorMessage,
      type,
    } = this.props;

    const dataTestIdForBox = label.replace(/\s/g, '').toLowerCase();

    return (
      <Box flexDirection="column">
        <Box flexDirection="row" justifyContent="space-between">
          <Label>{label}</Label>
          {secondaryLabel && <Label>{secondaryLabel}</Label>}
        </Box>
        <Box
          alignItems="center"
          data-test-id={`${dataTestIdForBox}-addItemInput`}
          flexDirection="column"
        >
          <TextInput
            data-test-id={dataIdLabel || ''}
            width="100%"
            onChange={(e) => onChangeFunction && onChangeFunction(e)}
            onBlur={(e) => onChangeFunction && onChangeFunction(e)}
            name={name}
            value={value}
            type={type}
            placeholder={placeholder}
            isInvalid={error}
            iconBefore={iconBefore}
          />
          {error && (
            <ErrorMessage
              data-test-id={`${dataTestIdForBox}-addItemInput-error`}
            >
              {errorMessage}
            </ErrorMessage>
          )}
        </Box>
      </Box>
    );
  }
}
