import React, { useEffect, useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Heading, Loader } from '@hover/blueprint';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  EstimationConfigTemplateCollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { GET_TEMPLATE_COLLECTIONS } from 'src/api/queries/queries';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { EmptySavedTemplatesDisclaimer } from 'src/components/MaterialList/EmptySavedTemplatesDisclaimer';
import { TemplateCardsSection } from 'src/components/MaterialList/TemplateCardsSection';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { useMachete } from 'src/features/exteriorEstimator/hooks';
import { initializeSelectedTemplates } from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getJobDetails,
  getGeometry,
  getMetadata,
  getSelectedTemplateIds,
  getTemplateSearchFilter,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getTemplateIdsFromUrl } from 'src/features/exteriorEstimator/utils/templateUtils';
import { useEffectOnMount, useMaterialListTemplates } from 'src/hooks';
import { getUserOrgId } from 'src/redux/selectors';

const TemplateWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
`;

export const SelectMaterialListTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const { initializeMacheteData } = useMachete();
  const {
    dispatchTemplatesFromSections,
    toggleTemplateAndUnselectOthersInSameTrade,
  } = useMaterialListTemplates();

  const orgId = useSelector(getUserOrgId);
  const geometryUrl = useSelector(getJobDetails)?.threeDFiles?.geometryUrl;
  const metadataUrl = useSelector(getJobDetails)?.threeDFiles?.metadataUrl;

  const geometry = useSelector(getGeometry);
  const metadata = useSelector(getMetadata);

  let selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const templateSearchFilter = useSelector(getTemplateSearchFilter);

  if (!selectedTemplateIds) {
    selectedTemplateIds = getTemplateIdsFromUrl();
    dispatch(initializeSelectedTemplates({ templateIds: selectedTemplateIds }));
  }

  const { data: templateCollectionsData, loading: templateCollectionsLoading } =
    useQuery(GET_TEMPLATE_COLLECTIONS, {
      variables: {
        orgId,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const handleCardClick = useCallback(
    (template: Template, templateTradeType: TradeTypeEnum) => {
      toggleTemplateAndUnselectOthersInSameTrade(
        template.id,
        templateTradeType,
      );
    },
    [templateCollectionsData, selectedTemplateIds],
  );

  useEffect(() => {
    // safely deconstruct templateCollectionsData so that we don't get an error if it's undefined or its templateSections is undefined
    const {
      estimationConfigTemplateCollectionsForOrg: { templateSections } = [],
    } = templateCollectionsData || {};

    if (!templateSections?.length) {
      return;
    }

    dispatchTemplatesFromSections(templateSections);
  }, [templateCollectionsData]);

  useEffectOnMount(() => {
    if (!geometry && !metadata && geometryUrl && metadataUrl) {
      initializeMacheteData(geometryUrl, metadataUrl);
    }
  });

  // render loading spinner if template collections are still loading
  if (templateCollectionsLoading) {
    return (
      <Box flex-direction="column" width="100%" height="100vh">
        <EstimatorResponsiveWrapper flex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Loader
              size="huge"
              data-test-id="SelectMaterialListTemplates-Loader"
            />
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
    );
  }

  // otherwise get the template sections from the data
  const {
    estimationConfigTemplateCollectionsForOrg: { templateSections },
  } = templateCollectionsData;
  // and render the template cards
  return (
    <Box flex-direction="column" width="100%" height="100vh">
      <EstimatorResponsiveWrapper data-test-id="select-templates-page" flex={1}>
        <Box flexDirection="column" width="100%">
          <Heading
            size={500}
            marginBottom={400}
            marginLeft={100}
            fontWeight="bold"
          >
            Select Template
          </Heading>

          <TemplateWrapper>
            {templateSections.map((section: TemplateSection) => {
              return section.collectionType ===
                EstimationConfigTemplateCollectionTypeEnum.CUSTOM &&
                section.templatesByTradeType.length <= 0 ? (
                <EmptySavedTemplatesDisclaimer key="empty-saved-template-disclaimer" />
              ) : (
                <TemplateCardsSection
                  templateCollection={section}
                  key={`${section.name}-template-cards-section`}
                  handleCardClick={handleCardClick}
                  searchFilterValue={templateSearchFilter}
                />
              );
            })}
          </TemplateWrapper>
        </Box>
      </EstimatorResponsiveWrapper>
    </Box>
  );
};
