export const reorderList = (
  list: Array<any>,
  sourceIndex: number,
  destinationIndex: number,
) => {
  const clonedList = [...list];
  const [removedItem] = clonedList.splice(sourceIndex, 1);
  clonedList.splice(destinationIndex, 0, removedItem);

  return clonedList;
};
