import { Box, Image } from '@hover/blueprint';

import HOVER_LOGO_SRC from 'src/assets/images/hover-logo.png';

interface TemplateLogoProps {
  logoUrl?: string | null;
}

export const TemplateLogo = ({ logoUrl }: TemplateLogoProps) => (
  <Box alignItems="center" marginBottom={600}>
    <Image src={logoUrl || HOVER_LOGO_SRC} alt="hover logo" width="300px" />
  </Box>
);
