/* eslint-disable camelcase */
import { ehiOrgSettings_ehiOrgSettings as OrgSettings } from 'src/api/types/ehiOrgSettings';
import { EstimateGroup_estimationEstimateGroup as EstimateGroup } from 'src/api/types/EstimateGroup';
import { profile_profile_user as User } from 'src/api/types/profile';
import EventNames from 'src/lib/eventNames';

export type { OrgSettings, User };

export type { EstimateGroup };

export enum TrackEventType {
  TRACK = 'TRACK',
  PAGEVIEW = 'PAGEVIEW',
}

export { EventNames };

export interface GetSubOrgResponse {
  meta: {
    pagination: {
      next_page: number | null;
    };
  };
  orgs: SubOrg[];
}

export interface SubOrg {
  id: number;
  name: string;
  parent_id: number;
}

export enum AUTHENTICATION_STATES {
  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATED = 'AUTHENTICATED',
  FAILED = 'FAILED',
}
