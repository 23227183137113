import { createSelector } from 'reselect';

import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { getOrgSettings } from 'src/redux/selectors/orgSelectors';

export const isUserLightWeightFlow = createSelector(
  getOrgSettings,
  (orgSettings: EhiOrgSettings | null) =>
    orgSettings?.showOrderingFlow ?? false,
);

export const shouldShowEstimateLineItemDetailsToggle = createSelector(
  getOrgSettings,
  (orgSettings: EhiOrgSettings | null) =>
    orgSettings?.showEstimateLineItemDetailsToggle ?? false,
);
