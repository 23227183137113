import { Checkbox, CheckboxProps } from '@hover/blueprint';

import type { QuestionAnswer } from 'src/features/exteriorEstimator/types';

type Props = CheckboxProps & {
  handleInputChange: (answer: QuestionAnswer) => void;
};

export const CheckboxQuestion: React.FC<Props> = ({
  handleInputChange,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e.target.checked);
  };
  return <Checkbox {...props} onChange={handleChange} />;
};
