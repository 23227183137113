import pluralize from 'pluralize';

import {
  GeometryEdge,
  ClassificiationFacade,
  WindowsForFacade,
} from 'src/features/exteriorEstimator/types';
import {
  CorniceEdge,
  HdfSource,
  RoofEdge,
  WallEdge,
} from 'src/features/exteriorEstimator/types/HdfMeasurements';

export interface EdgesForFacade {
  [facadeDisplayLabel: string]: string[];
}

export interface EdgeTotals {
  // ie ridge_total, ridge_count
  [key: string]: number;
}

export interface EdgeTotalsByTypeForFacade {
  [facadeDisplayLabel: string]: EdgeTotals;
}

export class PartialsMeasurementsMapHdf {
  public windowsForFacade: WindowsForFacade = {};

  public edges: GeometryEdge[] = [];

  public facades: ClassificiationFacade[] = [];

  constructor({ hdf }: { hdf: HdfSource }) {
    this.edges = PartialsMeasurementsMapHdf.createEdges(hdf);
    this.facades = PartialsMeasurementsMapHdf.createFacades(hdf);
    this.windowsForFacade =
      PartialsMeasurementsMapHdf.createWindowsForFacade(hdf);
  }

  private static edgeInfo(
    edge: CorniceEdge | WallEdge | RoofEdge,
    source: string,
  ) {
    const pluralTypes = [
      'SLOPED_BASE',
      'LEVEL_BASE',
      'OPENING_BOTTOM',
      'OPENING_TOP',
      'OPENING_SIDE',
    ];
    return {
      id: edge.id.uuid,
      length: edge.length,
      // eslint-disable-next-line no-prototype-builtins
      classifiedInContext: edge.hasOwnProperty('classifiedInContext')
        ? (edge as WallEdge).classifiedInContext
        : undefined,
      type: pluralTypes.includes(edge.edgeClass)
        ? pluralize(edge.edgeClass.toLowerCase())
        : edge.edgeClass?.toLowerCase(),
      source,
    };
  }

  private static createEdges(hdf: HdfSource) {
    const edges: GeometryEdge[] = [];

    hdf.classification.corniceEdges.forEach((edge) => {
      edges.push(PartialsMeasurementsMapHdf.edgeInfo(edge, 'cornice'));
    });
    // wall should go first because estimation_json sorted them siding, then unclassified, then roof for some reason
    hdf.classification.wallEdges.forEach((edge) => {
      edges.push(PartialsMeasurementsMapHdf.edgeInfo(edge, 'wall'));
    });
    hdf.classification.roofEdges.forEach((edge) => {
      edges.push(PartialsMeasurementsMapHdf.edgeInfo(edge, 'roof'));
    });

    return edges;
  }

  private static createFacades(hdf: HdfSource) {
    const facades: ClassificiationFacade[] = [];
    hdf.classification.faces.forEach((face) => {
      const faceWithEdges = hdf.geometry.faces.find(
        (geometryFace) => geometryFace.id.uuid === face.id.uuid,
      );
      facades.push({
        display_label: face.id.name,
        area: face.area,
        id: face.id.uuid,
        edges: faceWithEdges?.edgeIds.map((edgeId) => edgeId.uuid) ?? [],
      });
    });
    return facades;
  }

  // these have different labels than estimation_json and do not match the PDF
  private static createWindowsForFacade(hdf: HdfSource) {
    const map: { [key: string]: string[] } = {};
    hdf.classification.windows.forEach((window) => {
      window.parentFaceIds.forEach((faceId) => {
        if (map[faceId.name]) {
          map[faceId.name] = [...map[faceId.name], window.id.name];
        } else {
          map[faceId.name] = [window.id.name];
        }
      });
    });

    return map;
  }
}
