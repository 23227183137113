import { Box, Heading } from '@hover/blueprint';

import OrderInstructions from '../../OrderModal/Content/OrderDetailsForm/OrderInstructions';
import { DeliveryDetails } from './DeliveryDetails/DeliveryDetails';
import { DeliveryForm } from './DeliveryForm/DeliveryForm';
import { DeliveryMethods } from './DeliveryMethods/DeliveryMethods';

export const Delivery = () => (
  <Box flexDirection="column" width={1} paddingTop={600}>
    <Box flexDirection="column">
      <Heading color="primary900" size={500} marginBottom={400}>
        Delivery method
      </Heading>
      <DeliveryMethods />
      <DeliveryForm />
    </Box>
    <DeliveryDetails />
    <Box flexDirection="column">
      <Heading color="primary900" size={500} marginBottom={400}>
        Instructions
      </Heading>
      <OrderInstructions />
    </Box>
  </Box>
);
