import {
  Box,
  Body,
  Heading,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  TextInput,
} from '@hover/blueprint';
import { iPlusCircle } from '@hover/icons';

import {
  InspectionChecklistQuestionSupportsImageUploadsEnum,
  InspectionConfigChecklistQuestionTypeEnum,
} from 'src/api/graphql-global-types';
import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate_sections_questions_options as QuestionOptionType } from 'src/api/types/inspectionConfigChecklistTemplate';

import { IconBar } from './IconBar';

const getQuestionInput = (
  questionType: InspectionConfigChecklistQuestionTypeEnum | null,
  options: QuestionOptionType[] | null,
) => {
  if (questionType === InspectionConfigChecklistQuestionTypeEnum.BOOLEAN) {
    return (
      <RadioGroup flexDirection="row">
        <Box flexDirection="row">
          <Radio value="true" marginRight="40px">
            Yes
          </Radio>
          <Radio value="false">No</Radio>
        </Box>
      </RadioGroup>
    );
  }

  if (questionType === InspectionConfigChecklistQuestionTypeEnum.STRING) {
    return <Textarea placeholder="Placeholder" rows={3} />;
  }

  if (questionType === InspectionConfigChecklistQuestionTypeEnum.SELECT) {
    return (
      <Select placeholder="Select one">
        {options &&
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
      </Select>
    );
  }

  if (questionType === InspectionConfigChecklistQuestionTypeEnum.NUMBER) {
    return <TextInput type="number" placeholder="Enter a number" />;
  }

  return <Textarea placeholder="Placeholder" rows={3} />;
};

interface TemplateQuestionProps {
  id: string;
  templateId: string;
  templateSectionId: string;
  questionText: string;
  questionType: InspectionConfigChecklistQuestionTypeEnum | null;
  supportsImageUploads: InspectionChecklistQuestionSupportsImageUploadsEnum;
  sortOrder: number | null;
  updateQuestionOrder: (questionId: string, direction: number) => void;
  options: QuestionOptionType[] | null;
  isFirst: boolean;
  isLast: boolean;
}

export const TemplateQuestion = ({
  id,
  templateId,
  templateSectionId,
  questionText,
  questionType,
  supportsImageUploads,
  sortOrder,
  updateQuestionOrder,
  options,
  isFirst,
  isLast,
}: TemplateQuestionProps) => (
  <Box
    flexDirection="column"
    marginBottom={700}
    paddingY={300}
    paddingX={400}
    position="relative"
    backgroundColor="neutral.50"
  >
    <Box alignItems="center">
      <Heading isLight size={200} color="neutral" flexDirection="row">
        {questionText}
      </Heading>
    </Box>
    <Box paddingY="10px" maxWidth="50%">
      {getQuestionInput(questionType, options)}
    </Box>
    {supportsImageUploads ===
      InspectionChecklistQuestionSupportsImageUploadsEnum.OPTIONAL && (
      <Box alignItems="center">
        <IconButton
          label="DeleteTemplateSection"
          color="neutral"
          icon={iPlusCircle}
          fill="minimal"
          mr={100}
        />
        <Box>
          <Body as="span">Add Photos</Body>
        </Box>
      </Box>
    )}
    <IconBar
      templateQuestionId={id}
      questionText={questionText}
      questionType={questionType}
      supportsImageUploads={supportsImageUploads}
      templateId={templateId}
      templateSectionId={templateSectionId}
      sortOrder={sortOrder}
      updateQuestionOrder={updateQuestionOrder}
      options={options}
      isFirst={isFirst}
      isLast={isLast}
    />
  </Box>
);
