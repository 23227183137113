import { gql } from '@apollo/client';

import { userAnswers } from 'src/api/queries/queries';

export const CREATE_ESTIMATE_GROUP = gql`
  mutation estimationEstimateGroupCreate(
    $estimateGroupAttributes: EstimationEstimateGroupCreate!
    $tradeTypeAttributes: [EstimationEstimateGroupCreateTradeTypeAttributeInput!]
    $templateIds: [ID!]
    $inputs: [EstimationEstimateGroupCreateInput!]!
    $markAsSoldAndActivate: Boolean = false
    $customLineItems: [EstimationEstimateGroupCreateCustomLineItem!]
    $customizedProductionList: Boolean = false
  ) {
    estimationEstimateGroupCreate(
      estimateGroupAttributes: $estimateGroupAttributes
      tradeTypeAttributes: $tradeTypeAttributes
      templateIds: $templateIds
      inputs: $inputs
      markAsSoldAndActivate: $markAsSoldAndActivate
      customLineItems: $customLineItems
      customizedProductionList: $customizedProductionList
    ) {
      estimateGroup {
        id
        orgId
        jobId
        state
        monthlyPrice
        profitAmount
        profitMargin
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY = gql`
  query EstimateGroupForInputSummary($id: ID!, $active: Boolean) {
    estimationEstimateGroup(id: $id) {
      id
      jobId
      orgId
      createdAt
      totalPrice
      monthlyPrice
      profitAmount
      profitMargin
      facets {
        id
        identifier
        area
      }
      estimates(active: $active) {
        id
        active
        name
        tradeType
        jobId
        orgId
        userId
        template {
          id
          name
          tradeType
        }
        lineItems {
          id
          name
          price
          custom
        }
      }
      userAnswers {
        ...userAnswers
      }
    }
  }
  ${userAnswers}
`;

export const GET_ESTIMATE_GROUP = gql`
  query EstimateGroup(
    $id: ID!
    $active: Boolean
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    estimationEstimateGroup(id: $id) {
      id
      name
      enableVariationMatching
      jobId
      orgId
      state
      failureReason
      createdAt
      totalPrice
      monthlyPrice
      profitAmount
      profitMargin
      facets {
        id
        identifier
        area
      }
      estimates(active: $active) {
        id
        active
        name
        tradeType
        basePrice
        discounts {
          discountType
          id
          name
          value
          estimationEstimateId
        }
        jobId
        orgId
        userId
        template {
          id
          name
          tradeType
          sortOrder
          showPricesInEstimation
        }
        templateGroups {
          id
          name
          description
          sortOrder
          lineItems {
            allowVariationSelection
            custom
            isAuto
            id
            name
            price
            product {
              id
              name
              variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
                id
                name
                texture {
                  diffuseMapThumbnail {
                    redirectUrl
                  }
                }
              }
            }
            quantity
            quantityUnits
            results
            salesTax
            margin
            measurement
            measurementUnits
            wasteFactor
            productCatalogVariationName
            selectedVariation {
              id
              name
            }
            type
            unitCost
            values
            wasteFactor
          }
        }
        fullMeasurements
      }
      salesOpportunity {
        id
        soldEstimateGroupId
      }
      userAnswers {
        ...userAnswers
      }
    }
  }
  ${userAnswers}
`;

export const GET_ESTIMATE_GROUP_FOR_SUMMARY = gql`
  query EstimateGroupForSummary(
    $id: ID!
    $active: Boolean
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    estimationEstimateGroup(id: $id) {
      id
      enableVariationMatching
      jobId
      orgId
      state
      failureReason
      createdAt
      totalPrice
      monthlyPrice
      profitAmount
      profitMargin
      facets {
        id
        identifier
        area
      }
      estimates(active: $active) {
        id
        active
        name
        tradeType
        basePrice
        discounts {
          discountType
          id
          name
          value
          estimationEstimateId
        }
        jobId
        orgId
        userId
        template {
          id
          name
          tradeType
          sortOrder
          showPricesInEstimation
        }
        lineItems {
          allowVariationSelection
          custom
          id
          isAuto
          margin
          measurement
          measurementUnits
          name
          price
          product {
            id
            name
            variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
              id
              name
              texture {
                diffuseMapThumbnail {
                  redirectUrl
                }
              }
            }
          }
          quantity
          quantityUnits
          results
          salesTax
          productCatalogVariationName
          selectedVariation {
            id
            name
          }
          type
          unitCost
          values
          wasteFactor
        }
        fullMeasurements
      }
      salesOpportunity {
        id
        soldEstimateGroupId
      }
      userAnswers {
        ...userAnswers
      }
    }
  }
  ${userAnswers}
`;
export const GET_ESTIMATE_GROUPS_FOR_JOB = gql`
  query estimationEstimateGroupsForJob(
    $jobId: ID
    $endCursor: String
    $active: Boolean
  ) {
    estimationEstimateGroups(jobId: $jobId, after: $endCursor, first: 20) {
      edges {
        node {
          id
          name
          state
          createdAt
          orgId
          jobId
          totalPrice
          estimates(active: $active) {
            id
            tradeType
          }
          salesOpportunity {
            id
            soldEstimateGroupId
          }
        }
        cursor
      }
      nodes {
        id
        name
        state
        createdAt
        orgId
        jobId
        totalPrice
        estimates(active: $active) {
          id
          tradeType
        }
        salesOpportunity {
          id
          soldEstimateGroupId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
