import { createAction } from 'typesafe-actions';

import {
  QuestionId,
  QuestionAnswer,
  QuestionResponses,
  Input,
} from 'src/features/exteriorEstimator/types';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';

export interface UpdateAnswer {
  questionId: QuestionId;
  answer: QuestionAnswer;
}

export const updateAnswer = createAction(
  'EXTERIOR_ESTIMATOR/UPDATE_ANSWER',
  ({ questionId, answer }: UpdateAnswer) => ({
    questionId,
    answer,
  }),
)<UpdateAnswer>();

export const updateAnswers = createAction(
  'EXTERIOR_ESTIMATOR/UPDATE_ANSWERS',
  (questionsAndAnswers: QuestionResponses) => questionsAndAnswers,
)();

// this toggles partial selection questions (true/false) so the callee does not need to know what answer to update to
// necessary ATM for the callback that the Machete model uses
export const toggleSelectionAnswer = createAction(
  'EXTERIOR_ESTIMATOR/TOGGLE_SELECTION_ANSWER',
  (payload: {
    questionId: string;
    type: 'SIDING' | 'ROOF';
    lineSegmentInputs: Input[];
    sidingTotalQuestion?: Input | null | undefined;
    sidingWithOpeningsAreaQuestion?: Input | null | undefined;
    brickTotalQuestion: Input | null | undefined;
    stoneTotalQuestion: Input | null | undefined;
    brickFacetInputs?: Input[] | null | undefined;
    stoneFacetInputs?: Input[] | null | undefined;
    orgSettings: EhiOrgSettings;
  }) => payload,
)();
