import { uniqBy } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

import { RootState } from 'src/types/reduxStore';

export const getOrgSettings = (state: RootState) => state.ehi.orgSettings;

export const getTradeTypesSorted = (state: RootState) =>
  state.ehi.tradeTypesSorted;

export const getUserOrgs = (state: RootState) =>
  state.hover?.userProfile?.orgs ?? [];

export const getUserOrgId = createSelector(getUserOrgs, (orgs) =>
  orgs[0]?.id.toString(),
);

export const getParams = (state: RootState) =>
  queryString.parse(state.router.location.search);

// orgId querystring param must be required, to support suborg functionality;
// there is no typing that can enforce that the param is present in the URL querystring,
// so this selector and return typing present the querystring param as required.
export const getOrgIdParam = (state: RootState) => {
  return (getParams(state).orgId ?? '') as string;
};

export const getMaterialListCreateParam = (state: RootState) => {
  return (getParams(state).materialListCreate === 'true') as boolean;
};

export const getSubOrgs = (state: RootState) => state.ehi.subOrgs;

export const getParentOrg = createSelector(
  getUserOrgs,
  getSubOrgs,
  (parentOrgs, subOrgs) => {
    if (subOrgs.length === 0) return parentOrgs[0];
    return parentOrgs.find(
      (org) => Number(org.id) === Number(subOrgs[0]?.parent_id),
    );
  },
);

export const combineSubOrgsWithUserOrgs = createSelector(
  getParentOrg,
  getSubOrgs,
  (parentOrg, subOrgs) => uniqBy([parentOrg, ...subOrgs], 'id'),
);

export const getVariationsFilter = createSelector(
  getOrgSettings,
  getUserOrgId,
  getOrgIdParam,
  (orgSettings, userOrgId, paramOrgId) => {
    const productFilteringEnabled = orgSettings?.productFilteringEnabled;
    const orgId = paramOrgId || userOrgId;
    return {
      // returns an object so the return value can be spread into the query without worryng about naming conflicts
      filterVariationsByOrg: productFilteringEnabled,
      orgId,
    };
  },
);

export const getUserOrgVariationsFilter = createSelector(
  getOrgSettings,
  getUserOrgId,
  (orgSettings, userOrgId) => {
    const productFilteringEnabled = orgSettings?.productFilteringEnabled;
    const orgId = userOrgId;
    return {
      // returns an object so the return value can be spread into the query without worryng about naming conflicts
      filterVariationsByOrg: productFilteringEnabled,
      orgId,
    };
  },
);
