/* eslint-disable no-await-in-loop */
import { QueryOptions } from '@apollo/client';
import { set } from 'lodash';

import { GraphqlClient } from 'src/lib/GraphqlClient';

type Props = QueryOptions & {
  typeName: string;
};

export const getPaginatedGql = async <T>({
  typeName,
  ..._queryOptions
}: Props) => {
  let currentEndCursor = null;
  const queryOptions = { ..._queryOptions };
  let shouldFetch = true;
  try {
    while (shouldFetch) {
      shouldFetch = false;
      if (currentEndCursor) {
        set(queryOptions, 'variables.after', currentEndCursor);
      }

      const { data } = await GraphqlClient.query({
        ...queryOptions,
        fetchPolicy: 'network-only',
      });

      const {
        pageInfo: { hasNextPage, endCursor },
      } = data[typeName];

      if (hasNextPage && currentEndCursor !== endCursor) {
        currentEndCursor = endCursor;
        shouldFetch = true;
      }
    }

    return GraphqlClient.readQuery<T>(_queryOptions);
  } finally {
    // NO OP
  }
};
