import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';

import { PROJECT_MANAGEMENT_ORDER_DOCUMENTS } from 'src/features/projectManagement/apis/graphql/queries/queries';
import { RootState } from 'src/types/reduxStore';

import { getProductionListId } from '../../../redux/selectors/estimatorProductionSelectors';
import { sortObjectListByCreatedAt } from '../../../utils/ProductionListUtils';
import { PdfModal } from './PdfModal';

export const mapStateToProps = (state: RootState) => ({
  productionListId: getProductionListId(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const PdfWrapper: React.FC<Props> = ({ productionListId }) => {
  const fetchPolicy = window.Cypress ? 'no-cache' : 'cache-first';

  const { loading, data, refetch } = useQuery(
    PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
    {
      variables: { productionListId },
      fetchPolicy,
      skip: !productionListId,
    },
  );

  if (!data || loading) return null;
  const orderDocuments = data.projectManagementOrderDocuments;
  const sortedOrderDocuments = sortObjectListByCreatedAt(orderDocuments);

  return <PdfModal orderDocuments={sortedOrderDocuments} refetch={refetch} />;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(PdfWrapper);
