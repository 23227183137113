import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  Td,
  Tooltip,
  Icon,
  Divider,
} from '@hover/blueprint';
import { hCalculator } from '@hover/icons';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  LineItemTypeEnum,
  EditedStatusEnum,
} from 'src/api/graphql-global-types';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { formattedNumber } from 'src/features/project/util/utils';
import { usePrevious, useTracking } from 'src/hooks';
import {
  isEnabled,
  COMMERCE_INLINE_EDITING_REDESIGN,
} from 'src/lib/FeatureFlag';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

import { useProjectScopeTracker } from '../../hooks/useProjectScopeTracker';
import { EditableUnitsSelection } from './Inputs/EditableUnitsSelection';

type Props = {
  listItem: ListItem;
  isDisabled: boolean;
  unitsMap: Map<string, string>;
  enableInlineEditingV2: boolean;
  quantityUnit: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemQuantityColumn = ({
  listItem,
  isDisabled,
  unitsMap,
  quantityUnit,
  jobId,
  enableInlineEditingV2,
  onUpdate,
}: Props) => {
  /* Tracking */
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const triggerCalculationDetailsEvent = () => {
    typewriter.moduleViewed({
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      job_id: jobId,
      ...commonTrackingProps,
    });
  };

  const isMaterial = listItem?.type === LineItemTypeEnum.MATERIAL;
  const quantityErrorMessage = isMaterial
    ? 'Quantity must be whole number greater than zero'
    : 'Quantity must be greater than zero';

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  const prevQuantity = usePrevious(listItem.quantity);

  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();

  const quantityFormValue = getValues().quantity;
  const inlineEditRedesignEnabled = isEnabled(COMMERCE_INLINE_EDITING_REDESIGN);
  const inlineEditingRedesignStyles = inlineEditRedesignEnabled
    ? {
        paddingTop: { base: 100, tablet: 500 },
        paddingBottom: { base: 0, tablet: 400 },
        paddingLeft: 0,
        paddingRight: 0,
        verticalAlign: 'top',
      }
    : {};

  /* Calculation Details Tooltip */
  const showCalculationNumerics =
    (!!listItem.measurement && listItem.measurement > 0) ||
    (!!listItem.wasteFactor && listItem.wasteFactor > 0) ||
    !!listItem.coverage;

  const showCalculationDetailTooltip =
    !!listItem.listItemContext || showCalculationNumerics;

  const calculationDetailFragment = (
    <>
      <Box fontSize={400}>Calculation Details</Box>
      {!!listItem.listItemContext && (
        <Box fontSize={100} fontWeight={400} marginTop={100}>
          {listItem.listItemContext}
        </Box>
      )}
      {showCalculationNumerics && <Divider />}
      {!!listItem.measurement && listItem.measurement > 0 && (
        <Box display="flex" justifyContent="space-between">
          <Box fontSize={100} fontWeight={600} letterSpacing="1px">
            MEASUREMENTS:
          </Box>
          <Box fontSize={300} fontWeight={400}>{`${formattedNumber(
            listItem.measurement,
            null,
            lineItemQuantityUnits(listItem.measurementUnits),
          )}`}</Box>
        </Box>
      )}
      {!!listItem.wasteFactor && listItem.wasteFactor > 0 && (
        <Box display="flex" justifyContent="space-between" marginTop={400}>
          <Box fontSize={100} fontWeight={600} letterSpacing="1px">
            WASTE:
          </Box>
          <Box fontSize={300} fontWeight={400}>{`${
            (listItem.wasteFactor || 0) * 100
          } %`}</Box>
        </Box>
      )}
      {!!listItem.coverage && (
        <Box display="flex" justifyContent="space-between" marginTop={400}>
          <Box fontSize={100} fontWeight={600} letterSpacing="1px">
            COVERAGE:
          </Box>
          <Box fontSize={300} fontWeight={400}>{`${listItem.coverage} x`}</Box>
        </Box>
      )}
    </>
  );

  if (!enableInlineEditingV2) {
    return (
      <Td>
        <>
          <Box>{`${listItem.quantity} ${quantityUnit}`}</Box>
          {!!listItem.calculatedQuantity && (
            <Box fontSize={200} color="neutral.500">
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          )}
        </>
      </Td>
    );
  }

  return (
    <Td {...inlineEditingRedesignStyles} flexBasis="50%">
      <>
        <Box>
          {inlineEditRedesignEnabled ? (
            <>
              {showCalculationDetailTooltip && (
                <Tooltip
                  bg="white"
                  label={calculationDetailFragment}
                  placement="top"
                  color="black"
                  padding={500}
                  width={700}
                  onOpen={triggerCalculationDetailsEvent}
                >
                  <Box
                    border="1px solid"
                    borderColor="neutral.500"
                    borderRadius="6px 0px 0px 6px"
                    borderRight="0px"
                    alignItems="center"
                    padding={50}
                  >
                    <Icon
                      icon={hCalculator}
                      color={
                        materialListFeatureEnabled &&
                        listItem.editedStatus === EditedStatusEnum.EDITED
                          ? 'warning.500'
                          : undefined
                      }
                    />
                  </Box>
                </Tooltip>
              )}
              <Input
                data-test-id="AddMaterial-quantity"
                size="small"
                borderRight="0px"
                borderRadius={
                  showCalculationDetailTooltip ? '0px' : '6px 0px 0px 6px'
                }
                borderColor="neutral.500"
                textAlign="right"
                isRequired
                isInvalid={!!get(formErrors, 'quantity')}
                type="text"
                {...register('quantity', {
                  valueAsNumber: true,
                  validate: {
                    numberValidator: (quantityValue) => {
                      if (isMaterial) {
                        return Number.isInteger(quantityValue)
                          ? true
                          : quantityErrorMessage;
                      }
                      return Number.isFinite(quantityValue)
                        ? true
                        : quantityErrorMessage;
                    },
                    quantityValidator: (quantityValue) => {
                      return quantityValue > 0 ? true : quantityErrorMessage;
                    },
                  },
                })}
                onBlur={async (event) => {
                  // Set the new value based on the blur event, then trigger validation and wait for completion.
                  const targetElement = event.target as HTMLInputElement;
                  const newValue = parseFloat(targetElement.value);

                  setValue('quantity', newValue);
                  await trigger('quantity');

                  // If new value has no errors, update the value. Else, revert it.
                  if (!get(formErrors, 'quantity')) {
                    onUpdate('Quantity');
                  } else {
                    setValue('quantity', prevQuantity);
                  }
                }}
              />
            </>
          ) : (
            <Editable
              defaultValue={quantityFormValue}
              isPreviewFocusable
              selectAllOnFocus
              isDisabled={isDisabled}
              onSubmit={() => {
                onUpdate('Quantity');
              }}
              onChange={() => {
                trackInlineEditingInputPressed('Quantity');
              }}
              submitOnBlur={!get(formErrors, 'quantity.message')} // Prevents submission of invalid value, when validation error.
              onCancel={(prev) => {
                // When canceling edit (blurring the field) while the field is invalid,
                // cancel any in-progess saving.
                if (!!get(formErrors, 'quantity')) {
                  return;
                }

                // If input is canceled on blur due to validation error, then reset the previous value.
                setValue(
                  'quantity',
                  parseFloat(prev), // convert display string back to number type.
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                );
              }}
              size="tiny"
              display="inline-block"
              width="fit-content"
            >
              <Tooltip label="Click to edit" placement="top">
                <EditablePreview
                  width="fit-content"
                  minWidth="60px"
                  textAlign="right"
                />
              </Tooltip>
              <Tooltip
                label={get(formErrors, 'quantity.message')}
                placement="top"
                background="danger300"
              >
                <Input
                  data-test-id="AddMaterial-quantity"
                  as={EditableInput}
                  size="tiny"
                  // isRequired
                  isInvalid={!!get(formErrors, 'quantity')}
                  maxWidth="60px"
                  minWidth="60px"
                  textAlign="right"
                  type="text"
                  {...register('quantity', {
                    valueAsNumber: true,
                    validate: {
                      numberValidator: (quantityValue) => {
                        if (isMaterial) {
                          return Number.isInteger(quantityValue)
                            ? true
                            : quantityErrorMessage;
                        }
                        return Number.isFinite(quantityValue)
                          ? true
                          : quantityErrorMessage;
                      },
                      quantityValidator: (quantityValue) => {
                        return quantityValue > 0 ? true : quantityErrorMessage;
                      },
                    },
                  })}
                />
              </Tooltip>
            </Editable>
          )}

          {!inlineEditRedesignEnabled && (
            <Editable
              value={quantityUnit}
              isPreviewFocusable
              selectAllOnFocus={false}
              size="tiny"
              isDisabled={isDisabled}
              display="inline-block"
              width="fit-content"
              onSubmit={() => {
                onUpdate('Quantity Unit');
              }}
            >
              <Tooltip label="Click to edit" placement="top">
                <EditablePreview />
              </Tooltip>
              <EditableInput
                as={EditableUnitsSelection}
                units={Array.from(unitsMap, ([id, name]) => ({
                  id,
                  name: name.toUpperCase(),
                }))}
                listItem={listItem as unknown as ListItem}
                jobId={jobId}
                minWidth="56px"
              />
            </Editable>
          )}
        </Box>
        {!!listItem.calculatedQuantity && !inlineEditRedesignEnabled && (
          <Box>
            <Box
              display="inline"
              fontSize={200}
              color="neutral.500"
              paddingX={200}
              width="fit-content"
              minWidth="60px"
              textAlign="right"
            >
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          </Box>
        )}
        {!!listItem.calculatedQuantity && inlineEditRedesignEnabled && (
          <Box>
            <Box
              display="inline"
              fontSize={100}
              color="neutral.500"
              width="fit-content"
              marginTop={100}
            >
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          </Box>
        )}
      </>
    </Td>
  );
};
