import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Checkbox,
  Field,
  Loader,
  Modal,
  TextInput,
  Textarea,
} from '@hover/blueprint';
import { isNil } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
  projectManagementProductionList_projectManagementProductionList as ProductionList,
} from 'src/api/types/projectManagementProductionList';
import type { projectOrderDocumentCreate as projectOrderDocumentCreateResp } from 'src/api/types/projectOrderDocumentCreate';
import type { projectOrderDocuments } from 'src/api/types/projectOrderDocuments';
import { messages } from 'src/constants/messages';
import {
  ORDER_DOCUMENT_CREATE,
  PROJECT_ORDER_DOCUMENTS,
} from 'src/features/project/apis/graphql/queries/queries';
import {
  PdfOutput,
  PdfFooter,
} from 'src/features/project/components/PdfGeneration/PdfOutput';
import { getSelectedListItemIds } from 'src/features/project/redux/selectors/projectSelectors';
import { generateCsv } from 'src/features/project/util/utils';
import {
  useToastEhi,
  ToastStatusEnum,
  useQueryEhi,
  useTracking,
} from 'src/hooks';
import { downloadCsv, downloadPdf } from 'src/lib/download';
import { mobileDownloadPdf } from 'src/redux/actions';
import { getUserProfile, getTradeTypesSorted } from 'src/redux/selectors';
import { EventNames } from 'src/types';
import { byLineItemTypeTradeTypeSortOrderUnitCostAndCreatedAtComparator } from 'src/utils/comparators';
import { sentenceCase } from 'src/utils/Formatters';

interface DownloadPdfModalProps {
  isOpen: boolean;
  onCancel: () => void;
  productionList?: ProductionList;
  job?: Job;
  lineItemType: LineItemTypeEnum | null;
}

type DownloadPdfFields = {
  poNumber: string;
  notes: string;
  showItemCostDetails: boolean;
  showItemMeasurements: boolean;
  showTotalCost: boolean;
};

const enum TOAST_IDS {
  ORDER_DOCUMENT_CREATE_TOAST,
  PROJECT_ORDER_DOCUMENTS_TOAST,
}

export const DownloadPdfModal: React.FC<DownloadPdfModalProps> = ({
  isOpen,
  onCancel,
  productionList,
  job,
  lineItemType,
}) => {
  const dispatch = useDispatch();
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { jobId } = useParams();
  const listItemIds = useSelector(getSelectedListItemIds);
  const userProfile = useSelector(getUserProfile);
  const tradeTypeEnumsSorted = useSelector(getTradeTypesSorted).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );

  const [orderDocumentId, setOrderDocumentId] = useState<string | null>(null);

  const onCompleted = ({
    projectManagementOrderDocumentCreate,
  }: projectOrderDocumentCreateResp) => {
    if (projectManagementOrderDocumentCreate?.orderDocument?.id)
      setOrderDocumentId(
        projectManagementOrderDocumentCreate?.orderDocument?.id,
      );
  };

  const [orderDocumentCreate, { loading: orderDocumentCreating }] = useMutation(
    ORDER_DOCUMENT_CREATE,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.ORDER_DOCUMENT_CREATE_TOAST,
          description:
            messages.projectScope.errors.query.productionList
              .projectManagementOrderDocumentCreate,
          status: ToastStatusEnum.ERROR,
        });
      },
      onCompleted,
    },
  );

  const {
    data: orderDocument,
    stopPolling,
    loading: orderDocumentLoading,
  } = useQueryEhi<projectOrderDocuments>(PROJECT_ORDER_DOCUMENTS, {
    onError: () => {
      stopPolling();
      toast({
        id: TOAST_IDS.PROJECT_ORDER_DOCUMENTS_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .projectOrderDocuments,
        status: ToastStatusEnum.ERROR,
      });
    },
    variables: {
      productionListId: productionList?.id,
      orderDocumentIds: [orderDocumentId],
    },
    pollInterval: 1500,
    skip: !orderDocumentId,
  });

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      poNumber: '',
      notes: '',
      showItemCostDetails: true,
      showItemMeasurements: true,
      showTotalCost: true,
    },
  });

  const onClose = () => {
    setOrderDocumentId(null);
    setValue('notes', '');
    setValue('poNumber', '');
    setValue('showItemCostDetails', true);
    setValue('showItemMeasurements', true);
    setValue('showTotalCost', true);
    onCancel();
  };

  const pdf =
    orderDocument?.projectManagementOrderDocuments &&
    orderDocument?.projectManagementOrderDocuments?.length > 0
      ? orderDocument.projectManagementOrderDocuments[0]
      : null;

  if (pdf && pdf.stateDeprecated === 'complete') {
    stopPolling();
    const name =
      orderDocument?.projectManagementOrderDocuments[0].pdf?.filename ?? '';
    const url =
      orderDocument?.projectManagementOrderDocuments[0].pdf?.url ?? '';
    dispatch(
      mobileDownloadPdf.request({
        url,
        name,
      }),
    );
    downloadPdf({ url, name });

    toast({
      id: 'pdf-downloaded-success-toast',
      description: 'PDF downloaded successfully',
      status: ToastStatusEnum.SUCCESS,
    });
    onClose();
  }

  if (pdf && pdf.stateDeprecated === 'failed') {
    // states are defined https://github.com/hoverinc/ehi-api/blob/master/app/models/project_management/order_document.rb#L18 until backend makes this an enum
    stopPolling();
    toast({
      id: TOAST_IDS.PROJECT_ORDER_DOCUMENTS_TOAST,
      description:
        messages.projectScope.errors.query.productionList.projectOrderDocuments,
      status: ToastStatusEnum.ERROR,
    });
  }

  const getListItemsForDownload = () => {
    const sortedListItems = [...(productionList?.listItems ?? [])].sort(
      byLineItemTypeTradeTypeSortOrderUnitCostAndCreatedAtComparator(
        tradeTypeEnumsSorted,
      ),
    );

    return sortedListItems?.filter(
      (listItem) =>
        !isNil(listItemIds) &&
        !isNil(lineItemType) &&
        !isNil(listItemIds[lineItemType]) &&
        Object.values(listItemIds[lineItemType])
          .flat()
          .includes(listItem.id.toString()),
    );
  };

  const onSubmit = (data: DownloadPdfFields) => {
    // Segment tracking.
    typewriter.buttonPressed({
      button_text: 'Save PDF',
      button_location: 'Download-material-items-modal',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });

    const listItems = getListItemsForDownload();

    const inputHtml = renderToStaticMarkup(
      React.createElement(PdfOutput, {
        title: !isNil(lineItemType)
          ? `${sentenceCase(lineItemType)} order`
          : 'Order',
        purchaseOrderNumber: data.poNumber,
        job,
        lineItemType,
        userProfile,
        listItems,
        shouldShowTotalCost: data.showTotalCost,
        shouldShowItemMeasurements: data.showItemMeasurements,
        shouldShowCostDetails: data.showItemCostDetails,
        notes: data.notes,
      }),
    );
    const footerHtml = renderToStaticMarkup(
      React.createElement(PdfFooter, {
        jobId: job?.id,
      }),
    );

    orderDocumentCreate({
      variables: {
        listItemIds: listItems?.map((listItem) => listItem.id.toString()),
        purchaseOrderNumber: data.poNumber,
        orderDocumentAttributes: {
          footerHtml,
          inputHtml,
          notes: data.notes,
          productionListId: productionList?.id,
        },
      },
    });
  };

  const handleDownloadCsv = () => {
    typewriter.buttonPressed({
      button_text: 'Download CSV',
      button_location: 'Download-material-items-modal',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
    try {
      const listItems = getListItemsForDownload();

      const csvRows = generateCsv(listItems);
      let fileName = `Material_List_${jobId}_${job?.name}`;
      if (getValues('poNumber'))
        fileName = fileName.concat(`_PO#${getValues('poNumber')}`);
      fileName = fileName.concat('.csv');

      downloadCsv(csvRows.join('\n'), fileName);
      toast({
        id: 'csv-downloaded-success-toast',
        description: 'CSV downloaded successfully',
        status: ToastStatusEnum.SUCCESS,
      });
      onClose();
    } catch (error) {
      toast({
        id: 'csv-downloaded-error-toast',
        description:
          "error downloading csv, please try again or contact Hover's support team",
        status: ToastStatusEnum.ERROR,
      });
    }
  };

  const footer = (
    <Box data-test-id="DownloadPdf">
      {orderDocumentCreating ||
      orderDocumentLoading ||
      (pdf &&
        pdf.stateDeprecated !== 'complete' &&
        pdf.stateDeprecated !== 'error') ? (
        <Loader
          data-test-id="DownloadPdfSpinner"
          size="large"
          display="flex"
          justifyContent="center"
          alignItems="center"
        />
      ) : (
        <Box>
          <Button
            data-test-id="DownloadPdf-cancel"
            color="primary"
            fill="outline"
            marginRight={200}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-test-id="DownloadPdf-download"
            color="primary"
            marginRight={200}
            onClick={handleSubmit(onSubmit)}
          >
            Download PDF
          </Button>
          <Button
            data-test-id="DownloadCSV-download"
            color="primary"
            onClick={handleDownloadCsv}
          >
            Download CSV
          </Button>
        </Box>
      )}
    </Box>
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={`Download ${sentenceCase(lineItemType ?? '')} Items`}
      isClosable
      footer={footer}
    >
      <Field label="PO Number (optional)" name="poNumber">
        <TextInput {...register('poNumber')} />
      </Field>
      <Field label="Order Notes (optional)" name="notes">
        <Textarea resize="vertical" {...register('notes')} rows={5} />
      </Field>
      <Box flexDirection="column">
        <Checkbox {...register('showItemCostDetails')} marginBottom={200}>
          <Body size={500} margin={0} color="neutral700">
            Show item cost details
          </Body>
        </Checkbox>
        <Checkbox {...register('showItemMeasurements')} marginBottom={200}>
          <Body size={500} margin={0} color="neutral700">
            Show item measurements
          </Body>
        </Checkbox>
        <Checkbox {...register('showTotalCost')}>
          <Body size={500} margin={0} color="neutral700">
            Show total cost
          </Body>
        </Checkbox>
      </Box>
    </Modal>
  );
};
