import { Box, Icon } from '@hover/blueprint';
import { hHoverRotate } from '@hover/icons';
import { useLocation } from 'react-router-dom';

export const IconMessaging = () => {
  const location = useLocation();

  const page: 'partial_siding_selection' | 'siding_line_segment_adjustment' =
    location.pathname.split('/').pop();

  return (
    <Box
      as="span"
      margin={300}
      alignSelf="center"
      alignItems="center"
      color="neutral.500"
    >
      <Icon icon={hHoverRotate} size="large" />
      {
        {
          partial_siding_selection:
            'Select facades on interactive 3D model or in check boxes.',
          siding_line_segment_adjustment: 'Zoom in/out to see more details',
        }[page]
      }
    </Box>
  );
};
