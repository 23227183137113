import { useMutation } from '@apollo/client';
import { Box, Heading, IconButton, LoadingOverlay } from '@hover/blueprint';
import { iChevronDown, iChevronUp, iEdit } from '@hover/icons';

import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate_sections_questions as TemplateQuestionType } from 'src/api/types/inspectionConfigChecklistTemplate';
import { SortDirectionType } from 'src/constants';
import { UPDATE_TEMPLATE_QUESTION_ORDER } from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';

import { TemplateQuestion } from './TemplateQuestion';
import { TemplateSectionCreateOrUpdate } from './TemplateSectionCreateOrUpdate';
import { TemplateSectionDeleteIcon } from './TemplateSectionDeleteIcon';
import { TemplateSectionMenuOptions } from './TemplateSectionMenuOptions';

interface TemplateSectionProps {
  name: string;
  isFirst: boolean;
  isLast: boolean;
  templateId: string;
  templateSectionId: string;
  questions: TemplateQuestionType[];
  updateSectionOrder: (sectionId: string, direction: number) => void;
}

export const TemplateSection = ({
  name,
  isFirst,
  isLast,
  templateId,
  templateSectionId,
  questions,
  updateSectionOrder,
}: TemplateSectionProps) => {
  const [updateTemplateSectionOrder, { loading: updatingQuestionOrder }] =
    useMutation(UPDATE_TEMPLATE_QUESTION_ORDER, {
      refetchQueries: [
        {
          query: GET_INSPECTION_CHECKLIST_TEMPLATE,
          variables: { id: templateId },
        },
      ],
    });

  const onSortOrderUp = async () => {
    await updateSectionOrder(templateSectionId, SortDirectionType.asc);
  };

  const onSortOrderDown = async () => {
    await updateSectionOrder(templateSectionId, SortDirectionType.desc);
  };

  const updateQuestionOrder = async (questionId: string, direction: number) => {
    const newQuestions = [...questions];
    const fromIndex = newQuestions.findIndex(
      (question) => question.id === questionId,
    );
    if (
      (fromIndex === 0 && direction === SortDirectionType.asc) ||
      (fromIndex === questions.length - 1 &&
        direction === SortDirectionType.desc)
    )
      return;
    const question = newQuestions.splice(fromIndex, 1)[0];
    const toIndex = direction > 0 ? fromIndex + 1 : fromIndex - 1;
    newQuestions.splice(toIndex, 0, question);
    const checklistTemplateQuestionIds = newQuestions.map((s) => s.id);
    await updateTemplateSectionOrder({
      variables: {
        checklistTemplateSectionId: templateSectionId,
        checklistTemplateQuestionIds,
      },
    });
  };

  return (
    <Box marginBottom={600} flexDirection="column">
      <LoadingOverlay isLoading={updatingQuestionOrder} />
      <Box
        marginBottom={400}
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading size={300}>{name}</Heading>
        <Box alignItems="center">
          <TemplateSectionMenuOptions
            templateId={templateId}
            templateSectionId={templateSectionId}
          />

          <TemplateSectionCreateOrUpdate
            templateId={templateId}
            templateSectionId={templateSectionId}
            name={name}
          >
            <IconButton
              label="editTemplateSection"
              color="neutral"
              icon={iEdit}
              fill="minimal"
              ml={100}
            />
          </TemplateSectionCreateOrUpdate>

          <TemplateSectionDeleteIcon
            templateId={templateId}
            templateSectionId={templateSectionId}
          />

          <Box flexDirection="column">
            <IconButton
              isDisabled={isFirst}
              label="chevronSortButton"
              color="neutral"
              icon={iChevronUp}
              fill="minimal"
              ml={100}
              onClick={onSortOrderUp}
            />
            <IconButton
              isDisabled={isLast}
              label="chevronSortButton"
              color="neutral"
              icon={iChevronDown}
              fill="minimal"
              ml={100}
              onClick={onSortOrderDown}
            />
          </Box>
        </Box>
      </Box>
      {questions.map(
        (
          {
            id,
            questionText,
            questionType,
            supportsImageUploads,
            sortOrder,
            options,
          },
          index,
        ) => (
          <TemplateQuestion
            key={id}
            id={id}
            templateId={templateId}
            templateSectionId={templateSectionId}
            questionText={questionText}
            questionType={questionType}
            supportsImageUploads={supportsImageUploads}
            sortOrder={sortOrder}
            updateQuestionOrder={updateQuestionOrder}
            options={options}
            isFirst={index === 0}
            isLast={index === questions.length - 1}
          />
        ),
      )}
    </Box>
  );
};
