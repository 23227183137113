import styled, { css } from 'styled-components';

export const ListItemName = styled.div`
  ${({ theme }) => theme.mixins.font('bold', 13)};
  text-align: left;
  margin-bottom: 10px;
`;

export const ListItemErrorContainer = styled.div<{ marginBottom?: string }>`
  ${({ marginBottom = '25px' }) => css`
    margin-bottom: ${marginBottom};
  `};
`;

export const ErrorMessageContainer = styled.div`
  ${({ theme }) => css`
    ${theme.mixins.font('medium', 13)}
    color: ${theme.colors.defaultGray};
  `};
  text-align: left;
`;

export const ErrorListContainer = styled.div`
  min-height: 120px;
  max-height: 300px;
  padding: 32px 32px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 0.01em solid ${({ theme }) => theme.colors.lightGray};
  overflow: auto;
  width: 100%;
`;
