import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
  query getOrgTemplates(
    $orgId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    estimationConfigTemplates(
      orgId: $orgId
      after: $after
      before: $before
      first: $first
      last: $last
      includeInactive: true
    ) {
      nodes {
        active
        id
        name
        orgId
        tradeType
        sortOrder
        updatedAt
      }
      edges {
        node {
          active
          id
          name
          orgId
          tradeType
          sortOrder
          updatedAt
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

export const GET_TEMPLATE_ACTIVITY_HISTORY = gql`
  query estimationConfigTemplateVersions($templateId: ID!, $after: String) {
    estimationConfigTemplateVersions(
      templateId: $templateId
      after: $after
      first: 20
    ) {
      edges {
        node {
          id
          createdAt
          updatedByUserName
          attributeChanges {
            attributeName
            oldValue
            newValue
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

export const estimationConfigTemplateFields = gql`
  fragment estimationConfigTemplateFields on EstimationConfigTemplate {
    active
    createdAt
    id
    name
    sortOrder
    tradeType
    templateGroups {
      createdAt
      defaultGroup
      description
      id
      lineItems {
        active
        createdAt
        id
        name
        type
        unitCost
      }
      name
      sortOrder
    }
    updatedAt
  }
`;

export const GET_ESTIMATION_TEMPLATE = gql`
  query estimationConfigTemplate($id: ID!) {
    estimationConfigTemplate(id: $id) {
      ...estimationConfigTemplateFields
    }
  }
  ${estimationConfigTemplateFields}
`;

export const ESTIMATION_CONFIG_TEMPLATE_UPDATE = gql`
  mutation estimationConfigTemplateUpdate(
    $id: ID!
    $templateAttributes: EstimationConfigTemplateUpdateInput!
  ) {
    estimationConfigTemplateUpdate(
      id: $id
      templateAttributes: $templateAttributes
    ) {
      errors {
        attribute
        errors
      }
      template {
        ...estimationConfigTemplateFields
      }
    }
  }
  ${estimationConfigTemplateFields}
`;
