import { StepperInput } from '@hover/blueprint';

type Props = {
  value: number;
  updateAnswer: (value: number) => void;
};

export const StepperQuestion: React.FC<Props> = ({ value, updateAnswer }) => {
  const handleChangeStepper = (stringValue: string, floatValue: number) => {
    updateAnswer(floatValue || 0);
  };

  return (
    <StepperInput
      min={0}
      onChange={handleChangeStepper}
      defaultValue={value}
      size="small"
      width={600}
      data-test-id="stepper-input"
    />
  );
};
