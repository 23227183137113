import { useState } from 'react';

import { Modal, Button, Field, TextInput, Body } from '@hover/blueprint';

import { EstimationConfigLineItemUpdateInput } from 'src/api/graphql-global-types';
import { estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node_lineItems as LineItemType } from 'src/api/types/estimationConfigTemplatesWithLineItems';

interface LineItemEditModalProps {
  lineItem: LineItemType;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: EstimationConfigLineItemUpdateInput) => void;
}

export const LineItemEditModal = ({
  lineItem,
  onSubmit,
  onClose,
  isLoading,
}: LineItemEditModalProps) => {
  const [lineItemName, setLineItemName] = useState(lineItem.name);
  const [netCoverage, setNetCoverage] = useState(
    lineItem.netCoverageLineItemPreset?.value,
  );

  return (
    <Modal
      isOpen
      footer={
        <>
          <Button fill="outline" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            isLoading={isLoading}
            onClick={() =>
              onSubmit(lineItem.id, {
                name: lineItemName,
                unitCost: lineItem.unitCost,
                netCoveragePerSellingUnit: netCoverage
                  ? parseFloat(netCoverage)
                  : undefined,
              })
            }
          >
            Submit
          </Button>
        </>
      }
      header="Edit Line Item"
      onClose={onClose}
      size="medium"
    >
      <Body>
        Note: This will only affect quantity takeoff calculations going forward.
        It will not update the material calculations for takeoffs that have
        already been created.
      </Body>
      <Field label="Line Item Name (required)" name="line_item_name">
        <TextInput
          value={lineItemName}
          onChange={({ target: { value } }) => setLineItemName(value)}
        />
      </Field>
      {lineItem.netCoverageLineItemPreset && (
        <Field
          label={`Coverage (${lineItem.quantityUnits})`}
          name="line_item_coverage"
        >
          <TextInput
            type="number"
            value={netCoverage}
            onChange={({ target: { value } }) => setNetCoverage(value)}
          />
        </Field>
      )}
    </Modal>
  );
};
