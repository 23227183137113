import React from 'react';

import { Icon, Image, Box, Tooltip } from '@hover/blueprint';
import { Flex, Text } from '@hover/blueprint/chakra';
import { hHoverSolid, iCheck } from '@hover/icons';

import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template } from 'src/api/types/configTemplateCollectionsForOrg';

type Props = {
  template: Template;
  isSelected: boolean;
  handleCardClick: () => void;
};

export const TemplateCard = ({
  template,
  isSelected,
  handleCardClick,
}: Props) => {
  const templateToTooltipFragment = ({ name, description }: Template) => (
    <>
      <Box>{name}</Box>
      <Box>{description}</Box>
    </>
  );

  return (
    <Tooltip label={templateToTooltipFragment(template)}>
      <Flex
        data-test-id="MaterialListTemplateCard"
        key={`${template.id}-card`}
        onClick={handleCardClick}
        minH={{ base: '44px', tablet: '90px' }}
        flexBasis={{
          base: '100%',
          tablet:
            'calc(25% - 8px)' /* 25% width for each item minus the 8px gap */,
        }}
        borderRadius="8px"
        flexDirection={{ base: 'row', tablet: 'column' }}
        justifyContent="start"
        alignItems="center"
        position="relative"
        boxSizing="border-box"
        padding={{ base: 0, tablet: 200 }}
        border={isSelected ? '2px solid' : '1px solid'}
        borderColor={isSelected ? 'primary.600' : 'neutral.200'}
        backgroundColor={isSelected ? 'primary100' : 'transparent'}
      >
        {isSelected && (
          <Icon
            icon={iCheck}
            color="primary.600"
            size="small"
            position="absolute"
            top={{ base: '8px', tablet: '4px' }}
            left={{ base: 'none', tablet: '4px' }}
            right={{ base: '4px', tablet: 'none' }}
          />
        )}
        <Flex
          flex={{ tablet: '0 0 40px' }}
          marginTop={{ tablet: 200 }}
          margin={{ base: '10px', tablet: '0 auto' }}
          width={{ base: '24px', tablet: 600 }}
          height={{ base: '24px', tablet: 400 }}
          justifyContent="center"
          alignItems="center"
        >
          {template.templateImage?.image?.redirectUrl ? (
            <Image
              maxW={{ base: '24px;', tablet: 600 }}
              maxH={{ base: '24px', tablet: 400 }}
              padding={0}
              src={template.templateImage.image.redirectUrl}
              alt={template.templateImage.image.filename}
            />
          ) : (
            <Icon
              icon={hHoverSolid}
              color="primary.600"
              size="large"
              margin={0}
              height={{ base: '24px', tablet: 400 }}
            />
          )}
        </Flex>
        <Text
          fontSize={200}
          fontWeight={600}
          noOfLines={{ base: 0, tablet: 2 }}
          marginBottom={0}
          marginTop={0}
          maxWidth="80%"
          data-test-id="template-card-title"
        >
          {template.name}
        </Text>
      </Flex>
    </Tooltip>
  );
};
