import styled, { css } from 'styled-components';

import { colorShader } from 'src/lib/colorShader';

const BUTTON_MIN_WIDTH = 120;

/**
 * A fabulous HOVER button
 *
 * @param {Object} props
 * @param {boolean=} props.noMinWidth - override default button min width
 * @param {boolean=} props.inverted - invert color scheme
 */

interface Props {
  noMinWidth?: boolean;
  inverted?: boolean;
  secondary?: boolean;
  color?: string;
  borderColor?: string;
}

const Button = styled.button<Props>`
  border-radius: 4px !important;
  margin: 0 !important;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  outline: none !important;
  ${({ noMinWidth }) =>
    !noMinWidth &&
    css`
      min-width: ${BUTTON_MIN_WIDTH}px;
    `}
  /* 9/16 padding to account for border */
  padding: ${({ theme }) => theme.utils.pxToRem(9)}rem ${({ theme }) =>
    theme.utils.pxToRem(16)}rem !important;
  font-size: ${({ theme }) => theme.utils.pxToRem(12)}rem;
  ${({ theme }) => theme.mixins.fontWeights.bookImportant}
  ${({
    theme,
    color = 'defaultActionBlue',
    inverted,
    secondary,
    borderColor,
  }) => {
    const colorOfBorder = borderColor
      ? theme.colors[borderColor]
      : theme.colors[color];
    if (inverted) {
      return css`
        background-color: ${theme.colors.neutral000} !important;
        color: ${theme.colors[color]} !important;
        border: 1px solid ${colorOfBorder} !important;
        &:focus {
          background-color: ${theme.colors.neutral000} !important;
          color: ${theme.colors[color]} !important;
          border: 1px solid ${colorOfBorder} !important;
        }
        &:hover {
          background-color: ${colorOfBorder} !important;
          color: ${theme.colors.neutral000} !important;
        }
        &:disabled {
          color: ${theme.colors.neutral000} !important;
        }
      `;
    }
    if (secondary) {
      // white background
      return css`
        background-color: ${theme.colors.neutral000};
        border: 1px solid ${theme.colors.licorice};
        color: ${theme.colors.licorice};

        &:hover {
          background-color: ${theme.colors.alizarinCrimson};
          border: 1px solid ${theme.colors.alizarinCrimson};
          color: ${theme.colors.neutral000};
        }

        &:focus {
          background-color: ${theme.colors.neutral000};
          border: 1px solid ${theme.colors.licorice};
          color: ${theme.colors.licorice};
        }
      `;
    }
    const backgroundColor = theme.colors[color];
    let onHoverColor = colorShader(0.12, theme.colors[color]);
    let onPressColor = colorShader(-0.24, theme.colors[color]);
    if (borderColor) {
      onHoverColor = colorShader(0.12, colorOfBorder);
      onPressColor = colorShader(-0.24, colorOfBorder);
      return css`
        color: ${theme.colors.neutral000};
        background-color: ${backgroundColor};
        border: 1px solid ${colorOfBorder};
        &:focus {
          color: ${theme.colors.neutral000};
          background-color: ${backgroundColor};
          border: 1px solid ${colorOfBorder};
        }
        &:hover {
          background-color: ${onHoverColor};
          border: 1px solid ${onHoverColor};
        }
        &:active {
          background-color: ${onPressColor};
          border: 1px solid ${onPressColor};
        }
      `;
    }
    return css`
      color: ${theme.colors.neutral000};
      background-color: ${backgroundColor};
      border: 1px solid ${backgroundColor};
      &:focus {
        ${
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ({ theme }) => theme.mixins.focusBorder
        };
        color: ${theme.colors.neutral000};
        background-color: ${backgroundColor};
        border: 1px solid ${backgroundColor};
      }
      &:hover {
        background-color: ${onHoverColor};
        border: 1px solid ${onHoverColor};
      }
      &:active {
        background-color: ${onPressColor};
        border: 1px solid ${onPressColor};
      }
    `;
  }}
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledGray} !important;
    border: 1px solid ${({ theme }) => theme.colors.disabledGray} !important;
    cursor: not-allowed !important;
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Button;
