import { Box, Link, LinkProps, BoxProps } from '@hover/blueprint';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export type LinkOrComponentProps = (LinkProps & RouterLinkProps) & {
  disabled?: boolean;
  componentAs?: BoxProps['as'];
};

export const LinkOrComponent: React.FC<LinkOrComponentProps> = ({
  disabled,
  children,
  as: linkAs = RouterLink,
  componentAs = Box,
  ...props
}) => {
  if (disabled) {
    return (
      <Box as={componentAs} {...(props as BoxProps)}>
        {children}
      </Box>
    );
  }
  return (
    <Link as={linkAs} {...(props as RouterLinkProps)}>
      {children}
    </Link>
  );
};
