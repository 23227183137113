import { ReactNode, PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { withTypewriter } from 'src/components/WithTypewriter';
import { formatUnitCost } from 'src/features/projectManagement/utils/ProductionListUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { RootAction, RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import * as EstimatorProductionActions from '../../../redux/actions';
import { getJobDetails } from '../../../redux/selectors/estimatorProductionSelectors';
import { Variation, ListItemType } from '../../../types';
import {
  getVariationByName,
  getVariationSku,
  getListItemDefaultColor,
} from '../../../utils/ColorInputUtils';
import { isValidLength } from '../../../utils/InputValidator';
import { DumbInput } from '../Common/DumbInput';
import CustomInputValueIndicator from './CustomInputValueIndicator';
import { Dropdown } from './styled';

const MAX_WIDTH = '175px';
const CUSTOM_COLOR = 'Custom Color';

const mapStateToProps = (state: RootState) => ({
  jobDetails: getJobDetails(state),
  commonProps: getUserTrackingProps(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateListItem: EstimatorProductionActions.updateListItem,
    },
    dispatch,
  );

interface State {
  isCustomColor: boolean;
  color: string | null;
}

type ColorInputProps = {
  listItem: ListItemType & { isColorUnavailable?: boolean };
  typewriter: any;
};

type Props = ColorInputProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ColorInputComp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCustomColor: false,
      color: null,
    };
  }

  componentDidMount() {
    const { listItem } = this.props;
    this.setComponentStateColor(listItem);
  }

  @autobind
  componentDidUpdate({ listItem }) {
    const {
      listItem: { product },
    } = this.props;
    const newVariations = listItem?.product?.variations ?? [];
    const oldVariations = product?.variations ?? [];
    if (oldVariations.length === newVariations.length) return;
    this.setComponentStateColor(listItem);
  }

  @autobind
  private setComponentStateColor(listItem: ListItemType) {
    const { color: currentStateColor } = this.state;
    const defaultColor = getListItemDefaultColor(listItem);
    const formattedUnitCost = formatUnitCost(listItem?.unitCost);

    const shouldUpdate =
      !!defaultColor && // update if default color/variation exists
      !currentStateColor && // update on componentDidMount, never on componentDidUpdate or it will loop infinitely
      (defaultColor !== listItem?.color || // update if listItem.color != defaultColor
        !listItem.sku || // or if sku is null
        !parseInt(formattedUnitCost, 10)); // or if unit cost is 0

    this.setState(
      {
        isCustomColor: listItem.userSetCustomColor,
        color: defaultColor,
      },
      () => {
        if (shouldUpdate) {
          this.updateListItem(); // update listItem with default color/variation
        }
      },
    );
  }

  @autobind
  private handleDropdownSelection(event: React.ChangeEvent<HTMLSelectElement>) {
    const selection = event.target.value;
    const { color } = this.state;
    const isCustomColor = selection === CUSTOM_COLOR;
    this.setState(
      {
        isCustomColor,
        color: isCustomColor ? color : selection,
      },
      () => this.updateListItem(),
    );
  }

  @autobind
  private updateListItem(): void {
    const {
      updateListItem,
      listItem,
      listItem: { id },
      jobDetails,
      commonProps,
      typewriter,
    } = this.props;

    const { color, isCustomColor } = this.state;
    if (isCustomColor) {
      typewriter.textInput({
        input_value: color ?? '',
        input_label: 'description/color/variation',
        ...commonProps,
        ...jobProps(jobDetails),
      });
      return;
    }
    const variation = getVariationByName({ name: color, listItem });
    const sku = getVariationSku({ variation, listItem });
    updateListItem(id, {
      color,
      externalVariationId: variation?.id ?? null,
      sku,
      skuChangedByUser: false,
      userSetCustomColor: false,
      userSetCustomSku: false,
      userSetCustomUnitCost: false,
    });
  }

  @autobind
  private handleChange({
    target: { value: color },
  }: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ color });
  }

  @autobind
  private handleAutoSaveOnBlur(): void {
    const {
      updateListItem,
      listItem: { id },
    } = this.props;
    const { color } = this.state;

    updateListItem(id, {
      color,
      externalVariationId: null,
      sku: null,
      skuChangedByUser: false,
      userSetCustomColor: true,
    });
  }

  private getPlaceholder(listItem: ListItemType): string {
    // For missing/unavailable color case.
    return listItem.isColorUnavailable ? `${listItem.color} (unavailable)` : '';
  }

  private getDropdownValue(
    value: string | null,
    isCustomColor: boolean,
    listItem: ListItemType,
  ): string | undefined {
    // For missing/unavailable color case.
    if (listItem.isColorUnavailable) {
      return undefined;
    }

    return isCustomColor ? CUSTOM_COLOR : value;
  }

  public render(): ReactNode {
    const { isCustomColor, color } = this.state;
    const value = color ?? '';
    const {
      listItem,
      listItem: { product },
    } = this.props;
    const dropdownValue: string | undefined = this.getDropdownValue(
      value,
      isCustomColor,
      listItem,
    );
    const variations: Variation[] = product?.variations ?? [];
    const colorNames = variations
      .map(({ name }) => ({
        value: name,
      }))
      .concat({ value: CUSTOM_COLOR });
    const placeholder = this.getPlaceholder(listItem);
    return (
      <>
        <Dropdown
          data-test-id="colorsAndSKUsDropdown"
          options={colorNames}
          onChange={this.handleDropdownSelection}
          value={dropdownValue}
          placeholder={placeholder}
          error={listItem.isColorUnavailable}
          size="tiny"
        />
        {isCustomColor && (
          <CustomInputValueIndicator
            isCustomized={isCustomColor}
            parent="ColorInput"
            margin="10px 0 0"
          >
            <DumbInput
              data-test-id="colorsAndSKUsCustomInput"
              placeholder="Add custom color…"
              validators={[isValidLength(255)]}
              value={value}
              onChange={this.handleChange}
              width={MAX_WIDTH}
              handleBlur={this.handleAutoSaveOnBlur}
              margin="0"
            />
          </CustomInputValueIndicator>
        )}
      </>
    );
  }
}

export const ColorInput = connect(
  undefined,
  mapDispatchToProps,
)(withTypewriter(ColorInputComp));
