import { createAction } from 'typesafe-actions';

import {
  ToggleOrderPayload,
  OrderDetailsFormType,
  VendorForOrderPayload,
  BillingFormValues,
} from '../../types';

export const TOGGLE_ORDER_MODAL = 'ESTIMATOR_PRODUCTION/TOGGLE_ORDER_MODAL';
export const toggleOrderModal = createAction(
  TOGGLE_ORDER_MODAL,
  (payload: ToggleOrderPayload) => payload,
)();

export const SET_VENDOR_FOR_ORDER = 'ESTIMATOR_PRODUCTION/SET_VENDOR_FOR_ORDER';
export const setVendorForOrder = createAction(
  SET_VENDOR_FOR_ORDER,
  (payload: VendorForOrderPayload) => payload,
)();

export const UPDATE_ORDER_DETAILS_FORM =
  'ESTIMATOR_PRODUCTION/UPDATE_ORDER_DETAILS_FORM';
export const updateOrderDetailsForm = createAction(
  UPDATE_ORDER_DETAILS_FORM,
  (payload: OrderDetailsFormType) => payload,
)();

export const updateBillingForm = createAction(
  'PMP/UPDATE_BILLING_FORM',
  (payload: BillingFormValues) => payload,
)();

export const DELIVERY_METHOD_UPDATED =
  'ESTIMATOR_PRODUCTION/DELIVERY_METHOD_UPDATED';
export const deliveryMethodUpdated = createAction(
  DELIVERY_METHOD_UPDATED,
  () => null,
)();

export const clearOrderDetailsForm = createAction(
  'ESTIMATOR_PRODUCTION/CLEAR_ORDER_DETAILS_FORM',
  () => null,
)();
