import { forwardRef } from 'react';

import { Box, Select } from '@hover/blueprint';
import type { SelectProps } from '@hover/blueprint';
import { useEditableState } from '@hover/blueprint/chakra';
import { get, isNil } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import type { AddEditFormData } from 'src/features/project/components/ProjectScope/ProjectScope';

import { useProjectScopeTracker } from '../../../hooks/useProjectScopeTracker';

const CUSTOM_VARIANT = 'Custom variant';
export const CUSTOM_VARIANT_COLOR = {
  name: CUSTOM_VARIANT,
  id: CUSTOM_VARIANT,
};

type EditableVariationSelectionProps = {
  jobId: number;
  listItem: ListItem;
  units: {
    [s: string]: string;
  }[];
} & SelectProps;

/**
 * Custom component used inside a Chakra `Editable` component.
 * Knows when to hide/show itself based on the EditableState.
 */
export const EditableUnitsSelection: React.FC<EditableVariationSelectionProps> =
  forwardRef<HTMLDivElement, EditableVariationSelectionProps>(
    ({ listItem: listeItem, units, jobId, ...props }, ref) => {
      /* Form configuration */
      const {
        control,
        formState: { errors },
      } = useFormContext<AddEditFormData>();

      const editableState = useEditableState();
      const { trackInlineEditingInputPressed } = useProjectScopeTracker({
        jobId,
      });

      return (
        <Box
          as="span"
          ref={ref}
          display={editableState.isEditing ? 'flex' : 'none'}
          flexDirection="column"
        >
          <Controller
            control={control}
            name="quantityUnits"
            render={({ field: { onChange, value: unitsIdValue } }) => (
              <Select
                {...props}
                size="tiny"
                minWidth="56px"
                // size="small"
                fontSize="inherit"
                isInvalid={!!get(errors, 'quantityUnits')}
                data-test-id="AddMaterial-uom"
                value={unitsIdValue as string}
                onChange={(e) => {
                  onChange(e);
                  trackInlineEditingInputPressed('Quantity');
                  e.currentTarget.blur();
                  if (!!props.onBlur) {
                    props?.onBlur(e as React.FocusEvent<HTMLSelectElement>);
                  }
                }}
                ref={(input) => {
                  input?.focus();
                }}
              >
                {isNil(listeItem.quantityUnits) ||
                listeItem.quantityUnits.length === 0 ? (
                  <option key={null} value="">
                    Select units
                  </option>
                ) : null}
                {units.map((unit) => {
                  return (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </Select>
            )}
            rules={
              listeItem.type === LineItemTypeEnum.MATERIAL
                ? {
                    required: 'Units are required',
                  }
                : {}
            }
          />
        </Box>
      );
    },
  );
