import { useRef } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Input } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { SalesPresentationPresentationCreateInput } from 'src/api/graphql-global-types';
import { salesPresentationPresentationCreate } from 'src/api/types/salesPresentationPresentationCreate';
import { salesPresentationPresentationUpload_salesPresentationFileUploadCreate as SalesPresentationFileUploadCreateResponse } from 'src/api/types/salesPresentationPresentationUpload';
import {
  CREATE_SALES_PRESENTATION,
  GET_SALES_PRESENTATIONS,
  UPLOAD_SALES_PRESENTATION,
} from 'src/features/settings/api/queries/salesPresentation';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { getUserOrgId } from 'src/redux/selectors';

interface InputFileUploaderProps {
  setFileName?: (fileName: string) => void;
  setLoading: (loading: boolean) => void;
  track?: () => void;
}

export const InputFileUploader: React.FC<InputFileUploaderProps> = ({
  setFileName,
  setLoading,
  track,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (track) track();
    inputRef?.current?.click();
  };
  const orgId = useSelector(getUserOrgId);

  const { uploadFile } =
    useFileUpload<SalesPresentationFileUploadCreateResponse>(
      UPLOAD_SALES_PRESENTATION,
      'salesPresentationFileUploadCreate',
    );

  const [createSalesPresentationMutation] =
    useMutation<salesPresentationPresentationCreate>(
      CREATE_SALES_PRESENTATION,
      {
        refetchQueries: [
          { query: GET_SALES_PRESENTATIONS, variables: { orgId } },
        ],
        onCompleted: () => {
          setLoading(false);
        },
      },
    );

  const toast = useToastEhi();

  const salesPresentationCreateErrorToast = () => {
    toast({
      id: 'sales-presentation-create-error-toast',
      description: 'There was a problem creating the sales presentation',
      status: ToastStatusEnum.ERROR,
    });
  };

  const salesPresentationCreateSuccessToast = () => {
    toast({
      id: 'sales-presentation-create-success-toast',
      description: 'Your sales presentation was successfully uploaded',
      status: ToastStatusEnum.SUCCESS,
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return;
    }

    const fileName = file.name;

    if (setFileName) {
      setFileName(fileName);
    }

    setLoading(true);
    const signedBlobId = await uploadFile(file);

    createSalesPresentation(signedBlobId, fileName);

    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const createSalesPresentation = async (
    signedBlobId: string,
    filename: string,
  ) => {
    try {
      const presentationAttributes: SalesPresentationPresentationCreateInput = {
        name: filename,
        orgId,
        pdf: signedBlobId,
      };

      const { data, errors: highLevelErrors } =
        await createSalesPresentationMutation({
          variables: {
            presentationAttributes,
          },
        });

      if (highLevelErrors) {
        throw new Error(highLevelErrors[0].message);
      }

      const salesPresentationPresentationCreateResponse =
        data?.salesPresentationPresentationCreate;

      if (!salesPresentationPresentationCreateResponse) {
        throw new Error('No response from createSalesPresentationMutation');
      }

      const { errors, presentation } =
        salesPresentationPresentationCreateResponse;

      if (errors?.length > 0) {
        throw new Error(errors[0].errors[0]);
      }

      if (!presentation) {
        throw new Error('Missing Presentation response');
      }

      salesPresentationCreateSuccessToast();
    } catch (error) {
      setLoading(false);
      salesPresentationCreateErrorToast();
      Sentry.captureException(error);
    }
  };

  return (
    <Box onClick={handleClick}>
      <Input
        type="file"
        display="none"
        ref={inputRef}
        onChange={handleFileChange}
        accept="application/pdf"
      />
      {children}
    </Box>
  );
};
