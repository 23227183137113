import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';
import { getDiscounts } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';

export const estimateGroupReducer = createReducer(initialState)
  .handleAction(
    estimatorActions.receiveEstimateGroup,
    (state, { payload: estimateGroup }) => {
      return {
        ...state,
        estimateGroup: {
          ...state.estimateGroup,
          data: {
            ...estimateGroup,
          },
        },
        discounts: getDiscounts(estimateGroup.estimates),
      };
    },
  )
  .handleAction(
    estimatorActions.toggleHasDismissedMissingVariationsWarning,
    (state, action) => ({
      ...state,
      estimateGroup: {
        ...state.estimateGroup,
        hasDismissedMissingVariationsWarning: action.payload,
      },
    }),
  );
