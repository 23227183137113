import { createAsyncAction } from 'typesafe-actions';

import { CustomLineItem } from 'src/features/exteriorEstimator/types';

export const getCustomLineItems = createAsyncAction(
  'EXTERIOR_ESTIMATOR/GET_CUSTOM_LINE_ITEMS_REQUEST',
  'EXTERIOR_ESTIMATOR/GET_CUSTOM_LINE_ITEMS_SUCCESS',
  'EXTERIOR_ESTIMATOR/GET_CUSTOM_LINE_ITEMS_FAILURE',
)<void, { customLineItems: CustomLineItem[] }, Error>();

export const updateCustomLineItems = createAsyncAction(
  'EXTERIOR_ESTIMATOR/UPDATE_CUSTOM_LINE_ITEMS_REQUEST',
  'EXTERIOR_ESTIMATOR/UPDATE_CUSTOM_LINE_ITEMS_SUCCESS',
  'EXTERIOR_ESTIMATOR/UPDATE_CUSTOM_LINE_ITEMS_FAILURE',
)<
  { customLineItems: CustomLineItem[] },
  { customLineItems: CustomLineItem[] },
  Error
>();
