import { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Body,
  Image,
  Icon,
  TileRadio,
  RadioGroup,
  LoadingOverlay,
} from '@hover/blueprint';
import type { RadioProps } from '@hover/blueprint';
import { iAlertCircle, iCheck } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line camelcase
import { estimationEstimate_estimationEstimate_lineItems as LineItem } from 'src/api/types/estimationEstimate';
import { estimationLineItemUpdate as EstimationLineItemUpdate } from 'src/api/types/estimationLineItemUpdate';
import { ESTIMATION_LINE_ITEM_UPDATE } from 'src/features/exteriorEstimator/apis/queries';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  // eslint-disable-next-line camelcase
  lineItem: LineItem;
}

export const Variations: React.FC<Props> = ({
  lineItem,
  lineItem: { product, selectedVariation },
}) => {
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const { estimateId, estimateGroupId } = useParams();

  const orgId = useSelector(getUserOrgId);

  const getVariationName = (name: string | undefined) => name || 'none';
  const [selectedVariationName, setSelectedVariationName] = useState(
    getVariationName(selectedVariation?.name),
  );

  useEffect(() => {
    setSelectedVariationName(getVariationName(selectedVariation?.name));
  }, [selectedVariation?.name]);

  const lineItemUpdateError = () => {
    toast({
      id: 'line-item-update-error-toast',
      description: 'There is a problem with color selection',
      status: ToastStatusEnum.ERROR,
    });
  };

  const [estimationLineItemUpdate, { loading }] = useMutation(
    ESTIMATION_LINE_ITEM_UPDATE,
    {
      onError: lineItemUpdateError,
      onCompleted: (data: EstimationLineItemUpdate) => {
        if (data?.estimationLineItemUpdate?.matchedLineItems) {
          const matchCount =
            data.estimationLineItemUpdate.matchedLineItems.length;

          if (matchCount > 0) {
            toast({
              description: `${matchCount} variations matched`,
            });
          }
        }
      },
    },
  );

  const selectVariation = (value: RadioProps['value'], name?: string) => {
    setSelectedVariationName(getVariationName(name));

    estimationLineItemUpdate({
      variables: {
        id: lineItem.id,
        lineItemAttributes: {
          productCatalogVariationId: value as string,
          productCatalogVariationName: value ? (name as string) : '',
        },
        estimateId: Number(estimateId),
        orgId,
      },
      refetchQueries: ['EstimateGroup', 'estimationEstimate'],
    });

    // for tracking we want to send the display value of none when the value is empty
    typewriter.optionSelected({
      option_type: 'radio',
      backend_id_type: 'estimate_group_id',
      backend_id_value: estimateGroupId,
      selection: value ? (name as string) : 'None',
      page_or_screen_name: EventNames.estimator.colorSelection,
      primary_cta: false,
      options: 'Variations',
      ...commonTrackingProps,
    });
  };

  const variationTitle = () => {
    if (
      !lineItem?.product?.productCatalogCategory ||
      !lineItem?.product?.productCatalogCategory.name
    ) {
      return <Box alignSelf="center">{lineItem?.product?.name}</Box>;
    }

    return (
      <Box flexDirection={{ base: 'column', tablet: 'row' }}>
        <Body as="span" fontWeight="bold" margin={0}>
          {lineItem?.product?.productCatalogCategory.name}:&nbsp;
        </Body>
        <Body as="span" color="neutral.textLight">
          {lineItem?.product?.name}
        </Body>
      </Box>
    );
  };

  const variationStatus = () => {
    return (
      <Box>
        {selectedVariationName === 'none' ? (
          <Box alignItems="center">
            <Body
              as="span"
              color="warning.600"
              visibility={{ base: 'hidden', tablet: 'visible' }}
            >
              Variation not selected
            </Body>
            <Icon color="warning.600" icon={iAlertCircle} flexShrink={0} />
          </Box>
        ) : (
          <Box alignItems="center">
            <Box>Selected</Box>
            <Icon icon={iCheck} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />
      <Box
        flexDirection="column"
        data-test-id="variations-list"
        boxShadow="distance200"
        marginBottom={300}
        padding={500}
      >
        <Box flexDirection="row" justifyContent="space-between">
          {variationTitle()}
          {variationStatus()}
        </Box>
        <RadioGroup value={selectedVariationName}>
          <Box flexDirection="column" flex="1">
            {product?.variations.map((variation) => {
              const { name, id, texture } = variation;
              return (
                <TileRadio
                  key={id}
                  id={id}
                  value={name}
                  isSubtle
                  noOfLines={1}
                  data-test-id="variationRadioCard"
                  data-variation-name={name}
                  onChange={() => selectVariation(id, name)}
                >
                  <Box
                    flexDirection="row"
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <Box>{name}</Box>
                    <Box>
                      {texture?.diffuseMapThumbnail?.redirectUrl && (
                        <Image
                          alt={name}
                          borderColor="neutral600"
                          borderRadius={900}
                          borderWidth={500}
                          height={400}
                          src={texture?.diffuseMapThumbnail?.redirectUrl}
                          title={name}
                          width={400}
                        />
                      )}
                    </Box>
                  </Box>
                </TileRadio>
              );
            })}
            <TileRadio
              data-test-id="radioButtonCardNoneSelection"
              data-variation-name="None"
              value="none"
              isSubtle
              noOfLines={1}
              onChange={() => selectVariation('')}
            >
              None
            </TileRadio>
          </Box>
        </RadioGroup>
      </Box>
    </>
  );
};
