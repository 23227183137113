import { Body, Box, Icon, Panel } from '@hover/blueprint';
import { iAlertTriangle } from '@hover/icons';

import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';

export const MissingVariationsWarning = () => {
  return (
    <Panel
      data-test-id="missingVariationsWarning"
      backgroundColor="warning200"
      padding={0}
    >
      <EstimatorResponsiveWrapper
        padding={0}
        paddingX={{ base: 300, desktop: 500 }}
        paddingY={200}
      >
        <Box alignItems="center">
          <Icon
            icon={iAlertTriangle}
            size="small"
            color="neutral700"
            marginRight={300}
          />
          <Body size={400} margin={0}>
            Estimate is missing colors
          </Body>
        </Box>
      </EstimatorResponsiveWrapper>
    </Panel>
  );
};
