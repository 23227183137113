import { createSelector } from 'reselect';

import { Input } from 'src/features/exteriorEstimator/types';

import {
  getQuestionResponses,
  getMeasurementQuestions,
  getPages,
} from './estimatorSelectors';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/order
import { PARTIAL_ROOF_AREA_FEATURES_MAP } from 'src/features/exteriorEstimator/constants/questionArgumentMappings';

export const getRoofTotalQuestionId = createSelector(
  getMeasurementQuestions,
  (questions) => {
    if (!questions) return null;
    const roofTotalQuestion = questions.find(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (question: any) =>
        question.argument === PARTIAL_ROOF_AREA_FEATURES_MAP.roof_total,
    );
    return roofTotalQuestion ? roofTotalQuestion.id : null;
  },
);

export const getJobTotalRoofArea = createSelector(
  getRoofTotalQuestionId,
  getQuestionResponses,
  (id, questionResponses) => (id ? questionResponses[id] : 0),
);

export const getAllSelectedFacetParams = createSelector(
  getQuestionResponses,
  getPages,
  (questionResponses, pages) => {
    const facetRegex = /([A-Z]+)-(\d)+/;
    const allQuestions = pages.reduce<Input[]>(
      (questions, page) => [...questions, ...(page.questions || [])],
      [],
    );

    return Object.entries(questionResponses)
      .filter(([key, val]) => facetRegex.test(key) && val)
      .map(([key]) => {
        const question = allQuestions.find((q) => q.id === key);
        return {
          identifier: key,
          area: question ? question.area ?? question.combinedArea : null,
        };
      });
  },
);
