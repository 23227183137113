import { TradeTypeEnum } from 'src/api/graphql-global-types';

export const ALL_TRADES = 'ALL_TRADES';
const PRICE = 'PRICE';
const CREATED_AT = 'CREATED_AT';

export const TradeTypes = { ALL_TRADES, ...TradeTypeEnum };

export type TradeTypesType = typeof TradeTypes;

export const LineItemFilters = { PRICE, CREATED_AT };
