import { useEffect } from 'react';

import { Box, Heading, LoadingOverlay } from '@hover/blueprint';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { getSalesPresentations } from 'src/api/types/getSalesPresentations';
import { GET_SALES_PRESENTATIONS } from 'src/features/settings/api/queries/salesPresentation';
import { useQueryEhi, useTracking } from 'src/hooks';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';

import { SalesPresentationFileUpload } from './SalesPresentationFileUpload';
import { SalesPresentationTable } from './SalesPresentationTable';
import { SalesPresentationUploadButton } from './SalesPresentationUploadButton';

export const SalesPresentationListView: React.FC = () => {
  const orgId = useSelector(getUserOrgId);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: 'Sales Presentation List Empty',
      feature: 'Sales Presentation',
      ...commonTrackingProps,
    });
  });

  const { data, loading } = useQueryEhi<getSalesPresentations>(
    GET_SALES_PRESENTATIONS,
    {
      variables: {
        orgId,
      },
    },
  );

  const salesPresentationLoading = loading || isNil(data);
  const hasFileUploaded =
    data && data.salesPresentationPresentations.length !== 0;
  const salesPresentationBody =
    hasFileUploaded && data?.salesPresentationPresentations ? (
      <SalesPresentationTable
        salesPresentations={data.salesPresentationPresentations}
      />
    ) : (
      <SalesPresentationFileUpload />
    );

  return (
    <Box flexDirection="column">
      <LoadingOverlay
        data-test-id="salesPresentationSpinner"
        isLoading={salesPresentationLoading}
      />
      <Box alignItems="center" justifyContent="space-between">
        <Heading>Sales Presentation</Heading>
        {hasFileUploaded && <SalesPresentationUploadButton />}
      </Box>
      {!salesPresentationLoading && salesPresentationBody}
    </Box>
  );
};
