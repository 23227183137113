import { groupBy, get, isNil } from 'lodash';

import { ProductCatalogOrgVariationNestedInput } from 'src/api/graphql-global-types';
import {
  estimationConfigLineItems_estimationConfigLineItems_nodes as LineItem,
  estimationConfigLineItems_estimationConfigLineItems_nodes_product as LineItemProduct,
} from 'src/api/types/estimationConfigLineItems';
import {
  productCatalogProduct_productCatalogProduct as Product,
  productCatalogProduct_productCatalogProduct_variations as VariationsType,
} from 'src/api/types/productCatalogProduct';
import { EventNames } from 'src/types/actionTypes';

export const groupItemsByProvider = (lineItems: LineItem[]) => {
  const groupedItems = groupBy(lineItems, (lineItem) => {
    // Group lineItem products by their "Provider" name; for
    // products with no provider, group into an "Other" group.
    return get(lineItem, 'product.provider.name', 'Other');
  });

  return groupedItems;
};

export const groupProductsByProvider = (
  products: Product[] | LineItemProduct[],
) => {
  const groupedProducts = groupBy(products, (product) => {
    return get(product, 'provider.name', 'Other');
  });

  return groupedProducts;
};

// TODO: This can be removed if/when the backend API accepts an array of *all* variations to save.
export const constructOrgVariationsAttributes = (
  variants: VariationsType[],
  selected: string[],
) => {
  const inputs = variants.reduce(
    (
      result: ProductCatalogOrgVariationNestedInput[],
      variant: VariationsType,
    ) => {
      return [
        ...result,
        ...(!selected.includes(variant.id) && !isNil(variant.orgVariation?.id)
          ? [
              {
                id: variant.orgVariation?.id,
                destroy: true,
                variationId: variant.id,
              },
            ]
          : []),
        ...(selected.includes(variant.id) && isNil(variant.orgVariation?.id)
          ? [{ variationId: variant.id }]
          : []),
      ];
    },
    [],
  );

  return inputs;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const eventNameCurrentPage = (location: any) => {
  if (location.pathname.includes('edit'))
    return EventNames.settings.productVariantEdit.page;
  if (location.pathname.includes('products'))
    return EventNames.settings.productVariantShow.page;
  if (location.pathname.includes('proposal'))
    return EventNames.settings.proposal.page;
  if (location.pathname.includes('line_items'))
    return EventNames.settings.lineItems.page;
  if (location.pathname.includes('template'))
    return EventNames.settings.templates.page;
  return EventNames.settings.materialsList.page;
};
