import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import {
  EstimationConfigLineItemUpdateInput,
  EstimationConfigTemplateUpdateInput,
} from 'src/api/graphql-global-types';
import {
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node as Template,
  estimationConfigTemplatesWithLineItems_estimationConfigTemplates_edges_node_lineItems as LineItemType,
} from 'src/api/types/estimationConfigTemplatesWithLineItems';
import {
  GET_TEMPLATES_WITH_LINE_ITEMS,
  UPDATE_CONFIG_LINE_ITEM,
  UPDATE_CONFIG_TEMPLATE,
  COPY_ESTIMATION_CONFIG_TEMPLATE,
} from 'src/features/settings/takeoffs/apis/queries/templates';
import { LineItemEditModal } from 'src/features/settings/takeoffs/components/LineItems/LineItemEditModal';
import { TemplatesContent } from 'src/features/settings/takeoffs/components/Templates/TemplatesContent';
import { TemplatesWelcome } from 'src/features/settings/takeoffs/components/Templates/TemplatesWelcome';
import { useQueryEhi } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { FeatureFlag, QUANTITY_TAKEOFF } from 'src/lib/FeatureFlag';
import { getUserOrgId } from 'src/redux/selectors';

import { getRoofTemplates } from '../../utils/templatesFormatter';
import { DuplicateTemplateModal } from './DuplicateTemplateModal';
import { TemplateEditModal } from './TemplateEditModal';

export const TemplatesContainer: React.FC = () => {
  const { searchParams } = useSearchParams();
  const jobId = searchParams.get('jobId');
  const orgId = useSelector(getUserOrgId);
  const [isDuplicatingTemplate, setIsDuplicatingTemplate] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [currentLineItem, setCurrentLineItem] = useState<LineItemType>();
  const { data, loading, fetchMore } = useQueryEhi(
    GET_TEMPLATES_WITH_LINE_ITEMS,
    {
      variables: { orgId },
    },
  );

  const [updateConfigLineItem, { loading: updateListItemLoading }] =
    useMutation(UPDATE_CONFIG_LINE_ITEM);
  const [updateConfigTemplate, { loading: updateConfigTemplateLoading }] =
    useMutation(UPDATE_CONFIG_TEMPLATE);
  const [copyEstimationConfigTemplate, { loading: duplicateTemplateLoading }] =
    useMutation(COPY_ESTIMATION_CONFIG_TEMPLATE);

  const hasNextPage = data?.estimationConfigTemplates?.pageInfo.hasNextPage;
  const endCursor = data?.estimationConfigTemplates?.pageInfo.endCursor;

  if (hasNextPage) {
    fetchMore({
      variables: {
        orgId,
        after: endCursor,
      },
    });
  }

  const updateLineItem = async (
    id: number,
    params: EstimationConfigLineItemUpdateInput,
  ) => {
    await updateConfigLineItem({
      variables: { id, lineItemAttributes: params },
    });
    setCurrentLineItem(undefined);
  };

  const updateTemplate = async (
    id: number,
    params: EstimationConfigTemplateUpdateInput,
  ) => {
    updateConfigTemplate({
      variables: {
        id,
        templateAttributes: params,
      },
      refetchQueries: [
        {
          query: GET_TEMPLATES_WITH_LINE_ITEMS,
          variables: { orgId },
        },
      ],
    });
    setCurrentTemplate(undefined);
  };

  const copyTemplate = (templateId: number) => {
    if (duplicateTemplateLoading) return;

    copyEstimationConfigTemplate({
      variables: { templateId },
      refetchQueries: [
        {
          query: GET_TEMPLATES_WITH_LINE_ITEMS,
          variables: { orgId },
        },
      ],
    });
  };

  return (
    <FeatureFlag flagName={QUANTITY_TAKEOFF}>
      <Box data-test-id="templatesList" flexDirection="column">
        <TemplatesWelcome />
        <TemplatesContent
          loading={loading}
          data={data}
          jobId={jobId}
          onUpdateLineItem={(lineItem) => setCurrentLineItem(lineItem)}
          onUpdateTemplate={(template) => setCurrentTemplate(template)}
          onClickDuplicateTemplate={() => setIsDuplicatingTemplate(true)}
        />
        {currentLineItem && (
          <LineItemEditModal
            lineItem={currentLineItem}
            isLoading={updateListItemLoading}
            onSubmit={updateLineItem}
            onClose={() => setCurrentLineItem(undefined)}
          />
        )}
        {currentTemplate && (
          <TemplateEditModal
            template={currentTemplate}
            isLoading={updateConfigTemplateLoading}
            onSubmit={updateTemplate}
            onClose={() => setCurrentTemplate(undefined)}
          />
        )}
        {isDuplicatingTemplate && data?.estimationConfigTemplates?.edges && (
          <DuplicateTemplateModal
            onSubmit={(selectedTemplateId) => copyTemplate(selectedTemplateId)}
            onClose={() => setIsDuplicatingTemplate(false)}
            isLoading={duplicateTemplateLoading}
            templateOptions={getRoofTemplates(
              data.estimationConfigTemplates.edges,
            ).map(({ id, name }) => ({
              id,
              name,
            }))}
          />
        )}
      </Box>
    </FeatureFlag>
  );
};
