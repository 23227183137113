import { Body, Toggle, Label } from '@hover/blueprint';

import { ChangeEventHandler } from 'src/types';

interface Props {
  onChange?: ChangeEventHandler;
  value?: boolean;
  name: string;
  labelText: string;
  disabled?: boolean;
  'data-test-id'?: string;
  size?: number;
  small?: boolean;
  readOnly?: boolean;
}

export const ToggleField: React.FC<Props> = ({
  onChange,
  value,
  name,
  labelText,
  disabled,
  size,
  small,
  readOnly,
  ...rest
}) => {
  return (
    <Label justifyContent="space-between" alignItems="center" box>
      <Body
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        size={(size as any) ?? 400}
        color={disabled ? 'neutral300' : 'inherit'}
        marginRight={50}
        marginBottom={300}
        transitionDuration="150ms"
        transitionTimingFunction="ease-in-out"
        transitionProperty="color"
      >
        {labelText}
      </Body>
      <Toggle
        value={value}
        onChange={onChange}
        data-test-id={rest['data-test-id']}
        name={name}
        disabled={disabled}
        size={small ? 'small' : 'default'}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        readOnly={readOnly} // type is missing in blueprint but it works
      />
    </Label>
  );
};
