import { Box, Heading } from '@hover/blueprint';

import { InspectionTable } from 'src/features/settings/components/ChecklistTemplates/InspectionTable';
import { TemplateCreateButton } from 'src/features/settings/components/ChecklistTemplates/TemplateCreateButton';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

export const InspectionTemplates = () => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      ...commonTrackingProps,
    });
  });
  return (
    <Box flexDirection="column">
      <Box alignItems="center" justifyContent="space-between">
        <Heading>Checklist templates</Heading>
        <TemplateCreateButton />
      </Box>
      <InspectionTable />
    </Box>
  );
};
