import { errorFields } from 'src/api/types/errorFields';
import { projectManagementOrderDocuments_projectManagementOrderDocuments as OrderDocument } from 'src/api/types/projectManagementOrderDocuments';

import { ListItemTypeWithError, ListItemType } from '../types';

export const getInvalidListItemIdsFromOrder = (
  listItems: ListItemTypeWithError[],
) => {
  return listItems.filter(
    (listItem) => listItem.errors && listItem.errors.length > 0,
  );
};

export const getValidListItemIds = (
  allListItems: ListItemType[],
  invalidListItems: ListItemType[],
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const invalidListItemIds: any = new Set(
    invalidListItems.map(({ id }: ListItemType) => id),
  );
  return allListItems
    .filter(({ id }: ListItemType) => !invalidListItemIds.has(id))
    .map(({ id }: ListItemType) => id.toString());
};

export const getOrderDocumentsByState = (
  orderDocuments: OrderDocument[],
  state: string,
) => {
  return orderDocuments.filter(
    (orderDocument) => orderDocument.state === state,
  );
};

export const createErrorMessage = (errors: errorFields[]) => {
  let errorMessage = '';
  errors.forEach((error, i) => {
    errorMessage = errorMessage.concat(
      `${error.attribute} ${error.errors.join(' and ')}`,
    );
    if (i !== errors.length - 1) errorMessage = errorMessage.concat(', ');
  });
  return errorMessage;
};
