import { connect } from 'react-redux';
import styled from 'styled-components';

import { SanitizedHTML } from 'src/components';
import {
  getVendorForOrder,
  getOrderOrDistributionOrder,
} from 'src/features/projectManagement/redux/selectors';
import { RootState } from 'src/types/reduxStore';

import OrderDisclaimer from '../OrderDisclaimer';
import { InfoText, Container, Contact } from '../styled';
import Wrapper from '../Wrapper';
import { ItemErrors } from './ItemErrors';
import { OrderErrorMessages } from './OrderErrorMessages';

const TextWrapper = styled.div`
  ${({ theme }) => theme.mixins.font('book', 15)}
  padding: 32px 32px 0;
  margin: 0px auto 40px;
`;

const mapStateToProps = (state: RootState) => ({
  order: getOrderOrDistributionOrder(state),
  vendorForOrder: getVendorForOrder(state),
  errorMessage:
    state.estimatorProductionTools.projectManagementOrderData.errorMessage ||
    '',
});

type Props = ReturnType<typeof mapStateToProps>;

const OrderErrorsComponent: React.FC<Props> = ({
  order,
  vendorForOrder,
  errorMessage,
}) => {
  const orderCheck = order?.orderCheck;
  const distributorDisplayName =
    vendorForOrder?.distributor?.displayName ?? 'supplier';
  const websiteDisplayName =
    vendorForOrder?.distributor?.websiteDisplayName ??
    "your supplier's website";
  return (
    <Wrapper flexDirection="column">
      <Container>
        <OrderErrorMessages orderCheck={orderCheck} />
        <ItemErrors
          orderCheck={orderCheck}
          distributorDisplayName={distributorDisplayName}
        />
        <TextWrapper data-test-id="generic-error">
          <SanitizedHTML html={errorMessage} />
        </TextWrapper>
        <InfoText margin="25px auto 25px">
          Contact{' '}
          <Contact href="https://hover.to/contact/">HOVER support</Contact> or
          your {distributorDisplayName} sales rep for questions
        </InfoText>
      </Container>
      <OrderDisclaimer
        distributorDisplayName={distributorDisplayName}
        websiteDisplayName={websiteDisplayName}
      />
    </Wrapper>
  );
};

export const OrderErrors = connect(mapStateToProps)(OrderErrorsComponent);
