import { TextInput, TextInputProps } from '@hover/blueprint';
import styled from 'styled-components';

export const InputContainer = styled.div<{
  width?: React.CSSProperties['width'];
  maxWidth?: string;
}>`
  display: flex;
  justify-content: flex-end;
  width: ${({ width = 'auto' }) => `${width} !important`};
`;

export const BaseInput = styled.input<{
  isError?: boolean;
  maxWidth?: string;
  textAlign?: React.CSSProperties['textAlign'];
  margin?: React.CSSProperties['margin'];
  width?: React.CSSProperties['width'];
}>`
  background-color: ${({ theme }) => theme.colors.silver} !important;
  border: 1px solid
    ${({ theme, isError }) =>
      isError
        ? theme.colors.borderError
        : theme.colors.lightGrayThree} !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  padding: 0 8px !important;
  height: 24px !important;
  font-family: 'Gotham Book', 'Helvetica Neue', 'Arial', sans-serif !important;
  font-size: 0.75rem !important;
  ${({ theme }) => theme.mixins.font('book', 12)};
  background-color: ${({ disabled, theme }) =>
    disabled
      ? `${theme.colors.silver} !important;`
      : `${theme.colors.neutral000} !important;`};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width = '100%' }) => `${width}`};
  margin: 0 !important;
`;

interface InputProps {
  textAlign?: React.CSSProperties['textAlign'];
  isError?: boolean;
  width?: React.CSSProperties['width'];
}

export const PmpBlueprintInput = styled(TextInput)<TextInputProps & InputProps>`
  font-family: 'Gotham Book', 'Helvetica Neue', 'Arial', sans-serif !important;
  height: 24px !important;
  font-size: 0.75rem !important;
  text-align: ${({ textAlign = 'end' }) => textAlign};
  border-color: ${({ theme, isError, error }) =>
    isError || error ? theme.colors.borderError : theme.colors.lightGrayThree};
`;

export const StyledFormattedInput = styled(PmpBlueprintInput)<InputProps>`
  margin: 0 0 0 auto !important;
  width: ${({ width = '70px' }) => `${width} !important`};
  text-align: ${({ textAlign = 'right' }) => textAlign};
`;

export const StyledTextInput = styled(PmpBlueprintInput)<InputProps>`
  text-align: ${({ textAlign = 'left' }) => textAlign};
`;
