import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { estimationConfigTemplateVersions_estimationConfigTemplateVersions_edges as TemplateVersion } from 'src/api/types/estimationConfigTemplateVersions';
import { getOrgTemplates_estimationConfigTemplates_edges_node as TemplateType } from 'src/api/types/getOrgTemplates';
import { HistoryModal } from 'src/components/HistoryModal';
import { GET_TEMPLATE_ACTIVITY_HISTORY } from 'src/features/settings/api/queries/templates';

interface VersionHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  template: TemplateType | null;
}

export const TemplateHistoryModal: React.FC<VersionHistoryModalProps> = ({
  isOpen,
  onClose,
  template,
}) => {
  const { data, fetchMore, loading, refetch } = useQuery(
    GET_TEMPLATE_ACTIVITY_HISTORY,
    { variables: { templateId: template?.id } },
  );

  useEffect(() => {
    refetch();
  }, [isOpen, refetch]);

  const templateVersions: TemplateVersion[] | [] =
    data?.estimationConfigTemplateVersions?.edges ?? [];

  const hasNextPage =
    data?.estimationConfigTemplateVersions?.pageInfo?.hasNextPage;
  const endCursor = data?.estimationConfigTemplateVersions?.pageInfo?.endCursor;

  if (hasNextPage && endCursor) {
    fetchMore({
      variables: {
        templateId: template?.id,
        after: endCursor,
      },
    });
  }

  return (
    <HistoryModal
      name={template?.name}
      versions={templateVersions}
      isLoadingPages={loading || hasNextPage}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
