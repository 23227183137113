// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-classes-per-file
import { EstimationDiscountDiscountTypeEnum } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup_estimates as EstimateGroupEstimate } from 'src/api/types/EstimateGroup';

abstract class DiscountCalculator {
  abstract getTotalWithDiscount(): number;

  abstract discountAmountForbidden(
    estimateTotal: number,
    value: number,
    discountType: EstimationDiscountDiscountTypeEnum,
  ): boolean;
}

export class EstimateDiscountCalculator extends DiscountCalculator {
  estimate: EstimateGroupEstimate;

  constructor(estimate: EstimateGroupEstimate) {
    super();
    this.estimate = estimate;
  }

  getTotalWithDiscount() {
    return this.estimate.basePrice || 0;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line class-methods-use-this
  discountAmountForbidden(
    estimateTotal: number,
    value: number,
    discountType: EstimationDiscountDiscountTypeEnum,
  ) {
    return estimateTotal <= 0 ||
      discountType === EstimationDiscountDiscountTypeEnum.DOLLARS
      ? value >= estimateTotal
      : value >= 100;
  }
}

export class EstimateGroupDiscountCalculator extends DiscountCalculator {
  activeEstimates: EstimateGroupEstimate[];

  constructor(activeEstimates: EstimateGroupEstimate[]) {
    super();
    this.activeEstimates = activeEstimates;
  }

  getTotalWithDiscount() {
    return this.activeEstimates.reduce(
      (sum: number, estimate: EstimateGroupEstimate) => {
        return sum + (estimate.basePrice ?? 0);
      },
      0,
    );
  }

  discountAmountForbidden(
    _estimateTotal: number,
    value: number,
    discountType: EstimationDiscountDiscountTypeEnum,
  ) {
    return this.activeEstimates.some((estimate: EstimateGroupEstimate) => {
      const estimateTotal = estimate.basePrice ?? 0;
      const error =
        estimateTotal <= 0 ||
        discountType === EstimationDiscountDiscountTypeEnum.DOLLARS
          ? value >= estimateTotal
          : value >= 100;
      return error;
    });
  }
}

export class DiscountCalculatorGenerator {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line class-methods-use-this
  public generate(
    estimate: EstimateGroupEstimate | undefined,
    activeEstimates: EstimateGroupEstimate[],
  ) {
    if (estimate) {
      return new EstimateDiscountCalculator(estimate);
    }
    return new EstimateGroupDiscountCalculator(activeEstimates);
  }
}
