import { Box, Button, Body, Image } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface ColorGroupingRulesEmptyProps {
  noCategoriesConfigured: boolean;
}

export const ColorGroupingRulesEmpty: React.FC<
  ColorGroupingRulesEmptyProps
> = ({ noCategoriesConfigured }) => {
  const history = useHistory();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const navigateToCreate = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      button_text: 'Create Rule',
      primary_cta: true,
      ...commonTrackingProps,
    });
    history.push(`/workflows/color_grouping_rules/create`);
  };

  const handleUpsell = () => {
    window.open('/contact', '_blank');
  };

  return (
    <Box
      backgroundColor="primary50"
      flexDirection="column"
      alignItems="center"
      marginTop={400}
      padding={400}
    >
      <Image
        alt="CalculationRulesDescription"
        src="https://assets.hover.to/icons/PNG/workflows-rules.png"
        height="150"
        width="150"
      />
      <Body width="500px" textAlign="center">
        Enable color matching between product categories with rules. <br />
        {noCategoriesConfigured
          ? 'HOVER will assist with initial setup for your organization.'
          : 'Your rule(s) will be listed here once created and saved.'}
      </Body>
      <Button
        size="large"
        onClick={noCategoriesConfigured ? handleUpsell : navigateToCreate}
        label="Create rule"
      >
        {noCategoriesConfigured ? 'Start Configuration' : 'Create Rule'}
      </Button>
    </Box>
  );
};
