import HelloSign from 'hellosign-embedded';

import appSettings from 'src/appSettings';
import { isProduction } from 'src/utils/EnvUtils';

interface ClientParams {
  clientId: string;
  skipDomainVerification?: boolean;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class Hellosign {
  static openEmbeddedSign(signUrl: string) {
    const client = new HelloSign();

    let params: ClientParams = {
      clientId: appSettings.HELLOSIGN_CLIENT_APP,
    };

    if (!isProduction()) {
      params = { ...params, skipDomainVerification: true };
    }
    client.open(signUrl, params);
  }
}
