import styled, { css, CSSObject } from 'styled-components';

export const IconElement = styled.i<{
  iconSize: Props['iconSize'];
  shadow?: Props['shadow'];
  shadowOpacity?: Props['shadowOpacity'];
}>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : 'inherit')};
  font-size: ${({ theme, iconSize }) => theme.utils.pxToRem(iconSize)}rem;
  ${({ shadow, shadowOpacity = 0.64 }) =>
    shadow &&
    css`
      text-shadow: 0px 0px 2px rgba(0, 0, 0, ${shadowOpacity});
    `}
  &.fa-equal:before {
    content: '=';
    font-size: 1.5em;
    font-weight: bold;
  }
  &.hover-icon {
    line-height: ${({ iconSize, theme }) => theme.utils.pxToRem(iconSize)}rem;
  }
`;

export const Container = styled.div<{
  borderColor: string;
  borderWidth: Props['borderWidth'];
  containerColor: string;
  size?: number;
  containerType: Props['containerType'];
  cornerRadius: Props['cornerRadius'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, borderColor, containerColor, borderWidth, size = 24 }) =>
    css`
      border: ${borderWidth}px solid ${theme.colors[borderColor]};
      min-width: ${theme.utils.pxToRem(size)}rem;
      height: ${theme.utils.pxToRem(size)}rem;
      background-color: ${theme.colors[containerColor]};
    `}
  ${({ theme, containerType, cornerRadius, size = 24 }) => {
    if (containerType === 'circle') {
      return css`
        border-radius: ${theme.utils.pxToRem(size / 2)}rem;
      `;
    }
    return css`
      border-radius: ${theme.utils.pxToRem(cornerRadius)}rem;
    `;
  }}
`;

const Circle = styled.div<{ iconSize: Props['iconSize'] }>`
  width: ${({ theme, iconSize }) => theme.utils.pxToRem(iconSize)}rem;
  height: ${({ theme, iconSize }) => theme.utils.pxToRem(iconSize)}rem;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'inherit'};
  border-radius: 50%;
`;

interface Props {
  borderColor?: string;
  borderWidth?: number;
  className?: string;
  containerColor?: string;
  containerType?: string;
  cornerRadius?: number;
  icon: string;
  iconSize?: number;
  family?: string;
  onClick?: () => void;
  shadow?: string;
  shadowOpacity?: string;
  size?: number;
  style?: CSSObject;
}

const Icon: React.FC<Props> = (props) => {
  const {
    borderColor = 'black',
    borderWidth = 1,
    containerColor = 'white',
    containerType,
    cornerRadius = 0,
    icon,
    iconSize = 16,
    size,
    ...rest
  } = props;

  const fontClasses = `fa fa-${icon}`;

  const iconEle =
    icon === 'circle' ? (
      <Circle {...rest} iconSize={iconSize} />
    ) : (
      <IconElement
        {...rest}
        iconSize={iconSize}
        className={[rest.className, fontClasses].join(' ')}
      />
    );

  if (containerType) {
    return (
      <Container
        size={size}
        containerType={containerType}
        cornerRadius={cornerRadius}
        borderColor={borderColor}
        containerColor={containerColor}
        borderWidth={borderWidth}
      >
        {iconEle}
      </Container>
    );
  }

  return iconEle;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Icon;
