import { RouterAction, LocationChangeAction } from 'connected-react-router';
import { ActionType } from 'typesafe-actions';

// import all top level actions here
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import * as projectActions from 'src/features/project/redux/actions';
import * as productionManagementActions from 'src/features/projectManagement/redux/actions';
import * as ehiGlobalActions from 'src/redux/actions';
import * as reducers from 'src/store/combineReducers';

// add type for them here
type EHIGlobalActions = ActionType<typeof ehiGlobalActions>;
type EstimatorActions = ActionType<typeof estimatorActions>;
type ProductionManagementActions = ActionType<
  typeof productionManagementActions
>;
type ProjectActions = ActionType<typeof projectActions>;

// used in React components
export type RootAction =
  | LocationChangeAction
  | EHIGlobalActions
  | EstimatorActions
  | ProductionManagementActions
  | ProjectActions
  | RouterAction;

export type RootState = reducers.RootState;
