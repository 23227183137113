import { gql } from '@apollo/client';

import { proposalFields } from 'src/api/queries/queries';

export const CREATE_PROPOSAL_DOCUMENT = gql`
  mutation projectManagementProposalDocumentCreate(
    $proposalDocumentAttributes: ProjectManagementProposalDocumentCreate!
  ) {
    projectManagementProposalDocumentCreate(
      proposalDocumentAttributes: $proposalDocumentAttributes
    ) {
      errors {
        attribute
        errors
      }
      proposalDocument {
        ...proposalFields
      }
    }
  }
  ${proposalFields}
`;

export const FETCH_PROPOSAL_DOCUMENT = gql`
  query projectManagementProposalDocument($id: ID!) {
    projectManagementProposalDocument(id: $id) {
      ...proposalFields
    }
  }
  ${proposalFields}
`;

export const FETCH_PROPOSAL_DOCUMENTS = gql`
  query projectManagementProposalDocumentsEstimateDetails(
    $salesOpportunityId: ID
    $estimateGroupId: ID
    $state: ProjectManagementProposalDocumentStateEnum
    $after: String
  ) {
    projectManagementProposalDocuments(
      salesOpportunityId: $salesOpportunityId
      estimateGroupId: $estimateGroupId
      state: $state
      after: $after
      first: 20
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...proposalFields
        }
      }
    }
  }
  ${proposalFields}
`;

export const CREATE_SIGNATURE_REQUEST = gql`
  mutation projectManagementSignatureRequestCreate(
    $signatureRequestAttributes: ProjectManagementSignatureRequestCreate!
  ) {
    projectManagementSignatureRequestCreate(
      signatureRequestAttributes: $signatureRequestAttributes
    ) {
      signatureRequest {
        id
      }
    }
  }
`;

export const FETCH_SIGNATURE_REQUEST = gql`
  query projectManagementSignatureRequest($id: ID!) {
    projectManagementSignatureRequest(id: $id) {
      id
      failureReason
      state
    }
  }
`;

export const FETCH_EMBEDDED_SIGNATURE_URL = gql`
  query projectManagementSignatureRequestEmbeddedUrl($signatureRequestId: ID!) {
    projectManagementSignatureRequestEmbeddedUrl(
      signatureRequestId: $signatureRequestId
    ) {
      signUrl
    }
  }
`;

export const ORG_PROPOSAL_SETTINGS = gql`
  query projectManagementConfigOrgProposalSetting($orgId: ID!) {
    projectManagementConfigOrgProposalSetting(orgId: $orgId) {
      allowDefaultOverrideIncludeAllEstimateItems
      allowDefaultOverrideIncludeItemCost
      allowDefaultOverrideIncludeItemQuantities
      allowDefaultOverrideIncludeTotalPrice
      allowDefaultOverrideIncludeTradePrice
      defaultNote
      id
      includeAllEstimateItemsDefault
      includeItemCostDefault
      includeItemQuantitiesDefault
      includeTotalPriceDefault
      includeTradePriceDefault
      maxSigneeCount
    }
  }
`;
