import { gql } from '@apollo/client';

export const GET_JOB = gql`
  query job($ids: [Int!]) {
    jobs(ids: $ids) {
      results {
        id
        locationLine1
      }
    }
  }
`;
