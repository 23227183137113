import { gql } from '@apollo/client';

import { checklistTemplateQuestion } from 'src/features/settings/api/queries/inspectionChecklist';

export const CREATE_TEMPLATE = gql`
  mutation inspectionConfigChecklistTemplateCreate(
    $checklistTemplateAttributes: InspectionConfigChecklistTemplateCreateInput!
  ) {
    inspectionConfigChecklistTemplateCreate(
      checklistTemplateAttributes: $checklistTemplateAttributes
    ) {
      checklistTemplate {
        id
        name
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation inspectionConfigChecklistTemplateUpdate(
    $id: ID!
    $checklistTemplateAttributes: InspectionConfigChecklistTemplateUpdateInput!
  ) {
    inspectionConfigChecklistTemplateUpdate(
      id: $id
      checklistTemplateAttributes: $checklistTemplateAttributes
    ) {
      checklistTemplate {
        id
        name
        state
        logoUrl
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const CREATE_TEMPLATE_QUESTION = gql`
  mutation inspectionConfigChecklistTemplateQuestionCreate(
    $checklistTemplateQuestionAttributes: InspectionConfigChecklistTemplateQuestionCreateInput!
  ) {
    inspectionConfigChecklistTemplateQuestionCreate(
      checklistTemplateQuestionAttributes: $checklistTemplateQuestionAttributes
    ) {
      checklistTemplateQuestion {
        ...checklistTemplateQuestion
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${checklistTemplateQuestion}
`;

export const DELETE_TEMPLATE_QUESTION = gql`
  mutation inspectionConfigChecklistTemplateQuestionDelete($id: ID!) {
    inspectionConfigChecklistTemplateQuestionDelete(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;

export const CREATE_TEMPLATE_SECTION = gql`
  mutation inspectionConfigChecklistTemplateSectionCreate(
    $checklistTemplateSectionAttributes: InspectionConfigChecklistTemplateSectionCreateInput!
  ) {
    inspectionConfigChecklistTemplateSectionCreate(
      checklistTemplateSectionAttributes: $checklistTemplateSectionAttributes
    ) {
      checklistTemplateSection {
        id
        name
        sortOrder
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_TEMPLATE_SECTION = gql`
  mutation inspectionConfigChecklistTemplateSectionUpdate(
    $id: ID!
    $checklistTemplateSectionAttributes: InspectionConfigChecklistTemplateSectionUpdateInput!
  ) {
    inspectionConfigChecklistTemplateSectionUpdate(
      id: $id
      checklistTemplateSectionAttributes: $checklistTemplateSectionAttributes
    ) {
      checklistTemplateSection {
        id
        name
        sortOrder
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const DELETE_TEMPLATE_SECTION = gql`
  mutation inspectionConfigChecklistTemplateSectionDelete($id: ID!) {
    inspectionConfigChecklistTemplateSectionDelete(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;

export const ARCHIVE_TEMPLATE = gql`
  mutation inspectionConfigChecklistTemplateArchive($id: ID!) {
    inspectionConfigChecklistTemplateArchive(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_TEMPLATE_QUESTION = gql`
  mutation inspectionConfigChecklistTemplateQuestionUpdate(
    $id: ID!
    $checklistTemplateQuestionAttributes: InspectionConfigChecklistTemplateQuestionUpdateInput!
  ) {
    inspectionConfigChecklistTemplateQuestionUpdate(
      id: $id
      checklistTemplateQuestionAttributes: $checklistTemplateQuestionAttributes
    ) {
      checklistTemplateQuestion {
        ...checklistTemplateQuestion
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${checklistTemplateQuestion}
`;

export const UPDATE_TEMPLATE_SECTION_ORDER = gql`
  mutation inspectionConfigChecklistTemplateSectionsSortOrderUpdate(
    $checklistTemplateId: ID!
    $checklistTemplateSectionIds: [ID!]!
  ) {
    inspectionConfigChecklistTemplateSectionsSortOrderUpdate(
      checklistTemplateId: $checklistTemplateId
      checklistTemplateSectionIds: $checklistTemplateSectionIds
    ) {
      checklistTemplate {
        id
        name
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_TEMPLATE_QUESTION_ORDER = gql`
  mutation inspectionConfigChecklistTemplateQuestionsSortOrderUpdate(
    $checklistTemplateSectionId: ID!
    $checklistTemplateQuestionIds: [ID!]!
  ) {
    inspectionConfigChecklistTemplateQuestionsSortOrderUpdate(
      checklistTemplateSectionId: $checklistTemplateSectionId
      checklistTemplateQuestionIds: $checklistTemplateQuestionIds
    ) {
      checklistTemplateSection {
        id
        name
        questions {
          ...checklistTemplateQuestion
        }
        sortOrder
        updatedAt
        createdAt
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${checklistTemplateQuestion}
`;

export const COPY_TEMPLATE = gql`
  mutation inspectionConfigChecklistTemplateCopy($id: ID!) {
    inspectionConfigChecklistTemplateCopy(id: $id) {
      checklistTemplate {
        id
        name
        state
        logoUrl
      }
      errors {
        attribute
        errors
      }
    }
  }
`;
