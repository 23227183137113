import { gql } from '@apollo/client';

export const GET_REQUIRED_INPUTS = gql`
  query estimationConfigRequiredInputs(
    $orgId: ID!
    $templateIds: [ID!]
    $after: String
  ) {
    estimationConfigRequiredInputs(
      orgId: $orgId
      templateIds: $templateIds
      after: $after
      first: 50
    ) {
      edges {
        node {
          id
          name
          question
          questionDefaultValue
          inputType
          inputCategory {
            name
            id
          }
          inputConditions {
            id
            parentInputId
            parentValue
          }
          inputOptions(orgId: $orgId) {
            label
            value
            sortOrder
            createdAt
            updatedAt
            id
          }
          tradeTypes
          tradeTypeCategories
          argument
          measurementUnits
          sortOrder
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_INPUT_CATEGORIES = gql`
  query estimationConfigInputCategories($after: String) {
    estimationConfigInputCategories(after: $after, first: 100) {
      edges {
        node {
          id
          name
          sortOrder
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
