import { Panel, Body } from '@hover/blueprint';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '@hover/tokens-brand/assets/logo-mark--black.svg';

/*
  The logo doesn't exist in a format that can be used by Blueprint
  at the moment. TODO: replace when that exists
 */
export const HoverContentBadge: React.FC = () => (
  <Panel
    alignItems="center"
    alignSelf="center"
    backgroundColor="neutral100"
    flexDirection="row"
    height="max-content"
    paddingLeft={400}
    paddingY={100}
    width="max-content"
  >
    <img
      src={logo}
      alt="HOVER logo"
      style={{ height: '32px', opacity: 0.52 }}
    />
    <Body
      as="div"
      size={400}
      alignSelf="center"
      marginLeft={200}
      color="neutral600"
    >
      HOVER content
    </Body>
  </Panel>
);
