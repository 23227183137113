import { useMutation } from '@apollo/client';
import { Body, Box, Button, Heading, Modal } from '@hover/blueprint';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { deleteDiscount as DeleteDiscount } from 'src/api/types/deleteDiscount';
import {
  GET_ESTIMATE_GROUP,
  DELETE_DISCOUNT,
} from 'src/features/exteriorEstimator/apis/queries';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getSelectedPromotion,
  getParams,
  getEstimateGroupIdFromLocation,
  getEstimatefromEstimateGroupById,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  calculatePercentage,
  calculateDollars,
} from 'src/features/exteriorEstimator/utils/discountsUtils';
import { useTracking } from 'src/hooks';
import { getVariationsFilter } from 'src/redux/selectors';
import { RootState } from 'src/store/combineReducers';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  closeModal: () => void;
  total: number;
};

export const RemovePromotionModal: React.FC<Props> = ({
  closeModal,
  total,
}) => {
  const dispatch = useDispatch();
  const { jobId } = useSelector(getParams);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const discount = useSelector(getSelectedPromotion) ?? {};
  const { id, value, discountType, estimationEstimateId } = discount;
  const estimate = useSelector((state: RootState) =>
    getEstimatefromEstimateGroupById(state, estimationEstimateId),
  );

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [deleteDiscount, { loading }] = useMutation<DeleteDiscount>(
    DELETE_DISCOUNT,
    {
      onCompleted: ({ estimationDiscountDelete }) => {
        if (estimationDiscountDelete) {
          dispatch(
            estimatorActions.removeDiscount.success({
              discountId: id,
            }),
          );
        }
        closeModal();
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP,
          variables: {
            id: estimateGroupId,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    },
  );

  const handleRemovePromotion = () => {
    deleteDiscount({ variables: { id } });
  };

  const handleCloseModalButtonClick = () => {
    typewriter.buttonPressed({
      button_text: 'Cancel Promotion',
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
    closeModal();
  };

  if (!discount) return null;

  let discountTotal = total;

  if (estimate) discountTotal = estimate.basePrice ?? 0;

  const dollarValue = calculateDollars({
    value,
    discountType,
    total: discountTotal,
  });
  const percentValue = calculatePercentage({
    value,
    discountType,
    total: discountTotal,
  });
  return (
    <Modal
      isOpen
      isCentered
      isClosable={false}
      onClose={closeModal}
      size="small"
      data-test-id="removePromotionModalV2"
    >
      <Box padding={500} flexDirection="column" width={1}>
        <Box flex={1} width={1} alignItems="center" marginBottom={300}>
          <Heading
            size={400}
            margin=".5em 0 .5em 0"
            lineHeight=".83em"
            data-test-id="modalHeader"
          >
            Remove Custom Promotion
          </Heading>
        </Box>
        <Box flex={1} alignItems="center" marginBottom={400}>
          <Body size={400} color="neutral.600">
            Will add{' '}
            <NumericFormat
              value={dollarValue}
              decimalScale={2}
              displayType="text"
              prefix="-$"
              thousandSeparator
              data-test-id="promoDiffAmount"
            />{' '}
            <NumericFormat
              value={percentValue}
              decimalScale={2}
              displayType="text"
              prefix="("
              suffix="%)"
              thousandSeparator
            />{' '}
            to total estimate
          </Body>
        </Box>
        <Box flex={1} alignItems="center" width={1}>
          <Button
            width={1}
            marginBottom={400}
            color="danger"
            isDisabled={loading}
            onClick={handleRemovePromotion}
            data-test-id="confirmPromotionsV2DestroyModal"
          >
            Remove From Estimate
          </Button>
        </Box>
        <Box flex={1} alignItems="center" width={1}>
          <Button
            fill="outline"
            width={1}
            marginBottom={200}
            isDisabled={false}
            onClick={handleCloseModalButtonClick}
            data-test-id="cancelPromotionsV2DestroyModal"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
