import { gql } from '@apollo/client';

export const PRODUCT_CATALOG_CATEGORIES = gql`
  query productCatalogCategories(
    $orgId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    productCatalogCategories(
      orgId: $orgId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        id
        name
        orgProductsCount
      }
      edges {
        node {
          id
          name
          orgProductsCount
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

export const MATCH_GROUPS = gql`
  query productCatalogMatchGroups(
    $orgId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    productCatalogMatchGroups(
      orgId: $orgId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        id
        name
        categories {
          id
          name
        }
        active
        description
        createdAt
        updatedAt
      }
      edges {
        node {
          id
          name
          categories {
            id
            name
          }
          active
          description
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

export const MATCH_GROUP = gql`
  query productCatalogMatchGroup($id: ID!) {
    productCatalogMatchGroup(id: $id) {
      id
      name
      categories {
        id
        name
      }
      active
      description
    }
  }
`;

export const MATCH_GROUP_DELETE = gql`
  mutation productCatalogMatchGroupDelete($id: ID!) {
    productCatalogMatchGroupDelete(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;

export const MATCH_GROUP_CREATE = gql`
  mutation productCatalogMatchGroupCreate(
    $matchGroupAttributes: ProductCatalogMatchGroupCreateInput!
    $orgId: ID!
  ) {
    productCatalogMatchGroupCreate(
      matchGroupAttributes: $matchGroupAttributes
      orgId: $orgId
    ) {
      errors {
        attribute
        errors
      }
      matchGroup {
        id
        name
        categories {
          id
          name
        }
        active
        description
      }
    }
  }
`;

export const MATCH_GROUP_UPDATE = gql`
  mutation productCatalogMatchGroupUpdate(
    $matchGroupAttributes: ProductCatalogMatchGroupUpdateInput
    $matchGroupId: ID!
  ) {
    productCatalogMatchGroupUpdate(
      matchGroupAttributes: $matchGroupAttributes
      matchGroupId: $matchGroupId
    ) {
      errors {
        attribute
        errors
      }
      matchGroup {
        id
        name
        categories {
          id
          name
        }
        active
        description
      }
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query productCatalogProductsByCategory(
    $orgId: ID!
    $after: String
    $productCatalogCategoryId: ID
  ) {
    productCatalogProducts(
      orgId: $orgId
      after: $after
      filterByOrg: true
      productCatalogCategoryId: $productCatalogCategoryId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
        }
      }
      nodes {
        id
        name
      }
    }
  }
`;
