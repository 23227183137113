import { sanitize } from 'dompurify';

type Props = {
  html: string | Node;
};

export const SanitizedHTML: React.FC<Props> = ({ html }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
);
