import { useCallback, useState } from 'react';

export function useDistributorSelectModal() {
  const [showDistributorSelectModal, setShowDistributorSelectModal] =
    useState<boolean>(false);

  const openDistributorSelectModal = useCallback(() => {
    setShowDistributorSelectModal(true);
  }, [setShowDistributorSelectModal]);

  const closeDistributorSelectModal = useCallback(() => {
    setShowDistributorSelectModal(false);
  }, [setShowDistributorSelectModal]);

  return {
    showDistributorSelectModal,
    openDistributorSelectModal,
    closeDistributorSelectModal,
  };
}
