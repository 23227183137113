import { Box, Button, Heading } from '@hover/blueprint';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { messages } from 'src/constants/messages';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import {
  getParams,
  getRouter,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { hasAnySoldEstimateGroup } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import { getUserTrackingProps, getOrgSettings } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pushMethod: push,
    },
    dispatch,
  );

export const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  estimateGroupId: getEstimateGroupIdFromLocation(state),
  router: getRouter(state),
  estimateGroup: state.exteriorEstimator.estimateGroup.data,
  commonProps: getUserTrackingProps(state),
  showOrderingFlow: getOrgSettings(state)?.showOrderingFlow,
});

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const ButtonText = {
  Loading: 'Loading…',
  Sold: 'Select restricted',
  Unsold: 'Select estimates',
  DirectOrder: 'Edit calculation',
};

const NavbarComponent: React.FC<Props> = ({
  pushMethod,
  jobId,
  estimateGroupId,
  estimateGroup,
  router,
  showOrderingFlow,
  commonProps,
}) => {
  const toast = useToastEhi();
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();
  const hasSoldEstimateGroup = hasAnySoldEstimateGroup(estimateGroup);

  const handleSelectEstimates = () => {
    if (!jobId || !estimateGroupId) return;

    pushMethod({
      pathname: `/estimator/estimates/${estimateGroupId}`,
      search: router.location.search,
    });
  };

  const handleButtonClick = () => {
    if (hasSoldEstimateGroup) {
      toast({
        id: 'select-estimates-button-toast',
        description:
          messages.projectScope.errors.query.productionList
            .estimatesCannotBeChangedforSold,
        status: ToastStatusEnum.INFO,
      });
    } else {
      typewriter.buttonPressed({
        button_text: 'Select Estimates',
        job_id: Number(jobId),
        page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
        primary_cta: false,
        ...commonProps,
      });
      handleSelectEstimates();
    }
  };

  const getButtonText = () => {
    if (!estimateGroup) return ButtonText.Loading;

    if (showOrderingFlow) return ButtonText.DirectOrder;
    if (hasSoldEstimateGroup) return ButtonText.Sold;
    return ButtonText.Unsold;
  };

  const jobType = showOrderingFlow
    ? 'Material list preview'
    : 'Estimate Details';
  const buttonText = getButtonText();
  const isDisabled = !estimateGroup;

  return (
    <>
      <Box
        width="100vw"
        height="75px"
        justifyContent="center"
        alignItems="center"
        backgroundColor="neutral.0"
        boxShadow="0 4px 14px rgba(0, 0, 0, 0.1);"
      >
        <EstimatorResponsiveWrapper
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Box justifyContent="center">
            <Heading
              size={300}
              color="neutral.600"
              data-test-id="EstimateDetailsHeader"
            >
              {jobType}
            </Heading>
          </Box>
          <Button
            size={{
              base: 'default',
              tablet: 'large',
              desktop: 'large',
            }}
            isDisabled={isDisabled}
            onClick={handleButtonClick}
            fill="outline"
            data-test-id="selectEstimatesButton"
          >
            {buttonText}
          </Button>
        </EstimatorResponsiveWrapper>
      </Box>
    </>
  );
};

export const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavbarComponent);
