import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ProjectManagementProposalDocumentStateEnum } from 'src/api/graphql-global-types';
import * as ProposalActions from 'src/features/exteriorEstimator/redux/actions/proposalActions';
import { RootAction } from 'src/types/reduxStore';

import { FETCH_PROPOSAL_DOCUMENT } from '../../../apis/queries/proposal';
import { ProposalViews } from '../../../types';

interface IProps {
  id?: string;
  setProposalModalView: (view: ProposalViews | null) => void;
}

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateProposal: ProposalActions.updateProposal,
      setProposalsError: ProposalActions.setProposalsError,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapDispatchToProps> & IProps;

/*
  This gets called when we hit `Generate`. All it does is poll and then redirect to the SHOW view.
*/
export const ProposalLoaderComponent: React.FC<Props> = ({
  id,
  updateProposal,
  setProposalModalView,
  setProposalsError,
}) => {
  const { loading, data, stopPolling, error } = useQuery(
    FETCH_PROPOSAL_DOCUMENT,
    {
      variables: { id },
      pollInterval: 1500,
    },
  );

  if (!data || loading) return null;

  const proposal = data.projectManagementProposalDocument;

  if (
    error ||
    !proposal ||
    proposal.state === ProjectManagementProposalDocumentStateEnum.FAILED
  ) {
    stopPolling();
    setProposalsError(error || new Error('Proposal creation failed'));
    updateProposal({ proposal });
    return null;
  }
  if (proposal.state === ProjectManagementProposalDocumentStateEnum.COMPLETE) {
    stopPolling();
    updateProposal({ proposal });
    setProposalModalView(ProposalViews.SHOW);
  }

  return null;
};

export const ProposalLoader = connect(
  undefined,
  mapDispatchToProps,
)(ProposalLoaderComponent);
