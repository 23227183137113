import { PureComponent } from 'react';

import { TextInput, TextInputProps } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import numeral from 'numeral';

import { InputContainer } from './StyledFormattedInput';

type Props = TextInputProps & {
  format?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleBlur?: Function;
  isError?: boolean;
  role?: string;
};

interface FormattedInputState {
  trackedValue: string;
}

export class FormattedInput extends PureComponent<Props, FormattedInputState> {
  constructor(props: Props) {
    super(props);
    const { value, format } = props;
    this.state = { trackedValue: this.formatValue(value, format) };
  }

  componentDidUpdate(prevProps: Props) {
    const { value, format } = this.props;
    const { trackedValue } = this.state;
    if (value === prevProps.value || value?.toString() === trackedValue) return;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ trackedValue: this.formatValue(value, format) });
  }

  public formatValue(value: Props['value'], format?: string): string {
    return numeral(value).format(format || '0,0[.]00');
  }

  @autobind
  public onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      trackedValue: event.target.value,
    });
  }

  @autobind
  public onBlur(event: React.ChangeEvent<HTMLInputElement>) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.handleBlur) this.props.handleBlur(event);
    this.setState({
      trackedValue: this.formatValue(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
        this.state.trackedValue,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/destructuring-assignment
        this.props.format,
      ),
    });
  }

  public render() {
    const { trackedValue } = this.state;
    const { role = 'textbox', isError, handleBlur, ...props } = this.props;

    return (
      <InputContainer>
        <TextInput
          {...props}
          role={role}
          textAlign="end"
          width="70px"
          value={trackedValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          fontFamily="Gotham Book, Helvetica Neue, Arial, sans-serif"
          size="tiny"
          fontSize="0.75rem"
          isInvalid={isError}
        />
      </InputContainer>
    );
  }
}
