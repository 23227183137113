import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const miscReducer = createReducer(initialState)
  .handleAction(estimatorActions.setError, (state, action) => ({
    ...state,
    error: action.payload.error,
  }))
  .handleAction(estimatorActions.setupEstimatorEnd, (state) => ({
    ...state,
    pages: {
      ...state.pages,
    },
  }));
