import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { projectManagementConfigOrgProposalSettingSelfService } from 'src/api/types/projectManagementConfigOrgProposalSettingSelfService';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { GET_PROPOSAL_SETTING } from 'src/features/settings/api/queries/proposals';
import { ScopeOfWork } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/ScopeOfWork';
import { getUserOrgId } from 'src/redux/selectors';

type Props = {
  showOrgLogo: boolean;
  showTitle: boolean;
  title: string;
  setSuccessNotification: () => void;
  setFailureNotification: () => void;
  navigateToProposalList: () => void;
};

export const ScopeOfWorkDataContainer: React.FC<Props> = ({
  showOrgLogo,
  showTitle,
  title,
  setSuccessNotification,
  setFailureNotification,
  navigateToProposalList,
}) => {
  const orgId = useSelector(getUserOrgId);

  const { data: { projectManagementConfigOrgProposalSetting } = {}, loading } =
    useQuery<projectManagementConfigOrgProposalSettingSelfService>(
      GET_PROPOSAL_SETTING,
      {
        variables: { orgId },
      },
    );

  if (!projectManagementConfigOrgProposalSetting || loading)
    return <LoaderSpinner show />;

  return (
    <ScopeOfWork
      title={title}
      showOrgLogo={showOrgLogo}
      showTitle={showTitle}
      includeAllEstimateItemsDefault={
        projectManagementConfigOrgProposalSetting?.includeAllEstimateItemsDefault
      }
      includeItemCostDefault={
        projectManagementConfigOrgProposalSetting?.includeItemCostDefault
      }
      includeItemQuantitiesDefault={
        projectManagementConfigOrgProposalSetting?.includeItemQuantitiesDefault
      }
      includeTotalPriceDefault={
        projectManagementConfigOrgProposalSetting?.includeTotalPriceDefault
      }
      includeTradePriceDefault={
        projectManagementConfigOrgProposalSetting?.includeTradePriceDefault
      }
      allowDefaultOverrideIncludeAllEstimateItems={
        projectManagementConfigOrgProposalSetting?.allowDefaultOverrideIncludeAllEstimateItems
      }
      allowDefaultOverrideIncludeItemCost={
        projectManagementConfigOrgProposalSetting?.allowDefaultOverrideIncludeItemCost
      }
      allowDefaultOverrideIncludeItemQuantities={
        projectManagementConfigOrgProposalSetting?.allowDefaultOverrideIncludeItemQuantities
      }
      allowDefaultOverrideIncludeTotalPrice={
        projectManagementConfigOrgProposalSetting?.allowDefaultOverrideIncludeTotalPrice
      }
      allowDefaultOverrideIncludeTradePrice={
        projectManagementConfigOrgProposalSetting?.allowDefaultOverrideIncludeTradePrice
      }
      scopeOfWorkDisclaimer={
        projectManagementConfigOrgProposalSetting?.scopeOfWorkDisclaimer
      }
      setSuccessNotification={setSuccessNotification}
      setFailureNotification={setFailureNotification}
      navigateToProposalList={navigateToProposalList}
    />
  );
};
