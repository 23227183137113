export type ErrorType = 'danger500';
export type PristineType = 'neutral600';

export const LabelColors: {
  error: ErrorType;
  pristine: PristineType;
} = {
  error: 'danger500',
  pristine: 'neutral600',
};
