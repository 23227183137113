import { createAsyncAction, createAction } from 'typesafe-actions';

import { ProjectManagementProposalDocumentCreate } from 'src/api/graphql-global-types';
import { projectManagementProposalDocument_projectManagementProposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocument';
import { projectManagementProposalDocumentsEstimateDetails_projectManagementProposalDocuments_edges_node as ProposalType } from 'src/api/types/projectManagementProposalDocumentsEstimateDetails';

export const createProposal = createAsyncAction(
  'EXTERIOR_ESTIMATOR/GENERATE_PROPOSAL_REQUEST',
  'EXTERIOR_ESTIMATOR/GENERATE_PROPOSAL_SUCCESS',
  'EXTERIOR_ESTIMATOR/GENERATE_PROPOSAL_FAILURE',
)<ProjectManagementProposalDocumentCreate, ProposalType, Error>();

export const updateProposal = createAction(
  'EXTERIOR_ESTIMATOR/UPDATE_PROPOSAL_SUCCESS',
  ({ proposal }: { proposal: Proposal }) => ({ proposal }),
)();

export const getProposals = createAsyncAction(
  'EXTERIOR_ESTIMATOR/GET_PROPOSALS_REQUEST',
  'EXTERIOR_ESTIMATOR/GET_PROPOSALS_SUCCESS',
  'EXTERIOR_ESTIMATOR/GET_PROPOSALS_FAILURE',
)<{ estimateGroupId: string }, ProposalType[], Error>();

export const setSelectedProposal = createAction(
  'ESTIMATOR/SET_SELECTED_PROPOSAL',
  ({ id }: { id: string | null }) => ({ id }),
)();

export const setProposalsError = createAction(
  'ESTIMATOR/SET_PROPOSALS_ERROR',
  (error: Error) => error,
)();

export const clearProposalsError = createAction(
  'ESTIMATOR/CLEAR_PROPOSALS_ERROR',
  () => null,
)();
