import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { isNil, toNumber, get } from 'lodash';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { EHI_ESTIMATOR_DEBUG_KEY } from 'src/constants';
import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import * as checklistActions from 'src/features/exteriorEstimator/redux/actions/checklistActions';
import * as inputCategoriesActions from 'src/features/exteriorEstimator/redux/actions/inputCategoriesActions';
import * as measurementsActions from 'src/features/exteriorEstimator/redux/actions/measurementsActions';
import * as pageActions from 'src/features/exteriorEstimator/redux/actions/pageActions';
import * as requiredInputsActions from 'src/features/exteriorEstimator/redux/actions/requiredInputsActions';
import { getModelId } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import {
  PlainMeasurements,
  TradeTypeEnumOrg,
} from 'src/features/exteriorEstimator/types';
import {
  HdfSource,
  HDFMeasurements,
} from 'src/features/exteriorEstimator/types/HdfMeasurements';
import { JobMeasurements } from 'src/features/exteriorEstimator/types/jobMeasurements';
import { Page, Input } from 'src/features/exteriorEstimator/types/Questions';
import * as cacheUtils from 'src/features/exteriorEstimator/utils/cacheUtils';
import { EstimationMeasurementParser } from 'src/features/exteriorEstimator/utils/EstimationMeasurementParser';
import {
  getTemplates,
  getRequiredInputs,
  getCategories,
} from 'src/features/exteriorEstimator/utils/gqlUtils';
import { HdfEstimatorComparator } from 'src/features/exteriorEstimator/utils/HdfEstimationComparator';
import { HdfMeasurementParser } from 'src/features/exteriorEstimator/utils/HdfMeasurementParser';
import * as measurementsUtils from 'src/features/exteriorEstimator/utils/measurementsUtils';
import { getFilteredHdf } from 'src/features/exteriorEstimator/utils/measurementsUtils';
import { PartialsMeasurementsMapHdf } from 'src/features/exteriorEstimator/utils/PartialMeasurementsMapHdf';
import { PristineMeasurementsGenerator } from 'src/features/exteriorEstimator/utils/PristineMeasurementsGenerator';
import { QuestionResponsesGenerator } from 'src/features/exteriorEstimator/utils/QuestionResponsesGenerator';
import { EstimatorFlowGenerator } from 'src/features/exteriorEstimator/utils/QuestionsGenerator';
import { COMMERCE_USE_HDF, isEnabled } from 'src/lib/FeatureFlag';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { getTradeTypesSorted } from 'src/redux/selectors';
import { getUserOrgId } from 'src/redux/selectors/orgSelectors';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

export function useEstimate() {
  const jobIdFromSelector = toNumber(useSelector(getParams).jobId);
  const jobIdFromUrl = Number(get(useParams(), 'jobId'));
  const jobId = jobIdFromSelector || jobIdFromUrl;
  const orgId = useSelector(getUserOrgId);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const modelId = useSelector(getModelId);

  const [isFetchingQuestions, setIsFetchingQuestions] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const setPristineMeasurements = (questions: Input[]) => {
    const pristineMeasurements =
      PristineMeasurementsGenerator.initialize(questions);
    dispatch(
      measurementsActions.storePristineMeasurements({ pristineMeasurements }),
    );
  };

  const setQuestionResponses = ({
    questions,
    isManualMeasurements,
    tradeTypeToWasteFactorMap,
  }: {
    questions: Input[];
    isManualMeasurements: boolean;
    tradeTypeToWasteFactorMap: Map<TradeTypeEnum, number>;
  }) => {
    let questionResponses = QuestionResponsesGenerator.getInitialResponses(
      questions,
      tradeTypeToWasteFactorMap,
    );

    const jobDataInCache = cacheUtils.getJobDataFromCache(jobId);
    const didSetCacheWithManualMeasurements =
      jobDataInCache?.didSetCacheWithManualMeasurements;

    if (jobDataInCache) {
      // if we haven't previously set the didSetCacheWithManualMeasurements value in cache
      // then bust the cache and set all values
      if (isNil(didSetCacheWithManualMeasurements)) {
        cacheUtils.clearJobDataFromCache(jobId);
        cacheUtils.saveQuestionsToCache({
          jobId,
          answers: questionResponses,
          didSetCacheWithManualMeasurements: isManualMeasurements,
        });
      }
      // if cache previously set from manual measurements and we now have measurements then bust cache
      else if (didSetCacheWithManualMeasurements && !isManualMeasurements) {
        cacheUtils.clearJobDataFromCache(jobId);
        cacheUtils.saveQuestionsToCache({
          jobId,
          answers: questionResponses,
          didSetCacheWithManualMeasurements: false,
        });
      }
      // override questionResponses with values previously set in cache
      else {
        questionResponses = cacheUtils.overrideResponsesFromCache({
          jobId,
          questionResponses,
          didSetCacheWithManualMeasurements: isManualMeasurements,
        });
      }
    }
    // no job data in cache so set
    else {
      cacheUtils.saveQuestionsToCache({
        jobId,
        answers: questionResponses,
        didSetCacheWithManualMeasurements: isManualMeasurements,
      });
    }

    dispatch(
      estimatorActions.setQuestionResponsesEnd({
        emptyQuestionResponses: questionResponses,
      }),
    );
    return questionResponses;
  };

  const getOrInitializeSelectedTemplates = (
    selectedTemplates: number[] | null,
  ) => {
    let thisSelectedTemplates = selectedTemplates;
    if (!thisSelectedTemplates || thisSelectedTemplates.length === 0) {
      const parsedHash = queryString.parse(window.location.hash)[
        'template_ids[]'
      ];

      let templateIdsArray: Array<string | null> = [];
      if (parsedHash) {
        if (Array.isArray(parsedHash)) {
          templateIdsArray = parsedHash;
        } else {
          templateIdsArray = [parsedHash];
        }
      }

      const defaultSelectedTemplates: number[] = [];

      thisSelectedTemplates = templateIdsArray.length
        ? templateIdsArray.map((id) => Number(id))
        : defaultSelectedTemplates;
    }

    dispatch(
      estimatorActions.initializeSelectedTemplates({
        templateIds: thisSelectedTemplates,
      }),
    );
    return thisSelectedTemplates;
  };

  const getQuestionsAndPages = async (
    templates: Template[],
    currentlySelectedTemplates: number[],
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings,
  ) => {
    const requiredInputs = await getRequiredInputs(
      orgId,
      currentlySelectedTemplates,
    );

    let fullMeasurements: Measurements;
    let jobMeasurements: JobMeasurements;
    let hdfSource: HdfSource;
    let hdfMeasurements: HDFMeasurements | null = null;
    let plainJsonMeasurements: PlainMeasurements;

    try {
      fullMeasurements = (
        (await EstimatorApi.getMeasurementsEstimation(Number(jobId))) as any
      ).data as Measurements;

      jobMeasurements = new EstimationMeasurementParser(
        fullMeasurements as Measurements,
        orgSettings,
      ).results;
    } catch (_e) {
      //  else fallback to the machete model measurements
      const getModelsResponse: any = await EstimatorApi.getModels(
        Number(jobId),
      );
      const { models } = getModelsResponse.data;
      dispatch(estimatorActions.getModels({ models }));
      fullMeasurements = (
        models[0]?.machete_model_measurements?.url
          ? await EstimatorApi.getJobMeasurements(
              models[0].machete_model_measurements.url,
            )
          : null
      ) as any;
      jobMeasurements = fullMeasurements
        ? measurementsUtils.filterJobMeasurements(fullMeasurements as any)
        : {};
    }

    try {
      plainJsonMeasurements = (
        await EstimatorApi.getMeasurementsPlainJson(Number(jobId))
      ).data as PlainMeasurements;

      dispatch(
        estimatorActions.getMeasurementsPlainJson.success(
          plainJsonMeasurements,
        ),
      );
    } catch (error) {
      dispatch(estimatorActions.getMeasurementsPlainJson.failure(error as any));
    }

    if (isEnabled(COMMERCE_USE_HDF)) {
      try {
        hdfSource = getFilteredHdf(
          (await EstimatorApi.getHDFMeasurements(Number(modelId))).data,
        ) as HdfSource;

        const parser = new HdfMeasurementParser(hdfSource, orgSettings);
        hdfMeasurements = parser.getValues();
        dispatch(estimatorActions.storeHdfMeasurements({ hdfMeasurements }));
        const calculator = new PartialsMeasurementsMapHdf({
          hdf: hdfSource,
        });
        dispatch(
          estimatorActions.storePartialsMeasurements({
            partialsMeasurements: {
              edges: calculator.edges,
              facades: calculator.facades,
              windowsForFacade: calculator.windowsForFacade,
            },
          }),
        );
        // to turn on debug mode, in your JS console `localStorage.hoverEstimatorDebug = 'on'`
        const estimatorDebug = localStorage.getItem(EHI_ESTIMATOR_DEBUG_KEY);
        if (estimatorDebug === 'on') {
          console.log(parser.parse());
        }
        new HdfEstimatorComparator(
          hdfMeasurements,
          jobMeasurements,
          orgId,
          jobId,
        ).compare();
      } catch (error) {
        console.log(`error fetching/parsing HDF for job ${jobId}`, error);
        Sentry.captureException(error);
      }
    }

    const categories = await getCategories();

    const checklist = (
      await EstimatorApi.getChecklist({
        orgId: orgId.toString(),
        jobId: jobId?.toString() || '',
      })
    ).data.inspectionChecklist;

    dispatch(requiredInputsActions.storeRequiredInputs(requiredInputs));
    dispatch(estimatorActions.storeFullMeasurements({ fullMeasurements }));
    dispatch(estimatorActions.storeJobMeasurements({ jobMeasurements }));
    dispatch(inputCategoriesActions.storeInputCategories(categories));
    dispatch(checklistActions.storeChecklist(checklist));

    const selected = templates.filter((template: Template) =>
      currentlySelectedTemplates.includes(Number(template.id)),
    );

    const generator = EstimatorFlowGenerator.generatePages({
      inputs: requiredInputs as Input[],
      categories,
      templates,
      tradeTypes,
      jobMeasurements:
        isEnabled(COMMERCE_USE_HDF) && hdfMeasurements
          ? hdfMeasurements
          : jobMeasurements,
      selectedTemplates: selected,
      checklist,
      showOrderingVersion,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      partialMeasurements: plainJsonMeasurements,
      estimationJson: fullMeasurements,
    });
    const pages = generator.getSortedPagesWithQuestions();

    dispatch(
      pageActions.storePages({
        pages,
        isManualMeasurementsNeeded: generator.isManualMeasurementsNeeded(),
      }),
    );

    return { pages, isManualMeasurementsNeeded: !fullMeasurements };
  };

  const getPages = async (
    templates: Template[],
    currentlySelectedTemplates: number[],
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings | null,
  ) => {
    if (!templates || templates.length === 0 || !orgSettings) return;
    setIsFetchingQuestions(true);
    if (currentlySelectedTemplates && currentlySelectedTemplates.length > 0) {
      const { pages, isManualMeasurementsNeeded } = await getQuestionsAndPages(
        templates,
        currentlySelectedTemplates,
        showOrderingVersion,
        orgSettings,
      );

      const questions = pages.reduce(
        (qs: Input[], p: Page) => qs.concat(p?.questions || []),
        [],
      );

      setPristineMeasurements(questions);

      const tradeTypeToWasteFactorMap = new Map();
      const tradeTypeOrgEnums = (await EstimatorApi.getTradeTypeEnumOrgs(orgId))
        ?.data?.estimationConfigTradeTypeEnumOrgs?.nodes;

      tradeTypeOrgEnums?.forEach((tradeTypeOrgEnum: TradeTypeEnumOrg) => {
        tradeTypeToWasteFactorMap.set(
          tradeTypeOrgEnum.tradeType,
          tradeTypeOrgEnum.wasteFactor,
        );
      });

      setQuestionResponses({
        questions,
        isManualMeasurements: isManualMeasurementsNeeded,
        tradeTypeToWasteFactorMap,
      });
    }
    dispatch(estimatorActions.setupEstimatorEnd());
    setIsFetchingQuestions(false);
  };

  const setupEstimatorForRefresh = async (
    selectedTemplates: number[] | null,
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings,
  ) => {
    // fetch templates
    const templates = await getTemplates(orgId);
    // store templates
    dispatch(estimatorActions.getTemplatesEnd({ templates }));

    // get selected templates
    const currentlySelectedTemplates =
      getOrInitializeSelectedTemplates(selectedTemplates);

    // organize inputs and assign them to pages
    getPages(
      templates,
      currentlySelectedTemplates,
      showOrderingVersion,
      orgSettings,
    );
  };

  const setupEstimator = async ({
    currentlySelectedTemplates,
    templates,
    setIsInitializing,
    showOrderingVersion,
    orgSettings,
  }: {
    currentlySelectedTemplates: number[];
    templates: Template[];
    setIsInitializing: (value: boolean) => void;
    showOrderingVersion: boolean;
    orgSettings: EhiOrgSettings | null;
  }) => {
    setIsInitializing(true);
    await getPages(
      templates,
      currentlySelectedTemplates,
      showOrderingVersion,
      orgSettings,
    );
    setIsInitializing(false);
  };

  return {
    setupEstimatorForRefresh,
    setupEstimator,
    isFetchingQuestions,
  };
}
