export const ESTIMATOR_QUESTIONS_URL = '/estimator/questions';
export const ESTIMATES_URL = '/estimator/estimates';
export const ESTIMATES_FAILURE = '/estimator/estimate_load_failure';
export const ESTIMATOR = '/estimator';
export const PROPOSAL_TOOL = '/estimate_proposal';
export const ESTIMATOR_PRODUCTION = '/estimator_production';

// graphql
export const GRAPHQL_PROD = 'https://graph.hover.to';
export const GRAPHQL_STAGING = 'https://staging-graph.hover.to';
export const GRAPHQL_SANDBOX = 'https://sandbox-graph.hover.to';

// artemis endpoints
export const ARTEMIS_PROD = 'https://ehi.hover.to';
export const ARTEMIS_STAGING = 'https://staging-ehi.hover.to';

// planet terror
export const PLANET_TERROR_STAGING = 'https://staging-products.hover.to';
export const PLANET_TERROR_PROD = 'https://products.hover.to';
