import { gql } from '@apollo/client';

export const TOGGLE_VARIATIONS_MATCHING = gql`
  mutation estimationEstimateGroupUpdate(
    $estimateGroupId: ID!
    $estimateGroupAttributes: EstimationEstimateGroupUpdateInput!
  ) {
    estimationEstimateGroupUpdate(
      id: $estimateGroupId
      estimateGroupAttributes: $estimateGroupAttributes
    ) {
      estimateGroup {
        id
        enableVariationMatching
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_ESTIMATION_GROUP_NAME = gql`
  mutation estimationEstimateGroupUpdate(
    $estimateGroupId: ID!
    $estimateGroupAttributes: EstimationEstimateGroupUpdateInput!
  ) {
    estimationEstimateGroupUpdate(
      id: $estimateGroupId
      estimateGroupAttributes: $estimateGroupAttributes
    ) {
      estimateGroup {
        id
        name
      }
      errors {
        attribute
        errors
      }
    }
  }
`;
