import { PureComponent } from 'react';

import { Body, Box, Button, Heading } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { EstimateGroupForSummary_estimationEstimateGroup as EstimateGroupForSummary } from 'src/api/types/EstimateGroupForSummary';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { withTypewriter } from 'src/components/WithTypewriter';
import { EstimateGroupBottomBar } from 'src/features/exteriorEstimator/components/common/EstimateGroupBottomBar';
import { ColorConfirmationModal } from 'src/features/exteriorEstimator/components/EstimationTool/Estimates/ColorConfirmationModal';
import { toggleHasDismissedMissingVariationsWarning } from 'src/features/exteriorEstimator/redux/actions/estimateGroupActions';
import {
  getParams,
  getEstimateGroupIdFromLocation,
  getRouter,
  getHasDismissedMissingVariationsWarning, // make all these be utils and pass in groups
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getAreSelectedVariationsMissing } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { getEstimateGroupDetailsUrl } from 'src/features/exteriorEstimator/utils/miscUtils';
import {
  getUserTrackingProps,
  isUserLightWeightFlow,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  estimateGroupId: getEstimateGroupIdFromLocation(state),
  router: getRouter(state),
  isLightWeightFlow: isUserLightWeightFlow(state),
  hasDismissedMissingVariationsWarning:
    getHasDismissedMissingVariationsWarning(state),
  commonProps: getUserTrackingProps(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pushMethod: push,
      dismissMissingVariationsWarning:
        toggleHasDismissedMissingVariationsWarning,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    estimateGroup: EstimateGroupForSummary;
    hideTotalPrice: boolean;
    typewriter: any;
  };

class EstimateGroupFooterComponent extends PureComponent<Props> {
  state = {
    shouldShowColorConfirmationModal: false,
  };

  @autobind
  handleClickDone() {
    const {
      pushMethod,
      jobId,
      router,
      hasDismissedMissingVariationsWarning,
      estimateGroup,
    } = this.props;

    if (!jobId || !estimateGroup) return;
    const areVariationsMissing = getAreSelectedVariationsMissing(
      estimateGroup.estimates,
    );

    if (areVariationsMissing && !hasDismissedMissingVariationsWarning) {
      // if no color is selected, show confirmation modal to confirm user wants to proceed without color
      this.setShouldShowColorConfirmationModal(true);
      return;
    }

    pushMethod({
      pathname: `${router.location.pathname}/details`,
      search: router.location.search,
    });
  }

  renderPrice() {
    const { estimateGroup } = this.props;
    const estimateGroupTotalPrice = estimateGroup.totalPrice;

    return (
      <Box flexDirection="column" alignSelf="center">
        {estimateGroupTotalPrice ? (
          <Heading size={200} marginBottom="0.25em">
            Project total:{' '}
            <FormattedNumber
              format="$0,0.00"
              value={estimateGroupTotalPrice || 0}
              data-test-id="estimatePrice"
            />
          </Heading>
        ) : (
          <></>
        )}
      </Box>
    );
  }

  conditionallyRenderPrice() {
    const { isLightWeightFlow, hideTotalPrice } = this.props;

    return !isLightWeightFlow && !hideTotalPrice ? (
      this.renderPrice()
    ) : (
      <div data-test-id="hiddenPrice" style={{ visibility: 'hidden' }} />
    );
  }

  renderMonthlyCost() {
    const { estimateGroup } = this.props;
    const monthlySum = estimateGroup.monthlyPrice;
    const showEstimateMonthlyCost = monthlySum && monthlySum !== 0;

    return (
      <Box flexDirection="column" alignSelf="center">
        {showEstimateMonthlyCost && monthlySum ? (
          <Body as="span" color="neutral.600" margin="0">
            As low as <FormattedNumber value={monthlySum} format="$0,00.00" />
            /mo
          </Body>
        ) : (
          <></>
        )}
      </Box>
    );
  }

  conditionallyRenderMonthlyCost() {
    const { isLightWeightFlow, hideTotalPrice } = this.props;
    return !isLightWeightFlow && !hideTotalPrice ? (
      this.renderMonthlyCost()
    ) : (
      <div data-test-id="hiddenMonthlyCost" style={{ visibility: 'hidden' }} />
    );
  }

  @autobind
  setShouldShowColorConfirmationModal(shouldShow: boolean) {
    this.setState({
      shouldShowColorConfirmationModal: shouldShow,
    });
  }

  @autobind
  continueWithoutColorSelection() {
    const {
      pushMethod,
      estimateGroupId,
      jobId,
      dismissMissingVariationsWarning,
      commonProps,
      typewriter,
    } = this.props;
    dismissMissingVariationsWarning(true);

    pushMethod(
      getEstimateGroupDetailsUrl({
        jobId: Number(jobId),
        estimateGroupId: estimateGroupId as string,
      }),
    );

    typewriter.buttonPressed({
      backend_id_type: 'estimate_group_id',
      backend_id_value: estimateGroupId,
      button_text: 'Continue',
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.estimates.colorModal,
      primary_cta: true,
      ...commonProps,
    });
    this.setShouldShowColorConfirmationModal(false);
  }

  render() {
    const { estimateGroup } = this.props;
    const { shouldShowColorConfirmationModal } = this.state;
    const activeEstimates = estimateGroup.estimates.filter((est) => est.active);

    return (
      <EstimateGroupBottomBar>
        <>
          <ColorConfirmationModal
            isOpen={shouldShowColorConfirmationModal}
            handleAction={this.continueWithoutColorSelection}
            setShouldShowColorConfirmationModal={
              this.setShouldShowColorConfirmationModal
            }
          />
          <Box flex={1} justifyContent="flex-start" marginRight="auto">
            {this.conditionallyRenderPrice()}
          </Box>
          <Box flex={1} justifyContent="center" margin="auto">
            {this.conditionallyRenderMonthlyCost()}
          </Box>
          <Box flex={1} justifyContent="flex-end" marginLeft="auto">
            <Button
              size="large"
              onClick={this.handleClickDone}
              isDisabled={activeEstimates.length === 0}
              data-test-id="doneButton"
            >
              Done
            </Button>
          </Box>
        </>
      </EstimateGroupBottomBar>
    );
  }
}

export const EstimateGroupFooter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(EstimateGroupFooterComponent));
