import { Box, Image, ImageProps, Button, Icon } from '@hover/blueprint';
import { iSearch } from '@hover/icons';

import { VisibilityControlProps } from 'src/features/settings/components/Proposal/Section/ScopeOfWork/types';

const noLineItemsBase =
  'https://assets.hover.to/ehi/proposal-no-line-items-base.png';
const noLineItemsMaskTotalPrice =
  'https://assets.hover.to/ehi/proposal-no-line-items-mask-total-price.png';
const noLineItemsMaskTradePrice =
  'https://assets.hover.to/ehi/proposal-no-line-items-mask-trade-price.png';
const withLineItemsBase =
  'https://assets.hover.to/ehi/proposal-with-line-items-base.png';
const withLineItemsMaskLineItemPrice =
  'https://assets.hover.to/ehi/proposal-with-line-items-mask-line-item-price.png';
const withLineItemsMaskLineItemQuantity =
  'https://assets.hover.to/ehi/proposal-with-line-items-mask-line-item-quantity.png';
const withLineItemsMaskTotalPrice =
  'https://assets.hover.to/ehi/proposal-with-line-items-mask-total-price.png';
const withLineItemsMaskTradePrice =
  'https://assets.hover.to/ehi/proposal-with-line-items-mask-trade-price.png';

const Mask: React.FC<ImageProps> = ({ ...props }) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      justifyContent="center"
    >
      <Image height="100%" {...props} />
    </Box>
  );
};

export const PreviewMasks: React.FC<VisibilityControlProps> = ({
  includeTotalPriceDefault,
  includeTradePriceDefault,
  includeAllEstimateItemsDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
}) => {
  /*
    IF ANY LINE ITEM BOXES CHECKED 
  */
  if (includeAllEstimateItemsDefault) {
    return (
      <>
        <Mask
          src={withLineItemsBase}
          alt="withLineItemsBase"
          data-test-id="withLineItemsBase"
        />
        {includeItemCostDefault && (
          <Mask
            src={withLineItemsMaskLineItemPrice}
            alt="withLineItemsMaskLineItemPrice"
            data-test-id="withLineItemsMaskLineItemPrice"
          />
        )}
        {includeItemQuantitiesDefault && (
          <Mask
            src={withLineItemsMaskLineItemQuantity}
            alt="withLineItemsMaskLineItemQuantity"
            data-test-id="withLineItemsMaskLineItemQuantity"
          />
        )}
        {includeTotalPriceDefault && (
          <Mask
            src={withLineItemsMaskTotalPrice}
            alt="withLineItemsMaskTotalPrice"
            data-test-id="withLineItemsMaskTotalPrice"
          />
        )}
        {includeTradePriceDefault && (
          <Mask
            src={withLineItemsMaskTradePrice}
            alt="withLineItemsMaskTradePrice"
            data-test-id="withLineItemsMaskTradePrice"
          />
        )}
      </>
    );
  }
  /*
    IF NO LINE ITEM BOXES CHECKED: we show different masks because the overall layout changes
  */
  return (
    <>
      <Mask
        src={noLineItemsBase}
        alt="noLineItemsBase"
        data-test-id="noLineItemsBase"
      />

      {includeTradePriceDefault && (
        <Mask
          src={noLineItemsMaskTradePrice}
          alt="noLineItemsMaskTradePrice"
          data-test-id="noLineItemsMaskTradePrice"
        />
      )}
      {includeTotalPriceDefault && (
        <Mask
          src={noLineItemsMaskTotalPrice}
          alt="noLineItemsMaskTotalPrice"
          data-test-id="noLineItemsMaskTotalPrice"
        />
      )}
    </>
  );
};

type Props = VisibilityControlProps & {
  onClick: () => void;
};

export const PreviewImage: React.FC<Props> = ({
  includeTotalPriceDefault,
  includeTradePriceDefault,
  includeAllEstimateItemsDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
  onClick,
}) => {
  return (
    <Box
      position="relative"
      paddingBottom={600}
      height="322px"
      backgroundColor="neutral0"
    >
      <PreviewMasks
        includeAllEstimateItemsDefault={includeAllEstimateItemsDefault}
        includeItemCostDefault={includeItemCostDefault}
        includeItemQuantitiesDefault={includeItemQuantitiesDefault}
        includeTotalPriceDefault={includeTotalPriceDefault}
        includeTradePriceDefault={includeTradePriceDefault}
      />
      <Box position="absolute" bottom={1} right={1}>
        <Button
          type="button"
          fill="outline"
          onClick={onClick}
          shape="circle"
          height={22}
          width={22}
          label="preview-pdf"
        >
          <Icon icon={iSearch} />
        </Button>
      </Box>
    </Box>
  );
};
