import { Box } from '@hover/blueprint';

import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { formattedNumber } from 'src/features/project/util/utils';
import {
  COMMERCE_INLINE_EDITING_REDESIGN,
  isEnabled,
} from 'src/lib/FeatureFlag';
import {
  adjustedMeasurementWithWasteFactor,
  wasteFactorDisplay,
} from 'src/utils/estimatesUtils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

export type ListItemMeasurementsColumnProps = {
  listItem: ListItem;
};

export const ListItemMeasurementsColumn = ({
  listItem,
}: ListItemMeasurementsColumnProps) => {
  const isInlineEditingEnabled = isEnabled(COMMERCE_INLINE_EDITING_REDESIGN);
  const hasWasteFactor = !!listItem.wasteFactor;

  if (!listItem.measurement || listItem.measurement < 0) {
    return null;
  }

  const printWithWasteFactor = (measurement: number) => {
    return `${formattedNumber(measurement, null)} + ${wasteFactorDisplay(
      listItem.wasteFactor,
    )}`;
  };

  return (
    <div data-test-id="ListItemMeasurementsColumn">
      <Box>
        {formattedNumber(
          adjustedMeasurementWithWasteFactor(
            listItem.measurement,
            listItem.wasteFactor ?? 0,
          ),
          null,
          lineItemQuantityUnits(listItem.measurementUnits),
        )}
      </Box>

      {hasWasteFactor && isInlineEditingEnabled && (
        <Box fontSize={100} color="neutral.500" marginTop={200}>
          {printWithWasteFactor(listItem.measurement)}
        </Box>
      )}

      {hasWasteFactor && !isInlineEditingEnabled && (
        <Box fontSize={200} color="neutral.500">
          {printWithWasteFactor(listItem.measurement)}
        </Box>
      )}
    </div>
  );
};
