import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Button, Modal, Field, TextInput } from '@hover/blueprint';
import { iPlus } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CREATE_TEMPLATE } from 'src/features/settings/api/mutations/inspectionChecklist';
import { useTracking } from 'src/hooks';
import { getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const TemplateCreateButton = () => {
  const history = useHistory();

  const orgId = useSelector(getUserOrgId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [createTemplate, { loading }] = useMutation(CREATE_TEMPLATE, {
    variables: {
      checklistTemplateAttributes: {
        orgId,
        name: templateName,
      },
    },
    refetchQueries: ['inspectionConfigChecklistTemplates'],
    onCompleted: (data) => {
      const { id } =
        data.inspectionConfigChecklistTemplateCreate.checklistTemplate;

      history.push(`/workflows/inspection_templates/${id}`);
    },
  });

  const openModal = () => {
    setIsModalOpen(true);
    typewriter.buttonPressed({
      button_text: 'Create template',
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTemplateName('');
  };

  const createNewTemplate = async () => {
    await createTemplate();
    closeModal();
  };

  return (
    <Box>
      <Button
        fill="solid"
        onClick={openModal}
        iconBefore={iPlus}
        marginBottom={500}
      >
        Create template
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        header="New Template"
        footer={
          <>
            <Button fill="outline" size="large" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              size="large"
              isLoading={loading}
              onClick={createNewTemplate}
            >
              Add
            </Button>
          </>
        }
      >
        <Field label="Template name (required)" name="template_text">
          <TextInput
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
            isRequired
          />
        </Field>
      </Modal>
    </Box>
  );
};
