import { useDispatch } from 'react-redux';

import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import {
  storeMetadata,
  storeGeometry,
} from 'src/features/exteriorEstimator/redux/actions/measurementsActions';
import { emitPageAction, NewRelicEventType } from 'src/utils/newrelic/page';

export function useMachete() {
  const dispatch = useDispatch();

  const initializeMacheteData = (geometryUrl: string, metadataUrl: string) => {
    const geometryPromise = EstimatorApi.getMacheteJSON(geometryUrl);
    const metadataPromise = EstimatorApi.getMacheteJSON(metadataUrl);
    Promise.all<any>([geometryPromise, metadataPromise]).then((values) => {
      emitPageAction(NewRelicEventType.GEOMETRY_AND_METADATA_LOADED);
      dispatch(storeGeometry({ geometry: (values[0] as any).data }));
      dispatch(storeMetadata({ metadata: (values[1] as any).data }));
    });
  };

  return { initializeMacheteData };
}
