import React, {
  useEffect,
  useState,
  forwardRef,
  MouseEvent as ReactMouseEvent,
  useRef,
  KeyboardEvent,
} from 'react';

import { Box, Input, Select, Tooltip } from '@hover/blueprint';
import { useEditableState, useEditableControls } from '@hover/blueprint/chakra';
import { get, isNil, isEmpty } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import type {
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
  projectManagementProductionList_projectManagementProductionList_listItems_product as Product,
} from 'src/api/types/projectManagementProductionList';

import { useProjectScopeTracker } from '../../../hooks/useProjectScopeTracker';

const CUSTOM_VARIANT = 'Custom variant';

export const CUSTOM_VARIANT_COLOR = {
  name: CUSTOM_VARIANT,
  id: CUSTOM_VARIANT,
};

type EditableVariationSelectProps = {
  listItem: ListItem;
  isUpdating: boolean;
  jobId: number;
};

type Color = {
  id: string;
  name: string;
};

export const EditableVariationSelect: React.FC<EditableVariationSelectProps> =
  forwardRef<HTMLDivElement, EditableVariationSelectProps>(
    ({ listItem, isUpdating, jobId }, ref) => {
      const variationSelectInput = useRef<HTMLSelectElement>(null);
      const hasExternalVariationId = !isEmpty(listItem.externalVariationId);

      const {
        control,
        register,
        trigger,
        setValue,
        getValues,
        formState: { errors: formErrors },
      } = useFormContext();
      const formExternalVariationId = getValues().externalVariationId;
      const { trackInlineEditingInputPressed } = useProjectScopeTracker({
        jobId,
      });

      const editableState = useEditableState();

      const [colorOptions, setColorOptions] = useState<Color[]>([
        CUSTOM_VARIANT_COLOR,
      ]);

      const showCustomVariationField =
        (formExternalVariationId === CUSTOM_VARIANT_COLOR.name ||
          !!listItem.userSetCustomColor) &&
        editableState.isEditing;

      const handleTracking = () => {
        trackInlineEditingInputPressed('Variation Name');
      };

      const handleColorChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
        onChange: (value: string) => void,
      ) => {
        const { value } = e.target;

        if (value === CUSTOM_VARIANT_COLOR.name) {
          onChange(value);
          setValue('userSetCustomColor', true);
          setValue('color', null, {
            shouldValidate: true,
            shouldDirty: true,
          });
          return;
        }

        const product: Product = getValues('product');

        if (!product) {
          return;
        }

        const variation = product.variations.find(
          (suggestionVariation) => suggestionVariation.id === value,
        );

        if (!variation) {
          return;
        }

        onChange(value);
        setValue('userSetCustomColor', false);
        setValue('color', variation.name, {
          shouldValidate: true,
          shouldDirty: true,
        });
      };

      const shouldCancelBlurBasedOnTarget = (
        e:
          | React.FocusEvent<HTMLInputElement>
          | React.FocusEvent<HTMLSelectElement>,
      ): boolean =>
        e.relatedTarget?.tagName.toLowerCase() === 'select' ||
        (e.target.tagName.toLowerCase() === 'select' &&
          e.relatedTarget?.tagName.toLowerCase() === 'input' &&
          formExternalVariationId === CUSTOM_VARIANT_COLOR.name);

      const cancelEditing = () => {
        editableState.onCancel();
      };

      const submit = () => {
        if (get(formErrors, 'color')) {
          return;
        }

        editableState.onSubmit();
      };

      const handleSelectBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
        const { value } = e.target;

        if (
          value === CUSTOM_VARIANT_COLOR.name &&
          shouldCancelBlurBasedOnTarget(e)
        ) {
          return;
        }

        // Avoid calling update when the selected value was already set.
        if (value === listItem.externalVariationId) {
          cancelEditing();
          return;
        }

        submit();
      };

      const handleCustomVariationInputBlur = (
        e: React.FocusEvent<HTMLInputElement>,
      ) => {
        const { value } = e.target;

        if (isUpdating || shouldCancelBlurBasedOnTarget(e)) {
          return;
        }

        if (value === listItem.color) {
          cancelEditing();
          return;
        }

        submit();
      };

      const handleCustomVariationInputChange = (
        e: React.FocusEvent<HTMLInputElement>,
        onChange: (value: string) => void,
      ) => {
        const { value } = e.target;

        setValue('requiresProductVariationSelection', false);
        setValue('userSetCustomColor', true);
        setValue('color', value, {
          shouldValidate: true,
          shouldDirty: true,
        });
        onChange(value);
      };

      const handleCustomVariationInputKeyUp = (
        e: KeyboardEvent<HTMLInputElement>,
      ): void => {
        if (e.key.toLowerCase() !== 'enter') {
          return;
        }

        submit();
      };

      useEffect(() => {
        if (!editableState.isEditing) {
          return;
        }

        // Pre-fill with available product variations
        if (
          listItem.product &&
          listItem.product.variations &&
          listItem.product.variations.length > 0 &&
          colorOptions.length !== listItem.product.variations.length + 1
        ) {
          setColorOptions([
            ...listItem.product.variations,
            CUSTOM_VARIANT_COLOR,
          ]);
        }

        // Validate the custom variation name field when switching to editing mode.
        if (!!listItem.userSetCustomColor && !hasExternalVariationId) {
          trigger('color', { shouldFocus: true });
        }

        // Validate and focus the custom variation name field when changing variation to "Custom variant".
        if (formExternalVariationId === CUSTOM_VARIANT_COLOR.name) {
          trigger('color', { shouldFocus: true });
        }

        // Focus the variation selection Select field when not in "Custom variant" mode.
        if (
          formExternalVariationId !== CUSTOM_VARIANT_COLOR.name &&
          !listItem.userSetCustomColor
        ) {
          variationSelectInput.current?.focus();
        }
      }, [
        listItem,
        editableState.isEditing,
        colorOptions.length,
        formExternalVariationId,
        hasExternalVariationId,
        trigger,
      ]);

      return (
        <Box
          as="span"
          display={editableState.isEditing ? 'flex' : 'none'}
          flexDirection="column"
          ref={ref}
        >
          <Controller
            control={control}
            name="externalVariationId"
            render={({
              field: { onChange: onControllerChange, value: variationIdValue },
            }) => (
              <Select
                data-test-id="EditLineItemColor"
                // isInvalid={!!get(formErrors, 'color.message')}
                fontSize="inherit"
                onBlur={handleSelectBlur}
                onChange={(e) => {
                  handleTracking();
                  handleColorChange(e, onControllerChange);
                }}
                ref={variationSelectInput}
                size="tiny"
                value={variationIdValue}
              >
                {(isNil(formExternalVariationId) ||
                  formExternalVariationId.length === 0) &&
                formExternalVariationId !== CUSTOM_VARIANT_COLOR.name &&
                !(
                  isNil(listItem.externalVariationId) &&
                  !!listItem.userSetCustomColor
                ) ? (
                  <option key={null} value="">
                    Select variant
                  </option>
                ) : null}
                {colorOptions.map((colorOption) => {
                  return (
                    <option key={colorOption.id} value={colorOption.id}>
                      {colorOption.name}
                    </option>
                  );
                })}
              </Select>
            )}
          />

          {showCustomVariationField && (
            <Controller
              control={control}
              name="color"
              render={({ field: { onChange: onControllerChange, value } }) => {
                return (
                  <Tooltip
                    label={get(formErrors, 'color.message')}
                    placement="bottom"
                    background="danger300"
                  >
                    <Input
                      {...register('color', {
                        required: 'Custom variant is required',
                      })}
                      isInvalid={!!get(formErrors, 'color')}
                      size="tiny"
                      marginTop={1}
                      data-test-id="AddMaterial-customVariant"
                      placeholder="Enter custom variant name"
                      onKeyUp={handleCustomVariationInputKeyUp}
                      onBlur={handleCustomVariationInputBlur}
                      onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                        handleTracking();
                        handleCustomVariationInputChange(e, onControllerChange);
                      }}
                      defaultValue={value}
                      ref={(input) => {
                        // The ref for a ReactHookForm element is required to be regsitered
                        // with that framework, so to use ref functions like `focus()` or `select()`, we
                        // must use the ref provided by RHF.  This focuses on the Custom Variant
                        // when that field becomes visible.
                        if (!!input) {
                          input.focus();
                        }
                      }}
                    />
                  </Tooltip>
                );
              }}
            />
          )}
        </Box>
      );
    },
  );
