import { useEffect } from 'react';

import { Box, Body, Button, Icon, Heading } from '@hover/blueprint';
import { iArrowRight } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

interface Props {
  handleClick: () => void;
}

export const EarlyAccess: React.FC<Props> = ({ handleClick }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: 'Sales Presentation Index Admin',
      ...commonTrackingProps,
    });
  }, []);

  return (
    <Box flexDirection="column" alignItems="center">
      <Heading size={600} marginY="0" padding="0">
        Sign up for early access
      </Heading>
      <Heading size={600} marginY="0" padding="0">
        to Presentation tool today.
      </Heading>
      <Body size={600} m={0}>
        One app for everything your Sales team needs, now including the
        presentation tool.
      </Body>
      <Button size="large" marginTop={600} onClick={handleClick}>
        Get early access
        <Icon ml={200} icon={iArrowRight} color="white" />
      </Button>
    </Box>
  );
};
