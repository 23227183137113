import { useSelector } from 'react-redux';

import { getIsEstimateGroupSold } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getCanViewProductionConsole } from 'src/redux/selectors';

import { ChangeSoldStatusModal } from './ChangeSoldStatusModal';
import { Modal } from './styled';
import { UserCannotChangeStatus } from './UserCannotChangeStatus';

type Props = {
  setShowModal: (show: boolean) => void;
  isOpen: boolean;
};

export const SalesOpModal: React.FC<Props> = ({ setShowModal, isOpen }) => {
  const canViewProductionConsole = useSelector(getCanViewProductionConsole);
  const isEstimateGroupSold = useSelector(getIsEstimateGroupSold);

  const renderModals = () => {
    if (isEstimateGroupSold) {
      if (canViewProductionConsole) {
        return <ChangeSoldStatusModal setShowModal={setShowModal} isSold />;
      }
      return <UserCannotChangeStatus setShowModal={setShowModal} />;
    }
    return <ChangeSoldStatusModal setShowModal={setShowModal} />;
  };

  return (
    <Modal data-test-id="sales-op-modal" isOpen={isOpen} padding="32px">
      {renderModals()}
    </Modal>
  );
};
