import { useEffect } from 'react';

import { Box, Field } from '@hover/blueprint';
import { useFormContext } from 'react-hook-form';

import type { SelectProps } from './Select';
import { Select } from './Select';

/**
 *
 * Custom EHI Select or Text component.
 *
 */
export const SelectOrText = <
  TDisplayProperty extends string,
  TValueProperty extends string,
>({
  options = [],
  value,
  defaultValue,
  valueProperty,
  displayProperty,
  label = '',
  name = '',
  onChange,
  error,
  isDisabled,
  isRequired,
  sx,
  rules,
  ...props
}: SelectProps<TDisplayProperty, TValueProperty>) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const isText = options.length <= 1;

  // For the text-only case, , set the initial value of the MUI Select component in the react-hook-form
  // field for validation, via the form element name of the component's hidden input.
  useEffect(() => {
    if (isText) {
      //  (Unusual typing is due to RHF string literal types.)
      setValue(`${name}` as const, options[0]?.[valueProperty]);
    }
  }, [isText, name, options, setValue, valueProperty]);

  return isText ? (
    // Read-only text element that matches spacing of TextInput,
    // plus hidden input field for form value.
    <Field
      flex={1}
      marginRight={500}
      label={label}
      name={name}
      error={error || !!errors[name] ? errors[name]?.message : undefined}
      isDisabled={isDisabled}
    >
      <>
        <Box
          paddingTop={300}
          paddingRight={500}
          paddingBottom={0}
          paddingLeft={400}
          id={`${name}-label`}
        >
          {options[0]?.[displayProperty] ?? ''}
        </Box>
        <input
          id={name}
          type="hidden"
          //  (Unusual typing is due to RHF string literal types.)
          {...register(`${name}` as const, {
            ...(isRequired ? { required: `${label} is required` } : {}),
            ...rules,
          })}
          value={options[0]?.[valueProperty]}
        />
      </>
    </Field>
  ) : (
    <Select
      {...{
        options,
        value,
        defaultValue,
        valueProperty,
        displayProperty,
        label,
        name,
        onChange,
        error,
        isDisabled,
        isRequired,
        sx,
        rules,
      }}
      {...props}
    />
  );
};
