import { Icon, IconProps } from '@hover/blueprint';
import { forwardRef } from '@hover/blueprint/chakra';
import { iAlertTriangle, iAlertCircle } from '@hover/icons';

type ItemStatusIconProps = Omit<IconProps, 'icon' | 'color' | 'size'>;

export const ItemStatusIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon size="small" margin={0} alignSelf="center" ref={ref} {...props} />
  );
});

export const ItemWarningIcon = forwardRef((props: ItemStatusIconProps, ref) => {
  return (
    <ItemStatusIcon
      color="warning500"
      icon={iAlertTriangle}
      ref={ref}
      {...props}
    />
  );
});

export const ItemErrorIcon = forwardRef((props: ItemStatusIconProps, ref) => {
  return (
    <ItemStatusIcon
      color="danger500"
      icon={iAlertCircle}
      ref={ref}
      {...props}
    />
  );
});
