import React, { useMemo } from 'react';

import { Box, Body, Heading } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { getSelectedTemplateIds } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { replaceUnderscoresWithSpace } from 'src/features/project/util/utils';

import { TemplateCard } from './TemplateCard';

const TemplateCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

type Props = {
  templateCollection: TemplateSection;
  handleCardClick: (
    template: Template,
    templateTradeType: TradeTypeEnum,
  ) => void;
  searchFilterValue: string;
};

export const TemplateCardsSection = ({
  templateCollection,
  handleCardClick,
  searchFilterValue,
}: Props) => {
  const selectedTemplatesIds = useSelector(getSelectedTemplateIds) || [];
  const templatesByTrade = templateCollection?.templatesByTradeType || [];

  const filterBySearchFilter = (template: Template) => {
    return template.name
      .toLowerCase()
      .includes(searchFilterValue.toLowerCase());
  };

  // memoize total template count for all templates within
  // templateByTradeType that match the search filter value
  const totalTemplateCount = useMemo(() => {
    return templatesByTrade.reduce((accumulatorNumber, trade) => {
      const filteredTemplates = trade.templates.filter(filterBySearchFilter);
      return accumulatorNumber + filteredTemplates.length;
    }, 0);
  }, [templatesByTrade, searchFilterValue]);

  return (
    <>
      <Box display="flex">
        <Heading marginBottom={0} marginTop={600} size={400} fontWeight={400}>
          {templateCollection?.name}
        </Heading>
        <Heading
          marginTop={600}
          marginBottom={0}
          marginLeft={400}
          size={400}
          fontWeight={400}
          color="neutral.500"
        >
          {totalTemplateCount}
        </Heading>
      </Box>

      <div>
        {templatesByTrade.map((trade) => {
          // filter templates by search filter value
          const filteredTemplates =
            trade.templates.filter(filterBySearchFilter) || [];

          if (filteredTemplates.length === 0) {
            return null;
          }
          return (
            <React.Fragment key={`${trade.tradeType}-template-card-section`}>
              <Body
                fontWeight={700}
                size={{ base: 400, tablet: 200 }}
                marginBottom={0}
              >
                {replaceUnderscoresWithSpace(trade.tradeType)}
              </Body>

              <TemplateCardContainer>
                {filteredTemplates.map((template) => {
                  const isSelected = selectedTemplatesIds.includes(template.id);
                  return (
                    <TemplateCard
                      key={`${template.id}-card`}
                      template={template}
                      handleCardClick={() =>
                        handleCardClick(template, template.tradeType)
                      }
                      isSelected={isSelected}
                    />
                  );
                })}
              </TemplateCardContainer>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
