import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { productCatalogCategories } from 'src/api/types/productCatalogCategories';
import { PRODUCT_CATALOG_CATEGORIES } from 'src/features/settings/api/queries/calculationRules';
import { setCategories } from 'src/features/settings/redux/actions';
import { getPaginatedGql } from 'src/utils/PaginatedGqlUtils';

export function useProductCatalogCategories() {
  const dispatch = useDispatch();

  const fetchProductCatalogCategories = useCallback(
    async (orgId: string) => {
      const res: productCatalogCategories | null =
        await getPaginatedGql<productCatalogCategories>({
          query: PRODUCT_CATALOG_CATEGORIES,
          variables: {
            orgId,
          },
          typeName: 'productCatalogCategories',
        });
      const results = res!.productCatalogCategories.edges!.map((e) => e!.node!);

      if (results && results.length > 0) dispatch(setCategories(results));
    },
    [dispatch],
  );

  return { fetchProductCatalogCategories };
}
