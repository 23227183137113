import { AlphaNumericConfig } from '../types';

/**
 * isAlphaNumericDash() -> boolean
 *   @param value: String
 *   Uses RegExp to test if value contains only alpha-numeric characters and dashes (`-`)
 */

const defaultConfig = {
  allowedChars: [],
};

export const isAlphaNumeric =
  ({ allowedChars }: AlphaNumericConfig = defaultConfig) =>
  (value: string) => {
    const allowedCharsPattern = allowedChars.length
      ? `\\${allowedChars.join('\\')}`
      : '';
    const pattern = `^$|^[a-z0-9${allowedCharsPattern}]+$`;
    const regExp = new RegExp(pattern, 'i');
    return regExp.test(value);
  };

export const isNumeric = (value = ''): boolean => {
  const numericRegExp = /^\d+$/;
  return numericRegExp.test(value);
};

// Returns object with boolean `valid` and optional `error` string.
export const isValidLength =
  (maxLength: number | string = 50) =>
  (value = ''): { valid: boolean; error?: string } => {
    const isValid = value.length <= maxLength;
    const result = isValid
      ? { valid: true }
      : { valid: false, error: `must be ${maxLength} characters or less` };
    return result;
  };

export const hasNoEmojis = (text = '') => {
  const emojiRegExp =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/;
  return !text.match(emojiRegExp);
};

export const isEmpty = (value = '') => !value.length;
