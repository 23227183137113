import { useMutation } from '@apollo/client';
import { LoadingOverlay } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { salesOpportunityUpdate } from 'src/api/types/salesOpportunityUpdate';
import { GET_ESTIMATE_GROUP } from 'src/features/exteriorEstimator/apis/queries';
import { SALES_OPPORTUNITY_UPDATE } from 'src/features/exteriorEstimator/apis/queries/salesOpportunities';
import {
  getParams,
  getEstimateGroupIdFromLocation,
  getSalesOpportunity,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { getVariationsFilter, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { Modal } from './Modal';

type Props = {
  setShowModal: (show: boolean) => void;
  isSold?: boolean;
};

export const ChangeSoldStatusModal: React.FC<Props> = ({
  isSold,
  setShowModal,
}) => {
  const salesOpportunity = useSelector(getSalesOpportunity);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { jobId } = useSelector(getParams);

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.changeSoldStatusModal.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  });

  const [updateSalesOpportunity, { loading }] =
    useMutation<salesOpportunityUpdate>(SALES_OPPORTUNITY_UPDATE, {
      onCompleted: () => {
        setShowModal(false);
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP,
          variables: {
            id: estimateGroupId,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    });

  const handleAction = () => {
    if (!estimateGroupId || !salesOpportunity) return;
    updateSalesOpportunity({
      variables: {
        id: salesOpportunity.id.toString(),
        salesOpportunityAttributes: {
          soldEstimateGroupId: isSold ? null : estimateGroupId,
        },
      },
    });
    const text = isSold
      ? EventNames.estimator.estimateDetailsScreen.markAsSold
          .markedAsSoldRemovePressed
      : EventNames.estimator.estimateDetailsScreen.markAsSold
          .markAsSoldConfirmPressed;
    typewriter.buttonPressed({
      button_text: text,
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  };

  const handleCancel = () => {
    const text = isSold
      ? EventNames.estimator.estimateDetailsScreen.markAsSold
          .markedAsSoldCancelPressed
      : EventNames.estimator.estimateDetailsScreen.markAsSold
          .markAsSoldNotYetPressed;
    typewriter.buttonPressed({
      button_text: text,
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
    setShowModal(false);
  };

  return (
    <>
      <LoadingOverlay isLoading={loading} />
      {isSold ? (
        <Modal
          title="Estimate Marked as Sold"
          text="Sold estimates can be accessed on the project list dashboard"
          actionColor="borderError"
          actionText="Remove"
          cancelText="Cancel"
          handleAction={handleAction}
          handleCancel={handleCancel}
        />
      ) : (
        <Modal
          title="Mark as Sold?"
          text="Only Sold estimates can be accessed by Production console. You can only mark
      one estimate as Sold"
          actionColor="actionGreen"
          actionText="Confirm as Sold"
          cancelText="Not Sold Yet"
          handleAction={handleAction}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};
