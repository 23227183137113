import { useCallback } from 'react';

import { productCatalogMatchGroups } from 'src/api/types/productCatalogMatchGroups';
import { MATCH_GROUPS } from 'src/features/settings/api/queries/calculationRules';
import { getPaginatedGql } from 'src/utils/PaginatedGqlUtils';

export function useMatchGroups() {
  const fetchMatchGroups = useCallback(async (orgId: string) => {
    const res: productCatalogMatchGroups | null =
      await getPaginatedGql<productCatalogMatchGroups>({
        query: MATCH_GROUPS,
        variables: {
          orgId,
        },
        typeName: 'productCatalogMatchGroups',
      });
    const results = res!.productCatalogMatchGroups.edges!.map((e) => e!.node!);

    return results;
  }, []);

  return { fetchMatchGroups };
}
