import { Input } from '../types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/order
import { TradeTypeEnum } from 'src/api/graphql-global-types';
// organize questions by trade type. Includes questions in multiple trade types
export const generateMeasurementQuestions = (questions: Input[]) => {
  const questionsByTrade: {
    [key in TradeTypeEnum]?: Input[];
  } = {};
  if (!questions) return {};
  questions.forEach((question) => {
    question.tradeTypes?.forEach((trade) => {
      if (Array.isArray(questionsByTrade[trade])) {
        questionsByTrade[trade]?.push(question);
      } else {
        questionsByTrade[trade] = [question];
      }
    });
  });
  return questionsByTrade;
};
