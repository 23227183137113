import { useState, useMemo } from 'react';

import { Box } from '@hover/blueprint';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '@hover/tokens-brand/assets/logo-mark--primary.svg';
import { useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  RouteProps,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';

import { EHIHoverHeader as HoverHeader } from 'src/components/EHIHoverHeader';
import { ToastNotification } from 'src/components/ToastNotification';
import type { SeverityKeys } from 'src/components/ToastNotification';
import { Sidebar } from 'src/features/settings/components/Sidebar/Sidebar';
import { useEffectOnMount } from 'src/hooks';
import {
  QUANTITY_TAKEOFF,
  UNIT_COST_IN_LINE_EDIT_CHANGE_LOG,
  PA_SALES_PRES_PDF_UPLOAD,
  isEnabled,
  COMMERCE_INCLUDE_OPENINGS_LESS_THAN_33_SQFT,
} from 'src/lib/FeatureFlag';
import {
  getIsJobManager,
  getIsOrgAdmin,
  getMaterialListFeature,
} from 'src/redux/selectors';

const SettingsContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral000};
`;

export enum AccessLevel {
  ADMIN = 'ADMIN',
  JOB_MANAGER = 'JOB_MANAGER',
  PRO_PLUS = 'PRO_PLUS',
}

export interface RouteConfigRoute {
  path: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  routes?: RouteConfigRoute[];
  exact?: boolean;
  accessLevel?: AccessLevel;
}

export type NotificationProps = {
  show: boolean;
  severity: SeverityKeys;
  notification: string;
  clearError?: () => void;
};

export interface SettingsSection {
  title: string;
  url: string;
  description: string;
  logo: string;
  color: string;
  isNew?: boolean;
  accessLevel?: AccessLevel;
}

export type NotificationHandlers = {
  setNotification: (notification: NotificationProps) => void;
  clearNotification?: () => void;
};

type Props = RouteProps & { routes: RouteConfigRoute[] };

const AdminOnly: React.FC = ({ children, ...rest }) => {
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const hasMaterialListFeatureEnabled = useSelector(getMaterialListFeature);

  const canAccess = useMemo(
    () => isOrgAdmin || hasMaterialListFeatureEnabled,
    [isOrgAdmin, hasMaterialListFeatureEnabled],
  );

  return (
    <Route
      {...rest}
      render={() => {
        return canAccess ? children : <Redirect to="/estimates" />;
      }}
    />
  );
};

const AdminAndManager: React.FC = ({ children, ...rest }) => {
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const isJobManager = useSelector(getIsJobManager);
  const hasMaterialListFeatureEnabled = useSelector(getMaterialListFeature);

  const canAccess = useMemo(
    () => isOrgAdmin || isJobManager || hasMaterialListFeatureEnabled,
    [isOrgAdmin, isJobManager, hasMaterialListFeatureEnabled],
  );

  return (
    <Route
      {...rest}
      render={() => {
        return canAccess ? children : <Redirect to="/workflows" />;
      }}
    />
  );
};

export const Settings: React.FC<Props> = ({ routes }) => {
  const location = useLocation();
  const match = useRouteMatch();

  const [notification, setNotificationState] = useState<NotificationProps>({
    show: false,
    severity: 'Info',
    notification: '',
  });
  const [sections, setSections] = useState<SettingsSection[] | null>(null);
  const [tabIndex, setTabIndex] = useState<number | null>(null);

  useEffectOnMount(() => {
    const settingsSections = [
      {
        description:
          'Manage product variations Sales and Production team members use to sell or order materials.',
        logo: 'https://assets.hover.to/icons/PNG/workflows-material.png',
        url: '/workflows/materials',
        title: 'Materials',
        color: 'brandBrown',
        accessLevel: AccessLevel.ADMIN,
      },
      {
        description:
          'Create and edit proposal templates for Sales team members.',
        logo: 'https://assets.hover.to/icons/PNG/workflows-proposal.png',
        url: '/workflows/proposal',
        title: 'Proposal',
        color: 'brandYellow',
        accessLevel: AccessLevel.ADMIN,
      },
      ...(isEnabled(UNIT_COST_IN_LINE_EDIT_CHANGE_LOG)
        ? [
            {
              description:
                'Keep pricing up to date and see history of changes over time.',
              logo: 'https://assets.hover.to/icons/PNG/workflows-lineitems.png',
              url: '/workflows/line_items',
              title: 'Line Items',
              color: 'brandGreen',
              accessLevel: AccessLevel.ADMIN,
            },
          ]
        : []),
      {
        description:
          'Manage visibility and content of templates for material and labor calculations.',
        logo: 'https://assets.hover.to/icons/PNG/workflows-estimate-templates.png',
        url: '/workflows/template_management',
        title: 'Templates',
        color: 'primary',
        accessLevel: AccessLevel.ADMIN,
      },
      ...(isEnabled(QUANTITY_TAKEOFF)
        ? [
            {
              description:
                'Create and manage takeoff templates for material ordering.',
              logo: 'https://assets.hover.to/icons/PNG/workflows-takeoff.png',
              url: '/workflows/templates',
              title: 'Takeoffs',
              color: 'brandOrange',
              accessLevel: AccessLevel.ADMIN,
            },
          ]
        : []),
      ...(isEnabled(COMMERCE_INCLUDE_OPENINGS_LESS_THAN_33_SQFT)
        ? [
            {
              description: 'Manage calculation accuracy.',
              logo: 'https://assets.hover.to/icons/PNG/workflows-calculation-preferences.png',
              url: '/workflows/calculation_preferences',
              title: 'Calculation Preferences',
              color: 'primary',
              accessLevel: AccessLevel.ADMIN,
            },
          ]
        : []),
      {
        description: 'Create and edit checklist templates for team members.',
        logo: 'https://assets.hover.to/icons/PNG/workflows-inspection-checklist.png',
        url: '/workflows/inspection_templates',
        title: 'Checklist Templates',
        color: 'danger',
        isNew: true,
        accessLevel: AccessLevel.JOB_MANAGER,
      },
      {
        description:
          'Customize and manage preferences for product and color grouping and matching.',
        logo: 'https://assets.hover.to/icons/PNG/workflows-rules.png',
        url: '/workflows/color_grouping_rules',
        title: 'Color Grouping Rules',
        color: 'primary',
        accessLevel: AccessLevel.ADMIN,
      },
      ...(isEnabled(PA_SALES_PRES_PDF_UPLOAD)
        ? [
            {
              description:
                'One app for everything your Sales team needs, now including the presentation tool.',
              logo,
              url: '/workflows/sales_presentation',
              title: 'Sales Presentation',
              color: 'primary',
              isNew: true,
              accessLevel: AccessLevel.PRO_PLUS,
            },
          ]
        : []),
    ];
    setSections(settingsSections);
  });

  const handleTabsChange = (index: number | null) => {
    setTabIndex(index);
  };

  const clearNotification = () => {
    setNotificationState({
      show: false,
      severity: 'Info',
      notification: '',
    });
  };

  // Always clear notification after it has displayed; inject callback when setting.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setNotification = (notification: NotificationProps) => {
    setNotificationState({ ...notification, clearError: clearNotification });
  };

  /* Static settings page template elements. */
  return (
    <HoverHeader>
      {/* Toast notification instance shared by sub-route components. */}
      <ToastNotification
        show={notification.show}
        notification={notification.notification}
        severity={notification.severity}
        clearError={notification.clearError}
        autohide
      />
      <SettingsContainer flexDirection="row" flex={1}>
        <Sidebar
          sections={sections}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
        <Box
          flex="1 1 auto"
          flexDirection="column"
          paddingX={900}
          paddingY={700}
        >
          {/* Dynamic route-based content view elements. */}
          <AdminOnly>
            <Switch location={location}>
              {routes.map((route) => {
                // skip non-admin routes
                if (route.accessLevel !== AccessLevel.ADMIN) return null;
                return (
                  // Provide notification-handling funcs to sub-route components.
                  <Route
                    exact={route.exact}
                    path={`${match.path}${route.path}`}
                    key={route.path}
                    render={(props) => (
                      <route.component
                        {...props}
                        routes={route.routes}
                        setNotification={setNotification}
                        clearNotification={clearNotification}
                        sections={sections}
                        handleTabsChange={handleTabsChange}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </AdminOnly>
          <AdminAndManager>
            <Switch location={location}>
              {routes.map((route) => {
                // skip admin routes
                if (route.accessLevel === AccessLevel.ADMIN) return null;
                return (
                  // Provide notification-handling funcs to sub-route components.
                  <Route
                    exact={route.exact}
                    path={`${match.path}${route.path}`}
                    key={route.path}
                    render={(props) => (
                      <route.component
                        {...props}
                        routes={route.routes}
                        setNotification={setNotification}
                        clearNotification={clearNotification}
                        sections={sections}
                        handleTabsChange={handleTabsChange}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </AdminAndManager>
        </Box>
      </SettingsContainer>
    </HoverHeader>
  );
};
