import { useState } from 'react';

import { Box, Body, Icon } from '@hover/blueprint';
import { iCalendar } from '@hover/icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export interface Props {
  minDate?: Date;
  disabledDates?: number[];
  onChange: (date: Date) => void;
  disabled?: boolean;
}

export const DeliveryDate: React.FC<Props> = ({
  minDate = new Date(),
  disabledDates = [0, 6],
  onChange,
  disabled,
}) => {
  const [shouldShowCalendar, toggleShouldShowCalendar] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleChange = (date: Date) => {
    if (disabled) return;
    toggleShouldShowCalendar(false);
    onChange(date);
    setSelectedDate(date);
  };

  const handleClick = () => {
    if (disabled) return;
    toggleShouldShowCalendar(!shouldShowCalendar);
  };

  return (
    <Box flexDirection="column" data-test-id="delivery-date">
      <Box position="relative" data-test-id="calendar-dropdown-container">
        <Box
          position="relative"
          paddingY="12px"
          paddingX="16px"
          maxHeight="48px"
          maxWidth="170px"
          alignItems="center"
          cursor={disabled ? 'auto' : 'pointer'}
          marginBottom="24px"
          border="1px solid"
          borderColor="neutral.500"
          borderRadius="3.1111px"
          backgroundColor={disabled ? 'neutral.200' : 'neutral.0'}
          data-test-id="date-input-text-container"
          onClick={handleClick}
        >
          <Icon
            icon={iCalendar}
            color="neutral600"
            size="small"
            margin={0}
            marginRight={200}
            data-test-id="date-input-text-icon"
          />
          <Body size={400} margin={0} data-test-id="date-input-text-value">
            {!!selectedDate ? selectedDate.toDateString() : 'Select date'}
          </Body>
        </Box>
        {shouldShowCalendar && (
          <Box
            position="absolute"
            left={0}
            top={46}
            data-test-id="calendar-dropdown-wrapper"
            zIndex="+1"
          >
            <Calendar
              onChange={handleChange}
              value={selectedDate}
              minDate={minDate}
              tileDisabled={({ date }: { date: Date }) =>
                disabledDates.some(
                  (disabledDate) => date.getDay() === disabledDate,
                )
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
