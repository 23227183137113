import { useState } from 'react';

import { Button, Form, FormProps, Icon } from '@hover/blueprint';
import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
} from '@hover/blueprint/chakra';
import { iSearch, iXCircle } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface SearchProps {
  onSubmit: (search: string) => void;
  initialValue?: string;
  onClear: () => void;
}

export const Search = ({ onSubmit, initialValue, onClear }: SearchProps) => {
  const [searchValue, setSearchValue] = useState(initialValue ?? '');

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const searchClear = () => {
    if (searchValue.length > 0) {
      setSearchValue('');
      onClear();
    }
  };

  const onInputChange: InputProps['onChange'] = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const onSearchSubmit: FormProps['onSubmit'] = (event) => {
    event.preventDefault();

    typewriter.textInput({
      input_value: searchValue,
      input_label: 'Search',
      page_or_screen_name: EventNames.settings.lineItems.page,
      ...commonTrackingProps,
    });

    onSubmit(searchValue);
  };

  return (
    <Form onSubmit={onSearchSubmit}>
      <InputGroup size="default">
        <Input
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={onInputChange}
          data-test-id="LineItemsSearch"
        />
        <InputRightElement>
          {searchValue.length > 0 ? (
            <Button
              type="button"
              fill="minimal"
              shape="square"
              onClick={searchClear}
            >
              <Icon data-test-id="clearIcon" icon={iXCircle} />
            </Button>
          ) : (
            <Icon data-test-id="searchIcon" icon={iSearch} />
          )}
        </InputRightElement>
      </InputGroup>
    </Form>
  );
};
