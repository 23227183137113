import type { RadioProps } from '@hover/blueprint/chakra';

type Props = {
  checked?: RadioProps['isChecked'];
  selectedValue?: RadioProps['value'];
  value?: RadioProps['value'];
  readOnly?: boolean;
};

export const getIsControlled = ({ checked, selectedValue }: Props) => {
  return checked !== undefined || selectedValue !== undefined;
};

export const getIsChecked = ({ selectedValue, value, checked }: Props) => {
  if (getIsControlled({ checked, selectedValue })) {
    return checked || selectedValue === value;
  }

  return undefined; // required for input to know it's uncontrolled
};
