import { createReducer } from 'typesafe-actions';

import * as actions from 'src/features/projectManagement/redux/actions';
import initialState, {
  OrderDetailsFormInitialState,
} from 'src/features/projectManagement/redux/initialState';

const projectManagementOrderReducer = createReducer(initialState)
  .handleAction(actions.clearOrderDetailsForm, (state) => ({
    ...state,
    orderDetailsForm: OrderDetailsFormInitialState,
  }))
  .handleAction(
    actions.updateProjectManagementOrderData,
    (state, { payload }) => ({
      ...state,
      projectManagementOrderData: {
        ...state.projectManagementOrderData,
        ...payload,
      },
    }),
  )
  .handleAction(actions.updateBillingForm, (state, action) => ({
    ...state,
    billingFormValues: {
      ...state.billingFormValues,
      errors: {
        ...state?.billingFormValues?.errors,
        ...action.payload?.errors,
      },
      values: {
        ...state.billingFormValues.values,
        ...action.payload.values,
      },
    },
  }));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default projectManagementOrderReducer;
