import { PureComponent } from 'react';

import { Box } from '@hover/blueprint';
import styled, { css, CSSObject } from 'styled-components';

import Icon from '../Icon';
import Modal from '../Modal';

const DefaultContentStyle: CSSObject = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
};

// flex for the overflow scrolling
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const Title = styled.div`
  ${({ theme }) => theme.mixins.fontWeights.bookImportant}
  font-size: ${({ theme }) => theme.utils.pxToRem(18)}rem;
`;

const Message = styled.div`
  font-size: ${({ theme }) => theme.utils.pxToRem(13)}rem;
  line-height: ${18 / 13};
  margin-bottom: ${({ theme }) => theme.utils.pxToRem(28)}rem;
`;

// flex for the overflow scrolling
const Content = styled.div<{ padding: Props['padding'] }>`
  overflow-y: auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ padding = '32px 32px 32px' }) => padding};
`;

const Header = styled(Box)`
  justify-content: space-between;
  border-bottom: 0.01em solid ${({ theme }) => theme.colors.lightGray};
`;

const TitleWrapper = styled(Box)<{
  justifyTitle: Props['justifyTitle'];
  textAlign?: Props['textAlign'];
  lineHeight?: Props['lineHeight'];
}>`
  justify-content: ${({ justifyTitle }) => justifyTitle};
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ lineHeight }) => lineHeight};
`;

const Footer = styled.div<{
  footerStyle: Props['footerStyle'];
  justifyFooter: Props['justifyFooter'];
}>`
  display: flex;
  justify-content: ${({ justifyFooter = 'center' }) => justifyFooter};
  align-items: center;
  ${({ footerStyle }) =>
    footerStyle &&
    css`
      ${footerStyle}
    `};
`;

const ButtonWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.utils.pxToRem(15)}rem !important;
`;

const CloseButton = styled(Icon)`
  font-size: ${({ theme }) => theme.utils.pxToRem(13)}rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface Props {
  isOpen: boolean;
  title?: string | JSX.Element;
  message?: string;
  cancel?: JSX.Element;
  action?: JSX.Element;
  closeButton?: () => void;
  justifyTitle?: React.CSSProperties['justifyContent'];
  justifyFooter?: React.CSSProperties['justifyContent'];
  contentStyle?: CSSObject;
  headerStyle?: CSSObject;
  childrenStyle?: CSSObject;
  closeButtonStyle?: CSSObject;
  footerStyle?: CSSObject;
  footerContent?: Element;
  textAlign?: React.CSSProperties['textAlign'];
  lineHeight?: React.CSSProperties['lineHeight'];
  padding?: React.CSSProperties['padding'];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class DefaultModal extends PureComponent<Props> {
  static defaultProps = {
    title: '',
    message: '',
    cancel: null,
    action: null,
    closeButton: null,
    justifyTitle: 'flex-start',
    justifyFooter: 'center',
    footerContent: null,
    contentStyle: null,
    headerStyle: null,
    closeButtonStyle: null,
    footerStyle: null,
    textAlign: 'center',
    lineHeight: 'normal',
    childrenStyle: null,
  };

  render() {
    const {
      isOpen,
      title,
      message,
      cancel,
      action,
      contentStyle,
      headerStyle,
      closeButtonStyle,
      closeButton,
      justifyTitle,
      justifyFooter,
      footerStyle,
      footerContent,
      textAlign,
      lineHeight,
      padding,
      childrenStyle,
      children,
      ...otherProps
    } = this.props;

    return (
      <Modal
        contentStyle={{
          ...DefaultContentStyle,
          ...contentStyle,
        }}
        {...otherProps}
        isOpen={isOpen}
      >
        <Container>
          {title && (
            <Header padding={600} style={headerStyle}>
              <TitleWrapper
                justifyTitle={justifyTitle}
                textAlign={textAlign}
                lineHeight={lineHeight}
              >
                <Title>{title}</Title>
              </TitleWrapper>
              {closeButton && (
                <CloseButton
                  icon="times"
                  onClick={closeButton}
                  data-test-id="modalCloseButton"
                  style={closeButtonStyle}
                />
              )}
            </Header>
          )}
          {message && <Message>{message}</Message>}
          <Content padding={padding} style={childrenStyle}>
            {children}
          </Content>
          <Footer justifyFooter={justifyFooter} footerStyle={footerStyle}>
            {footerContent}
            {!!cancel && <ButtonWrapper>{cancel}</ButtonWrapper>}
            {!!action && <ButtonWrapper>{action}</ButtonWrapper>}
          </Footer>
        </Container>
      </Modal>
    );
  }
}
