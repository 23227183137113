import React from 'react';

import { Body, Box, Tooltip } from '@hover/blueprint';
import type { BoxProps } from '@hover/blueprint';
import { isEmpty, isNil, omit } from 'lodash';
import numeral from 'numeral';

import type { distributionOrderCheck_distributionOrderCheck_lineItems as OrderCheckLineItem } from 'src/api/types/distributionOrderCheck';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ProductionListItem } from 'src/api/types/projectManagementProductionList';
import { ItemWarningIcon } from 'src/features/project/components/common/CommonIcons';

type ListOrLineItem = ProductionListItem | OrderCheckLineItem;
type CellRendererProps = {
  item: ProductionListItem | OrderCheckLineItem;
} & BoxProps;

const LIST_ITEM_TYPE = 'ListItem';
const PLACEHOLDER = '--';

/**
 * Accepts list/line item from either Project Scope or Order Details,
 * renders warning for missing variation and variation/color name or placeholder.
 */
export const VariationCellRenderer = React.forwardRef<
  HTMLDivElement,
  CellRendererProps
>((props: CellRendererProps, ref) => {
  const { item } = props;
  const boxProps = omit(props, 'item') as BoxProps;
  const isListItem = (listOrLineItem: ListOrLineItem) => {
    return (
      // eslint-disable-next-line no-underscore-dangle
      !isNil(listOrLineItem) && listOrLineItem.__typename === LIST_ITEM_TYPE
    );
  };

  const getVariationName = (listOrLineItem: ListOrLineItem) => {
    if (isListItem(listOrLineItem)) {
      const { color } = listOrLineItem as ProductionListItem;
      return isEmpty(color) ? PLACEHOLDER : color;
    }
    const variationName = (listOrLineItem as OrderCheckLineItem)?.variationName;
    return isEmpty(variationName) ? PLACEHOLDER : variationName;
  };

  return (
    <Box {...boxProps} ref={ref}>
      {isListItem(item) && item.requiresProductVariationSelection && (
        <Tooltip label="Select a color/variation" placement="top">
          <ItemWarningIcon
            marginRight={200}
            data-test-id="MissingVariationIcon"
          />
        </Tooltip>
      )}
      <Body as="span" size={400}>
        {getVariationName(item)}
      </Body>
    </Box>
  );
});

/**
 * Accepts list/line item from either Project Scope or Order Details,
 * renders sku value or placeholder.
 */
export const SkuCellRenderer = React.forwardRef<
  HTMLDivElement,
  CellRendererProps
>((props: CellRendererProps, ref) => {
  const { item } = props;
  return (
    <Box ref={ref}>
      <Body as="span" size={400}>
        {item.sku ?? PLACEHOLDER}
      </Body>
    </Box>
  );
});

/**
 * Accepts list/line item from either Project Scope or Order Details,
 * renders formatted number value.
 */
type FormattedNumberProps = {
  value: number | null;
  format?: string;
} & BoxProps;
export const FormattedNumberCellRenderer = React.forwardRef<
  HTMLDivElement,
  FormattedNumberProps
>(({ value, format, ...props }, ref) => {
  const boxProps = omit(props, 'value', 'format') as BoxProps;

  const num = numeral((value ?? 0) >= 0.000001 ? value : 0);
  const formatted = num.format(format || '0,0.00');
  return (
    <Box {...boxProps} ref={ref}>
      <Body as="span" size={400}>
        {formatted}
      </Body>
    </Box>
  );
});
