import { Box, Heading, Body } from '@hover/blueprint';

export const PostSurvey: React.FC = () => (
  <Box flexDirection="column">
    <Heading size={600}>Thank you for signing up!</Heading>
    <Body m={0} size={600}>
      Our team will reach out to you within 2-3 weeks
    </Body>
  </Box>
);
