import { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { some } from 'lodash';
import { useSelector } from 'react-redux';

import type { estimationEstimateGroups_estimationEstimateGroups_edges_node as EstimateGroupType } from 'src/api/types/estimationEstimateGroups';
import { projectManagementProposalDocuments as ProposalsQueryType } from 'src/api/types/projectManagementProposalDocuments';
import { NavButton } from 'src/components/blueprint';
import { GET_PROPOSAL_FOR_ESTIMATE_GROUP } from 'src/features/estimatesAccess/api/queries/queries';
import { useTracking } from 'src/hooks';
import { COMMERCE_PROJECT_SCOPE, FeatureFlag } from 'src/lib/FeatureFlag';
import {
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

enum BUTTON_NAMES {
  SelectEstimates = 'Select Estimates',
  Opportunity = 'Project',
  Proposal = 'Proposal',
  Production = 'Production Console',
  CalculateMaterials = 'Calculate Materials',
  OrderMaterials = 'Order Materials',
  ProjectScope = 'Project Scope',
}

const BUTTON_NAME_EVENTS: { [k: string]: string } = {
  [BUTTON_NAMES.SelectEstimates]:
    EventNames.saveAndAccess.selectEstimatesPressed,
  [BUTTON_NAMES.Opportunity]: EventNames.saveAndAccess.opportunityPressed,
  [BUTTON_NAMES.Proposal]: EventNames.saveAndAccess.proposalPressed,
  [BUTTON_NAMES.Production]: EventNames.saveAndAccess.productionPressed,
};
interface Props extends EstimateGroupType {
  canViewProductionConsole: boolean;
  selectedOrgId: number;
  isSold?: boolean;
}

export const EstimateGroupButtons: React.FC<Props> = (props) => {
  const {
    estimates,
    jobId,
    id,
    canViewProductionConsole,
    selectedOrgId,
    isSold,
  } = props;
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const shouldShowSelect = !some(estimates, { active: true });
  const isPmpDisabled = !isSold;
  const dataTestIdTag = isPmpDisabled ? 'Disabled' : 'Enabled';
  const [isProposalDisabled, setIsProposalDisabled] = useState(true);

  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const { data } = useQuery<ProposalsQueryType>(
    GET_PROPOSAL_FOR_ESTIMATE_GROUP,
    {
      variables: { estimateGroupId: id },
      skip: shouldShowSelect,
    },
  );

  useEffect(() => {
    if (data?.projectManagementProposalDocuments?.nodes?.[0]) {
      setIsProposalDisabled(false);
    }
  }, [data]);

  const trackClick = useCallback(
    (name: string) => {
      typewriter.buttonPressed({
        button_text: BUTTON_NAME_EVENTS[name],
        page_or_screen_name: EventNames.projectList.page,
        job_id: Number(jobId),
        primary_cta: false,
        ...commonTrackingProps,
      });
    },
    [commonTrackingProps, jobId],
  );

  const trackProjectScopePage = useCallback(() => {
    typewriter.buttonPressed({
      button_text: showOrderingVersion
        ? BUTTON_NAMES.OrderMaterials
        : 'Project Scope',
      job_id: Number(jobId),
      page_or_screen_name: EventNames.projectList.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps, showOrderingVersion]);

  return (
    <Box flex={1} justifyContent="flex-end" alignItems="center">
      {shouldShowSelect ? (
        <Box>
          <NavButton
            fill="outline"
            to={`/estimator/estimates/${id}?jobId=${jobId}`}
            onClick={() => trackClick(BUTTON_NAMES.SelectEstimates)}
          >
            {showOrderOrMaterialListVersion
              ? BUTTON_NAMES.CalculateMaterials
              : BUTTON_NAMES.SelectEstimates}
          </NavButton>
        </Box>
      ) : (
        <>
          {!showOrderOrMaterialListVersion && (
            <NavButton
              marginRight={100}
              fill="outline"
              to={`/estimator/estimates/${id}/details?jobId=${jobId}&orgId=${selectedOrgId}`}
              data-test-id="opportunityButton"
              onClick={() => trackClick(BUTTON_NAMES.Opportunity)}
            >
              {BUTTON_NAMES.Opportunity}
            </NavButton>
          )}

          {!showOrderOrMaterialListVersion && (
            <NavButton
              marginRight={100}
              fill="outline"
              to={`/estimator/estimates/${id}/details?jobId=${jobId}&orgId=${selectedOrgId}&showProposalModal=true`}
              target="_blank"
              data-test-id="proposalButton"
              label="Proposal"
              isDisabled={isProposalDisabled}
              onClick={() => trackClick(BUTTON_NAMES.Proposal)}
            >
              {BUTTON_NAMES.Proposal}
            </NavButton>
          )}

          {canViewProductionConsole && (
            <FeatureFlag
              flagName={COMMERCE_PROJECT_SCOPE}
              whenOn={
                <NavButton
                  to={`/project/${jobId}?orgId=${selectedOrgId}`}
                  isDisabled={isPmpDisabled}
                  onClick={trackProjectScopePage}
                  fill="outline"
                  data-test-id="projectScopeButton"
                >
                  {showOrderOrMaterialListVersion
                    ? BUTTON_NAMES.OrderMaterials
                    : BUTTON_NAMES.ProjectScope}
                </NavButton>
              }
              whenOff={
                <NavButton
                  fill="outline"
                  label="Production Console"
                  to={`/estimator_production?jobId=${jobId}&orgId=${selectedOrgId}`}
                  data-test-id={`productionButton${dataTestIdTag}`}
                  isDisabled={isPmpDisabled}
                  onClick={() => trackClick(BUTTON_NAMES.Production)}
                >
                  {showOrderOrMaterialListVersion
                    ? BUTTON_NAMES.OrderMaterials
                    : BUTTON_NAMES.Production}
                </NavButton>
              }
            />
          )}
        </>
      )}
    </Box>
  );
};
