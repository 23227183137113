import { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  Link,
  Box,
  Icon,
  Modal,
  Heading,
  Body,
  useToast,
} from '@hover/blueprint';
import { hCalculator } from '@hover/icons';
import { useParams } from 'react-router-dom';

import { salesOpportunityUpdate } from 'src/api/types/salesOpportunityUpdate';
import { SALES_OPPORTUNITY_UPDATE } from 'src/features/exteriorEstimator/apis/queries/salesOpportunities';
import { LinkBody } from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  salesOpportunityId?: number;
}

export const RecalculateMaterialListButton: React.FC<Props> = ({
  salesOpportunityId,
}: {
  salesOpportunityId?: number;
}) => {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const { jobId } = useParams();
  const toast = useToast();
  const confirmationText = 'Got it, remove project scope';
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();

  const [updateSalesOpportunity] = useMutation<salesOpportunityUpdate>(
    SALES_OPPORTUNITY_UPDATE,
    {
      onCompleted: () => {
        window.location.href = `/ehi/#/estimator/questions/select_templates?jobId=${jobId}`;
      },
      onError: () => {
        toast({
          description:
            'There was a problem updating your project. Please try again.',
          status: 'error',
        });
      },
    },
  );

  const handleConfirm = () => {
    if (!salesOpportunityId) return;
    typewriter.buttonPressed({
      button_text: confirmationText,
      page_or_screen_name: EventNames.project.scope.page,
      primary_cta: true,
    });
    updateSalesOpportunity({
      variables: {
        id: salesOpportunityId.toString(),
        salesOpportunityAttributes: {
          soldEstimateGroupId: null,
        },
      },
    });
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        data-test-id="recalculate-materials-link"
        alignSelf="flex-start"
        onClick={() => {
          typewriter.linkPressed({
            link_text: 'Recalculate materials list',
            page_or_screen_name: EventNames.project.scope.page,
            primary_cta: false,
          });
          setShowWarningModal(true);
        }}
        _hover={{
          textDecoration: 'underline',
          cursor: 'pointer',
          textDecorationColor: 'primary600',
        }}
      >
        <Box paddingY={100}>
          <Icon icon={hCalculator} color="primary.600" />
          <LinkBody size={200}>Recalculate materials list</LinkBody>
        </Box>
      </Link>
      <Modal
        isOpen={showWarningModal}
        data-test-id="recalculate-material-warning-modal"
        onClose={() => setShowWarningModal(false)}
        size="medium"
        isCentered
        header={<Heading size={600}>Recalculate materials list</Heading>}
      >
        <Box flexDirection="column" alignItems="center">
          <Body size={400} color="neutral600">
            Any changes done on project scope will be lost. There may be
            existing orders associated with this project. This cannot be undone.
          </Body>
          <Box flexDirection="row" width="100%" justifyContent="right">
            <Button
              data-test-id="recalculate-modal-cancel"
              size="large"
              fill="outline"
              onClick={() => setShowWarningModal(false)}
            >
              Cancel
            </Button>
            <Button
              data-test-id="recalculate-modal-confirm"
              size="large"
              color="danger"
              fill="solid"
              marginLeft={300}
              onClick={handleConfirm}
            >
              {confirmationText}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
