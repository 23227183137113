/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createReducer } from 'typesafe-actions';

import { productCatalogCategories_productCatalogCategories_nodes as Category } from 'src/api/types/productCatalogCategories';
import { setCategories } from 'src/features/settings/redux/actions';

type SettingsState = {
  categories: Category[] | null;
  allCategories: Category[] | null;
};
const initialState: SettingsState = {
  categories: null,
  allCategories: null,
};

export const settings = createReducer(initialState).handleAction(
  // @ts-ignore
  setCategories,
  // @ts-ignore
  (state, { payload: { categories } }) => ({
    ...state,
    categories: categories.filter(
      (c: Category | null) => c !== null && c.orgProductsCount > 0,
    ),
    allCategories: categories,
  }),
);
