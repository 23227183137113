import { useCallback } from 'react';

import { Icon } from '@hover/blueprint';
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbLink as ChakraBreadcrumbLink,
  BreadcrumbLinkProps as ChakraBreadcrumbLinkProps,
  BreadcrumbProps,
  Text,
} from '@hover/blueprint/chakra';
import { iChevronRight } from '@hover/icons';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';

export { BreadcrumbItem } from '@hover/blueprint/chakra';

type BreadcrumbLinkProps = ChakraBreadcrumbLinkProps &
  Partial<LinkProps> & {
    label: string;
    trackEventName?: string;
  };

export const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({
  label,
  isCurrentPage,
  trackEventName,
  ...props
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleBreadcrumbClick = useCallback(
    (eventName?: string) => {
      if (!isEmpty(eventName)) {
        typewriter.linkPressed({
          link_text: label,
          page_or_screen_name: eventName ?? '',
          primary_cta: false,
          link_location: 'Breadcrumb',
          ...commonTrackingProps,
        });
      }
    },
    [label, commonTrackingProps],
  );

  return isCurrentPage ? (
    <Text as="span" fontSize={14} fontWeight={700} color="neutral.600">
      {label}
    </Text>
  ) : (
    <ChakraBreadcrumbLink
      as={RouterLink}
      color="primary.600"
      fontSize={14}
      fontWeight={700}
      isCurrentPage={isCurrentPage}
      onClick={() => handleBreadcrumbClick(trackEventName)}
      {...props}
    >
      {label}
    </ChakraBreadcrumbLink>
  );
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  children,
  ...props
}) => (
  <ChakraBreadcrumb
    separator={<Icon icon={iChevronRight} color="neutral500" />}
    spacing="0px"
    {...props}
    sx={{ ol: { paddingInlineStart: 0, margin: 0 } }}
  >
    {children}
  </ChakraBreadcrumb>
);
