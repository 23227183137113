import { memo } from 'react';

import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import {
  ListItemIdsByTrade,
  ListItemsByTrade,
} from 'src/features/project/types';
import { getTradeTypesSorted } from 'src/redux/selectors';

import { ProjectScopeTypeListControls } from '../ProjectScopeTypeListControls';
import { ProjectScopeTradeListMemoized } from './ProjectScopeTradeList';

type Props = {
  jobId: number;
  orgId: string;
  lineItemType: LineItemTypeEnum;
  selectedListItemIds: ListItemIdsByTrade | null;
  itemsByTrade: ListItemsByTrade;
  enableInlineEditingV2: boolean;
  distributors?: Distributor[];
  productionListId: number;
  quantityUnits: estimationQuantityUnit[];
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  selectDistributor: () => void;
  openAddEditMaterialModal: (lineItemType: LineItemTypeEnum) => void;
  setEditingListItem: (listItem: ListItem) => void;
};

export const ProjectScopeTypeList = ({
  jobId,
  orgId,
  lineItemType,
  selectedListItemIds,
  itemsByTrade,
  enableInlineEditingV2,
  distributors,
  productionListId,
  quantityUnits,
  openDownloadPdfModal,
  selectDistributor,
  openAddEditMaterialModal,
  setEditingListItem,
}: Props) => {
  const tradeTypesSorted = useSelector(getTradeTypesSorted);

  return (
    <Box
      marginY={200}
      backgroundColor="neutral.0"
      flexDirection="column"
      data-test-id="ProjectScope-ListItemsTable"
    >
      <ProjectScopeTypeListControls
        jobId={jobId}
        lineItemType={lineItemType}
        selectedListItemIds={selectedListItemIds}
        itemsByTrade={itemsByTrade}
        selectDistributor={selectDistributor}
        openDownloadPdfModal={openDownloadPdfModal}
        openAddEditMaterialModal={openAddEditMaterialModal}
        sx={{ position: 'sticky', top: '0px', zIndex: 1 }}
      />
      <Box
        paddingX={500}
        flex="1 1 auto"
        flexDirection="column"
        position="relative"
      >
        {tradeTypesSorted.map((tradeTypeObj) => {
          // Map over the global set of sorted trade types, rendering the trade types
          // in order that exist in the pre-mapped `listItemsByTypeAndTrade` object.
          const tradeType = tradeTypeObj.tradeTypeEnumValue;

          if (!itemsByTrade[tradeType]) {
            return null;
          }

          return (
            <ProjectScopeTradeListMemoized
              key={tradeType}
              orgId={orgId}
              jobId={jobId}
              lineItemType={lineItemType}
              tradeType={tradeType}
              items={itemsByTrade[tradeType]}
              selectedItemsIds={
                selectedListItemIds &&
                selectedListItemIds[tradeType] && [
                  ...selectedListItemIds[tradeType],
                ]
              }
              setEditingListItem={setEditingListItem}
              distributors={distributors}
              productionListId={productionListId}
              enableInlineEditingV2={enableInlineEditingV2}
              quantityUnits={quantityUnits}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const ProjectScopeTypeListMemoized = memo(ProjectScopeTypeList);
