import { css } from 'styled-components';

export const Grid = css`
  max-width: 1280px;
  display: grid;
  grid-template-columns: [col1] 10% [col2] 24% [col3] 15% [col4] 8% [col5] 13% [col6] 1fr [end];
  grid-template-areas:
    'header header header header header header header'
    'main main main main main main main';
`;

export const MultiTradesGrid = css`
  max-width: 1280px;
  display: grid;
  grid-template-columns: [col1] 8% [col2] 15% [col3] 15% [col4] 10% [col5] 7% [col6] 3% [col7] 1fr [col8] 3%;
  /* grid-template-areas:
    'header header header header header header header header'
    'main main main main main main main main'; */
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`;
