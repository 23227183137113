import numeral from 'numeral';

import { EstimateGroupForInputSummary_estimationEstimateGroup_estimates_lineItems as LineItem } from 'src/api/types/EstimateGroupForInputSummary';
import { Answer as AnswerComponent } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/Answer';
import { AnswerSection } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/AnswerSection';

interface Props {
  customLineItems: (LineItem | undefined)[];
}

export const CustomLineItem: React.FC<Props> = ({ customLineItems }) => {
  const userValueFormatter = (lineItem: LineItem) => {
    return numeral(lineItem.price).format('$0,0.00');
  };

  return (
    <AnswerSection name="Custom Line Items">
      {customLineItems.map((customLineItem) => {
        return customLineItem ? (
          <AnswerComponent
            key={customLineItem.id}
            id={customLineItem.id.toString()}
            formattedUserValue={userValueFormatter(customLineItem)}
            question={customLineItem?.name}
          />
        ) : null;
      })}
    </AnswerSection>
  );
};
