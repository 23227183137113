import { PureComponent } from 'react';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';

import {
  TableHead,
  TableHeadVendorDropdown,
  TableRow,
  TableCellContent,
  TableHeadText,
  TableSmallText,
} from '../styled';

interface Props {
  tableType: LineItemTypeEnum;
}

class HeadRow extends PureComponent<Props> {
  public render() {
    const { tableType } = this.props;

    return (
      <TableRow>
        <TableHead>
          <TableCellContent>
            <TableHeadText>Description</TableHeadText>
            {tableType === LineItemTypeEnum.LABOR ? null : (
              <TableSmallText>Colors</TableSmallText>
            )}
          </TableCellContent>
        </TableHead>
        <TableHeadVendorDropdown>
          <TableCellContent>
            <TableHeadText>Vendor</TableHeadText>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <TableSmallText>Supplier's SKU ID</TableSmallText>
          </TableCellContent>
        </TableHeadVendorDropdown>
        <TableHead data-test-id="trade-column-head">
          <TableCellContent>
            <TableHeadText>Trade</TableHeadText>
          </TableCellContent>
        </TableHead>
        <TableHead>
          <TableCellContent>
            <TableHeadText>Measurement</TableHeadText>
            <TableSmallText>+ waste</TableSmallText>
          </TableCellContent>
        </TableHead>
        <TableHead>
          <TableCellContent>
            <TableHeadText>Quantity</TableHeadText>
            <TableSmallText>calculated qty</TableSmallText>
          </TableCellContent>
        </TableHead>
        <TableHead>
          <TableCellContent>
            <TableHeadText>Unit Cost</TableHeadText>
          </TableCellContent>
        </TableHead>
        <TableHead>
          <TableCellContent>
            <TableHeadText>Amount</TableHeadText>
          </TableCellContent>
        </TableHead>
        <TableHead />
      </TableRow>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default HeadRow;
