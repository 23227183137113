import * as Sentry from '@sentry/react';
import { uniqBy } from 'lodash';

import { hyperion } from 'src/api';
import { SubOrg } from 'src/types';

export const getSuborgs = async (orgId: number) => {
  let page: number | null = 1;
  let orgs: SubOrg[] = [];
  let maxCalls = 0; // prevent infinite loop if the responses aren't what we expect

  while (page && maxCalls < 100) {
    try {
      const {
        data: {
          meta: {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line camelcase
            pagination: { next_page },
          },
          orgs: _orgs,
        },
      }: // eslint-disable-next-line no-await-in-loop
      AsyncReturnType<any> = await hyperion.getSubOrgs({
        orgId,
        page,
      });

      orgs = [
        ...orgs,
        // TODO: Fix this the next time the file is edited.
        ..._orgs.map(
          ({
            id,
            name,
            // eslint-disable-next-line camelcase
            parent_id,
          }: {
            id: number;
            name: string;
            parent_id: number;
          }) => ({
            id,
            name,
            // eslint-disable-next-line camelcase
            parent_id,
          }),
        ),
      ];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line camelcase
      page = next_page;
    } catch (error) {
      page = null;
      Sentry.captureException(error);
      return null;
    }
    maxCalls += 1;
  }
  // next_page becomes null when on last page
  // de-dupe results (pagination sometimes has some overlap)
  // put the results once we have all of the pages
  return uniqBy(orgs, 'id');
};
