import { useState, useEffect } from 'react';

import { Modal, Image, IconButton, Box } from '@hover/blueprint';
import { iArrowLeft, iArrowRight } from '@hover/icons';

export interface LightboxImage {
  url: string;
  title?: string;
}

export interface ImageLightboxModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: LightboxImage[];
  initialImageUrl: string;
}

interface NavigationButtonProps {
  showButton: boolean;
  direction: 'previous' | 'next';
  onClick: (navDirection: 'previous' | 'next') => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  showButton,
  direction,
  onClick,
}) => {
  if (!showButton) {
    return null;
  }
  const buttonArrow = direction === 'previous' ? iArrowLeft : iArrowRight;

  const onButtonClick = () => onClick(direction);

  return (
    <IconButton
      icon={buttonArrow}
      label={direction}
      onClick={onButtonClick}
      data-test-id={`nav-${direction}`}
    />
  );
};

export const ImageLightboxModal: React.FC<ImageLightboxModalProps> = ({
  isOpen,
  onClose,
  images,
  initialImageUrl,
}) => {
  const findImageIdx = (imageUrl: string) =>
    images.findIndex((image) => image.url === imageUrl);

  const [currentImageUrl, setCurrentImageUrl] =
    useState<string>(initialImageUrl);

  const navigateImages = (navDirection: 'next' | 'previous') => {
    if (images.length < 1) {
      return;
    }

    let newIndex = 0;
    const currentIdx = findImageIdx(currentImageUrl);

    if (navDirection === 'previous') {
      newIndex = currentIdx === 0 ? images.length - 1 : currentIdx - 1;
    } else if (navDirection === 'next') {
      newIndex = (currentIdx + 1) % images.length;
    }
    setCurrentImageUrl(images[newIndex].url);
  };

  useEffect(() => {
    setCurrentImageUrl(initialImageUrl);
  }, [isOpen, initialImageUrl]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <Box justifyContent="center" fontWeight={600} data-test-id="modal-title">
        {images[findImageIdx(currentImageUrl)]?.title}
      </Box>
      <Box
        justifyContent="space-evenly"
        alignItems="center"
        data-test-id="modal-content-container"
      >
        <NavigationButton
          showButton={images.length > 1}
          direction="previous"
          onClick={navigateImages}
        />
        <Box
          paddingTop={500}
          paddingLeft={400}
          paddingRight={400}
          paddingBottom={400}
          display="flex"
          justifyContent="center"
        >
          {images.length > 0 && (
            <Image
              src={currentImageUrl}
              alt="Modal Image"
              maxHeight={800}
              height="auto"
              maxWidth="100%"
              data-test-id="modal-image"
            />
          )}
        </Box>
        <NavigationButton
          showButton={images.length > 1}
          direction="next"
          onClick={navigateImages}
        />
      </Box>
    </Modal>
  );
};
