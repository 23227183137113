import { useSelector } from 'react-redux';

import { AreaCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/AreaCounter';
import { BottomBar } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBar';
import { BottomBarLastPage } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarLastPage';
import { BottomBarSelectTemplates } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarSelectTemplates';
import { SelectedPartialSidingAreaCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/SelectedPartialSidingAreaCounter';
import { TemplateCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/TemplateCounter';
import {
  SELECT_TEMPLATES,
  SIDING_FACET_SELECTION_3D,
  ROOF_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import { PARTIAL_SIDING_SELECTION_ID } from 'src/features/exteriorEstimator/constants/sidingConstants';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import { getJobTotalRoofArea } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { NAVIGATION_DIRECTION } from 'src/features/exteriorEstimator/types/Navigation';
import { RootState } from 'src/store/combineReducers';

type Props = {
  navigateCategories: (
    direction: NAVIGATION_DIRECTION,
    forcedTargetCategoryIndex?: number | null,
  ) => void;
  setIsInitializing: (newState: boolean) => void;
  updateAnswer: (updateProps: UpdateAnswer) => void;
  hasNextPage: boolean;
};

export const QuestionViewFooter: React.FC<Props> = ({
  navigateCategories,
  setIsInitializing,
  updateAnswer,
  hasNextPage,
}) => {
  const category = useSelector(
    (state: RootState) =>
      state.exteriorEstimator.inputs.currentQuestionCategory,
  );
  const roofTotalArea = useSelector(getJobTotalRoofArea);

  // Templates and the last page are special cases
  if (category === SELECT_TEMPLATES)
    return (
      <BottomBarSelectTemplates setIsInitializing={setIsInitializing}>
        <TemplateCounter />
      </BottomBarSelectTemplates>
    );
  if (!hasNextPage)
    return <BottomBarLastPage navigateCategories={navigateCategories} />;

  switch (category) {
    case ROOF_FACET_SELECTION_3D:
      return (
        <BottomBar navigateCategories={navigateCategories}>
          <AreaCounter area={roofTotalArea} />
        </BottomBar>
      );
    case SIDING_FACET_SELECTION_3D:
    case PARTIAL_SIDING_SELECTION_ID:
      return (
        <BottomBar navigateCategories={navigateCategories}>
          <SelectedPartialSidingAreaCounter updateAnswer={updateAnswer} />
        </BottomBar>
      );
    default:
      return <BottomBar navigateCategories={navigateCategories} />;
  }
};
