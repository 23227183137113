/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { ProjectManagementProposalDocumentStateEnum } from 'src/api/graphql-global-types';
import { estimationConfigInputCategories } from 'src/api/types/estimationConfigInputCategories';
import { estimationConfigRequiredInputs } from 'src/api/types/estimationConfigRequiredInputs';
import { estimationConfigTemplates } from 'src/api/types/estimationConfigTemplates';
import {
  getProductsVariables,
  getProducts as getProductsQuery,
} from 'src/api/types/getProducts';
import { projectManagementProposalDocumentsEstimateDetails } from 'src/api/types/projectManagementProposalDocumentsEstimateDetails';
import { GET_PRODUCTS } from 'src/features/projectManagement/apis/graphql/queries';
import { getPaginatedGql } from 'src/utils/PaginatedGqlUtils';

import {
  FETCH_PROPOSAL_DOCUMENTS,
  GET_INPUT_CATEGORIES,
  GET_REQUIRED_INPUTS,
  GET_TEMPLATES,
} from '../apis/queries';

export const getTemplates = async (orgId: number | string) => {
  const res = await getPaginatedGql<estimationConfigTemplates>({
    query: GET_TEMPLATES,
    variables: {
      orgId,
    },
    typeName: 'estimationConfigTemplates',
  });

  return res!.estimationConfigTemplates.edges!.map((e) => e!.node!);
};

export const getRequiredInputs = async (
  orgId: number | string,
  templateIds: number[],
) => {
  const res = await getPaginatedGql<estimationConfigRequiredInputs>({
    query: GET_REQUIRED_INPUTS,
    typeName: 'estimationConfigRequiredInputs',
    variables: {
      orgId,
      templateIds,
    },
  });

  return res!.estimationConfigRequiredInputs.edges!.map((e) => e!.node!);
};

export const getCategories = async () => {
  const res = await getPaginatedGql<estimationConfigInputCategories>({
    query: GET_INPUT_CATEGORIES,
    typeName: 'estimationConfigInputCategories',
  });
  return res!.estimationConfigInputCategories.edges!.map((e) => e!.node!);
};

export const getProposals = async (estimateGroupId: string) => {
  const res =
    await getPaginatedGql<projectManagementProposalDocumentsEstimateDetails>({
      query: FETCH_PROPOSAL_DOCUMENTS,
      variables: {
        estimateGroupId,
        state: ProjectManagementProposalDocumentStateEnum.COMPLETE,
      },
      typeName: 'projectManagementProposalDocuments',
    });

  return res!.projectManagementProposalDocuments!.edges!.map((e) => e!.node!);
};

export const getProducts = async ({
  productIds,
  distributorIds,
  filterVariationsByOrg,
  orgId,
  after,
}: getProductsVariables) => {
  const res = await getPaginatedGql<getProductsQuery>({
    query: GET_PRODUCTS,
    variables: {
      productIds,
      distributorIds,
      filterVariationsByOrg,
      orgId,
      after,
    },
    typeName: 'productCatalogProducts',
  });

  return res!.productCatalogProducts!.edges!.map((e) => e!.node!);
};
