import { useEffect } from 'react';

import {
  useQuery,
  useLazyQuery,
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  QueryHookOptions,
  QueryFunctionOptions,
  QueryResult,
  QueryTuple,
  ServerError,
} from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { isNil } from 'lodash';

/**
 * A hook that wraps the ApolloClient `useQuery` hook, in order to
 * invoke the `onError` call back consistently for both GraphQL errors and
 * server errors.
 *
 * @param query
 * @param options
 * @returns useQuery result
 */
export const useQueryEhi = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  const useQueryResult = useQuery<TData, TVariables>(query, options);

  const { error } = useQueryResult;
  const { onError } = options as QueryFunctionOptions;
  useEffect(() => {
    // This useEffect is to catch the GraphQL errors in a >=500 response;
    // calls the passed-in `onError` handler.

    const networkErrored = !isNil(useQueryResult?.error?.networkError);
    const serverError = useQueryResult?.error?.networkError as ServerError;
    const messageStarts500 = useQueryResult.error?.message.startsWith('500:');
    const erroredIn500 =
      networkErrored && (serverError.statusCode >= 500 || messageStarts500);
    const erroredDownstream =
      !isNil(useQueryResult?.error?.graphQLErrors) &&
      (useQueryResult.error?.graphQLErrors as GraphQLErrors)?.length > 0 &&
      useQueryResult.error?.graphQLErrors.some(
        (gqlError) => gqlError.extensions.code === 'DOWNSTREAM_SERVICE_ERROR',
      );

    if (!isNil(onError) && !!error && (erroredIn500 || erroredDownstream)) {
      onError(error);
    }
  }, [
    error,
    onError,
    useQueryResult.error?.graphQLErrors,
    useQueryResult.error?.message,
    useQueryResult.error?.networkError,
  ]);

  return useQueryResult;
};

/**
 * A hook that wraps the ApolloClient `useLazyQuery` hook, in order to
 * invoke the `onError` call back consistently for both GraphQL errors and
 * server errors.
 *
 * @param query
 * @param options
 * @returns useQuery result
 */
export const useLazyQueryEhi = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> => {
  const useQueryResult = useLazyQuery<TData, TVariables>(query, options);

  const [func, { error }] = useQueryResult;
  const { onError } = options as QueryFunctionOptions;

  useEffect(() => {
    // This useEffect is to catch the GraphQL errors in a >=500 response;
    // calls the passed-in `onError` handler.
    if (
      !isNil(onError) &&
      !!error &&
      ((!isNil(error?.networkError) &&
        // 500 server errors
        ((error?.networkError as ServerError).statusCode >= 500 ||
          error?.message.startsWith('500:'))) ||
        (!isNil(error?.graphQLErrors) &&
          (error?.graphQLErrors as GraphQLErrors)?.length > 0 &&
          error?.graphQLErrors.some(
            (gqlError) =>
              gqlError.extensions.code === 'DOWNSTREAM_SERVICE_ERROR',
          )))
    ) {
      onError(error);
    }
  }, [
    error,
    onError,
    error?.graphQLErrors,
    error?.message,
    error?.networkError,
  ]);

  return useQueryResult;
};
