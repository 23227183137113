import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Icon, Button, Box, Heading } from '@hover/blueprint';
import { iX } from '@hover/icons';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { GET_PRODUCT_CATALOG_ORG_SETTINGS } from 'src/api/queries/queries';
import { PRODUCT_CATALOG_ORG_SETTINGS_FILTER_ENABLED_UPDATE } from 'src/features/settings/api/queries/orgSettings';
import { useLazyQueryEhi, useTracking } from 'src/hooks';
import * as hoverActions from 'src/redux/actions';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import {
  getOrgSettings,
  getUserTrackingProps,
  getUserOrgId,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { Modal } from 'style-guide';

import { SwitchContent, ProductFilteringState } from './ModalContents';

export const ModalStyled = styled(Modal)`
  min-height: 259px;
  min-width: 648px;
`;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const EnableVariantsFilteringModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
}) => {
  const orgId = useSelector(getUserOrgId);

  const orgSettings = useSelector(getOrgSettings);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const dispatch = useDispatch();
  const [productFilteringState, setProductFilteringState] =
    useState<ProductFilteringState>(ProductFilteringState.Disabled);

  const [getProductCatalogOrgSettings] = useLazyQueryEhi(
    GET_PRODUCT_CATALOG_ORG_SETTINGS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: ({ productCatalogOrgSettings }) => {
        dispatch(
          hoverActions.getOrgSettings.success({
            orgSettings: {
              ...orgSettings,
              productFilteringEnabled:
                productCatalogOrgSettings.productFilteringEnabled,
            } as EhiOrgSettings,
          }),
        );
      },
    },
  );

  const onCompleted = () => {
    getProductCatalogOrgSettings({ variables: { orgId } });
    setProductFilteringState(ProductFilteringState.Enabled);
  };

  const onError = () => {
    setProductFilteringState(ProductFilteringState.Error);
  };

  const [enableVariantsFiltering] = useMutation(
    PRODUCT_CATALOG_ORG_SETTINGS_FILTER_ENABLED_UPDATE,
    {
      onCompleted,
      onError,
    },
  );

  const closeModal = () => {
    setIsOpen(false);

    typewriter.buttonPressed({
      button_text: 'Got it',
      page_or_screen_name:
        EventNames.settings.materialsList.enableFilteringModal.confirmation
          .page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  const handleEnableVariantsFiltering = () => {
    setProductFilteringState(ProductFilteringState.Processing);
    enableVariantsFiltering({
      variables: {
        orgId,
        productFilteringEnabled: true,
      },
    });

    typewriter.buttonPressed({
      button_text: 'Enable filtering',
      page_or_screen_name:
        EventNames.settings.materialsList.enableFilteringModal.page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  return (
    <Box data-test-id="EnableVariantsFilteringModal" position="relative">
      <ModalStyled isOpen={isOpen}>
        <Box position="absolute" top={500} right={500}>
          <Button
            onClick={closeModal}
            fill="minimal"
            shape="circle"
            data-test-id="EnableVariantsFilteringModalClose"
          >
            <Icon icon={iX} color="neutral600" />
          </Button>
        </Box>
        <Box flexDirection="column" alignItems="center" paddingX={700}>
          <Heading size={500}>Variants filtering</Heading>
          <SwitchContent
            closeModal={closeModal}
            productFilteringState={productFilteringState}
            enableVariantsFiltering={handleEnableVariantsFiltering}
          />
        </Box>
      </ModalStyled>
    </Box>
  );
};
