import { useState } from 'react';

import { Modal, Button, Field, Select, Body } from '@hover/blueprint';

interface DuplicateTemplateModalProps {
  templateOptions: { id: number; name: string | null }[];
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (templateId: number) => void;
}

export const DuplicateTemplateModal = ({
  templateOptions,
  onSubmit,
  onClose,
  isLoading,
}: DuplicateTemplateModalProps) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    templateOptions[0].id,
  );

  return (
    <Modal
      isOpen
      footer={
        <>
          <Button fill="outline" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            isLoading={isLoading}
            onClick={() => onSubmit(selectedTemplateId)}
          >
            Duplicate
          </Button>
        </>
      }
      header="Duplicate Template"
      onClose={onClose}
      size="medium"
    >
      <Body>
        This will copy over your entire template, including all line items, to a
        new template that you can further customize from there.
      </Body>

      <Field label="Existing templates" name="selected_template">
        <Select
          defaultValue={selectedTemplateId}
          onChange={({ target: { value } }) =>
            setSelectedTemplateId(Number(value))
          }
        >
          {templateOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
      </Field>
    </Modal>
  );
};
