/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Spinner, AspectRatio } from '@hover/blueprint';
import { colors } from '@hover/blueprint/foundation';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup_facets as Facet } from 'src/api/types/EstimateGroup';
import { IconMessaging } from 'src/features/exteriorEstimator/components/EstimationTool/Machete/IconMessaging';
import { useMachete } from 'src/features/exteriorEstimator/hooks';
import {
  getPages,
  getJobDetails,
  getQuestionResponses,
  getGeometry,
  getMetadata,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import { PartialSidingUtils } from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import { useEffectOnMount } from 'src/hooks';
import { renderMachete } from 'src/lib/machete';
import { emitPageAction, NewRelicEventType } from 'src/utils/newrelic/page';

export interface OnFeatureClickProps {
  featureName: string;
  trade: TradeTypeEnum.ROOF | TradeTypeEnum.SIDING;
}

interface Props {
  onFeatureClick?: (props: OnFeatureClickProps) => void;
  trade?: TradeTypeEnum.ROOF | TradeTypeEnum.SIDING;
  widescreen?: boolean;
  facets?: Facet[]; // if available, this param indicates which facets are selected
}

export const ModelSection: React.FC<Props> = ({
  onFeatureClick,
  facets,
  widescreen,
}) => {
  const geometryUrl = useSelector(getJobDetails)?.threeDFiles?.geometryUrl;
  const metadataUrl = useSelector(getJobDetails)?.threeDFiles?.metadataUrl;
  const pages = useSelector(getPages);

  const { initializeMacheteData } = useMachete();

  const questionResponses = useSelector(getQuestionResponses);
  const [building, setBuilding] = useState<any>(null);
  const [isBuildingReady, setIsBuildingReady] = useState<boolean>(false);

  const canvasRef = useRef<HTMLDivElement | null>(null);

  const geometry = useSelector(getGeometry);
  const metadata = useSelector(getMetadata);

  useEffectOnMount(() => {
    if (!geometry && !metadata && geometryUrl && metadataUrl) {
      initializeMacheteData(geometryUrl, metadataUrl);
    }
  });

  useEffect(() => {
    const readyForModel = facets ? true : !isEmpty(pages);
    if (geometry && metadata && readyForModel) {
      renderMachete({ canvasRef, geometry, metadata }).then((macheteStuff) => {
        emitPageAction(NewRelicEventType.BUILDING_FULLY_LOADED);
        if (macheteStuff.building) {
          setBuilding(macheteStuff.building);
          setIsBuildingReady(true);
        }
      });
    }
  }, [geometry, metadata, pages, facets]);

  const handleModelFacetClick = useCallback(
    (event: React.ChangeEvent<{ name: string }>) => {
      const facet = event.target;

      // need to use window here instead of props because of something weird with
      // machete closures on event handlers
      let trade: TradeTypeEnum.SIDING | TradeTypeEnum.ROOF = TradeTypeEnum.ROOF;
      if (
        window.location.href.includes('partial_siding_selection') ||
        window.location.href.includes('siding_facet_selection_3d')
      ) {
        trade = TradeTypeEnum.SIDING;
      }

      if (onFeatureClick) {
        if (
          (PartialSidingUtils.isQuestionPartialSidingSelection(facet.name) &&
            (window.location.href.includes('partial_siding_selection') ||
              window.location.href.includes('siding_facet_selection_3d'))) ||
          (PartialRoofUtils.isQuestionPartialRoofFacetSelection(facet.name) &&
            window.location.href.includes('roof_facet_selection_3d'))
        ) {
          onFeatureClick({
            featureName: facet.name,
            trade,
          });
        }
      }
    },
    [onFeatureClick],
  );

  const setSelected = (facet: any) => {
    // eslint-disable-next-line prefer-destructuring
    facet.highlight.config.color = colors.primary[100];
    facet.highlight.visible = true;

    facet.outline_color = colors.primary.base;
    facet.outline_thickness = 0.08;

    facet.name_tag.config.backgroundColor = colors.primary.base;
    facet.name_tag.visible = true;
  };

  const setUnselected = (facet: any) => {
    facet.highlight.config.color = '#EDECED';
    facet.highlight.visible = false;

    // eslint-disable-next-line prefer-destructuring
    facet.outline_color = colors.neutral[300];
    facet.outline_thickness = 0.08;

    // eslint-disable-next-line prefer-destructuring
    facet.name_tag.config.backgroundColor = colors.neutral[500];
    facet.name_tag.visible = true;
  };

  const setModel = () => {
    const { walls, roofs } = building.features;
    if (!facets) return;

    walls.forEach((wall: any) => {
      if (facets.find((f) => f.identifier === wall.name)) {
        setSelected(wall);
      } else {
        setUnselected(wall);
      }
    });

    roofs.forEach((roof: any) => {
      if (facets.find((f) => f.identifier === roof.name)) {
        setSelected(roof);
      } else {
        setUnselected(roof);
      }
    });
  };

  useEffect(() => {
    if (!isBuildingReady) return;

    const { walls, roofs } = building.features;

    // base outline for all features, not just walls
    building.features.forEach((feature: any) => {
      // eslint-disable-next-line prefer-destructuring
      feature.outline_color = colors.neutral[300];
      feature.outline_thickness = 0.05;
      feature.display_outline = true;
      feature.color = '#EDECED';
    });

    roofs.forEach((roof: any) => {
      roof.addEventListener('click', handleModelFacetClick);
      roof.name_tag.config.fontSize = '40px';
    });

    walls.forEach((wall: any) => {
      wall.addEventListener('click', handleModelFacetClick);
      wall.name_tag.config.fontSize = '40px';
    });
    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuildingReady]);

  // when building is ready and facets are available, initialize model based on facets
  useEffect(() => {
    if (!isBuildingReady) return;

    if (facets) {
      setModel();
    }
    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuildingReady, facets]);

  // listen to questionResponses changes
  useEffect(() => {
    if (!isBuildingReady) return;
    if (facets) return;
    const { walls, roofs } = building.features;

    walls.forEach((wall: any) => {
      if (questionResponses[wall.name] === true) {
        setSelected(wall);
      } else {
        setUnselected(wall);
      }
    });

    roofs.forEach((roof: any) => {
      if (questionResponses[roof.name] === true) {
        setSelected(roof);
      } else {
        setUnselected(roof);
      }
    });

    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionResponses, isBuildingReady, facets]);

  return (
    <Box
      flex={{ base: 0, tablet: 1 }}
      height="100%"
      flexDirection="column"
      alignSelf="center"
      padding={300}
      data-test-id="3DModel"
      width={1}
    >
      <AspectRatio ratio={widescreen ? 16 / 9 : 4 / 3}>
        <Box flex={1} ref={canvasRef}>
          {!building && (
            <Box
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner label="Loading model" size="large" />
              <Box>Loading model...</Box>
            </Box>
          )}
        </Box>
      </AspectRatio>
      <IconMessaging />
    </Box>
  );
};
/* eslint-enable no-param-reassign */
