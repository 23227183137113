import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
  EstimationDiscountDiscountTypeEnum,
  EstimationDiscountUpdate,
} from 'src/api/graphql-global-types';
import { updateDiscount as EstimationDiscountUpdateMutation } from 'src/api/types/updateDiscount';
import {
  GET_ESTIMATE_GROUP,
  UPDATE_DISCOUNT,
} from 'src/features/exteriorEstimator/apis/queries';
import { PromotionModal } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/PromotionModal';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getEstimateGroupIdFromLocation,
  getSelectedPromotion,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  calculatePercentage,
  calculateDollars,
} from 'src/features/exteriorEstimator/utils/discountsUtils';
import { getVariationsFilter } from 'src/redux/selectors';

type Props = {
  estimateTotal: number;
  closeModal: () => void;
};

export const EditPromotionModal: React.FC<Props> = ({
  estimateTotal: total,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const discount = useSelector(getSelectedPromotion);
  const {
    discountType = EstimationDiscountDiscountTypeEnum.PERCENTAGE,
    name,
    value,
    id,
  } = discount;

  const [updateDiscount] = useMutation<EstimationDiscountUpdateMutation>(
    UPDATE_DISCOUNT,
    {
      onCompleted: ({ estimationDiscountUpdate }) => {
        if (estimationDiscountUpdate?.discount) {
          dispatch(
            estimatorActions.receiveDiscount({
              discount: estimationDiscountUpdate.discount,
            }),
          );
        }
        closeModal();
      },
      refetchQueries: [
        {
          query: GET_ESTIMATE_GROUP,
          variables: {
            id: estimateGroupId,
            ...variationsFilter,
            active: true,
          },
        },
      ],
    },
  );

  const handleUpdate = (discountAttributes: EstimationDiscountUpdate) => {
    updateDiscount({
      variables: {
        id,
        discountAttributes,
      },
    });
  };

  return (
    <PromotionModal
      closeModal={closeModal}
      discountType={discountType}
      percentValue={calculatePercentage({
        value,
        discountType,
        total,
      })}
      dollarValue={calculateDollars({ value, discountType, total })}
      name={name}
      updatePromotion={handleUpdate}
      displayVerb="Edit"
    />
  );
};
