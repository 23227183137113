import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { IconButton } from '@hover/blueprint';
import { iTrash } from '@hover/icons';

import { ConfirmDeleteModal } from 'src/features/settings/components/common/ConfirmDeleteModal';

import { DELETE_TEMPLATE_QUESTION } from '../../api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from '../../api/queries/inspectionChecklist';

interface TemplateQuestionDeleteIconProps {
  templateId: string;
  templateQuestionId: string;
}

export const TemplateQuestionDeleteIcon = ({
  templateId,
  templateQuestionId,
}: TemplateQuestionDeleteIconProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteTemplateQuestion, { loading }] = useMutation(
    DELETE_TEMPLATE_QUESTION,
    {
      variables: {
        id: templateQuestionId,
      },
      refetchQueries: [
        {
          query: GET_INSPECTION_CHECKLIST_TEMPLATE,
          variables: { id: templateId },
        },
      ],
    },
  );

  const showConfirmationModal = () => {
    setIsOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsOpen(false);
  };

  const deleteQuestion = async () => {
    await deleteTemplateQuestion();
    hideConfirmationModal();
  };

  return (
    <>
      <IconButton
        label="DeleteTemplateQuestion"
        color="neutral"
        icon={iTrash}
        fill="minimal"
        onClick={showConfirmationModal}
      />
      <ConfirmDeleteModal
        isOpen={isOpen}
        isLoading={loading}
        message="Are you sure you want to delete this question?"
        onCancel={hideConfirmationModal}
        onConfirm={deleteQuestion}
      />
    </>
  );
};
