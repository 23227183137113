const enum NewRelicEventType {
  BUILDING_FULLY_LOADED = 'BuildingLoaded',
  GEOMETRY_AND_METADATA_LOADED = 'GeometryAndMetadataLoaded',
  COMPONENT_MOUNTED = 'ComponentMounted',
}

const emitPageAction = (actionName: NewRelicEventType) => {
  if (window.newrelic) {
    window.newrelic.addPageAction(actionName);
  }
};

export { emitPageAction, NewRelicEventType };
