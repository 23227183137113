import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Heading, Link } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { PageDirection, Pagination } from 'src/components/blueprint/Pagination';
import { Search } from 'src/components/blueprint/Search';
import { GET_LINE_ITEMS } from 'src/features/settings/api/queries/lineItems';
import { LineItemsContent } from 'src/features/settings/components/LineItems/LineItemsContent';
import { useTracking } from 'src/hooks';
import { usePagination } from 'src/hooks/usePagination';
import { useSearchParams } from 'src/hooks/useSearchParams';
import {
  FeatureFlag,
  UNIT_COST_IN_LINE_EDIT_CHANGE_LOG,
} from 'src/lib/FeatureFlag';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const LineItemsList: React.FC = () => {
  const orgId = useSelector(getUserOrgId);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.lineItems.page,
      ...commonTrackingProps,
    });
    // Runs once on page load for Segment tracking.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { updateParams, searchParams, removeParam } = useSearchParams();
  const { onPaginate, paginationQueryParams } = usePagination();

  const search = searchParams.get('search');
  const after = searchParams.get('after');
  const before = searchParams.get('before');

  const { data, loading, refetch, fetchMore } = useQuery(GET_LINE_ITEMS, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId,
      active: true,
      search: search || undefined,
      ...paginationQueryParams({ after, before }),
    }, // default variables hydrated from url via hook
  });

  const endCursor = data?.estimationConfigLineItems?.pageInfo?.endCursor;
  const startCursor = data?.estimationConfigLineItems?.pageInfo?.startCursor;

  const onSearchSubmit = (updatedSearch: string) => {
    // sync url
    updateParams({
      search: updatedSearch,
      before: undefined,
      after: undefined,
    });
    // update list items
    refetch({ search: updatedSearch, before: undefined, after: undefined });
  };

  const trackLearnMore = () => {
    typewriter.linkPressed({
      link_text: 'Learn More',
      primary_cta: false,
      page_or_screen_name: EventNames.settings.lineItems.page,
      ...commonTrackingProps,
    });
  };

  const handlePageClick = (direction: PageDirection) => {
    onPaginate({ direction, updateParams, fetchMore, startCursor, endCursor });
  };

  const onSearchClear = () => {
    removeParam('search');
    refetch({ search: undefined });
  };

  return (
    <FeatureFlag
      flagName={UNIT_COST_IN_LINE_EDIT_CHANGE_LOG}
      whenOn={
        <Box data-test-id="lineItemsList" flexDirection="column">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={400}
          >
            <Heading
              data-test-id="lineItemsHeader"
              size={600}
              marginBottom="0px"
            >
              Line Items
            </Heading>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                fontWeight="bold"
                marginRight={500}
                onClick={trackLearnMore}
                href="https://help.hover.to/en/articles/5647511-line-item-management"
              >
                Learn More
              </Link>
              <Search
                initialValue={search ?? undefined}
                onSubmit={onSearchSubmit}
                onClear={onSearchClear}
              />
            </Box>
          </Box>
          <Box flexDirection="column">
            <LineItemsContent loading={loading} data={data} search={search} />
            <Pagination
              hasPrevious={
                data?.estimationConfigLineItems?.pageInfo?.hasPreviousPage
              }
              hasNext={data?.estimationConfigLineItems?.pageInfo?.hasNextPage}
              handlePageClick={handlePageClick}
              resultsCount={data?.estimationConfigLineItems?.nodes?.length}
            />
          </Box>
        </Box>
      }
    />
  );
};
