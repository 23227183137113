import { createAction } from 'typesafe-actions';

import { QuestionResponses } from 'src/features/exteriorEstimator/types';
import {
  HDFMeasurements,
  PartialsMeasurements,
} from 'src/features/exteriorEstimator/types/HdfMeasurements';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

export const setupEstimatorEnd = createAction(
  'EXTERIOR_ESTIMATOR/SETUP_ESTIMATOR_END',
)();

export const setQuestionResponsesEnd = createAction(
  'EXTERIOR_ESTIMATOR/SET_QUESTION_RESPONSES_END',
  ({
    emptyQuestionResponses,
  }: {
    emptyQuestionResponses: QuestionResponses;
  }) => ({
    emptyQuestionResponses,
  }),
)();

export const setCurrentQuestionCategory = createAction(
  'EXTERIOR_ESTIMATOR/SET_CURRENT_QUESTION_CATEGORY',
  (category: string) => ({ category }),
)();

export const getModels = createAction(
  'EXTERIOR_ESTIMATOR/GET_MODELS',
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ models }: { models: any }) => ({
    models,
  }),
)();

export const storeFullMeasurements = createAction(
  'EXTERIOR_ESTIMATOR/STORE_FULL_MEASUREMENTS',
  ({ fullMeasurements }: { fullMeasurements: Measurements }) => ({
    fullMeasurements,
  }),
)();

export const storeHdfMeasurements = createAction(
  'EXTERIOR_ESTIMATOR/STORE_HDF_MEASUREMENTS',
  ({ hdfMeasurements }: { hdfMeasurements: HDFMeasurements }) => ({
    hdfMeasurements,
  }),
)();

export const storePartialsMeasurements = createAction(
  'EXTERIOR_ESTIMATOR/STORE_PARTIALS_MEASUREMENTS',
  ({
    partialsMeasurements,
  }: {
    partialsMeasurements: PartialsMeasurements;
  }) => ({
    partialsMeasurements,
  }),
)();

export const shouldRenderPage = createAction(
  'EXTERIOR_ESTIMATOR/SHOULD_RENDER_PAGE',
  ({
    pageIndex,
    shouldRender,
  }: {
    pageIndex: number;
    shouldRender: boolean;
  }) => ({ pageIndex, shouldRender }),
)();
