import { useMutation } from '@apollo/client';
import { Box, Button } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ProductCatalogMatchGroupCreateInput,
  ProductCatalogMatchGroupUpdateInput,
} from 'src/api/graphql-global-types';
import { productCatalogMatchGroup_productCatalogMatchGroup as MatchGroup } from 'src/api/types/productCatalogMatchGroup';
import {
  productCatalogMatchGroupCreate as MatchGroupCreateResponse,
  productCatalogMatchGroupCreate_productCatalogMatchGroupCreate_errors as Errors,
} from 'src/api/types/productCatalogMatchGroupCreate';
import { productCatalogMatchGroupUpdate as MatchGroupUpdateResponse } from 'src/api/types/productCatalogMatchGroupUpdate';
import {
  MATCH_GROUP_CREATE,
  MATCH_GROUPS,
  MATCH_GROUP_UPDATE,
} from 'src/features/settings/api/queries/calculationRules';
import { CreateFormValues } from 'src/features/settings/components/ColorGroupingRules/types';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const CreateEditFooter = ({
  editMode,
  formValues,
  saveDisabled,
  matchGroup,
}: {
  editMode: boolean;
  formValues: CreateFormValues;
  saveDisabled: boolean;
  matchGroup?: MatchGroup;
}) => {
  const toast = useToastEhi();
  const history = useHistory();
  const orgId = useSelector(getUserOrgId);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const onCompleted = (
    response: MatchGroupCreateResponse | MatchGroupUpdateResponse,
  ) => {
    const {
      matchGroup: matchGroupResponse,
      errors,
    }: { matchGroup: MatchGroup; errors: Errors[] } =
      (response as any).productCatalogMatchGroupCreate ||
      (response as any).productCatalogMatchGroupUpdate;

    if (errors && errors.length > 0) {
      toast({
        id: 'create-edit-footer-error-toast',
        description: `There was a problem ${
          editMode ? 'updating' : 'creating'
        } the rule. Please try again`,
        status: ToastStatusEnum.ERROR,
      });
    } else if (matchGroupResponse) {
      history.push(`/workflows/color_grouping_rules`);
      toast({
        id: 'create-edit-footer-success-toast',
        description: `Rule ${matchGroupResponse.name} successfully ${
          editMode ? 'updated' : 'created'
        }`,
        status: ToastStatusEnum.SUCCESS,
      });
    }
  };

  const [matchGroupCreate, { loading: isCreatingMatchGroup }] = useMutation(
    MATCH_GROUP_CREATE,
    {
      onCompleted,
      onError: () => {
        toast({
          id: 'create-edit-footer-match-group-create-error-toast',
          description: `There was a problem creating the rule. Please try again`,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: MATCH_GROUPS,
          variables: {
            orgId,
          },
        },
      ],
    },
  );

  const [matchGroupUpdate, { loading: matchGroupUpdateLoading }] = useMutation(
    MATCH_GROUP_UPDATE,
    {
      onCompleted,
      onError: () => {
        toast({
          id: 'create-edit-footer-match-group-update-error-toast',
          description: `There was a problem updating the rule. Please try again`,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: MATCH_GROUPS,
          variables: {
            orgId,
          },
        },
      ],
    },
  );

  const handleCreateEdit = (type: 'create' | 'edit') => {
    const matchGroupAttributes:
      | ProductCatalogMatchGroupCreateInput
      | ProductCatalogMatchGroupUpdateInput = {
      name: formValues.name,
      description: formValues.description,
      active: matchGroup ? matchGroup.active : false,
      matchGroupCategoriesAttributes: formValues.dropdowns
        .filter((d) => d.value !== null)
        .map((d) => {
          return { productCatalogCategoryId: d.value ?? '' };
        }),
    };
    if (type === 'create')
      matchGroupCreate({
        variables: {
          orgId,
          matchGroupAttributes,
        },
      });
    else
      matchGroupUpdate({
        variables: {
          matchGroupId: matchGroup?.id,
          matchGroupAttributes,
        },
      });

    typewriter.buttonPressed({
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      button_text: 'Save',
      primary_cta: false,
      backend_id_type: 'MatchGroup',
      backend_id_value: matchGroup?.id,
      ...commonTrackingProps,
    });
  };

  const handleCancel = () => {
    typewriter.buttonPressed({
      page_or_screen_name: editMode
        ? EventNames.settings.colorGrouping.edit
        : EventNames.settings.colorGrouping.create,
      button_text: 'Cancel',
      primary_cta: false,
      backend_id_type: 'MatchGroup',
      backend_id_value: matchGroup?.id,
      ...commonTrackingProps,
    });
    history.push(`/workflows/color_grouping_rules`);
  };

  const handleSave = () => {
    if (editMode) {
      handleCreateEdit('edit');
    } else {
      handleCreateEdit('create');
    }
  };
  return (
    <Box
      as="footer"
      paddingTop={400}
      alignItems="center"
      gap={400}
      justifyContent="flex-end"
    >
      <Button
        fill="outline"
        onClick={handleCancel}
        data-test-id="categories-Cancel"
      >
        Cancel
      </Button>
      <Button
        onClick={handleSave}
        isDisabled={saveDisabled}
        isLoading={isCreatingMatchGroup || matchGroupUpdateLoading}
        data-test-id="categories-Save"
      >
        Save
      </Button>
    </Box>
  );
};
