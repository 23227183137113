import { createReducer } from 'typesafe-actions';

import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import { initialState } from 'src/features/exteriorEstimator/redux/initialState';

export const jobReducer = createReducer(initialState)
  .handleAction(estimatorActions.getJobDetails.success, (state, action) => {
    return {
      ...state,
      job: {
        ...state.job,
        jobDetails: action.payload.jobDetails,
      },
    };
  })
  .handleAction(estimatorActions.getModels, (state, action) => ({
    ...state,
    job: {
      ...state.job,
      models: action.payload.models,
    },
  }));
