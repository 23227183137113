import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types';

interface UseDownloadPdfModalProps {
  jobId: number;
}

export function useDownloadPdfModal({ jobId }: UseDownloadPdfModalProps) {
  const [showDownloadPdfModal, setShowDownloadPdfModal] =
    useState<boolean>(false);
  const [downloadPdfLineItemType, setDownloadPdfLineItemType] =
    useState<LineItemTypeEnum | null>(null);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const closeDownloadPdfModal = useCallback(() => {
    setShowDownloadPdfModal(false);
    setDownloadPdfLineItemType(null);
  }, [setShowDownloadPdfModal]);

  const openDownloadPdfModal = useCallback(
    (lineItemType: LineItemTypeEnum) => {
      setDownloadPdfLineItemType(lineItemType);
      setShowDownloadPdfModal(true);
      // Segment tracking.
      typewriter.buttonPressed({
        button_text: 'Download PDF',
        page_or_screen_name: EventNames.project.scope.page,
        job_id: jobId,
        primary_cta: false,
        ...commonTrackingProps,
      });
    },
    [jobId, commonTrackingProps],
  );

  return {
    showDownloadPdfModal,
    downloadPdfLineItemType,
    openDownloadPdfModal,
    closeDownloadPdfModal,
  };
}
