import { gql } from '@apollo/client';

export const GET_SALES_PRESENTATIONS = gql`
  query getSalesPresentations($orgId: ID!) {
    salesPresentationPresentations(orgId: $orgId) {
      id
      name
      state
      updatedAt
      createdAt
      pdf {
        redirectUrl
      }
    }
  }
`;

export const fileUploadCreateFields = gql`
  fragment fileUploadCreateFields on FileUploadCreatePayload {
    fileUpload {
      signedBlobId
      url
      headers
    }
    errors {
      attribute
      errors
    }
  }
`;

export const UPLOAD_SALES_PRESENTATION = gql`
  mutation salesPresentationPresentationUpload(
    $fileAttributes: FileUploadCreateInput!
  ) {
    salesPresentationFileUploadCreate(fileAttributes: $fileAttributes) {
      ...fileUploadCreateFields
    }
  }
  ${fileUploadCreateFields}
`;

export const CREATE_SALES_PRESENTATION = gql`
  mutation salesPresentationPresentationCreate(
    $presentationAttributes: SalesPresentationPresentationCreateInput!
  ) {
    salesPresentationPresentationCreate(
      presentationAttributes: $presentationAttributes
    ) {
      presentation {
        id
      }
      errors {
        errors
      }
    }
  }
`;

export const ARCHIVE_SALES_PRESENTATION = gql`
  mutation salesPresentationPresentationArchive($id: ID!) {
    salesPresentationPresentationArchive(id: $id) {
      salesPresentation {
        id
        name
      }
      errors {
        errors
        attribute
      }
    }
  }
`;

export const UPDATE_SALES_PRESENTATION = gql`
  mutation salesPresentationPresentationUpdate(
    $presentationAttributes: SalesPresentationPresentationUpdateInput!
  ) {
    salesPresentationPresentationUpdate(
      presentationAttributes: $presentationAttributes
    ) {
      presentation {
        id
        name
        state
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_SALES_PRESENTATION_STATE = gql`
  mutation salesPresentationPresentationStateToggle($id: ID!) {
    salesPresentationPresentationStateToggle(id: $id) {
      salesPresentation {
        id
        name
        state
      }
      errors {
        attribute
        errors
      }
    }
  }
`;
