import { useState, useEffect } from 'react';

import { Box, Body, Icon, Heading, Image } from '@hover/blueprint';
import { iCalendar, iStar } from '@hover/icons';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { EarlyAccess } from 'src/features/settings/components/SalesPresentation/EarlyAccess';
import { InfoCard } from 'src/features/settings/components/SalesPresentation/InfoCard';
import { PostSubmit } from 'src/features/settings/components/SalesPresentation/PostSubmit';
import { PostSurvey as PostSurveyComponent } from 'src/features/settings/components/SalesPresentation/PostSurvey';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import {
  FeatureFlag,
  WORKFLOWS_SALES_PRESENTATION_INTEREST,
  PA_SALES_PRES_PDF_UPLOAD,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { getUserTrackingProps } from 'src/redux/selectors';

import { SalesPresentationListView } from './SalesPresentationListView';

enum EarlyAccessState {
  NoResponse,
  OnEarlyAccess,
  PostSurvey,
}

export const SalesPresentation: React.FC = () => {
  const [earlyAccessState, setEarlyAccessState] = useState(
    EarlyAccessState.NoResponse,
  );

  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffect(() => {
    const earlyAccess = localStorage.getItem('earlyAccessState');
    if (earlyAccess) {
      setEarlyAccessState(Number(earlyAccess));
    }
  }, []);

  const showToast = () => {
    toast({
      id: 'sales-presentation-success-toast',
      description: 'You are on the early access Sales presentation list!',
      status: ToastStatusEnum.SUCCESS,
    });
  };

  const updateEarlyAccessState = (currentState: EarlyAccessState) => {
    setEarlyAccessState(currentState);
    localStorage.setItem('earlyAccessState', currentState.toString());
  };

  const handleGetEarlyAccessClick = () => {
    typewriter.buttonPressed(
      {
        button_text: 'Get Early Access',
        page_or_screen_name: 'Sales Presentation Index',
        primary_cta: false,
        ...commonTrackingProps,
      },
      {},
      showToast,
    );
    updateEarlyAccessState(EarlyAccessState.OnEarlyAccess);
  };

  const handleClickSubmit = () => {
    typewriter.buttonPressed({
      button_text: 'Submit',
      page_or_screen_name: 'Sales Presentation Sign Up Info',
      primary_cta: true,
      ...commonTrackingProps,
    });
    updateEarlyAccessState(EarlyAccessState.PostSurvey);
  };

  const currentStep = {
    [EarlyAccessState.NoResponse]: (
      <EarlyAccess handleClick={handleGetEarlyAccessClick} />
    ),
    [EarlyAccessState.OnEarlyAccess]: (
      <PostSubmit handleClickSubmit={handleClickSubmit} />
    ),
    [EarlyAccessState.PostSurvey]: <PostSurveyComponent />,
  };

  return (
    <FeatureFlag
      flagName={WORKFLOWS_SALES_PRESENTATION_INTEREST}
      whenOn={
        earlyAccessState === EarlyAccessState.PostSurvey &&
        isEnabled(PA_SALES_PRES_PDF_UPLOAD) ? (
          <SalesPresentationListView />
        ) : (
          <Box
            flexDirection="column"
            maxWidth="1400px"
            margin="0 auto"
            paddingX={800}
            justifyContent="center"
            alignItems="center"
          >
            {currentStep[earlyAccessState]}
            <Box justifyContent="center" alignItems="center">
              <InfoCard
                icon={<Icon icon={iCalendar} color="#003E7D" size="large" />}
                heading="STREAMLINE YOUR SALES TOOL"
                body="Eliminate the need to pay for multiple tools and licenses. HOVER will be your source of truth"
              />
              <InfoCard
                icon={<Icon icon={iStar} color="#003E7D" size="large" />}
                heading="LOOK PROFESSIONAL"
                body="Stop fumbling between tools during the appointment and use one tool for your entire sales process."
              />
            </Box>

            <Box justifyContent="space-between" width="100%">
              <Box mt={700} width="50%" justifyContent="flex-end">
                <Image
                  src="https://res.cloudinary.com/dsj4qc7bu/image/upload/v1661180608/device_image_jsphwg.png"
                  alt="Device Images"
                  height="auto"
                  width="100%"
                />
              </Box>
              <Box flexDirection="column" width="50%" p={800}>
                <Heading size={400}>How it works</Heading>
                <Body>
                  Our creative team will work with you to highlight testimonials
                  and products. HOVER can help you design design and design and
                  built out personalized sales presentations.
                </Body>
              </Box>
            </Box>
          </Box>
        )
      }
      whenOff={<Redirect to="/workflows" />}
    />
  );
};
