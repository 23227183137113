import styled from 'styled-components';

import { CircularButton } from 'style-guide';

export const Text = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

export const AddButton = styled(CircularButton)`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
  z-index: 11;
  border-style: none;
`;
