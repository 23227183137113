import { gql } from '@apollo/client';

export const CREATE_DISCOUNT = gql`
  mutation createDiscount($discountAttributes: EstimationDiscountCreate!) {
    estimationDiscountCreate(discountAttributes: $discountAttributes) {
      discount {
        id
        name
        discountType
        value
        estimationEstimateId
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount(
    $id: ID!
    $discountAttributes: EstimationDiscountUpdate!
  ) {
    estimationDiscountUpdate(id: $id, discountAttributes: $discountAttributes) {
      discount {
        id
        name
        discountType
        value
        estimationEstimateId
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($id: ID!) {
    estimationDiscountDelete(id: $id) {
      errors {
        attribute
        errors
      }
    }
  }
`;
