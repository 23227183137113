/* eslint-disable react/require-default-props */

import { useCallback } from 'react';

import { Box, Button, Heading, Icon, Link } from '@hover/blueprint';
import { iX } from '@hover/icons';

interface Props {
  header: string;
  subText?: string;
  helpLinkText?: string;
  buttonText: string;
  onClick?: () => void;
}

export const Error = (props: Props) => {
  const { header, subText, helpLinkText, buttonText, onClick } = props;

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Box width="100vw" justifyContent="center">
      <Box
        width={{ base: 1, mobile: 1, tablet: 1, desktop: 0.6 }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingTop={1000}
        paddingX={800}
        paddingBottom={800}
      >
        <Icon
          data-test-id="error-icon"
          size="huge"
          marginBottom={800}
          icon={iX}
        />
        <Heading
          size={500}
          textAlign="center"
          marginBottom={600}
          data-test-id="error-header"
        >
          {header}
        </Heading>
        {subText && (
          <Heading
            size={400}
            textAlign="center"
            marginBottom={600}
            data-test-id="error-subtext"
          >
            {subText}
          </Heading>
        )}
        <Button
          size="large"
          marginBottom={600}
          data-test-id="error-back-button"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
        {helpLinkText && (
          <Link href="/contact/" target="_blank" textAlign="center">
            {helpLinkText}
          </Link>
        )}
      </Box>
    </Box>
  );
};
