import * as miscActions from './miscActions';
import * as userActions from './userActions';

export * from './miscActions';
export * from './userActions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default {
  ...miscActions,
  ...userActions,
};
