import { useMutation, DocumentNode } from '@apollo/client';
import * as Sentry from '@sentry/react';
import md5 from 'js-md5';

import { FileUploadCreateInput } from 'src/api/graphql-global-types';
import { fileUploadCreateFields } from 'src/api/types/fileUploadCreateFields';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';

export const useFileUpload = <UploadResponse extends fileUploadCreateFields>(
  fileUploadQuery: DocumentNode,
  mutationAlias: string,
) => {
  type MutationResponse = Record<string, UploadResponse>;

  const [uploadFileMutation] = useMutation<MutationResponse>(fileUploadQuery);
  const toast = useToastEhi();

  const fileUploadErrorToast = () => {
    toast({
      id: 'file-upload-error-toast',
      description: 'There was a problem uploading the input file',
      status: ToastStatusEnum.ERROR,
    });
  };

  const getFileRawData = async (file: File) => {
    return file.arrayBuffer();
  };

  const getFileAttributes = async (
    fileArrayBuffer: ArrayBuffer,
    fileName: string,
  ) => {
    const checksum = md5.base64(fileArrayBuffer);
    const attributes: FileUploadCreateInput = {
      byteSize: fileArrayBuffer.byteLength,
      checksum,
      contentType: 'application/pdf',
      filename: fileName,
    };

    return attributes;
  };

  const uploadFile = async (file: File): Promise<string> => {
    let signedBlobId = '';
    const rawData = await getFileRawData(file);
    const fileAttributes = await getFileAttributes(rawData, file.name);

    try {
      const { data, errors: highLevelErrors } = await uploadFileMutation({
        variables: {
          fileAttributes,
        },
      });

      if (highLevelErrors) {
        throw new Error(highLevelErrors[0].message);
      }

      const fileUploadCreateResponse = data && data[mutationAlias];

      if (!fileUploadCreateResponse) {
        throw new Error(`No response from ${mutationAlias}`);
      }

      const { errors, fileUpload } = fileUploadCreateResponse;

      if (errors?.length > 0) {
        throw new Error(errors[0].errors[0]);
      }

      if (!fileUpload) {
        throw new Error('Missing fileUpload response');
      }

      const { headers, url } = fileUpload;
      const fetchHeaders = JSON.parse(headers);

      const fetchResp = await fetch(url, {
        headers: fetchHeaders,
        method: 'PUT',
        body: rawData,
      });

      if (!fetchResp.ok) {
        throw new Error('Upload to fileUploadUrl failed');
      }

      signedBlobId = fileUpload?.signedBlobId;
    } catch (error) {
      fileUploadErrorToast();
      Sentry.captureException(error);
    }
    return signedBlobId;
  };

  return { uploadFile };
};
