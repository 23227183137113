// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useState, useRef, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  Icon,
  Box,
  Button,
  TextInput,
  useTheme,
  Td,
  Tr,
} from '@hover/blueprint';
import { iClock } from '@hover/icons';
import { lowerCase, startCase } from 'lodash';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { estimationConfigLineItemsSettings_estimationConfigLineItems_nodes as LineItemType } from 'src/api/types/estimationConfigLineItemsSettings';
import { UPDATE_LINE_ITEM } from 'src/features/settings/api/queries/lineItems';
import { CancelEditModal } from 'src/features/settings/components/common/CancelEditModal';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { formatTrades } from 'src/utils/Formatters';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

interface LineItemProps {
  lineItem: LineItemType;
  openVersionHistoryModal: (lineItem: LineItemType) => void;
}

export const LineItem: React.FC<LineItemProps> = ({
  lineItem: defaultLineItem,
  openVersionHistoryModal,
}) => {
  const theme = useTheme();
  const toast = useToastEhi();
  const inputRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [lineItem, setLineItem] = useState(defaultLineItem);
  const [unitCost, setUnitCost] = useState<string>(lineItem.unitCost);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const openCancelModal = () => setIsCancelModalOpen(true);
  const handleClickLeave = () => {
    typewriter.buttonPressed({
      button_text: 'Leave',
      page_or_screen_name: EventNames.settings.lineItems.page,
      primary_cta: false,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      ...commonTrackingProps,
    });

    setIsEditing(false);
    setUnitCost(lineItem.unitCost);
    setIsCancelModalOpen(false);
  };

  const handleClickKeepEditing = () => {
    typewriter.buttonPressed({
      button_text: 'Keep Editing',
      page_or_screen_name: EventNames.settings.lineItems.page,
      primary_cta: false,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      ...commonTrackingProps,
    });

    setIsCancelModalOpen(false);
  };

  const onClick = () => {
    setIsEditing(true);
    typewriter.textInput({
      page_or_screen_name: EventNames.settings.lineItems.page,
      input_value: '',
      input_label: lineItem.name,
      ...commonTrackingProps,
    });
  };

  const onClickHistory = () => {
    typewriter.buttonPressed({
      button_text: 'Activity History',
      page_or_screen_name: EventNames.settings.lineItems.page,
      primary_cta: false,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      ...commonTrackingProps,
    });

    openVersionHistoryModal(lineItem);
  };

  useEffect(() => {
    if (isEditing) inputRef?.current?.focus();
  });

  const onSaved = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.lineItems.page,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      lineItemCost: unitCost,
      successfullySaved: true,
      ...commonTrackingProps,
    });

    setIsEditing(false);
    toast({
      id: 'line-item-save-success-toast',
      description: 'Cost changes successfully saved',
      status: ToastStatusEnum.SUCCESS,
    });
  };

  const onSaveError = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.lineItems.page,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      lineItemCost: unitCost,
      successfullySaved: false,
      ...commonTrackingProps,
    });

    toast({
      id: 'line-item-save-error-toast',
      description: 'There was an issue with saving, please try again',
      status: ToastStatusEnum.WARNING,
    });
  };

  const [updateLineItem] = useMutation(UPDATE_LINE_ITEM, {
    onCompleted: (data) => {
      onSaved();
      setLineItem({
        ...lineItem,
        unitCost: data.estimationConfigLineItemUpdate.lineItem.unitCost,
        updatedAt: data.estimationConfigLineItemUpdate.lineItem.updatedAt,
      });
    },
    onError: onSaveError,
  });

  const onSave = () => {
    typewriter.buttonPressed({
      button_text: 'Save',
      page_or_screen_name: EventNames.settings.lineItems.page,
      primary_cta: false,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      lineItemCost: unitCost,
      ...commonTrackingProps,
    });

    updateLineItem({
      variables: {
        id: lineItem.id,
        lineItemAttributes: { unitCost: Number(unitCost) },
      },
    });
  };

  const isValid = unitCost === '';

  const isDirty = String(unitCost) !== String(lineItem.unitCost);

  const onCancel = () => {
    typewriter.buttonPressed({
      button_text: 'Cancel',
      page_or_screen_name: EventNames.settings.lineItems.page,
      primary_cta: false,
      lineItemName: lineItem.name,
      lineItemId: lineItem.id,
      ...commonTrackingProps,
    });

    if (isDirty) {
      openCancelModal();
    } else {
      setIsEditing(false);
    }
  };

  const onMouseDownSave = () => {
    saveButtonRef?.current?.focus();
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBlur = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const relatedTargetAriaLabel = e?.relatedTarget?.getAttribute('aria-label');

    if (
      relatedTargetAriaLabel === 'Keep Editing' ||
      relatedTargetAriaLabel === 'Close' || // Keep Editing and Close are the same button, but Blueprint seems to reassign the aria label on the modal button
      relatedTargetAriaLabel === 'Save'
    )
      return; // don't turn off Edit Mode if Save button is pressed. onSave will handle that.
    onCancel();
  };

  return (
    <Tr
      key={lineItem.id}
      data-test-id={`ListItemRow-${lineItem.id}`}
      _hover={{ backgroundColor: theme.colors.primary100 }}
    >
      <Td width="35%">{lineItem.name}</Td>
      <Td>{lineItem.type ? startCase(lowerCase(lineItem.type)) : '--'}</Td>
      <Td>{lineItem.tradeTypes ? formatTrades(lineItem.tradeTypes) : '--'}</Td>
      <Td isNumeric>
        <Box display="block" textAlign="right" marginX={400}>
          {lineItem.templatesCount}
        </Box>
      </Td>
      <Td isNumeric width="12%">
        <Box
          data-test-id="lineItemUnitCostWrapper"
          onClick={onClick}
          paddingLeft={isEditing ? 'none' : '10px'}
          paddingRight={isEditing ? 'none' : '10px'}
          backgroundColor={isEditing ? 'none' : theme.colors.primary100}
          paddingTop="2px"
          marginX={400}
          borderRadius="3px"
          height="24px"
          _hover={{
            borderColor: isEditing ? ' none' : theme.colors.primary700,
            borderWidth: isEditing ? ' none' : '1px',
            borderStyle: isEditing ? ' none' : 'solid',
            paddingTop: isEditing ? ' none' : '0',
          }}
          justifyContent="right"
        >
          <NumericFormat
            aria-label="numberInput"
            customInput={TextInput}
            size="tiny"
            displayType={isEditing ? 'input' : 'text'}
            getInputRef={inputRef}
            autoFocus
            data-test-id="lineItem-unitCostInput"
            value={unitCost}
            onValueChange={(values) => {
              const { value } = values;
              setUnitCost(value);
            }}
            onBlur={onBlur}
            decimalScale={2}
            fixedDecimalScale
          />
        </Box>
      </Td>
      <Td>{lineItemQuantityUnits(lineItem.quantityUnits)}</Td>
      <Td>
        {isEditing ? (
          <Box flexDirection="row">
            <Button
              width={67}
              marginRight={400}
              data-test-id="lineItem-UnitCostEditSave"
              onMouseDown={onMouseDownSave}
              onClick={onSave}
              isDisabled={isValid || !isDirty}
              ref={saveButtonRef}
            >
              Save
            </Button>
            <Button
              width={67}
              fill="minimal"
              onClick={onCancel}
              data-test-id="lineItem-UnitCostEditCancel"
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Box display="inline-flex" data-test-id="lineItem-updatedAt">
            {new Date(lineItem.updatedAt).toLocaleString()}
          </Box>
        )}
      </Td>
      <Td>
        <Button
          label="historyIcon"
          data-test-id="historyIcon"
          type="button"
          fill="minimal"
          shape="square"
          onClick={onClickHistory}
        >
          <Icon color="neutral600" icon={iClock} />
        </Button>
      </Td>
      <CancelEditModal
        isOpen={isCancelModalOpen}
        onCancel={handleClickKeepEditing}
        onLeave={handleClickLeave}
        itemType="Line Item"
        itemId={lineItem.id}
      />
    </Tr>
  );
};
