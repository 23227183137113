import { useMemo } from 'react';

import { Button, Box, Icon } from '@hover/blueprint';
import { iAlertCircle, iCheck } from '@hover/icons';
import { push } from 'connected-react-router';
import pluralize from 'pluralize';
import { useSelector, useDispatch } from 'react-redux';

import { estimationEstimate_estimationEstimate_lineItems as LineItem } from 'src/api/types/estimationEstimate';
import { EstimateGroupBottomBar } from 'src/features/exteriorEstimator/components/common/EstimateGroupBottomBar';
import {
  getEstimateGroupIdFromLocation,
  getRouter,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  lineItemsWithVariations?: LineItem[];
}

export const VariationSelectionFooter: React.FC<Props> = ({
  lineItemsWithVariations,
}) => {
  const router = useSelector(getRouter);
  const dispatch = useDispatch();
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const unselectedVariationCount = useMemo(
    () =>
      lineItemsWithVariations
        ? lineItemsWithVariations.filter(
            (lineItem) => !lineItem.selectedVariation,
          ).length
        : null,
    [lineItemsWithVariations],
  );

  const unselectedVariationIndicator = () => {
    if (unselectedVariationCount === null) return null;

    if (unselectedVariationCount === 0) {
      return (
        <>
          <Icon icon={iCheck} />
          <Box> All variations selected</Box>
        </>
      );
    }

    return (
      <>
        <Icon icon={iAlertCircle} color="warning.600" />
        <Box>{`${unselectedVariationCount} ${pluralize(
          'variation',
          unselectedVariationCount,
        )} not selected`}</Box>
      </>
    );
  };

  const onPress = () => {
    dispatch(
      push({
        pathname: `/estimator/estimates/${estimateGroupId}`,
        search: router.location.search,
      }),
    );

    typewriter.buttonPressed({
      backend_id_type: 'estimate_group_id',
      backend_id_value: estimateGroupId,
      button_text: 'Done',
      page_or_screen_name: EventNames.estimator.colorSelection,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  return (
    <EstimateGroupBottomBar>
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          {unselectedVariationIndicator()}
        </Box>
        <Button
          onClick={onPress}
          shape="box"
          data-test-id="VariationSelectionFooter-Done"
        >
          Done
        </Button>
      </Box>
    </EstimateGroupBottomBar>
  );
};
