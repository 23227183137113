import styled from 'styled-components';

import StyleGuide from 'style-guide';

export const Modal = styled(StyleGuide.DefaultModal)`
  min-width: 790px;
  max-height: 400px;
`;

export const Button = styled(StyleGuide.Button)``;
