import { createReducer } from 'typesafe-actions';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import type {
  distributionApprovedBranchesQuery_distributionApprovedBranches as Branch,
  distributionApprovedBranchesQuery_distributionApprovedBranches_jobAccounts as JobAccount,
} from 'src/api/types/distributionApprovedBranchesQuery';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors_distributor as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import {
  updateSelectedListItems,
  updateDistributor,
  updateBranch,
  updateJobAccount,
  toggleSelectedItem,
  toggleSelectAllTypeItems,
  toggleSelectAllTradeItems,
  removeLineItem,
} from 'src/features/project/redux/actions';
import type { ListItemIdsByTypeAndTrade } from 'src/features/project/types';

type ProjectState = {
  selectedListItemIds: ListItemIdsByTypeAndTrade | null;
  distributor: Distributor | null;
  branch: Branch | null;
  jobAccount: JobAccount | null;
};

const initialState: ProjectState = {
  selectedListItemIds: null,
  distributor: null,
  branch: null,
  jobAccount: null,
};

export const project = createReducer(initialState)
  .handleAction(
    updateSelectedListItems,
    (state, { payload: { selectedListItemIds } }) => ({
      ...state,
      selectedListItemIds,
    }),
  )
  .handleAction(updateDistributor, (state, { payload: { distributor } }) => ({
    ...state,
    distributor,
  }))
  .handleAction(updateBranch, (state, { payload: { branch } }) => ({
    ...state,
    branch,
  }))
  .handleAction(updateJobAccount, (state, { payload: { jobAccount } }) => ({
    ...state,
    jobAccount,
  }))
  .handleAction(toggleSelectedItem, (state, { payload }) => {
    const { type, trade, itemId } = payload;

    const updatedSelectedListItemIds =
      (state.selectedListItemIds &&
        state.selectedListItemIds[type] &&
        state.selectedListItemIds[type][trade]) ||
      [];

    if (updatedSelectedListItemIds.includes(itemId.toString())) {
      updatedSelectedListItemIds.splice(
        updatedSelectedListItemIds.indexOf(itemId.toString()),
        1,
      );
    } else {
      updatedSelectedListItemIds.push(itemId.toString());
    }

    return {
      ...state,
      selectedListItemIds: {
        ...state.selectedListItemIds,
        ...{
          [type]: {
            ...((state.selectedListItemIds &&
              state.selectedListItemIds[type]) ||
              {}),
            ...{
              [trade]: updatedSelectedListItemIds ?? [],
            },
          },
        },
      } as ListItemIdsByTypeAndTrade,
    };
  })
  .handleAction(toggleSelectAllTypeItems, (state, { payload }) => {
    const { type, typeItemsByTrade } = payload;

    const totalSelectedItemsInType = Object.values(
      state.selectedListItemIds![type],
    ).flat().length;

    const trades = Object.keys(typeItemsByTrade) as TradeTypeEnum[];
    const itemIdsByTrade = trades.reduce((acc, tradeType) => {
      return {
        ...acc,
        ...{
          [tradeType]: typeItemsByTrade[tradeType].map((item) =>
            item.id.toString(),
          ),
        },
      };
    }, {});
    const totalItemsInType = Object.values(itemIdsByTrade).flat().length;

    // should unselect all
    if (totalSelectedItemsInType === totalItemsInType) {
      const emptyTradeObject = Object.keys(itemIdsByTrade).reduce(
        (acc, tradeType) => {
          return {
            ...acc,
            ...{
              [tradeType]: [],
            },
          };
        },
        {},
      );

      return {
        ...state,
        selectedListItemIds: {
          ...state.selectedListItemIds,
          ...{
            [type]: emptyTradeObject,
          },
        } as ListItemIdsByTypeAndTrade,
      };
    }

    // should select all
    return {
      ...state,
      selectedListItemIds: {
        ...state.selectedListItemIds,
        ...{
          [type]: itemIdsByTrade,
        },
      } as ListItemIdsByTypeAndTrade,
    };
  })
  .handleAction(toggleSelectAllTradeItems, (state, { payload }) => {
    const { type, trade, items } = payload;

    if (
      !state.selectedListItemIds ||
      !state.selectedListItemIds[type] ||
      !state.selectedListItemIds[type][trade]
    ) {
      return state;
    }

    const totalSelectedItems = state.selectedListItemIds[type][trade].length;

    // should un-check all items in trade
    if (totalSelectedItems === items.length) {
      return {
        ...state,
        selectedListItemIds: {
          ...state.selectedListItemIds,
          ...{
            [type]: {
              ...state.selectedListItemIds[type],
              ...{
                [trade]: [],
              },
            },
          },
        },
      };
    }

    // Should check all
    return {
      ...state,
      ...{
        selectedListItemIds: {
          ...state.selectedListItemIds,
          ...{
            [type]: {
              ...state.selectedListItemIds[type],
              ...{
                [trade]: items.map((item) => item.id.toString()),
              },
            },
          },
        },
      },
    };
  })
  .handleAction(removeLineItem, (state, { payload }) => {
    const { type, trade, itemId } = payload;

    if (
      !state.selectedListItemIds ||
      !state.selectedListItemIds[type] ||
      !state.selectedListItemIds[type][trade]
    ) {
      return state;
    }

    const tradeItems = state.selectedListItemIds[type][trade].filter(
      (id) => id.toString() !== itemId.toString(),
    );

    return {
      ...state,
      ...{
        selectedListItemIds: {
          ...state.selectedListItemIds,
          ...{
            [type]: {
              ...state.selectedListItemIds[type],
              ...{
                [trade]: tradeItems,
              },
            },
          },
        },
      },
    };
  });
