import { useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Heading,
  Button,
  Body,
  Icon,
  Label as _Label,
  LabelProps,
  Checkbox,
  CheckboxGroup,
  Tooltip,
  useTheme,
} from '@hover/blueprint';
import { iHelpCircle } from '@hover/icons';
import { isEmpty, isNil } from 'lodash';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { productCatalogEnabledOrgVariantsUpdate } from 'src/api/types/productCatalogEnabledOrgVariantsUpdate';
import {
  productCatalogProduct as ProductCatalogProductQueryType,
  productCatalogProduct_productCatalogProduct_variations as VariationsType,
  productCatalogProduct_productCatalogProduct as ProductType,
} from 'src/api/types/productCatalogProduct';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from 'src/components/blueprint/Breadcrumb';
import { PRODUCT_CATALOG_ENABLED_ORG_VARIANTS_UPDATE } from 'src/features/settings/api/queries/orgSettings';
import { GET_PRODUCT_CATALOG_PRODUCT } from 'src/features/settings/api/queries/products';
import { ProductHeader } from 'src/features/settings/components/common/ProductHeader';
import { constructOrgVariationsAttributes } from 'src/features/settings/utils/materials';
import { useTracking } from 'src/hooks';
import {
  getUserTrackingProps,
  getUserOrgId,
  getUserOrgVariationsFilter,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import type { NotificationHandlers } from '../Settings';

const Label = styled(_Label)<LabelProps>`
  :hover {
    cursor: pointer;
  }
`;

const EditButtons = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
`;

type Props = {
  variations: VariationsType[];
  product: ProductType;
  orgId: string;
};

type SelectedVariations = string[];
type FormData = {
  selectedVariationsGroup: SelectedVariations;
};
export const ProductEditContent: React.FC<Props & NotificationHandlers> = ({
  variations,
  product,
  orgId,
  setNotification,
}) => {
  const history = useHistory();
  const theme = useTheme();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.productVariantEdit.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  const onSaveError = () => {
    setNotification({
      show: true,
      notification: 'Something went wrong while saving. Try again.',
      severity: 'Error',
    });
  };

  const onSaved = ({
    productCatalogOrgSettingsUpdate,
  }: productCatalogEnabledOrgVariantsUpdate) => {
    if (isEmpty(productCatalogOrgSettingsUpdate?.errors)) {
      setNotification({
        show: true,
        notification: 'Enabled variants successfully updated.',
        severity: 'Confirmation',
      });
      history.push(`/workflows/materials/products/${product.id}`);
    } else onSaveError();
  };

  const [updateOrgEnabledVariations] = useMutation(
    PRODUCT_CATALOG_ENABLED_ORG_VARIANTS_UPDATE,
    {
      onCompleted: onSaved,
      onError: onSaveError,
      refetchQueries: [
        {
          query: GET_PRODUCT_CATALOG_PRODUCT,
          variables: { id: product.id, orgId },
        },
      ],
    },
  );

  // Get initial selection state of org variations.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getSelectedVariations = (variations: VariationsType[]): string[] => {
    const selectedVariations = variations
      .filter((variant) => {
        return !isNil(variant.orgVariation);
      })
      .map((selected) => selected.id)
      // Sort keeps array of values stable and comparable, and allows `isDirty` to work.
      .sort();
    return selectedVariations;
  };

  // Form-management react-hook-form, set initial form state.
  const {
    formState: { isDirty, isValid },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedVariationsGroup: getSelectedVariations(variations),
    },
  });

  // State of the "Select all/none" checkbox
  const selectedVariations = useWatch({
    control,
    name: 'selectedVariationsGroup',
  });
  const allChecked = selectedVariations.length === variations.length;
  const isIndeterminate =
    selectedVariations.length !== 0 &&
    selectedVariations.length !== variations.length;

  const handleSelectAllNone = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    typewriter.buttonPressed({
      button_text: 'Select Deselect All',
      page_or_screen_name: EventNames.settings.productVariantEdit.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    const { checked } = event.target;
    if (checked) {
      // Set all variations as selected
      const allVariations = variations.map((variation) => variation.id);
      setValue('selectedVariationsGroup', allVariations, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      // Set all variations as unselected
      setValue('selectedVariationsGroup', [], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const validateCheckboxGroup = (values: string[]) => {
    // validate that there's at least one variation selected, to enable Save button.
    return values.length > 0 || 'no-selection';
  };

  const handleSave = (data: FormData) => {
    // TODO: This can be removed if/when the backend API accepts an array of *all* variations to save.
    const orgVariationsAttributes = constructOrgVariationsAttributes(
      variations,
      data.selectedVariationsGroup,
    );
    updateOrgEnabledVariations({
      variables: { orgId, productId: product.id, orgVariationsAttributes },
    });

    typewriter.buttonPressed({
      button_text: 'Save',
      page_or_screen_name: EventNames.settings.productVariantEdit.page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  const handleCancel = () => {
    history.push(`/workflows/materials/products/${product.id}`);
    typewriter.buttonPressed({
      backend_id_type: 'org_product',
      button_text: 'Cancel',
      page_or_screen_name: EventNames.settings.productVariantEdit.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            trackEventName={EventNames.settings.materialsList.page}
            label="Materials"
            to="/workflows/materials"
          />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink isCurrentPage label={product.name} />
        </BreadcrumbItem>
      </Breadcrumb>
      <Box flexDirection="column" width={1}>
        <ProductHeader name={product.name} />
        <Box flexDirection="column" data-test-id="ProductEdit">
          <Box flexDirection="row" alignItems="center" marginBottom={200}>
            <Heading size={400}>Edit enabled variants</Heading>
          </Box>
          <Box
            flexDirection="column"
            width="75%"
            backgroundColor={theme.colors.neutral000}
          >
            <Box
              flexDirection="row"
              alignItems="center"
              borderBottom={`1px solid ${theme.colors.neutral400}`}
            >
              <Box mx={500} alignItems="center" flex="0 0 178px">
                <Checkbox // Select all/none - indeterminate checkbox.
                  name="selectAll"
                  aria-label="selectAll"
                  id="selectAll"
                  data-test-id="checkbox-select-all"
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={handleSelectAllNone}
                />
                <Label htmlFor="selectAll" flex="1 0 auto">
                  <Body
                    size={300}
                    color="neutral500"
                    marginX={200}
                    marginY={400}
                  >
                    {selectedVariations?.length ?? 0} of{' '}
                    {variations?.length ?? 0} variants selected
                  </Body>
                </Label>
              </Box>
              <Box>
                <Tooltip
                  hasArrow
                  placement="top-start"
                  mb={1}
                  label={
                    <Box
                      data-test-id="questionMarkIconTooltip"
                      style={{ fontSize: '0.875rem', padding: '16px 8px' }}
                    >
                      Your team can access enabled products while using
                      estimation and ordering
                    </Box>
                  }
                >
                  <Icon
                    data-test-id="questionMarkIcon"
                    icon={iHelpCircle}
                    color="neutral600"
                  />
                </Tooltip>
              </Box>
            </Box>
            <form onSubmit={handleSubmit(handleSave)}>
              <Box
                flexDirection="column"
                width="100%"
                maxHeight="calc(100vh - 395px)"
                overflowY="auto"
              >
                {/* Register Chakra CheckboxGroup as a react-hook-form field. */}
                <Controller
                  name="selectedVariationsGroup"
                  control={control}
                  rules={{
                    validate: validateCheckboxGroup,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CheckboxGroup
                        onChange={(values) => {
                          // Sort keeps array of values stable and comparable, and allows `isDirty` to work.
                          onChange(values.sort());
                        }}
                        value={value}
                        defaultValue={getSelectedVariations(variations)}
                      >
                        {variations.map((variant) => {
                          return (
                            <Box
                              _first={{
                                borderTop: 'none',
                              }}
                              borderTop={`1px solid ${theme.colors.neutral200}`}
                              key={variant.id}
                              data-test-id={`Variant-${variant.id}`}
                            >
                              <Box mx={500} alignItems="center">
                                <Checkbox
                                  value={variant.id}
                                  aria-label={variant.name}
                                  id={variant.id}
                                  key={variant.id}
                                  data-test-id="checkbox-input"
                                />
                                <Label htmlFor={variant.id}>
                                  <Body size={400} marginX={200} my={400}>
                                    {variant.name}
                                  </Body>
                                </Label>
                              </Box>
                            </Box>
                          );
                        })}
                      </CheckboxGroup>
                    );
                  }}
                />
              </Box>
              <EditButtons
                flexDirection="row"
                padding={300}
                alignItems="center"
              >
                <Box ml={300}>
                  <Button
                    data-test-id="saveVariantionSelection"
                    type="submit"
                    isDisabled={!isDirty || !isValid}
                  >
                    Save
                  </Button>
                </Box>
                <Box ml={300}>
                  <Button
                    data-test-id="cancelVariantionSelection"
                    fill="outline"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </EditButtons>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const ProductEdit: React.FC<NotificationHandlers> = ({
  setNotification,
  clearNotification,
}) => {
  const orgId = useSelector(getUserOrgId);
  let variationsFilter = useSelector(getUserOrgVariationsFilter);
  variationsFilter = { ...variationsFilter, filterVariationsByOrg: false };

  const { productId } = useParams();
  const { data: allVariantsData } = useQuery<ProductCatalogProductQueryType>(
    GET_PRODUCT_CATALOG_PRODUCT,
    {
      variables: { id: productId, ...variationsFilter },
    },
  );

  const variations = allVariantsData?.productCatalogProduct?.variations;

  if (!allVariantsData || !variations) return null;
  const { productCatalogProduct: product } = allVariantsData;
  if (!product) return null;

  return (
    <ProductEditContent
      variations={variations}
      product={product}
      orgId={orgId}
      setNotification={setNotification}
      clearNotification={clearNotification}
    />
  );
};
