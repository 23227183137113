import { LabelHTMLAttributes } from 'react';

import { Body } from '@hover/blueprint';
import styled from 'styled-components';

export const Label: React.FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  ...props
}) => (
  <label {...props}>
    <Body size={300} marginTop={200} marginBottom={100} color="neutral600">
      {children}
    </Body>
  </label>
);

export const Divider = styled.div<{ margin?: React.CSSProperties['margin'] }>`
  width: 100%;
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid ${({ theme: t }) => t.colors.neutral200};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
