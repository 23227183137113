import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  TogglePdfPayload,
  OrderDocumentType,
  UpdatePdfModalStatePayload,
  createPdfVariables,
  PollProjectManagementOrderDocumentsPayload,
  FetchProjectManagementOrderDocumentsPayload,
} from '../../types';

export const TOGGLE_PDF_MODAL = 'ESTIMATOR_PRODUCTION/TOGGLE_PDF_MODAL';
export const togglePdfModal = createAction(
  TOGGLE_PDF_MODAL,
  (payload: TogglePdfPayload) => payload,
)();

export const CUSTOMIZE_PDF_OUTPUT = 'ESTIMATOR_PRODUCTION/CUSTOMIZE_PDF_OUTPUT';
export const customizePdfOutput = createAction(
  CUSTOMIZE_PDF_OUTPUT,
  ({
    shouldShowCostDetails,
    shouldShowItemMeasurements,
    shouldShowTotalCost,
    notes,
  }) => ({
    shouldShowCostDetails,
    shouldShowItemMeasurements,
    shouldShowTotalCost,
    notes,
  }),
)();

export const UPDATE_PDF_MODAL_STATE =
  'ESTIMATOR_PRODUCTION/UPDATE_PDF_MODAL_STATE';
export const updatePdfModalState = createAction(
  UPDATE_PDF_MODAL_STATE,
  (payload: UpdatePdfModalStatePayload) => payload,
)();

export const CREATE_PDF_REQUEST = 'ESTIMATOR_PRODUCTION/CREATE_PDF_REQUEST';
export const CREATE_PDF_SUCCESS = 'ESTIMATOR_PRODUCTION/CREATE_PDF_SUCCESS';
export const CREATE_PDF_FAILURE = 'ESTIMATOR_PRODUCTION/CREATE_PDF_FAILURE';
export const createPdf = createAsyncAction(
  CREATE_PDF_REQUEST,
  CREATE_PDF_SUCCESS,
  CREATE_PDF_FAILURE,
)<createPdfVariables, void, Error>();

export const webDownloadPdf = createAsyncAction(
  'ESTIMATOR_PRODUCTION/WEB_DOWNLOAD_PDF_REQUEST',
  'ESTIMATOR_PRODUCTION/WEB_DOWNLOAD_PDF_SUCCESS',
  'ESTIMATOR_PRODUCTION/WEB_DOWNLOAD_PDF_FAILURE',
)<OrderDocumentType, void, Error>();

export const RECEIVE_ORDER_DOCUMENT =
  'ESTIMATOR_PRODUCTION/RECEIVE_ORDER_DOCUMENT';
export const receiveOrderDocument = createAction(
  RECEIVE_ORDER_DOCUMENT,
  (payload: OrderDocumentType) => payload,
)();

export const RECEIVE_ORDER_DOCUMENTS =
  'ESTIMATOR_PRODUCTION/RECEIVE_ORDER_DOCUMENTS';
export const receiveOrderDocuments = createAction(
  RECEIVE_ORDER_DOCUMENTS,
  (payload: OrderDocumentType[]) => payload,
)();

export const POLL_PROJECT_MANAGEMENT_ORDER_DOCUMENTS =
  'ESTIMATOR_PRODUCTION/POLL_PROJECT_MANAGEMENT_ORDER_DOCUMENTS';
export const pollProjectManagementOrderDocuments = createAction(
  POLL_PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
  (payload: PollProjectManagementOrderDocumentsPayload) => payload,
)();

export const FETCH_PROJECT_MANAGEMENT_ORDER_DOCUMENTS =
  'ESTIMATOR_PRODUCTION/FETCH_PROJECT_MANAGEMENT_ORDER_DOCUMENTS';
export const fetchProjectManagementOrderDocuments = createAction(
  FETCH_PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
  (payload: FetchProjectManagementOrderDocumentsPayload) => payload,
)();
