import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Field, HStack, Input } from '@hover/blueprint';
import { InputGroup, InputRightAddon } from '@hover/blueprint/chakra';
import { useSelector } from 'react-redux';

import { UPDATE_ESTIMATION_GROUP_NAME } from 'src/features/exteriorEstimator/apis/mutations/estimateGroup';
import {
  getEstimateGroup,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';

export const EstimateGroupDetailName = () => {
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const estimateGroup = useSelector(getEstimateGroup);

  const [estimateName, setEstimateName] = useState('');

  useEffect(() => {
    setEstimateName(estimateGroup?.name ?? '');
  }, [estimateGroup?.name]);

  const toast = useToastEhi();

  const [updateEstimateGroupName] = useMutation(UPDATE_ESTIMATION_GROUP_NAME, {
    onCompleted: () => {
      toast({
        id: 'estimate-group-name-success',
        description: 'Changes successfully saved',
        status: ToastStatusEnum.SUCCESS,
      });
    },
    onError: () => {
      toast({
        id: 'estimate-group-name-error',
        description: 'Changes not saved. Try again',
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const submitEstimateName = () => {
    updateEstimateGroupName({
      variables: {
        estimateGroupId,
        estimateGroupAttributes: {
          name: estimateName,
        },
      },
    });
  };

  const saveDisabled =
    (!estimateGroup?.name && !Boolean(estimateName)) ||
    estimateGroup?.name === estimateName;

  return (
    <HStack paddingTop={300}>
      <Field name="Estimate name" label="Estimate name">
        <InputGroup>
          <Input
            placeholder="Estimate"
            onChange={(e) => setEstimateName(e.target.value)}
            value={estimateName}
          />
          <InputRightAddon>
            <Button
              label="Save"
              shape="box"
              size="large"
              paddingX={300}
              borderTopLeftRadius="0"
              borderBottomLeftRadius="0"
              onClick={submitEstimateName}
              isDisabled={saveDisabled}
            >
              Save
            </Button>
          </InputRightAddon>
        </InputGroup>
      </Field>
    </HStack>
  );
};
