import { breakerClient } from '@hover/breaker-react';

import SplitConfig from 'src/utils/SplitConfig';

const isSplitFlagEnabled = (flagName: string) =>
  SplitConfig.isEnabled(flagName);

const isBreakerFlagEnabled = (flagName: string) =>
  breakerClient.isEnabled(flagName);

export const shouldUseBreaker = () => {
  try {
    return JSON.parse(localStorage.getItem('breaker-admin') ?? '') === true;
  } catch (e) {
    return false;
  }
};

export const isEnabled = (flagName: string) => {
  return shouldUseBreaker()
    ? isBreakerFlagEnabled(flagName)
    : isSplitFlagEnabled(flagName);
};
