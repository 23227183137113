import {
  Page,
  QuestionId,
  Input,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';

import { PARTIAL_ROOF_AREA_FEATURES_MAP } from '../constants/questionArgumentMappings';
import { MEASUREMENT } from '../constants/questionCategories';
import * as questionsUtils from './questionsUtils';

export interface PitchQuestionsHashmap {
  [pitch: string]: Input[];
}

export class PartialRoofUtils {
  // Checks if a given question id is a partial roof face selection
  // Assumes that those questionIds are in the form 'RF-123'
  static isQuestionPartialRoofFacetSelection(questionId: QuestionId) {
    return typeof questionId === 'string' && questionId.split('-')[0] === 'RF';
  }

  static getPitchQuestionsForPitch(questions: Page[], pitch: string) {
    const measurementPage = questionsUtils.getPageForCategory(
      questions,
      MEASUREMENT,
    );
    let result: {
      pitchQuestion?: Input;
      roofTotalQuestion?: Input;
    } = {};
    if (measurementPage) {
      const { questions: measurementQuestions } = measurementPage;

      const pitchKey = parseInt(pitch, 10) <= 15 ? pitch : 'greater_15';
      const pitchQuestion = measurementQuestions?.find(
        (question) =>
          question.argument ===
          PARTIAL_ROOF_AREA_FEATURES_MAP[`pitch_area_${pitchKey}_in_12`],
      );
      const roofTotalQuestion = PartialRoofUtils.getRoofTotalQuestion(
        questions,
        measurementPage,
      );

      result = {
        pitchQuestion,
        ...(!!roofTotalQuestion && { roofTotalQuestion }),
      };
    }
    return result;
  }

  static getRoofTotalQuestion(questions: Page[], categoryObj: Page) {
    const measurementPage =
      categoryObj || questionsUtils.getPageForCategory(questions, MEASUREMENT);
    if (measurementPage) {
      const { questions: measurementQuestions } = measurementPage;
      return measurementQuestions?.find(
        (question) =>
          question.argument === PARTIAL_ROOF_AREA_FEATURES_MAP.roof_total,
      );
    }
    return null;
  }

  static organizeByPitch(questions: Input[]) {
    const pitchQuestionHashmap = questions?.reduce(
      (hash: PitchQuestionsHashmap, question) => {
        if (question.id && question.pitch !== undefined) {
          if (Array.isArray(hash[question.pitch])) {
            hash[question.pitch]?.push(question);
          } else {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            hash[question.pitch] = [question];
          }
        }
        return hash;
      },
      {},
    );

    return pitchQuestionHashmap;
  }

  static isPitch(question?: Input) {
    return !!(
      question &&
      (question.pitch || question?.argument?.startsWith('pitch_area'))
    );
  }

  static getTotalAreaForPitch(
    roofFacetInputs: Input[],
    pitch: string,
    questionResponses: QuestionResponses,
  ) {
    const pitchNumber = Number(pitch);

    const allFacetsOfPitch = roofFacetInputs.filter((facet) => {
      return pitchNumber <= 15
        ? Number(facet.pitch) === pitchNumber
        : Number(facet.pitch) > 15;
    });

    const totalForPitch = allFacetsOfPitch.reduce((acc, facet) => {
      // eslint-disable-next-line no-plusplus, no-param-reassign
      if (questionResponses[facet.id]) acc += facet?.area ?? 0;
      return acc;
    }, 0);

    return totalForPitch;
  }

  static getRoofTotalArea = (
    questionResponses: QuestionResponses,
    roofFacetInputs: Input[],
  ) => {
    return roofFacetInputs.reduce((_acc, question) => {
      let acc = _acc;

      acc += questionResponses[question.id as number] ? question?.area ?? 0 : 0;
      return acc;
    }, 0);
  };
}
