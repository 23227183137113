import { Heading, Body, Box, Button } from '@hover/blueprint';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';
import { Modal as _Modal } from 'style-guide';

export const Modal = styled(_Modal)`
  max-width: 320px;
  width: 90%;
`;

interface Props {
  isOpen: boolean;
  handleAction: () => void;
  setShouldShowColorConfirmationModal: (shouldShow: boolean) => void;
}

export const ColorConfirmationModal: React.FC<Props> = ({
  isOpen,
  handleAction,
  setShouldShowColorConfirmationModal,
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const { estimateGroupId } = useParams();
  const handleCancel = () => {
    typewriter.buttonPressed({
      backend_id_type: 'estimate_group_id',
      backend_id_value: estimateGroupId,
      button_text: 'Cancel',
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.estimates.colorModal,
      primary_cta: true,
      ...commonTrackingProps,
    });
    setShouldShowColorConfirmationModal(false);
  };

  return (
    <Modal isOpen={isOpen} data-test-id="colorConfirmationModal">
      <Heading size={500}>Continue with an estimate without color?</Heading>
      <Body size={400}>
        Homeowner should confirm color and your production manager will see
        selected colors for this estimate
      </Body>
      <Box flexDirection="column" alignItems="space-between">
        <Button
          data-test-id="colorConfirmationModalContinueButton"
          onClick={handleAction}
          marginBottom={400}
        >
          Continue
        </Button>
        <Button
          data-test-id="colorConfirmationModalCancelButton"
          onClick={handleCancel}
          fill="outline"
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
