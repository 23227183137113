import moment from 'moment';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';

type SortOrderCreatedAtSortable = {
  sortOrder?: number | null;
  createdAt?: string;
  id: string | number | null;
};
export const bySortOrderAndCreatedAtComparator = (
  a: SortOrderCreatedAtSortable,
  b: SortOrderCreatedAtSortable,
) => {
  return (
    (a?.sortOrder ?? Infinity) - (b?.sortOrder ?? Infinity) || // sort by sortOrder, ordering null sortOrder to the end
    moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf() || // secondary sort by createdAt, most recent dates first
    (typeof a.id === 'number' && typeof b.id === 'number'
      ? a.id - b.id
      : ((a.id ?? '') as string).localeCompare((b.id ?? '') as string)) // tertiary sort by item id if first two fall through; to ensure stable sort
  );
};

export const bySortOrderAndReverseCreatedAtComparator = (
  a: SortOrderCreatedAtSortable,
  b: SortOrderCreatedAtSortable,
) => {
  return (
    (a?.sortOrder ?? Infinity) - (b?.sortOrder ?? Infinity) || // sort by sortOrder, ordering null sortOrder to the end
    moment(a?.createdAt).valueOf() - moment(b?.createdAt).valueOf() || // secondary sort by createdAt, most recent dates first
    (typeof a.id === 'number' && typeof b.id === 'number'
      ? a.id - b.id
      : ((a.id ?? '') as string).localeCompare((b.id ?? '') as string)) // tertiary sort by item id if first two fall through; to ensure stable sort
  );
};

type LineItemTypeSortOrderUnitCostCreatedAtSortable = {
  sortOrder?: number | null;
  createdAt?: string;
  unitCost?: number;
  type?: LineItemTypeEnum;
  tradeType?: TradeTypeEnum;
  id: string | number | null;
};

export const byLineItemTypeTradeTypeSortOrderUnitCostAndCreatedAtComparator = (
  tradeTypeEnumsSorted: TradeTypeEnum[],
) => {
  return (
    a: LineItemTypeSortOrderUnitCostCreatedAtSortable,
    b: LineItemTypeSortOrderUnitCostCreatedAtSortable,
  ) => {
    return (
      byLineItemTypeComparator(a.type, b.type) || // sort by lineItemType, nulls last
      (!!a.tradeType ? tradeTypeEnumsSorted.indexOf(a.tradeType) : Infinity) -
        (!!b.tradeType
          ? tradeTypeEnumsSorted.indexOf(b.tradeType)
          : Infinity) || // sort by tradeType, nulls last
      (a?.sortOrder ?? Infinity) - (b?.sortOrder ?? Infinity) || // sort by sortOrder, ordering null sortOrder to the end
      (b?.unitCost ?? Infinity) - (a?.unitCost ?? Infinity) || // sort by unitCost, highest to lowest
      moment(a?.createdAt).valueOf() - moment(b?.createdAt).valueOf() || // secondary sort by createdAt, most recent dates last
      (typeof a.id === 'number' && typeof b.id === 'number'
        ? a.id - b.id
        : ((a.id ?? '') as string).localeCompare((b.id ?? '') as string)) // tertiary sort by item id if first two fall through; to ensure stable sort
    );
  };
};

const sortedLineItemTypes = [
  LineItemTypeEnum.MATERIAL,
  LineItemTypeEnum.LABOR,
  LineItemTypeEnum.OTHER,
];
export const byLineItemTypeComparator = (
  a: LineItemTypeEnum | undefined,
  b: LineItemTypeEnum | undefined,
) => {
  return (
    (!!a ? sortedLineItemTypes.indexOf(a) : Infinity) -
    (!!b ? sortedLineItemTypes.indexOf(b) : Infinity)
  );
};
