import { useQuery } from '@apollo/client';
import { Loader, Box } from '@hover/blueprint';
import { isNil } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

import {
  GET_JOBS,
  GET_JOBS_WITH_SALES_OPPORTUNITIES,
} from 'src/api/queries/queries';
import { jobs as JobsType } from 'src/api/types/jobs';
import type { jobsWithSalesOpportunity as JobsWithSalesOpportunityType } from 'src/api/types/jobsWithSalesOpportunity';
import { NoResultsFound } from 'src/components/NoResultsFound';
import {
  COMMERCE_PROJECT_LIST_REQUEST_REFACTOR,
  isEnabled,
} from 'src/lib/FeatureFlag';

import { Job } from './Job';

type Props = {
  selectedOrgId: number;
  searchQuery: string | null;
};

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    display="grid"
    overflow="auto"
    gridRowStart={2}
    gridArea="main"
    width="100vw"
    height="calc(100vh - 225px)"
  >
    {children}
  </Box>
);

export const JobsList: React.FC<Props> = ({ searchQuery, selectedOrgId }) => {
  const QUERY_NAME = isEnabled(COMMERCE_PROJECT_LIST_REQUEST_REFACTOR)
    ? GET_JOBS_WITH_SALES_OPPORTUNITIES
    : GET_JOBS;

  const { data, loading, fetchMore } = useQuery<
    JobsType | JobsWithSalesOpportunityType
  >(QUERY_NAME, {
    variables: {
      search: searchQuery,
      page: 1,
      perPage: 20,
      orgId: selectedOrgId,
    },
    notifyOnNetworkStatusChange: true, // for some reason this ensures that `loading` works on fetchMore calls
  });

  const renderLoader = () => (
    <Box maxWidth="1280px" alignItems="center" flexDirection="column">
      <Loader size="medium" />
    </Box>
  );

  if (!data) return <Wrapper>{renderLoader()}</Wrapper>;

  const { hasNextPage, currentPage } = data?.jobs?.pagination ?? {};

  const loadMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: { page: currentPage + 1, searchQuery, perPage: 20 },
      });
    }
  };

  const results = data?.jobs?.results;

  return results?.length === 0 ? (
    <Wrapper>
      <Box maxWidth="1280px" alignItems="center" flexDirection="column">
        <NoResultsFound searchTerm={searchQuery} />
      </Box>
    </Wrapper>
  ) : (
    <Wrapper>
      <InfiniteScroll
        pageStart={1}
        data-test-id="jobsListWrapper"
        loadMore={loadMore}
        initialLoad={false}
        useWindow={false}
        hasMore={hasNextPage}
        style={{
          maxWidth: '1280px',
          display: 'grid',
          gridTemplateColumns:
            '[col1] 10% [col2] 24% [col3] 15% [col4] 8% [col5] 13% [col6] 1fr [end]',
          gridTemplateAreas: `
            'header header header header header header header'
            'main main main main main main main'
          `,
        }}
      >
        <Box display="grid" gridRowStart={2} gridArea="main">
          {results.map(
            (job) =>
              !isNil(job) && (
                <Job key={job.id} job={job} selectedOrgId={selectedOrgId} />
              ),
          )}
        </Box>
      </InfiniteScroll>
      {loading && renderLoader()}
    </Wrapper>
  );
};
