import React, { useEffect } from 'react';

import {
  Body,
  Heading,
  Button,
  Popover,
  useDisclosure,
} from '@hover/blueprint';
import { iEdit } from '@hover/icons';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import { useLocalStorage, useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  job: Job;
};

export const RecalculateMaterialListButton = ({ job }: Props) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isMobile = useIsMobileBreakpoint({ ssr: false });

  /* Recalculate Popover */
  const [localShowPopover, setLocalShowPopover] = useLocalStorage<boolean>(
    'showRecalculatePopover',
    true,
  );

  const {
    isOpen: isRecalcPopoverOpen,
    onOpen: onRecalcPopoverOpen,
    onClose: onRecalcPopoverClose,
  } = useDisclosure();

  useEffect(() => {
    // when mounting, if on desktop, show the popover if it's enabled
    if (localShowPopover && !isMobile) onRecalcPopoverOpen();
  }, []);

  return (
    <Popover
      isOpen={isRecalcPopoverOpen}
      placement="left"
      colorScheme="dark"
      inPortal
      borderRadius="10px"
      w={750}
      pt={400}
      pb={500}
      px={600}
      anchor={
        <Button
          fill="outline"
          data-test-id="customizeMaterialList"
          iconBefore={isMobile ? undefined : iEdit}
          height={{ base: '42px', tablet: 'auto' }}
          onClick={() => {
            typewriter.buttonPressed({
              page_or_screen_name:
                EventNames.project.scope.customizeMaterialListPressed,
              button_text: 'Recalculate Material List',
              primary_cta: false,
              feature: 'material-list',
              ...commonTrackingProps,
            });
            window.location.href = `/ehi/#/estimator/questions/select_templates?jobId=${job.id}`;
          }}
        >
          Recalculate {!isMobile && 'Material List'}
        </Button>
      }
    >
      <Heading margin={0} fontSize={600} color="neutral.0">
        Make scope changes
      </Heading>
      <Body margin={0} color="neutral.300">
        Modify templates, waste, partial projects area selections, or make other
        changes to improve accuracy
      </Body>
      <Button
        marginTop={200}
        color="light"
        onClick={() => {
          setLocalShowPopover(false);
          typewriter.buttonPressed({
            page_or_screen_name:
              EventNames.project.scope.customizeMaterialListPressed,
            button_text: 'Got it',
            primary_cta: true,
            feature: 'material-list',
            ...commonTrackingProps,
          });
          onRecalcPopoverClose();
        }}
      >
        Got it
      </Button>
    </Popover>
  );
};
