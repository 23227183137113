import { Box, Icon, Link } from '@hover/blueprint';
import { hTriangleRuler } from '@hover/icons';

import { JobModelStates } from 'src/api/graphql-global-types';
import appSettings from 'src/appSettings';
import { JobDetails } from 'src/features/exteriorEstimator/types';

interface Props {
  job: JobDetails | null | undefined;
}

export const MeasurementsLink: React.FC<Props> = (props) => {
  const { job } = props;
  const id = job?.id;
  return (
    <Link
      data-test-id="measurementsLink"
      pointerEvents={job?.state !== JobModelStates.COMPLETE ? 'none' : 'auto'}
      color={
        job?.state !== JobModelStates.COMPLETE ? 'neutral.600' : 'primary.600'
      }
      href={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${id}/measurements.pdf`}
      download={`measurements_${id}.pdf`}
      type="application/pdf"
    >
      <Box display="flex" alignItems="center" marginX="1em">
        <Icon icon={hTriangleRuler} />
        <Box marginLeft=".25em">Measurements</Box>
      </Box>
    </Link>
  );
};
