import { createSelector } from 'reselect';

import { RootState } from 'src/types/reduxStore';

export const getSelectedPromotionId = (state: RootState) =>
  state.exteriorEstimator.inputs.selectedPromotionId;

export const getPromotions = (state: RootState) =>
  state.exteriorEstimator.discounts;

export const getSelectedPromotion = createSelector(
  getSelectedPromotionId,
  getPromotions,
  (discountId, discounts) => discounts[discountId ?? -1],
);
