import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  JobMeasurements,
  PristineMeasurement,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';

export const storeJobMeasurements = createAction(
  'ESTIMATOR/STORE_JOB_MEASUREMENTS',
  ({ jobMeasurements }: { jobMeasurements: JobMeasurements }) => ({
    jobMeasurements,
  }),
)();

export const storePristineMeasurements = createAction(
  'ESTIMATOR/STORE_PRISTINE_MEASUREMENTS',
  ({
    pristineMeasurements,
  }: {
    pristineMeasurements: PristineMeasurement[];
  }) => ({ pristineMeasurements }),
)();

export const storeGeometry = createAction(
  'ESTIMATOR/STORE_GEOMETRY',
  ({ geometry }: { geometry: object }) => ({ geometry }),
)();

export const storeMetadata = createAction(
  'ESTIMATOR/STORE_METADATA',
  ({ metadata }: { metadata: object }) => ({ metadata }),
)();

export const getMeasurementsPlainJson = createAsyncAction(
  'ESTIMATOR/GET_MEASUREMENTS_PLAIN_JSON_REQUEST',
  'ESTIMATOR/GET_MEASUREMENTS_PLAIN_JSON_SUCCESS',
  'ESTIMATOR/GET_MEASUREMENTS_PLAIN_JSON_FAILURE',
)<{ jobId: number }, PlainMeasurements, Error>();
