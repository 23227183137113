import { Field, RadioGroup, SimpleGrid, TileRadio } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { jobDetails_jobs_results as JobDetails } from 'src/api/types/jobDetails';
import { Input } from 'src/features/exteriorEstimator/types';
import type { QuestionAnswer } from 'src/features/exteriorEstimator/types';
import { sortQuestionInputOptions } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { questionProps, jobProps } from 'src/utils/trackingUtils';

type Props = {
  question: Input;
  value?: string;
  jobDetails?: JobDetails | null;
  questionText: string;
  handleInputChange: (answer: QuestionAnswer) => void;
};

export const RadioTileQuestion: React.FC<Props> = ({
  question,
  value,
  jobDetails,
  questionText,
  handleInputChange,
}) => {
  const { inputOptions } = question;

  const sortedOptions = sortQuestionInputOptions(inputOptions ?? []);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleSelectOption = (newValue: string) => {
    handleInputChange(newValue);

    typewriter.optionSelected({
      selection: newValue,
      option_type: 'radio',
      options: question?.name ?? '',
      ...commonTrackingProps,
      ...jobProps(jobDetails),
      ...questionProps(jobDetails, question, newValue),
    });
  };

  return (
    <Field label={questionText} name="radioTileInput">
      <RadioGroup value={value} onChange={handleSelectOption}>
        <SimpleGrid gap={400} minChildWidth="128px">
          {sortedOptions.map((option) => (
            <TileRadio value={option.value} key={option.value}>
              {option.label}
            </TileRadio>
          ))}
        </SimpleGrid>
      </RadioGroup>
    </Field>
  );
};
