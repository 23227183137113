import React from 'react';

import { Box, Body, Heading, Badge } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import {
  VariationMatchingToggle,
  MatchingGroups,
} from 'src/components/VariationMatchingToggle';
import { buildAddressNameFromJob } from 'src/features/project/util/utils';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';

import { HelpDrawerButton } from './HelpDrawerButton';
import { RecalculateMaterialListButton } from './RecalculateMaterialListButton';
import { TipsDrawerButton } from './TipsDrawerButton';

type Props = {
  job: Job;
  productionList: ProjectManagementProductionList;
};

export const ProjectScopeHeader = ({ job, productionList }: Props) => {
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const isMobile = useIsMobileBreakpoint({ ssr: false });

  return (
    <Box
      padding={
        materialListFeatureEnabled || isMobile ? '0px' : '24px 40px 0px 40px'
      }
      backgroundColor={materialListFeatureEnabled ? 'white' : undefined}
      height={materialListFeatureEnabled ? '100px' : undefined}
    >
      <Box
        padding={materialListFeatureEnabled && !isMobile ? '0px 40px' : '0px'}
        width="100%"
        justifyContent="space-between"
      >
        {materialListFeatureEnabled ? (
          <Box flexDirection="column" justifyContent="center">
            {materialListFeatureEnabled && (
              <Body
                margin="0"
                fontSize={100}
                fontWeight={600}
                color="neutral.500"
                whiteSpace="nowrap"
              >
                {buildAddressNameFromJob(job, true)}
              </Body>
            )}

            <Box flexDirection="row" alignItems="center">
              <Body
                fontWeight="700"
                fontSize={{
                  base: 600,
                  tablet: materialListFeatureEnabled ? 600 : 300,
                }}
                margin={
                  materialListFeatureEnabled
                    ? '0px 0px 16px 0px'
                    : '24px 16px 16px 0px'
                }
                whiteSpace="nowrap"
              >
                Material List
              </Body>
              <Badge
                colorScheme="warning"
                margin={
                  materialListFeatureEnabled ? '0px 0px 16px 16px' : '0px'
                }
              >
                BETA
              </Badge>
            </Box>
          </Box>
        ) : (
          <Box flexDirection="column">
            <Body margin="0">Production console</Body>
            <Heading size={500} data-test-id="ProjectScope-JobName">
              {job?.name}
            </Heading>
          </Box>
        )}
        <Box alignItems="center">
          {!isMobile && (
            <VariationMatchingToggle
              type={MatchingGroups.PRODUCTION_LIST}
              id={productionList.id}
              enableVariationMatching={productionList.enableVariationMatching}
            />
          )}
          {materialListFeatureEnabled && (
            <>
              {!isMobile && <HelpDrawerButton />}
              {!isMobile && <TipsDrawerButton />}
              <RecalculateMaterialListButton job={job} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
