import { Body } from '@hover/blueprint';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { SanitizedHTML } from 'src/components';
import { RootState } from 'src/types/reduxStore';

import { InfoText, Container, Contact } from '../styled';
import Wrapper from '../Wrapper';

const mapStateToProps = (state: RootState) => ({
  errorMessage:
    state.estimatorProductionTools.projectManagementOrderData.errorMessage,
});

interface GenericErrorProps {
  errorMessage?: string;
}

const TextWrapper = styled.div`
  ${({ theme }) => theme.mixins.font('book', 15)}
  padding: 32px 32px 0;
  margin: 0px auto 40px;
`;

const GenericErrorComponent: React.FC<GenericErrorProps> = ({
  errorMessage,
}) => {
  return (
    <Wrapper>
      <Container data-test-id="GenericErrorContent">
        {errorMessage && (
          <TextWrapper>
            <SanitizedHTML html={errorMessage} />
          </TextWrapper>
        )}
        <InfoText margin="25px auto 10px">
          <Body>
            We encountered a connection issue. Please try again. <br />
            If you continue to encounter an error, contact{' '}
            <Contact href="https://hover.to/contact/">HOVER support</Contact>
          </Body>
        </InfoText>
      </Container>
    </Wrapper>
  );
};

export const GenericError = connect(mapStateToProps)(GenericErrorComponent);
