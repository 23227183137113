import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const TableContainer = styled.section`
  margin-bottom: 150px;
  width: 100%;
`;

export const TableWrapper = styled.table`
  background: ${({ theme }) => theme.colors.neutral000};
  margin: 90px 0 0;
  width: 100%;
`;

export const TableHeadSection = styled.thead`
  background: unset;
  display: flex;
  padding: 0.5em;
`;

export const TableName = styled.th.attrs({ colSpan: 6 })`
  ${({ theme }) => theme.mixins.font('normal', 13)};
  color: ${({ theme }) => theme.colors.mediumGray};
  font-weight: 600;
  height: 20px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 0 0 10px 0;
`;

export const TableRow = styled.tr`
  min-height: 50px;
  width: 100%;
`;

const TableCell = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayFour};
  letter-spacing: 0.5px;
  padding: 0;

  &:first-child {
    padding-left: 24px;
    max-width: 250px;
    width: 250px;
  }

  &:nth-child(1) {
    div {
      h3 {
        text-align: left;
      }
    }
  }
`;

const TableCellVendorDropdown = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayFour};
  letter-spacing: 0.5px;
  padding: 0;

  &:nth-child(-n + 2) {
    padding-left: 24px;
    max-width: 250px;
    width: 250px;
  }

  &:nth-child(-n + 2) {
    div {
      h3 {
        text-align: left;
      }
    }
  }
`;

export const TableHead = styled(TableCell)`
  height: 50px;
`;

export const TableHeadVendorDropdown = styled(TableCellVendorDropdown)`
  height: 50px;
`;

export const TableData = styled(TableCell)`
  height: 75px;

  &:first-child {
    div {
      h3 {
        ${({ theme }) => theme.mixins.font('medium', 12)};
      }
    }
  }
`;

export const TableDataVendorDropdown = styled(TableCellVendorDropdown)`
  height: 75px;

  &:first-child {
    div {
      h3 {
        ${({ theme }) => theme.mixins.font('medium', 12)};
      }
    }
  }
`;

export const TableCellContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

export const TableDataContent = styled(TableCellContent)`
  padding: 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TableCellText = styled.h3`
  position: relative;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: right;
  text-overflow: ellipsis;
  width: 100%;
`;

export const TableHeadText = styled(TableCellText)`
  ${({ theme }) => theme.mixins.font('medium', 13)};
  font-weight: 600;
`;

export const TableDataText = styled(TableCellText)`
  ${({ theme }) => theme.mixins.font('normal', 12)};
  overflow: visible;
  font-weight: 100;
`;

export const TableSmallText = styled(TableCellText)`
  ${({ theme }) => theme.mixins.font('normal', 11)};
  color: ${({ theme }) => theme.colors.mediumGray};
`;

export const TableCellDisplayUnit = styled.div`
  position: absolute;
  padding-left: 0.5rem;
  text-align: left;
  left: 100%;
  top: 0;
`;

export const TableCellUnit = styled(TableDataText)`
  position: absolute;
  padding-left: 0.5rem;
  text-align: left;
  left: 100%;
  top: 0;
`;

/**
 * TODO: @jay - figure out a better way to create an empty column
 *
 *
 * Background:
 *
 * MaterialsTable & LaborTable have the same table styles but
 * LaborTable has a missing/empty column in the middle. To keep
 * the same styles/column width between the tables, display a
 * column with white text so it's invisible to the user.
 *
 * This is strictly for demo purposes. Must figure out a better
 * way to display an empty column and keep the columns aligned
 */
export const TableInvisibleText = styled(TableHeadText)`
  color: ${({ theme }) => theme.colors.neutral000};
`;

interface ColumnCellSizedWrapperProps {
  height?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  width?: string;
}

export const ColumnCellSizedWrapper = styled.div`
  ${({
    height = 'auto',
    margin = '0 0 10px 0',
    maxHeight = 'auto',
    maxWidth = '225px',
    minHeight = '25px',
    minWidth = 'auto',
    width = '100%',
  }: ColumnCellSizedWrapperProps) => css`
    height: ${height};
    margin: ${margin};
    max-height: ${maxHeight};
    max-width: ${maxWidth};
    min-height: ${minHeight};
    min-width: ${minWidth};
    width: ${width};
  `}
`;
