import { PureComponent } from 'react';

import { JobDetails } from 'src/features/projectManagement/components/ProductionView/ProductionSummary/JobDetails';
import { MeasurementDetails } from 'src/features/projectManagement/components/ProductionView/ProductionSummary/MeasurementDetails';
import { SummaryContainer } from 'src/features/projectManagement/components/ProductionView/ProductionSummary/ProductionSummaryStyled';
import { ProjectFinancials } from 'src/features/projectManagement/components/ProductionView/ProductionSummary/ProjectFinancials';

export class ProductionSummary extends PureComponent {
  public render() {
    return (
      <SummaryContainer>
        <JobDetails />
        <MeasurementDetails />
        <ProjectFinancials />
      </SummaryContainer>
    );
  }
}
