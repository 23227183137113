import styled from 'styled-components';

import { Icon } from 'style-guide';

export { PmpDropdown as Dropdown } from 'src/features/projectManagement/components/ProductionView/Common/PmpDropdown';

interface CustomInputValueContainerProps {
  margin?: string;
  width?: string;
  maxWidth?: string;
}

export const CustomInputValueContainer = styled.div<CustomInputValueContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ margin = '0' }) => margin};
  width: ${({ width = 'auto' }) => width};
  max-width: ${({ maxWidth = 'auto' }) => maxWidth};
`;

interface CustomInputValueUserIconProps {
  shouldShow: boolean;
}

export const CustomInputValueUserIcon = styled(Icon)`
  position: absolute;
  box-sizing: border-box;
  top: ${({ iconSize }) => `${(24 - iconSize) / 2}px`};
  left: ${({ iconSize }) => `-${iconSize + 4}px`};
  display: ${({ shouldShow }: CustomInputValueUserIconProps) =>
    shouldShow ? 'block' : 'none'};
  transition: 0.15s ease-in-out;
`;
