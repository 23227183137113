import { useState, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Select,
  Modal,
  Field,
  TextInput,
  Radio,
  RadioGroup,
} from '@hover/blueprint';
import { omit } from 'lodash';

import {
  InspectionChecklistQuestionSupportsImageUploadsEnum,
  InspectionConfigChecklistQuestionTypeEnum,
} from 'src/api/graphql-global-types';
import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate_sections_questions_options as QuestionOptionType } from 'src/api/types/inspectionConfigChecklistTemplate';
import {
  CREATE_TEMPLATE_QUESTION,
  UPDATE_TEMPLATE_QUESTION,
} from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATE } from 'src/features/settings/api/queries/inspectionChecklist';

import { TemplateQuestionFormSelectOptions } from './TemplateQuestionFormSelectOptions';

const SELECT_OPTIONS = [
  {
    label: 'Yes or no',
    value: InspectionConfigChecklistQuestionTypeEnum.BOOLEAN,
  },
  { label: 'Text', value: InspectionConfigChecklistQuestionTypeEnum.STRING },
  { label: 'Number', value: InspectionConfigChecklistQuestionTypeEnum.NUMBER },
  {
    label: 'Multiple choice',
    value: InspectionConfigChecklistQuestionTypeEnum.SELECT,
  },
];

export type SelectQuestionInputType = {
  id: string;
  label: string;
  sortOrder: number;
  destroy: boolean;
};

const NEW_OPTION = {
  id: null,
  label: '',
  sortOrder: 0,
  destroy: false,
};

interface TemplateQuestionCreateOrUpdateProps {
  templateId: string;
  templateSectionId: string;
  sortOrder?: number | null;
  questionId?: string;
  text?: string;
  type?: InspectionConfigChecklistQuestionTypeEnum | null;
  supportsImage?: string;
  options?: QuestionOptionType[] | null;
}

export const TemplateQuestionCreateOrUpdate: React.FC<
  TemplateQuestionCreateOrUpdateProps
> = ({
  children,
  templateId,
  templateSectionId,
  questionId,
  text = '',
  type = InspectionConfigChecklistQuestionTypeEnum.BOOLEAN,
  supportsImage,
  options,
  sortOrder = 0,
}) => {
  const mapSupportsImageUploads = useCallback(
    () =>
      supportsImage ===
      InspectionChecklistQuestionSupportsImageUploadsEnum.OPTIONAL
        ? 'yes'
        : 'no',
    [supportsImage],
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>(text);
  const [questionType, setQuestionType] =
    useState<InspectionConfigChecklistQuestionTypeEnum | null>(type);
  const [selectOptions, setSelectOptions] = useState<
    Array<SelectQuestionInputType>
  >([
    {
      ...NEW_OPTION,
      id: '0',
    },
  ]);
  const [supportsImageUploads, setSupportsImageUploads] = useState<string>(
    mapSupportsImageUploads(),
  );
  const actionName = questionId ? 'Save changes' : 'Add';

  useEffect(() => {
    if (isModalOpen) {
      setQuestionText(text);
      if (type) {
        setQuestionType(type);
      }
      if (options?.length) {
        setSelectOptions(
          options.map((option) => ({
            ...option,
            destroy: false,
          })),
        );
      }
      setSupportsImageUploads(mapSupportsImageUploads());
    }
  }, [
    isModalOpen,
    text,
    type,
    supportsImage,
    mapSupportsImageUploads,
    options,
  ]);

  const getSelectOptionsAttributes = () => {
    // when the question is no longer of type select, we want to delete any select options
    if (questionType !== InspectionConfigChecklistQuestionTypeEnum.SELECT) {
      return (
        options?.map((option) => ({
          ...omit(option, '__typename'),
          _destroy: true,
        })) || []
      );
    }
    let sortOrderCounter = 0;
    return selectOptions.map((option) => {
      const newOption = {
        label: option.label,
        sortOrder: sortOrderCounter,
        _destroy: option.destroy,
      };
      if (!option.destroy) sortOrderCounter += 1;
      if (options?.find((o) => o.id === option.id)) {
        return {
          ...newOption,
          id: option.id,
        };
      }
      return newOption;
    });
  };

  const variables = {
    checklistTemplateQuestionAttributes: {
      questionText,
      questionType,
      sortOrder,
      supportsImageUploads:
        supportsImageUploads === 'yes'
          ? InspectionChecklistQuestionSupportsImageUploadsEnum.OPTIONAL
          : InspectionChecklistQuestionSupportsImageUploadsEnum.NO,
      selectOptionsAttributes: getSelectOptionsAttributes(),
    },
  };
  const refetchQueries = [
    {
      query: GET_INSPECTION_CHECKLIST_TEMPLATE,
      variables: { id: templateId },
    },
  ];

  const [createTemplateQuestion, { loading: createLoading }] = useMutation(
    CREATE_TEMPLATE_QUESTION,
    {
      variables: {
        ...variables,
        checklistTemplateQuestionAttributes: {
          ...variables.checklistTemplateQuestionAttributes,
          sectionId: templateSectionId,
        },
      },
      refetchQueries,
    },
  );

  const [updateTemplateQuestion, { loading: updateLoading }] = useMutation(
    UPDATE_TEMPLATE_QUESTION,
    {
      variables: {
        ...variables,
        id: questionId,
      },
      refetchQueries,
    },
  );

  const loading = createLoading || updateLoading;

  const onCancel = () => {
    setIsModalOpen(false);
    setQuestionText('');
    setQuestionType(InspectionConfigChecklistQuestionTypeEnum.BOOLEAN);
    setSupportsImageUploads('no');
    setSelectOptions([
      {
        ...NEW_OPTION,
        id: '0',
      },
    ]);
  };

  const createQuestion = async () => {
    if (loading) return;
    await (questionId ? updateTemplateQuestion() : createTemplateQuestion());
    onCancel();
  };

  const deleteSelectOption = (id: string) => {
    const newSelectOptions = [...selectOptions];
    const index = newSelectOptions.findIndex((option) => option.id === id);
    newSelectOptions[index] = {
      ...newSelectOptions[index],
      destroy: true,
    };
    setSelectOptions(newSelectOptions);
  };

  const addNewSelectOption = () => {
    const id = selectOptions.length;
    setSelectOptions([
      ...selectOptions,
      {
        ...NEW_OPTION,
        id: id.toString(),
      },
    ]);
  };

  const onChangeSelectOption = (id: string, value: string) => {
    const newSelectOptions = [...selectOptions];
    const index = newSelectOptions.findIndex((option) => option.id === id);
    newSelectOptions[index] = {
      ...newSelectOptions[index],
      label: value,
    };
    setSelectOptions(newSelectOptions);
  };

  const hasInvalidSelectOption = () => {
    if (questionType === InspectionConfigChecklistQuestionTypeEnum.SELECT) {
      return selectOptions.some(
        (option) => option.label === '' && option.destroy === false,
      );
    }
    return false;
  };

  const hasError = questionText === '' || hasInvalidSelectOption();

  return (
    <Box>
      <Box onClick={() => setIsModalOpen(true)} width="100%">
        {children}
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={onCancel}
        header={`${actionName} Question`}
        footer={
          <>
            <Button fill="outline" size="large" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              size="large"
              isLoading={loading}
              onClick={createQuestion}
              isDisabled={hasError}
            >
              {actionName}
            </Button>
          </>
        }
      >
        <Field label="Question description (required)" name="question_text">
          <TextInput
            value={questionText}
            onChange={(event) => setQuestionText(event.target.value)}
            isRequired
          />
        </Field>

        <Field label="Type of answer (required)" name="question_type">
          <Select
            onChange={(event) =>
              setQuestionType(
                event.target.value as InspectionConfigChecklistQuestionTypeEnum,
              )
            }
            value={questionType || ''}
            isRequired
          >
            {SELECT_OPTIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Field>
        {questionType === InspectionConfigChecklistQuestionTypeEnum.SELECT && (
          <TemplateQuestionFormSelectOptions
            options={selectOptions.filter((option) => !option.destroy)}
            onAdd={addNewSelectOption}
            onDelete={deleteSelectOption}
            onChange={onChangeSelectOption}
          />
        )}
        <Field
          label="Will this question need checklist photos? (required)"
          name="supports_image_uploads"
        >
          <RadioGroup
            flexDirection="row"
            onChange={setSupportsImageUploads}
            value={supportsImageUploads}
          >
            <Box flexDirection="row">
              <Radio value="yes" marginRight="40px">
                Yes
              </Radio>
              <Radio value="no">No</Radio>
            </Box>
          </RadioGroup>
        </Field>
      </Modal>
    </Box>
  );
};
